import _ from "lodash";

/* @ngInject */
export function itemSearchPageManager(
  $rootScope,
  $q,
  DatabaseApi,
  generalUtils,
  $filter,
  dataManagerGenerator
) {
  let defaultContractTypesDataManager = null;
  const getDefaultContractTypesDataManager = function () {
    if (!defaultContractTypesDataManager) {
      defaultContractTypesDataManager = dataManagerGenerator.createContractTypesDataManager();
    }
    return defaultContractTypesDataManager;
  };

  let defaultServiceCodesDataManager = null;
  const getDefaultServiceCodesDataManager = function () {
    if (!defaultServiceCodesDataManager) {
      defaultServiceCodesDataManager = dataManagerGenerator.createServiceCodesDataManager();
    }
    return defaultServiceCodesDataManager;
  };

  let defaultPayCodesDataManager = null;
  const getDefaultPayCodesDataManager = function () {
    if (!defaultPayCodesDataManager) {
      defaultPayCodesDataManager = dataManagerGenerator.createPayCodesDataManager();
    }
    return defaultPayCodesDataManager;
  };

  let defaultPatientStatusesDataManager = null;
  const getDefaultPatientStatusesDataManager = function () {
    if (!defaultPatientStatusesDataManager) {
      defaultPatientStatusesDataManager = dataManagerGenerator.createPatientStatusesDataManager();
    }
    return defaultPatientStatusesDataManager;
  };

  let defaultCaregiverStatusesDataManager = null;
  const getDefaultCaregiverStatusesDataManager = function () {
    if (!defaultCaregiverStatusesDataManager) {
      defaultCaregiverStatusesDataManager = dataManagerGenerator.createCaregiverStatusesDataManager();
    }
    return defaultCaregiverStatusesDataManager;
  };

  let defaultGetOfficeDataManager = null;
  const getDefaultGetOfficeDataManager = function () {
    if (!defaultGetOfficeDataManager) {
      defaultGetOfficeDataManager = dataManagerGenerator.createOfficeDataManager();
    }
    return defaultGetOfficeDataManager;
  };

  let defaultTeamDataManager = null;
  const getDefaultTeamDataManager = function () {
    if (!defaultTeamDataManager) {
      defaultTeamDataManager = dataManagerGenerator.createTeamDataManager();
    }
    return defaultTeamDataManager;
  };

  let defaultCoordinatorDataManager = null;
  const getDefaultCoordinatorDataManager = function ({onlyActive}) {
    if (!defaultCoordinatorDataManager) {
      defaultCoordinatorDataManager = dataManagerGenerator.createCoordinatorDataManager({onlyActive});
    }
    return defaultCoordinatorDataManager;
  };

  let defaultCertificationDataManager = null;
  const getDefaultCertificationDataManager = function () {
    if (!defaultCertificationDataManager) {
      defaultCertificationDataManager = dataManagerGenerator.createCertificationDataManager();
    }
    return defaultCertificationDataManager;
  };

  const generateFullUrlForSearch = function (relativeUrlPath, queryString) {
    return (
      "agencies/" +
      $rootScope.agencyId +
      "/agency_members/" +
      $rootScope.agencyMemberId +
      relativeUrlPath +
      queryString
    );
  };

  const getItemsFromPipedItemIds = function (itemIds, getItemByIdMethod) {
    const items = [];
    if (itemIds && itemIds.length > 0) {
      for (let idx = 0; idx < itemIds.length; idx++) {
        const relatedItem = getItemByIdMethod(itemIds[idx]);
        items.push(relatedItem);
      }
    }
    return items;
  };

  /*

    // example for 'relativeUrlPath' value:
    relativeUrlPath = "/export_batches";

    */
  const createItemSearchPageManager = function (relativeUrlPath) {
    const data = {
      relativeUrl: relativeUrlPath,
      isLoadingResults: false,
      searchParams: {},
      paramNamesForQueryString: [],
    };

    let validatorsDataManager = null;

    /**
     *
     * @param {string} name
     * @param {string|number} initialValue
     * @param {*} options
     */
    const initSearchParam = function (name, initialValue, options = {}) {
      if (name === undefined)
        throw new Error("Page Manager: Search param initiated without name");

      data.paramNamesForQueryString.push(name);

      const {
        placeholderValue, // Placeholders replace nulls
        queryName,
        toQueryConverter,
        fromQueryConverter,
        isEntity,
      } = options;

      const initValue = initialValue !== undefined ? initialValue : ""; // Can't be undefined, empty string will work
      const initPlaceholder =
        placeholderValue !== null ? placeholderValue : undefined; // Placeholders replace nulls
      const initQueryName = queryName !== undefined ? queryName : name; // If no query name provided - use param name
      const initIsEntity = isEntity !== undefined ? isEntity : false; // If no isEntity provided will be false

      data.searchParams[name] = {
        name,
        value: initValue,
        placeholder: initPlaceholder,
        queryName: initQueryName,
        toQueryConverter,
        fromQueryConverter,
        isEntity: initIsEntity,
        nonEntityCache: undefined, // This is to save the value of a non entity search param when an entity is present
      };
    };

    $rootScope.$watch(
      function () {
        return data.searchParams;
      },
      function () {
        if (!thereWasAnEntitySearch()) return;

        const entityParam = getEntityParam();
        if (entityParam !== undefined) return;

        resetAllEntitySearchCache();
      },
      true
    );

    const updateSearchParamValue = function (paramName, paramValue) {
      data.searchParams[paramName].value = paramValue;
    };

    const getQueryParamName = function (searchParam) {
      return searchParam.queryName || searchParam.name;
    };

    const getQueryParamValue = function (searchParam) {
      return searchParam.toQueryConverter
        ? searchParam.toQueryConverter(searchParam.value)
        : encodeURIComponent(searchParam.value);
    };

    const empty = function (value) {
      if (value === null || value === undefined || value === "") return true;

      return false;
    };

    const setEntitySearchCache = function (searchParam) {
      searchParam.nonEntityCache = searchParam.value;
    };

    const resetEntitySearchCache = function (searchParam) {
      searchParam.value = searchParam.nonEntityCache;
      searchParam.nonEntityCache = undefined;
    };

    const resetAllEntitySearchCache = function () {
      data.paramNamesForQueryString
        .filter(
          (paramName) =>
            data.searchParams[paramName].nonEntityCache !== undefined
        )
        .forEach(function (paramName) {
          const searchParam = data.searchParams[paramName];
          resetEntitySearchCache(searchParam);
        });
    };

    const thereWasAnEntitySearch = function () {
      return (
        getEntityParam() !== undefined ||
        data.paramNamesForQueryString.some(
          (paramName) =>
            data.searchParams[paramName].nonEntityCache !== undefined
        )
      );
    };

    const getEntityParam = function () {
      return data.searchParams[
        data.paramNamesForQueryString
          .sort()
          .find(
            (paramName) =>
              data.searchParams[paramName].isEntity &&
              !empty(data.searchParams[paramName].value)
          )
      ];
    };

    const generateQueryStringParams = function () {
      if (!thereWasAnEntitySearch()) {
        return noEntityQueryStringParams();
      }

      const entityParam = getEntityParam();
      const entityValue = entityParam.value;
      for (const paramName of data.paramNamesForQueryString) {
        const searchParam = data.searchParams[paramName];
        if (!searchParam.isEntity) {
          setEntitySearchCache(searchParam);
        }

        const placeholder = searchParam.placeholder;
        searchParam.value =
          placeholder !== undefined ? _.cloneDeep(placeholder) : null;
      }
      entityParam.value = entityValue;
      const paramNamesForEntityQueryString = data.paramNamesForQueryString.filter(
        (paramName) => !empty(data.searchParams[paramName].value)
      );

      if (paramNamesForEntityQueryString.indexOf(entityParam.name) === -1)
        paramNamesForEntityQueryString.push(entityParam.name);

      return entityQueryStringParams(paramNamesForEntityQueryString);
    };

    const entityQueryStringParams = function (paramNames) {
      let qString = "";

      for (const paramName of paramNames) {
        const searchParam = data.searchParams[paramName];
        const queryParamName = getQueryParamName(searchParam);
        const convertedValue = getQueryParamValue(searchParam);
        qString += generalUtils.generateQueryStringParam(
          queryParamName,
          convertedValue
        );
      }

      if (qString) qString = "?" + qString.substr(1);

      return qString;
    };

    const noEntityQueryStringParams = function () {
      let qString = "";

      data.paramNamesForQueryString.forEach(function (paramName) {
        const searchParam = data.searchParams[paramName];

        if (searchParam.nonEntityCache !== undefined) {
          resetEntitySearchCache(searchParam);
        }

        const queryParamName = getQueryParamName(searchParam);
        const convertedValue = getQueryParamValue(searchParam);
        qString += generalUtils.generateQueryStringParam(
          queryParamName,
          convertedValue
        );
      });

      if (qString) qString = "?" + qString.substr(1);

      return qString;
    };

    const updateSearchParamValuesByQueryString = function () {
      const queryParams = generalUtils.getQueryStringParams();

      if (!queryParams) return;

      data.paramNamesForQueryString.forEach(function (paramName) {
        const searchParam = data.searchParams[paramName];
        const queryParamName = getQueryParamName(searchParam);
        if (queryParams.hasOwnProperty(queryParamName)) {
          searchParam.value = searchParam.fromQueryConverter
            ? searchParam.fromQueryConverter(queryParams[queryParamName])
            : queryParams[queryParamName];
        }
      });
    };

    /*

        // example for 'resolve' method:
        resolve = function (response) { ... };

        // example for 'reject' method:
        reject = function (errorResponse) { ... };

        */
    const searchItemsOnServer = function () {
      data.isLoadingResults = true;

      return $q(function (resolve, reject) {
        const url = generateFullUrlForSearch(
          data.relativeUrl,
          generateQueryStringParams()
        );

        DatabaseApi.get(url).then(
          function (response) {
            data.isLoadingResults = false;
            resolve(response);
          },
          function (err) {
            data.isLoadingResults = false;
            reject(err);
          }
        );
      });
    };

    const pageManager = {
      isLoading: function () {
        return data.isLoadingResults;
      },

      searchParams: data.searchParams,

      updateRelativeURL: (url) => {
        data.relativeUrl = url;
      },

      setSearchParams: function (newSearchParams) {
        for (const key in newSearchParams) {
          if (newSearchParams.hasOwnProperty(key)) {
            const { value } = newSearchParams[key];
            updateSearchParamValue(key, value);
          }
        }
      },

      /**
       * @deprecated Use `initSearchParam` instead
       */
      initSearchParamDeprecated: function (
        name,
        initialValue,
        queryName,
        toQueryConverter,
        fromQueryConverter,
        placeholderValue
      ) {
        initSearchParam(name, initialValue, {
          placeholderValue,
          queryName,
          toQueryConverter,
          fromQueryConverter,
          isEntity: false,
        });
      },

      initSearchParam,

      initFromToDateParams: function (
        fromLastMonth = false,
        isInitialized = true,
        fromParamName = "from",
        toParamName = "to"
      ) {
        const convertDateTimeFromString = function (dateTimeValue) {
          return new Date(dateTimeValue);
        };

        let toDate, fromDate;
        if (isInitialized) {
          toDate = new Date();
          fromDate = new Date();
          const monthDays = generalUtils.getMonthDays(fromDate);
          fromDate.setDate(fromDate.getDate() - (fromLastMonth ? (monthDays - 1) : 1));
        }

        initSearchParam(fromParamName, fromDate, {
          toQueryConverter: generalUtils.formatDateByFilter,
          fromQueryConverter: convertDateTimeFromString,
        });
        initSearchParam(toParamName, toDate, {
          toQueryConverter: generalUtils.formatDateByFilter,
          fromQueryConverter: convertDateTimeFromString,
        });
      },

      getContractTypesDataManager: getDefaultContractTypesDataManager,
      getServiceCodesDataManager: getDefaultServiceCodesDataManager,
      getPayCodesDataManager: getDefaultPayCodesDataManager,
      getPatientStatusesDataManager: getDefaultPatientStatusesDataManager,
      getCaregiverStatusesDataManager: getDefaultCaregiverStatusesDataManager,
      getOfficeDataManager: getDefaultGetOfficeDataManager,
      getTeamDataManager: getDefaultTeamDataManager,
      getCoordinatorDataManager: getDefaultCoordinatorDataManager,
      getCertificationDataManager: getDefaultCertificationDataManager,

      initContractTypesParam: function () {
        initSearchParam("selectedContractTypes", [], {
          queryName: "contracts",
          toQueryConverter: function (contractTypes) {
            return $filter("pipeItems")(contractTypes, "id");
          },
          fromQueryConverter: function (contractTypeIds) {
            return getItemsFromPipedItemIds(
              contractTypeIds,
              defaultContractTypesDataManager.getContractTypeById
            );
          },
          placeholderValue: [],
        });
      },

      initServiceCodesParam: function () {
        initSearchParam("selectedServiceCodes", [], {
          queryName: "serviceCodes",
          toQueryConverter: function (serviceCodes) {
            return $filter("pipeItems")(serviceCodes, "id");
          },
          fromQueryConverter: function (serviceCodeIds) {
            return getItemsFromPipedItemIds(
              serviceCodeIds,
              defaultServiceCodesDataManager.getServiceCodeById
            );
          },
          placeholderValue: [],
        });
      },

      initOfficeParam: function () {
        initSearchParam("selectedOffices", [], {
          queryName: "offices",
          toQueryConverter: function (offices) {
            return $filter("pipeItems")(offices, "id");
          },
          fromQueryConverter: function (officesIds) {
            return getItemsFromPipedItemIds(
              officesIds,
              defaultGetOfficeDataManager.getOfficeById
            );
          },
          placeholderValue: [],
        });
      },

      initTeamParam: function () {
        initSearchParam("selectedTeams", [], {
          queryName: "teams",
          toQueryConverter: function (teams) {
            return $filter("pipeItems")(teams, "id");
          },
          fromQueryConverter: function (teamsIds) {
            return getItemsFromPipedItemIds(
              teamsIds,
              defaultTeamDataManager.getTeamById
            );
          },
          placeholderValue: [],
        });
      },

      initCoordinatorParam: function () {
        initSearchParam("selectedCoordinators", [], {
          queryName: "coordinators",
          toQueryConverter: function (coordinators) {
            return $filter("pipeItems")(coordinators, "id");
          },
          fromQueryConverter: function (coordinatorsIds) {
            return getItemsFromPipedItemIds(
              coordinatorsIds,
              defaultCoordinatorDataManager.getCoordinatorById
            );
          },
          placeholderValue: [],
        });
      },

      initCertificationParam: function () {
        initSearchParam("selectedCertifications", [], {
          queryName: "certifications",
          toQueryConverter: function (certifications) {
            return $filter("pipeItems")(certifications, "id");
          },
          fromQueryConverter: function (certificationIds) {
            return getItemsFromPipedItemIds(
              certificationIds,
              defaultCertificationsDataManager.getCertificationById
            );
          },
          placeholderValue: [],
        });
      },

      getValidatorsDataManager: function (validatorsType = "BILLING") {
        if (!validatorsDataManager) {
          validatorsDataManager = dataManagerGenerator.createValidtorsDataManager(validatorsType);
        }
        return validatorsDataManager;
      },

      initValidatorsParam: function (validatorsFieldName = "validators", validatorsType = "BILLING") {
        if (!validatorsDataManager) {
          validatorsDataManager = dataManagerGenerator.createValidtorsDataManager(validatorsType);
        }

        initSearchParam(
          validatorsFieldName,
          validatorsDataManager.getAllValidators(),
          {
            toQueryConverter: function () {
              const selectedValidators = validatorsDataManager.getSelectedValidators();
              return $filter("pipeItems")(selectedValidators, "id");
            },
            fromQueryConverter: function (pipedValidatorIds) {
              return getItemsFromPipedItemIds(
                pipedValidatorIds,
                validatorsDataManager.getValidatorById
              );
            },
          }
        );
      },

      updateSearchParamValue,

      resetSearchParamsByUrl: updateSearchParamValuesByQueryString,

      generateQueryStringParams,

      getFullUrl: function () {
        return generateFullUrlForSearch(data.relativeUrl, "");
      },

      executeSearch: function () {
        return searchItemsOnServer();
      },
    };

    return pageManager;
  };

  /*

    // example for 'relativeUrlPathForAllResultIds' value:
    relativeUrlPathForAllResultIds = "/visits_to_export_ids";

    // example for 'relativeUrlPathForResultsDataToRender' value:
    relativeUrlPathForResultsDataToRender = "/visits_to_export_data";

    */
  const createVirtualizedItemSearchPageManager = function (
    relativeUrlPathForAllResultIds,
    relativeUrlPathForResultsDataToRender
  ) {
    const pageManager = createItemSearchPageManager(
      relativeUrlPathForAllResultIds
    );

    const data = {
      relativeUrlForDataToRender: relativeUrlPathForResultsDataToRender,
    };

    /*

        // example for 'resolve' method:
        resolve = function (response) { ... };

        // example for 'reject' method:
        reject = function (errorResponse) { ... };

        */
    const loadResultsDataToRenderFromServer = function (
      startItemIndex,
      amountOfItems
    ) {
      data.isLoadingResults = true;

      return $q(function (resolve, reject) {
        const url =
          generateFullUrlForSearch(
            data.relativeUrlForDataToRender,
            pageManager.generateQueryStringParams()
          ) +
          generalUtils.generateQueryStringParam("start", startItemIndex) +
          generalUtils.generateQueryStringParam("count", amountOfItems);

        DatabaseApi.get(url).then(
          function (response) {
            data.isLoadingResults = false;
            resolve(response);
          },
          function (err) {
            data.isLoadingResults = false;
            reject(err);
          }
        );
      });
    };

    const loadResultsDataToRenderFromServerByIds = function (ids) {
      data.isLoadingResults = true;

      return $q(function (resolve, reject) {
        const url =
          generateFullUrlForSearch(data.relativeUrlForDataToRender, "?") +
          generalUtils.generateQueryStringParam("ids", ids.join("|"));

        DatabaseApi.get(url).then(
          function (response) {
            data.isLoadingResults = false;
            resolve(response);
          },
          function (err) {
            data.isLoadingResults = false;
            reject(err);
          }
        );
      });
    };

    pageManager.loadDataToRender = function (firstItemIndex, amountOfItems) {
      return loadResultsDataToRenderFromServer(firstItemIndex, amountOfItems);
    };

    pageManager.loadDataToRenderByIds = loadResultsDataToRenderFromServerByIds;

    return pageManager;
  };

  this.createSearchPageManager = createItemSearchPageManager;
  this.createVirtualizedSearchPageManager = createVirtualizedItemSearchPageManager;
};
