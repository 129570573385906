export function getFullName<
  T extends {
    firstName: string;
    middleName?: string | null;
    lastName: string;
  }
>(entity: T): string {
  let name = entity.firstName;

  if (entity.middleName ?? null !== null) {
    name += ` ${entity.middleName?.charAt(0)}.`;
  }

  name += ` ${entity.lastName}`;

  return name;
}
