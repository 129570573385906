export const entityNoteFilesTable = {
    templateUrl: "admin/views/entity-note/entity-note-files-table.html",
    bindings: {
        files: '<',
    },
    //! @ngInject
    controller: function (
        DatabaseApi,
        $uibModal,
        $rootScope,
        $scope,
    ) {
        const agencyMembers = DatabaseApi.getAgencyMembersArr();

        const mapFiles = () => {
            this.files.forEach(file => {
                const agencyMember = agencyMembers.find(a => a.id === file.createdBy);
                if (agencyMember) {
                    file.createdByText = agencyMember.displayName;
                }
            });
        };

        this.viewNoteFile = (file) => {
            const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/files/${file.id}`;
            let newScope = $scope.$new(true);
            newScope.document = file;
            newScope.url = url;

            $uibModal.open({
                templateUrl: 'admin/views/image-viewer-modal.html',
                size: 'lg',
                controller: 'imageViewerModalCtrl',
                scope: newScope
            });
        };

        this.$onInit = function () {
            mapFiles();
        };
    }
};