/* @ngInject */
export function Analytics(Consts, Storage, $rootScope) {
  
  
    // general
    var user = false;
    
    // make sure we have user id
    function setAnalyticsUser(){
      
      if(!$rootScope.user || !$rootScope.user.userId){
        user = Storage.getObject('user') || {};
        //$analytics.setUsername(user.id || 'N/A');
        //$mixpanel.identify(user.id || 'N/A');
      
      } else {
        user = $rootScope.user;
      }
      
    //   mixpanel.identify(user.userId); // TODO WMR - Consider removal
      if(window.ga) window.ga.setUserId(user.userId);
      var data = {
        userid: user ? user.userId : undefined,
        user_name: user.agencyMember ? user.agencyMember.firstName + ' ' + user.agencyMember.lastName : undefined,
        date: new Date(),
        agencyAdmin: user.agencyMember ? user.agencyMember.jobTitle === 'Admin' : false,
        title: user.agencyMember ? user.agencyMember.jobTitle : undefined,
        superuser: user.superuser,
        agencyId: user.agency ? user.agency.id : undefined,
        agencyName: user.agency ? user.agency.name : undefined,
      };
  
      /*
      var a = {
        "authToken":"e2de80820b5ad23a08a689f25e23c94448f399ad874c1bda",
        "pusherAppKey":"ed3b8f253cc59aeff859",
        "pusherCluster":"mt1",
        "pusherChannelName":"private-agency-49",
        "userId":45715,
        "superuser":false,
        "agencyMember":{
          "id":228,
          "createdAt":"2017-07-18T14:12:32.043Z",
          "jobTitle":"Coordinator",
          "agencyAdmin":false,
          "firstName":"Rena",
          "lastName":"Finkiel",
          "photoUrl":null,
          "email":"rina@preferredgoldphc.com",
          "status":"Active"
        },
        "coordinator":{
          "id":228,
          "createdAt":"2017-07-18T14:12:32.043Z",
          "jobTitle":"Coordinator",
          "agencyAdmin":false,
          "firstName":"Rena",
          "lastName":"Finkiel",
          "photoUrl":null,
          "email":"rina@preferredgoldphc.com",
          "status":"Active"
        },
        "agency":{
          "id":49,
          "createdAt":"2017-07-10T18:42:39.170Z",
          "name":"Preferred Home Care of New York",
          "logoUrl":null,
          "website":"www.preferredhcny.com",
          "address":"1267 57th St, Brooklyn, NY 11219, USA",
          "officePhone":"+17188418000",
          "organizationType":"LHCSA"
        }
      };
      */
      
      if(user.userId && user.agencyMember && user.agencyMember.createdAt){
        // TODO WMR - Consider removal
        // mixpanel.people.set({
        //   "$last_login": new Date(),
        //   "$created": user.agencyMember.createdAt,
        //   userid: user.userId,
        //   user_name: user.agencyMember.firstName + ' ' + user.agencyMember.lastName
        // });
      }
      
      return data;
  
    }
    
    function event(name, params){
      
      if(Consts.dev) return;
          
      params = params || {};
      
      var userData = setAnalyticsUser();
      for (var attrname in userData) { params[attrname] = userData[attrname]; }
      var data = params;
      
      //if(!data.userid) return;
      
      // mixpanel
    //   if(mixpanel) mixpanel.track(name, data); // TODO WMR - Consider removal
      
      
      // this is if we like to record the events in our db
      /*
      if($rootScope.appData.analytics){
        API.post('app/analytics', {event: name, data: data});
      }
      */
      
    }
    
    function pageView(name){
      
      if(Consts.dev) return;
          
      var data = setAnalyticsUser();
      //name = name.replace('app.','');
      data.page = name;
      
      //google analytics
      if(window.ga) window.ga.trackView(name);
      
      // this is if we like to record the events in our db
      /*
      if($rootScope.appData.analytics){
        API.post('app/analytics', {event: 'page_view', data: data});
      
      }
      */
      
      // mixpanel
    //   TODO WMR - Consider removal
    //   if(mixpanel) mixpanel.track("page_view", data);
      
    }
    
    
    return { 
      event: event,
      pageView: pageView
    };
    
  }