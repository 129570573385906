import { ChakraProvider, Portal } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import React, { StrictMode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ApiProvider } from "./core/api";
import { AuthProvider } from "./core/auth";
import ErrorPage from "./shared/components/ErrorPage";
import { theme } from "./shared/theme";

const GlobalStyle = () => <Global styles={{ "react-ui-view-adapter": { fontSize: 14 } }} />;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: (e) => !axios.isAxiosError(e),
      staleTime: 1000 * 30,
      retry: 0,
    },
  },
});

export function withApp<P>(Component: React.ComponentType<P>) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithApp = (props: P) => {
    return (
      <StrictMode>
        <AuthProvider>
          <ApiProvider>
            <QueryClientProvider client={queryClient}>
              {import.meta.env.DEV && (
                <Portal>
                  <ReactQueryDevtools initialIsOpen={false} />
                </Portal>
              )}
              <GlobalStyle />
              <ChakraProvider theme={theme} resetCSS={false}>
                <QueryErrorResetBoundary>
                  {({ reset }) => (
                    <ErrorBoundary onReset={reset} FallbackComponent={ErrorPage}>
                      <Component {...props} />
                    </ErrorBoundary>
                  )}
                </QueryErrorResetBoundary>
              </ChakraProvider>
            </QueryClientProvider>
          </ApiProvider>
        </AuthProvider>
      </StrictMode>
    );
  };

  ComponentWithApp.displayName = `withApp(${displayName})`;

  return ComponentWithApp;
}
