//! @ngInject
export function customFieldSettingModalCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    fields,
    data,
    toaster,
    FormService,
    agencyCustomFieldsService,
    SweetAlert
  ) {
    $scope.fields = FormService.getFields(fields, data);
    $scope.form = {};

    if ($scope.viewModeOnly) {
      $scope.customFieldFormDisabled = true;
      $scope.hideSaveFormButton = true;

      for (const fieldGroup of $scope.fields.groups) {
        for (const field of fieldGroup.fields) {
          $scope.form[field.name] = field.value;
          if (field.name !== "active" && field.name !== "addToSettings") {
            field.disabled = true;
          }
        }
      }

      if (data.fieldType === "ENUM") {
        $scope.customFieldFormDisabled = false;
        $scope.form.options = data.fieldOptions.map((option) => ({
          val: option,
        }));

        $scope.form.optionsCopy = angular.copy($scope.form.options);
      }
    }

    $scope.fieldChanged = (item) => {
      $scope.form[item.name] = item.value;
      if (item.type.type === "Boolean" && data.id) {
        $scope.submitForm();
      }
      if (item.name === "fieldType" && item.value === "ENUM") {
        $scope.form.options = [{ val: "" }];
      }
    };

    $scope.submitForm = () => {
      if ($scope.form.data.$invalid) return;

      const body = FormService.prepareFormData($scope.fields);
      if ($scope.form.options && body.fieldType === "ENUM") {
        body.fieldOptions = $scope.form.options
          .filter((item) => item.val !== "")
          .map((item) => item.val);
      }
      
      if (data.id) {
        agencyCustomFieldsService
          .editCustomField($rootScope.agencyId, $rootScope.agencyMemberId, data.id, body)
          .then(
            (res) => {
              toaster.pop("success", "Custom field saved successfully");
              $scope.closeModal();
            },
            (err) => {
              toaster.pop(
                "error",
                "Something went wrong",
                "Could not edit custom field"
              );
            }
          );
      } else {      
        agencyCustomFieldsService
          .createCustomField(
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            body
          )
          .then(
            (res) => {
              toaster.pop("success", "Custom field saved successfully");
              $scope.closeModal();
            },
            (err) => {
              toaster.pop(
                "error",
                "Something went wrong",
                "Could not create new custom field"
              );
            }
          );
      }
    };

    const updateAgencySingleSelectionCustomField = (body) => {
      agencyCustomFieldsService.updateAgencySingleSelectionCustomField(
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        data.id,
        body
      ).then((res) => {
        toaster.pop("success", "Custom field updated successfully");
        $scope.closeModal();
      },
        (err) => {
          toaster.pop(
            "error",
            "Something went wrong",
            "Could not update custom field"
          );
        }
      );
    };

    const getCurrentUpdatedOption = (currentAnswersArray) => {
      for (let option of currentAnswersArray) {
        const item = $scope.form.options.find(o => o.val === option);
        if (!item) {
          return option;
        }
      }
      return null;
    };

    $scope.handleClickOnSaveSingleSelectionField = (newOption) => {
      if (!newOption || !newOption.val) {
        return toaster.pop("warning", "Can't save an empty option");
      }

      if (newOption.isNew) {
        return $scope.submitForm();
      }

      const currentAnswersArray = $scope.form.optionsCopy.map(option => option.val);
      if (currentAnswersArray.includes(newOption.val)) {
        return toaster.pop("warning", "No value to update");
      }

      const currentOption = getCurrentUpdatedOption(currentAnswersArray);
      if (currentOption === null) {
        return toaster.pop("error", "Failed to update option");
      }

      const body = {
        entityName: $scope.form.entityName,
        fieldName: $scope.form.fieldName,
        currentOption: currentOption,
        newOption: newOption.val
      };

      SweetAlert.swal({
        title: 'Update Single Selection Answer',
        text: 'Changing this option will update all the previous data as well.\n Do you want to proceed?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3077EB',
        confirmButtonText: 'Yes, update',
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        if (isConfirm) {
          updateAgencySingleSelectionCustomField(body);
        }
      });
    };

    $scope.addNewOption = () => {
      if (!$scope.form.options) {
        $scope.form.options = [];
      }
      $scope.form.options.push({ val: "", isEdit: true, isNew: true });
    };

    $scope.closeModal = () => {
      $uibModalInstance.close("ok");
    };

    $scope.exit = () => {
      $uibModalInstance.dismiss();
    };
  };
