export const entitySideBarComponent = {
  templateUrl: "admin/views/entity-side-bar-component.html",
  bindings: {
    entity: "<",
    entityType: "<",
    state: "<",
    chats: "<",
    currentChat: "<",
    notesSection: "<",
    onStateChange: "&",
    onChatClick: "&",
    onChatClear: "&",
  },
  //! @ngInject
  controller: function ($scope) {
    const visitModesStates = ['NEW_VISIT', 'EDIT_NEW_VISIT'];

    this.changeState = (newState) => {
      this.scrollModalToTop();
      this.onStateChange({ newState });
    };

    this.handleChatClicked = (chat) => {
      this.onChatClick({ chat });
    };

    this.handleBackToChatsList = () => {
      this.onChatClear();
    };

    this.scrollModalToTop = () => {
      const elements = document.getElementsByClassName("entity-side-bar-content");
      Array.from(elements).forEach(element => element.scrollTop = 0);
    };

    this.$onInit = () => {
      $scope.isVisitEntity = visitModesStates.includes($scope.$ctrl.state);
    };

    this.$onChanges = (changes) => {
      if ("state" in changes && changes.state.currentValue) {
        $scope.isVisitEntity = visitModesStates.includes($scope.$ctrl.state);
      }
    };
  },
};
