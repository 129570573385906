//! @ngInject
export function inviteCtrl(
  $scope,
  $rootScope,
  $state,
  DatabaseApi,
  toaster,
  $timeout,
  officesService
) {
  window.scrollTo(0, 0);
  $scope.tabIndex = 0;
  $scope.selectedSenior = {};
  $scope.form = { role: "Coordinator", senior: null };
  
  function init() {
    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members').then(function (res) {
      $scope.members = res.data.agencyMembers;
      DatabaseApi.updateAgencyMembersByRole($scope.members);
      $scope.seniors = DatabaseApi.getAgencyMembersByRole().coordinatorsSeniors.filter((senior) => senior.status === "Active");
    });

    $scope.offices = officesService.offices;
    if(!$scope.offices) {
        officesService.getOffices().then((res) => {
          $scope.offices = res;
      }).catch(err => {
        toaster.pop("error", "Failed to fetch offices");
      });
    }
    
  }
  init();

  $scope.inviteMember = function() {
    if ($scope.invite.$invalid) {
      toaster.pop("error", "Missing Information", "Please Complete The Form");
      return window.scrollTo(0, 0);
    }

    $scope.form.agencyAdmin = false;
    $scope.form.hr = false;
    $scope.form.jobTitle = "Coordinator";

    console.log($scope.form.role);
    if ($scope.form.role === "a") {
      $scope.form.agencyAdmin = true;
      $scope.form.jobTitle = "Admin";
      $scope.form.supervisor = null;
    } else if ($scope.form.role === "hr") {
      $scope.form.hr = true;
      $scope.form.jobTitle = "HR";
      $scope.form.supervisor = null;
    } else if ($scope.form.role === "sc") {
      $scope.form.jobTitle = "SeniorCoordinator";
      $scope.form.supervisor = null;
    }

    if ($scope.form.jobTitle === "Coordinator") {
      //$scope.form.senior = $scope.selectedSenior;
      //console.log($scope.selectedSenior);
    }

    console.log($scope.invite);
    console.log($scope.form);

    if ($scope.form.jobTitle === "Coordinator" && !$scope.form.supervisor) {
      toaster.pop("error", "Missing Info", "Please select Senior Coordinator");
      window.scrollTo(0, 0);
      return;
    }

    $scope.sending = true;

    var body = angular.copy($scope.form);
    if (body.supervisor) body.supervisor = parseInt(body.supervisor);

    DatabaseApi.post(
      "agencies/" + $rootScope.agencyId + "/coordinators",
      body
    ).then(
      function(res) {
        toaster.pop("success", "Success", "New team member has been invited");
        window.scrollTo(0, 0);
        $scope.sending = false;
        $scope.sent = true;
        $scope.form = {};
        $scope.invite.$submitted = false;
        $timeout(function() {
          $state.reload();
        }, 50);
        //console.log($scope.invite);
      },
      function(err) {
        toaster.pop("error", "Something went wrong", err.data.error);
        $scope.sending = false;

        if (err.status === 403) {
          $scope.errText = "Domain is taken, please contact your system admin";
        }
      }
    );
  };

  $scope.onUpdateSeniorsAfterChangeRole = function() {
    $scope.seniors = DatabaseApi.getAgencyMembersByRole().coordinatorsSeniors;
  }

  $scope.change = function() {
    $scope.sent = false;
    $scope.errText = false;
    console.log($scope.form);
  };

  function remove(i, id) {}
};
