import { LocalDate, nativeJs } from "js-joda";

//! @ngInject
export function invoicedVisitsAddNotesModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {

    $scope.newNote = {
        arReasonId: "",
        collectionStatusRefId: "",
        claimStatusRefId: "",
        text: "",
        followUpDate: null,
        followupFor: "",
        visitInstanceIds: $scope.$resolve.visitInstanceIds
    }

    $scope.closeModal = (message, data) => {
        $uibModalInstance.close({
            success: message ? message : 'ok',
            data
        });
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

    $scope.addNotes = () => {
        const errorMessage = validateForm();
        if (errorMessage !== '') {
            toaster.pop('error', errorMessage);
            return;
        }

        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/create_ar_notes`;
        $scope.isLoading = true;
        DatabaseApi.post(url, convertFormToRequestBody($scope.newNote)).then((res) => {
            toaster.pop('success', "Notes created successfully");
            $scope.closeModal('success', $scope.newNote);
            $scope.isLoading = false;
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not create notes");
            $scope.isLoading = false;
        });
    }

    const validateForm = () => {
        let errorMessage = '';
        if ($scope.newNote.text === "") {
            errorMessage = 'Missing note text';
        } else if ($scope.newNote.arReasonId === "") {
            errorMessage = 'Missing no payment reason';
        } else if ($scope.newNote.collectionStatusRefId === "") {
            errorMessage = 'Missing collection status';
        } else if ($scope.newNote.followUpDate === null && $scope.newNote.followupFor !== "") {
            errorMessage = 'Missing follow up date';
        } else if ($scope.newNote.followUpDate !== null && $scope.newNote.followupFor === "") {
            errorMessage = 'Missing follow up by';
        }

        return errorMessage;
    }

    const convertFormToRequestBody = (formNote) => {
        return {
            ...formNote,
            arReasonId: parseInt(formNote.arReasonId),
            collectionStatusRefId: parseInt(formNote.collectionStatusRefId),
            claimStatusRefId: formNote.claimStatusRefId === "" ? null : parseInt(formNote.claimStatusRefId),
            followUpDate: formNote.followUpDate === null ? null : LocalDate.from(nativeJs($scope.newNote.followUpDate)),
            followupFor: formNote.followupFor === "" ? null : parseInt(formNote.followupFor)
        }
    }

    $scope.followUpDateOptions = {
        datepickerMode: 'day',
        minDate: new Date(),
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };
};