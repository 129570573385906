import _ from "lodash";

//! @ngInject
export function newSubSectionModalCtrl($scope, $rootScope, $http, $uibModalInstance, application, certification, modalData, subsectionId, sectionId, DatabaseApi, $timeout, $window, toaster, Consts, $uibModal){

    // initial types object
    $scope.questionTypes = [
        { description: "Text", type: "Text" },
        { description: "Address", type: "Address" },
        { description: "Email", type: "Email" },
        { description: "Numeric", type: "Numeric" },
        { description: "Dollar", type: "Dollar" },
        { description: "Phone Number", type: "PhoneNumber" },
        { description: "Full Date", type: "FullDate" },
        { description: "Month-Year Date", type: "MonthYearDate" },
        { description: "Year Only", type: "YearDate" },
        { description: "DropDown", type: "DropDown" },
        { description: "Checkbox", type: "Checkbox" },
        { description: "Disclaimer", type: "Checkbox" },
        { description: "Radio", type: "Radio" },
        { description: "Free text", type: "PlainText" },
        { description: "Free text (title)", type: "TitleText" },
        { description: "Employee Signature", type: "Signature" },
        { description: "General Signature", type: "GeneralSignature"}
    ];

    $scope.multiCheckQuestionTypes = ["Radio", "DropDown", "Checkbox", "AmericanTest"];

    $scope.showSelectedQuestionDiv = false;
    $scope.showNewQuestionForm = false;
    $scope.selectedQuestionType = {};
    $scope.selectedQuestionType.name = '';

    $scope.selectors = {};

    $scope.application = angular.copy(application);
    $scope.data = modalData;
    $scope.subsectionId = subsectionId;

    if($scope.data) $scope.modalType = $scope.data.type;

    console.log(subsectionId);
    var insertedGroups = [];
    $scope.questions = [];
    $scope.notAllowedQuestions = ['FreeText','AmericanTest','StartAndEndTime','Placeholder','Document','DocumentWithExpiry'];
    $scope.visibleAnswersQuestionIds = [];
    $scope.newPossibleAnswer = {val: ""};
    $scope.addingNewPossibleAnswerQuestionId = null;
    $scope.file = {
        upload: null,
        partSize: 5 * 1024 * 1024
    };

    function makePreset() {

        $scope.presets = {
            general: [],
            medical: [],
            legal: [],
            government: [],
            hasSubsections: false
        };

        $scope.application.subsections.forEach(function (subsection) {

            if(subsection.sectionLabel && !subsection.isActive){
                if($scope.presets[subsection.sectionLabel]) {
                    $scope.presets[subsection.sectionLabel].push(subsection);
                    $scope.presets.hasSubsections = true;
                }
            }

        });
    }

    const ckeditorConfig = {
        extraPlugins: "mentions,emoji,basicstyles,undo,link,toolbar",
        removePlugins: "elementspath, showborders",
        resize_enabled: false,
        language: "en",
        contentsCss: [
            "/global-vendors/ckeditor4/ckeditor.css",
            "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700",
        ],
        font_names: "Open Sans",
        allowedContent: true,
        removeButtons: "",
        height: 150
    };

    makePreset();

    function makeQuestions() {

        var insertedGroups = [];
        $scope.questions = [];

        if($scope.modalType === 'TEST') $scope.data.questions = [];

        $scope.application.questions.forEach(function (question) {

            if(question.templateId === 'PlaceholderAvailability' && question.isActive && question.subSection === $scope.subsectionId){
                if(!$scope.data.settings) $scope.data.settings = {};
                if(!$scope.data.settings.availabilityPlaceholder) $scope.data.settings.availabilityPlaceholder = {};
            }

            question.name = question.name.replace( /<br>/g, '\n' );

            if($scope.modalType === 'TEST' || $scope.modalType === 'CUSTOM'){
                if($scope.modalType === 'TEST' && question.subSection === $scope.data.id && question.isActive) {
                    $scope.data.questions.push(question);
                } else if($scope.modalType === 'CUSTOM' && question.subSection === $scope.data.id && question.type === 'FreeText' && question.isActive) {
                    $scope.data.question = question;
                }
            }

            if (question.isActive && !question.allowDuplicates) {


            } else if($scope.notAllowedQuestions.indexOf(question.type) >= 0 && !question.allowDuplicates) {


            } else if (question.questionGroupName && question.questionGroupName.length) {
                if (insertedGroups.indexOf(question.questionGroupName) === -1) {
                    $scope.questions.push(question.questionGroupName);
                    insertedGroups.push(question.questionGroupName);
                }
            } else {
                if($scope.questions.indexOf(question.name) === -1) $scope.questions.push(question.name);
            }
            $scope.questions = $scope.questions.sort();
        });
    }

    makeQuestions();


    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    $scope.pickPresetSubsectionType = function(id) {

        if(!id) return;

        makePreset();

    };

    $scope.pickSubsectionType = function(type){

        if(!type) return;
        var item = type;

        $scope.modalType = type;

        console.log(item);

        var body = {
            fatherSection: sectionId
        };

        if(item === 'REGULAR') {

            body.type = 'REGULAR';
            body.name = 'subsection title';

        } if(item === 'TEST') {

            body.type = 'TEST';
            body.name = 'Test';

        } else if(item === 'CUSTOM') {

            body.type = 'CUSTOM';
            body.name = 'Custom Section';

        } else if(item === 'DOWNLOADABLE_FILES') {

            body.type = 'DOWNLOADABLE_FILES';
            body.name = 'Downloadable Files';

        } else if(item === 'PRESET') {

            return;

        }

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            certification + '/subsection';



        DatabaseApi.post(url, body).then(function (res) {

            toaster.pop('success','Success','New subsection has been added');

            if(item === 'REGULAR') {

                //some init before

                res.data.subsections.sort(function (a, b) {
                    return b.id - a.id;
                });

                $scope.data = res.data.subsections[0];
                $scope.subsectionId = $scope.data.id;
                if(!$scope.data.settings) $scope.data.settings = {
                    hideOnMobile: false
                };

                $scope.application.questions.forEach(function (question) {
                    if(question.templateId === 'PlaceholderAvailability' && question.isActive && question.subSection === $scope.subsectionId){
                        $scope.data.settings.availabilityPlaceholder = {};
                    }
                });

            } else if(item === 'CUSTOM'){

                //some init before

                res.data.subsections.sort(function (a,b) {
                    return b.id - a.id;
                });

                $scope.data = res.data.subsections[0];
                $scope.subsectionId = $scope.data.id;

                if(!$scope.data.settings) $scope.data.settings = {
                    hideOnMobile: true
                };

                if(!$scope.data.question){
                    $scope.addQuestion({}, $scope.data.id, '', true, 'FreeText', null, 'CUSTOM');
                    $timeout(function(){
                        $scope.addQuestion({}, $scope.data.id, 'Check to confirm', true, 'Signature', null);
                    },300);
                }


            } else if(item === 'TEST'){

                //some init before
                res.data.subsections.sort(function (a,b) {
                    return b.id - a.id;
                });

                $scope.data = res.data.subsections[0];
                $scope.subsectionId = $scope.data.id;

                if(!$scope.data.settings) $scope.data.settings = {
                    hideOnMobile: true,
                    testSettings: {}
                };

                if(!$scope.data.questions) $scope.data.questions = [];
                if(!$scope.data.questions.length){
                    $scope.addQuestion({}, $scope.data.id, newQuestion.questionText, true, 'AmericanTest', newQuestion.possibleAnswers, 'TEST');
                }

            } else if(item === 'DOWNLOADABLE_FILES') {
                //some init before
                res.data.subsections.sort(function (a, b) {
                    return b.id - a.id;
                });

                $scope.data = res.data.subsections[0];
                $scope.subsectionId = $scope.data.id;
                if(!$scope.data.settings) $scope.data.settings = {
                    hideOnMobile: false
                };
            }

        }, function (err) {
            console.log('err');
            console.log(err);
            toaster.pop('error','Something went wrong');
        });

    };

    console.log($scope.data);
    console.log($scope.application);


    var newQuestion = $scope.newQuestion = {
        questionText: 'new question',
        possibleAnswers: [
            {
                id:1, text: 'Answer 1'
            },{
                id:2, text: 'Answer 2'
            },{
                id:3, text: 'Answer 3'
            },{
                id:4, text: 'Answer 4'
            }
        ]
    };

    var customQuestionPossibleAnswer = [{"id": 1, "text": "Check to confirm"}];


    $scope.removeQuestionAnswer = function(question){
        question.possibleAnswers.splice(question.possibleAnswers.length-1,1);
        $scope.updateQuestion(question,false);
    };

    $scope.addQuestionAnswer = function(question){
        question.possibleAnswers.push({id: question.possibleAnswers.length + 1, text: 'Answer '+ (question.possibleAnswers.length+1)});
        $scope.updateQuestion(question,false);
    };

    $scope.updateQuestion = function(question, isCustom){

        var body = angular.copy(question);

        body.possibleAnswers = body.possibleAnswers || undefined;

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            certification  + '/question/' + question.id;

           // if(isCustom) $scope.closeModal('ok');
        DatabaseApi.patch(url, body).then(function(res){

        }, function (err) {

            toaster.pop('error','Something went wrong');

        });
    };

    $scope.updateTest = function(){

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            certification  + '/subsection/' + $scope.data.id;

        var body = {
            name: $scope.data.name,
            settings: $scope.data.settings
        };

        DatabaseApi.patch(url, body).then(function(res){

        }, function (err) {

            toaster.pop('error','Something went wrong');
        });
    };

    $scope.editItem = function(type, item) {

        if(!$scope.application || !$scope.application[type].length) return;


        var urlEntity = 'question';
        item.possibleAnswers = item.possibleAnswers || undefined;

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            certification + '/' + urlEntity + '/' + item.id;

        var body = item;


        DatabaseApi.patch(url, body).then(function (res) {

            console.log(res.data);
            if(res.data && res.data) parseApplication(res.data);

        }, function (err) {
            toaster.pop('error', "Error updating question data")
        });


    };

    $scope.activateSubsection = function(id, name){

        var body = {
            fatherSection: sectionId,
            name: name
        };

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            certification + '/subsection/' + id + '/activate';

        DatabaseApi.post(url, body).then(function (res) {

            toaster.pop('success','Success','New subsection has been added');
            $scope.closeModal();

        }, function (err) {
            console.log('err');
            console.log(err);
            toaster.pop('error','Something went wrong');
        });
    };

    $scope.updateCustom = function(){

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            certification  + '/subsection/' + $scope.data.id;

        var body = {
            name: $scope.data.name,
            settings: $scope.data.settings
        };

        DatabaseApi.patch(url, body).then(function(res){

        }, function (err) {

            toaster.pop('error','Something went wrong');
        });
    };

    /**
     * 
     * @param {*} father 
     * @param {*} name 
     * @param {*} isMandatory 
     * @param {*} type 
     * @param {*} possibleAnswers 
     * @param {*} testOrCustom 
     * @param {*} templateId 
     * @param {*} addQuestion 
     * @param {{forceNewLine: boolean, width: number | null, answerInline: boolean, showOnlyAnswer: boolean}} displaySettings 
     */
    $scope.addQuestion = function(displaySettings, father, name, isMandatory, type, possibleAnswers, testOrCustom, templateId, addQuestion){
        const displaySettingsFormatted = {
            forceNewLine: (displaySettings && displaySettings.forceNewLine) || false,
            width: (displaySettings && displaySettings.width) || null,
            answerInline: (displaySettings && displaySettings.answerInline) || true,
            showOnlyAnswer: (displaySettings && displaySettings.showOnlyAnswer) || true
        }
        if(testOrCustom === 'TEST'){
            name === name || newQuestion.questionText;
            possibleAnswers = possibleAnswers || newQuestion.possibleAnswers;
        } else if(testOrCustom === 'CUSTOM'){
            possibleAnswers = customQuestionPossibleAnswer;
            displaySettingsFormatted.answerInline = false;
            displaySettingsFormatted.showOnlyAnswer = false;
        }

        var body = {
            fatherSubSection: father,
            name: name,
            isMandatory: isMandatory,
            type: type,
            possibleAnswers: possibleAnswers || null,
            templateId: templateId || null,
            forceNewLine: displaySettingsFormatted.forceNewLine,
            width: displaySettingsFormatted.width,
            answerInline: displaySettingsFormatted.answerInline,
            showOnlyAnswer: displaySettingsFormatted.showOnlyAnswer
        };

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' + certification  + '/question';

        DatabaseApi.post(url, body).then(function(res){
            if(testOrCustom === 'TEST') {
                $scope.data.questions.push(res.data);
            } else if(testOrCustom === 'CUSTOM') {
                $scope.data.question = res.data;
            }

            if(addQuestion){
                $scope.application.questions.push(res.data);
                makeQuestions();
                clearQuestionObject();
            }
        }, function (err) {

            toaster.pop('error','Something went wrong');
        });


    };

    $scope.moveItem = function(id1, direction) {

        var type = 'questions';

        if(!$scope.application || !$scope.application.questions.length) return;


        var id2 = false, previousItem, itemFound;
        var itemFather = false;

        $scope.application.questions.forEach(function (item) {
            if (item.id === id1) {
                itemFather = item.subSection;
            }
        });

        if(direction === 'up'){
            $scope.application.questions.forEach(function (item) {
                if(item.id === id1) {
                    itemFound = true;
                }

                if(itemFound && item.isActive && item.subSection === itemFather && item.id !== id1) {
                    if(id2 === false) {
                        id2 = item.id;
                    }
                }
            });

        } else {
            $scope.application.questions.forEach(function (item) {
                if(id2 === false && item.isActive && item.subSection === itemFather && item.id !== id1) {
                    previousItem = item;
                }

                if(item.id === id1){
                    itemFound = true;
                    id2 = previousItem.id;
                }
            });
        }

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            certification + '/switch_' + type;

        var body = {};

        body.question1 = id1;
        body.question2 = id2;

        DatabaseApi.post(url, body).then(function (res) {

            if(res.data) parseApplication(res.data);

        }, function (err) {
            console.log(err);
            toaster.pop('error','Something went wrong');
        });


    };

    $scope.removeQuestion = function(idx, id){
        $scope.data.questions.splice(idx,1);

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' + certification  + '/question/' + id + '/deactivate';

        var body = {};

        DatabaseApi.post(url, body).then(function(res){

        }, function (err) {

            toaster.pop('error','Something went wrong');
        });
    };

    $scope.editorOptions = {
        height: 300,
        focus: true,
        airMode: false,
        toolbar: [
            ['edit',['undo','redo']],
            ['headline', ['style']],
            ['style', ['bold', 'superscript', 'subscript', 'clear']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
            ['height', ['height']]
        ]
    };


    $scope.removeApplicationItem = function(item) {

        var urlEntity = 'question';
        var activeName = 'isActive';

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            certification + '/' + urlEntity + '/' + item.id + '/deactivate';

        var body = item;

        body[activeName] = false;

        DatabaseApi.post(url, body).then(function (res) {

            $scope.application.questions.forEach(function (question) {
                if (question.id === item.id) question.isActive = false;
            });
            makeQuestions();

        }, function (err) {
            console.log(err);
            toaster.pop('error','Something went wrong');
        });


    };


    $scope.selectorUpdated = function(val) {

        if(!val) return;
        var item = val;

        console.log(item);

        var body;
        var urlEntity;

        urlEntity = 'question';
        body = {
            fatherSubSection: $scope.subsectionId
        };

        var questions = [];

        var isDuplicate = false;
        var questionGroupOrQuestion = null;
        $scope.application.questions.forEach(function (question) {

            if(isDuplicate) return;

            if (!questionGroupOrQuestion && (question.questionGroupName === val || question.name === val)) {
                if (question.questionGroupName === val) questionGroupOrQuestion = 'group';
                else if (question.name === val) questionGroupOrQuestion = 'question';
            }


            if (question.questionGroupName === val && questionGroupOrQuestion === 'group'){

                if(!question.isActive){
                    questions.push(question);

                } else if(question.isActive && question.allowDuplicates){
                    isDuplicate = true;
                    questions.push(question);
                }


            } else if (question.name === val && questionGroupOrQuestion === 'question'){

                if(!question.isActive){
                    isDuplicate = false;

                } else if(question.isActive && question.allowDuplicates){
                    isDuplicate = true;
                }

                questions = [question];

            }
        });

        if(isDuplicate){

            questions.forEach(function (value) {

                console.log(value);
                $scope.addQuestion({}, $scope.subsectionId, value.name, true, value.type, value.possibleAnswers, null, value.templateId, true);

            });

        } else {
            activateQuestions(questions, certification, urlEntity, body).then((application) => {
                // To prevent order mess in the questions order
                $scope.application = {...$scope.application, ...application};
                makeQuestions();
            });
        }

        $scope.selectors = {};
    };

    async function activateQuestions(questions, certification, urlEntity, body) {
        let ret = null;
        for (const question of questions) {
            const url = `hr/agencies/${$rootScope.agencyId}/certification/${certification}/${urlEntity}/${question.id}/activate`;
            await DatabaseApi.post(url, body).then(function (res) {
                question.isActive = true;
                question.subSection = $scope.subsectionId;
                ret = res.data;
            }, function (err) {
                console.log('err');
                console.log(err);
                toaster.pop('error', 'Something went wrong');
            });
        }
        return ret;
    }

    function getApplication(){

        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certifications/' + certification + '/application_form')
            .then(function (res) {
                $scope.application = res.data;

                parseApplication(res.data);


            }, function (err) {

                toaster.pop('error','Something went wrong');


            });
    }

    function parseApplication(data, savedSection, savedSubsection, savedSubsectionType) {

        var subSection, section, subsectionType;
        if($scope.application){
            if($scope.application.section) section = $scope.application.section;
            if($scope.application.subSection) subSection = $scope.application.subSection;
            if($scope.application.subSectionType) subsectionType = $scope.application.subSectionType;
        }
        $scope.application = data;


        //$scope.application.subsections[0].type = 'test';
        //$scope.application.subsections[1].type = 'custom';
        //$scope.application.sections[2].isStop = true;


        //$timeout(function () {
        if(savedSection){
            $scope.application.section = savedSection;
            $scope.application.subSection = savedSubsection;
            $scope.application.subSectionType = savedSubsectionType;
        } else {
            $scope.application.section = section;
            $scope.application.subSection = subSection;
            $scope.application.subSectionType = subsectionType;
        }
        //},0);

        makeQuestions();

    }

    getApplication();

    $scope.generateExamplePdf = function(id, preset) {

        var url = 'hr/agencies/' + $rootScope.agencyId + '/subsection/' + id + '/generate_doc_preview';
        DatabaseApi.post(url).then(function(res){

            $scope.presets[preset].forEach(function (value) {
                value.loading = false;
            });
            if(res.data.url) $window.open(res.data.url);

        }, function(err){

            toaster.pop('error','Something went wrong');
        });

    };


    // from http://www.targetlocal.co.uk/wordoff
    function cleanUpWordHTML(html) {
        if (html === '') return;

        var t = new RegExp('(\n|\r| class=(")?Mso[a-zA-Z]+(")?)', 'gi');
        html = html.replace(t, '');
        html = html.replace(/<!--(.*?)-->/gi, '');
        html = html.replace(/<!\[(.*?)\]>/gi, '');
        html = html.replace(/�/gi, ' ');

        var r = new RegExp('<(/)*(meta|link|div|span|\\?xml:|st1:|o:|font)(.*?)>', 'gim');
        html = html.replace(r, '');

        var i = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];
        for (var s = 0; s < i.length; s++) {
            r = new RegExp('<' + i[s] + '.*?' + i[s] + '(.*?)>', 'gim');
            html = html.replace(r, '');
        }

        var o = ['style', 'start'];
        for (var v = 0; v < o.length; v++) {
            var u = new RegExp(' ' + o[v] + '="(.*?)"', 'gi');
            html = html.replace(u, '');
        }

        html = html.replace(/�/g, '"');
        html = html.replace(/�/g, '"');
        html = html.replace(/\s\s+/g, '');
        html = html.replace(/<p><\/p>/gi, '');
        html = html.replace(/<\/h1><\/h1>/gi, '');
        html = html.replace(/<\/h2><\/h2>/gi, '');
        html = html.replace(/<\/h3><\/h3>/gi, '');
        html = html.replace(/<\/h4><\/h4>/gi, '');
        html = html.replace(/<\/h5><\/h5>/gi, '');
        html = html.replace(/<\/h6><\/h6>/gi, '');
        html = html.replace(/(<\/h[0-9]>)/gi, '$1\n');
        html = html.replace(/(<\/p>)/gi, '$1\n');
        html = html.replace(/(<h[0-9]>)/gi, ' $1');
        html = html.replace(/(<p>)/gi, '  $1');
        html = html.replace(/(<html>)/gi, '$1\n');
        html = html.replace(/(<head>)/gi, '\n$1\n  ');
        html = html.replace(/(<\/title>)/gi, '$1\n');
        html = html.replace(/(<\/head>)/gi, '$1\n');
        html = html.replace(/(<body>)/gi, '$1\n');
        html = html.replace(/(<\/body>)/gi, '$1\n');
        html = html.replace(/(<\/html>)/gi, '$1\n');
        html = html.replace(/(<\/li>)/gi, '$1\n');
        html = html.replace(/(<\/ul>)/gi, '$1\n');
        html = html.replace(/(<ul>)/gi, '$1\n');
        html = html.replace(/(<\/table>)/gi, '$1\n');
        html = html.replace(/(<br(.*?)>)/gi, '$1\n');

        return html;
    }

    $scope.showNewQuestionDiv = function () {
        $scope.showNewQuestionForm = true;
    }
    
    $scope.updateSelectedQuestion = function (selectedQuestionType) {
        $scope.selectedQuestionType.description = selectedQuestionType.description;
        $scope.selectedQuestionType.type = selectedQuestionType.type;
        $scope.showSelectedQuestionDiv = true;
        if ($scope.selectedQuestionType.type === 'DropDown'
        || $scope.selectedQuestionType.type === 'Checkbox'
        || $scope.selectedQuestionType.type === 'Radio') {
            $scope.selectedQuestionType.possibleAnswers = [{ id: 1, text: "" }];
        }
        else {
            $scope.selectedQuestionType.possibleAnswers = null;
        }
        if ($scope.selectedQuestionType.type === 'TitleText'
        || $scope.selectedQuestionType.type === 'PlainText') {
            $scope.selectedQuestionType.isMandatory = false;
        }
        else {
            $scope.selectedQuestionType.isMandatory = true;
        }
    }
    
    $scope.addInput = function (input) {
        const index = $scope.selectedQuestionType.possibleAnswers.indexOf(input);
        const lastAnswer = _.maxBy($scope.selectedQuestionType.possibleAnswers, 'id');
        const nextId = lastAnswer ? lastAnswer.id + 1 : 1;
        $scope.selectedQuestionType.possibleAnswers.splice(index + 1, 0,{ id: nextId , text: "" });
    }
    
    $scope.removeInput = function (input) {
        if( $scope.selectedQuestionType.possibleAnswers.length == 1){
            toaster.pop('error', 'You must have one label');
        } else {
            var index = $scope.selectedQuestionType.possibleAnswers.indexOf(input);
            if (index > 0) {
                $scope.selectedQuestionType.possibleAnswers.splice(index, 1);
            }
        }
    }

    $scope.saveNewQuestion = function () {
        if ($scope.selectedQuestionType.name !== '' || $scope.selectedQuestionType.type === 'Signature') {
            if ($scope.selectedQuestionType.type === 'Signature') {
                $scope.selectedQuestionType.name = 'Please sign';
            }
            $scope.cancelNewQuestion();
            $scope.addQuestion({}, $scope.subsectionId, $scope.selectedQuestionType.name, $scope.selectedQuestionType.isMandatory, $scope.selectedQuestionType.type, $scope.selectedQuestionType.possibleAnswers, null, null, true);
        } else {
            toaster.pop('error', 'Please enter question text');
        }
    }
    
    $scope.cancelNewQuestion = function () {
        $scope.showSelectedQuestionDiv = false;
        $scope.showNewQuestionForm = false;
    }

    function clearQuestionObject() {
        $scope.selectedQuestionType = {};
    }

    $scope.isQuestionAnswersVisible = function (questionId) {
        return $scope.visibleAnswersQuestionIds.indexOf(questionId) > -1;
    }

    $scope.toggleVisibleQuestionAnswers = function (questionId) {
        var index = $scope.visibleAnswersQuestionIds.indexOf(questionId);
        if (index === -1) {
            $scope.visibleAnswersQuestionIds.push(questionId);
        } else {
            $scope.visibleAnswersQuestionIds.splice(index, 1);
        }
    }

    $scope.showQuestionAnswers = function (questionId) {
        if (!$scope.isQuestionAnswersVisible(questionId)) {
            $scope.visibleAnswersQuestionIds.push(questionId);
        }
    }

    $scope.addQuestionPossibleAnswer = function (questionId) {
        $scope.addingNewPossibleAnswerQuestionId = questionId;
    }

    $scope.removeQuestionPossibleAnswer = function (question, answerId) {
        question.possibleAnswers = question.possibleAnswers.filter(function (answer) {
            return answer.id !== answerId;
        });
        $scope.updateQuestion(question);
    }

    $scope.saveNewPossibleAnswer = function (question) {
        if ($scope.newPossibleAnswer.val) {
            if (question.possibleAnswers.find(function (answer) {
                return answer.text === $scope.newPossibleAnswer.val;
            }) !== undefined) {
                toaster.pop('warning', 'Oops! Value already exists in possible answers');
                return;
            }
            question.possibleAnswers.push({
                id: question.possibleAnswers.length === 0 ? 1 : question.possibleAnswers[question.possibleAnswers.length - 1].id + 1,
                text: $scope.newPossibleAnswer.val
            });
            $scope.updateQuestion(question);
            $scope.showQuestionAnswers(question.id);
        }
        $scope.newPossibleAnswer.val = "";
        $scope.addingNewPossibleAnswerQuestionId = null;
    }

    $scope.viewDownloadableFile = function (questionId) {
        var newScope = $scope.$new();
        newScope.document = {};
        newScope.url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId +
            '/hr_questions/' + questionId + '/downloadable_files/file_url';
        $uibModal.open({
            templateUrl: 'admin/views/image-viewer-modal.html',
            size: 'lg',
            controller: 'imageViewerModalCtrl',
            scope: newScope
        });
    }

    $scope.$watch('file.upload', function () {
        if ($scope.file && $scope.file.upload && $scope.file.upload.name){
            let fileType = $scope.file.upload.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (fileType !== 'pdf') {
                toaster.pop('warning', "Unsupported file type", 'Please uplaod a PDF file (.pdf)');
                $scope.file = null;
                return;
            }
            let formData = new FormData();
            formData.append('file',  $scope.file.upload, $scope.file.upload.name);
            // preparing form data as new hr_question
            formData.append('fatherSubSection', $scope.data.id);
            formData.append('name', $scope.file.upload.name);
            formData.append('type', 'DownloadableFile');

            const url = Consts.api + 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
                certification + '/upload_downloadable_file';
            $http({
                url: url,
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': undefined }
            }).then(function (res) {
                $scope.application.questions.push(res.data);
                makeQuestions();
                clearQuestionObject();
                $scope.file.upload = null;
                toaster.pop('success','Success','File Uploaded Successfully');
            }, function (err) {
                clearQuestionObject();
                $scope.file.upload = null;
                toaster.pop('error','Failed to upload file');
            });
        }
    });

    $scope.onReady = function (instance, question) {
        if (CKEDITOR.instances[instance]) {
          CKEDITOR.instances[instance].destroy();
        }
    
        const noteEditor = CKEDITOR.replace(instance, ckeditorConfig);
    
        noteEditor.on("change", function () {
          handleCkEditorChange(instance, question, this.getData());
        });
    
        // When finished - add custom class
        $timeout(() => {
          CKEDITOR.instances[instance].element.$.classList.add("active-note-editor");
        });
      };

    function handleCkEditorChange(instance, question, value) {
        question.name = value;

        if (instance.startsWith('update-question')) {
            const isCustom = instance === 'update-question-custom';
            $scope.updateQuestion(question, isCustom);
        }
    }

    $scope.toggleEditor = function(question) {
        if (!question.isEditorOpen) {
            $scope.application.questions.forEach(q => q.isEditorOpen = false);
        }

        $timeout(() => {
            question.isEditorOpen = !question.isEditorOpen;
        });
    }
};