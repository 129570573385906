import React from "react";
import { z } from "zod";
import usePathParams from "../../hooks/usePathParams";

export function withPathParams<$Schema extends z.AnyZodObject, T = unknown>(
  Component: React.ComponentType<T>,
  schema: $Schema
) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithPathParams = (props: T) => {
    const pathParams = usePathParams(schema);

    if (Object.keys(pathParams).length === 0) {
      return <></>;
    }

    return <Component pathParams={pathParams} {...props} />;
  };

  ComponentWithPathParams.displayName = `withPathParams(${displayName})`;

  return ComponentWithPathParams;
}
