//! @ngInject
export function vbpService($http, $rootScope, Consts, wildcard) { return {
  get(from, to) {
     
    return $http.get(wildcard(Consts.api + "agencies/:agencyId/vbp_charts", $rootScope.agencyId));
  },

  getPatients(questionId, answer) {
    return $http.get(
      wildcard(Consts.api + "agencies/:agencyId/vbp_items/:vbpItemId/answers/:answer/patients",
      $rootScope.agencyId,
      questionId,
      answer
    ));
  },

  getChartConfigByQuestion(question) {
    const BLUE_COLOR = "#46A8FF";
    const RED_COLOR = "#FF5684";
    const ORANGE_COLOR = "#FF9A31";
    const YELLOW_COLOR = "#FFC006";
    const GREEN_COLOR = "#3077db";

    return {
      type: "pie",
      data: {
        datasets: [
          {
            data: question.answers.map(answer => answer.count),
            backgroundColor: [
              BLUE_COLOR,
              RED_COLOR,
              ORANGE_COLOR,
              YELLOW_COLOR,
              GREEN_COLOR
            ],
            label: question.title
          }
        ],
        labels: question.answers.map(answer => answer.title)
      },
      options: {
        // responsive: false,
        maintainAspectRatio: false,
        defaultFontColor: "#FFFFFF",
        defaultFontFamily: "Open Sans",
        defaultFontSize: 13,
        legend: {
          padding: 30,
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontColor: "#53606C",
            fontFamily: "Open Sans",
            fontSize: 13
          }
        }
      }
    };
  }
}};
