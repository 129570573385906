//! @ngInject
export function trainingCenterEnrollmentsRequestsCtrl($scope, $rootScope, DatabaseApi, NgTableParams, SweetAlert, toaster) {
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.loadingData = true;

    const statusMapping = {
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        null: 'Pending'
    }

    function initialize() {
        if (Object.keys($scope.caregiversMap).length > 0) {
            $scope.loadItems();
        }
    }

    $scope.loadItems = function () {
        const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
            + "/caregiver_enrollments";

        DatabaseApi.get(url).then(function (res) {
            initTable(res.data.enrollments);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Failed to retrieve caregiver enrollments');
        });
    };

    function initTable(items) {
        $scope.enrollmentsRequests = items;

        $scope.enrollmentsRequests.forEach(enrollment => {
            enrollment.status = enrollment.status === null ? 'PENDING' : enrollment.status;
            enrollment.type = "Bundle Enroll Request";

            const caregiver = $scope.caregiversMap[enrollment.caregiverId];
            if (caregiver) {
                enrollment.caregiverName = caregiver.displayName;
            }
        });
        
        $scope.statistics = {
            caregiversEnrolled: [...new Set(
                $scope.enrollmentsRequests
                    .filter(row => row.status === 'approved')
                    .map(row => row.caregiverName)
            )].length
        };
        $scope.loadingData = false;
        const oldTotal = $scope.tableParams?.total?.() || 0;
        let page = false;
        let sorting = { createdAt: "desc" };
        let filters = undefined;

        const options = {
            count: 25,
            sorting: sorting
        };

        if ($scope.tableParams) {
            page = $scope.tableParams.page()
            sorting = $scope.tableParams.sorting();
            filters = $scope.tableParams.filter();
        }
        if (filters) options.filter = filters;

        $scope.tableParams = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: $scope.enrollmentsRequests
        });

        if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    }

    $scope.setRequestStatus = (enrollment, status) => {
        const statusText = statusMapping[status];
        SweetAlert.swal({
            title: 'Change request status?',
            text: `Are you sure you want to change request status to ${statusText}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Yes, Change',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
                    + "/caregiver_enrollments/" + enrollment.id;
                const body = {
                    status: status
                }

                DatabaseApi.put(url, body).then(function (res) {
                    toaster.pop('success', "Request status updated successfully");
                    enrollment.status = status === null ? 'PENDING' : status;
                }, function (err) {
                    toaster.pop('error', 'Something went wrong', 'Failed to update caregiver enrollment status');
                });
            }
        });
    };

    $scope.goToProfile = function (id) {
        $rootScope.openCaregiverModal(id);
    };

    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers();
        initialize();
    });

    initialize();
};