import React from "react";
import { webappChannel } from "../../core";
import { AngularJSPayload, AngularJSDispatch } from "../../core/webapp-channel";
import useStableCallback from "./useStableCallback";

type WebappChannelPayloadMessage = {
  type: "PAYLOAD_SET";
  key: keyof AngularJSPayload;
};

export function useWebappChannelPayloadEvent<
  $EventName extends WebappChannelPayloadMessage["key"]
>(params: { eventName: $EventName; runInitial: boolean; onEvent: () => void }) {
  const stableCallback = useStableCallback(params.onEvent);
  const listener = useStableCallback(
    (event: MessageEvent<WebappChannelPayloadMessage | WebappChannelDispatchMessage>) => {
      if (event.data.type === "PAYLOAD_SET" && event.data.key === params.eventName) {
        stableCallback();
      }
    }
  );

  React.useEffect(() => {
    if (params.runInitial) {
      stableCallback();
    }
  }, [stableCallback, params.runInitial]);

  React.useEffect(() => {
    webappChannel.channel.addEventListener("message", listener);
    return () => webappChannel.channel.removeEventListener("message", listener);
  }, [listener]);
}

type WebappChannelDispatchMessage<$Key extends keyof AngularJSDispatch = keyof AngularJSDispatch> =
  {
    type: "DISPATCH";
    key: $Key;
    payload: AngularJSDispatch[$Key];
  };

export function useWebappChanneDispatchEvent<$Key extends keyof AngularJSDispatch>(params: {
  eventName: $Key;
  onEvent: (payload: AngularJSDispatch[$Key]) => void;
}) {
  const stableCallback = useStableCallback(params.onEvent);
  const listener = useStableCallback(
    (event: MessageEvent<WebappChannelPayloadMessage | WebappChannelDispatchMessage<$Key>>) => {
      if (event.data.type === "DISPATCH" && event.data.key === params.eventName) {
        stableCallback(event.data.payload);
      }
    }
  );

  React.useEffect(() => {
    webappChannel.channel.addEventListener("message", listener);
    return () => webappChannel.channel.removeEventListener("message", listener);
  }, [listener]);
}
