import { BubbleDataPoint, Chart, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

class PieChartCtrl implements ng.IComponentController, Bindings {
  // --- Bindings --- //
  identifier!: string;
  entries!: PieChartEntry[];
  height?: number;
  labelMaxCharsPerLine?: number;
  onFilter?: () => (identifier: string, items: ChartLabel[]) => void;
  // --- END Bindings --- //

  private chart?: Chart<keyof ChartTypeRegistry, number[], string | string[]>;

  $onInit = () => {
    setTimeout(this.setChart, 0);
  };

  $onChanges = (data) => {
    if (Array.isArray(data.entries.previousValue) && this.chart !== undefined) {
      this.chart.destroy();
      this.setChart();
    }
  };

  setChart = () => {
    const canvas = document.getElementById(`pie-chart-${this.identifier}`);

    if (!(canvas instanceof HTMLCanvasElement)) {
      console.error("Canvas not found");
      return;
    }

    const ctx = canvas.getContext("2d");

    if (ctx === null) {
      console.error("Canvas context not found");
      return;
    }

    this.entries.sort((a, b) => b.value - a.value);

    this.chart = new Chart(ctx, {
      type: "pie",
      plugins: [ChartDataLabels],
      data: {
        datasets: [
          {
            data: this.entries.map((entry) => entry.value),
            backgroundColor: this.entries.map((entry) => entry.color),
          },
        ],
        labels: this.entries.map((entry) => this.breakLabel(entry.label)),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
            onClick: (e, item, legend) => {
              Chart.overrides.pie.plugins.legend.onClick.call(legend, e, item, legend);

              if (this.onFilter !== undefined && legend.legendItems !== undefined) {
                const items = legend.legendItems.map((item) => ({
                  label: parseLabel(item.text),
                  isHidden: item.hidden ?? false,
                }));

                this.onFilter()(this.identifier, items);
              }
            },
          },
          datalabels: {
            color: "white",
            font: {
              weight: "bold",
            },
            padding: 6,
            display: "auto",
            formatter: (value, context) => {
              const data = context.dataset.data;
              const sum = this.sum(data);

              if (typeof value === "number") {
                const percent = sum === 0 ? 0 : ((value / sum) * 100).toFixed(1);
                return `${percent}%`;
              }

              return `${value}`;
            },
          },
        },
      },
    });
  };

  private sum(data: (number | ScatterDataPoint | BubbleDataPoint | null)[]): number {
    let sum = 0;

    for (const value of data) {
      if (typeof value === "number") {
        sum += value;
      }
    }

    return sum;
  }

  private breakLabel = (label: string) => {
    if (this.labelMaxCharsPerLine === undefined) {
      return label;
    }

    const labelWithBreaks = label.replace(
      new RegExp(`(.{${this.labelMaxCharsPerLine}}[^ ]* )`, "g"),
      "$1\n"
    );

    return labelWithBreaks.split("\n");
  };
}

function parseLabel(value: string | string[]): string {
  return Array.isArray(value) ? value.join("") : value;
}

export enum PieChartColor {
  blue = "#46A8FF",
  red = "#FF5684",
  orange = "#FF9A31",
  yellow = "#FFC006",
  green = "#3077db",
  gray = "#555555",
}

export interface PieChartEntry {
  label: string;
  value: number;
  color: PieChartColor;
}

export interface ChartLabel {
  label: string;
  isHidden: boolean;
}

interface Bindings {
  identifier: string;
  entries: PieChartEntry[];
  height?: number;
  labelMaxCharsPerLine?: number;
  onFilter?: () => (identifier: string, items: ChartLabel[]) => void;
}

interface Component extends angular.IComponentOptions {
  $name: string;
  bindings: Record<keyof Bindings, string>;
}

export const pieChartComponent: Component = {
  $name: "pieChart",
  templateUrl: "admin/modules/shared/components/pie-chart/pie-chart.component.html",
  controller: PieChartCtrl,
  controllerAs: "ctrl",
  bindings: {
    identifier: "<",
    entries: "<",
    height: "<",
    labelMaxCharsPerLine: "<",
    onFilter: "&",
  },
};
