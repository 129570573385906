import $ from "jquery";

//! @ngInject
export function TimeZoneService() {
    const GOOGLE_MAPS_API_KEY = 'AIzaSyAcpv_xCANE3_P2EEqNmZL4LESMR_iAIik';

    this.getTimzone = async function (location) {

        const timestamp = Math.floor((new Date()).getTime() / 1000);
        let url = 'https://maps.googleapis.com/maps/api/timezone/json';
        url += '?location=' + location.lat + ',' + location.lng;
        url += '&timestamp=' + timestamp;
        url += '&key=' + GOOGLE_MAPS_API_KEY;
        return await $.ajax(url);
    }
}
