//! @ngInject
export function BillingChecksService($http, Consts, wildcard) { return {

    /**
     * Get a single check record by given id
     * @param {number} checkBatchId
     * @param {number} agencyId
     * @param {number} agencyMemberId
     */
    getCheckById: (checkBatchId, { agencyId, agencyMemberId }) => {
      return $http.get(
        wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/check_batches/:checkBatchId`,
          agencyId,
          agencyMemberId,
          checkBatchId
        )
      );
    },

    /**
     * Update details for multiple check lines
     * @param {object[]} updatedChecklines
     * @param {number} agencyId
     * @param {number} agencyMemberId
     */
    updateCheckLinesAmounts: (updatedChecklines, { agencyId, agencyMemberId }) => {
      return $http.put(
        wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/update_check_line_details`,
          agencyId,
          agencyMemberId
        ),
        {
          checkLines: updatedChecklines
        }
      );
    },

    removeCheckById: (checkBatchId, { agencyId, agencyMemberId }) => {
      return $http.delete(
        wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/check_batches/:checkId`,
          agencyId,
          agencyMemberId,
          checkBatchId
        )
      )
    },

    updateCheckById: ({ checkId, agencyId, agencyMemberId, ...paramsToUpdate }) => {
      return $http.put(
        wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/check_batches/:checkId`,
          agencyId,
          agencyMemberId,
          checkId
        ), {
          ...paramsToUpdate
        }
      )
    },

    removeCheckLineById: (checkLineId, { agencyId }) => {
      return $http.delete(
        wildcard(
          `${Consts.api}agencies/:agencyId/check_lines/:checkLineId`,
          agencyId,
          checkLineId
        )
      )
    }
  }};
