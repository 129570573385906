//! @ngInject
export function billingChecksCtrl(
    $scope,
    $rootScope,
    DatabaseApi,
    NgTableParams,
    itemSearchPageManager,
    $state,
    toaster,
    $uibModal,
    FilterUtils,
    $filter,
    SweetAlert,
    BillingChecksService
) {

    $scope.tableParams = null;
    $scope.globalFilter = { val: "" };

    function initialize() {
        $scope.offices = DatabaseApi.offices() || {};
        $scope.officesOptions = $scope.offices.map(office => ({ id: office.id, label: office.name }));
        initPageManager();
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingChecksCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingChecksCtrl = angular.copy(filters);
    });

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/check_batches");

        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initSearchParam("checkNumber", "", { placeholderValue: "", isEntity: true });
        $scope.pageManager.initSearchParam("filterByDateType", "CHECK_DATE", {}); // enum: 'CHECK_DATE' and 'DEPOSIT_DATE'
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
    };

    var mapItems = function (items) {
        items.forEach(function (item) {
            if (!item.contractTypeName && item.contractTypeId) {
                var contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractTypeId);
                item.contractTypeName = (contractType && contractType.name) || item.contractTypeId;
            }

            if (!item.officesNames && item.officesIds) {
                item.officesNames = $scope.offices.filter(officeItem => item.officesIds.includes(officeItem.id)).map(officeItem => officeItem.name).join(", ");
            }
        });
    };

    const initTable = (items) => {
        const oldTotal = $scope.tableParams?.total?.() || 0;
        if (!items) {
            toaster.pop("error", "Oops... Something went wrong");
            return;
        }
        if (!items.length) {
            return;
        }
        mapItems(items);

        let page = false;
        let sorting = {};
        let count = 25;
        if ($scope.tableParams) {
            page = $scope.tableParams.page();
            sorting = $scope.tableParams.sorting();
            count = $scope.tableParams.count();
        }

        const options = {
            sorting: sorting,
            count: count
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
        if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    };

    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        }
    };

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            $scope.allChecks = [...response.data.checkBatches];
            initTable(response.data.checkBatches);
        }, function (error) {
            toaster.pop("error", "Failed to load check batches");
        });
    };

    $scope.clickTableRow = function (item) {
        if (!item) return;
        $state.go('app.billing.check', { id: item.id, check: item });
    };

    $scope.openAddNewCheckModal = () => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/billing-new-manual-check-modal.html',
            size: 'md',
            controller: 'billingNewManualCheckModalCtrl',
            windowTopClass: "billing-new-manual-check-modal"
        });
        modalInstance.result.then(function (response) {
            if (response) {
                if (response.isError) {
                    toaster.pop("error", response.message);
                    $scope.isDataLoaded = true;
                }
                else {
                    toaster.pop("success", "New check added successfully");
                    $state.go('app.billing.check', { id: response.data.id, check: response.data })
                }
            }
            else {
                $scope.isDataLoaded = true;
            }
        }, function (rejection) {
            if (rejection !== "backdrop click") {
                toaster.pop("error", "Failed to open modal to add new check");
            }
            $scope.isDataLoaded = true;
        });
    }

    $scope.goToEdiFileUploader = function () {
        var controlData = {
            previousPageUrl: "app.billing.checks",

            // temporary implementation for the 'createDataToUpload' method
            createDataToUpload: function (scope) {
                var formData = new FormData();
                formData.append("file", scope.file, scope.file.name);
                return formData;
            },

            uploadPostUrl: "agencies/" + $rootScope.agencyId + "/upload_export_response",
            // historyGetUrl: "agencies/" + $rootScope.agencyId + "/table_file_upload",
            fileTypeName: "plain-text",
            // requiredInfoFields: [ "Name", "Status" ],
            caption: "Upload EDI files",
            subcaption: "Please make sure your EDI file contains the required information",
            css: "billing-page",
        };
        $state.go('app.billing.uploadEdi', { data: controlData });
    };

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        var rows = [];
        var titles = [
            'Check No.',
            'Contract',
            'Offices',
            'Check amount',
            'Applied amount',
            'Applied positive',
            'Applied negative',
            'Unapplied amount',
            'Unapplied positive',
            'Unapplied negative',
            'Visit Count',
            'Check Date',
            'Deposit Date',
            'Notes',
            'File URL'
        ];
        rows.push(titles);

        // Filter table data by global filter
        const allChecks = $filter('filter')($scope.allChecks, $scope.globalFilter.val);
        allChecks.forEach(dataRow => {
            var csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Check No.":
                        toPush = dataRow.checkNumber || '';
                        break;
                    case "Contract":
                        toPush = dataRow.contractTypeName ? dataRow.contractTypeName : '';
                        break;
                    case "Offices":
                        toPush = dataRow.officesNames ? dataRow.officesNames : '';
                        break;
                    case "Check amount":
                        toPush = $filter("centsToDollars")(dataRow.amount);
                        break;
                    case "Applied amount":
                        toPush = $filter("centsToDollars")(dataRow.appliedAmount);
                        break;
                    case "Applied positive":
                        toPush = $filter("centsToDollars")(dataRow.appliedPositiveAmount);
                        break;
                    case "Applied negative":
                        toPush = $filter("centsToDollars")(dataRow.appliedNegativeAmount);
                        break;
                    case "Unapplied amount":
                        toPush = $filter("centsToDollars")(dataRow.unappliedAmount);
                        break;
                    case "Unapplied positive":
                        toPush = $filter("centsToDollars")(dataRow.unappliedPositiveAmount);
                        break;
                    case "Unapplied negative":
                        toPush = $filter("centsToDollars")(dataRow.unappliedNegativeAmount);
                        break;
                    case "Visit Count":
                        toPush = dataRow.visitsCount;
                        break;
                    case "Check Date":
                        toPush = $filter("mfShortTime")(dataRow.checkDate, ['withDate']) || '';
                        break;
                    case "Deposit Date":
                        toPush = $filter("mfShortTime")(dataRow.depositDate, ['withDate']) || '';
                        break;
                    case "Notes":
                        toPush = dataRow.notes ? dataRow.notes : '';
                        break;
                    case "File URL":
                        toPush = dataRow.fileUrl || '';
                        break;
                    default:
                        break;
                }
                csvRow.push("\"" + toPush + "\"");
            });

            rows.push(csvRow);
        });

        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    var getExportedFileName = function () {
        var filename = "medflyt-export-batches"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.removeCheckBatch = (checkId) => {
        SweetAlert.swal({
            title: 'Remove check',
            text: 'Are you sure you want to remove this check?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Yes, Remove',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                BillingChecksService.removeCheckById(checkId, {
                    agencyId: $rootScope.agencyId,
                    agencyMemberId: $rootScope.agencyMemberId
                }).then(() => {
                    toaster.pop("success", "Check removed successfully");
                    $scope.loadItems();
                }, (err) => {
                    toaster.pop("warning", "something went wrong", "Check wasn't removed");
                });
            }
        });
    }

    $scope.filterByCheckDate = () => {
        $scope.pageManager.searchParams.filterByDateType.value = 'CHECK_DATE';
    };

    $scope.filterByDepositDate = () => {
        $scope.pageManager.searchParams.filterByDateType.value = 'DEPOSIT_DATE';
    };

    $scope.isFilterByCheckDate = () => {
        return $scope.pageManager.searchParams.filterByDateType.value === 'CHECK_DATE';
    };

    $scope.isFilterByDepositDate = () => {
        return $scope.pageManager.searchParams.filterByDateType.value === 'DEPOSIT_DATE';
    };

    $scope.fromLabelText = () => {
        return $scope.isFilterByCheckDate() ? 'Check From' : 'Deposit From';
    };

    $scope.toLabelText = () => {
        return $scope.isFilterByCheckDate() ? 'Check To' : 'Deposit To';
    };

    initialize();
};