export const reportToolContent = {
  templateUrl: "admin/views/report-tool-content.html",
  bindings: {
    loadingReport: "<",
    reportName: "=",
    allowEmpty: "=",
    columns: "<",
    tables: "<",
    primaryTable: "=",
    filters: "<",
    onSaveClicked: "&",
    onFilterClone: "&",
    columnInAggFilters: "&",  
    onFilterClicked: "&",
    onColumnClicked: "&",
    onRemoveFilter: "&",
    onRunClicked: "&",
    onClearClicked: "&",
    gridOptions: "<",
    onFilterDescriptionClick: "&",
    accessType: "<"
  },
  //! @ngInject
  controller: function($rootScope,$filter,uiGridExporterConstants) {
    var that = this;
    this.sortableOptions = {
      handle: '> .myHandle',
      stop: function(e,ui){
                        
      that.onColumnClicked()
      }
    }
    this.hideValueOperators = ['IS NULL', 'NOT IS NULL'];
    this.altInputFormats = ['MM/dd/yyyy'];
    this.datePickerOptions = {
      locale: {
        format: "MM/DD/YYYY",
      },
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };
    this.timePeriodOptions = ["DAY", "WEEK", "MONTH", "YEAR"];
    this.timePeriodIntervalOptions = ["DAYS", "WEEKS", "MONTHS", "YEARS"];
    this.timePeriodOperators = ["LAST X", "THIS X", "NEXT X", "LAST N X", "NEXT N X"];
    this.timePeriodIntervalOperators = ["LAST N X", "NEXT N X"];
    this.groupFields = ["DAY","MONTH","WEEK","YEAR"]
    this.filterInGroupFields = (arr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i].aggregateType;

        if (this.groupFields.indexOf(item) !== -1) return true;

      }
      return false;
    }
    this.filterGroupFields = (item)=>{
      if (this.groupFields.indexOf(item.aggregateType) !== -1) return false;
      return true
    }
    this.filterNotGroupFields = (item)=>{
      if (this.groupFields.indexOf(item.aggregateType) !== -1) return true;
      return false
    }
    this.handleSaveClick = () => {
      this.onSaveClicked();
    }
    this.handleRunClick = (format) => {
      if(!format){
        format = "json";
      }

      this.onRunClicked({format});
    }
    this.handleFilterClone = (filter) => {
      this.onFilterClone({
        filter
      });
    }
    this.handleRemoveFilter = (index, tableName, columnName) => {
      this.onRemoveFilter({
        index,
        tableName,
        columnName
      });
    }
    this.handleClearReport = () => {
      this.onClearClicked();
    }

    this.handleFilterClicked = (filter) => {


      const tableName = filter.tableName;


      this.onFilterClicked({
        tableName,
        filter
      })
    }
    this.handleColumnInAggFilters = (column) => {

      const tableName = column.tableName;


      return this.columnInAggFilters({
        tableName,
        column
      });
    }
    this.handleTimePeriodChange = (filter) => {
      if (this.isTimePeriodValid(filter)) {
        filter.value = `${filter.timePeriodValue.interval} ${filter.timePeriodValue.timePeriod}`;
      } else {
        filter.value = "";
      }
    }
    const getFileName = (filename) => {
      return `medflyt-report-${(filename ? filename + "-" : '')}${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }
    this.export = (format) => {
      if (format == 'csv') {
        var myElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));
        var rowTypes = uiGridExporterConstants.ALL;
        var colTypes = uiGridExporterConstants.ALL;
        // this.gridOptions.exporterCsvFilename = getFileName(this.reportName);
        $rootScope.gridApi.exporter.csvExport(rowTypes,colTypes,myElement );
      } else if (format == 'xlsx') {

        var rowTypes = uiGridExporterConstants.ALL;
        var colTypes = uiGridExporterConstants.ALL;
        $rootScope.gridApi.exporter.excelExport(rowTypes,colTypes );
      };
    };
    this.isTimePeriodValid = (filter) => {
      return (filter.timePeriodValue.interval !== undefined && filter.timePeriodValue.interval !== null &&
        filter.timePeriodValue.timePeriod !== undefined && filter.timePeriodValue.timePeriod !== "")
    }
    this.handleFilterDescriptionClick = () => {
      this.onFilterDescriptionClick();
    }
  },
};
