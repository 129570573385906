import moment from "moment";

//! @ngInject
export function mySavedReportsCtrl($scope, $rootScope, $state, $uibModal, $filter, NgTableParams, reportService, toaster, SweetAlert) {
    
    function initialize() {
        loadItems();
    }

    const loadItems = function () {
        reportService.getMySavedReports().then(res => {
            $scope.savedReports = res.map(report => ({
                ...report, 
                loadingReport: false,
                lastUpdatedAt: report.updatedAt ?? report.createdAt
            }));
            initTable($scope.savedReports);
        });
    }

    const initTable = function (items) {
        const options = {
            count: 25,
        };

        $scope.tableParams = new NgTableParams(options, {
            dataset: items,
        });
    };

    $scope.clickTableRow = (savedReportId) => {
        $state.go('app.reports.reportTool', { savedReportId: savedReportId });
    }

    $scope.handleNewReport = () => {
        const mySavedReportsNames = $scope.savedReports.map(report => report.name);
        $state.go('app.reports.reportTool', { mySavedReportsNames });
    }

    var universalBOM = "\uFEFF";

    $scope.handleRunReport = (savedReport) => {
        const reportIndex = $scope.savedReports.findIndex(report => report.id === savedReport.id);
        $scope.savedReports[reportIndex].loadingReport = true;

        reportService.executeSavedReport(savedReport.id, moment.tz.guess(true)).then(({ lines }) => {
            let csvContent = "";
            lines.forEach(function (row) {
                csvContent += row + "\r\n";
            });
            const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", getFileName(savedReport.name));
            document.body.appendChild(link);

            link.click();
                toaster.pop("success", "Success", "Successfully created report");
            }).catch(() => {
                toaster.pop("error", "Something went wrong", "Failed to create report");
            }).finally(() => {
                $scope.savedReports[reportIndex].loadingReport = false;
            });
    }

    $scope.handleManageAccess = (savedReport) => {
        const newScope = $scope.$new();
        newScope.savedReport = savedReport;
        $rootScope.openSavedReportPermissionsModal(newScope);
    }

    $scope.handleRemoveReport = (savedReportId) => {
        SweetAlert.swal({
            title: "Remove report",
            text: `When you remove the report, all the agency members will no longer be able to see it
                   Are you sure you want to remove this report?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, (isConfirm) => {
            if (isConfirm) {
                reportService.removeSavedReport(savedReportId).then(res => {
                    toaster.pop(
                    "success",
                    "Success",
                    "Report removed"
                    );
                    loadItems();
                }).catch(err => {
                    toaster.pop(
                    "error",
                    "Something went wrong",
                    "Failed to remove report");
                });
            }
        });
    }

    const getFileName = (filename) => {
        return `medflyt-report-${(filename ? filename + "-" : '')}${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }

    $scope.handleOpenReportEntityPermissionsModal = () => {
        $rootScope.openReportEntityPermissionsModal();
    }

    $scope.handleOpenScheduledReport = function (id, name) {
        const newScope = $scope.$new();
        newScope.savedReportId = id;
        newScope.savedReportName = name;

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/scheduled-reports-modal.html',
            size: 'md',
            controller: 'scheduledReportsModalCtrl',
            scope: newScope,
            windowClass: 'modal modal-slide-in-right scheduled-reports-container',
            backdrop: 'static',
            keyboard : false
        });

        return modalInstance;
    };

    initialize();

};