/*! @ngInject */
export class Api {
  constructor(private $http: ng.IHttpService, private $q: ng.IQService, private Consts: any) {}

  get = <Data>(route: string) => {
    return this.$q<ng.IHttpResponse<Data>>((resolve, reject) =>
      this.$http
        .get<Data>(this.Consts.api + route)
        .then(resolve)
        .catch(reject)
    );
  };

  post = <Body>(route: string, body?: unknown) => {
    return this.$q<ng.IHttpResponse<Body>>((resolve, reject) =>
      this.$http
        .post<Body>(this.Consts.api + route, body)
        .then(resolve)
        .catch(reject)
    );
  };

  put = <Body>(route: string, body?: unknown) => {
    return this.$q<ng.IHttpResponse<Body>>((resolve, reject) =>
      this.$http
        .put<Body>(this.Consts.api + route, body)
        .then(resolve)
        .catch(reject)
    );
  };

  patch = <Body>(route: string, body?: unknown) => {
    return this.$q<ng.IHttpResponse<Body>>((resolve, reject) =>
      this.$http
        .patch<Body>(this.Consts.api + route, body)
        .then(resolve)
        .catch(reject)
    );
  };

  delete = <Body>(route: string) => {
    return this.$q<ng.IHttpResponse<Body>>((resolve, reject) =>
      this.$http
        .delete<Body>(this.Consts.api + route)
        .then(resolve)
        .catch(reject)
    );
  };
}
