import { LocalDate, DateTimeFormatter, nativeJs } from "js-joda";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

//! @ngInject
export function eTimeSheetApprovalCtrl(
  $rootScope,
  $scope,
  NgTableParams,
  DatabaseApi,
  generalUtils,
  selectionLogic,
  toaster,
  itemSearchPageManager,
  officesService,
  Storage,
  $filter,
  FilterUtils,
  mfModal,
  timesheetsService,
  base64ImageConsts,
  $uibModal,
) {
  const initialize = () => {
    initAssets();
    initOffices();
    initCoordinators();
    initTeams();
    initStatuses();
    if (
      !$scope.initializeMap['patients'] ||
      !$scope.initializeMap['caregivers'] ||
      !$scope.gotOffices
    ) {
      return;
    }

    $scope.filters = {
      offices: [],
      coordinators: [],
      teams: [],
      statuses: [{ id: "PENDING" }],
    };

    initTableColumns();
    initPageManager();

    // Load filters
    let storageFilters;
    storageFilters = FilterUtils.rootFilters.eTimeSheetApprovalCtrl;

    if (storageFilters !== undefined) {
      $scope.pageManager.setSearchParams(storageFilters);
    }

    $scope.loadItems();
  };

  const initAssets = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.patientsMap = DatabaseApi.patients() || {};
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
  };

  $scope.$on("$destroy", () => {
    if ($scope.pageManager) {
      const filters = $scope.pageManager.searchParams;
      FilterUtils.rootFilters.eTimeSheetApprovalCtrl = angular.copy(filters);
    }
  });

  const initTableColumns = () => {
    const columns = Storage.getObject("timesheetApprovalCallsTableSettings");
    if (columns && Object.keys(columns).length) {
      $scope.tableColumns = columns;

      if ($scope.tableColumns["Status"] === undefined) {
        $scope.tableColumns["Status"] = true
      }
    } else {
      $scope.tableColumns = {
        "ID": false,
        "Patient": true,
        "Caregiver": true,
        "Visit Date": true,
        "Schedule Time": true,
        "Clock Time": true,
        "Submitted Time": true,
        "Duties": true,
        "Caregiver Signature": true,
        "Patient Signature": true,
        "Status": true,
      };
    }

    $scope.$watch("tableColumns", () => {
        if ($scope.tableColumns) {
          Storage.setObject("timesheetApprovalCallsTableSettings", $scope.tableColumns);
        }
      },
      true
    );
  };

  const initOffices = () => {
    if ($scope.gotOffices) {
      return;
    }
    $scope.officesExtraSettings = {
      displayProp: "name",
      styleActive: true,
      scrollable: true,
      scrollableHeight: "300px",
      enableSearch: true
    };
    if (!officesService.offices) {
      officesService.getOffices().then((offices) => {
        $scope.offices = offices;
        $scope.gotOffices = true;
        initialize();
      });
    } else {
      $scope.offices = officesService.offices
      $scope.gotOffices = true;
    }
  };

  const initTeams = () => {
    $scope.teamsExtraSettings = {
      displayProp: "name",
      styleActive: true,
      scrollable: true,
      scrollableHeight: "300px",
      enableSearch: true
    };
  };

  const initCoordinators = async () => {
    if (Array.isArray($scope.coordinators) && $scope.coordinators.length > 0) {
      return;
    }
    $scope.coordinatorsDropdownOptions = {
      enableSearch: true,
      displayProp: "displayName",
      styleActive: true,
      scrollable: true,
    };

    initAgencyMembers();
  };

  const initAgencyMembers = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    const agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole();
    if (!$scope.initializeMap['agencyMembers']) {
      $scope.coordinators = [];
      return DatabaseApi.loadAgencyMembers();
    }
    $scope.coordinators = [
      ...agencyMembersByRoles.coordinatorsSeniors,
      ...agencyMembersByRoles.coordinators,
      ...agencyMembersByRoles.admins,
    ].filter(coordinator => coordinator.hasCasesNotDischarged).sort(generalUtils.sortByDisplayName);
  };

  const initStatuses = () => {
    if (Array.isArray($scope.timesheetStatuses) && $scope.timesheetStatuses.length > 0) {
      return;
    }
    $scope.timesheetStatuses = [
      { id: "PENDING", label: "Pending", actionLabel: null, statusClass: "orange", btnClass: 'warning', singleActionCallback: null, bulkActionCallback: null },
      { id: "APPROVED", label: "Approved", actionLabel: "Approve", statusClass: "green", btnClass: 'primary', singleActionCallback: approveSingleTimesheet, bulkActionCallback: approveSelectedTimesheets },
      { id: "DECLINED", label: "Declined", actionLabel: "Decline", statusClass: "red", btnClass: 'danger', singleActionCallback: declineSingleTimesheet, bulkActionCallback: declineSelectedTimesheets },
    ];
    $scope.timesheetStatusesMap = {};
    $scope.timesheetStatuses.forEach(status => {
      $scope.timesheetStatusesMap[status.id] = status;
    });

    $scope.statusesExtraSettings = {
      styleActive: true,
      smartButtonMaxItems: 3,
    };
  };

  const initSelection = (items) => {
    $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
      $scope.selectionLogic.addItemToCollection(item);
    }, "timesheetId");

    if (items) {
      items.forEach((item) => {
        $scope.selectionLogic.initItem(item);
      });
    }
  };

  const initPageManager = () => {
    $scope.pageManager = itemSearchPageManager.createSearchPageManager("/time_sheet_approval" );

    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
    $scope.teamDataManager = $scope.pageManager.getTeamDataManager();

    $scope.pageManager.initFromToDateParams();
    $scope.pageManager.initOfficeParam();
    $scope.pageManager.initTeamParam();
    $scope.pageManager.initCoordinatorParam();
    $scope.pageManager.initSearchParam("selectedStatuses", $scope.filters.statuses, {
      queryName: "statuses",
      toQueryConverter: (statuses) => $filter("pipeItems")(statuses, "id"),
      placeholderValue: [],
    });
    $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().minusDays(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    $scope.pageManager.initSearchParam("dateByTimesheet", true, {
      placeholderValue: true,
      queryName: "dateByTimesheet",
    });

    $scope.initialQueryRecords = [];
    $scope.filteredQueryRecords = [];
  };

  const populateTable = (items) => {
    const oldTotal = $scope.tableParams?.total?.() || 0;
    const defaultSorting = { createdAt: "desc" };
    const sorting = $scope.tableParams
      ? $scope.tableParams.sorting()
      : defaultSorting;
    const page = $scope.tableParams ? $scope.tableParams.page() : null;
    const options = {
      count: 25,
      sorting,
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
    if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    if ($scope.globalFilter) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
    initSelection(items);
  }

  $scope.loadItems = () => {
    $scope.pageManager
      .executeSearch()
      .then(onSuccessLoadItems)
      .catch(onErrorLoadItems);
  };

  const approveSingleTimesheet = (row) => {
    sendApproveRequest([{
      timesheetId: row.timesheetId,
      caregiverId: row.caregiver.id,
      patientId: row.patient.id,
      visitInstanceId: row.visitInstanceId
    }]);
  };

  const declineSingleTimesheet = (row) => {
    sendDeclineRequest([{
      timesheetId: row.timesheetId,
      caregiverId: row.caregiver.id,
      patientId: row.patient.id,
      visitInstanceId: row.visitInstanceId
    }]);
  };

  const getSelectedTimesheetsForAction = () => {
    const selectedItems = $scope.selectionLogic.getSelectedItems();
    if (selectedItems.length === 0) {
      return { errorMessage: "Please select items" };
    }
    if (selectedItems.find(item => item.status !== "PENDING")) {
      return { errorMessage: "Please select only pending items" };
    }
    return selectedItems.map((item) => ({
      timesheetId: item.timesheetId,
      caregiverId: item.caregiver.id,
      patientId: item.patient.id,
      visitInstanceId: item.visitInstanceId
    }));
  };
  
  const approveSelectedTimesheets = () => {
    const timesheets = getSelectedTimesheetsForAction();
    if (timesheets.errorMessage) {
      return mfModal.createSimple({
        variant: "danger",
        subject: "Error",
        message: timesheets.errorMessage
      });
    }
    sendApproveRequest(timesheets);
  };

  const declineSelectedTimesheets = () => {
    const timesheets = getSelectedTimesheetsForAction();
    if (timesheets.errorMessage) {
      return mfModal.createSimple({
        variant: "danger",
        subject: "Error",
        message: timesheets.errorMessage
      });
    }
    sendDeclineRequest(timesheets);
  };

  const openChooseCaregiversModal = () => {
    const modalInsatnce = $uibModal.open({
      templateUrl: "admin/views/timesheet-export-choose-caregivers-modal.html",
      size: "md",
      controller: "timesheetExportChooseCaregiversModalCtrl",
      resolve: {
        caregiverIds: () => $scope.initialQueryRecords.map(row => row.caregiverId)
      }
    });
    return modalInsatnce.result;
  };

  $scope.exportTable = (exportType) => {
    if (!$scope.tableParams || !$scope.tableParams.data || !$scope.tableParams.data.length || $scope.loadingExport) {
      return;
    }
    try {
      $scope.loadingExport = true;
      if (exportType === 'CSV') {
        exportTableAsCSV();
      } else if (exportType === 'PDF') {
        openChooseCaregiversModal().then(result => {
          if (result.chooseType && result.caregiverIds !== undefined) {
            $scope.pdfFilters.chooseType = result.chooseType
            $scope.pdfFilters.caregiverIds = result.caregiverIds;
            exportTableAsPDF();
          } else {
            $scope.loadingExport = false;
          }
        });
      }
    }
    catch (e) {
      toaster.pop("error", "Failed to export table", "Please try again");
      $scope.loadingExport = false;
      throw e;
    }
  };

  const getTextWidth = (doc, text) => {
    return doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  }

  const getJSPDFCenterOffset = (doc, text) => {
    const textWidth = getTextWidth(doc, text);
    return (doc.internal.pageSize.width - textWidth) / 2;
  }

  const getPDFTitle = () => {
    const getDatesPart = () => {
      const fetchDateType = $scope.pdfFilters.dateByTimesheet.value ? "Submitting" : "Visits";
      const fromDateFormatted = $filter("mfShortDate")($scope.pdfFilters.from.value);
      const toDateFormatted = $filter("mfShortDate")($scope.pdfFilters.to.value);
      return `for ${fetchDateType} dates: ${fromDateFormatted}-${toDateFormatted}`;
    };
    const getCaregiversPart = () => {
      if (
        $scope.pdfFilters.chooseType === "ALL_CAREGIVERS" ||
        !Array.isArray($scope.pdfFilters.caregiverIds) ||
        $scope.pdfFilters.caregiverIds.length === 0
      ) {
        return "";
      }
      const caregiverNamesArr = $scope.pdfFilters.caregiverIds.map(id => {
        const caregiver = $scope.caregiversMap[id];
        const displayId = caregiver.displayId ? ` (${caregiver.displayId})` : "";
        return caregiver.displayName + displayId;
      });
      const caregiverNames = caregiverNamesArr.join(", ");
      const s = caregiverNamesArr.length > 1 ? "s" : "";
      return `Caregiver Name${s}: ${caregiverNames}`;
    };
    const getTitlePart = ({displayName, pdfFiltersField, isEs, disallowLength3, idToNameCb}) => {
      if (
        !$scope.pdfFilters[pdfFiltersField] ||
        !Array.isArray($scope.pdfFilters[pdfFiltersField].value) ||
        $scope.pdfFilters[pdfFiltersField].value.length === 0 ||
        ($scope.pdfFilters[pdfFiltersField].value.length === 3 && disallowLength3)
      ) {
        return "";
      }
      const namesArr = $scope.pdfFilters[pdfFiltersField].value.map(({id}) => idToNameCb(id));
      const names = namesArr.join(", ");
      const e = isEs ? "e" : "";
      const s = namesArr.length > 1 ? `${e}s` : "";
      return `${displayName}${s}: ${names}`;
    };
    const datesPart = getDatesPart();
    const caregiverPart = getCaregiversPart();
    const officesPart = getTitlePart({displayName: "Office", pdfFiltersField: "selectedOffice", idToNameCb: (id) => $scope.offices.find(office => office.id === id).name});
    const teamsPart = getTitlePart({displayName: "Team", pdfFiltersField: "selectedTeams", idToNameCb: (id) => $scope.teamDataManager.getTeamById(id).name});
    const coordinatorsPart = getTitlePart({displayName: "Coordinator", pdfFiltersField: "selectedCoordinators", idToNameCb: (id) => $scope.coordinators.find(x => x.id === id).displayName});
    const statusesPart = getTitlePart({displayName: "Status", pdfFiltersField: "selectedStatuses", isEs: true, disallowLength3: true, idToNameCb: (id) => id});
    const optionalFiltersParts = [
      caregiverPart,
      officesPart,
      teamsPart,
      coordinatorsPart,
      statusesPart
    ].filter(x => x !== "").join(", ");
    const filtersPart = [datesPart, optionalFiltersParts].filter(x => x !== "").join(" for ");
    return `Visit and Time Sheet Information ${filtersPart}`;
  };

  const getPDFCols = () => {
    const cols = [{
      patientName: "Patient Name",
      patientDisplayId: "Patient Display ID",
      patientOffice: "Patient Office",
      caregiverName: "Caregiver Name",
      caregiverDisplayId: "Caregiver Display ID",
      visitDate: "Visit Date",
      scheduledTime: "Scheduled Time",
      clockedTime: "Clocked Time",
      duties: "Duties",
      caregiverSignature: "Caregiver Signature",
      patientSignature: "Patient Signature",
      submittedTime: "Submitted Time",
      submissionStatus: "Submission Status",
    }];
    if (
      $scope.pdfFilters.chooseType === "SPECIFIC" &&
      Array.isArray($scope.pdfFilters.caregiverIds) &&
      $scope.pdfFilters.caregiverIds.length === 1
    ) {
      delete cols[0].caregiverName;
      delete cols[0].caregiverDisplayId;
    }
    return cols;
  };

  const exportTableAsPDF = () => {
    let doc = new jsPDF('l');
    const medflytLogoBase64 = base64ImageConsts.medflytLogo;
    const agencyNameText = $rootScope.user.agency.name;
    const agencyAddressText = $rootScope.user.agency.address;
    const titleText = getPDFTitle();
    const fileDate = LocalDate.from(nativeJs(moment(new Date())));

    const cols = getPDFCols();
    const rows = [];
    const rowsSignatures = [];
    const rowsToExport = getFilteredRecords().sort((a, b) => a.caregiverId > b.caregiverId ? 1 : -1);
    rowsToExport.forEach(dataRow => {
      const pdfRow = Object.values(cols[0]).map(title => getExportedValueByRowAndTitle(dataRow, title));

      rowsSignatures.push({
        caregiverSignature: dataRow.caregiverSignature,
        patientSignature: dataRow.patientSignature
      });
      rows.push(pdfRow);
    });

    const didDrawPage = (data) => {
      const middleX = (doc.internal.pageSize.width / 2);
      const pageSize = doc.internal.pageSize;
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.addImage(medflytLogoBase64, "JPEG", middleX - 16, 2, 32, 8);
      doc.setFontSize(10);
      doc.text(agencyNameText, getJSPDFCenterOffset(doc, agencyNameText), 16);
      doc.text(agencyAddressText, getJSPDFCenterOffset(doc, agencyAddressText), 22);
      doc.line(middleX - 100, 24, middleX + 100, 24);
      doc.setFontSize(12);
      doc.text(doc.splitTextToSize(titleText, pageSize.width - 32), 16, 30);
    };

    const didDrawCell = (data) => {
      if (
        data.section === "body" &&
        data.row &&
        data.row.index >= 0 &&
        ["caregiverSignature", "patientSignature"].includes(data.column.dataKey)
      ) {
        const signatureBase64 = rowsSignatures[data.row.index][data.column.dataKey];
        if (signatureBase64) {
          doc.addImage(signatureBase64, 'JPEG', data.cell.x + 2, data.cell.y + 2, 10, 10);
        }
      }
    }

    autoTable(doc, {
      head: cols,
      body: rows,
      didDrawPage: didDrawPage,
      didDrawCell: didDrawCell,
      horizontalPageBreak: true,
      margin: { top: 42, bottom: 16 }
    });

    const drawFooters = () => {
      const middleX = (doc.internal.pageSize.width / 2);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      const formattedDate = $filter("mfShortDate")(fileDate);
      const pageCount = doc.internal.getNumberOfPages();
      const footerLeftText = (pageIdx) => `Page ${pageIdx}/${pageCount}, created - ${formattedDate}`;
      const footerRightText = "MedFlyt, L.L.C";
      for (let pageIdx = 1; pageIdx <= pageCount; pageIdx++) {
        doc.setPage(pageIdx);
        doc.setFontSize(10);
        doc.text(footerLeftText(pageIdx), middleX - 100, pageHeight - 6);
        doc.text(footerRightText, middleX + 100 - getTextWidth(doc, footerRightText), pageHeight - 6);
        doc.line(middleX - 100, pageHeight - 5, middleX + 100, pageHeight - 4);
      }
    };
    drawFooters();

    doc = doc.output('arraybuffer');
    const fileName = titleText +  " - " + fileDate.toString() + ".pdf";
    const blob = new Blob([doc], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    $scope.loadingExport = false;
  };

  const exportTableAsCSV = () => {
    const rows = [];
    const titles = [
      'Timesheet ID',
      'Patient Name',
      'Patient Display ID',
      'Patient Office',
      'Caregiver Name',
      'Caregiver Display ID',
      'Visit Date',
      'Submitted Time',
      'Scheduled Time',
      'Clocked Time',
      'Duties',
      'Submission Status',
    ];

    rows.push(titles);
    $scope.tableParams.data.forEach(dataRow => {
      const csvRow = titles.map(title => getExportedValueByRowAndTitle(dataRow, title));
      rows.push(csvRow);
    });

    let csvContent = "";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const universalBOM = "\uFEFF";
    const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", getExportedFileName());
    document.body.appendChild(link);

    link.click();
    $scope.loadingExport = false;
  };

  const getExportedValueByRowAndTitle = (row, title) => {
    if (title === 'Timesheet ID') return (row.timesheetId || '');
    else if (title === 'Patient Name') return (row.patient.fullName || '');
    else if (title === 'Patient Display ID') return (row.patient.displayId || '');
    else if (title === 'Patient Office') return (row.patient.currentOfficeName || '');
    else if (title === 'Caregiver Name') return (row.caregiver.fullName || '');
    else if (title === 'Caregiver Display ID') return (row.caregiver.displayId || '');
    else if (title === 'Visit Date') return ($filter("mfShortDate")(row.visitDate) || '');
    else if (title === 'Submitted Time') return ($filter("mfShortTime")(row.submittedAt, ['withDate']) || '');
    else if (title === 'Scheduled Time') return ($filter("mfShortTime")(row.ScheduleStartTime) + "-" + $filter("mfShortTime")(row.ScheduleEndTime) || '');
    else if (title === 'Clocked Time') {
      const clockInTime = row.clockInTime ? $filter("mfShortTime")(row.clockInTime) : '';
      const clockOutTime = row.clockOutTime ? $filter("mfShortTime")(row.clockOutTime) : '';
      return ((clockInTime !== '' || clockOutTime !== '') ? (clockInTime + "-" + clockOutTime) : '');
    }
    else if (title === 'Duties') {
      if (row.selectedDuties && row.selectedDuties.length > 0) {
        return row.selectedDuties.map(duty => duty.code).join(", ");
      }
      return "";
    }
    else if (title === 'Submission Status') return (row.status || '');
    else if (title === 'Caregiver Signature') return "";
    else if (title === 'Patient Signature') return "";

    return "";
  };

  const getExportedFileName = () => {
    const fromDate = $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd");
    const toDate = $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd");
    return `timesheet-approval-${fromDate}-to-${toDate}.csv`;
  };

  const sendApproveRequest = (timesheets) => {
    timesheetsService.editTimesheetsStatus({
      timesheets: timesheets,
      onSuccess: (res) => onSuccessAction(res, "approved"),
      onCatch: (err) => onFailAction(err, "approve"),
      onFinally: () => $scope.isProcessing = false
    }, "APPROVED");
  };

  const sendDeclineRequest = (timesheets) => {
    timesheetsService.editTimesheetsStatus({
      timesheets: timesheets,
      onSuccess: (res) => onSuccessAction(res, "declined"),
      onCatch: (err) => onFailAction(err, "decline"),
      onFinally: () => $scope.isProcessing = false
    }, "DECLINED");
  };

  const onSuccessLoadItems = (response) => {
    $scope.initialQueryRecords = response.data.timeSheets.map(parseRecord.bind(this))
      .filter(record => record.caregiver !== undefined);
    $scope.pdfFilters = angular.copy($scope.pageManager.searchParams);

    populateTable($scope.initialQueryRecords);
    $scope.onFiltersChange();
    $scope.loadingExport = false;
  };

  const onErrorLoadItems = (error) => {
    toaster.pop("error", "Failed to load time sheets");

    throw error;
  };

  const onSuccessAction = (response, strActed) => {
    toaster.pop("success", "Success", `Successfully ${strActed}`);
    $scope.loadItems();
  };

  const onFailAction = (error, strAction) => {
    toaster.pop("error", "Something went wrong", `Could not ${strAction} timesheet(s)`);
  };

  const parseRecord = (record) => {
    const caregiver = DatabaseApi.getCaregiverById(record.caregiverId);
    if (caregiver) {
      record.caregiver = angular.copy(caregiver);
      record.caregiver.id = record.caregiverId;
      record.caregiver.fullName = getFullName(caregiver);
      record.caregiver.displayId = caregiver.displayId;
      record.caregiver.address = caregiver.address;
      record.caregiver.certifications = caregiver.certifications;
    }

    const patient = DatabaseApi.getPatientById(record.patientId);
    if (patient) {
        record.patient = angular.copy(patient);
        record.patient.id = record.patientId;
        record.patient.fullName = getFullName(patient);
        record.patient.displayId = patient.displayId;
        record.patient.address = patient.address ? patient.address.text : null;

        const patientCurrentOffice = $scope.offices.find(office => office.id === patient.currentOfficeId);
        if (patientCurrentOffice) {
            record.patient.currentOfficeName = patientCurrentOffice.name;
        }
    }

    let div = '<ul class="tooltip-duties">';
    record.selectedDuties.forEach((duty) => {
        div += `<li>${duty.label} (${duty.code})</li>`
    });
    div += "</ul> ";

    record.duties = record.selectedDuties.length > 0 ? div : "";
    
    return record;
  };

  const getFullName = ({ firstName, middleName, lastName }) => {
    return [firstName, middleName, lastName].filter(namePart => namePart !== null).join(' ');
  };

  $scope.onFiltersChange = () => {
    $scope.filteredQueryRecords = Array.from($scope.initialQueryRecords);
    
    $scope.pageManager.updateSearchParamValue("selectedOffices", $scope.filters.offices);
    $scope.pageManager.updateSearchParamValue("selectedTeams", $scope.filters.teams);
    $scope.pageManager.updateSearchParamValue("selectedCoordinators", $scope.filters.coordinators);
    $scope.pageManager.updateSearchParamValue("selectedStatuses", $scope.filters.statuses);

    // Offices
    if ($scope.filters.offices.length) {
      const selectedOfficeIds = $scope.filters.offices.map(
        (office) => office.id
      );
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          let relevantOfficeIds = [];

          const caregiver = DatabaseApi.getCaregiverById(record.caregiver.id);
          if (caregiver) {
            relevantOfficeIds.push(...caregiver.officeIds);
          }

          const patient = DatabaseApi.getPatientById(record.patient.id);
          if (patient) {
            relevantOfficeIds.push(patient.currentOfficeId);
          }

          return relevantOfficeIds.some(relevantOfficeId => selectedOfficeIds.includes(relevantOfficeId));
        }
      );
    }

    // Coordinators
    if ($scope.filters.coordinators.length) {
      const selectedCoordinatorIds = $scope.filters.coordinators.map(
        (coordinator) => coordinator.id
      );
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          const patientIds = (() => {
            if (record.patient.id) {
                return [record.patient.id]
            }

            return record.potentialLinks.map(x => x.patient.id)
          })();

          const patients = patientIds.map(DatabaseApi.getPatientById);

          return patients.some(patient => patient.assignedCoordinator && selectedCoordinatorIds.includes(patient.assignedCoordinator));
        }
      );
    }

    // Teams
    if ($scope.filters.teams.length) {
      const selectedTeamIds = $scope.filters.teams.map((team) => team.id);
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          if (!record.patient.id) {
            return false;
          }
          const patient = DatabaseApi.getPatientById(record.patient.id);
          return selectedTeamIds.indexOf(patient.agencyTeamId) !== -1
        }
      );
    }

    populateTable($scope.filteredQueryRecords);
  };

  $scope.multiSelectEvents = {
    onSelectionChanged() {
      $scope.onFiltersChange();
    }
  };

  $scope.applyGlobalSearch = async () => {
    const filter = { $: $scope.globalFilter.val };
    if ($scope.tableParams) {
      await angular.extend($scope.tableParams.filter(), filter);
      $scope.selectionLogic.initItemsCollection(getFilteredRecords())
    }
  };

  $scope.getCountSelected = () => {
    const selected = $scope.selectionLogic?.countSelected?.();
    if (!selected) {
      return "";
    }
    return ` (${selected} selected)`;
  };

  const getFilteredRecords = () => {
    if (!$scope.globalFilter || !$scope.globalFilter.val) {
      return $scope.initialQueryRecords;
    }
    const records = angular.copy($scope.initialQueryRecords);
    return $filter("filter")(records, $scope.globalFilter.val);
  };

  $rootScope.$on("refresh_timesheets", $scope.loadItems);
  $rootScope.$on("got_caregivers_data", initialize);
  $rootScope.$on("got_patients_data", initialize);
  $rootScope.$on("got_agency_members", initAgencyMembers);

  initialize();
};

