//! @ngInject
export function coriModalCtrl($scope, $rootScope, NgTableParams, $state, $uibModalInstance, modalData, subsection, DatabaseApi, $timeout, $window, $stateParams, toaster) {
    const getQuestionById = (textId) => {
        for (const questionGroup of subsection.questionGroups) {
            for (const question of questionGroup.questions) {
                if (question.textId === textId) {
                    return question;
                }
            }
        }
    };

    $scope.CAFFormGovernmentIssuedIdentification = getQuestionById("CAFFormGovernmentIssuedIdentification");

    $scope.isTooBigText = function() {
        const answer = $scope.CAFFormGovernmentIssuedIdentification.answer || '';
        return answer.split("\n").length > 3;
    };

    $scope.closeModal = (res) => {
        if (res === undefined) {
            res = "cancel";
        }
        $uibModalInstance.close(res);
    };

    $scope.governmentIssuedIdentificationUpdate = () => {
        const questionUrl = "hr/agencies/" + $rootScope.agencyId +
            "/caregivers/" + modalData.caregiverId +
            "/caregiver_application_answer_webapp";

        return DatabaseApi
            .put(questionUrl, $scope.CAFFormGovernmentIssuedIdentification)
            .then(function(responses) {
                let hasIssues = responses.filter(res => !res.data.isSuccessful).length > 0;
                if (!hasIssues) {
                    toaster.pop("success", "Question was updated successfully");
                }
            }, function(err) {
                toaster.pop("warning", "something went wrong", "");
            });
    };

    $scope.submitCoriDoc = () => {
        $scope.sendingCori = true;
        const postUrl = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${modalData.caregiverId}/agency_confirm_subsection`;

        const data = {
            questions: [$scope.CAFFormGovernmentIssuedIdentification],
            subsectionId: subsection.data.id
        };

        DatabaseApi.post(postUrl, data).then((res) => {
            $window.open(res.data.url);
            $scope.sendingCori = false;
            $scope.closeModal("Completed");
        }, (err) => {
            toaster.pop("warning", "something went wrong", "");
            $scope.sendingCori = false;
        });

    };
};
