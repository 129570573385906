//! @ngInject
export function docsExpirationsCtrl($scope, $rootScope, Socket, NgTableParams, $state, pros, $http, toaster, DatabaseApi, $filter, $uibModal, Analytics, Consts ) {

  window.scrollTo(0, 0);
  
  $scope.expiry = [];
  $scope.working = true;
  
  $scope.getLimit = function(i){
    if(i) return 100000000
    return 100;
  };
  
  var prosMap = DatabaseApi.caregivers() || {};

  $scope.days = 1;
  $scope.changeDays = function(num){
    $scope.days = num;
    $scope.working = true;
    getExpiry(num);
  }
  
  
  // get upload history
  function getExpiry(days){
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/documents_expiring_in/' + days).then(function (response) {
      console.log(response.data);
      var a = response.data.documents;
      a.forEach(function(c){
        c.caregiver = prosMap[c.caregiver];
      });
      $scope.expiry = response.data.documents;
      initTable($scope.expiry);

    }, function (response) {
      $scope.working = false;
    });
  }
  
  // application document types
   var docMap = {};

  DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/document_types').then(function(res){
    res.data.documentTypes.forEach(function(d){
      docMap[d.id] = d;
    });
    console.log(docMap);
    getExpiry(1);
  }, function(err){

  });
  
  $scope.getDocTitle = function(id){
    return docMap[id];
  }

  $rootScope.$on('got_data', function (event) {
    prosMap = DatabaseApi.caregivers() || {};
  });
  $rootScope.$on('caregiver_changed', function (event) {
    prosMap = DatabaseApi.caregivers() || {};
  });
  $rootScope.$on('new_caregiver', function (event) {
    prosMap = DatabaseApi.caregivers() || {};
  });

  init();


  function init() {
    
    if(!prosMap || $state.current.name !== 'app.docsExpirations' || !$scope.expiry) return;
    
    initTable($scope.expiry);
  }


  $scope.showStatus = function (row) {
    var selected = $filter('filter')($scope.statuses, {value: row.status});
    return (row.status && selected.length) ? selected[0].text : 'Not set';
  };

  $scope.goToProfile = function (id) {
    $rootScope.openCaregiverModal(id);
  };

  $scope.applyGlobalSearch = function (term) {
    var filter = { $: term };
    if ($scope.table) {
      angular.extend($scope.table.filter(), filter);
    } else {
      console.log('no table');
    }
  };

  function initTable(data) {
    if(!data.length) return;
    const oldTotal = $scope.table?.total?.() || 0;
    
    var page = false, filters = undefined, sorting = {lastSeen: 'desc', createdAt: "desc"};
    if ($scope.table) {
      filters = $scope.table.filter();
      sorting = $scope.table.sorting();
      //$scope.tableOptions.filter = $scope.table.filter();
      page = $scope.table.page();
    }

    var options = {
      count: 25,
      sorting: sorting
    };
    if (filters) options.filter = filters;

    $scope.table = new NgTableParams(options, {
      counts: [],
      dataset: data
    });
    if (page && oldTotal === $scope.table.total()) $scope.table.page(page);
    
    $scope.working = false;
    //console.log($scope.table);
  }

  //initTable($scope.expiry);

  
    
  //
  //
};

