/* @ngInject */
export function filterByNameAndSize() {
    return function (items, name, visitId, patient) {
        var result = {};
        try {
            var regex = new RegExp(name, 'ig');
        } catch (e) {
            name = false;
        }
        if (!name || name === '')
            return result;
        else {
            angular.forEach(items, function (value, key) {
                if ((regex.test(value.displayName) || regex.test(value.displayId)) && Object.keys(result).length < 10) {

                    value.chatRoomType = {
                        visitId: visitId,
                        caregiverId: parseInt(value.id),
                        patientId: patient ? patient.id : null,
                        type: 'CaregiverPatientChat'
                    };

                    value.members = [
                        {
                            lastViewed: null,
                            user: {
                                caregiverId: value.id,
                                type: 'Caregiver'
                            }
                        }
                    ];

                    value.messages = [];
                    value.photo = value.photoUrl || 'admin/images/blank-profile.jpg';
                    value.caregiverLastViewd = null;
                    value.agencyLastViewd = null;
                    value.displayName = value.displayName;
                    //value.caregiverId = key;
                    value.fromList = true;

                    result[key] = value;
                }
            });
            return result;
        }
    };
}
;
