//! @ngInject
export function visitClusterSuggestModalCtrl($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, Socket, SweetAlert, $timeout, $uibModal, clusters){

    window.scrollTo(0,0);

    $scope.activeTime = 0;
    $scope.isLoading = true;

    var googleDistanceMatrixService = new google.maps.DistanceMatrixService();


    $scope.closeModal = function(){
        $uibModalInstance.close();
    };

    var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

    $scope.getDay = function(num){
        return days[num];
    };

    $scope.showMatchingCaregivers = function(cluster){
        if(!cluster || !cluster.visit1 || !cluster.visit2) return;

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/matching-caregivers-modal.html',
            size: 'md',
            controller: 'MatchingCaregiversModalCtrl',
            windowClass: 'top-top',
            resolve: {
                visits: function() { return [cluster.visit1.id, cluster.visit2.id]; },
                cluster: function() { return cluster || {}; }
            }

        });
        modalInstance.result.then(function (res) {
            //console.log('modal click');
            //console.log(res);
        }, function () {
            //console.log('modal close');
        });
    };

    if(!clusters) {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/cluster_cases').then(function (res) {

            setClusters(res.data.clusterCases);

        }, function (err) {
            $scope.isLoading = false;
            toaster.pop('error', 'Something went wrong');
        });
    } else {
        setClusters(clusters);
    }

    function setClusters(data){

        data.forEach(function (cluster, i) {
            cluster.visit1coords = {latitude: cluster.visit1.geo.lat, longitude: cluster.visit1.geo.lng};
            cluster.visit2coords = {latitude: cluster.visit2.geo.lat, longitude: cluster.visit2.geo.lng};
            cluster.center = {latitude: cluster.visit1.geo.lat, longitude: cluster.visit1.geo.lng};


            getDistanceAndTime(cluster, i);


        });

        $scope.clusters = data;

        $scope.isLoading = false;
        console.log($scope.clusters);
    }

    function getDistanceAndTime(cluster,i){

        var origin = new google.maps.LatLng(cluster.visit1coords.latitude, cluster.visit1coords.longitude);
        var destination = new google.maps.LatLng(cluster.visit2coords.latitude, cluster.visit2coords.longitude);

        googleDistanceMatrixService.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                travelMode: 'DRIVING'
            }, drivingCallback);

        googleDistanceMatrixService.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                travelMode: 'TRANSIT',
                transitOptions: {
                    departureTime: new Date( cluster.visit1.startTime),
                    modes: ['SUBWAY', 'BUS']
                }
            }, transitCallback);

        googleDistanceMatrixService.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                travelMode: 'WALKING'
            }, walkingCallback);

        function drivingCallback(response, status) {
            if (status !== 'OK') return;
            $scope.clusters[i].googleDriving = {
                distance: response.rows[0].elements[0].distance.text,
                duration: response.rows[0].elements[0].duration.text
            }
        }
        function transitCallback(response, status) {
            if (status !== 'OK') return;
            $scope.clusters[i].googleTransit = {
                distance: response.rows[0].elements[0].distance.text,
                duration: response.rows[0].elements[0].duration.text
            };
        }
        function walkingCallback(response, status) {
            if (status !== 'OK') return;
            $scope.clusters[i].googleWalking = {
                distance: response.rows[0].elements[0].distance.text,
                duration: response.rows[0].elements[0].duration.text
            }
        }

    }


};
