import ng from "angular";
import { Caregiver } from "../../../../scripts/messages/caregiver";
import { CaregiverRequestsRowBindings, CaregiverWorkingHoursData} from "./flexible-caregiver-requests-row.types";
import "./flexible-caregiver-requests-row.component.scss";
import { CaregiverRequest } from "../../visitBroadcast.types";
import { CaregiverId } from "../../../../scripts/messages/ids";

//! @ngInject
class caregiverRequestsRowCtrl implements ng.IComponentController, CaregiverRequestsRowBindings
{
    disableSelection = false;
    caregiver!: Caregiver;
    requests!: CaregiverRequest[];
    visitType!: "PATTERN" | "SINGLES"
    toggleShiftSelection!: (requestDetails: {requestDetails: CaregiverRequest[]}) => void;
    onRejectRequest!: (caregiverId: {caregiverId: CaregiverId}) => void;
    allowMultipleCaregivers!: boolean;
    caregiverWorkingHours!: CaregiverWorkingHoursData[];

    selectedRow!: boolean;
    displayOvertimeWarning!: boolean;
    
    constructor(
      private mfModal: any,
      private $filter: ng.IFilterService
    ){
        this.selectedRow = false;
        this.displayOvertimeWarning = false;
    }

    $onChanges(changesObj){
      if("requests" in changesObj){
        this.selectedRow = this.requests.some((request) => request.isSelected);
      }

      if ("caregiverWorkingHours" in changesObj) {
        this.caregiverWorkingHours = changesObj.caregiverWorkingHours.currentValue;
        this.displayOvertimeWarning = this.caregiverWorkingHours !== undefined && this.caregiverWorkingHours.some((week) => week.isInOvertime);
      }
    }

  toggleShiftSelectionForCaregiver(requestDetails: CaregiverRequest){
    this.toggleShiftSelection({requestDetails: [requestDetails]});
  }

  toggleSelectionForAllCaregiverShifts(){
    this.selectedRow = !this.selectedRow;    
    this.toggleShiftSelection({requestDetails: this.requests.filter(((request) => !request.isDisabled))});
  }

  mapOvertimeWeeksToModalText(){
    return this.caregiverWorkingHours.filter((week) => week.isInOvertime).map((weekData) => 
        `Start of week: ${this.$filter("mfShortDate")(weekData.weekStart)},   Total: ${weekData.weeklyHours}h, Weekly overtime: ${weekData.weeklyOvertimeHoursMark}h`);
  }

  openBroadcastOvertimeModal(){
    const overtimeWeeksDispaly = ["Caregiver will have overtime hours on the following weeks: \n", ...this.mapOvertimeWeeksToModalText()];

    const modal = this.mfModal.create({
      subject: "Potential Overtime After Assign",
      variant: "warning",
      message: overtimeWeeksDispaly.join("\n"),
      confirmLabel: "OK",
      cancelLabel: "Close",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: () => {
        modal.close();
      },
      onCancel: () => {
        modal.close();
      }
    });
  }
}

export const flexibleCaregiverRequestsRow = {
    templateUrl:
      "admin/modules/visit-broadcast/components/flexible-caregiver-requests-row/flexible-caregiver-requests-row.component.html",
    controller: caregiverRequestsRowCtrl,
    controllerAs: "ctrl",
    bindings: {
      disableSelection: "<",
      caregiver: "<",
      visitType: "<",
      requests: "<",
      datesRange: "<",
      toggleShiftSelection: "&",
      allowMultipleCaregivers: "<",
      caregiverWorkingHours: "<",
      onRejectRequest:"&"
    },
  };