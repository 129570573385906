//! @ngInject
export function patientDocDatabaseQuestionsModalCtrl($scope, $rootScope, $uibModalInstance, $uibModal, toaster, DatabaseApi) {

    $scope.closeModal = function (databaseQuestionId) {
        $uibModalInstance.close(databaseQuestionId);
    };

    $scope.selectedQuestionModel = {};
    $scope.databaseQuestionsExtraSettings = {
        styleActive: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        enableSearch: true,
        scrollable: true,
        scrollableHeight: '400px'
    };

    const questionTypesMap = {
        radio: "Radio",
        check: "Multiple Select (check)",
        dropDown: "Drop Down",
        yesNo: "Yes/No",
        textShort: "Short Text",
        textLong: "Long Text",
        number: "Number",
        time: "Time",
        date: "Date",
        bloodPressure: "Blood Pressure"
    };

    $scope.allowedAnswerTypesToEditShowOnAgency = ['radio', 'check', 'dropDown'];
    $scope.multipleAnswersQuestionTypes = ['radio', 'check', 'dropDown'];

    $scope.mapDatabaseQuestions = (mappedDatabaseQuestinos) => {
        $scope.databaseQuestionsOptions = mappedDatabaseQuestinos[$scope.$resolve.item.itemType].map((question) => {
            return {
                id: question.id,
                label: `${question.questionText} (${questionTypesMap[question.answerType]})`
            };
        });
    }
    $scope.mapDatabaseQuestions($scope.$resolve.mappedDatabaseQuestions);

    $scope.openNewQuestionModal = () => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-new-question-type-modal.html',
            size: 'md',
            controller: 'patientNewQuestionTypeModalCtrl',
            resolve: {
                questionType: function () {
                    return $scope.$resolve.item.itemType;
                },
                allowedVBPQuestionTypes: function () {
                    return ["radio", "dropDown", "yesNo"];
                },
                modalStatus: function () {
                    return "NEW_QUESTION";
                }
            }
        });

        modalInstance.result.then(function (res) {
            if (res === true) {
                DatabaseApi.setPatientQuestionTypes();
            }
        });
    }

    $scope.selectQuestion = () => {
        const selectedQuestion = $scope.databaseQuestionsOptions.find(question => question.id === $scope.selectedQuestionModel.id);
        if (selectedQuestion) {
            $scope.closeModal(selectedQuestion.id);
        } else {
            toaster.pop('error', 'Oops! Something went wrong');
        }
    }

    $rootScope.$on('got_data', function () {
        const questions = DatabaseApi.patientQuestionTypes();
        if (!questions || questions.length === 0) {
            return;
        }

        const questionsMap = {
            "radio": [],
            "check": [],
            "dropDown": [],
            "yesNo": [],
            "textShort": [],
            "textLong": [],
            "number": [],
            "time": [],
            "date": [],
            "bloodPressure": []
        };
        questions.forEach(question => questionsMap[question.answerType].push(question));
        $scope.mapDatabaseQuestions(questionsMap);
    });

};