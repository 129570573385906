import { IFilterService } from "angular";
import { DayOfWeek, LocalTime } from "js-joda";

export const daysOfWeekDisplay = {
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
};

export function parseFlexibleDaysAndHoursVisitHoursPattern(
  visitDetails: {
    amountOfDays: number;
    amountOfHours: number;
  }[]
) {
  return visitDetails.map(
    (detail) => `${detail.amountOfDays} Days of ${detail.amountOfHours} Hours.`
  );
}

export function parseFixedDaysFlexibleHoursVisitHoursPattern(
  visitDetails: {
    day: DayOfWeek;
    amountOfHours: number;
  }[]
) {
  return visitDetails.map(
    (detail) => `${detail.amountOfHours} Hours On ${daysOfWeekDisplay[detail.day.toString()]}.`
  );
}

export function parseFixedHoursFlexibleDaysPattern(
  timeFilter: IFilterService,
  visitDetails: {
    day: DayOfWeek;
    startTime: LocalTime;
    endTime: LocalTime;
  }[],
  amountOfDays: number
) {
  const patientPreferences = visitDetails.map(
    (details) => daysOfWeekDisplay[details.day.toString()]
  );

  return [
    `${timeFilter("mfShortTime")(visitDetails[0].startTime)} - ${timeFilter("mfShortTime")(
      visitDetails[0].endTime
    )}, ${amountOfDays} days a week`,
    `Patient Preferences: ${patientPreferences.toString().replace(",", ",  ")}`,
  ];
}
