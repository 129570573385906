import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import {
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  PatientEntity,
} from "../../../shared/components/EntityCard";
import PhotoFillIcon from "../../../shared/icons/PhotoFillIcon";
import {
  AgencyMemberId,
  CaregiverId,
  CommCenterLabelId,
  CommCenterTeamMemberId,
  PatientId,
} from "../../../shared/schema/schema";

export type NewTicketRequestBody = {
  labelId: CommCenterLabelId;
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  message: string;
  topic: "Patient" | "Caregiver";
};

export const getTopicEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<Entity> => {
  return match(ticket)
    .with(
      { topic: "Caregiver", relatedCaregiver: P.not(null) },
      ({ relatedCaregiver }): EntityWithStatus<CaregiverEntity> => ({
        type: "Caregiver",
        displayId: relatedCaregiver.displayId,
        fullName: relatedCaregiver.name,
        id: relatedCaregiver.id,
        status: relatedCaregiver.status,
        photoUrl: relatedCaregiver.photoUrl,
      })
    )
    .with(
      { topic: "Patient", relatedPatient: P.not(null) },
      ({ relatedPatient }): EntityWithStatus<PatientEntity> => ({
        type: "Patient",
        displayId: relatedPatient.displayId,
        fullName: relatedPatient.name,
        id: relatedPatient.id,
        status: relatedPatient.status,
        gender: relatedPatient.gender,
      })
    )
    .otherwise((state) => {
      throw new Error(`Invalid state ${JSON.stringify(state)}`);
    });
};

export const getRelatedEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<Entity> | null => {
  return match(ticket)
    .with(
      { topic: "Patient", relatedCaregiver: ticket.relatedCaregiver },
      ({ relatedCaregiver }): EntityWithStatus<CaregiverEntity> | null => (relatedCaregiver === null ? null : {
        type: "Caregiver",
        displayId: relatedCaregiver.displayId,
        fullName: relatedCaregiver.name,
        id: relatedCaregiver.id,
        status: relatedCaregiver.status,
        photoUrl: relatedCaregiver.photoUrl,
      })
    )
    .with(
      { topic: "Caregiver", relatedPatient: ticket.relatedPatient},
      ({ relatedPatient }): EntityWithStatus<PatientEntity> | null => (relatedPatient === null ? null :{
        type: "Patient",
        displayId: relatedPatient.displayId,
        fullName: relatedPatient.name,
        id: relatedPatient.id,
        status: relatedPatient.status,
        gender: relatedPatient.gender,
      })
    )
    .otherwise((state) => {
      throw new Error(`Invalid state ${JSON.stringify(state)}`);
    });
};

export const getCommCenterTeamMemberIdByAgencyMemberId = (
  teams: Messages["CommCenterTeamWithMembers"][],
  agencyMemberId: AgencyMemberId
): CommCenterTeamMemberId | null => {
  const teamMember = teams
    .flatMap((team) => team.members)
    .find((member) => member.agencyMemberId === agencyMemberId);

  return teamMember?.id ?? null;
};

export function getCommCenterMessageCreatorName(
  message: Pick<Messages["CommCenterMessage"], "createdBy">
) {
  return match(message.createdBy)
    .with({ type: "Agency Member" }, (agencyMember) => agencyMember.name)
    .with({ type: "Caregiver" }, (caregiver) => caregiver.name)
    .with({ type: "System" }, () => "Medflyt Support")
    .exhaustive();
}

export function isCommCenterMessageAuthorTheSame(params: {
  previous: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
  next: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
}) {
  if (params.previous === undefined || params.next === undefined) {
    return false;
  }

  return match({ a: params.previous.createdBy, b: params.next.createdBy })
    .with({ a: { type: "Agency Member" }, b: { type: "Agency Member" } }, (agencyMembers) => {
      return agencyMembers.a.id === agencyMembers.b.id;
    })
    .with({ a: { type: "Caregiver" }, b: { type: "Caregiver" } }, (caregivers) => {
      return caregivers.a.id === caregivers.b.id;
    })
    .with({ a: { type: "System" }, b: { type: "System" } }, () => true)
    .otherwise(() => false);
}

export function getMessagePreview(message: Messages["CommCenterMessage"]) {
  const latestMessage = message.payload.at(-1);

  if (latestMessage === undefined) {
    return null;
  }

  return match(latestMessage)
    .with({ type: "TEXT" }, ({ message }) => message)
    .with({ type: "IMAGE" }, () => (
      <Flex gap={2} align="center">
        <PhotoFillIcon />
        <Text>Photo</Text>
      </Flex>
    ))
    .with({ type: "ACTION" }, () => <></>)
    .exhaustive();
}

const CommCenterTicketStatus = ["NEW", "IN PROGRESS", "RESOLVED"] as const;
export type CommCenterTicketStatus = typeof CommCenterTicketStatus[number];

export function getLatestCommCenterMessageByCaregiver(messages: Messages["CommCenterMessage"][]) {
  return messages.filter((message) => message.createdBy.type === "Caregiver").at(-1);
}

export function sortTicketsByLastMessage(tickets: Messages["CommCenterTicket"][]) {
    return [...tickets].sort((a, b) => {
      const aLastMessage = a.messages[a.messages.length - 1];
      const bLastMessage = b.messages[b.messages.length - 1];
  
      if (aLastMessage === undefined) {
        return -1;
      }
  
      if (bLastMessage === undefined) {
        return 1;
      }
  
      return aLastMessage.createdAt.isBefore(bLastMessage.createdAt) ? 1 : -1;
    });
  }