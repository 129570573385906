import { IPromise } from "angular";
import { DateTimeFormatter, LocalDate } from "js-joda";
import { PatientTaskInstanceEditScheduleDateTime } from "../messages/patient_task";
import moment from "moment";
import { AssertNever } from "../consts/assertNever.const";
import { CaregiverTaskInstancesResponse, CaregiverTaskTypesForWebapp } from "../messages/caregiver_task";
import { CaregiverId, PatientDocumentTypeId, PatientId, PatientTaskInstanceId, VisitInstanceId } from "../messages/ids";
import { PatientTaskInstanceWithDetails, PatientTaskTypes, PatientTaskTypesForWebapp } from "../messages/patient_task";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

export interface ExtenedPatientTaskInstanceWithDetails extends PatientTaskInstanceWithDetails {
  patientAddress: string;
  status: TaskStatusOption;
}

export interface ExtenedPatientFutureTask extends PatientTaskTypes.FutureTask {
  patientAddress: string;
  status: TaskStatusOption;
}

export type ExtendedCaregiverTaskTypesForWebapp =
  | ExtenedPatientTaskInstanceWithDetails
  | ExtenedPatientFutureTask;

export interface CaregiverTaskFilters {
  from: LocalDate | null;
  to: LocalDate | null;
}

type TaskColorClass = "default" | "grey" | "red" | "orange" | "blue" | "lightblue";
export type TaskStatus =
  | "Completed"
  | "Incompleted"
  | "Unassigned"
  | "Broadcasting"
  | "Scheduled"
  | "Missing"
  | "Future"
  | "Canceled"
  | "Assigned";
export interface TaskStatusOption {
  id: number;
  status: TaskStatus;
  label: TaskStatus;
  colorClass: TaskColorClass;
}
const format = DateTimeFormatter.ofPattern("yyyy-MM-dd");


/*! @ngInject */
export class TasksService {
  statuses: TaskStatusOption[];
  statusesOptionsRecords: Record<TaskStatus, TaskStatusOption> = {
    Completed: { id: 0, status: "Completed", label: "Completed", colorClass: "default" },
    Incompleted: { id: 1, status: "Incompleted", label: "Incompleted", colorClass: "grey" },
    Unassigned: { id: 2, status: "Unassigned", label: "Unassigned", colorClass: "red" },
    Broadcasting: { id: 3, status: "Broadcasting", label: "Broadcasting", colorClass: "orange" },
    Assigned: { id: 4, status: "Assigned", label: "Assigned", colorClass: "grey" },
    Scheduled: { id: 5, status: "Scheduled", label: "Scheduled", colorClass: "blue" },
    Missing: { id: 6, status: "Missing", label: "Missing", colorClass: "red" },
    Future: { id: 7, status: "Future", label: "Future", colorClass: "lightblue" },
    Canceled: { id: 8, status: "Canceled", label: "Canceled", colorClass: "red" },
  };

  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService,
    private DatabaseApi: any,
    private assertNever: AssertNever
  ) {
    this.statuses = Object.values(this.statusesOptionsRecords);
  }

  /**
   * Get all caregiver tasks by date range
   */
  getCaregiverTasks = (
    caregiverId: CaregiverId,
    filters: CaregiverTaskFilters
  ): IPromise<ExtendedCaregiverTaskTypesForWebapp[]> => {
    let queryParams: URLSearchParams | string = "";
    if (filters.from !== null && filters.to !== null) {
      queryParams = new URLSearchParams({
        from: filters.from.format(format),
        to: filters.to.format(format),
      });
    }

    const queryParamsString = queryParams.toString();

    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/tasks?${queryParamsString}`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        caregiverId: caregiverId,
      },
    });

    return this.api.get<CaregiverTaskInstancesResponse>(url).then((res) => {
      return res.data.caregiverTaskInstances.map(this.parseCaregiverTask);
    });
  };

  /**
   * Agency member sets a schedule date time for a task instance
   */
  setPatientTaskScheduledDate = (
    patientTaskInstanceId: PatientTaskInstanceId,
    body: PatientTaskInstanceEditScheduleDateTime
  ): IPromise<void> => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances/:patientTaskInstanceId/update_schedule_date_time`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientTaskInstanceId: patientTaskInstanceId,
      },
    });

    return this.api.put(url, body).then(() => {
      return;
    });
  };

  /**
   * Agency member request a patient task instance by visit instance id
   */
  getPatientTaskInstanceByVisitInstanceId = (
    visitInstanceId: VisitInstanceId
  ): IPromise<PatientTaskInstanceWithDetails> => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_task_instance`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        visitInstanceId: visitInstanceId,
      },
    });

    return this.api.get<PatientTaskInstanceWithDetails>(url).then((res) => {
      return res.data;
    });
  };

  getTaskStatus = (task: PatientTaskTypesForWebapp): TaskStatusOption => {
    switch (task.type) {
      case "RegularTask": {
        if (task.taskInstanceCanceledAt) {
          return this.statusesOptionsRecords.Canceled;
        }

        if (task.completionDate !== null) {
          return this.statusesOptionsRecords.Completed;
        }

        if (task.hasClockedIn) {
          return this.statusesOptionsRecords.Incompleted;
        }

        const overDueDate = moment().isAfter(moment(task.dueDate.toString()));

        if (overDueDate) {
          return this.statusesOptionsRecords.Missing;
        }

        if (!overDueDate && task.scheduleDateTime !== null) {
          return this.statusesOptionsRecords.Scheduled;
        }

        return this.statusesOptionsRecords.Assigned;
      }
      case "FutureTask":
        return this.statusesOptionsRecords.Future;

      case "DraftTask":
        return this.statusesOptionsRecords.Unassigned;

      case "Broadcasting":
        return this.statusesOptionsRecords.Broadcasting;

      default:
        return this.assertNever(task);
    }
  };

  parseCaregiverTask = (
    caregiverTask: CaregiverTaskTypesForWebapp
  ): ExtendedCaregiverTaskTypesForWebapp => {
    const patient = this.DatabaseApi.getPatientById(caregiverTask.patientId);
    const status = this.getTaskStatus(caregiverTask);
    return { ...caregiverTask, patientAddress: patient.address?.text, status: status };
  };

  deletePatientTaskDocument = (
    patientId: PatientId,
    patientTaskInstanceId: PatientTaskInstanceId,
    patientDocumentTypeId: PatientDocumentTypeId
  ) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_task_instances/:patientTaskInstanceId/patient_documents/:documentTypeId`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
        patientTaskInstanceId: patientTaskInstanceId,
        documentTypeId: patientDocumentTypeId,
      },
    });

    return this.api.delete(url);
  };
}
