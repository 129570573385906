import React from "react";

type AutosizeTextareaOptions = { lineHeight: number; maxHeight: number };

export function useAutosizeTextarea(
  ref: React.RefObject<HTMLTextAreaElement | null>,
  options?: Partial<AutosizeTextareaOptions>
) {
  const { current: optionsRef } = React.useRef<AutosizeTextareaOptions>({
    lineHeight: 40,
    maxHeight: 200,
    ...options,
  });

  React.useEffect(() => {
    const { current: textarea } = ref;

    if (textarea === null) {
      return;
    }

    textarea.style.height = `${optionsRef.lineHeight}px`;
    textarea.style.overflowY = "hidden";

    const onInput = (e: Event) => {
      if (e.target instanceof HTMLTextAreaElement) {
        e.target.style.height = "auto";
        if (e.target.value === "") {
          e.target.style.height = `${optionsRef.lineHeight}px`;
          return;
        }

        if (e.target.scrollHeight > optionsRef.maxHeight) {
          e.target.style.height = `${optionsRef.maxHeight}px`;
          e.target.style.overflowY = "scroll";
          return;
        }

        e.target.style.height = `${e.target.scrollHeight}px`;
      }
    };

    textarea.addEventListener("input", onInput);

    return () => textarea.removeEventListener("input", () => onInput);
  }, [ref, optionsRef]);
}
