import React from "react";
import { Messages } from "../../../core/api";
import EntitySelect from "../../../shared/components/EntitySelect";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import CaregiverIcon from "../../../shared/icons/CaregiverIcon";
import { CaregiverId } from "../../../shared/schema/schema";
import { useCaregiversQuery } from "../hooks/useCaregiversQuery";
import { useSearchCaregiversQuery } from "../hooks/useSearchCaregiversQuery";

type CaregiverResult = Pick<
  Messages["GlobalSearchCaregiverResult"],
  "id" | "firstName" | "middleName" | "lastName" | "displayId" | "gender" | "photoUrl"
>;

type Props = {
  value: CaregiverId | null;
  isDisabled?: boolean;
  onChange: (value: CaregiverResult | null) => void;
};

const CaregiverSelect = (props: Props) => {
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const searchQuery = useSearchCaregiversQuery({ term: debouncedSearchText });
  const caregiversQuery = useCaregiversQuery();

  const getPhotoUrlByCaregiver = (caregiver: CaregiverResult) => {
    return caregiver.photoUrl ?? "/admin/images/blank-profile.jpg";
  };

  return (
    <EntitySelect
      entitiesQuery={caregiversQuery}
      searchQuery={searchQuery}
      searchText={searchText}
      value={props.value}
      buttonPlaceholder="Select caregiver"
      inputPlaceholder="Search caregiver..."
      notFoundPlaceholder="No results"
      isDisabled={props.isDisabled}
      photoUrlPredicate={getPhotoUrlByCaregiver}
      leftIcon={<CaregiverIcon fontSize={18} />}
      onChangeSearchText={setSearchText}
      onChange={props.onChange}
    />
  );
};

export default CaregiverSelect;
