import { Duration } from "@js-joda/core";

function createDurationFormatter() {
  const rtf = new Intl.RelativeTimeFormat("en", { style: "long" });

  return {
    relative: (duration: Duration) => {
      const formatted = (() => {
        if (Math.abs(duration.toDays()) >= 1) {
          return rtf.format(duration.toDays(), "days");
        }

        if (Math.abs(duration.toHours()) >= 1) {
          return rtf.format(duration.toHours(), "hours");
        }

        if (Math.abs(duration.toMinutes()) >= 1) {
          return rtf.format(duration.toMinutes(), "minutes");
        }

        if (Math.abs(duration.toMillis()) / 1000 > 10) {
          return rtf.format(Math.floor(duration.toMillis() / 1000), "seconds");
        }

        return "Just now";
      })();

      return { duration, formatted };
    },
  };
}

export const durationFormatter = createDurationFormatter();
