import React from "react";
import { createIcon } from "@chakra-ui/react";

const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      fill="currentColor"
      d="M128 20a108 108 0 1 0 108 108A108.1 108.1 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84a84.1 84.1 0 0 1-84 84Zm68-84a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12V72a12 12 0 0 1 24 0v44h44a12 12 0 0 1 12 12Z"
    />
  ),
});

export default ClockIcon;
