"use strict";

export const calendarWeekAggregationColumn = {
  templateUrl: "admin/views/calendar-week-aggregation-column.html",
  bindings: {
    items: "<",
  },
  //! @ngInject
  controller: function ($scope) {
  },
};