//! @ngInject
export function visitsBroadcastCtrl($scope, $rootScope, NgTableParams, DatabaseApi, $q, selectionLogic, $http, Consts, SweetAlert, toaster) {

    $scope.isLoading = false;
    $scope.hasData = false;
    $scope.file = null;

    function initialize() {
        initSelections();
    }

    var initSelections = function () {
        $scope.selectionLogic = {
            toCreate: selectionLogic.createNewLogic(null, "patternId"),
            toUpdate: selectionLogic.createNewLogic(null, "patternId"),
            toRemove: selectionLogic.createNewLogic(null, "patternId")
        };
    };

    $scope.$watch('file', function () {
        if ($scope.file && $scope.file.name) {
            var fileType = $scope.file.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (fileType === 'xls' || fileType === 'xlsx') {
                fileUpload();
            } else {
                toaster.pop('warning', "Unsupported file type", 'Please uplaod an Excel file (.xls, .xlsx)');
                $scope.file = null;
            }
        }
    });

    function fileUpload() {
        $scope.isLoading = true;

        var formData = new FormData();
        formData.append('file', $scope.file, $scope.file.name);

        formData.append('type', 'SCHEDULE');
        formData.append('caregiverBranch', '');

        var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/open_visits';
        $http({
            url: url,
            method: 'PUT',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (response) {
            $scope.file = null;
            $scope.fileSent = "Thanks for uploading, the file was uploaded successfully.";
            setIfHasData(response.data);
            renderImportedVisits(response.data);
            toaster.pop("success", "The file was uploaded successfully");
            $scope.isLoading = false;
        }, function (response) {
            $scope.file = null;
            $scope.fileSent = "An Error Occurred, please refresh the page and try again";
            toaster.pop("error", "Failed to upload the file", "Please try again");
            $scope.isLoading = false;
        });

    }

    function setIfHasData(data) {
        $scope.hasData = data && ((data.create && data.create.length > 0) ||
            (data.update && data.update.length > 0) ||
            (data.remove && data.remove.length > 0));
    }

    function renderImportedVisits(data) {
        initSelections();
        renderImportedVisitsToCreate(data.create);
        renderImportedVisitsToUpdate(data.update);
        renderImportedVisitsToRemove(data.remove);
    }

    function renderImportedVisitsToCreate(items) {
        $scope.selectionLogic.toCreate.initItemsCollection(items);

        var options = {
            count: 10
        };
        $scope.visitsToCreateTableParams = new NgTableParams(options, {
            dataset: items
        });
    }

    function renderImportedVisitsToUpdate(items) {
        $scope.selectionLogic.toUpdate.initItemsCollection(items);

        var options = {
            count: 10
        };
        $scope.visitsToUpdateTableParams = new NgTableParams(options, {
            dataset: items
        });
    }

    function renderImportedVisitsToRemove(items) {
        $scope.selectionLogic.toRemove.initItemsCollection(items);

        var options = {
            count: 10
        };
        $scope.visitsToRemoveTableParams = new NgTableParams(options, {
            dataset: items
        });
    }

    $scope.openModalToApplyChanges = function () {
        var textVisitsToCreate = $scope.selectionLogic.toCreate.hasSelectedItems()
            ? "create " + $scope.selectionLogic.toCreate.countSelected() + " new visits"
            : "";
        var textVisitsToUpdate = $scope.selectionLogic.toUpdate.hasSelectedItems()
            ? "update " + $scope.selectionLogic.toUpdate.countSelected() + " visits"
            : "";
        var textVisitsToRemove = $scope.selectionLogic.toRemove.hasSelectedItems()
            ? "remove " + $scope.selectionLogic.toRemove.countSelected() + " existing visits"
            : "";
        var textsOfActions = [];
        if (textVisitsToCreate) { textsOfActions.push(textVisitsToCreate); }
        if (textVisitsToUpdate) { textsOfActions.push(textVisitsToUpdate); }
        if (textVisitsToRemove) { textsOfActions.push(textVisitsToRemove); }
        var contentText = "Are you sure you want to ";
        switch (textsOfActions.length) {
            case 3:
                contentText += textsOfActions[0] + ", " + textsOfActions[1] + " and " + textsOfActions[2];
                break;
            case 2:
                contentText += textsOfActions[0] + " and " + textsOfActions[1];
                break;
            case 1:
                contentText += textsOfActions[0];
                break;
        }
        contentText += " ?";

        SweetAlert.swal({
            title: "Broadcast Visits",
            text: contentText,
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, apply the changes",
            closeOnConfirm: true,
            customClass: 'swal-generate-invoices-modal',
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                return applyChangesOnServer();
            }
        });
    };

    function applyChangesOnServer() {
        return $q(function (resolve, reject) {

            var url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/apply_open_visits';

            var patternIds = [];
            var selectedToCreate = $scope.selectionLogic.toCreate.getSelectedItems();
            selectedToCreate.forEach(function (importedVisit) {
                patternIds.push(importedVisit.patternId);
            });
            var selectedToUpdate = $scope.selectionLogic.toUpdate.getSelectedItems();
            selectedToUpdate.forEach(function (importedVisit) {
                patternIds.push(importedVisit.patternId);
            });
            var selectedToRemove = $scope.selectionLogic.toRemove.getSelectedItems();
            selectedToRemove.forEach(function (importedVisit) {
                patternIds.push(importedVisit.patternId);
            });
            var body = {
                ids: patternIds
            };

            DatabaseApi.post(url, body).then(function (response) {
                clearData();
                toaster.pop("success", "All selected visits were broadcasted successfully");
                resolve();
            }, function (error) {
                toaster.pop("error", "Failed to broadcast the selected visits");
                reject();
            });
        });
    }

    function clearData() {
        $scope.file = null;
        $scope.fileSent = null;

        var data = {
            create: null,
            update: null,
            remove: null
        };
        setIfHasData(data);
        renderImportedVisits(data);
    }

    initialize();

};
