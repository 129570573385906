import moment from "moment";

//! @ngInject
export function billingNewPayrollBatchModalCtrl($scope, DatabaseApi, $rootScope, generalUtils, $uibModalInstance, toaster, $state) {
    $scope.selectedPayrollSetup = {};

    const allPayrollSetups = $scope.$resolve.payrollSetups;
    const activePayrollSetups = allPayrollSetups.filter(ps => ps.isActive);
    $scope.payrollSetupOptions = activePayrollSetups.map(ps => ({ id: ps.id, label: ps.name }));

    $scope.formParams = {
        tillDate: new Date(moment()),
    };

    $scope.calendarObject = {
        tillDate: false,
    };

    $scope.toggleCalendarObject = function (prop) {
        $scope.calendarObject[prop] = !$scope.calendarObject[prop];
    };

    $scope.isProcessing = false;

    $scope.generatePayrolls = function () {

        if(!$scope.selectedPayrollSetup.id) {
            toaster.pop("warning", "Please select a payroll setup");
            return;
        }
        $scope.isProcessing = true;

        var url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/payroll_batches";
        var body = {
            date: generalUtils.formatDateByFilter($scope.formParams.tillDate),
            payrollSetupId: $scope.selectedPayrollSetup.id
        };

        // console.log("generatePayrolls -> url=", url, ",  body=", body);

        DatabaseApi.post(url, body).then(function (response) {
            $scope.isProcessing = false;
            const data = response.data;
            $state.go('app.payroll.batch',
                {
                    id: data.payrollBatch.id,
                    payrollBatchWithVisits: response.data,
                    isDraft: true
                });

            $scope.closeModal(response);
        }, function (errorResponse) {
            $scope.isProcessing = false;
            $scope.closeModal({
                isError: true,
                message: errorResponse.data.error,
                response: errorResponse
            });
        });
    };

    $scope.closeModal = function(result) {
        if (!$scope.isProcessing) {
            $uibModalInstance.close(result);
        }
    };

    $scope.selectPatientTaskDocumentsExtraSettings = {
        scrollableHeight: "200px",
        showCheckAll: false,
        showUncheckAll: false
    };
    
    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false
    };
};