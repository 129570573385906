//! @ngInject
export function changeTeamMemberPasswordModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, SweetAlert, toaster) {
    const validPasswordPattern = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9\S])(.{8,30})$/);
    $scope.passwordValidationError = false;
    $scope.isPassword = true;

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };

    $scope.togglePasswordVisibility = () => {
        $scope.isPassword = !$scope.isPassword;
    }

    $scope.changeTeamMemberPassword = () => {
        if (!validPasswordPattern.test($scope.password)) {
            $scope.passwordValidationError = true;
            return;
        }

        $scope.passwordValidationError = false;

        SweetAlert.swal({
            title: 'Change Team Member Password',
            text: 'Are you sure you want to change ' + $scope.agencyMember.displayName + ' password?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                const url = `agencies/${$rootScope.agencyId}/coordinators/${$rootScope.agencyMemberId}/change_team_member_password`;
                const body = {
                    agencyMemberId: $scope.agencyMember.id,
                    password: $scope.password
                };

                DatabaseApi.put(url, body).then((res) => {
                    toaster.pop("success", "Successfully updated team member password");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop("error", "Failed to update team member password");
                });
            }
        });
    }
};