export const apiErrors = {
  format: (response: any, fallbackMessage?: string) => {
    if (response?.data?.message) {
      return response.data.message;
    }

    if (response.data?.error === "Invalid Request") {
      return response.data.details[0];
    }

    return fallbackMessage ?? "Something went wrong";
  },
};

export type ApiErrors = typeof apiErrors;
