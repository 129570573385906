export function anomaliesCtrl($scope, $rootScope, Socket, NgTableParams, $state, $http, toaster, DatabaseApi, $filter, $uibModal, Analytics, Consts, Storage, $timeout ) {
    var prosMap = DatabaseApi.caregivers() || {};
    var caregivers;

    $scope.$on('got_data', function(event) {
        prosMap = DatabaseApi.caregivers()||{};
        init(caregivers);
    })

    DatabaseApi.get("agencies/"+$rootScope.agencyId+"/"+$rootScope.agencyMemberId+"/caregivers_anomalies?min_distance=5&max_duration=4&min_duration=4&duration_diff=8&blocked_days_amt=3").then(function (response){

        if(!response.data.caregivers || !response.data.caregivers.length){
            return;
        }
        caregivers = response.data.caregivers;
        if(prosMap && Object.keys(prosMap).length){
            init(caregivers);
        }
    }, function (err) {
        toaster.pop("error","failed to fetch", "couldn't get caregiver anomalies");
    })

    $scope.goToProfile = function (id) {
        $rootScope.openCaregiverModal(id);
      };

    $scope.sendPushToCaregiver = function(id) {
        DatabaseApi.post("agencies/"+$rootScope.agencyId+"/"+$rootScope.agencyMemberId+"/caregivers/"+id+"/send_anomaly_notification").then(function (response) {
            Analytics.event('anomaly-notification-sent', {
                caregiverId: id
              });
            toaster.pop("success","pushed notification succefully", "");
        }, function(err) {
            toaster.pop("error","failed to push notification", "couldn't send notification to caregiver");
        })
    };

    $scope.sendPushToCaregivers = function() {
        var ids = caregivers.map(function (c) {
            return c.id;
        });

        DatabaseApi.post("agencies/" + $rootScope.agencyId + "/" +
            $rootScope.agencyMemberId + "/send_bulk_anomaly_notifications", {caregivers: ids}).then(function (response) {
            Analytics.event('anomaly-bulk-notification-sent', {
                count: ids.length
              });
            toaster.pop("success","pushed notification succefully", "");
        }, function(err) {
            toaster.pop("error","failed to push notification", "couldn't send notification to caregivers");
        })
    };

    function bindPros(problematiceCaregiver) {
        if(!problematiceCaregiver)
            return;

        caregivers = problematiceCaregiver.map(function (row, i) {
            row.anomalies.sort(function(a,b) {
                return b.length - a.length;
            })
            return angular.extend(problematiceCaregiver[i], prosMap[row.id.toString()]);
        });
        caregivers.sort(function(a,b) {
            return b.anomalies.length - a.anomalies.length
        })
    }

    function initTable(){
        var options = {
            count: 25
        }
        
        $scope.anomaliesTable = new NgTableParams(options, {
            countrs: [],
            dataset: caregivers
        });
    }
    
    function init(caregivers) {
        bindPros(caregivers);
        initTable();
    }
    
};

