import React from "react";
import { match } from "ts-pattern";
import LoadingPage from "../shared/components/LoadingPage";
import { useWebappChannelPayloadEvent } from "../shared/hooks/useWebappChannelEvent";
import { UserId } from "../shared/schema/schema";
import { Loadable, loadable } from "../shared/utils/loadable";
import { Messages, PostSuccessResponse } from "./api";
import webappChannel from "./webapp-channel";

export interface AuthInfo {
  userId: UserId;
  agency: Messages["Agency"];
  agencyMember: Messages["AgencyMember"];
  permissions: Messages["AgencyRolePermissions"]["permissions"][];
  authToken: string;
  refreshToken: string;
}

export const AuthContext = React.createContext<{
  authInfo: AuthInfo;
  setTokens: (tokens: { accessJWT: string; refreshJWT: string }) => void;
}>(null as any);

class UnauthorizedError extends Error {}

function saveTokensInLocalStorage(data: PostSuccessResponse<"/auth/token">) {
  const userStorage = localStorage.getItem("user");

  if (userStorage !== null) {
    const user = JSON.parse(userStorage);
    user.authToken = data.accessJWT;
    user.refreshToken = data.refreshJWT;
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [authInfo, setAuthInfo] = React.useState<Loadable<AuthInfo>>(loadable.loading());

  const handleSetTokens = React.useCallback((tokens: { accessJWT: string; refreshJWT: string }) => {
    saveTokensInLocalStorage(tokens);
    setAuthInfo((prev) => {
      if (prev.type === "Resolved") {
        return loadable.resolve({
          ...prev.value,
          authToken: tokens.accessJWT,
          refreshToken: tokens.refreshJWT,
        });
      }

      return prev;
    });
  }, []);

  useWebappChannelPayloadEvent({
    eventName: "AUTH_SET",
    runInitial: true,
    onEvent: () => {
      const info = webappChannel.getPayload("AUTH_SET") ?? null;

      return info !== null
        ? setAuthInfo(loadable.resolve(info))
        : setAuthInfo(loadable.reject(new UnauthorizedError()));
    },
  });

  return match(authInfo)
    .with({ type: "Loading" }, () => <LoadingPage />)
    .with({ type: "Rejected" }, ({ error }) => <div>{`${error}`}</div>)
    .with({ type: "Resolved" }, ({ value }) => (
      <AuthContext.Provider value={{ authInfo: value, setTokens: handleSetTokens }}>
        {props.children}
      </AuthContext.Provider>
    ))
    .exhaustive();
};
