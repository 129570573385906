import $ from "jquery";
import _ from "lodash";

//! @ngInject
export function TrainingCenterBundleItemSettingsCtrl($stateParams, $scope, $uibModal, $rootScope, DatabaseApi, $timeout, toaster, $http, Consts, wildcard) {
    $scope.bundleId = $stateParams.bundleId;
    $scope.bundleItemId = $stateParams.bundleItemId;

    const DEFAULT_LANGUAGE = 'English';
    const DEFAULT_SUBTITLES_LANGUAGE = 'Arabic';

    $scope.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
    $scope.DEFAULT_SUBTITLES_LANGUAGE = DEFAULT_SUBTITLES_LANGUAGE;

    $scope.allLanguages = [
        'English',
        'Spanish',
        'Russian',
        'Chinese',
        'French',
        'Arabic',
        'Creole'
    ];

    $scope.subtitlesLanguages = [
        'Arabic',
        'Bengali',
        'Chinese',
        'French',
        'Creole',
        'Hindi',
        'Polish',
        'Punjabi',
        'Russian',
        'Spanish',
        'Urdu',
        'Uzbek'
    ];

    $scope.medioOverlayFileTypes = [
      { id: "FILE", label: "File" },
      { id: "LOGO", label: "Agency Logo" },
    ];

    $scope.changeLanguage = (newLang) => {
        if ($scope.file.upload) {
            toaster.pop("warning", "Can't change language", "Can't change language while uploading file");
            return ;
        }

        $scope.activeLanguage.val = newLang;
    }

    $scope.changeSubtitleLanguage = (newLang) => {
        if ($scope.uploadSubtitles.fileUpload) {
            toaster.pop("warning", "Can't change language", "Can't change language while uploading subtitles file");
            return ;
        }

        $scope.activeSubtitlesLanguage.val = newLang;
    }

    $scope.handleToggleCollapseClick = (sectionId) => {
        $('#' + sectionId + ' div.ibox-content').slideToggle();
        $('#' + sectionId + ' .ibox-title .ibox-tools a.collapse-link i').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
    }

    $scope.fileStrategy = { val: undefined }; // "FILE" | "URL"

    $scope.file = {
        upload: null,
        partSize: 5 * 1024 * 1024
    };

    $scope.mediaFileUrl = {
        val: null,
        sending: false
    };

    $scope.videoRequiredWatchTimePercentInput = { val: null };

    function init() {
        $scope.refreshBundleItem();

        $scope.uploadThumbnail = {
            uiActive: false,
            fileUpload: null
        };

        $scope.uploadSubtitles = {
            uiActive: false,
            fileUpload: null
        };

        $scope.uploadPDFFile = {
            uiActive: false,
            fileUpload: null
        };

        $scope.mediaOverlayImage = {
            uiActive: false,
            fileUpload: null
        };

        $scope.fullDescriptionImage = {
            uiActive: false,
            fileUpload: null
        };
    }

    $timeout(init, 0);

    $scope.refreshBundleItem = function () {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId).then(function (res) {
            updateBundleItem(res.data);

            $scope.activeLanguage = { val: DEFAULT_LANGUAGE };
            $scope.activeSubtitlesLanguage = { val: DEFAULT_SUBTITLES_LANGUAGE };

            $scope.translatedBundleItemTitleInput = {};
            $scope.translatedBundleItemSectionHeaderInput = {};
            $scope.translatedBundleItemSectionDescriptionInput = {};
            $scope.translatedBundleItemLabelInput = {};
            $scope.translatedBundleItemDescriptionInput = {};
            $scope.translatedBundleItemTestSuccessMessageInput = {};
            $scope.translatedBundleItemTestFailMessageInput = {};
            $scope.translatedBundleItemTestRetryMessageInput = {};
            $scope.bundleItem.displayData.forEach(function (obj) {
                $scope.translatedBundleItemTitleInput[obj.language] = obj.data.title;
                $scope.translatedBundleItemSectionHeaderInput[obj.language] = obj.data.sectionHeader;
                $scope.translatedBundleItemSectionDescriptionInput[obj.language] = obj.data.sectionDescription;
                $scope.translatedBundleItemLabelInput[obj.language] = obj.data.label;
                $scope.translatedBundleItemDescriptionInput[obj.language] = obj.data.description;
                $scope.translatedBundleItemTestSuccessMessageInput[obj.language] = obj.data.testSuccessMessage;
                $scope.translatedBundleItemTestFailMessageInput[obj.language] = obj.data.testFailMessage;
                $scope.translatedBundleItemTestRetryMessageInput[obj.language] = obj.data.testRetryMessage;
            });

            $scope.bundleItem.mediaOverlays.forEach((overlay) => {
                overlay.untilSecond = overlay.atSecond + overlay.duration;
            });

            $scope.maxWrongAnswersInput = $scope.bundleItem.testParams.maxWrongAnswers;
            $scope.showWrongAnswersInput = $scope.bundleItem.testParams.showWrongAnswers;
            $scope.testMaxAttemptsAllowedInput = $scope.bundleItem.testParams.testMaxAttemptsAllowed;

            $scope.timeForCertificateMinutesInput = $scope.bundleItem.timeForCertificateMinutes;
            $scope.pdfFileDownloadable = { val: false };

            switch ($scope.bundleItem.media.type) {
                case 'PDF':
                    $scope.pdfRequiredViewTimeMinutesInput = $scope.bundleItem.media.requiredViewTimeMinutes;
                    $scope.pdfFileDownloadable.val = $scope.bundleItem.media.pdfFileDownloadable;
                    break;
                case 'Video':
                    $scope.videoRequiredWatchTimePercentInput.val = $scope.bundleItem.media.requiredWatchTimePercent;
                    break;
                case 'TestOnly':
                    break;
            }
        }, function (err) {
            toaster.pop("error", "something went wrong", "");
        });
    };

    function updateBundleItem(bundleItem) {
        $scope.bundleItem = bundleItem;

        if (bundleItem.media.type === "Video") {
            bundleItem.media.videoInfo.forEach(info => {
                info.data.isVertical = info.data.videoDimensions.width < info.data.videoDimensions.height
            })
            $scope.bundleItem.__videoInfo = _.keyBy(bundleItem.media.videoInfo, "language");
        }

        $scope.bundleItem.displayData.forEach(function (obj) {
            if ($scope.allLanguages.indexOf(obj.language) < 0) {
                $scope.allLanguages.push(obj.language);
            }
        });

        $scope.bundleItem.testQuestions.forEach(function (q) {
            q.forEach(function (obj) {
                if ($scope.allLanguages.indexOf(obj.language) < 0) {
                    $scope.allLanguages.push(obj.language);
                }
            });
        });

        addMissingLanguages();
        addEmptyQuestionAnswerSlots();
        
        /*-- Engagement Questions --*/
        if ($scope.bundleItem.engagementQuestions.length !== 0) {
            $scope.bundleItem.engagementQuestions.forEach(function (question) {
                question.displayData.forEach(function (obj) {
                    if ($scope.allLanguages.indexOf(obj.language) < 0) {
                        $scope.allLanguages.push(obj.language);
                    }
                });
            });
            
            addMissingEngagementQuestionsLanguages();
        }
        
        /*-- Media Overlays --*/
        if ($scope.bundleItem.mediaOverlays.length !== 0) {
            $scope.bundleItem.mediaOverlays.forEach(function (overlay) {
                overlay.overlayData.forEach(function (obj) {
                    if ($scope.allLanguages.indexOf(obj.language) < 0) {
                        $scope.allLanguages.push(obj.language);
                    }
                });
            });
            
            addMissingMediaOverlaysLanguages();
        }
    }

    function addMissingLanguages() {
        $scope.bundleItem.testQuestions.forEach(function (q) {
            $scope.allLanguages.forEach(function (lang) {
                if (q.find(t2 => t2.language === lang) === undefined) {
                    q.push({
                        language: lang,
                        data: {
                            questionText: '',
                            questionAnswers: ['', '']
                        }
                    });
                }
            });
        });
    }

    function addEmptyQuestionAnswerSlots() {
        $scope.bundleItem.testQuestions.forEach(function (q) {
            q.forEach(function (obj) {
                if (obj.data.questionAnswers[obj.data.questionAnswers.length - 1].trim().length !== 0) {
                    obj.data.questionAnswers.push('');
                } else if (obj.data.questionAnswers.length > 2 &&
                    obj.data.questionAnswers[obj.data.questionAnswers.length - 1].trim().length === 0 &&
                    obj.data.questionAnswers[obj.data.questionAnswers.length - 2].trim().length === 0) {
                    obj.data.questionAnswers.pop();
                }
            });
        });
    }

    function fileUploadNextPart() {
        if ($scope.file.uploadedParts.length >= $scope.file.numTotalParts) {
            $scope.fileSent = 'Thanks for uploading, the file was uploaded successfully: ' + $scope.file.upload.name;
            toaster.pop('success', "Success", "File Uploaded: " + $scope.file.upload.name);
            $scope.file = {
                upload: null
              };
            return;
        }

        let currentPartNum = 1;
        while ($scope.file.uploadedParts.indexOf(currentPartNum) >= 0) {
            currentPartNum++;
        }

        const fileStartOffset = $scope.file.partSize * (currentPartNum - 1) // "currentPartNum" starts at 1 (not 0)
        const blob = $scope.file.upload.slice(fileStartOffset, fileStartOffset + $scope.file.partSize);

        const formData = new FormData();
        formData.append('file', blob, $scope.file.upload.name);
        formData.append('partNumber', currentPartNum);
        formData.append('videoId', $scope.bundleItemId);
        formData.append('numTotalParts', $scope.file.numTotalParts);
        formData.append(
          "dimensions",
          JSON.stringify($scope.file.dimensions)
        );
        formData.append('language', $scope.activeLanguage.val || 'English');

        const { agencyId, agencyMemberId } = $rootScope;

        const url = wildcard(
            `${Consts.api}agencies/:agencyId/training_center/video_upload_part`,
            agencyId,
            agencyMemberId
        );

        $http({
            url: url,
            method: 'POST',
            data: formData,
            headers: {'Content-Type': undefined}
        }).then(({ status }) => {
            if (status >= 200 && status < 300) {
                $scope.file.uploadedParts.push(currentPartNum);
                $scope.file.uploadProgressPercent = 100 * $scope.file.uploadedParts.length / $scope.file.numTotalParts;
                fileUploadNextPart();
            } else {
                console.log('Unexpected response uploading file part. Will retry');
                console.log(response);
                $scope.file.retryCount++;
                setTimeout(fileUploadNextPart, 5000);
            }
        }, function (response) {
            console.log('Error uploading file part. Will retry');
            console.log(response);
            $scope.file.retryCount++;
            setTimeout(fileUploadNextPart, 5000);
        });

    }

    $scope.getBundleItemThumbnailUrl = function (lang) {
        if (!$scope.bundleItem) {
            return;
        }

        var thumbnailUrl = null;
        $scope.bundleItem.displayData.forEach(function (obj) {
            if (obj.language === lang) {
                thumbnailUrl = obj.data.thumbnailUrl;
            }
        });
        return thumbnailUrl;
    };

    $scope.getBundleItemSubtitlesFilename = function(lang) {
        if (!$scope.bundleItem) {
            return;
        }

        let videoSubtitles = null;

        $scope.bundleItem.media.videoSubtitles.forEach(function (obj) {
            if (obj.language === lang) {
                videoSubtitles = obj.data.subtitlesUrl;
            }
        });

        if (videoSubtitles === null) {
            return null;
        }

        return videoSubtitles;
    }

    $scope.getBundleItemPdfInfo = function (lang) {
        if (!$scope.bundleItem) {
            return;
        }

        if ($scope.bundleItem.media.type !== 'PDF') {
            return;
        }

        var data = null;
        $scope.bundleItem.media.pdfInfo.forEach(function (obj) {
            if (obj.language === lang) {
                data = obj.data;
            }
        });

        return data;
    };

    $scope.onBundleItemSettingsChange = function () {
        var params = {
            timeForCertificateMinutes: $scope.timeForCertificateMinutesInput
        };

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/edit', params).then(function (res) {
            // Ok, updated
        }, function err(err) {
            toaster.pop("error", "something went wrong", "");
        });
    };

    $scope.onBundleItemDisplayDataChange = function (lang) {
        console.log("title", lang, $scope.translatedBundleItemTitleInput[lang]);

        if (lang === DEFAULT_LANGUAGE ||
            $scope.translatedBundleItemTitleInput[lang] ||
            $scope.translatedBundleItemLabelInput[lang] ||
            $scope.translatedBundleItemDescriptionInput[lang] ||
            $scope.translatedBundleItemTestSuccessMessageInput[lang] ||
            $scope.translatedBundleItemTestFailMessageInput[lang] ||
            $scope.translatedBundleItemTestRetryMessageInput[lang]) {

            var params = {
                title: $scope.translatedBundleItemTitleInput[lang],
                sectionHeader: $scope.translatedBundleItemSectionHeaderInput[lang] || null,
                sectionDescription: $scope.translatedBundleItemSectionDescriptionInput[lang] || null,
                label: $scope.translatedBundleItemLabelInput[lang],
                description: $scope.translatedBundleItemDescriptionInput[lang],
                testSuccessMessage: $scope.translatedBundleItemTestSuccessMessageInput[lang] || null,
                testFailMessage: $scope.translatedBundleItemTestFailMessageInput[lang] || null,
                testRetryMessage: $scope.translatedBundleItemTestRetryMessageInput[lang] || null
            };

            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/languages/' + lang + '/display_data/edit', params).then(function (res) {
                // Ok, updated
            }, function err(err) {
                console.log()
            });
        } else {
            DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/languages/' + lang + '/display_data').then(function (res) {
                // Ok, updated
            }, function err(err) {
                console.log()
            });
        }
    }

    $scope.uploadBundleItemThumbnail = function () {
        console.log("Upload Bundle Item Thumbnail");

        var language = $scope.activeLanguage.val;

        var formData = new FormData();
        formData.append('file', $scope.uploadThumbnail.fileUpload, $scope.uploadThumbnail.fileUpload.name);

        $http({
            url: Consts.api + 'agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/bundle_item_thumbnail/languages/' + language + '/upload_bundle_item_thumbnail',
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (res) {
            toaster.pop('success', "Success", "File Uploaded: " + $scope.uploadThumbnail.fileUpload.name);
            $scope.uploadThumbnail = {
                uiActive: false,
                fileUpload: null
            };
            updateBundleItem(res.data);
        }, function (response) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.uploadThumbnail = {
                uiActive: false,
                fileUpload: null
            };
        });
    };

    $scope.uploadBundleItemPDFFile = function () {
        console.log("Upload Bundle Item PDF File");

        var language = $scope.activeLanguage.val;

        var formData = new FormData();
        formData.append('file', $scope.uploadPDFFile.fileUpload, $scope.uploadPDFFile.fileUpload.name);

        $http({
            url: Consts.api + 'agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/bundle_item_thumbnail/languages/' + language + '/upload_bundle_item_pdf_file',
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (res) {
            toaster.pop('success', "Success", "File Uploaded: " + $scope.uploadPDFFile.fileUpload.name);
            $scope.uploadPDFFile = {
                uiActive: false,
                fileUpload: null
            };
            updateBundleItem(res.data);
        }, function (response) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.uploadPDFFile = {
                uiActive: false,
                fileUpload: null
            };
        });
    };

    $scope.onPdfSettingsChange = function (pdfRequiredViewTimeMinutesInput) {
        if (pdfRequiredViewTimeMinutesInput !== null) {
            let params = {
                requiredViewTimeMinutes: pdfRequiredViewTimeMinutesInput,
                pdfFileDownloadable: $scope.pdfFileDownloadable.val
            };

            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/set_pdf_params', params).then(function (res) {
                // Ok, updated
            }, function err(err) {
                toaster.pop("error", "something went wrong", "");
            });
        }
    };

    $scope.onVideoSettingsChange = function () {
        if ($scope.videoRequiredWatchTimePercentInput.val !== null) {
            var params = {
                requiredWatchTimePercent: $scope.videoRequiredWatchTimePercentInput.val
            };

            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/set_video_params', params).then(function (res) {
                // Ok, updated
            }, function err(err) {
                toaster.pop("error", "something went wrong", "");
            });
        }
    };

    $scope.onTestSettingsChange = function () {
        var params = {
            maxWrongAnswers: $scope.maxWrongAnswersInput !== null ? $scope.maxWrongAnswersInput : undefined,
            showWrongAnswers: $scope.showWrongAnswersInput,
            testMaxAttemptsAllowed: $scope.testMaxAttemptsAllowedInput !== null ? $scope.testMaxAttemptsAllowedInput : undefined
        };

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/edit_test_params', params).then(function (res) {
            // Ok, updated
        }, function err(err) {
            toaster.pop("error", "something went wrong", "");
        });
    };

    $scope.onVideoOrientationChange = (language, isVertical) => {
        // It's ugly i know but it's the easiest way for Sarah
        const { agencyId, agencyMemberId } = $rootScope;
        const body = isVertical ? {
            height: 1920,
            width: 1080
        } : {
            height: 1080,
            width: 1920
        };
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/training_center/bundles/:trainingCenterBundleId/bundle_items/:trainingCenterBundleItemId/languages/:language/video_dimensions",
            agencyId,
            agencyMemberId,
            $scope.bundleId,
            $scope.bundleItemId,
            language
        );
        DatabaseApi.post(url, body).then(res => {
            // Ok, updated
        }, err => {
            toaster.pop("error", "something went wrong", "");
        })
    }

    $scope.uploadBundleItemSubtitles = function () {
        const language = $scope.activeSubtitlesLanguage.val;

        const formData = new FormData();
        formData.append('file', $scope.uploadSubtitles.fileUpload, $scope.uploadSubtitles.fileUpload.name);

        $http({
            url: Consts.api + 'agencies/' + $rootScope.agencyId + '/training_center/bundles/' + $scope.bundleId + '/bundle_items/' + $scope.bundleItemId + '/bundle_item_subtitles/languages/' + language + '/upload_bundle_item_subtitles',
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (res) {
            toaster.pop('success', "Success", "File Uploaded: " + $scope.uploadSubtitles.fileUpload.name);
            $scope.uploadSubtitles = {
                uiActive: false,
                fileUpload: null
            };
            updateBundleItem(res.data);
        }, function (response) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.uploadSubtitles = {
                uiActive: false,
                fileUpload: null
            };
        });
    };

    $scope.onAddQuestion = function (index) {
        $scope.bundleItem.testQuestions.splice(index, 0, [
            {
                language: DEFAULT_LANGUAGE,
                data: {
                    questionText: '',
                    questionAnswers: ['', '']
                }
            }
        ]);

        addMissingLanguages();
    };

    $scope.onDeleteTestQuestion = function (index) {
        $scope.bundleItem.testQuestions.splice(index, 1);
        $scope.onTestQuestionsChange();
    };

    $scope.onMoveTestQuestion = function (index, direction) {
        function swap(index1, index2) {
            var tmp = $scope.bundleItem.testQuestions[index1];
            $scope.bundleItem.testQuestions[index1] = $scope.bundleItem.testQuestions[index2];
            $scope.bundleItem.testQuestions[index2] = tmp;
        }

        switch (direction) {
            case 'UP':
                swap(index, index - 1);
                break;
            case 'DOWN':
                swap(index, index + 1);
                break;
        }

        $scope.onTestQuestionsChange();
    };

    function defaultTranslationExists(bundleQuestion, translationIndex) {
      if (bundleQuestion === undefined || translationIndex === undefined)
        return true;

      if (bundleQuestion[translationIndex].language === DEFAULT_LANGUAGE)
        return true;

      const defaultTranslation = bundleQuestion.find(
        (translation) => translation.language === DEFAULT_LANGUAGE
      );
      if (defaultTranslation) {
        const hasDefaultTranslation =
          defaultTranslation.data.questionText !== "" ||
          defaultTranslation.data.questionAnswers.some(
            (answer) => answer !== ""
          ) ||
          false;
        return hasDefaultTranslation;
      }

      return false;
    }

    function currentQuestionTextExists(bundleQuestion, translationIndex) {
      if (bundleQuestion === undefined || translationIndex === undefined)
        return true;

      const hasQuestion =
        bundleQuestion[translationIndex].data.questionText !== "";

      return hasQuestion;
    }

    function isEmpty(str) {
      if (typeof str === "string" && str.trim().length !== 0) return false;
      return true;
    }

    $scope.onTestQuestionsChange = function (bundleQuestion, translationIndex) {
      console.log("question", bundleQuestion);
      console.log("tran index", translationIndex);
      console.log(
        "default exists",
        defaultTranslationExists(bundleQuestion, translationIndex)
      );
      addEmptyQuestionAnswerSlots();

      if (!currentQuestionTextExists(bundleQuestion, translationIndex)) {
        toaster.pop(
          "warning",
          "Didn't save",
          "Question text not found"
        );

        return;
      }

      if (!defaultTranslationExists(bundleQuestion, translationIndex)) {
        toaster.pop(
          "error",
          "Didn't save translation",
          `Default translation needed (${DEFAULT_LANGUAGE})`
        );

        return;
      }

      let testQuestions = [];
      for (const question of $scope.bundleItem.testQuestions) {
        let questionTranslations = [];
        for (const translation of question) {
          if (isEmpty(translation.data.questionText)) continue;

          let questionAnswers = translation.data.questionAnswers.filter(
            (answer) => !isEmpty(answer)
          );
          if (questionAnswers.length >= 1) {
            questionTranslations.push({
              language: translation.language,
              data: {
                questionText: translation.data.questionText,
                questionAnswers: questionAnswers,
              },
            });
          }
        }
        if (questionTranslations.length > 0) {
            testQuestions.push(questionTranslations);
        }
      }

      let params = {
        testQuestions: testQuestions,
      };
      DatabaseApi.post(
        "agencies/" +
          $rootScope.agencyId +
          "/training_center/bundles/" +
          $scope.bundleId +
          "/bundle_items/" +
          $scope.bundleItemId +
          "/set_test_questions",
        params
      ).then(
        function (res) {
          // Ok, updated
        },
        function (err) {
          if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
            toaster.pop("error", "Questions not saved", err.data.error);
          } else {
            toaster.pop("error", "Something went wrong", "");
          }
        }
      );
    };

    $scope.$watch('file.upload', async function () {
        if ($scope.file && $scope.file.upload && $scope.file.upload.name){

            $scope.file.uploadedParts = [];
            $scope.file.numTotalParts = Math.ceil($scope.file.upload.size / $scope.file.partSize);
            $scope.file.uploadProgressPercent = 0;
            $scope.file.retryCount = 0;

            const { agencyId, agencyMemberId } = $rootScope;

            const url = wildcard(
                `${Consts.api}agencies/:agencyId/training_center/video_upload_query`,
                agencyId,
                agencyMemberId
            );

            $scope.file.dimensions = await getVideoDimensions($scope.file.upload);

            $http({
                url: url,
                method: 'POST',
                data: {
                    filename: $scope.file.upload.name,
                    language: $scope.activeLanguage.val || 'English'
                },
                headers: {'Content-Type': 'application/json'}
            }).then(({ data }) => {
                $scope.file.uploadedParts = data.uploadedPartNumbers
                $scope.file.uploadProgressPercent = 100 * $scope.file.uploadedParts.length / $scope.file.numTotalParts;
                fileUploadNextPart();
            }).catch(() => {
                $scope.fileSent = 'An Error Occurred, please refresh the page and try again';
                toaster.pop('error', "Something Went Wrong", 'Please try again');
            });
        }
    });

    $scope.insertMediaFileUrl = function () {
        if (!$scope.mediaFileUrl.val) {
            return;
        }

        $scope.mediaFileUrl.sending = true;

        const endpoint = wildcard(
            'agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/bundle_items/:trainingCenterBundleItemId/languages/:language/set_youtube_video',
            $rootScope.agencyId,
            $scope.bundleId,
            $scope.bundleItemId,
            $scope.activeLanguage.val
        )

        const body = {
            videoUrl: $scope.mediaFileUrl.val
        };

        DatabaseApi.post(endpoint, body)
        .then(({ data }) => {
            $scope.mediaFileUrl.val = null;
            $scope.mediaFileUrl.time = null;
            updateBundleItem(data);
            toaster.pop('success', 'Media url has been added', '');
        })
        .catch(() => {
            toaster.pop('error', 'Something went wrong', '');
        })
        .finally(() => {
            $scope.mediaFileUrl.sending = false;
        })
    };

    $scope.getVideoLength = function() {
        if ($scope.bundleItem.media.videoInfo !== undefined) {
            let foundVideo = $scope.bundleItem.media.videoInfo.find(video => video.language === $scope.activeLanguage.val)
            if (foundVideo === undefined) {
                foundVideo = $scope.bundleItem.media.videoInfo.find(video => video.language === DEFAULT_LANGUAGE);
            }
            return foundVideo.data.videoLengthSeconds;
        }
    }

    $scope.onAddEngagementQuestion = function (index) {

        const createURL = "agencies/" +
            $rootScope.agencyId +
            "/training_center/bundles/" +
            $scope.bundleId +
            "/bundle_items/" +
            $scope.bundleItemId +
            "/create_video_engagement_question";
            DatabaseApi.post(
                createURL,
                {}
            ).then(
            function (res) {
                const { questionId } = res.data;
                // Ok, updated
                $scope.bundleItem.engagementQuestions.splice(index, 0, {
                    questionId: questionId,
                    atSecond: 0,
                    duration: 20,
                    displayData: [{
                        language: DEFAULT_LANGUAGE,
                        data: {
                            questionText: '',
                            questionAnswers: ['', '']
                        }
                    }]
                });

                addMissingEngagementQuestionsLanguages();
        
                toaster.pop('success', 'Engagement Question', 'Created Successfully');
            },
            function (err) {
                if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                    toaster.pop("error", "Engagement Questions not saved", err.data.error);
                } else {
                    toaster.pop("error", "Something went wrong", "");
                }
            });
    };

    $scope.onDeleteVideoEngagementQuestion = function (question, index) {
        const params = {
            engagementQuestionId: question.questionId
        }
        DatabaseApi.post(
            "agencies/" +
                $rootScope.agencyId +
                "/training_center/bundles/" +
                $scope.bundleId +
                "/bundle_items/" +
                $scope.bundleItemId +
                "/remove_video_engagement_question",
            params
            ).then(
            function (res) {
                // Ok, updated
                toaster.pop('success', 'Engagement Question', 'Removed Successfully');
                $scope.bundleItem.engagementQuestions.splice(index, 1);
            },
            function (err) {
                toaster.pop("error", "Something went wrong", "Failed to remove engagement question");
            }
            );
    };

    function addMissingEngagementQuestionsLanguages() {
        $scope.bundleItem.engagementQuestions.forEach(function (q) {
            $scope.allLanguages.forEach(function (lang) {
                if (q.displayData.find(t2 => t2.language === lang) === undefined) {
                    q.displayData.push({
                        language: lang,
                        data: {
                            questionText: '',
                            questionAnswers: ['', '']
                        }
                    });
                }
            });
        });
    }

    $scope.onAddMediaOverlay = function (index) {

        const createURL = "agencies/" +
            $rootScope.agencyId +
            "/training_center/bundles/" +
            $scope.bundleId +
            "/bundle_items/" +
            $scope.bundleItemId +
            "/create_video_media_overlay";
            DatabaseApi.post(
                createURL,
                {}
            ).then(
            function (res) {
                const { itemId } = res.data;
                // Ok, updated
                $scope.bundleItem.mediaOverlays.splice(index, 0, {
                    itemId: itemId,
                    atSecond: 0,
                    duration: 5,
                    overlayData: [{
                        language: DEFAULT_LANGUAGE,
                        data: {
                        }
                    }]
                });

                addMissingMediaOverlaysLanguages();
        
                toaster.pop('success', 'Media Overlay', 'Created Successfully');
            },
            function (err) {
                if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                    toaster.pop("error", "Media Overlays not saved", err.data.error);
                } else {
                    toaster.pop("error", "Something went wrong", "");
                }
            });
    };

    $scope.onDeleteVideoMediaOverlay = function (overlay, index) {
        const params = {
            mediaOverlayId: overlay.itemId
        }
        DatabaseApi.post(
            "agencies/" +
                $rootScope.agencyId +
                "/training_center/bundles/" +
                $scope.bundleId +
                "/bundle_items/" +
                $scope.bundleItemId +
                "/remove_video_media_overlay",
            params
            ).then(
            function (res) {
                // Ok, updated
                toaster.pop('success', 'Media Overlay', 'Removed Successfully');
                $scope.bundleItem.mediaOverlays.splice(index, 1);
            },
            function (err) {
                toaster.pop("error", "Something went wrong", "Failed to remove Media Overlay");
            }
            );
    };

    function addMissingMediaOverlaysLanguages() {
        $scope.bundleItem.mediaOverlays.forEach(function (q) {
            $scope.allLanguages.forEach(function (lang) {
                if (q.overlayData.find(t2 => t2.language === lang) === undefined) {
                    q.overlayData.push({
                        language: lang,
                        data: {
                        }
                    });
                }
            });
        });
    }

    function hasIdenticalEngagementAtSecond(bundleQuestion) {
        const foundEngagement = $scope.bundleItem.engagementQuestions.filter(engagementQuestion => bundleQuestion.atSecond === engagementQuestion.atSecond)
        
        // The new engagement added is already a part of bundleItem.engagementQuestions..
        return foundEngagement.length > 1;
    }

    function hasIdenticalMediaOverlayAtSecond(bundleOverlay) {
        const foundEngagement = $scope.bundleItem.mediaOverlays.filter(mediaOverlay => bundleOverlay.atSecond === mediaOverlay.atSecond)
        
        // The new engagement added is already a part of bundleItem.mediaOverlays..
        return foundEngagement.length > 1;
    }

    function getBundleQuestionLanguageIndex(bundleQuestion, language) {
        const foundIndex = bundleQuestion.displayData.findIndex(questionLanguage => questionLanguage.language === language);
        return foundIndex;   
    }

    $scope.onEngagementQuestionsChange = function (bundleQuestion, translationIndex) {
        if (translationIndex === undefined) {
            translationIndex = getBundleQuestionLanguageIndex(bundleQuestion, $scope.activeLanguage.val);
        }
        console.log("question", bundleQuestion);
        console.log("tran index", translationIndex);
        console.log(
            "default exists",
            defaultTranslationExists(bundleQuestion.displayData, translationIndex)
        );

        if (!currentQuestionTextExists(bundleQuestion.displayData, translationIndex)) {
            toaster.pop(
                "warning",
                "Didn't save",
                "Question text not found"
            );

            return;
        }

        if (!defaultTranslationExists(bundleQuestion.displayData, translationIndex)) {
            toaster.pop(
                "error",
                "Didn't save translation",
                `Default translation needed (${DEFAULT_LANGUAGE})`
            );

            return;
        }
        
        if (bundleQuestion.displayData[translationIndex].data.questionAnswers.filter(answer => answer !== "").length === 0) {
            toaster.pop(
                "error",
                "Didn't save translation",
                `Answers needed in (${bundleQuestion.displayData[translationIndex].language})`
            );

            return;
        }

        if (hasIdenticalEngagementAtSecond(bundleQuestion)) {
            toaster.pop(
                "error",
                "Didn't save Engagement",
                `Already have engagement question at (${bundleQuestion.atSecond})`
            );

            return;
        }

        if (bundleQuestion.atSecond === undefined) {
            toaster.pop(
                "error",
                "Didn't save Engagement",
                `Time cannot be negative`
            );

            return;
        }

        if (bundleQuestion.atSecond < 0 || bundleQuestion.atSecond > $scope.getVideoLength()) {
            toaster.pop(
                "error",
                "Didn't save Engagement",
                `Time cannot be more than video length`
            );

            return;
        }

        const editURL = "agencies/" +
            $rootScope.agencyId +
            "/training_center/bundles/" +
            $scope.bundleId +
            "/bundle_items/" +
            $scope.bundleItemId +
            "/update_video_engagement_question";
        let params = {
            engagementQuestion: bundleQuestion,
        };
        DatabaseApi.post(
            editURL,
            params
        ).then(
        function (res) {
            // Ok, updated
            toaster.pop('success', 'Engagement Question', 'Updated Successfully');
        },
        function (err) {
            if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                toaster.pop("error", "Engagement Questions not saved", err.data.error);
            } else {
                toaster.pop("error", "Something went wrong", "");
            }
        });
    };

    function getBundleOverlayLanguageIndex(bundleOverlay, language) {
        const foundIndex = bundleOverlay.overlayData.findIndex(lang => lang.language === language);
        return foundIndex;   
    }

    $scope.onMediaOverlaysChange = function (bundleOverlay, translationIndex) {
        if (translationIndex === undefined) {
            translationIndex = getBundleOverlayLanguageIndex(bundleOverlay, $scope.activeLanguage.val);
        }
        console.log("Overlay", bundleOverlay);
        console.log("tran index", translationIndex);

        if (hasIdenticalMediaOverlayAtSecond(bundleOverlay)) {
            toaster.pop(
                "error",
                "Didn't save Engagement",
                `Already have Media Overlay at (${bundleOverlay.atSecond})`
            );

            return;
        }

        if (bundleOverlay.atSecond === undefined) {
            toaster.pop(
                "error",
                "Didn't save Media Overlay",
                `Start Time cannot be negative`
            );

            return;
        }
        if (bundleOverlay.untilSecond === undefined) {
            toaster.pop(
                "error",
                "Didn't save Media Overlay",
                `End Time cannot be negative`
            );

            return;
        }
        if (bundleOverlay.atSecond > bundleOverlay.untilSecond) {
            toaster.pop(
                "error",
                "Didn't save Media Overlay",
                `End Time cannot be smaller than Start Time`
            );

            return;
        }

        if (
          bundleOverlay.atSecond < 0 ||
          bundleOverlay.atSecond > $scope.getVideoLength() ||
          bundleOverlay.untilSecond < 0 ||
          bundleOverlay.untilSecond > $scope.getVideoLength()
        ) {
          toaster.pop(
            "error",
            "Didn't save Media Overlay",
            `Time cannot be more than video length`
          );

          return;
        }

        if (bundleOverlay.position === undefined) {
            bundleOverlay.position = "TOP"; // default to TOP
        }

        bundleOverlay.duration = bundleOverlay.untilSecond - bundleOverlay.atSecond;

        const editURL = "agencies/" +
            $rootScope.agencyId +
            "/training_center/bundles/" +
            $scope.bundleId +
            "/bundle_items/" +
            $scope.bundleItemId +
            "/update_video_media_overlay";
        let params = {
            mediaOverlay: bundleOverlay,
        };
        DatabaseApi.post(
            editURL,
            params
        ).then(
        function (res) {
            // Ok, updated
            toaster.pop('success', 'Media Overlay', 'Updated Successfully');
        },
        function (err) {
            if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                toaster.pop("error", "Media Overlays not saved", err.data.error);
            } else {
                toaster.pop("error", "Something went wrong", "");
            }
        });
    };

    $scope.uploadMediaOverlayDataImage = function ({ itemId }) {
        console.log("Upload Media Overlay Data Image");

        const language = $scope.activeLanguage.val;

        const formData = new FormData();
        formData.append('file', $scope.mediaOverlayImage.fileUpload, $scope.mediaOverlayImage.fileUpload.name);

        const url = Consts.api +
            'agencies/' + $rootScope.agencyId +
            '/training_center/bundles/' + $scope.bundleId +
            '/bundle_items/' + $scope.bundleItemId +
            '/bundle_item_media_overlay_image/' + itemId +
            '/languages/' + language +
            '/upload_bundle_item_media_overlay_image';
        $http({
            url: url,
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (res) {
            toaster.pop('success', "Success", "File Uploaded: " + $scope.mediaOverlayImage.fileUpload.name);
            $scope.mediaOverlayImage = {
                uiActive: false,
                fileUpload: null
            };
            $scope.refreshBundleItem();
        }).catch(function (response) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.mediaOverlayImage = {
                uiActive: false,
                fileUpload: null
            };
        });
    };

    $scope.handleMediaOverlayImageRemove = function(mediaOverlay) {
        console.log("Remove Media Overlay Data Image");

        const { itemId } = mediaOverlay;
        const language = $scope.activeLanguage.val;


        const overlayIndex = getBundleOverlayLanguageIndex(mediaOverlay, language);

        const removeURL = "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        $scope.bundleItemId +
        "/bundle_item_media_overlay_image/" + 
        itemId + 
        "/languages/" +
        language +
        "/remove_bundle_item_media_overlay_image";

        DatabaseApi.delete(removeURL)
        .then(function (res) {
            // Ok, updated
            toaster.pop('success', 'Media Overlay', 'Successfully Removed Image');
            if (overlayIndex !== -1) {
                mediaOverlay.overlayData[overlayIndex].data.imageUrl = undefined
            }
        })
        .catch(function (err) {
            if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                toaster.pop("error", "Media Overlays not saved", err.data.error);
            } else {
                toaster.pop("error", "Something went wrong", "");
            }
        });
    }

    function getVideoDimensions(file) {
      return new Promise((resolve, reject) => {
        if (!file) reject();

        const reader = new FileReader();

        reader.onload = () => {
          const dataUrl = reader.result;
          const videoId = "videoMain";
          const $videoEl = $('<video id="' + videoId + '"></video>');
          $("body").append($videoEl);
          $videoEl.attr("src", dataUrl);

          const videoTagRef = $videoEl[0];
          videoTagRef.addEventListener("loadedmetadata", (e) => {
            resolve({
              width: videoTagRef.videoWidth,
              height: videoTagRef.videoHeight,
            });
          });
        };

        reader.onerror = (error) => {
          toaster.pop("error", "Something Went Wrong", "Please try again");
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    }

    $scope.onAddFullDescriptionRow = function (index) {
      $scope.bundleItem.fullDescriptions.splice(index, 0, {
        order: index,
        fullDescriptionData: [
          {
            language: DEFAULT_LANGUAGE,
            data: {
                speechFiles: {}
            },
          },
        ],
      });
      addMissingFullDescriptionLanguages();

      const newDescriptionRow = $scope.bundleItem.fullDescriptions.find(
        (_obj, descIndex) => descIndex === index
      );
      const createURL =
        "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        $scope.bundleItemId +
        "/create_full_description";
      DatabaseApi.post(createURL, newDescriptionRow).then(
        function (res) {
          const { itemId } = res.data;
          newDescriptionRow.itemId = itemId;

          // Ok, updated
          toaster.pop("success", "Full Description", "Created Successfully");
        },
        function (err) {
          if (
            err &&
            err.data &&
            err.data.error &&
            err.data.error === "Missing default translation"
          ) {
            toaster.pop("error", "Full Descriptions not saved", err.data.error);
          } else {
            toaster.pop("error", "Something went wrong", "");
          }
        }
      );
    };

    $scope.onDeleteFullDescriptionRow = function (fullDescriptionRow, index) {
      const params = {
        itemId: fullDescriptionRow.itemId,
      };
      DatabaseApi.post(
        "agencies/" +
          $rootScope.agencyId +
          "/training_center/bundles/" +
          $scope.bundleId +
          "/bundle_items/" +
          $scope.bundleItemId +
          "/remove_full_description",
        params
      ).then(
        function (res) {
          // Ok, updated
          toaster.pop("success", "Full description", "Removed Successfully");
          $scope.bundleItem.fullDescriptions.splice(index, 1);
        },
        function (err) {
          toaster.pop(
            "error",
            "Something went wrong",
            "Failed to remove full description"
          );
        }
      );
    };

    function addMissingFullDescriptionLanguages() {
        $scope.bundleItem.fullDescriptions.forEach(function (q) {
            $scope.allLanguages.forEach(function (lang) {
                if (q.fullDescriptionData.find(t2 => t2.language === lang) === undefined) {
                    q.fullDescriptionData.push({
                        language: lang,
                        data: {
                            speechFiles: {}
                        }
                    });
                }
            });
        });
    }

    function getFullDescriptionRowLanguageIndex(fullDescriptionRow, language) {
        const foundIndex = fullDescriptionRow.fullDescriptionData.findIndex(descriptionDatum => descriptionDatum.language === language);
        return foundIndex;   
    }

    $scope.onFullDescriptionRowChange = function (fullDescriptionRow, translationIndex) {
        if (translationIndex === undefined) {
            translationIndex = getFullDescriptionRowLanguageIndex(fullDescriptionRow, $scope.activeLanguage.val);
        }
        fullDescriptionRow.isLoading = true;
        console.log("question", fullDescriptionRow);
        console.log(
            "default exists",
            defaultTranslationExists(fullDescriptionRow.fullDescriptionData, translationIndex)
        );

        if (!defaultTranslationExists(fullDescriptionRow.fullDescriptionData, translationIndex)) {
            toaster.pop(
                "error",
                "Didn't save translation",
                `Default translation needed (${DEFAULT_LANGUAGE})`
            );

            return;
        }

        const editURL = "agencies/" +
            $rootScope.agencyId +
            "/training_center/bundles/" +
            $scope.bundleId +
            "/bundle_items/" +
            $scope.bundleItemId +
            "/update_full_description";
            
        let params = {
            itemId: fullDescriptionRow.itemId,
            order: fullDescriptionRow.order,
            fullDescriptionData: fullDescriptionRow.fullDescriptionData
        };
        DatabaseApi.put(
            editURL,
            params
        ).then(
        function (res) {
            // Ok, updated
            toaster.pop('success', 'Full Description', 'Updated Successfully');
        })
        .catch(function (err) {
            if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                toaster.pop("error", "Full Descriptions not saved", err.data.error);
            } else {
                toaster.pop("error", "Something went wrong", "");
            }
        }).finally(() => {
            fullDescriptionRow.isLoading = false;
        });
    };

    $scope.uploadFullDescriptionRowDataImage = function ({ itemId }) {
        console.log("Upload Media Overlay Data Image");

        const language = $scope.activeLanguage.val;

        const formData = new FormData();
        formData.append('file', $scope.fullDescriptionImage.fileUpload, $scope.fullDescriptionImage.fileUpload.name);

        const url = Consts.api +
            'agencies/' + $rootScope.agencyId +
            '/training_center/bundles/' + $scope.bundleId +
            '/bundle_items/' + $scope.bundleItemId +
            '/bundle_item_full_description/' + itemId +
            '/languages/' + language +
            '/upload_bundle_item_full_description_image';
        $http({
            url: url,
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (res) {
            toaster.pop('success', "Success", "File Uploaded: " + $scope.fullDescriptionImage.fileUpload.name);
            $scope.fullDescriptionImage = {
                uiActive: false,
                fileUpload: null
            };
            updateBundleItem(res.data);
        }).catch(function (response) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.fullDescriptionImage = {
                uiActive: false,
                fileUpload: null
            };
        });
    };

    $scope.handleFullDescriptionRowDataImageRemove = function(fullDescriptionRow) {
        console.log("Remove Media Overlay Data Image");

        const { itemId } = fullDescriptionRow;
        const language = $scope.activeLanguage.val;

        const rowIndex = getFullDescriptionRowLanguageIndex(fullDescriptionRow, language);

        const removeURL = "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        $scope.bundleItemId +
        "/bundle_item_full_description/" + 
        itemId + 
        "/languages/" +
        language +
        "/remove_bundle_item_full_description_image";

        DatabaseApi.delete(removeURL)
        .then(function (res) {
            // Ok, updated
            toaster.pop('success', 'Full Description', 'Successfully Removed Image');
            if (rowIndex !== -1) {
                fullDescriptionRow.fullDescriptionData[rowIndex].data.imageUrl = undefined
            }
        })
        .catch(function (err) {
            if (err && err.data && err.data.error && err.data.error === "Missing default translation") {
                toaster.pop("error", "Full Descriptions not saved", err.data.error);
            } else {
                toaster.pop("error", "Something went wrong", "");
            }
        });
    }

    $scope.onClickSync = () => {
        $uibModal.open({
            templateUrl: 'admin/views/training-center-bundle-item-sync.html',
            size: 'lg',
            controller: 'trainingCenterBundleItemSync',
            resolve: {
                bundleId: () => $scope.bundleId,
                bundleItemId: () => $scope.bundleItemId
            },
            windowClass: "modal modal-slide-in-right uib-side-modal"
        });
    }

};