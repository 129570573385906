//! @ngInject
export function agencyCustomFieldsService(DatabaseApi) {
    /* Decalare factory return object */
    const service = {};

    // ============ Custom Field ================

    /**
     * Edit custom field subjects
     */
    service.editCustomField = (
      agencyId,
      agencyMemberId,
      agencyCustomFieldId,
      editCustomFieldParams
    ) => {
      const url = "agencies/:agencyId/agency_members/:agencyMemberId/custom_field/:agencyCustomFieldId"
        .replace(":agencyId", agencyId)
        .replace(":agencyMemberId", agencyMemberId)
        .replace(":agencyCustomFieldId", agencyCustomFieldId);

      return DatabaseApi.put(url, editCustomFieldParams);
    };

    /**
     * Create custom field subjects
     */
    service.createCustomField = (
      agencyId,
      agencyMemberId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/agency_members/:agencyMemberId/custom_fields"
        .replace(":agencyId", agencyId)
        .replace(":agencyMemberId", agencyMemberId);

      return DatabaseApi.post(url, customFieldParams);
    };

    /**
     * Update agency single selection custom field
    */
    service.updateAgencySingleSelectionCustomField = (
      agencyId,
      agencyMemberId,
      agencyCustomFieldId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/agency_members/:agencyMemberId/custom_field/:agencyCustomFieldId/single_selection_custom_field"
        .replace(":agencyId", agencyId)
        .replace(":agencyMemberId", agencyMemberId)
        .replace(":agencyCustomFieldId", agencyCustomFieldId);

      return DatabaseApi.patch(url, customFieldParams);
    };

    /*-------------------------------------
     *------- Entity Custom Fields --------
     *-------------------------------------/

    /**
     * Upsert custom field on Patient
     */
    service.upsertPatientCustomField = (
      agencyId,
      patientId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/patients/:patientId/custom_field"
        .replace(":agencyId", agencyId)
        .replace(":patientId", patientId);

      return DatabaseApi.post(url, customFieldParams);
    };

    /**
     * Upsert custom field on Caregiver
     */
    service.upsertCaregiverCustomField = (
      agencyId,
      caregiverId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/caregivers/:caregiverId/custom_field"
        .replace(":agencyId", agencyId)
        .replace(":caregiverId", caregiverId);

      return DatabaseApi.post(url, customFieldParams);
    };

    /**
     * Remove custom field on Patient
     */
    service.removePatientCustomField = (
      agencyId,
      patientId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/patients/:patientId/custom_field/:customFieldName"
        .replace(":agencyId", agencyId)
        .replace(":patientId", patientId)
        .replace(":customFieldName", customFieldParams.fieldName);

      return DatabaseApi.delete(url);
    };

    /**
     * Remove custom field on Caregiver
     */
    service.removeCaregiverCustomField = (
      agencyId,
      caregiverId,
      customFieldParams
    ) => {
      const url = "agencies/:agencyId/caregivers/:caregiverId/custom_field/:customFieldName"
        .replace(":agencyId", agencyId)
        .replace(":caregiverId", caregiverId)
        .replace(":customFieldName", customFieldParams.fieldName);

      return DatabaseApi.delete(url);
    };

    return service;
  };
