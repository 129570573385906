import { LocalDate, TemporalAdjusters, Month } from "js-joda";
import moment from "moment";
import _ from "lodash";

const CLICK_TYPE = {
  1: "LEFT",
  2: "MIDDLE",
  3: "RIGHT",
};

const DAYS = [
  {
    key: "sunday",
    name: "Sunday",
    short: "Sun",
  },
  {
    key: "monday",
    name: "Monday",
    short: "Mon",
  },
  {
    key: "tuesday",
    name: "Tuesday",
    short: "Tue",
  },
  {
    key: "wednesday",
    name: "Wednesday",
    short: "Wed",
  },
  {
    key: "thursday",
    name: "Thursday",
    short: "Thu",
  },
  {
    key: "friday",
    name: "Friday",
    short: "Fri",
  },
  {
    key: "saturday",
    name: "Saturday",
    short: "Sat",
  },
];

export const mfCalendar = {
  templateUrl: "admin/views/calendar.html",
  bindings: {
    calendarType: "<",
    items: "<",
    tasks: "<",
    actions: "<",
    extraColumns: "<",
    dailyEvents: "<",
    isLoading: "<",
    startDayOfWeek: "<",
    multipleDaysSelection: "<",
    itemsActions: "<",
    isAllowItemsCheck: "<",
    prologue: "<",
    onClickItem: "&",
    onCheckSingleItem: "&",
    onChangeView: "&",
    editShiftsOptions: "<",
    editShiftsParams: "<",
    changeShiftParams: "&",
    untilDateSelected: "&",
    isForwardShiftsDatePickerOpen: "<",
    editUntilMinDate: "<",
    editUntilMaxDate: "<",
    resetSelection: "&",
    onEditAssignedTask: "&",
  },
  //! @ngInject
  controller: function ($rootScope, $scope, entityNewVisitModalService, $filter, $timeout) {
    const vm = this;
    let isInit = false;

    const calendar = { val: undefined };

    const dayColumns = { val: [] };
    const daySelectionRange = { val: {} };
    const calendarActionsStyle = { val: {} };
    const selectionActions = {
      val: {
        actions: [
          // {
          //   label: "Select items",
          //   invoke: handleClickSelectItemsInRange,
          // },
          // {
          //   label: "Clear selection",
          //   invoke: handleClickClearItemsSelection,
          // },
          {
            label: "Close",
            invoke: handleClickResetDaysSelection,
          },
        ],
      },
    };

    const selectedMonth = { val: LocalDate.now().month().value() };
    const selectedYear = { val: LocalDate.now().year() };

    let itemsActions;
    $scope.isDayActionsOpen = false;

    vm.$onInit = () => {
      itemsActions = $scope.$ctrl.itemsActions;
      getSelectedItems();
    };

    vm.$onChanges = (changes) => {
      const startDayOfWeek = vm.startDayOfWeek || 0;

      if ("startDayOfWeek" in changes) {
        dayColumns.val = getDays({ startDayOfWeek });
      }

      if ("items" in changes && isInit) {
        setCalendar({ startDayOfWeek });
        calculatePermanentVisitParams();
      }

      if ("actions" in changes) {
        selectionActions.val.customActions = vm.actions;
      }

      isInit = true;
    };

    function setCalendar({ startDayOfWeek }) {
      const { from, to, weeks } = getMonthArrayWithParams({ startDayOfWeek });

      calendar.val = weeks;

      if ($scope.$ctrl.onChangeView()) {
        $scope.$ctrl.onChangeView()({ from, to });
      }

    }

    function getMonthArrayWithParams({ startDayOfWeek }) {
      return getMonthArray({
        month: selectedMonth.val,
        year: selectedYear.val,
        items: $scope.$ctrl.items,
        startDayOfWeek: startDayOfWeek,
        extraColumns: $scope.$ctrl.extraColumns || [],
        dailyEvents: $scope.$ctrl.dailyEvents || [],
      });
    }

    function resetDaysSelection() {
      $scope.isDayActionsOpen = false;
      daySelectionRange.val = {};
      calendarActionsStyle.val = {};
    }

    function handleSelectMonth(month) {
      selectedMonth.val = month;
    }

    function handleClickPreviousMonth() {
      if (selectedMonth.val === Month.JANUARY.value()) {
        selectedYear.val--;
      }

      selectedMonth.val = Month.of(selectedMonth.val).minus(1).value();
    }

    function handleClickNextMonth() {
      if (selectedMonth.val === Month.DECEMBER.value()) {
        selectedYear.val++;
      }

      selectedMonth.val = Month.of(selectedMonth.val).plus(1).value();
    }

    function handleSelectYear(year) {
      selectedYear.val = year;
    }

    function handleClickPreviousYear() {
      selectedYear.val--;
    }

    function handleClickNextYear() {
      selectedYear.val++;
    }

    function handleClickToday() {
      const now = LocalDate.now();
      handleSelectYear(now.year());
      handleSelectMonth(now.month().value());
    }

    function handleMouseDownDay($event, day) {
      if (CLICK_TYPE[$event.which] !== "LEFT") {
        return;
      }

      daySelectionRange.val = { day: day };
    }

    const getLeft = ($event) => {
      const maxWidth = document.querySelector("body").clientWidth;
  
      return $event.clientX + 150 > maxWidth
        ? $event.clientX - 150
        : $event.clientX;
    };

    function setCalendarActionStyle($event) {
        daySelectionRange.val.items = getItemsInDaysSelection(vm.items, daySelectionRange.val);
        const calendarRect = document.querySelector(".calendar").getBoundingClientRect();
        const maxWidth = document.querySelector("body").clientWidth;

        calendarActionsStyle.val = {
            top: $event.clientY - calendarRect.y,
            left: $event.clientX - calendarRect.x + ($event.clientX + 150 > maxWidth ? -150 : 0),
        };

        console.log(`calendarActionsStyle`, calendarActionsStyle)
    }

    function handleMouseUpDay($event, day) {
        daySelectionRange.val.items = getItemsInDaysSelection(vm.items, daySelectionRange.val);
        setCalendarActionStyle($event);
    }

    $scope.handleDayClick = ($event, day) => {
      daySelectionRange.val = { day: day };
      $scope.isDayActionsOpen = true;
      setCalendarActionStyle($event);
    }

    function handleMouseEnterDay(day) {
      //
    }

    function handleBlurDay($event, day) {
      if (!$event.relatedTarget) {
        return;
      }

      if (!$event.relatedTarget.classList.contains("day-background")) {
        $scope.isDayActionsOpen = false;
      }
    }

    function handleClickAction({ invoke }) {
      const selection = daySelectionRange.val;
      const items = getItemsInDaysSelection($scope.$ctrl.items, selection);

      invoke({ ...selection, items });

      resetDaysSelection();
    }

    function handleClickResetDaysSelection() {
      resetDaysSelection();
    }

    function handleClickSelectItemsInRange(selection) {
      const items = getItemsInDaysSelection($scope.$ctrl.items, selection);

      for (const item of items) {
        item.checked = true;
      }
    }

    function handleClickClearItemsSelection(selection) {
      const items = getItemsInDaysSelection($scope.$ctrl.items, selection);

      for (const item of items) {
        item.checked = false;
      }
    }

    function handleClickItem(item) {
      const onClick = $scope.$ctrl.onClickItem();

      if (onClick) {
        onClick(item);
      }
    }

    function handleCheckItem(e, item) {
      e.stopPropagation();

      if ($scope.$ctrl.onCheckSingleItem()) {
        $scope.$ctrl.onCheckSingleItem()(item);
      }
    }

    function handleClickOpenCaregiver(
      $event,
      { caregiverId, shouldOpenExternal }
    ) {
      $event.stopPropagation();

      if (shouldOpenExternal) {
        return $rootScope.openCaregiverNewTab(caregiverId);
      }

      return $rootScope.openCaregiverModal(caregiverId);
    }

    function handleClickOpenPatient($event, { patientId, shouldOpenExternal }) {
      $event.stopPropagation();

      if (shouldOpenExternal) {
        return $rootScope.openPatientNewTab(patientId);
      }

      return $rootScope.openPatientModal(patientId);
    }

    function updateSelectedItems() {
      if (calendar && calendar.val !== undefined) {
        const selectedItemsKeys = [
          ...$scope.selectedVisits.map(x => x.key),
          ...$scope.selectedVacations.map(x => x.key)
        ];
        calendar.val.forEach(week => week.days.forEach(day => day.items.forEach(item => {
          item.checked = selectedItemsKeys.includes(item.key);
        })));
      }
    }

    $scope.showCalendarEditShiftsOptions = false;
    $scope.selectionType = null;
    $scope.selectionTypeText = null;
    $scope.selectedBatchId = null;
    $scope.selectedPatternStartTime = null;
    $scope.selectedPatternEndTime = null;
    $scope.selectedDaysOfWeek = null;
    $scope.highlightSelectedMode = false;
    $scope.hoverBatchId = null;
    $scope.highlightHoverMode = false;

    $rootScope.$on("close_new_visit_modal", () => {
      setSelectionType();
    });

    $rootScope.$on("open_delete_visit_modal", () => {
      setSelectionType(true);
    });

    $rootScope.$on("close_delete_visit_modal", () => {
      setSelectionType();
    });

    $rootScope.$on("close_missed_visit_modal", () => {
      setSelectionType();
    });

    function setSelectionType(resetSelection) {
      if ($scope.$ctrl.calendarType !== "PATIENT") {
        return;
      }
      $scope.selectionType = resetSelection || $scope.selectedVacations.length > 0 ? null : entityNewVisitModalService.getSelectionType($scope.selectedVisits);
      $scope.showCalendarEditShiftsOptions = $scope.selectionType !== null;
      $scope.selectionTypeText = getSelectionTypeText($scope.selectionType);
      setDefaultUntilDate();
    }

    function setDefaultUntilDate() {
      if ($scope.$ctrl.editShiftsParams && $scope.$ctrl.editShiftsParams.type === 'UNTIL_DATE' &&
          $scope.$ctrl.editUntilMinDate && !$scope.$ctrl.editShiftsParams.untilDate) {
        $scope.$ctrl.editShiftsParams.untilDate = angular.copy($scope.$ctrl.editUntilMinDate);
      }
    }

    function getSelectionTypeText(selectionType, selectedVisits, isEnd) {
      selectedVisits = selectedVisits || $scope.selectedVisits;
      isEnd = isEnd === undefined ? true : isEnd;

      switch (selectionType) {
        case entityNewVisitModalService.selectionTypes.singles:
          return buildSelectionPartText(selectedVisits, "single", isEnd);

        case entityNewVisitModalService.selectionTypes.permanents:
        case entityNewVisitModalService.selectionTypes.permanentsMixed:
          let text = buildSelectionPartText(selectedVisits, "permanent", isEnd);

          if (selectionType === entityNewVisitModalService.selectionTypes.permanentsMixed) {
            text += " (mixed series)";
          }

          return text;
        
        case entityNewVisitModalService.selectionTypes.mixed:
          const permanents = selectedVisits.filter(visit => visit.visitBatchType === "PERMANENT");
          const singles = selectedVisits.filter(visit => visit.visitBatchType === "SINGLE");
          const arePermanetsFromSameSeries = (new Set(permanents.map(pv => pv.visitBatchId))).size <= 1;

          return getSelectionTypeText(
            entityNewVisitModalService.selectionTypes.singles,
            singles,
            false
          ) +
          ", and " +
          getSelectionTypeText(
            arePermanetsFromSameSeries
              ? entityNewVisitModalService.selectionTypes.permanents
              : entityNewVisitModalService.selectionTypes.permanentsMixed,
            permanents
          );

        default:
          return "";
      }
    }

    function buildSelectionPartText(selectedVisits, type, end) {
      const visitWord = selectedVisits.length > 1 ? "visits" : "visit";

      return `${selectedVisits.length} ${type} ${visitWord}${end ? " selected." : ""}`;
    }

    function onResetSelection() {
      if ($scope.$ctrl.resetSelection()) {
        $scope.$ctrl.resetSelection()();
      }
    }

    function onEditAssignedTask($event, item) {
      $event.stopPropagation();
      if ($scope.$ctrl.onEditAssignedTask()) {
        $scope.$ctrl.onEditAssignedTask()(item.payload);
      }
    }

    function changeShiftParams(type) {
      if ($scope.$ctrl.changeShiftParams()) {
        $scope.$ctrl.changeShiftParams()(type);
      }
    }

    function untilDateSelected() {
      $scope.$ctrl.untilDateSelected()();
    }

    function getSelectedVisits() {
      $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
    }

    function calculatePermanentVisitParams() {
      if ($scope.$ctrl.calendarType !== "PATIENT") {
        return;
      }
      const oldEditMode = $scope.showCalendarEditShiftsOptions;
      setSelectionType();

      if (oldEditMode === false && $scope.showCalendarEditShiftsOptions === true) {
        changeShiftParams('CURRENT_SHIFTS');
      }

      $scope.selectedBatchId = null;
      $scope.hoverBatchId = null;
      $scope.selectedPatternStartTime = null;
      $scope.selectedPatternEndTime = null;
      $scope.highlightSelectedMode = false;
      $scope.highlightHoverMode = false;
      $scope.selectedDaysOfWeek = null;
      if ($scope.selectedVisits.length > 0 && $scope.$ctrl.items) {
        $scope.selectedBatchId = $scope.selectedVisits[0].visitBatchId;
        $scope.selectedPatternStartTime = $filter("mfShortDate")($scope.selectedVisits[0].visitBatchMinStartDate);
        $scope.selectedPatternEndTime = $filter("mfShortDate")($scope.selectedVisits[0].visitBatchMaxEndDate);
        $scope.selectedDaysOfWeek = daysOfWeekToString(
          _.uniqBy(
            $scope.$ctrl.items
            .map(i => ({ ...i, dayOfWeek: moment(i.payload.startTime).format('dddd') }))
            .filter(i => i.payload.visitBatchId === $scope.selectedBatchId),
            'dayOfWeek'
          )
          .sort((a,b) => Number(moment(a.payload.startTime).format('d')) - Number(moment(b.payload.startTime).format('d')))
          .map(i => `<b>${i.dayOfWeek}</b>`)
        );
      }
    }

    function getSelectedItems() {
      getSelectedVisits();
      getSelectedVacations();

      if ($scope.$ctrl.calendarType === "PATIENT") {
        calculatePermanentVisitParams();
        setSelectionType();
      }
    }

    function daysOfWeekToString(days) {
      if (!days || !days.length) return '';

      const last = days.pop();
      const str = days.join(', ');

      return str !== '' ? `${str} and ${last}` : last;
    }

    function getSelectedVacations() {
      $scope.selectedVacations = entityNewVisitModalService.selectedItems.vacations;
    }

    entityNewVisitModalService.registerObserverCallback(
      "visits",
      "mfCalendar",
      () => {
        getSelectedVisits();
        calculatePermanentVisitParams();
        updateSelectedItems();
      }
    );

    entityNewVisitModalService.registerObserverCallback(
      "vacations",
      "mfCalendar",
      () => {
        getSelectedVacations();
        setSelectionType();
        updateSelectedItems();
      }
    );

    vm.$onDestroy = () => {
      entityNewVisitModalService.unregisterObserverCallback("visits", "mfCalendar");
      entityNewVisitModalService.unregisterObserverCallback("vacations", "mfCalendar");
    };

    $scope.$watchGroup(["selectedMonth.val", "selectedYear.val"], () => {
      setCalendar({ startDayOfWeek: vm.startDayOfWeek || 0 });
    });

    // callbacks
    $scope.handleSelectMonth = handleSelectMonth;
    $scope.handleSelectYear = handleSelectYear;
    $scope.handleClickPreviousMonth = handleClickPreviousMonth;
    $scope.handleClickNextMonth = handleClickNextMonth;
    $scope.handleClickPreviousYear = handleClickPreviousYear;
    $scope.handleClickNextYear = handleClickNextYear;
    $scope.handleClickToday = handleClickToday;
    $scope.handleClickResetDaysSelection = handleClickResetDaysSelection;
    $scope.handleMouseDownDay = handleMouseDownDay;
    $scope.handleMouseUpDay = handleMouseUpDay;
    $scope.handleMouseEnterDay = handleMouseEnterDay;
    $scope.handleBlurDay = handleBlurDay;
    $scope.handleClickItem = handleClickItem;
    $scope.handleCheckItem = handleCheckItem;
    $scope.handleClickAction = handleClickAction;
    $scope.handleClickOpenCaregiver = handleClickOpenCaregiver;
    $scope.handleClickOpenPatient = handleClickOpenPatient;
    $scope.changeShiftParams = changeShiftParams;
    $scope.untilDateSelected = untilDateSelected;
    $scope.onResetSelection = onResetSelection;
    $scope.onEditAssignedTask = onEditAssignedTask;

    $scope.dayColumns = dayColumns;
    $scope.months = Month.values().map((x) => x.value());
    $scope.years = getYears();
    $scope.selectedMonth = selectedMonth;
    $scope.selectedYear = selectedYear;
    $scope.calendar = calendar;
    $scope.daySelectionRange = daySelectionRange;
    $scope.calendarActionsStyle = calendarActionsStyle;
    $scope.selectionActions = selectionActions;
    $scope.itemsActions = itemsActions;
  },
};

function getItemsInDaysSelection(items, selection) {
  if (selection.day === undefined) {
    return [];
  }
  return items.filter((item) => item.date.toEpochDay() === selection.day.date.toEpochDay());
}

function getYears() {
  const years = [];
  const currentYear = LocalDate.now().year();

  for (let i = currentYear - 3; i <= currentYear + 1; i++) {
    years.unshift(i);
  }

  return years;
}

function getMonthArray({
  year,
  month,
  items,
  dailyEvents,
  startDayOfWeek,
  extraColumns,
}) {
  const weeks = [];
  const { from, to, totalWeeks } = getCalendarMonthView({
    year,
    month,
    startDayOfWeek,
  });
  let currentDay = LocalDate.from(from);

  for (let i = 0; i < totalWeeks; i++) {
    const week = {
      days: [],
      extra: [],
    };

    for (let j = 0; j < 7; j++) {
      week.days.push({
        date: currentDay,
        items: items.filter((x) => x.date.equals(currentDay)),
        events: dailyEvents.filter((x) => x.date.equals(currentDay)),
        isInCurrentMonth: month === currentDay.month().value(),
      });
      currentDay = currentDay.plusDays(1);
    }

    for (const customColumn of extraColumns) {
      week.extra.push({
        ...customColumn,
        html: customColumn.html(week.days),
      });
    }

    weeks.push(week);
  }

  return { from, to, weeks };
}

function getCalendarMonthView({ year, month, startDayOfWeek }) {
  const firstDayOfMonth = LocalDate.of(year, month, 1);
  const lastDayOfMonth = firstDayOfMonth.with(
    TemporalAdjusters.lastDayOfMonth()
  );

  let from = firstDayOfMonth.minusDays(
    getDayOfWeekOrdinal(firstDayOfMonth, startDayOfWeek)
  );

  if (from.isAfter(firstDayOfMonth)) {
    from = from.minusWeeks(1);
  }

  const to = lastDayOfMonth.plusDays(
    6 - getDayOfWeekOrdinal(lastDayOfMonth, startDayOfWeek)
  );

  const totalWeeks = Math.ceil((to.toEpochDay() - from.toEpochDay()) / 7);

  return { from, to, totalWeeks };
}

function getDays({ startDayOfWeek }) {
  const days = [...DAYS];

  for (let i = 0; i < startDayOfWeek; i++) {
    const day = days.shift();
    days.push(day);
  }

  return days;
}

function getLastDateOfWeek(year, month, weekIdx, startDayOfWeek) {
  return new Date(year, month, weekIdx * 7 + 6 + startDayOfWeek);
}

function getTotalWeeksInMonth(year, month, startDayOfWeek) {
  // Get the first day of week week day (0: Sunday, 1: Monday, ...)
  const firstDayOfWeek = startDayOfWeek || 0;

  const firstOfMonth = new Date(year, month, 1);
  const lastOfMonth = new Date(year, month + 1, 0);
  const numberOfDaysInMonth = lastOfMonth.getDate();
  const firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;

  const used = firstWeekDay + numberOfDaysInMonth;

  return Math.ceil(used / 7);
}

function getDayOfWeekOrdinal(localDate, startDayOfWeek) {
  const ordinal = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  }[localDate.dayOfWeek().name()];

  return (ordinal - startDayOfWeek) % 7;
}
