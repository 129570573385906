"use strict";

/*! @ngInject */
export function agencyMemberMentionsInboxCtrl(
  $scope,
  $rootScope,
  $timeout,
  $stateParams,
  NgTableParams,
  toaster,
  DatabaseApi,
  agencyMemberMentionsService,
  noteService,
  entityNotesModalService,
  Storage,
  noteConsts,
  SweetAlert,
  selectionLogic,
  createDropdownFilter,
) {
  $scope.dropdownFilterEvents = {
    onSelectionChanged: () => applyFilters(),
  };

  $scope.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();

  $scope.statuses = [
    { value: "NONE", text: "---", statusClass: "" },
    { value: "VIEWED", text: "Viewed", statusClass: "lightblue" },
    { value: "IN_PROGRESS", text: "In progress", statusClass: "orange" },
    { value: "DONE", text: "Done", statusClass: "green" }
  ];

  $scope.statusesHash = {
    "NONE": { value: "NONE", text: "---", statusClass: "" },
    "VIEWED": { value: "VIEWED", text: "Viewed", statusClass: "lightblue" },
    "IN_PROGRESS": { value: "IN_PROGRESS", text: "In progress", statusClass: "orange" },
    "DONE": { value: "DONE", text: "Done", statusClass: "green" }
  };

  $scope.filterBy = {
    dropdowns: [
      {
        title: "Status",
        entityPath: ["status"],
        values: $scope.statuses.filter(status => status.value !== 'DONE').map(_status => ({
          id: _status.value
        })),
        options: $scope.statuses.map(status => ({
          id: status.value,
          label: status.text
        }))
      },
    ]
  };

  const dropdownFilterManager = createDropdownFilter({
    dropdowns: $scope.filterBy.dropdowns
  });

  function initialize() {
    $scope.caregiversMap = DatabaseApi.caregivers();
    $scope.patientsMap = DatabaseApi.patients();
    initTableColumns();
    $scope.loadItems();
    
    if ($stateParams.mentionId && $stateParams.noteId) {
      openMentionById($stateParams.mentionId, $stateParams.noteId, null)
    }
  }

  function initTableColumns() {
    var columns = Storage.getObject("agencyMemberMentionsTableSettings");

    if (columns && Object.keys(columns).length) {
      Object.keys(columns).forEach(function (t) {
        if (columns[t]) $scope.tableColumnsLength++;
      });

      $scope.tableColumns = columns;
    } else {
      $scope.tableColumns = {
        "Date posted": true,
        "Posted by": true,
        Note: true,
        Category: true,
      };
    }

    $scope.$watch(
      "tableColumns",
      function () {
        if ($scope.tableColumns) {
          Storage.setObject(
            "agencyMemberMentionsTableSettings",
            $scope.tableColumns
          );
          $scope.tableColumnsLength = 0;
          Object.keys($scope.tableColumns).forEach(function (t) {
            if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
          });
          //   filterTable();
        }
      },
      true
    );
  }

  $scope.globalFilter = { val: "" };
  $scope.applyGlobalSearch = function (term) {
    var filter = { $: term };
    if ($scope.tableParams) {
      angular.extend($scope.tableParams.filter(), filter);
      $timeout(() => $scope.selectionLogic.initItemsCollection($scope.tableParams.data));
    } else {
      console.log("no table");
    }
  };

  function mapNotesMentionsData() {
    const caregiversLen = Object.keys($scope.caregiversMap).length;
    const patientsLen = Object.keys($scope.patientsMap).length;

    $scope.mentions = $scope.mentions.map(note => {
      const status = $scope.statusesHash[note.status];
      let patients = [];
      let caregivers = [];

      if (note.noteType === noteConsts.NoteTypes.BULK) {
        if (note.caregiverIds !== null && $scope.caregiversMap && caregiversLen > 0) {
          note.caregiverIds.forEach((id) => {
            caregivers.push($scope.caregiversMap[id]);
          })
        }

        if (note.patientIds !== null && $scope.patientsMap && patientsLen > 0) {
          note.patientIds.forEach((id) => {
            patients.push($scope.patientsMap[id]);
          })
        }
      } else {
        if (note.caregiverId !== null && $scope.caregiversMap && caregiversLen > 0) {
          caregivers.push($scope.caregiversMap[note.caregiverId]);
        }

        if (note.patientId !== null && $scope.patientsMap && patientsLen > 0) {
          patients.push($scope.patientsMap[note.patientId]);
        }
      }

      const subjectText = note.subject ? note.subject : noteConsts.NoteTypesTranslations[note.noteType];

      return {
        ...note,
        subjectText,
        patients,
        caregivers,
        statusText: status.text,
        statusClass: status.statusClass,
      };
    });

    $scope.mentions.forEach((note) => {
      $scope.selectionLogic.initItem(note);
    });
  }

  function initTable(mentions) {
    $scope.tableData = mentions;
    var options = {
      count: 25,
      sorting: { createdAt: "desc" },
    };
    $scope.tableParams = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: $scope.tableData,
    });

    if ($scope.globalFilter.val) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
  }

  const getProfile = (note) => {
    let profileType = "Caregiver";
    let profileId = note.caregiverId;
    let profileName;
    if (note.caregiverId === null && note.patientId !== null) {
      profileType = "Patient";
      profileId = note.patientId;
      const patient = $scope.patientsMap[note.patientId];
      if (patient) {
        profileName = patient.displayName;
      }
    } else {
      const caregiver = $scope.caregiversMap[note.caregiverId];
      if (caregiver) {
        profileName = caregiver.displayName;
      }
    }
    return {
      type: profileType,
      id: profileId,
      profileName: profileName
    };
  };

  const initSelection = function () {
    $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
      $scope.selectionLogic.addItemToCollection(item);
    }, "mentionId");
  };

  const openMentionById = (mentionId, noteId, seenAt) => agencyMemberMentionsService
    .markMentionsAsRead([mentionId])
    .then(async () => {
      const noteDetails = await noteService.getNoteDetails(noteId);
      const profile = getProfile(noteDetails);
      entityNotesModalService.handleNotesModalOpen({
        profileId: profile.id,
        notesType: profile.type,
        profileName: profile.profileName
      });
      $scope.loadItems();
      if (seenAt === null && $rootScope.mentionesCount > 0) {
        $rootScope.mentionesCount -= 1;
      }
    })
    .catch(() => {
      toaster.pop("error", "Failed to mark note mention as read");
    });

  $scope.openMention = function (mention) {
    if (mention.noteType === noteConsts.NoteTypes.BULK) {
      return SweetAlert.swal({
        title: "Bulk Note Type",
        text: "cant open view for bulk note type",
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "OK",
        closeOnConfirm: true
      });
    }

    openMentionById(mention.mentionId, mention.content.noteId, mention.content.seenAt);
  };

  $scope.loadItems = function () {
    $scope.isLoading = true;
    agencyMemberMentionsService
      .getMentions({ done: false })
      .then((res) => {
        $scope.isLoading = false;
        $scope.mentions = res.data.mentions;
        initSelection();
        mapNotesMentionsData();
        applyFilters();
      })
      .catch(() => {
        $scope.isLoading = false;
        toaster.pop("error", "Failed to load mentions");
      });
  };

  $scope.markAsDone = function (mention) {
    agencyMemberMentionsService
      .markMentionsAsDone([mention.mentionId])
      .then(() => {
        toaster.pop("success", "Note mention marked as done");
        $scope.loadItems();

        if (mention.content.seenAt === null && $rootScope.mentionesCount > 0) {
          $rootScope.mentionesCount -= 1;
        }
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as done");
      });
  };

  $scope.markSelectedAsDone = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as done",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsDone(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as done");
        $scope.loadItems();
        if ($rootScope.mentionesCount > 0) {
          $rootScope.mentionesCount -= getUnSeenCountFromIds(mentionIds);
        }
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as done");
      });
  };

  $scope.markSelectedAsRead = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as read",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsRead(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as read");
        $scope.loadItems();

        if ($rootScope.mentionesCount > 0) {
          $rootScope.mentionesCount -= getUnSeenCountFromIds(mentionIds);
        }
      })
      .catch((err) => {
        console.log(err);
        toaster.pop("error", "Failed to mark note mention as read");
      });
  };

  $scope.markSelectedAsUnread = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as unread",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsUnread(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as unread");
        $scope.loadItems();

        $rootScope.mentionesCount += getSeenCountFromIds(mentionIds);
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as unread");
      });
  };

  function getSeenCountFromIds(mentionIds) {
    return $scope.tableData.filter(
      (mention) =>
        mentionIds.includes(mention.mentionId) &&
        mention.content.seenAt !== null
    ).length;
  }

  function getUnSeenCountFromIds(mentionIds) {
    return $scope.tableData.filter(
      (mention) =>
        mentionIds.includes(mention.mentionId) &&
        mention.content.seenAt === null
    ).length;
  }
  
  const applyFilters = () => {
    initTable($scope.mentions.filter(dropdownFilterManager.filter));
  };

  $rootScope.$on("got_patients_data", () => {
    $scope.patientsMap = DatabaseApi.patients();
    initialize();
  });

  $rootScope.$on("got_caregivers_data", () => {
    $scope.patientsMap = DatabaseApi.patients();
    initialize();
  });

  initialize();
};
