import { CommCenterTicketId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const commCenterKeys = createQueryKeys("comm_center", {
  search: (params: Record<string, unknown>) => params,
  get: (id: CommCenterTicketId) => id,
  stats: null,
  labels: null,
  teams: null,
});
