import moment from "moment";

/* @ngInject */
export function notesReportCtrl($rootScope, $scope, $filter, DatabaseApi, itemSearchPageManager,
                                            noteService, SweetAlert, NgTableParams, toaster, $uibModal, noteConsts,
                                            officesService, $q, agencyPatientStatusService, entityNoteService) {
    const initializeMap = DatabaseApi.entitiesInitializeMap();
    let filterModalInstance;
    $scope.assetsAreReady = false;
    $scope.isAdmin = $rootScope.user.admin;
    $scope.globalFilter = { val: "" };
    $scope.subjects = DatabaseApi.agencyNoteSubjectsWithNoteTypes();

    $scope.statuses = [
        { value: "NONE", text: "---", statusClass: "" },
        { value: "VIEWED", text: "Viewed", statusClass: "lightblue" },
        { value: "IN_PROGRESS", text: "In progress", statusClass: "orange" },
        { value: "DONE", text: "Done", statusClass: "green" }
      ];

    $scope.statusesHash = {
        "NONE": { value: "NONE", text: "---", statusClass: "" },
        "VIEWED": { value: "VIEWED", text: "Viewed", statusClass: "lightblue" },
        "IN_PROGRESS": { value: "IN_PROGRESS", text: "In progress", statusClass: "orange" },
        "DONE": { value: "DONE", text: "Done", statusClass: "green" }
    };

    const defaultNoteTypesList = [
        'calendar',
        'missed_visit_instance',
        'patient_status',
        'patient_authorization',
        'visit_instance',
        'caregiver_status_change',
        'caregiver_absence',
        'caregiver_exclusion',
        'caregiver_pto'
    ];

    $scope.filters = {
        certifications:{
            title: "Certifications",
            selected: [],
            options: [],
        },
        offices:{
            title: "Offices",
            selected: [],
            options: [],
        },
        gender:{
            title: "Gender",
            selected: [],
            options: [{id: 'M', label: 'Male'}, {id: 'F', label: 'Female'}],
        },
        counties:{
            title: "Counties",
            selected: [],
            options: [],
        },
        contracts:{
            title: "Contracts",
            selected: [],
            options: [],
        },
        patientStatuses:{
            title: "patient Statuses",
            selected: [],
            options: [],
        },
        caregiverStatuses:{
            title: "Caregiver Statuses",
            selected: [],
            options: [
                { id: 1, label: "ACTIVE" },
                { id: 2, label: "ON HOLD", value: "ON_HOLD" },
                { id: 3, label: "ON LEAVE", value: "ON_LEAVE" },
                { id: 4, label: "PENDING" },
                { id: 5, label: "TERMINATED" },
                { id: 6, label: "SUSPENDED" },
                { id: 7, label: "QUIT" },
                { id: 8, label: "REJECTED" }
              ],
        },
        assignedCoordinators:{
            title: "Assigned Coordinators",
            selected: [],
            options: [],
        },
        agencyTeams:{
            title: "Teams",
            selected: [],
            options: [],
        },
        reasons:{
            title: "Reasons",
            selected: [],
            options: [],
        },
        statuses: {
            title: "Status",
            selected: $scope.statuses.filter(status => status.value !== 'DONE').map(_status => ({
                id: _status.value
            })),
            options: $scope.statuses.map(status => ({
                id: status.value,
                label: status.text
            }))
        }
    }

    $scope.activeFilters = ()=>{
        return Object.keys($scope.filters).reduce((activeFilters, key)=>{
            if($scope.filters[key].selected.length>0){
                activeFilters.push($scope.filters[key]);
            }
            return activeFilters;
        }, []);
    }

    $scope.chipLabel = (filter)=>{
        return filter.selected.map((obj) => filter.options.find((opt) => opt.id === obj.id).label).join(", ")
    }

    $scope.chipRemove = (filter)=>{
        filter.selected.splice(0,filter.selected.length)
    }

    $scope.extraFilters = {
        subjects: []
    }
    setDefaultNoteTypesAndSubjectsFilter();

    $scope.datepickerOptions = {
        minDate: new Date()
    }

    function setDefaultNoteTypesAndSubjectsFilter() {
        $scope.extraFilters.subjects = $scope.subjects.filter(s => !s.noteType || defaultNoteTypesList.includes(s.noteType))
        .map(_s => ({ id: _s.id }));
    }

    function checkEssentialAssets() {
        const patientsState = initializeMap['patients'];
        const caregiversState = initializeMap['caregivers'];
        const agencyMembersState = initializeMap['agencyMembers'];

        if (patientsState && caregiversState && agencyMembersState) {
            $scope.assetsAreReady = true;
            Object.keys($scope.filters).map(k => `filters.${k}.selected`).forEach(filter => {
                $scope.$watchCollection(filter, () => {
                    $scope.loadItems();
                });
            });
            return;
        }
        $scope.assetsAreReady = false;
        return;
    }
    
    function initialize() {
        $scope.caregiversMap = DatabaseApi.caregivers();
        $scope.patientsMap = DatabaseApi.patients();
        $scope.agencyMembers = DatabaseApi.getAgencyMembers();

        checkEssentialAssets();
        if (!$scope.assetsAreReady) return;

        initPageManager();
        initColumns();
        $scope.pageManager.resetSearchParamsByUrl();
        initFilters();
        $scope.loadItems();
    }

    const convertObjsArrToIdsStringSplitWithPipe = (objsArr) => objsArr.map(obj => obj.id).join('|');
    const convertIdsStringToObjsArr = (strCommasSeparated) => strCommasSeparated.split('|');

    $scope.convertFilterByValueOrLabeleSplitWithPipe = (filter)=>{
        const splitWithPipe = (objsArr) => objsArr.map((obj) => {
            const data = filter.options.find((opt) => opt.id === obj.id);
            return data.value || data.label;
        }).join('|');
        return splitWithPipe;
    }

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager(
            "/notes_report"
        );

        $scope.pageManager.initFromToDateParams(true);
        $scope.pageManager.initSearchParamDeprecated("subjects", $scope.extraFilters.subjects, "subjects",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);
            
        $scope.pageManager.initSearchParamDeprecated("certifications", $scope.filters.certifications.selected, "certifications",
            $scope.convertFilterByValueOrLabeleSplitWithPipe($scope.filters.certifications), convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("offices", $scope.filters.offices.selected, "offices",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("genders", $scope.filters.gender.selected, "genders",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("counties", $scope.filters.counties.selected, "counties",
            $scope.convertFilterByValueOrLabeleSplitWithPipe($scope.filters.counties), convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("contracts", $scope.filters.contracts.selected, "contracts",
                convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("patientStatuses", $scope.filters.patientStatuses.selected, "patientStatuses",
            $scope.convertFilterByValueOrLabeleSplitWithPipe($scope.filters.patientStatuses), convertIdsStringToObjsArr);
            
        $scope.pageManager.initSearchParamDeprecated("caregiverStatuses", $scope.filters.caregiverStatuses.selected, "caregiverStatuses",
            $scope.convertFilterByValueOrLabeleSplitWithPipe($scope.filters.caregiverStatuses), convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("assignedCoordinators", $scope.filters.assignedCoordinators.selected, "assignedCoordinators",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("agencyTeams", $scope.filters.agencyTeams.selected, "agencyTeams",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);

        $scope.pageManager.initSearchParamDeprecated("notePredefinedAnswers", $scope.filters.reasons.selected, "notePredefinedAnswers",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);
        $scope.pageManager.initSearchParamDeprecated("statuses", $scope.filters.statuses.selected, "statuses",
            convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);
    }
    
    const initTable = function (items) {
        const options = {
            count: 25,
        };

        $scope.tableParams = new NgTableParams(options, {
            dataset: items,
        });
    };

    const mapNotesData = () => {
        const agencyMembersLen = Object.keys($scope.agencyMembers).length;
        const caregiversLen = Object.keys($scope.caregiversMap).length;
        const patientsLen = Object.keys($scope.patientsMap).length;

        $scope.notes = $scope.notes.map(note => {
            const status = $scope.statusesHash[note.status];
            let agencyMembersNames = [];
            let patients = [];
            let caregivers = [];
            let isToday = false;
            let caregiverDisplayId;
            let patientDisplayId;
            if (note.followUp) {
                const now = moment().subtract(24, 'h');
                const followUpDate = moment(note.followUp);
                isToday = followUpDate.diff(now, 'h') > 0 && followUpDate.diff(now, 'h') < 24;
            }

            if (note.agencyMembers !== undefined && $scope.agencyMembers && agencyMembersLen > 0) {
                for (const agencyMemberId of note.agencyMembers) {
                    const agencyMember = $scope.agencyMembers[agencyMemberId];
                    if (agencyMember === undefined) {
                        continue;
                    }
                    const splitName = agencyMember.split(' ');
                    agencyMembersNames.push({
                        fullName: agencyMember,
                        initials: splitName[0].substr(0, 1) + (splitName.length > 1 ? ' ' + splitName[splitName.length - 1].substr(0, 1) : ''),
                    });
                }
            }

            if(note.noteType === noteConsts.NoteTypes.BULK){
                if (note.caregiverIds !== null && $scope.caregiversMap && caregiversLen > 0) {
                    note.caregiverIds.forEach((id)=>{
                        caregivers.push($scope.caregiversMap[id]);
                    })
                    caregiverDisplayId = note.caregiverIds.map((id) => {
                        return $scope.caregiversMap[id].displayId ? $scope.caregiversMap[id].displayId : id
                    }).join(', ');
                }

                if (note.patientIds !== null && $scope.patientsMap && patientsLen > 0) {
                    note.patientIds.forEach((id)=>{
                        patients.push($scope.patientsMap[id]);
                    });
                    patientDisplayId = note.patientIds.map((id) => {
                        return $scope.patientsMap[id].displayId ? $scope.patientsMap[id].displayId : id
                    }).join(', ');
                }
            } else {
                if (note.caregiverId !== null && $scope.caregiversMap && caregiversLen > 0) {
                    caregivers.push($scope.caregiversMap[note.caregiverId]);
                    caregiverDisplayId = $scope.caregiversMap[note.caregiverId].displayId ? $scope.caregiversMap[note.caregiverId].displayId : note.caregiverId;
                }

                if (note.patientId !== null && $scope.patientsMap && patientsLen > 0) {
                    patients.push($scope.patientsMap[note.patientId]);
                    patientDisplayId = $scope.patientsMap[note.patientId].displayId ? $scope.patientsMap[note.patientId].displayId : note.patientId;
                }
            }

            const subjectText = note.subject ? note.subject: noteConsts.NoteTypesTranslations[note.noteType];

            return {
              ...note,
              statusText: status.text,
              statusClass: status.statusClass,
              subjectText,
              isFollowUpToday: isToday,
              agencyMembersNames: agencyMembersNames,
              patients,
              caregivers,
              content: note.noteRichText? stripHtml(limitWords(note.noteRichText, 30)) : '',
              caregiverDisplayId: caregiverDisplayId,
              patientDisplayId: patientDisplayId
            };});
        // If today shift array
        $scope.notes = $scope.notes.filter(note => note.isFollowUpToday).concat($scope.notes.filter(note => !note.isFollowUpToday));
    }
      
    $scope.loadItems = function () {
        if ($scope.loadingNotes === true) return;

        $scope.globalFilter.val = "";

        $scope.loadingNotes = true;
        $scope.pageManager.executeSearch().then(
          function ({ data }) {
            $scope.notes = data.list;
            mapNotesData();
            initTable($scope.notes);
            $scope.loadingNotes = false;
          },
          function (error) {
            toaster.pop("error", "Failed to load notes report data");
            $scope.loadingNotes = false;
          }
        );
    };

    $scope.applyGlobalSearch = function (term) {
        const filter = { $: term };
        if ($scope.tableParams) {
          angular.extend($scope.tableParams.filter(), filter);
        }
    };

    $scope.openCreateNewNote = async (isBulk) => {
        const modalInstance = $rootScope.openNoteCreateModal(isBulk);

        modalInstance.result.then(function (res) {
            $scope.loadItems();
         }, function () { // Dismissed
            $scope.loadItems();
         });
    }

    $scope.updateFollowUpDate = (row) => {
        const { id, followUp } = row;
        const followUpEditParam = { followUp: followUp };
        noteService.editFollowUp(id, followUpEditParam, $rootScope.agencyId, $rootScope.agencyMemberId)
          .then((res) => {
            toaster.pop("success", "Success", "Edited note follow up");
          })
          .catch((err) => {
            toaster.pop(
              "error",
              "Something went wrong",
              "Failed to update note follow up"
            );
        });
    };

    $scope.updateRowStatus = (note) => {
        const statusEditParam = {status: note.status};
        noteService.editStatus(note.id, statusEditParam, $rootScope.agencyId, $rootScope.agencyMemberId).then(res => {
            toaster.pop(
            "success",
            "Success",
            "Edited note status"
            );
            const updatedStatus = $scope.statusesHash[note.status];
            note.statusText = updatedStatus.text;
            note.statusClass = updatedStatus.statusClass;
        }).catch(err => {
            toaster.pop(
            "error",
            "Something went wrong",
            "Failed to update note status");
        });
    }

    $scope.handleDeleteNote = (noteId) => {
        SweetAlert.swal({
            title: "Remove note",
            text: "Are you sure you want to remove this note? ",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, (isConfirm) => {
            if (isConfirm) {
                noteService.deleteNote(noteId, $rootScope.agencyId, $rootScope.agencyMemberId).then(res => {
                    toaster.pop(
                    "success",
                    "Success",
                    "Delete note"
                    );
                    $scope.notes = $scope.notes.filter(n => n.id !== noteId);
                    initTable($scope.notes);
                }).catch(err => {
                    toaster.pop(
                    "error",
                    "Something went wrong",
                    "Failed to Delete note");
                });
            }
        });
    }

    $scope.clickTableRow = (note) => {
        const viewOnlyMode = !$scope.isAdmin;
        $scope.openEditNote(note.id, viewOnlyMode);
    }

    $scope.openEditNote = async (noteId, viewOnlyMode = false) => {
        const noteDetails = await noteService.getNoteDetails(noteId);
        const note = {
            ...noteDetails
        }

        if (note.caregiverId !== null) {
            note.caregiver = $scope.caregiversMap[note.caregiverId];
        }

        if (note.patientId !== null) {
            note.patient = $scope.patientsMap[note.patientId];
        }

        const newScope = $scope.$new();
        newScope.note = note;
        newScope.editMode = true;
        newScope.viewOnlyMode = viewOnlyMode;
        newScope.isBulk = note.noteType == noteConsts.NoteTypes.BULK;
     
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/create-note-modal.html',
            size: 'md',
            controller: 'createNoteModalCtrl',
            scope: newScope,
            windowClass: "modal modal-slide-in-right create-note-container"
        });
        modalInstance.result.then(function (res) {
            $scope.loadItems();
        }, function () { // Dismissed
            $scope.loadItems();
        });
    };

    $scope.openCommentNote = async (noteId) => {
        const newScope = $scope.$new();
        newScope.note = await noteService.getNoteDetails(noteId);
     
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/entity-note-comments-modal.html',
            size: 'md',
            controller: 'entityNoteCommentsModalCtrl',
            scope: newScope,
            windowClass: "modal modal-slide-in-right entity-notes-container"
        });
        modalInstance.result.then(function (res) {
            $scope.loadItems();
        }, function () {
            $scope.loadItems();
        });
    }

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        var rows = [];
        var titles = ['Date', 'Content', 'Follow up', 'Status', 'Created by', 'Assigned agency members', 'Caregiver', 'Caregiver Id', 'Patient', 'Patient Id', 'Subject', 'Comments'];
        rows.push(titles);

        $scope.notes.forEach(dataRow => {
            var csvRow = [];
            csvRow.push($filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '');
            const content = stripHtml(dataRow.noteRichText).replace(/^\s+|\s+$/g, '');
            csvRow.push(`"${content}"` || '');
            csvRow.push($filter("mfShortDate")(dataRow.followUp) || '');
            csvRow.push(dataRow.status || '');
            csvRow.push($scope.agencyMembers[dataRow.createdBy] || '');

            const agencyMembers = dataRow.agencyMembersNames.map(member=>member.fullName);
            csvRow.push(agencyMembers.join(' | '));

            const caregivers = dataRow.caregivers.map(caregiver=>caregiver.displayName);
            csvRow.push(caregivers.join(' | '));

            const caregiverDisplayId = dataRow.caregiverDisplayId ? '"' + dataRow.caregiverDisplayId + '"' : '';
            csvRow.push(caregiverDisplayId);

            const patients = dataRow.patients.map(patient=>patient.displayName);
            csvRow.push(patients.join(' | '));

            const patientDisplayId = dataRow.patientDisplayId ? '"' + dataRow.patientDisplayId + '"' : '';
            csvRow.push(patientDisplayId);
            
            csvRow.push(dataRow.subjectText || '');
            csvRow.push(`${dataRow.totalComments}`);

            rows.push(csvRow);
        });

        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    var getExportedFileName = function () {
        var filename = "medflyt-export-notes-report"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.subjectSelectionSettings = {
        displayProp: "text",
        scrollable: true,
        showCheckAll: false,
        enableSearch: true
    };

    $scope.$watch('pageManager.searchParams.from.value', function () {
        if (!$scope.assetsAreReady) return;
        $scope.loadItems();
    }, true);

    $scope.$watch('pageManager.searchParams.to.value', function () {
        if (!$scope.assetsAreReady) return;
        $scope.loadItems();
    }, true);

    $scope.subjectEvents = {
        onSelectionChanged: () => {
            $scope.loadItems();
        },
    }

    const initFilters = ()=>{
        $scope.filters.certifications.options = DatabaseApi.agencyCertifications() || [];
        $scope.filters.certifications.options = $scope.filters.certifications.options.filter(c => c.isActive === true)
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

        officesService.getOffices().then((offices)=>{
            $scope.filters.offices.options = offices.map((office) => ({
                id: office.id,
                label: office.name
            })); 
        });

        var deferred = $q.defer();
        const locationGroupsAndCounties = DatabaseApi.locationGroupsAndCounties();
        if(locationGroupsAndCounties.counties.length == 0){
            DatabaseApi.getLocationGroupsAndCounties().then((groupsAndCounties)=>{
                deferred.resolve(groupsAndCounties.counties);
            })
        }else{
            deferred.resolve(locationGroupsAndCounties.counties);
        }

        deferred.promise.then((counties)=>{
            $scope.filters.counties.options = counties.map((county) => ({
                id: county.id,
                value: county.countyName,
                label: `${county.countyName} (${county.state})`
            })); 
        });

        $scope.filters.contracts.options = DatabaseApi.activeContractTypes().map(contract => ({
            id: contract.id,
            label: contract.name,
        }));

        agencyPatientStatusService.getAgencyPatientStatuses().then((statuses)=>{
            $scope.filters.patientStatuses.options = statuses.map((status, i) => ({
                id: i + 1,
                value: status.text,
                label: status.text.replace(/_/g, " ")
            }));
        })

        $scope.filters.assignedCoordinators.options = DatabaseApi.getAgencyMembersArr().map((coordinator) => ({
            id: coordinator.id,
            label: coordinator.label
        }));

        $scope.filters.agencyTeams.options = DatabaseApi.agencyTeams().filter(team => team.active && team.hasCasesNotDischarged).map((team) => ({
            id: team.id,
            label: team.name,
        }));

        $scope.filters.reasons.options = entityNoteService.getPredefinedReasonsGroupedByEntity();
        $scope.reasonsGroupsExtraSettings = {
            styleActive: true, 
            enableSearch: true,
            showCheckAll: false,
            groupByTextProvider: function(groupValue) {
                return groupValue
            },
            groupby: 'entity',
        };
    }

    $scope.openFilterModal = () => {
        filterModalInstance = $uibModal.open({
            templateUrl: 'admin/views/notes-report-filter-modal.html',
            size: 'md',
            scope: $scope,
            resolve: {},
            backdropClass: 'transparent-backdrop',
            windowClass: "modal modal-slide-in-right uib-side-modal"
        });
        
        return filterModalInstance;
    }
    
    $scope.closeModal = () => {
        filterModalInstance && filterModalInstance.close();
    };

    $rootScope.$on("got_patients_data", () => {
        $scope.patientsMap = DatabaseApi.patients();
        checkEssentialAssets();

        if ($scope.assetsAreReady) {
            initialize();
        }
    });

    $rootScope.$on("got_caregivers_data", () => {
        $scope.caregiversMap = DatabaseApi.caregivers();
        checkEssentialAssets();

        if ($scope.assetsAreReady) {
            initialize();
        }
    });

    $rootScope.$on("got_agency_members", () => {
        $scope.agencyMembers = DatabaseApi.getAgencyMembers();
        checkEssentialAssets();

        if ($scope.assetsAreReady) {
            initialize();
        }
    });

    $rootScope.$on("got_notes_settings", function (event) {
        $scope.subjects = DatabaseApi.agencyNoteSubjectsWithNoteTypes();
        setDefaultNoteTypesAndSubjectsFilter();
    });

    const initColumns = () => {
      $scope.notesReportTableColumns = {
        "Creation Time": true,
        "Subject": true,
        "Content": true,
        "Reason": true,
        "Patient": true,
        "Patient Id": false,
        "Caregiver": true,
        "Caregiver Id": false,
        "Status": true,
        "Follow Up": true,
        "Created By": true,
        "Assigned Agency Member": true,
        "Reporter Name": false,
        "Reporter Phone": false,
        "Reporter Email": false,
        "Files": true,
        "Comments": true
      };
    };

    const stripHtml = (html) => {
       const tempElement = document.createElement("DIV");
       tempElement.innerHTML = html;
       return tempElement.textContent || tempElement.innerText || "";
    };

    const limitWords = (text, amount) => {
        return text
          .split(" ")
          .map((item, index) => (index <= amount ? item : ""))
          .join(" ");
    };

    initialize();
};