//! @ngInject
export function tutorialsCtrl($scope, $rootScope, $uibModal) {

    $scope.tutorials = [
    {
        imageUrl: 'https://s3.amazonaws.com/medflyt-assets/tutorials/tutorial3.jpg',
        videoUrl: 'https://vimeo.com/392400080',
        title: 'Nursing Documentation Platfrom + Value Based Payment and Quality of Care tracking',
        description:
            "In this webinar, we'll present the most advance digitized nursing visits " +
            "platform to date - Never use paper documents again - everything using the MedFlyt app."
    },
    {
        imageUrl: 'https://s3.amazonaws.com/medflyt-assets/tutorials/tutorial2.jpg',
        videoUrl: 'https://vimeo.com/386789953',
        title: 'Online Training platform',
        description: "Online Training platform for Caregivers’ In-Service  (Webinar), January 2020"
    },
    {
        imageUrl: 'https://s3.amazonaws.com/medflyt-assets/tutorials/tutorial1.jpg',
        videoUrl: 'https://vimeo.com/330970725',
        title: 'Staffing Webinar',
        description: "MedFlyt Webinar week1 4-16-19"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.us-east-1.amazonaws.com/tutorials/tutorial4.jpg',
        videoUrl: 'https://vimeo.com/436574672',
        title: 'EVV webinar',
        description: ""
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/Screen+Shot+2020-11-22+at+10.50.10.png',
        videoUrl: 'https://vimeo.com/431759753',
        title: 'How to setup signature',
        description: "This video will instruct you how to setup your signature in your agency profile"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+add+phone+number+to+a+caregiver.png',
        videoUrl: 'https://vimeo.com/431760582',
        title: 'How to add phone number to a caregiver',
        description: "This video will instruct you how to add phone number to caregiver’s profile"
    },   
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+invite+agency+member.png',
        videoUrl: 'https://vimeo.com/431761398',
        title: 'How to invite agency member',
        description: "This video will instruct you how to invite agency member to Medflyt"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+search+by+phone+number%3A+email%3A+name.png',
        videoUrl: 'https://vimeo.com/431762942',
        title: 'How to search by phone number or email or name',
        description: "This video will instruct you how to search by phone number or email or name"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+email++notification+settings+for+agency+members.png',
        videoUrl: 'https://vimeo.com/439707765',
        title: 'How to change email notification settings for agency members',
        description: "This video will instruct you how to change email notification settings for agency members"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+name+on+caregiver+profile.png',
        videoUrl: 'https://vimeo.com/439708448',
        title: 'How to change name on caregiver profile',
        description: "This video will instruct you how to change name on caregiver profile"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+add+office+to+a+caregiver.png',
        videoUrl: 'https://vimeo.com/439709143',
        title: 'How to add office to a caregiver',
        description: "This video will instruct you how to add office to a caregiver"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+add+office%3Abranch+to+an+agency+member%3F.png',
        videoUrl: 'https://vimeo.com/439709197',
        title: 'How to add office/branch to an agency member?',
        description: "This video will instruct you how to add office to an agency member?"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+caregiver+status%3F.png',
        videoUrl: 'https://vimeo.com/439709224',
        title: 'How to change caregiver status?',
        description: "This video will instruct you how to change caregiver status?"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+reset+password+agency+member.png',
        videoUrl: 'https://vimeo.com/439709241',
        title: 'How to reset password agency members?',
        description: "This video will instruct you how to reset password agency member"
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+Add+notes.png',
        videoUrl: 'https://vimeo.com/442644743',
        title: 'How to add notes',
        description: "This video will instruct you how to add notes"
    },
    {
        imageUrl: `https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+caregiver's+certification%3F.png`,
        videoUrl: 'https://vimeo.com/473362270',
        title: `How to change caregiver's certification?`,
        description: "This video will instruct you how to change caregiver's certification?" 
    },
    {
        imageUrl: `https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+Patient's+status%3F.png`,
        videoUrl: 'https://vimeo.com/473363511',
        title: `How to change a patient's status?`,
        description: "This video will instruct you how to change a patient's status?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+delete+phone+number+from+caregover+profile%3F.png',
        videoUrl: 'https://vimeo.com/473361787',
        title: 'How to delete phone number from caregiver profile?',
        description: "This video will instruct you how to delete phone number from caregiver profile?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+create+and+assign+team%3F+.png',
        videoUrl: 'https://vimeo.com/473360995',
        title: 'How to create and assign a team?',
        description: "This video will instruct you how to create and assign a team" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+cancel+visit.png',
        videoUrl: 'https://vimeo.com/431763215',
        title: 'How to cancel a visit?',
        description: "This video will instruct you how to cancel a visit" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+approve+join+request+%3A+%22pending+acceptance%22.png',
        videoUrl: 'https://vimeo.com/442643286',
        title: 'How to approve join request / "pending acceptance"',
        description: `This video will instruct you how to approve join request / "pending acceptance"`
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+cancel+recurring+visit+.png',
        videoUrl: 'https://vimeo.com/472591537',
        title: 'How to cancel a recurring visit',
        description: "This video will instruct you how to cancel a recurring visit" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/how+to+create+and+schedule+a+task.png',
        videoUrl: 'https://vimeo.com/478104013',
        title: 'How to create and schedule a task?',
        description: "This video will instruct you how to create and schedule a task" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+send+HR+link.png',
        videoUrl: 'https://vimeo.com/437576625',
        title: 'How to send an HR link?',
        description: "This video will instruct you how to send an HR link" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+switch+applicant+stages%3F.png',
        videoUrl: 'https://vimeo.com/437576925',
        title: 'How to switch applicant stages?',
        description: "This video will instruct you how to switch applicant stages?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+approve+i-9+form.png',
        videoUrl: 'https://vimeo.com/437577274',
        title: 'How to approve i-9 form?',
        description: "This video will instruct you how to approve i-9 form?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+add+hire+date+to+a+caregiver+%3A+applicant.png',
        videoUrl: 'https://vimeo.com/439971496',
        title: 'How to add a hire date to a caregiver / applicant',
        description: "This video will instruct you how to add a hire date to a caregiver / applicant?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+filter+based+on+certification+and+stage.png',
        videoUrl: 'https://vimeo.com/442759517',
        title: 'How to filter based on certification and stage',
        description: "This video will instruct you how to filter based on certification and stage" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+add+test+attempts.png',
        videoUrl: 'https://vimeo.com/437566290',
        title: 'How to add test attempts',
        description: "This video will instruct you how to add test attempts" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+change+bundles+dates+for+specific+caregiver.png',
        videoUrl: 'https://vimeo.com/442679518',
        title: 'How to change bundles dates for specific caregiver',
        description: "This video will instruct you how to change bundles dates for specific caregiver" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+exempt+item+for+specific+caregiver.png',
        videoUrl: 'https://vimeo.com/442756076',
        title: 'How to exempt item for specific caregiver',
        description: "This video will instruct you how to exempt item for specific caregiver" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+exempt+entire+bundle+for+a+caregiver.png',
        videoUrl: 'https://vimeo.com/442718470',
        title: 'How to exempt entire bundle for a caregiver',
        description: "This video will instruct you how to exempt entire bundle for a caregiver" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+send+reminders+to+complete+the+training%3F.png',
        videoUrl: 'https://vimeo.com/442757887',
        title: 'How to send reminders to complete the training?',
        description: "This video will instruct you how to send reminders to complete the training?" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+un-exempt+bundle+for+a+caregiver.png',
        videoUrl: 'https://vimeo.com/442756842',
        title: 'How to un-exempt bundle for a caregiver',
        description: "This video will instruct you how to un-exempt bundle for a caregiver" 
    },
    {
        imageUrl: 'https://medflyt-assets.s3.amazonaws.com/tutorials/How+to+allow+specific+caregiver+watch+training+during+shift%3F.png',
        videoUrl: 'https://vimeo.com/464927518',
        title: 'How to allow specific caregiver watch training during shift?',
        description: "This video will instruct you how to allow a specific caregiver watch training during shift?" 
    }];

    $scope.openPreviewModal = function(video){

        if(!video){
            return;
        }

        var type = 'video';
        if(video.videoUrl.search('www.youtube.com') > -1){
            type = 'youtube';
        } else if(video.videoUrl.search('vimeo') > -1){
            type = 'vimeo';
        }

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/orientation-item-preview-modal.html',
            size: 'lg',
            resolve: {
                videoData: function () {
                    return video;
                },
                type: function () {
                    return type;
                },
                bundleData: function () {
                    return {};
                },
                itemData: function () {
                    return {};
                },
                lang: function () {
                    return 'English';
                },
                forSelection: function () {
                    return false
                },
                existingGroups: function () {
                    return [];
                }
            },
            controller: 'orientationItemPreviewModalCtrl'

        });
        modalInstance.result.then(function (res) {
            console.log('modal out');
            //console.log(res);
        }, function () {
            console.log('modal close');
        });

    };
};