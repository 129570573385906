import moment from "moment";
import $ from "jquery";

//! @ngInject
export function patientVacationsModalCtrl($scope, $rootScope, DatabaseApi, $uibModalInstance, SweetAlert, $stateParams, toaster) {

    $scope.vacations = [];

    function getVacationsFromServer() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId +  '/patients/' + $stateParams.id + '/patient_vacations').then(function(res) {
            $scope.vacations = res.data.patientVacations;
        }, function(err) {
            // var date = new Date(); // TODO: remove fake data when 'get vacations' works
            // for (var i = 0; i < 10; i++) {
            //     date.setDate(date.getDate() + 1);
            //     $scope.vacations.push({
            //         date: LocalDate.ofInstant(Instant.ofEpochMilli(date.valueOf())).toString()
            //     });
            // }
        });
    }

    getVacationsFromServer();

    $scope.exit = function() {
        $uibModalInstance.dismiss();
    }

    $scope.vacationsDashboard = {
        type: 'overview',
        datePicker: {
            startDate: moment(),
            endDate: moment()
        },
        options: {
            alwaysShowCalendars: true,
            applyClass: 'btn-primary',
            locale: {
                applyLabel: "Apply",
                fromLabel: "From",
                // format: "YYYY-MM-DD", // will return 2017-01-06
                format: "D MMM YY", // will return 6  Jan 17
                //format: "D-MMMM-YY", // will return 6-January-17
                toLabel: "To",
                cancelLabel: 'Cancel'
            },
            autoApply: true,
            minDate: new Date('2018-01-01')
        }
    };

    $scope.openCalendar = function () {
        $('#date-picker').data('daterangepicker').show();
    };

    $scope.addPatientVacation = function () {
        console.log($scope.vacationsDashboard.datePicker);
        var body = {
            startTime: $scope.vacationsDashboard.datePicker.startDate.format("YYYY-MM-DD"),
            endTime: $scope.vacationsDashboard.datePicker.endDate.format("YYYY-MM-DD")
        };
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId +  '/patients/' + $stateParams.id + '/patient_vacations' , body).then(function(res) {
            toaster.pop('success', "Vacations were saved");
            getVacationsFromServer();
        }, function(err) {
            toaster.pop('error', 'Failed to create new vacations');

            // var currentDate = $scope.vacationsDashboard.datePicker.startDate; // TODO: remove when 'add vacation' works
            // while (currentDate.valueOf() <= $scope.vacationsDashboard.datePicker.endDate) {
            //     $scope.vacations.push({
            //         date: currentDate.format("YYYY-MM-DD")
            //     });
            //     currentDate = currentDate.add(1, 'days');
            // }
        });
    }

    $scope.deletePatientVacation = function (vacation, index) {
        SweetAlert.swal({
            title: "Remove " + vacation.date + '?',
            text: "",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var body = {
                    vacation: vacation
                };
                DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + $stateParams.id + '/patient_vacations/' + vacation.id).then(function(res) {
                    toaster.pop('success', 'Vacation removed succefully');
                    $scope.vacations.splice(index, 1);

                }, function(err) {
                    toaster.pop('error', 'Failed to remove vacation');
                });
            }
        });
    }
};