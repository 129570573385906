// Copied and modified directive from https://github.com/phng/angular-input-time
//! @ngInject
export function pngTimeInput($rootScope) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'admin/views/png-time-input.html',
        scope: {
            ngModel: '=',
            minuteStep: '<',
            onChange: '&',
            onBlur: '&',
        },
        link: function (scope, element, attr) {
            var hourInput, minuteInput, modeInput,
                hourInputEl, minuteInputEl, modeInputEl,
                hourInputCtrl, minuteInputCtrl, modeInputCtrl,
                currentFocusEl,
                keyCount = 2;

            // default time mode to 12h
            scope.timeMode = $rootScope.visitSettings.isMilitaryTime ? 24 : 12;
            var timeMode = scope.timeMode;

            updateModel(scope.ngModel);

            function hourInputParser(value) {
                var hour = parseInt(value);

                if (isNaN(hour)) {
                    hourInputCtrl.$viewValue = '00';
                    hourInputCtrl.$render();
                    hourInput.select();
                    return hourInputCtrl.$viewValue;
                }

                // Some logic If we're in military time and the user wants to input 00
                if (timeMode === 24 && hour === 0) {
                    // Check for '10' input
                    if (parseInt(hourInputCtrl.$modelValue) === 0) {
                        hourInputCtrl.$viewValue = '00';
                        hourInputCtrl.$commitViewValue();
                        hourInputCtrl.$render();
                        minuteInput.focus();
                        minuteInput.select();
                        return hourInputCtrl.$viewValue;
                    }
                }

                // if user meant to type 10, 11 or 12 for standard or 10 - 23 for military
                var squashTime = false;
                if (timeMode === 24) {
                    if (parseInt(hourInputCtrl.$modelValue) === 2 && hour < 4) {
                        squashTime = true;
                    } else if (parseInt(hourInputCtrl.$modelValue) < 2 && hour < 10) {
                        squashTime = true;
                    }
                } else {
                    if (parseInt(hourInputCtrl.$modelValue) < 2 && hour < 3) {
                        squashTime = true;
                    }
                }
                if (squashTime) {
                    hourInputCtrl.$viewValue = parseInt(hourInputCtrl.$modelValue) + '' + hour;
                    hourInputCtrl.$commitViewValue();
                    hourInputCtrl.$render();
                    minuteInput.focus();
                    minuteInput.select();
                    return hourInputCtrl.$viewValue;
                }

                if (hour < 10 && value.length === 1) {
                    hourInputCtrl.$viewValue = '0' + value;
                    hourInputCtrl.$commitViewValue();
                    hourInputCtrl.$render();
                }

                if (hour > (timeMode === 24 ? 2 : 1)) {
                    minuteInput.focus();
                    minuteInput.select();
                } else {
                    hourInput.select();
                }

                return hourInputCtrl.$viewValue;
            }

            function minuteInputParser(value) {
                var minute = parseInt(value);

                if (isNaN(minute)) {
                    minuteInputCtrl.$viewValue = '00';
                    minuteInputCtrl.$render();
                    minuteInput.select();
                    return minuteInputCtrl.$viewValue;
                }

                var minuteInputCtrlValue = parseInt(minuteInputCtrl.$modelValue);
                if (minuteInputCtrlValue < 6 && minute < 10) {
                    minuteInputCtrl.$viewValue = parseInt(minuteInputCtrl.$modelValue) + '' + minute;
                    minuteInputCtrl.$commitViewValue();
                    minuteInputCtrl.$render();
                    if (timeMode === 12 && minuteInputCtrlValue > 0) {
                        modeInput.focus();
                        modeInput.select();
                    } else {
                        minuteInput.select();
                    }
                    return minuteInputCtrl.$viewValue;
                }

                if (minute < 10 && value.length === 1) {
                    minuteInputCtrl.$viewValue = '0' + value;
                    minuteInputCtrl.$commitViewValue();
                    minuteInputCtrl.$render();
                }

                if (parseInt(value) > 5 && timeMode === 12) {
                    modeInput.focus();
                    modeInput.select();
                } else {
                    minuteInput.focus();
                    minuteInput.select();
                }

                return minuteInputCtrl.$viewValue;
            }

            function modeInputParser(value) {
                // if user has typed 'a', or has typed 'm' with 'AM' showing
                if ((value.toLowerCase().indexOf('a') >= 0) || (value.toLowerCase() === 'm' && modeInputCtrl.$modelValue === 'AM')) {
                    modeInputCtrl.$viewValue = 'AM';
                } else {
                    modeInputCtrl.$viewValue = 'PM';
                }
                modeInputCtrl.$commitViewValue();
                modeInputCtrl.$render();

                modeInput.select();

                return modeInputCtrl.$viewValue;
            }

            function resetKeyCount() {
                keyCount = 2;
            }

            function roundMinutes() {
                let hours = parseInt(scope.hour);
                const minutes = parseInt(scope.minute);
                if (!scope.minuteStep || isNaN(hours) || isNaN(minutes)) {
                    return;
                }
                if (!scope.model) {
                    scope.model = new Date();
                }
                if (timeMode === 12) {
                    if (scope.mode !== "AM" && scope.mode !== "PM") {
                        scope.mode = "AM";
                    }
                    if (scope.mode === "PM" && hours < 12) hours += 12;
                    if (scope.mode === "AM" && hours === 12) hours = 0;
                }

                if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                    // round to the nearest minute step
                    scope.minute = (parseInt((minutes + scope.minuteStep / 2) / scope.minuteStep, 10) * scope.minuteStep) % 60;
                    scope.hour = minutes > (60 - scope.minuteStep / 2) ? (hours === 23 ? 0 : ++hours) : hours;
                    scope.model.setMinutes(scope.minute);
                    scope.model.setHours(scope.hour);
                    if (timeMode === 12 && scope.hour > 12) scope.hour -= 12;
                }

                modelWatcher(scope.model);
            }

            function inputBlurHandler() {
                element.removeClass('time-focus');

                if (currentFocusEl === this) {
                    currentFocusEl = null;
                }

                if (scope.onBlur) {
                    scope.onBlur();
                }
            }

            function inputFocusHandler() {
                element.addClass('time-focus');
                currentFocusEl = this;
            }

            function modelWatcher(dt) {
                if (!dt) {
                    scope.hour = '--';
                    scope.minute = '--';
                    scope.mode = '--';
                    return;
                }

                if (angular.isString(dt)) {
                    dt = new Date(dt);
                }

                if (angular.isDate(dt)) {
                    var tempHour = dt.getHours();
                    scope.minute = dt.getMinutes();

                    if (timeMode === 12) {
                        if (tempHour > 11) {
                            scope.hour = tempHour === 12 ? tempHour : tempHour - 12;
                            scope.mode = 'PM';
                        } else {
                            scope.hour = tempHour === 0 ? 12 : tempHour;
                            scope.mode = 'AM';
                        }
                    } else {
                        scope.hour = tempHour === 0 ? 0 : tempHour; // Default to 00 for military time
                    }
                    if (scope.hour < 10) {
                        scope.hour = '0' + scope.hour;
                    }
                    if (scope.minute < 10) {
                        scope.minute = '0' + scope.minute;
                    }
                }

                if (scope.onChange) {
                    scope.onChange({ newVal: scope.model });
                }
            }

            scope.handleHourMinuteChange = () => {
                roundMinutes();
                var strTime;
                var hour = parseInt(scope.hour);
                var minute = parseInt(scope.minute);

                if (isNaN(hour) || isNaN(minute)) {
                    strTime = null;
                }
                else if (timeMode === 12) {
                    if (scope.mode === 'AM') {
                        strTime = (hour === 12 ? 0 : hour) + ':' + minute;
                    } else {
                        strTime = (hour === 12 ? hour : hour + 12) + ':' + minute;
                    }
                } else {
                    strTime = hour + ':' + minute;
                }

                if (!strTime) {
                    return;
                }

                var dateParts = strTime.split(':');

                if (!scope.model) {
                    scope.model = new Date();
                }

                scope.model.setHours(dateParts[0]);
                scope.model.setMinutes(dateParts[1]);
                scope.model.setSeconds(0);
                modelWatcher(scope.model);

                inputBlurHandler();
            };

            scope.focus = function elementFocus() {
                if (document.activeElement !== minuteInput && document.activeElement !== modeInput) {
                    hourInput.focus();
                    hourInput.select();
                }
            };

            angular.forEach(element.find('input'), function (input) {
                if (input.name === 'hour') {
                    hourInput = input;
                    hourInputEl = angular.element(input);
                    hourInputCtrl = hourInputEl.controller('ngModel');
                } else if (input.name === 'minute') {
                    minuteInput = input;
                    minuteInputEl = angular.element(input);
                    minuteInputCtrl = minuteInputEl.controller('ngModel');
                } else if (input.name === 'mode') {
                    modeInput = input;
                    modeInputEl = angular.element(input);
                    modeInputCtrl = modeInputEl.controller('ngModel');
                }
            });

            hourInputCtrl.$parsers.unshift(hourInputParser);
            minuteInputCtrl.$parsers.unshift(minuteInputParser);
            modeInputCtrl.$parsers.unshift(modeInputParser);

            hourInputEl.on('focus', resetKeyCount);
            minuteInputEl.on('focus', resetKeyCount);
            hourInputEl.on('focus', inputFocusHandler);
            minuteInputEl.on('focus', inputFocusHandler);
            modeInputEl.on('focus', inputFocusHandler);

            scope.toggleAmPm = () => {
                toggleMode();
                scope.handleHourMinuteChange();
            };

            function toggleMode() {
                var newMode = scope.mode === "AM" ? "PM" : "AM";
                updateMode(newMode);
            }

            function incHour() {
                var shouldToggleMode = false;
                var oldHour = parseInt(scope.hour);
                if (isNaN(oldHour)) {
                    oldHour = 0;
                }
                var newHour = oldHour + 1;
                if (timeMode === 12 && newHour >= timeMode) {
                    if (newHour === 12) {
                        shouldToggleMode = true;
                    } else {
                        newHour = 1;
                    }
                } else {
                    if (newHour >= 24) {
                        newHour = 0;
                    }
                }
                updateHour(newHour);
                if (shouldToggleMode) toggleMode();
            }

            function decHour() {
                var shouldToggleMode = false;
                var oldHour = parseInt(scope.hour);
                if (isNaN(oldHour)) {
                    oldHour = 0;
                }
                var newHour = oldHour - 1;
                if (timeMode === 12) {
                    if (newHour === 0) {
                        newHour += 12;
                    } else if (newHour === 11) {
                        shouldToggleMode = true;
                    }
                } else if (timeMode === 24 && newHour < 0) {
                    newHour = 23;
                }
                updateHour(newHour);
                if (shouldToggleMode) toggleMode();
            }

            function incMinute() {
                var oldMinute = parseInt(scope.minute);
                if (isNaN(oldMinute)) {
                    oldMinute = 0;
                }
                var newMinute = oldMinute + (scope.minuteStep ? scope.minuteStep : 1);
                if (newMinute > 59) {
                    newMinute -= 60;
                    incHour();
                }
                updateMinute(newMinute);
            }

            function decMinute() {
                var oldMinute = parseInt(scope.minute);
                if (isNaN(oldMinute)) {
                    oldMinute = 0;
                }
                var newMinute = oldMinute - (scope.minuteStep ? scope.minuteStep : 1);
                if (newMinute < 0) {
                    newMinute += 60;
                    decHour();
                }
                updateMinute(newMinute);
            }

            function updateHour(value) {
                var strValue = (value < 10 ? '0' : '') + value;
                scope.hour = parseInt(strValue);
                hourInputCtrl.$modelValue = '0' + strValue[0];
                hourInputCtrl.$modelValue = hourInputParser(strValue[1]);
            }

            function updateMinute(value) {
                var strValue = (value < 10 ? '0' : '') + value;
                scope.minute = parseInt(strValue);
                minuteInputCtrl.$modelValue = '0' + strValue[0];
                minuteInputCtrl.$modelValue = minuteInputParser(strValue[1]);
            }

            function updateMode(value) {
                scope.mode = value;
                modeInputCtrl.$modelValue = value;
                modeInputCtrl.$modelValue = modeInputParser(value);
            }

            hourInputEl.on('keydown', function (evt) {
                keyCount--;

                switch (evt.keyCode) {
                    case 37: //left key
                        evt.preventDefault();
                        evt.stopPropagation();
                        break;
                    case 38: //up key
                        evt.preventDefault();
                        evt.stopPropagation();
                        incHour();
                        scope.handleHourMinuteChange();
                        minuteInput.focus();
                        minuteInput.select();
                        hourInput.focus();
                        hourInput.select();
                        break;
                    case 40: //down key
                        evt.preventDefault();
                        evt.stopPropagation();
                        decHour();
                        scope.handleHourMinuteChange();
                        minuteInput.focus();
                        minuteInput.select();
                        hourInput.focus();
                        hourInput.select();
                        break;
                    case 39: //right key
                        evt.preventDefault();
                        evt.stopPropagation();
                        minuteInput.focus();
                        minuteInput.select();
                        break;
                }
            });
            minuteInputEl.on('keydown', function (evt) {
                keyCount--;

                switch (evt.keyCode) {
                    case 37: //left key
                        evt.preventDefault();
                        evt.stopPropagation();
                        hourInput.focus();
                        hourInput.select();
                        break;
                    case 38: //up key
                        evt.preventDefault();
                        evt.stopPropagation();
                        incMinute();
                        scope.handleHourMinuteChange();
                        hourInput.focus();
                        hourInput.select();
                        minuteInput.focus();
                        minuteInput.select();
                        break;
                    case 40: //down key
                        evt.preventDefault();
                        evt.stopPropagation();
                        decMinute();
                        scope.handleHourMinuteChange();
                        hourInput.focus();
                        hourInput.select();
                        minuteInput.focus();
                        minuteInput.select();
                        break;
                    case 39: //right key
                        evt.preventDefault();
                        evt.stopPropagation();
                        modeInput.focus();
                        modeInput.select();
                        break;
                }
            });
            modeInputEl.on('keydown', function (evt) {
                keyCount--;

                switch (evt.keyCode) {
                    case 37: //left key
                        evt.preventDefault();
                        evt.stopPropagation();
                        minuteInput.focus();
                        minuteInput.select();
                        break;
                    case 38: //up key
                    case 40: //down key
                        evt.preventDefault();
                        evt.stopPropagation();
                        scope.toggleAmPm();
                        minuteInput.focus();
                        minuteInput.select();
                        modeInput.focus();
                        modeInput.select();
                        break;
                    case 39: //right key
                        evt.preventDefault();
                        evt.stopPropagation();
                        break;
                }
            });

            hourInputEl.on('click', function () {
                hourInput.select();
                //safari
                hourInput.setSelectionRange(0, 2)
            });
            minuteInputEl.on('click', function () {
                minuteInput.select();
                //safari
                minuteInput.setSelectionRange(0, 2)
            });
            modeInputEl.on('click', function () {
                modeInput.select();
                //safari
                modeInput.setSelectionRange(0, 2)
            });

            scope.$on('$destroy', function () {
                hourInputEl.off('click');
                hourInputEl.off('focus');
                hourInputEl.off('keydown');
                hourInputEl.off('blur');
                minuteInputEl.off('click');
                minuteInputEl.off('focus');
                minuteInputEl.off('keydown');
                minuteInputEl.off('blur');
                modeInputEl.off('blur');
                modeInputEl.off('click');
                modeInputEl.off('keydown');
                modeInputEl.off('blur');
            });

            function updateModel(value) {
                scope.model = value;
                modelWatcher(scope.model);
            }

            scope.$watch('ngModel', updateModel);
        }
    };
};

