//! @ngInject
export function visitInstancePotentialClockRecords($rootScope, $scope, $uibModalInstance, ClockinClockoutService, SweetAlert, toaster) {
    $scope.possibilities = $scope.$resolve.possibilities.map(x => ({
        ...x,
        formattedReason: ClockinClockoutService.formatReason({ reason: x.reason })
    }));
    $scope.isProcessing = false;

    const promptLinkPossibility = (possibility) => {
        SweetAlert.swal(
            {
              title: "Link Call",
              text: "Are you sure you want to link this call?",
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#3077EB",
              confirmButtonText: "Yes, link",
              closeOnConfirm: true,
              showConfirmButton: true,
              showLoaderOnConfirm: true,
            },
            hasConfirmed => hasConfirmed && linkCall(possibility)
          );
    }

    const linkCall = (possibility) => {
      $scope.isProcessing = true;
      return ClockinClockoutService.linkCalls($rootScope.agencyId, $rootScope.agencyMemberId,
        [{
          clockinClockoutRecordId: possibility.id,
          visitInstanceId: possibility.visitInstanceId
        }])
            .then(onSuccessLink)
            .catch(onFailLink)
            .finally(() => ($scope.isProcessing = false));
    }

    /**
       * Hanlde success response of linking a record
       * @param {Response} response 
       */
     function onSuccessLink(response) {
        toaster.pop("succes", "Success", "Call has been successfully linked");
        
        if ($scope.$resolve.onLink) {
            $scope.$resolve.onLink();
        }

        $uibModalInstance.close(response);
      }

      /**
       * Handle an error response of linking a record
       * @param {Error} error 
       */
      function onFailLink(error) {
        console.log(`error`, error)
        toaster.pop("error", "Something went wrong", "Could not link call");
      }

    $scope.promptLinkPossibility = promptLinkPossibility;
    $scope.linkCall = linkCall;
}