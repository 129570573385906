//! @ngInject
export function planOfCareModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, plan, DatabaseApi, toaster){

    window.scrollTo(0,0);

    //$scope.


    $scope.plan = plan;


    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };


};
