"use strict";

export const patientWeeklyTemplateTable = {
  templateUrl: "admin/views/patient-weekly-template-table.html",
  bindings: {
    weeklyShifts: "<",
    showShiftNumber: "<",
    styleVariant: "<", // "active" | "inactive"
    daysOfWeek: "<",
    patientActive: "<"
  }
};
