import $ from "jquery";
import { ChronoUnit, LocalDateTime, LocalTime } from "js-joda";
import moment from "moment";

export function visitTimeSpan() {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/visit-time-span.html',
        scope: {
            form: '=',
            advancedDays: '=',
            times: '=',
            isSkilled: '=',
            setSelectedTimeSpanType: '&',
            setIsAllDayHours: '&'
        },
        controller: 'visitTimeSpanCtrl'
    };
};

//! @ngInject
export function visitTimeSpanCtrl($scope,$stateParams, toaster) {
    $scope.timeSpanTabs = {
        single: {
            id: 0,
            name: 'Single',
            src: 'admin/views/visit-time-single.html'
        },
        permanent: {
            id: 1,
            name: 'Permanent',
            src: 'admin/views/visit-time-permanent.html'
        },
        flexible: {
            id: 2,
            name: 'Flexible',
            src: 'admin/views/visit-time-flexible.html'
        },
        splitshift: {
            id: 3,
            name: 'Live In / Splitshift',
            src: 'admin/views/visit-time-live-in.html'
        }
    }

    $scope.flexibleOptions = {
        'days': 'Flexible days',
        'hours': 'Flexible hours',
        'daysAndHours': 'Flexible days and hours'
    };
    $scope.resetTime = "12:00 AM";
    if($scope.form) {
        $scope.form.selectedFlexible = null;
    }

    initTimeSpanTab();
    initTimes24();

    function initTimeSpanTab() {
        // new visit - default single
        if (!$scope.form.id && !$stateParams.id) {
            $scope.activeTimeSpanTab = $scope.timeSpanTabs['single'].id;

            const start = moment($scope.form.datePicker.date.startDate);
            const dayOfTheWeek1 = $scope.advancedDays[start.day()].name;
            $scope.form.daysOfTheWeek[dayOfTheWeek1] = true;
            $scope.isAllDayHours = false;
            return;
        }

        //check created exist visits by types
        // splitshift
        if ($scope.form.splitShiftParams !== null) {
            $scope.activeTimeSpanTab = $scope.timeSpanTabs['splitshift'].id;
            $scope.selectedTimeSpanType = 'splitshift';
        }
        //flexible
        else if ($scope.form.flexibleVisitParams !== null || $scope.form.rnVisitParams !==null) {
                $scope.activeTimeSpanTab = $scope.timeSpanTabs['flexible'].id;
                // if non-skilled flexible
                if($scope.form.flexibleVisitParams !== null) {
                    $scope.selectedTimeSpanType = 'flexible';
                    setFlexibleSelectedVisit();
                }
        }
        //single
        else if($scope.form.isSingleVisit) {
            $scope.activeTimeSpanTab = $scope.timeSpanTabs['single'].id;
            $scope.selectedTimeSpanType = 'single';
        } 
        //permanent
        else {
            $scope.activeTimeSpanTab = $scope.timeSpanTabs['permanent'].id;
            $scope.selectedTimeSpanType = 'permanent';
        }
        $scope.setSelectedTimeSpanType({ val: $scope.selectedTimeSpanType });
    }

    function setFlexibleSelectedVisit() {
        switch($scope.form.flexibleVisitParams.type) {
            case 'FixedTimeFlexibleDays': {
                $scope.form.selectedFlexible = 'days'
                break;
            }
            case 'FlexibleTimeFixedDays': {
                $scope.form.selectedFlexible = 'hours'
                break;
            }
            case 'FlexibleTimeAndDays': {
                $scope.form.selectedFlexible = 'daysAndHours'
                break;
            }
            default: break;
        }
    }

    $scope.onTimeSpanTabChange = function (tab) {
        $scope.selectedTimeSpanType = tab;
        $scope.setSelectedTimeSpanType({ val: tab });

        // reset form settings
        $scope.form.advancedTimes = false;
        $scope.form.advancedTimesData = [{
            day: 0,
            startTime: '9:00 AM',
            endTime: '1:00 PM'
        }];
        $scope.form.advancedTimesData2 = [{
            day: 0,
            startTime: '2:00 PM',
            endTime: '6:00 PM'
        }];

        $scope.form.datePicker.date.startDate = new Date();
        $scope.form.datePicker.date.endDate = new Date();
        $scope.form.splitShiftParams = null;
        $scope.form.isPreAssigned = false;
        $scope.form.rnVisitParams = null;
        $scope.form.isDuration = false;
        
        switch (tab) {
            case 'single': {
                const start = moment($scope.form.datePicker.date.startDate);
                const dayOfTheWeek1 = $scope.advancedDays[start.day()].name;
                $scope.form.daysOfTheWeek = {};
                $scope.form.daysOfTheWeek[dayOfTheWeek1] = true;
                break;
            }
            case 'permanent': {
                if($scope.isSkilled) {
                    $scope.toggleRnVisitType('fixed');
                }

                break;
            }
            case 'flexible': {
                if($scope.isSkilled) {
                    $scope.form.rnVisitType = 'flexible';
                    $scope.toggleRnVisitType('flexible');
                }
                break;
            }
            case 'splitshift': {
                initSplitShiftParams();
                break;
            }
            default: return;
        }
    }

    $scope.setSplitShiftNumber = function (type, times) {
        $scope.form.splitShiftParams[type] = times;
    };

    $scope.selectAllDays = function () {
        $scope.toggleAllDays = !$scope.toggleAllDays;

        // more than one week
        if ($scope.getWeeksDiff() >= 1) {
            $scope.advancedDays.forEach(function (day) {
                $scope.toggleDays(day.name, true);
            });
        } else {
            // get days
            var start = moment($scope.form.datePicker.date.startDate);
            var end = moment($scope.form.datePicker.date.endDate);

            var selectedDays = [];
            for (var dayMoment = start; !dayMoment.isAfter(end); dayMoment.add(1, 'days')) {
                var dayName = moment(dayMoment).format('ddd').toLowerCase();
                selectedDays.push(dayName);
                $scope.toggleDays(dayName, true);
            }
            for (var day in $scope.form.daysOfTheWeek) {
                if (!selectedDays.includes(day)) {
                    delete $scope.form.daysOfTheWeek[day];
                }
            }
        }
    }

    $scope.undoToggleFlexibleTimes = function () {
        $scope.form.flexibleTimes = false;
    };

    $scope.selectTimePicker = function (time, day, startend, shift) {
        if (!time || !startend) return;

        if (!day || !day[startend]) return;

        var currentAmPm = day[startend].split(' ')[1];
        var timePart = time.split(' ')[0];

        // Shift overlap warning
        if ($scope.form.shifts > 1) {
            var tempShift = Object.assign({}, day);

            tempShift[startend] = timePart + ' ' + currentAmPm;

            shiftsOverlap(tempShift, day, shift);
        }

        day[startend] = timePart + ' ' + currentAmPm;
    };

    $scope.setSplitShiftTimePicker = function (time) {

        var timePart = time.split(' ')[0];
        
        var splittedTime = timePart.split(':');
        var startDate = moment($scope.form.datePicker.date.startDate);
        startDate.set({h:splittedTime[0], m:splittedTime[1]});
        $scope.form.splitShiftParams.shiftChangeTime = LocalTime.parse(startDate.format("hh:mm"));
        
        $scope.form.advancedTimesData[0].startTime = timePart + " AM";
        $scope.form.advancedTimesData[0].endTime = timePart + " PM";
    }


    function getRelativeTimeFromLabels(start, end) {
        var localTime = getLocalTimeFromLabels(start, end);
        var startTimeAMPM = start.split(' ')[1];
        var endTimeAMPM = end.split(' ')[1];

        if ((startTimeAMPM === 'PM' && endTimeAMPM === 'AM') ||
            (startTimeAMPM === endTimeAMPM &&
                (localTime.start.hour > localTime.end.hour ||
                    (localTime.start.hour === localTime.end.hour &&
                        localTime.start.minute > localTime.end.minute
                    )
                )
            )
        ) {
            localTime.end.hour += 24;
        }

        return localTime;
    }


    function getLocalTimeFromLabels(start, end) {
        var startTimeAMPM = start.split(' ')[1];
        var startTimeHour = parseInt(start.split(' ')[0].split(':')[0]);
        var startTimeMinutes = parseInt(start.split(' ')[0].split(':')[1]);

        var endTimeAMPM = end.split(' ')[1];
        var endTimeHour = parseInt(end.split(' ')[0].split(':')[0]);
        var endTimeMinutes = parseInt(end.split(' ')[0].split(':')[1]);

        if (startTimeHour === 12 && startTimeAMPM === 'AM') startTimeHour = 0;
        else if (startTimeHour !== 12 && startTimeAMPM === 'PM') startTimeHour += 12;
        if (endTimeHour === 12 && endTimeAMPM === 'AM') endTimeHour = 0;
        else if (endTimeHour !== 12 && endTimeAMPM === 'PM') endTimeHour += 12;

        var data = {
            start: { hour: startTimeHour, minute: startTimeMinutes },
            end: { hour: endTimeHour, minute: endTimeMinutes }
        };

        return data;
    }


    function shiftsOverlapping(shift1, shift2) {
        var shift1end = getRelativeTimeFromLabels(shift1.startTime, shift1.endTime).end;
        var shift2start = getRelativeTimeFromLabels(shift2.startTime, shift2.endTime).start;

        // Checking overlap betweeen shifts
        return (
            parseInt([shift1end.hour, shift1end.minute].join('')) >=
            parseInt([shift2start.hour, shift2start.minute].join(''))
        );
    }


    function shiftsOverlap(newShift, oldShift, shiftNumber) {
        var compShift, shiftsOverlaps;

        if (shiftNumber === 1) {
            compShift = $scope.form.advancedTimesData2[$scope.form.advancedTimesData.indexOf(oldShift)];
            shiftsOverlaps = shiftsOverlapping(newShift, compShift);
        } else {
            compShift = $scope.form.advancedTimesData[$scope.form.advancedTimesData2.indexOf(oldShift)];
            shiftsOverlaps = shiftsOverlapping(compShift, newShift);
        }

        if (shiftsOverlaps) {
            toaster.pop('warning', "Oops...", 'Shifts overlapse..');
        }
    };


    $scope.toggleAmPm = function (time, day, startend, shift) {
        if (!time || !startend) return;

        if (!day || !day[startend]) return;

        var timePart = time.split(' ')[0];
        var ampmPart = time.split(' ')[1] === 'AM' ? 'PM' : 'AM';

        // Shift overlap warning
        if ($scope.form.shifts > 1) {
            var tempShift = Object.assign({}, day);

            tempShift[startend] = timePart + ' ' + ampmPart;

            shiftsOverlap(tempShift, day, shift);
        }

        day[startend] = timePart + ' ' + ampmPart;
    };

    $scope.getWeeksDiff = function () {
        var start = convertDate($scope.form.datePicker.date.startDate);
        var end = convertDate($scope.form.datePicker.date.endDate);

        return start.until(end, ChronoUnit.WEEKS);
    };

    $scope.isOverNight = function (day) {
        var start, end;
        var idx = 0;
        while ((!start || !end) && idx < $scope.times.length) {
            if (day.startTime === $scope.times[idx].label) {
                start = $scope.times[idx].value;
            }
            if (day.endTime === $scope.times[idx].label) {
                end = $scope.times[idx].value;
            }
            idx++;
        }

        if (!start || !end) return false;

        start = LocalTime.parse(start);
        end = LocalTime.parse(end);

        return end.isBefore(start) || end.compareTo(start) === 0;;
    };

    $scope.openCalendar = function () {
        $('#date-picker').data('daterangepicker').show();
    };

    $scope.toggleHideEndDate = function () {
        $scope.form.hideEndDateFromCaregiver = !$scope.form.hideEndDateFromCaregiver;
    };

    $scope.timeDiffHoursLabel = function (days) {
        var difMinutes = 0;
        days.forEach(function (day) {
            difMinutes += $scope.getTimeDiffMinutes(day);
        });

        var diffHours = Math.floor(difMinutes / 60);
        difMinutes = difMinutes - (diffHours * 60);

        var returnString = '';
        if (diffHours) returnString += diffHours + 'H:';
        if (difMinutes) returnString += difMinutes + 'M';
        else returnString = returnString.replace(':', '');

        return returnString;
    };


    $scope.getTimeDiffMinutes = function (day) {
        var start, end;
        var idx = 0;
        while ((!start || !end) && idx < $scope.times.length) {
            if (day.startTime === $scope.times[idx].label) {
                start = $scope.times[idx].value;
            }
            if (day.endTime === $scope.times[idx].label) {
                end = $scope.times[idx].value;
            }
            idx++;
        }

        if (!start || !end) return false;

        start = LocalTime.parse(start);
        end = LocalTime.parse(end);

        var difMinutes = start.until(end, ChronoUnit.MINUTES);
        if (end.isBefore(start)) difMinutes = 1440 + difMinutes;

        return difMinutes;
    }

    $scope.toggleDays = function (day, enforceChoice) {
        if ($scope.form.disableDays) {
            return;
        }

        $scope.form.flexibleDays = false;
        if (enforceChoice) {
            $scope.form.daysOfTheWeek[day] = $scope.toggleAllDays;
        } else {
            $scope.form.daysOfTheWeek[day] = !$scope.form.daysOfTheWeek[day];
        }

        $scope.form.daysOfTheWeekCount = 0;
        for (var key in $scope.form.daysOfTheWeek) {
            if ($scope.form.daysOfTheWeek[key]) $scope.form.daysOfTheWeekCount++;
        }
    
        $scope.setFlexibleDaysCount($scope.form.daysOfTheWeekCount);
        if($scope.form.flexibleDaysCount < 7 && !enforceChoice) { 
            $scope.toggleAllDays = false;
        }

    };

    function initSplitShiftParams() {
        $scope.form.splitShiftParams = {};
        $scope.form.splitShiftParams.availableShifts = [];

        // set inital shiftChangeTime param
        var startDate = moment($scope.form.datePicker.date.startDate);
        startDate.set({h:'9', m:'00'});
        $scope.form.splitShiftParams.shiftChangeTime = LocalTime.parse(startDate.format("hh:mm"));
        $scope.form.advancedTimesData[0].startTime = "9:00 AM";
        $scope.form.advancedTimesData[0].endTime = "9:00 PM";
    }

    $scope.setFlexibleDaysCount = function (days) {
        $scope.form.flexibleDaysCount = days;
    };

    $scope.toggleRnVisitType = function (type) {
        $scope.form.rnVisitType = type;
        if ($scope.form.rnVisitType === 'fixed') {
            $scope.form.isDuration = false;
            $scope.form.contactPatient = false;
            $scope.form.rnVisitParams = false;
        } else {
            $scope.form.isDuration = true;
        }

        $scope.form.hideEndDateFromCaregiver = false;
        $scope.form.flexibleTimes = false;
        $scope.form.flexibleDays = false;
    };
    
    $scope.toggleContactPatient = function(){

        if($scope.form.flexibleDays || $scope.form.flexibleTimes) return;

        $scope.form.contactPatient = !$scope.form.contactPatient;

    };


    $scope.toggleAdvancedtimes = function () {
        if ($scope.form.flexibleDays || $scope.form.flexibleTimes || $scope.isSingleVisit()) {
            $scope.form.advancedTimes = false;
            $scope.form.advancedTimesData = [{
                day: 0,
                startTime: $scope.form.advancedTimesData[0].startTime,
                endTime: $scope.form.advancedTimesData[0].endTime
            }];
            $scope.form.advancedTimesData2 = [{
                day: 0,
                startTime: $scope.form.advancedTimesData2[0].startTime,
                endTime: $scope.form.advancedTimesData2[0].endTime
            }];
            toaster.pop('warning', "Oops...",
                'Can’t select different times for different days with flexible days or flexible time');

        } else {

            $scope.form.advancedTimes = !$scope.form.advancedTimes;
            if ($scope.form.advancedTimes) {
                for (var i = 1; i < 7; i++) {
                    $scope.form.advancedTimesData.push({
                        day: i,
                        startTime: $scope.form.advancedTimesData[0].startTime,
                        endTime: $scope.form.advancedTimesData[0].endTime
                    });
                    $scope.form.advancedTimesData2.push({
                        day: i,
                        startTime: $scope.form.advancedTimesData2[0].startTime,
                        endTime: $scope.form.advancedTimesData2[0].endTime
                    });
                }
            } else {
                $scope.form.advancedTimesData = [{
                    day: 0,
                    startTime: $scope.form.advancedTimesData[0].startTime,
                    endTime: $scope.form.advancedTimesData[0].endTime
                }];
                $scope.form.advancedTimesData2 = [{
                    day: 0,
                    startTime: $scope.form.advancedTimesData2[0].startTime,
                    endTime: $scope.form.advancedTimesData2[0].endTime
                }];
            }

        }
    };

    $scope.isSingleVisit = function (days) {
        if (!$scope.form.datePicker.date.startDate || !$scope.form.datePicker.date.endDate) return false;

        var start = moment($scope.form.datePicker.date.startDate);
        var end = moment($scope.form.datePicker.date.endDate);
        var daysDiff = end.diff(start, 'd');

        if (daysDiff === 0 || daysDiff === 1) {
            $scope.form.disableDays = true;
            if ($scope.form.selectedFlexible !== 'daysAndHours') {
                $scope.form.flexibleDays = false;
                $scope.form.selectedFlexible = null;
            }

            $scope.form.daysOfTheWeek = {};

            var dayOfTheWeek1 = $scope.advancedDays[start.day()].name;
            var dayOfTheWeek2 = $scope.advancedDays[end.day()].name;
            $scope.form.daysOfTheWeek[dayOfTheWeek1] = true;
            $scope.form.daysOfTheWeek[dayOfTheWeek2] = true;


        } else {
            $scope.form.disableDays = false;
        }

        //if($scope.form.flexibleDays) $scope.form.daysOfTheWeek = {};

        if (!days) {
            days = 0;
        }
        return daysDiff === days;
    };

    $scope.setFlexibleTimesCount = function (times) {
        $scope.form.flexibleTimesCount = times;
    };


    /**
     * Converts a ISO 8601 UTC timestamp according to the current browser's timezone
     * @param date {string} ISO 8601 UTC timestamp
     * @returns {LocalDateTime}
     */
    function convertDate(date, reset) {
        var d = new Date(date);
        var jodaDate;
        if (reset) {
            jodaDate = LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), 0, 0, 0, 0);

            jodaDate = jodaDate.withHour(0);
            jodaDate = jodaDate.withMinute(0);
            jodaDate = jodaDate.withSecond(0);
            jodaDate = jodaDate.withNano(0);

        } else {
            jodaDate = LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds() * 1000000);

        }
        return jodaDate;
    }


    $scope.totalTimeForWeek = function (shifts, daysDetails, workingDays, isAdvancedTimes) {
        var days = daysDetails.filter(function (day) {
            return workingDays[day.name];
        }).map(function (dayDetails) {
            return dayDetails.day;
        });

        var shiftsToSum = [];

        if (!isAdvancedTimes) {
            var shift1 = shifts[0];
            var shift2 = shifts[1];
            days.forEach(function (day) {
                var newShift = Object.assign({}, shift1);
                newShift.day = day;
                shiftsToSum.push(newShift);
                
                if ($scope.form.shifts === 2) {
                    var newShift = Object.assign({}, shift2);
                    newShift.day = day;
                    shiftsToSum.push(newShift)
                }
            });
        } else {
            if ($scope.form.shifts === 1) {
                shiftsToSum = shifts.slice(0, shifts.length / 2);
            } else {
                shiftsToSum = shifts;
            }

            shiftsToSum = shiftsToSum.filter(function (shift) {
                return days.includes(shift.day);
            });
        }

        $scope.form.totalTimePerWeek = $scope.timeDiffHoursLabel(shiftsToSum);
        return $scope.form.totalTimePerWeek;
    }

    $scope.toggleFlexibleDays = function(){

        if ($scope.form.advancedTimes) {
            toaster.pop('warning',"Oops...",
                'Can’t select flexible days with "different times for different days"');

            return;
        }

        if ($scope.form.isDuration || $scope.form.contactPatient) {
            toaster.pop('warning',"Oops...",
                'Can’t select flexible days with "different times RN or LPN"');

            return;
        }

        if ($scope.form.shifts > 1) {
            toaster.pop('warning',"Oops...",
                'Can’t select flexible days with "Split hours"');

            return;
        }

        if ($scope.isSingleVisit()) {
            $scope.form.flexibleDays = false;
            toaster.pop('warning',"Oops...",
                'Can’t select flexible days for short visit"');
                $scope.form.selectedFlexible = null;

        } else {
            $scope.form.flexibleDays = !$scope.form.flexibleDays;
        }

        $scope.form.daysOfTheWeek = $scope.form.flexibleDays ? {} : $scope.form.daysOfTheWeek;

    };

    $scope.toggleFlexibleDaysAndTimes = function() {
        switch($scope.form.selectedFlexible) {
            case 'days': {
                $scope.toggleFlexibleDays();
                $scope.form.flexibleTimes = false;
                break;
            }
            case 'hours': {
                $scope.form.flexibleTimes = true;
                $scope.form.flexibleDays = false;
                break;
            }
            case 'daysAndHours': {
                $scope.form.flexibleTimes = true;
                $scope.form.flexibleDays = true;
                break;
            }
            default: break;
        }
    }

    $scope.onSingleDatePickerChange = function (date) {
        $scope.form.datePicker.date = {
            startDate: date,
            endDate: date
        };

        var start = moment($scope.form.datePicker.date.startDate);
        var dayOfTheWeek1 = $scope.advancedDays[start.day()].name;
        $scope.form.daysOfTheWeek = {};
        $scope.form.daysOfTheWeek[dayOfTheWeek1] = true;
    }

    $scope.toggle24Hours = function () {
        $scope.form.advancedTimesData.forEach(function (time, index) {
            time.startTime = $scope.resetTime;
            time.endTime = $scope.resetTime;
        });
        $scope.isAllDayHours = true;
        $scope.setIsAllDayHours({ val: $scope.isAllDayHours });
    }

    $scope.enable24HoursToggle = function() {
        var result = $scope.form.advancedTimesData.some(function (time, index) {
            return (time.startTime !== $scope.resetTime) || (time.endTime !== $scope.resetTime);
        });
        if(result) {
            $scope.isAllDayHours = false;
            $scope.setIsAllDayHours({ val: $scope.isAllDayHours });
        }
        return result;
    }

    function initTimes24() {
        // make hours intervals
        var interval = 15; //minutes interval
        var times24 = []; // time array
        var tt = 0; // start time
        for (var i = 0; tt < 24 * 60; i++) {
            var hh = Math.floor(tt / 60);
            var realHH = Math.floor(tt / 60); // getting hours of day in 0-24 format
            if (hh === 0) hh = 12;
            var mm = (tt % 60); // getting minutes of the hour in 0-55 format
            if (i) {
                times24[i] = realHH + ':' + ("0" + mm).slice(-2);
            }
            tt = tt + interval;
        }
        times24.push('24:00');
        $scope.times24 = times24;
    }

    $scope.singleVisitDatePickerOptions = {
        datepickerMode: 'day',
        minDate: new Date()
    };
};