//! @ngInject
export function visitFileUploadCtrl(
  $scope,
  $rootScope,
  toaster,
  VisitFileUploadService,
  wildcard,
  DatabaseApi,
) {
  const type = "SCHEDULE";

  $scope.isUploading = false;
  $scope.isLoading = false;
  $scope.files = [];

  (function init() {
    getHistory();
  })();

  $scope.updateFiles = function (filesWithTempObjects) {
    const filesToBeAdded = filesWithTempObjects.filter((file) => file.name);
    if (filesToBeAdded.length === 0) return;

    const alreadyAddedFileNames = $scope.files.map((file) => file.name);
    const newFiles = filesToBeAdded.filter(
      (fileToBeAdded) => !alreadyAddedFileNames.includes(fileToBeAdded.name)
    );

    $scope.files = $scope.files.concat(newFiles);
  };

  $scope.removeFile = function ({ name }) {
    if (!name) return;

    $scope.files = $scope.files.filter((file) => file.name !== name);
  };

  $scope.uploadFiles = function () {
    $scope.isUploading = true;

    VisitFileUploadService.uploadFiles({
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId,
      type,
      files: $scope.files,
    })
      .then(() => {
        toaster.pop("success", "Success", "File Uploaded");
      })
      .catch(() =>
        toaster.pop("error", "Something Went Wrong", "Please try again")
      )
      .finally(() => {
        getHistory();
        $scope.files = [];
        $scope.isUploading = false;
      });
  };

  function getHistory() {
    $scope.isLoading = true;

    VisitFileUploadService.getHistory({
      agencyId: $rootScope.agencyId,
      type,
    })
      .then((history) => ($scope.history = history))
      .catch(() =>
        toaster.pop("error", "Something Went Wrong", "Please try again")
      )
      .finally(() => {
        $scope.isLoading = false;
      });
  }

  $scope.exportTxt = function ({ filename, createdAt, message }) {
    const blob = new Blob([message], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}-${createdAt}-result.txt`);

    link.click();
    window.URL.revokeObjectURL(url);
  };

  $scope.downloadOriginalFile = function ({ id }) {
    const endpoint = wildcard(
      `agencies/:agencyId/table_file_upload/:uploadJobId/url`,
      $rootScope.agencyId,
      id
    );

    DatabaseApi.get(endpoint)
      .then(({ data: { url } }) => {
        window.open(url);
      })
      .catch(() => toaster.pop("error", "File wasn't found"));
  };
};
