import { UseQueryResult } from "@tanstack/react-query";

type Loading = { type: "Loading" };
type Resolved<T> = { type: "Resolved"; value: T };
type Rejected<E> = { type: "Rejected"; error: E };
export type Loadable<T> = Loading | Resolved<T> | Rejected<any>;

export const loadable = {
  loading(): Loading {
    return { type: "Loading" };
  },

  resolve<T>(value: T): Resolved<T> {
    return { type: "Resolved", value };
  },

  reject<T>(error: T): Rejected<T> {
    return { type: "Rejected", error };
  },

  isLoading<T>(value: Loadable<T>): value is Loading {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Loading"
    );
  },

  isResolved<T>(value: Loadable<T>): value is Resolved<T> {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Resolved"
    );
  },

  isRejected<T>(value: Loadable<T>): value is Rejected<T> {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Rejected"
    );
  },

  fromUndefined<T>(value: T | undefined): Loadable<T> {
    return value === undefined ? loadable.loading() : loadable.resolve(value);
  },

  fromQuery<T>(query: UseQueryResult<T>): Loadable<T> {
    if (query.isLoading) {
      return loadable.loading();
    }

    if (query.isError) {
      return loadable.reject(query.error);
    }

    return loadable.resolve(query.data);
  },

  fmap<T, U>(value: Loadable<T>, cb: (value: T) => U): U | undefined {
    if (loadable.isResolved(value)) {
      return cb(value.value);
    }

    return undefined;
  },
};

export type LoadableConstructor = typeof loadable;
