import { LocalDateTime, LocalTime, nativeJs } from "js-joda";

//! @ngInject
export function agencySettingsModal(
      $scope,
      $http,
      AgencySettingsService,
      agencyData,
      toaster,
      Consts,
      wildcard
    ) {
      $scope.agencyData = agencyData;
      $scope.tasks = AgencySettingsService.getSettings();
      $scope.subscriptions = AgencySettingsService.getSubscriptions();
      $scope.dateRangePicker = {
        options: {
          alwaysShowCalendars: true,
          applyClass: "btn-primary",
          locale: {
            format: "D MMM YYYY", //will give you 6  Jan 17
          },
          autoApply: true,
          eventHandlers: {
            "apply.daterangepicker": function () {
              const subscriptions = {};
              
              for (const subscription of Object.keys($scope.subscriptions)) {
                let { startDate, endDate } = agencyData[subscription];
                
                if (startDate === null || endDate === null) {
                  subscriptions[subscription] = { startDate: null, endDate: null };
                  continue;
                }

                // We get hardcoded "12:00" from the server. In order to have a consistency
                // with the server status (`.enabled`), we'll have to hardcode it in here too
                startDate = LocalDateTime.from(nativeJs(new Date(startDate)), LocalTime.parse("12:00"));
                endDate = LocalDateTime.from(nativeJs(new Date(endDate)), LocalTime.parse("12:00"));

                subscriptions[subscription] = { startDate, endDate };

                agencyData[subscription].enabled = 
                    startDate.isBefore(LocalDateTime.now()) &&
                    endDate.isAfter(LocalDateTime.now());
              }

              return AgencySettingsService.changeSubscription(agencyData.id, subscriptions)
                .then(() => toaster.pop("success", "Saved."))
                .catch(() => toaster.pop("error", "Enexpected error."));
            }
          }
        },
      }

      $scope.handleTaskRowClick = (taskId) => {
        $scope.agencyData[taskId] = !$scope.agencyData[taskId];

        switch (taskId) {
          case "allowBoost":
          case "shouldSendInvoice":
            return handleAgencySettingTask(taskId);
          default:
            return handleUnknownEvent();
        }
      };

      $scope.handleSubscriptionTrigger = (e, subscriptionId, agencyData) => {
        e.stopPropagation();

        const getEndpointByKey = (key) => {
          const endpoint = `${Consts.api}admin/agencies/:agencyId/trigger_sms/:subscription`;
          return wildcard(endpoint, agencyData.id, key);
        };

        const endpoints = {
          withPrizes: "send-sms-prizes",
          withoutPrizes: "send-sms-without-prizes",
          training: "orientation-notification-reminder",
          corona: "daily-covid-screening-notification",
          evvInitial: "send-sms-evv-initial",
          downloadInitial: "download-initial", // instead of makeCalls: "Make calls",
          downloadOngoing: "download-ongoing",
          trainingInitial: "training-initial",
          trainingOngoing: "training-ongoing"
        };

        const url = getEndpointByKey(endpoints[subscriptionId]);

        $http
          .post(url)
          .then(() => toaster.pop("success", "Triggered successfully"))
          .catch(() => toaster.pop("error", "Unexpected error"));
      };

      function handleAgencySettingTask(taskId) {
        const label = $scope.tasks[taskId];
        const active = agencyData[taskId];

        return AgencySettingsService.changeSetting(taskId, active, {
          agencyId: agencyData.id,
        })
          .then(() => toaster.pop("success", `${label} has been changed`))
          .catch(() => toaster.pop("error", `Error while changing ${label}`));
      }

      function handleUnknownEvent(taskId) {
        toaster.pop("error", `couldn't identify task ${taskId}`);
      }
    }

