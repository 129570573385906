//! @ngInject
export function patientScheduleTaskModalCtrl(
      $scope,
      $rootScope,
      $uibModalInstance,
      toaster,
      dateUtils,
      tasksService,
    ) {

      const initialize = () => {
        $scope.dataModel = null;
        $scope.task = $scope.$resolve.task;
        $scope.datePickerOptions = {
          minDate: $scope.task.startDate.clone().toDate(),
          maxDate: $scope.task.dueDate.clone().toDate()
        };
      };

      $scope.submit = () => {
        const body = {
          scheduledDateTime: dateUtils.dateToLocalDateTime($scope.dataModel.startTime.toDate()),
          duration: $scope.dataModel.totalDurationInMinutes
        };

        $scope.isLoading = true;
        tasksService
          .setPatientTaskScheduledDate($scope.task.patientTaskInstanceId, body)
          .then(() => {
            $rootScope.$emit("refresh_visits");
            toaster.pop("success", "Successfully updated task scheduled date time");
            $scope.close();
          })
          .catch(() => {
            toaster.pop("error", "Something went wrong", "Could not set task scheduled date time");
          })
          .finally(() => ($scope.isLoading = false));
      };

      $scope.handleTimeChange = (updatedDate) => {
        if(!updatedDate) return;
        $scope.dataModel = updatedDate;
      };

      $scope.close = () => {
        $uibModalInstance.close();
      };

      initialize();
    }
  ;
