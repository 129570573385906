import { Box, Flex, Progress } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
  as?: React.ElementType;
}

const Page = (props: Props) => {
  return (
    <Page.Root as={props.as}>
      <div style={{ opacity: props.isLoading === true ? 1 : 0 }}>
        <Progress size="xs" isIndeterminate />
      </div>
      <Flex direction="column" m={3} rounded={8} bg="white" boxShadow="sm" height="full" flex={1}>
        {props.children}
      </Flex>
    </Page.Root>
  );
};

Page.Root = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 170px);
`;

Page.Header = styled.div`
  padding: 24px 16px 16px;
`;

Page.Title = styled.h2``;

Page.Filters = styled.div`
  padding: 24px 32px;
`;

Page.Content = styled.div`
  padding: 24px 32px;
  height: 100%;
`;

export default Page;
