import angular from "angular";
import { DateTimeFormatter, DayOfWeek, LocalDate } from "js-joda";
import { ApiErrors } from "../consts/apiErrors.const";
import { DateUtils } from "../consts/dateUtils";
import { loadable, Loadable } from "../consts/loadable.const";
import { CaregiverWithDisplayName } from "../messages/caregiver";
import { PatientPlansOfCareDutySheetsResponse } from "../messages/duty_sheet";
import {
  CaregiverId,
  OfficeId,
  PatientId,
  PlanOfCareItemId,
  VisitInstanceId,
} from "../messages/ids";
import { PlanOfCareType } from "../messages/plan_of_care_type";
import { DatabaseApiService } from "../services/db";
import { DutySheetService } from "../services/dutySheetService";
import { PlanOfCareTypeService } from "../services/planOfCareTypeService";

interface TableColumn {
  dayOfWeek: DayOfWeek;
  title: string;
  date: string;
}

interface TableRowDuty {
  visitInstanceId: VisitInstanceId;
  notes: string | null;
  uniqueId: string;
  caregiverName: string;
  caregiverAvatar: string | null;
  isCompleted: boolean;
}

interface TableRow {
  item: {
    id: PlanOfCareItemId;
    label: string;
    code: string;
    section: string;
  };
  days: Record<string, TableRowDuty[]>;
}

interface Bindings {
  patientId: PatientId;
  patientCurrentOfficeId: OfficeId;
}

interface ComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof Bindings, string>;
}

//! @ngInject
class PatientDutySheetCtrl implements ng.IComponentController, Bindings {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $scope: ng.IScope,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $timeout: ng.ITimeoutService,
    private $filter: ng.IFilterService,
    private DatabaseApi: DatabaseApiService,
    private toaster: toaster.IToasterService,
    private planOfCareTypeService: PlanOfCareTypeService,
    private dutySheetService: DutySheetService,
    private dateUtils: DateUtils,
    private apiErrors: ApiErrors
  ) {
    this.dateRange = {
      from: this.dateUtils.startOfWeek(
        LocalDate.now(),
        this.$rootScope.getCalendarFirstDayOfWeek()
      ),

      to: this.dateUtils
        .startOfWeek(LocalDate.now(), this.$rootScope.getCalendarFirstDayOfWeek())
        .plusDays(6),
    };

    this.$scope.$watch("ctrl.dateRange", this.fetchDateRangeData.bind(this), true);
    this.$scope.$on("got_caregivers_data", () => this.fetchDateRangeData());
  }

  //#region bindings
  patientId!: PatientId;
  patientCurrentOfficeId!: OfficeId;
  //#endregion

  //#region template variables
  operatorIconMap = {
    MOBILE: "admin/images/icons/caregiver.svg",
    IVR: "admin/images/icons/call.svg",
    COORDINATOR: "admin/images/icons/operator.svg",
  };
  //#endregion

  dateRange: { from: LocalDate; to: LocalDate };
  rangeData: Loadable<PatientPlansOfCareDutySheetsResponse> = loadable.loading();
  selectedRowData: PatientPlansOfCareDutySheetsResponse["rows"][number] | null = null;
  selectedCaregiverIds: Set<CaregiverId> = new Set();
  selectedCaregivers: CaregiverWithDisplayName[] = [];
  planOfCareTypes: Loadable<PlanOfCareType[]> = loadable.loading();
  shouldShowOptionalRows = false;
  tableColumns: TableColumn[] = [];
  tableRows: TableRow[] = [];
  hasPlanOfCare: boolean | null = null;

  $onInit() {
    this.fetchDateRangeData();
    this.fetchPlanOfCareTypes();
  }

  handleClickNextWeek() {
    this.dateRange = {
      from: this.dateRange.from.plusWeeks(1),
      to: this.dateRange.to.plusWeeks(1),
    };
  }

  handleClickPreviousWeek() {
    this.dateRange = {
      from: this.dateRange.from.minusWeeks(1),
      to: this.dateRange.to.minusWeeks(1),
    };
  }

  handleToggleShowOptionalRows() {
    this.shouldShowOptionalRows = !this.shouldShowOptionalRows;
    this.tableRows = this.mapDataToTableRows();
  }

  handleClickExportData() {
    if (this.selectedRowData === null) {
      return;
    }

    const planOfCareId = this.selectedRowData.planOfCare.id;

    const modalInstance = this.$uibModal.open({
      templateUrl: "admin/views/export-duty-sheet-data-modal.html",
      size: "md",
      controller: "exportDutySheetDataModalCtrl",
      controllerAs: "ctrl",
      resolve: {
        fromDate: () => this.dateRange.from,
        patientId: () => this.patientId,
        planOfCareId: () => planOfCareId,
      },
    });

    modalInstance.result.then((res: unknown) => {
      if (res === "success") {
        this.fetchDateRangeData();
        this.$rootScope.$emit("patient_poc_updated");
      }
    });
  }

  handleClickPlanOfCare(row: PatientPlansOfCareDutySheetsResponse["rows"][number]) {
    this.setSelectedRowData(row);
  }

  handleToggleCaregiver(caregiverId: CaregiverId) {
    this.selectedCaregiverIds.has(caregiverId)
      ? this.selectedCaregiverIds.delete(caregiverId)
      : this.selectedCaregiverIds.add(caregiverId);

    this.tableRows = this.mapDataToTableRows();
  }

  handleClickCheckDuty(params: {
    planOfCareItemId: PlanOfCareItemId;
    visitInstanceId: VisitInstanceId;
    completed: boolean;
  }) {
    if (this.selectedRowData === null) {
      return console.warn("No selected row data");
    }

    this.dutySheetService
      .answer({
        completed: params.completed,
        planOfCareItemId: params.planOfCareItemId,
        visitInstanceId: params.visitInstanceId,
      })
      .then(() => {
        this.$timeout(
          () =>
            this.toaster.success(
              `Duty task has been marked as ${params.completed ? "completed" : "incomplete"}`
            ),
          0
        );
      });
  }

  handleRequestFocusNoteTextarea($event: JQueryEventObject) {
    $event.target.parentElement?.querySelector("textarea")?.focus();
  }

  handleKeyUpDutyNote($event: JQueryEventObject) {
    if ($event?.keyCode === 13) {
      angular.element($event.target).blur();
    }
  }

  handleChangeDutyNote(params: {
    duty: TableRowDuty;
    planOfCareItemId: PlanOfCareItemId;
    selectedRowData: PatientPlansOfCareDutySheetsResponse["rows"][number];
  }) {
    this.dutySheetService
      .submitNote({
        visitInstanceId: params.duty.visitInstanceId,
        planOfCareItemId: params.planOfCareItemId,
        notes:
          params.duty.notes === null || params.duty.notes.length === 0
            ? null
            : params.duty.notes.replace(/\r?\n/gm, ""),
      })
      .then(() => this.$timeout(() => this.toaster.success("Note has been saved"), 0))
      .catch((error) => {
        const errorMessage = this.apiErrors.format(error, "Failed to save note");
        this.$timeout(() => this.toaster.error(errorMessage), 0);

        // revert the note back to the previous value
        params.duty.notes = params.selectedRowData.dutySheets.find(
          (x) => x.visitInstanceId === params.duty.visitInstanceId
        )!.selectedItems.find((x) => x.planOfCareItemId === params.planOfCareItemId)!.notes;
      });
  }

  private mapDataToTableRows() {
    if (this.selectedRowData === null || !loadable.isResolved(this.planOfCareTypes)) {
      return [];
    }

    const planOfCareType = this.planOfCareTypes.value.find(({ planOfCareTypeId }) => {
      return planOfCareTypeId === this.selectedRowData!.planOfCare.planOfCareTypeId;
    });

    if (planOfCareType === undefined) {
      throw new Error(
        `Could not find plan of care type for ${this.selectedRowData!.planOfCare.planOfCareTypeId}`
      );
    }

    const planOfCareRequiredItems = new Set(
      this.selectedRowData.planOfCare.duties.map((duty) => duty.item.id)
    );

    const planOfCareItems = planOfCareType.columns.filter(
      (item) => this.shouldShowOptionalRows || planOfCareRequiredItems.has(item.id)
    );

    const rows: TableRow[] = [];

    for (const planOfCareItem of [...planOfCareItems].sort(
      (a, b) => parseInt(a.code, 10) - parseInt(b.code, 10)
    )) {
      rows.push({
        item: {
          code: planOfCareItem.code,
          id: planOfCareItem.id,
          label: planOfCareItem.label,
          section: planOfCareItem.section,
        },
        days: this.dateUtils.getLocalDatesInRange(this.dateRange).reduce((days, date) => {
          const relevantDutySheets = this.selectedRowData!.dutySheets.filter((dutySheet) =>
            dutySheet.visitInstanceStartDate.equals(date)
          );

          days[date.toString()] = relevantDutySheets
            .filter((dutySheet) => this.selectedCaregiverIds.has(dutySheet.caregiverId))
            .map((dutySheet) => {
              const caregiver = this.selectedCaregivers.find(
                ({ id }) => dutySheet.caregiverId === id
              )!;

              const selected = dutySheet.selectedItems.find(
                (item) => item.planOfCareItemId === planOfCareItem.id
              );

              return {
                visitInstanceId: dutySheet.visitInstanceId,
                caregiverName: caregiver.displayName,
                caregiverAvatar: caregiver.photoUrl,
                isCompleted: selected?.isCompleted === true,
                uniqueId: `${dutySheet.visitInstanceId}-${dutySheet.caregiverId}-{${planOfCareItem.id}}`,
                notes: selected?.notes ?? null,
                type: selected?.type ?? null,
              };
            });

          return days;
        }, {} as TableRow["days"]),
      });
    }

    return rows;
  }

  private fetchPlanOfCareTypes() {
    this.planOfCareTypes = loadable.loading();

    this.planOfCareTypeService
      .get()
      .then((planOfCareTypes) => (this.planOfCareTypes = loadable.resolve(planOfCareTypes)))
      .catch((error) => {
        const errorMessage = this.apiErrors.format(error, "Failed to get plan of care types");
        this.planOfCareTypes = loadable.reject(errorMessage);
        this.toaster.error(errorMessage);
      });
  }

  private fetchDateRangeData() {
    this.rangeData = loadable.loading();
    this.selectedCaregiverIds = new Set();
    this.selectedCaregivers = [];
    this.selectedRowData = null;
    this.tableColumns = this.getTableColumns();

    if (Object.keys(this.DatabaseApi.caregivers()).length === 0) {
      return;
    }

    this.dutySheetService
      .getByPatient({ patientId: this.patientId, startOfWeek: this.dateRange.from })
      .then((response) => {
        this.$timeout(() => {
          this.rangeData = loadable.resolve(response);
          this.hasPlanOfCare = this.rangeData.value.hasPlanOfCare;

          if (this.rangeData.value.rows.length === 1) {
            this.setSelectedRowData(this.rangeData.value.rows[0]);
          }
        }, 0);
      })
      .catch((error) => {
        console.error(error);
        loadable.reject("Failed to get patient duty sheet");
      });
  }

  private setSelectedRowData(row: PatientPlansOfCareDutySheetsResponse["rows"][number]) {
    this.selectedRowData = row;
    this.selectedCaregiverIds = new Set(row.dutySheets.map((dutySheet) => dutySheet.caregiverId));

    this.selectedCaregivers = [...this.selectedCaregiverIds]
      .map((caregiverId) => this.DatabaseApi.caregivers()[caregiverId as unknown as string])
      .filter((caregiver) => caregiver !== undefined);

    this.tableRows = this.mapDataToTableRows();
  }

  private getTableColumns() {
    return this.dateUtils.getLocalDatesInRange(this.dateRange).reduce((acc, day) => {
      const formattedDay = this.$filter("capitalize")(day.dayOfWeek().name()).substring(0, 2);
      const formattedDate = day.format(DateTimeFormatter.ofPattern("dd"));

      acc.push({
        dayOfWeek: day.dayOfWeek(),
        title: day.equals(LocalDate.now()) ? "Today" : `${formattedDay} ${formattedDate}`,
        date: day.toString(),
      });

      return acc;
    }, [] as TableColumn[]);
  }
}

export const patientDutySheetComponent: ComponentOptions = {
  templateUrl: "admin/views/patient-duty-sheet.component.html",
  controller: PatientDutySheetCtrl,
  controllerAs: "ctrl",
  bindings: {
    patientId: "<",
    patientCurrentOfficeId: "<",
  },
};
