import moment from "moment";

//! @ngInject
export function unstaffedModalCtrl($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, visit, Socket, SweetAlert, $timeout, $uibModal, Analytics){

    window.scrollTo(0,0);

    console.log(visit);
    $scope.showCancelDiv = false;
    var isOkToCloseModal  = false;
    //$uibModalInstance.close();


    $scope.visit = visit;


    $scope.$on('modal.closing', function (e) {
        if(!isOkToCloseModal) e.preventDefault();
    });

    function closeModal(){
        isOkToCloseModal = true;
        $uibModalInstance.close();
    }

    $scope.reasonRadio = {
        val: '',
        search: ''
    };


    $scope.getNewStartDate = function(){

        console.log(visit.startTime);
        var start = moment(visit.startTime);

        start.add(1,'d');

        return start.format('MM/DD/YYYY');


    };

    $scope.goToVisit = function(id){
        $state.go('app.single-visit', {id: id});
        closeModal();
    };

    $scope.closeModal = function () {
        closeModal();
    };
    
    $scope.reBroadcast = function(v, id){

        $scope.disable = true;

        console.log(visit.startTime);
        var start = moment(visit.startTime);
        start.add(1,'d');
        v.startTime = start.format('YYYY-MM-DD[T]HH:mm'); // 2018-03-13T13:00

        var end = moment(visit.endTime);
        end.add(1,'d');
        v.endTime = end.format('YYYY-MM-DD[T]HH:mm'); // 2018-03-13T13:00


        var body = {
            startTime: v.startTime,
            timezone: v.timezone,
            id: v.id
        };

        if (v.isSingleVisit){
            body.endTime = v.endTime;
        }

        DatabaseApi.patch('agencies/' + $rootScope.agencyId + '/visits/' + v.id, body).then(function (res) {


            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/visits/' + v.id).then(function(){


                Analytics.event('unstaffed-modal-rebroadcast', {
                    visitId: v.id,
                    status: v.status,
                    assigned: !!v.assignedCaregiver,
                    accepted: v.acceptedBy.length
                });

                toaster.pop('success', "Success", 'Visit has been broadcasted');
                closeModal();

            }, function(){
                toaster.pop('error', "Something went wrong", 'please try again');
                $scope.disable = false;

            });



        }, function (err) {
            $scope.disable = true;

        });



    };

    $scope.checkCancelReason = function(reset){
        console.log($scope.reasonRadio.val);
        $scope.reasonRadio.search = '';
        if(reset){
            $scope.showCancelDiv = false;
            $scope.reasonRadio.val = '';
            $scope.reasonRadio.other = '';
            $scope.reasons = [
                {
                    text: 'Assigned over the phone (using Medflyt)',
                    pick: true
                },
                {
                    text: 'Assigned over the agency chat',
                    pick: true
                },
                {
                    text: 'Assigned over the visit chat',
                    pick: true
                },
                {
                    text: 'Wasn\'t assigned by Medflyt, was assigned outside of Medflyt',
                    external: true
                },
                {
                    text: 'Other',
                    other: true
                }
            ];

        }
    };
    $scope.checkCancelReason('reset');

    $scope.preDeleteVisit = function(){
        $scope.showCancelDiv = true;
    };

    $scope.deleteVisit = function(visit, text){

        var url = 'agencies/' + $rootScope.agencyId + '/visits/' + visit.id  + '/cancel';

        var body = {reason: text ? 'Other - ' + text : 'Wasn\'t assigned by Medflyt'};
        DatabaseApi.put(url, body).then(function(res){
            visit.assignedCaregiver = undefined;
            toaster.pop('success', "Success", 'Visit was canceled');

            Analytics.event('unstaffed-modal-canceled', {
                visitId: visit.id,
                status: 'unstaffed',
                reason: text ? 'Other - ' + text : 'Wasn\'t assigned by Medflyt'
            });
            closeModal();

        }, function(err){
            toaster.pop('error', "Something went wrong", err.data);
        });

    };


};
