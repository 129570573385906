import { ComplianceQuestion } from "../../../../scripts/messages/compliance";
import { formatDisplayComplianceQuestionAnswer } from "../../compliance.utils";
import "./compliance-reviewers-answers.component.scss";

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "ComplianceReviewersAnswers";

  reviewersAnswers!: ComplianceQuestion[][];

  formatDisplayReviewAnswer = (question: ComplianceQuestion) =>
    formatDisplayComplianceQuestionAnswer(question);
}

export const ComplianceReviewersAnswersComponent: ComponentOptions = {
  $name: "complianceReviewersAnswers",
  templateUrl:
    "admin/modules/compliance/components/compliance-reviewers-answers/compliance-reviewers-answers.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {
    reviewersAnswers: "<",
  },
};
