//! @ngInject
export function trainingCenterBundleItemSync($rootScope, $scope, $uibModalInstance, DatabaseApi, toaster) {
    $scope.settings = [
      { id: "includeBundleItemTitleName", label: "Title name" },
      { id: "includeBundleItemCourseName", label: "Course name" },
      { id: "includeBundleItemLabel", label: "Label" },
      { id: "includeBundleItemDescription", label: "Description" },
      { id: "includeBundleItemTestSuccessMessage", label: "Test success message" },
      { id: "includeBundleItemTestFailMessage", label: "Test fail message" },
      { id: "includeBundleItemTestRetryMessage", label: "Test retry message" },
      { id: "includeBundleItemFullDescription", label: "Full description" },
      {
        id: "includeBundleItemTestQuestionsAndAllAnswers",
        label: "Test questions and all answers",
      },
      { id: "includeBundleItemEngagementQuestions", label: "Engagement questions" },
      { id: "includeBundleItemVideoUrls", label: "Video urls" },
    ];

    $scope.form = (() => {
        return $scope.settings.reduce((acc, curr) => {
            acc[curr.id] = true;
            return acc;
        }, {});
    })();

    $scope.onClickSetting = (id) => {
      $scope.form[id] = !$scope.form[id];
    };

    $scope.sync = () => {
      $scope.isLoading = true;

      const url =
        "agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/bundle_items/:trainingCenterBundleItemId/sync"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":trainingCenterBundleId", $scope.$resolve.bundleId)
          .replace(":trainingCenterBundleItemId", $scope.$resolve.bundleItemId);

      const body = {
        criterias: $scope.form,
      };

      $scope.isLoading = true;

      return DatabaseApi.post(url, body)
        .then(() => {
          toaster.pop("success", "Sync was successful");
          $uibModalInstance.close();
        })
        .catch((e) => {
          if (e.status === 403) {
            toaster.pop("error", "You are not authorized to do this action");
            return;
          }

          console.error(e);

          toaster.pop("error", "Something went wrong");
        })
        .finally(() => ($scope.isLoading = false));
    };
  };
