import moment from "moment";

export const dateRangeDefaultOptions = {
    ranges: {
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 14 Days": [moment().subtract(13, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "Last Year": [
        moment().subtract(1, "year").startOf("year"),
        moment().subtract(1, "year").endOf("year"),
      ],
      "This Year": [moment().startOf("year"), moment().endOf("year")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
    },
    alwaysShowCalendars: true,
    applyClass: "btn-primary",
    locale: {
      direction: "ltr date-range-picker-v2",
      format: "D MMM YY",
    },
    opens: "left",
    autoApply: true,
    minDate: new Date("2001-01-01"),
    eventHandlers: {
    //   "apply.daterangepicker": () => this.loadTable(),
    },
  };
  
  export type DateRangeDefaultOptions = typeof dateRangeDefaultOptions;

  
  