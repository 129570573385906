//'use strict';
import jwt_decode from "jwt-decode";

/* @ngInject */
export function authInterceptor($q, $rootScope, Consts, Storage, $injector) {
  function setAuthHeader(config) {
    config.headers = config.headers || {};
    if (
      (config.url.search(Consts.api) > -1 || config.url.search(Consts.localServer) > -1) &&
      config.url.indexOf("auth/token") === -1
    ) {
      config.headers.Authorization = "Token " + $rootScope.token || undefined;
    } else {
      if (config && config.headers) config.headers.Authorization = undefined;
    }

    if (config.url.search("amazonaws") > -1) {
      if (config && config.headers) delete config.headers.Authorization;
    }

    return config;
  }

  function runAuthInterceptorLogic(config) {
    const deferred = $q.defer();
    const loginDataStorageService = $injector.get("loginDataStorageService");
    if ($rootScope.loginType === "JWT") {
      if (config.url.indexOf("auth/token") === -1 && config.url.indexOf("auth/new_login") === -1) {
        let refreshToken;
        try {
          refreshToken = jwt_decode($rootScope.refreshToken);
        } catch (err) {
          console.log("No refresh token");
          $rootScope.logout();
          return;
        }

        if (refreshToken === undefined || Date.now() >= refreshToken.exp * 1000) {
          $rootScope.logout();
          return;
        }

        const currentAccessToken = jwt_decode($rootScope.token);
        const timeBuffer = 5 * 1000;
        if (Date.now() >= currentAccessToken.exp * 1000 - timeBuffer) {
          $rootScope.refreshTokenPromise = new Promise((resolve, reject) => {
            fetch(Consts.api + "auth/token", {
              method: "POST",
              headers: {
                ...(config.headers || {}),
                "X-MedFlyt-grant-type": $rootScope.refreshToken,
              },
              body: undefined,
            })
              .then((res) => res.json())
              .then((data) => {
                const userObject = Storage.getObject("user");
                loginDataStorageService.setLoginDataFromAccessToken(
                  data.accessJWT,
                  data.refreshJWT,
                  userObject.permissions
                );
                deferred.resolve(setAuthHeader(config));
                resolve(setAuthHeader(config));
              })
              .catch((err) => {
                console.log(err);
                console.log("No refresh token");
                reject(err);
                $rootScope.logout();
              });
          });

          return deferred.promise;
        }
      }
    }

    deferred.resolve(setAuthHeader(config));
    return deferred.promise;
  }
  return {
    request: function (config) {
      if ($rootScope.refreshTokenPromise) {
        return $rootScope.refreshTokenPromise.then(() => {
          return runAuthInterceptorLogic(config);
        });
      } else {
        return runAuthInterceptorLogic(config);
      }
    },

    responseError: function (rejection) {
      return $q.reject(rejection);
    },
  };
}
