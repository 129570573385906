export const noteCommentsComponent = {
  templateUrl: "admin/views/note-comments-component.html",
  bindings: {
    noteDetails: "<",
    onCommentsChange: "&",
  },
  //! @ngInject
  controller: function (
    $rootScope,
    toaster,
    DatabaseApi,
    SweetAlert,
    noteService
  ) {
    this.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();
    this.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
    this.newComment = {};
    this.isAdmin = $rootScope.user.admin;

    this.addComment = () => {
      noteService
        .createComment(
          this.noteDetails.id,
          this.newComment,
          $rootScope.agencyId,
          $rootScope.agencyMemberId
        )
        .then((res) => {
          toaster.pop("success", "Comment added successfully");
          this.newComment.text = '';
          this.onCommentsChange();
        })
        .catch((err) => {
          toaster.pop("warning", "Something went wrong");
        });
    };

    this.deleteComment = (noteId, commentId) => {
      SweetAlert.swal(
        {
          title: "Delete comment",
          text: "Are you sure you want to delete this comment? ",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3077EB",
          confirmButtonText: "Yes, delete",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        (isConfirm) => {
          if (isConfirm) {
            noteService
              .deleteComment(
                noteId,
                commentId,
                $rootScope.agencyId,
                $rootScope.agencyMemberId
              )
              .then((res) => {
                toaster.pop("success", "Comment deleted successfully");
                this.onCommentsChange();
              })
              .catch((err) => {
                toaster.pop("warning", "Something went wrong");
              });
          }
        }
      );
    };
  },
};
