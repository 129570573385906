export const patientBilling = {
    templateUrl: "admin/views/patient-billing.html",
    bindings: {
        patientId: '<'
    },
    //! @ngInject
    controller: function ($scope, $rootScope, DatabaseApi, NgTableParams, toaster, $q, $timeout, $uibModal) {
        $scope.caregviersMap = DatabaseApi.caregivers() || {};
        $scope.officesMap = DatabaseApi.offices() || {};
        $scope.serviceCodesMap = DatabaseApi.serviceCodes() || {};
        
        const getPatientInvoices = async () => {
            const deferred = $q.defer();
            $scope.isLoading = true;

            DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${$scope.$ctrl.patientId}/invoices`)
            .then((res) => {
                deferred.resolve(res.data.invoices);
            }).catch((err) => {
                toaster.pop("error", "Failed to load billing data");
                console.log(err);
                deferred.reject(null);
            })
            .finally(() => {
                $timeout(() => $scope.isLoading = false);
            });

            return deferred.promise;
        }


        const initInvoicesTable = (invoices) => {
            const invoiceVisitTableOptions = {
                count: 25,
                sorting: { date: "desc" }
            };

            invoices.forEach((invoice) => {
                invoice.activeInvoiceTab = null;
                const office = $scope.officesMap.find(({ id }) => invoice.officeId === id);
                invoice.officeName = office.name;    
  
                invoice.invoiceVisitsTable = new NgTableParams(invoiceVisitTableOptions, {
                    counts: [],
                    dataset: invoice.currentInvoiceVisitDetails
                });

                invoice.currentInvoiceVisitDetails.forEach((visit) => {
                    const caregiver = $scope.caregviersMap[visit.caregiverId];
                    visit.caregiver = caregiver;

                    const serviceCode = $scope.serviceCodesMap.find(({ id }) => visit.serviceCodeId === id);
                    visit.serviceCodeName = serviceCode ? serviceCode.code : "No service code";            
                })
            });


            const invoicesTableOptions = {
                count: 10,
                sorting: { date: "desc" }
            };

            $scope.invoicesTable = new NgTableParams(invoicesTableOptions, {
                counts: [],
                dataset: invoices
            });
        }

        

        async function loadData() {
            const invoices = await getPatientInvoices();
            if (invoices !== null) {
                $scope.invoices = invoices;
                initInvoicesTable(invoices);
            }
        }

        $scope.toggleRow = (row) => {
            if (row.activeInvoiceTab === null) {
                row.activeInvoiceTab = true;
            } else {
                row.activeInvoiceTab = null;
            }
        };

        $scope.viewExportHistory = ($event, visit) => {
            $event.stopPropagation();
    
            $uibModal
                .open({
                    templateUrl: "admin/views/billing-exported-invoices-modal.html",
                    size: "md",
                    controller: "billingExportedInvoicesModalCtrl",
                    windowClass: "billing-exported-invoices-modal-ctrl center-center",
                    resolve: {
                        visit
                    }
                });
        };

    //     $scope.preOpenPatientModal = function (patientId) {
    //         if (!patientId) return;
    //         $rootScope.openPatientModal(patientId);
    //     }

    //     $scope.handlePayrollItemClick = (item) => {
    //         switch(item.type) {
    //             case 'VISIT': {
    //                 $rootScope.openVisitInstanceModal(item.visitInstanceId)
    //                 break;
    //             }
    //             default: return;
    //         }
    //     }
    
    //     $rootScope.$on("got_patients_data", function () {
    //         if ($scope.gotPatientData) return;
    //         $scope.gotPatientData = true
    //         $scope.patientsMap = DatabaseApi.patients();
    //     });

    //     $scope.secondsToLabel = function (seconds) {
    //         if (!seconds && seconds !== 0) return '';
    //         const duration = moment.duration(Math.abs(seconds), 'seconds');
    //         const hours = Math.floor(duration.asHours());
    //         if (hours > 0) duration.subtract(hours, "hours");
    //         let minutes = Math.floor(duration.asMinutes());
    //         if (minutes < 10) minutes = '0' + minutes;
    //         const sign = Math.sign(seconds) === -1 ? '-' : '';
    //         return `${sign}${hours}:${minutes} hr`;
    //     }

        this.$onInit = async () => {
            await loadData();
        }
    }
};