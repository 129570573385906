//! @ngInject
export function visitAdjustmentApprovalTable(
    DatabaseApi,
    $rootScope,
    toaster,
    NgTableParams,adjustmentApprovalsConsts
    
  ) {
    return {
      restrict: "E",
      templateUrl: "admin/views/visit-adjustment-approvals-view.html",
      scope: {
        visitInstance: "=",
      },
      link: function (scope) {
        scope.caregiversMap = DatabaseApi.caregivers();
        scope.patientsMap = DatabaseApi.patients();
        scope.contracts = DatabaseApi.contractTypes();
        scope.offices = DatabaseApi.offices();
        scope.agencyMembers = DatabaseApi.getAgencyMembers();
        
        scope.filterBy = {
          approvalStatus: "PENDING",
          changes: [],
          requirePerfectMatch: false,
        };
        scope.statuses =adjustmentApprovalsConsts.statuses;
        scope.diffsMap = adjustmentApprovalsConsts.diffsMap;
        scope.diffsOptions =adjustmentApprovalsConsts.diffsOptions;
        scope.diffsExtraSettings =adjustmentApprovalsConsts.diffsExtraSettings;        

        function initialize() {
          


          // Load filters
    
          scope.loadItems()
        }



        scope.loadItems = () => {
        
          
              scope.allAdjustments = scope.visitInstance.adjustments;
              mapItems(scope.allAdjustments);
              filterTable();
          
        };

        const mapItems = (items) => {
          items.forEach((item) => {
            if (item.approvalStatus === null) {
              item.approvalStatus = "PENDING";
            }
            if (item.caregiverId) {
              item.caregiver = scope.caregiversMap[item.caregiverId];
            }
            if (item.patientId) {
              item.patient = scope.patientsMap[item.patientId];
            }
            const contract = scope.contracts.find(
              (c) => c.id === item.contractTypeId
            );
            if (contract) {
              item.contractName = contract.name;
            }
            const office = scope.offices.find((o) => o.id === item.officeId);
            if (office) {
              item.officeName = office.name;
            }
            item.diffsValues = [];
            item.diffsTexts = [];
            Object.entries(item.diffs).forEach(([key, val]) => {
              if (val && scope.diffsMap[key]) {
                item.diffsValues.push(key);
                item.diffsTexts.push(scope.diffsMap[key]);
              }
            });
          });
        };


        scope.filterByApprovalStatus = (approvalStatus) => {
          if (scope.filterBy.approvalStatus !== approvalStatus) {
            scope.filterBy.approvalStatus = approvalStatus;
          }
        };

        const filterTable = () => {
          if (!scope.allAdjustments) return;

          const selectedChangesIds = scope.filterBy.changes.map((d) => d.id);
          const changeMap = new Map();

          for (const opt of scope.diffsOptions) {
            changeMap.set(opt.value, selectedChangesIds.includes(opt.id));
          }
          const selectedChanges = scope.diffsOptions
            .filter((opt) => selectedChangesIds.includes(opt.id))
            .map((opt) => opt.value);

          let items = [...scope.allAdjustments].filter((item) => {
            const isStatusMatch = scope.filterBy.approvalStatus
              ? item.approvalStatus === scope.filterBy.approvalStatus
              : true;

            // I don't know if this is the best way but it's simple enough?
            const isDifferencesMatch = scope.filterBy.requirePerfectMatch
              ? scope.diffsOptions.every(
                  (opt) => item.diffs[opt.value] === changeMap.get(opt.value)
                )
              : selectedChangesIds.length === 0
              ? true
              : scope.diffsOptions.some(
                  (opt) => item.diffs[opt.value] && changeMap.get(opt.value)
                );

            return isStatusMatch && isDifferencesMatch;
          });

          initTable(items);
        };

        const initTable = (items) => {
          const oldCount = scope.tableParams?.total?.() || 0;
          
          if (!items) {
            toaster.pop("error", "Oops... Something went wrong");
            return;
          }
          let page = false;
          let sorting = {};
          let count = 25;
          if (scope.tableParams) {
            page = scope.tableParams.page();
            sorting = scope.tableParams.sorting();
            count = scope.tableParams.count();
          }
          const options = {
            sorting: sorting,
            count: count,
          };
          scope.tableParams = new NgTableParams(options, {
            dataset: items,
          });

          if (page && oldCount === scope.tableParams.total())
            scope.tableParams.page(page);
        };


        scope.goToInvoicePage = (invoiceId) => {
          $rootScope.openInvoiceModal({ invoiceId });
        };

        initialize();
      },
    };
  };
