import _ from "lodash";

//! @ngInject
export function ediComparisonCtrl($scope, NgTableParams, toaster, DatabaseApi, $rootScope) {
    $scope.tableParams = null;
    $scope.isLoading = false;
    $scope.onlyMismtaches = false;
    var originalData;

    $scope.globalFilter = { val: '' };
    var initTable = function (items) {
        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
        $scope.isLoading = false;
    };

    $scope.visitStatusClass = function (row) {
        var result = '';
        if (row.edi1 === undefined || row.edi2 === undefined) {
            result = "edi-missing-visit";
        } else if (!row.areEqual) {
            result = "edi-mismatched-visit";
        }

        return result;
    }

    $scope.compareEdis = function () {
        $scope.isLoading = true;
        DatabaseApi.post("agencies/" + $rootScope.agencyId + "/compare_edi", { edi1: $scope.edi1Text, edi2: $scope.edi2Text }).then(function (response) {
            const mappedData = $scope.mapCompareResponse(response.data.compare);
            initTable(mappedData);
            originalData = mappedData;
            $scope.generalCompariosn = {
                edi1: response.data.edi1,
                edi2: response.data.edi2,
                type: response.data.edi1.type === response.data.edi2.type,
                ein: response.data.edi1.ein === response.data.edi2.ein,
                npi: response.data.edi1.npi === response.data.edi2.npi
            }
            $scope.loadedOnce = true;
        }, function (error) {
            $scope.isLoading = false;
            toaster.pop("error", "Failed to load EDI comparison");
        });
    };

    $scope.filterEdi = function (term) {
        var filter = { $: term };
        if ($scope.onlyMismtaches) {
            filter.areEqual = false;
        } else {
            delete filter.areEqual;
        }
        if ($scope.tableParams) {
            initTable(originalData);
            angular.extend($scope.tableParams.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    $scope.claimMap = {
        cl1: 'CL1',
        dtp434RangeType: 'DTP 434',
        dtp435RangeType: 'DTP 435',
        dxCode: 'Diagnosis Code',
        hi: 'HI',
        nm1_71: 'NM1 71',
        refF8: 'REF F8',
        refG1: 'REF G1'
    }

    $scope.mapCompareResponse = function (records) {
        return records.map(record => {
            const mismatches = Object.keys(_.pickBy(record.claimEquality, x => x === false)).map(v => $scope.claimMap[v]).join(', ');
            return {
                ...record,
                unequalClaimsText: mismatches !== undefined ? 'Mismatch ' + mismatches : null,
                areEqual: record.fieldsAreEqual && record.claimsAreEqual
            }
        })
    }

    $scope.clickOnlyMismtaches = function () {
        $scope.onlyMismtaches = !$scope.onlyMismtaches;
        $scope.filterEdi($scope.tableParams.filter().$);
        return $scope.onlyMismtaches
    }
};