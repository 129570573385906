import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function caregiverRegenerateCertificateModalCtrl($scope, $rootScope, $timeout, DatabaseApi) {
      const caregiverId = $scope.$resolve.caregiverId;
      const dueDate = $scope.$resolve.dueDate;
      const bundle = $scope.$resolve.bundle;
      const shouldForce = $scope.$resolve.shouldForce;
      const onSuccess = $scope.$resolve.onSuccess;
      const onFail = $scope.$resolve.onFail;
      const NAME = "NAME";
      const SIGNATURE_PERSON = "SIGNATURE_PERSON";
      const DATE = "DATE";
      let revealForceToggleCounter = shouldForce ? 7 : 0;

      const dateOptions = {
        minDate: moment("2017-01-01", "YYYY-MM-dd"),
        maxDate: moment("").add(1, "week").startOf("isoWeek"), // use 'week' instead of 'isoWeek' to get Sunday.
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
      };

      const reasons = [
        { id: NAME, selected: true, label: "Wrong caregiver name" },
        {
          id: SIGNATURE_PERSON,
          selected: false,
          label: "Change signed agency member",
        },
        { id: DATE, selected: false, label: "Fix certification date" },
      ];

      const signaturePersonRole =
        bundle && bundle.bundleParams
          ? bundle.bundleParams.signingAgencyMemberTitle
          : undefined;

      const certificateDate = new Date(
        dueDate.completionCertificate
          ? dueDate.completionCertificate.createdAt
          : dueDate.completionDate
      );
      const timezoneOffset = certificateDate.getTimezoneOffset() * 60000;
      const certificateDateWithTimezoneFixed = new Date(
        certificateDate.getTime() + timezoneOffset
      );

      const form = {
        fields: {
          reasons: [NAME],
          signaturePerson: undefined,
          signaturePersonRole,
          dateOfCertificate: certificateDateWithTimezoneFixed,
          force: shouldForce,
        },
        valid: false,
        loading: false,
      };

      function submit() {
        form.loading = true;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/regenerate_certificates`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", caregiverId);

        const data = prepareData(form.fields, dueDate.bundleDueDateId);

        return DatabaseApi.post(url, data)
          .then(({ data }) => onSuccess(bundle.title, dueDate.year, data.fileUrl))
          .catch((e) => onFail(bundle.title, dueDate.year, e.data.error))
          .finally(() => (form.loading = false));
      }

      $scope.$watchCollection("form.fields.reasons", onUpdates, true);

      $scope.$watchGroup(
        ["form.fields.signaturePerson", "form.fields.dateOfCertificate"],
        onUpdates,
        true
      );

      function revealForceToggle() {
        revealForceToggleCounter++;
      }

      function showForceToggle() {
        return revealForceToggleCounter > 6;
      }

      function showFixNameWarning() {
        return findReason(NAME);
      }

      function showFixDateInput() {
        return findReason(DATE);
      }

      function showFixAgencyMemberInput() {
        return findReason(SIGNATURE_PERSON);
      }

      function isDefined(val) {
        return val !== null && val !== undefined;
      }

      function findReason(reason) {
        return form.fields.reasons.includes(reason);
      }

      function toggleReason(reason) {
        reason.selected = !reason.selected;
        if (reason.selected) {
          form.fields.reasons = [
            ...new Set([reason.id, ...form.fields.reasons]),
          ];
        } else {
          form.fields.reasons = form.fields.reasons.filter(
            (id) => id !== reason.id
          );
        }
      }

      function onUpdates() {
        if (form.fields.reasons.length === 0) {
          form.valid = false;
          return;
        }
        form.valid = true;
        if (findReason(SIGNATURE_PERSON)) {
          const isAgencyMemberFilled = form.fields.signaturePerson
            ? isDefined(form.fields.signaturePerson.id)
            : false;

          form.valid = isAgencyMemberFilled;
          if (!form.valid) return;
        }

        if (findReason(DATE)) {
          const isDateFilled = isDefined(form.fields.dateOfCertificate);

          form.valid = isDateFilled;
          if (!isDateFilled) return;
        }
      }

      function prepareData(
        { reasons, signaturePerson, signaturePersonRole, dateOfCertificate, force },
        dueDateId
      ) {
        let data = {
          force,
          reasons,
          dueDateId,
        };

        if (findReason(SIGNATURE_PERSON)) {
          data = {
            ...data,
            signaturePersonId: signaturePerson.id,
            signaturePersonRole,
          };
        }
        if (findReason(DATE)) {
          data = {
            ...data,
            dateOfCertificate: LocalDate.from(nativeJs(dateOfCertificate)),
          };
        }

        return data;
      }

      (function initAgencyMembers() {
        const members = DatabaseApi.getAgencyMembersArr();
        if (!members || !members.length) {
          $timeout(initAgencyMembers, 500);
          return;
        }

        $scope.agencyMemberOptions = members.map(({ id, displayName }) => ({
          id,
          displayName,
        }));

        const signaturePersonId =
          bundle && bundle.bundleParams
            ? bundle.bundleParams.signingAgencyMemberId
            : undefined;
        const member = $scope.agencyMemberOptions.find(
          ({ id }) => id === signaturePersonId
        );

        form.fields.signaturePerson = member;
      })();

      $scope.submit = submit;
      $scope.reasons = reasons;
      $scope.form = form;
      $scope.caregiverId = caregiverId;
      $scope.dueDate = dueDate;
      $scope.bundle = bundle;
      $scope.dateOptions = dateOptions;
      $scope.showFixNameWarning = showFixNameWarning;
      $scope.showFixDateInput = showFixDateInput;
      $scope.showFixAgencyMemberInput = showFixAgencyMemberInput;
      $scope.toggleReason = toggleReason;
      $scope.revealForceToggle = revealForceToggle;
      $scope.showForceToggle = showForceToggle;
    };
