import { Box, Button, Flex, Heading, keyframes, List, ListItem, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, Tooltip} from "@chakra-ui/react";
import React from "react";
import ClockIcon from "../../../shared/icons/ClockIcon";
import { Messages } from "../../../core/api";
import { match } from "ts-pattern";
import { CommCenterMessageId } from "../../../shared/schema/schema";
import CheckDoubleFillIcon from "../../../shared/icons/CheckDoubleFillIcon";
import CheckLineIcon from "../../../shared/icons/CheckLineIcon";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import styled from "@emotion/styled";
import {getCommCenterMessageCreatorName} from "../utils/communication-utils";
import { css } from "@emotion/react";
import CachedSignedImageModal from "../../../shared/components/CachedSignedImageModal";

interface Props {
  message: Messages["CommCenterMessage"];
  displayEntityName: boolean;
}

const ChatMessage = (props: Props) => {
  const sentOrReceived = props.message.createdBy.type === "Caregiver" ? "received" : "sent";
  const entityName = getCommCenterMessageCreatorName(props.message);
  const [firstMessage, ...otherMessages] = props.message.payload;

  const relevantIcon = (() => {
    if (CommCenterMessageId.unwrap(props.message.id) < 0) {
      return (
        <Tooltip label="Sending...">
          <ClockIcon color="gray.500" />
        </Tooltip>
      );
    }

    if (props.message.readAt !== null) {
      return (
        <Tooltip label="Read">
          <CheckDoubleFillIcon color="blue.500" />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Sent">
        <CheckLineIcon color="gray.500" />
      </Tooltip>
    );
  })();

  return (
    <ChatMessage.Root
      data-is-new={CommCenterMessageId.unwrap(props.message.id) < 0}
      type={sentOrReceived}
    >
      <ChatMessage.Bubble type={sentOrReceived}>
        <Flex alignItems="center" gap={1} mb={1} justifyContent="space-between">
          {props.displayEntityName && <Heading size="xs">{entityName}</Heading>}
          <Text fontSize="xs" color="gray.700">
            {dateFormatter.toDateOrDateTime(props.message.createdAt)}
          </Text>
          {sentOrReceived === "sent" && <Box px={1}>{relevantIcon}</Box>}
        </Flex>
        <ChatMessage.Content message={firstMessage} />
      </ChatMessage.Bubble>

      {otherMessages.map((payload, i) => (
        <ChatMessage.Bubble key={i} type={sentOrReceived}>
          <ChatMessage.Content message={payload} />
        </ChatMessage.Bubble>
      ))}
    </ChatMessage.Root>
  );
};

ChatMessage.Content = (props: {
  message: Messages["CommCenterMessage"]["payload"][number];
}) => {
  return (
    match(props.message)
      .with({ type: "TEXT" }, ({ message }) => <Text whiteSpace="break-spaces">{message}</Text>)
      .with({ type: "IMAGE" }, ({ url }) => (
        <CachedSignedImageModal src={url} width={200} height={200} />
      ))
      .with({ type: "ACTION" }, ({ payload }) => {
        return (
          match(payload)
            .with({ payloadType: "TEXT" }, (message) => (
              <Text whiteSpace="break-spaces">{message.text}</Text>
            ))
            .with({ payloadType: "IMAGE" }, (image) => (
              <CachedSignedImageModal src={image.url} width={200} height={200} />
            ))
            .with({ payloadType: "LIST" }, (list) => (
              <ChatMessage.ActionsPopover list={list} />
            ))
            .exhaustive()
        );
      })
      .exhaustive()
  );
};

ChatMessage.ActionsPopover = (props: {
  list: Messages["CommCenterMessagePayloadActionList"];
}) => {
  return (
    <Popover placement="auto-end">
      <PopoverTrigger>
        <Button size="sm" colorScheme="blue" variant="outline" w="full">
          Show options
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <List spacing={5}>
            {props.list.items.map((item) => (
              <ListItem key={item.id}>{item.text}</ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

ChatMessage.Bubble = styled(Box)<{ type: "sent" | "received" }>`
  padding: 12px;
  border-radius: 12px;
  min-width: 120px;
  transition: all 250ms ease;

  ${({ type }) => {
    if (type === "received") {
      return css`
        background-color: var(--chakra-colors-gray-100);
      `;
    }

    return css`
      background-color: var(--chakra-colors-blue-100);
    `;
  }}
`;

const chatMessageIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.95);
    }
`;

ChatMessage.Root = styled(Flex)<{ type: "sent" | "received" }>`
  max-width: 80%;
  flex-direction: column;
  gap: 4px;

  &[data-is-new="true"] {
    animation: ${chatMessageIn} 0.3s ease-in-out backwards;
    opacity: 0.75;
  }

  ${({ type }) => {
    if (type === "received") {
      return css`
        margin-inline-end: auto;
        align-items: flex-start;
      `;
    }

    return css`
      margin-inline-start: auto;
      align-items: flex-end;
    `;
  }}
`;


export default ChatMessage;