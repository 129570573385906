//! @ngInject
export function VisitInstanceModalService($rootScope, DatabaseApi, wildcard, $filter, mfModal) { return {

    updateVisitCaregiverDuty(visitInstanceId, dutyId, notes, completed) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/duty_sheets/answer"
      .replace(":agencyId", agencyId)
      .replace(":agencyMemberId", agencyMemberId)
      .replace(":visitInstanceId", visitInstanceId);

      const body = {
        dutyId: dutyId,
        notes: notes,
        completed: completed
      }

      return DatabaseApi.post(endpoint, body).then((res) => res.data);
    },

    showSkippedVisitsErrorModal({
      skippedVisitsAndMissingPermissions,
      updatedVisitsAmount,
      action = "update",
      overlappingAmount = 0,
      caregiversWithNoValidHireDate = [],
      skippedBroadcastAssignedVisitInstnaces = []
    }) {
      const getVisitsFormattedDates = (visits) => {
        return visits.map((visit, index) => {
          const date = $filter("mfShortTime")(new Date(visit.date), ['withDate']);
          return `${index + 1}. ${date}`;
        }).join("\n");
      };

      const getSuccessMessage = () => {
        const titledcaseAction = $filter("titlecase")(action);
        const successMessage = updatedVisitsAmount > 0 ?
          `${titledcaseAction} finished successfully for ${updatedVisitsAmount} visit${updatedVisitsAmount > 1 ? "s" : ""}` : "";
        return successMessage;
      };

      const getSkippedBroadcastAssignedMessage = () => {
        if (!skippedBroadcastAssignedVisitInstnaces || skippedBroadcastAssignedVisitInstnaces.length === 0) {
          return "";
        }
        const broadcastAssignedDatesPrefix = `The following visits will not be broadcasted because they are already assigned:\n`;
        const broadcastAssignedDates = getVisitsFormattedDates(skippedBroadcastAssignedVisitInstnaces);
        const broadcastAssignedMessage = `${broadcastAssignedDatesPrefix}${broadcastAssignedDates}`;
        return broadcastAssignedMessage;
      };

      const getOverlappingMessage = () => {
        if (!overlappingAmount || overlappingAmount === 0) {
          return "";
        }
        const sentencePart = overlappingAmount > 1 ? "visits were" : "visit was";
        const overlappingMessage = `${overlappingAmount} ${sentencePart} not updated due to overlap with other visits.`;
        return overlappingMessage;
      };

      const getSkippedMissingPermissionsMessage = () => {
        if (!skippedVisitsAndMissingPermissions) {
          return "";
        }
        const permissionsMap = {
          'edit_visit_instance_caregiver_replacement': `Can't replace caregiver for visits with recorded time`,
          'assign_inactive_caregiver_to_visits': `Can't assign an inactive caregiver to a visit`,
          'edit_visit_instance_contract_replacement': `Can't replace contract for billed visits`,
          'edit_visit_instance_general_info': `Missing general visit edit permission`,
          'edit_paid_visit_instance_info': `Can't edit paid visits`,
          'edit_billed_visit_instance_info': `Can't edit billed visits`,
          "edit_visit_instance_on_payroll_draft": `Can't edit visit on payroll draft`
        };
        skippedVisitsAndMissingPermissions.visits.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        const sentencePart = skippedVisitsAndMissingPermissions.visits.length > 1 ? "visits were" : "visits was";
        const permissionsOverview = `${skippedVisitsAndMissingPermissions.visits.length} ${sentencePart} not updated due to lack of permissions:\n\n`;
        const permissionsDatesDetailsPrefix = `You are not permitted to ${action} the visits on the next dates:\n`;
        const permissionsDatesDetails = getVisitsFormattedDates(skippedVisitsAndMissingPermissions.visits);
        const permissionDetailsPrefix = "\n\nBecause you are missing the next permissions: \n";
        const permissionsDetails = skippedVisitsAndMissingPermissions.permissions.map(p => `* ${permissionsMap[p]}`).join("\n");
        const permissionsMessage = `${permissionsOverview}${permissionsDatesDetailsPrefix}${permissionsDatesDetails}${permissionDetailsPrefix}${permissionsDetails}`;
        return permissionsMessage;
      };

      const getInvalidHireDateMessage = () => {
        if (!caregiversWithNoValidHireDate || caregiversWithNoValidHireDate.length === 0) {
          return "";
        }
        const invalidHireDateMessage = `These caregivers can't be assigned to visits due to hire date not matching visit dates: ${caregiversWithNoValidHireDate.join(", ")}`;
        return invalidHireDateMessage;
      };

      const successMessage = getSuccessMessage();
      const broadcastAssignedMessage = getSkippedBroadcastAssignedMessage();
      const overlappingMessage = getOverlappingMessage();
      const permissionsMessage = getSkippedMissingPermissionsMessage();
      const invalidHireDateMessage = getInvalidHireDateMessage();

      const messages = [
        successMessage,
        broadcastAssignedMessage,
        overlappingMessage,
        permissionsMessage,
        invalidHireDateMessage
      ].filter(message => message && message !== "");

      const subject = `Failed to ${action} some visits`;
      const message = `${messages.join(`\n\n`)}`;

      const modal = mfModal.create({
        variant: updatedVisitsAmount && updatedVisitsAmount > 0 ? "warning" : "danger",
        subject: subject,
        message: message,
        layoutOrder: ["message"],
        confirmLabel: "Ok",
        hideCancelButton: true,
        onComplete: () => modal.close()
      });
    }
  }};
