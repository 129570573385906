"use strict";

export const historyTimelineChange = {
  templateUrl: "admin/views/history-timeline-change.html",
  bindings: {
    change: "<",
    oldOrNew: "@"
  },
  //! @ngInject
  controller: function ($scope, $element, $attrs) {
    var ctrl = this;
    ctrl.isVisible = function(){
      return ctrl.change.old !== ctrl.change.new && ctrl.change[ctrl.oldOrNew] !== undefined && ctrl.change[ctrl.oldOrNew] !== null;
    }

    ctrl.fromOrToOrColon = () => {
        if (ctrl.change['old'] && ctrl.change['new']) {
            return ctrl.oldOrNew === 'old' ? 'from' : 'to';
        }

        if (ctrl.change['old'] && !ctrl.change['new']) {
            return ctrl.oldOrNew === 'old' ? ': ' : "";
        }

        if (!ctrl.change['old'] && ctrl.change['new']) {
            return ctrl.oldOrNew === 'new' ? ': ' : "";
        }

        if (!ctrl.change['old'] && !ctrl.change['new']) {
            return ctrl.oldOrNew === 'new' ? ': ' : "";
        }
    };
  }
};
