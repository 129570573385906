//! @ngInject
export function timePeriodDescriptionModalCtrl($scope, $uibModalInstance) {
    $scope.timePeriodsDescription = [{
        title: "THIS DAY",
        description: `Starts at 12:00:00 AM on the current day and continues for 24 hours.`
    }, {
        title: "THIS WEEK",
        description: `Starts at 12:00:00 AM on the first day of the current week and continues for seven days.`
    }, {
        title: "THIS MONTH",
        description: `Starts at 12:00:00 AM on the first day of the current month and continues for all the days of that month.`
    }, {
        title: "THIS YEAR",
        description: `Starts at 12:00:00 AM on January 1 of the current year and continues through the end of December 31 of the current year.`
    }, {
        title: "LAST DAY",
        description: `Starts at 12:00:00 AM on the day before the current day and continues for 24 hours.`
    }, {
        title: "LAST WEEK",
        description: `Starts at 12:00:00 AM on the first day of the week before the current week and continues for seven days.`
    }, {
        title: "LAST MONTH",
        description: `Starts at 12:00:00 AM on the first day of the month before the current month and continues for all the days of that month.`
    }, {
        title: "LAST YEAR",
        description: `Starts at 12:00:00 AM on January 1 of the year before the current year and continues through the end of December 31 of that year.`
    }, {
        title: "NEXT DAY",
        description: `Starts at 12:00:00 AM. on the day after the current day and continues for 24 hours.`
    }, {
        title: "NEXT WEEK",
        description: `Starts at 12:00:00 AM on the first day of the current week and continues for seven days.`
    }, {
        title: "NEXT MONTH",
        description: `Starts at 12:00:00 AM on the first day of the month after the current month and continues for all the days of that month.`
    }, {
        title: "NEXT YEAR",
        description: `Starts at 12:00:00 AM on January 1 of the year after the current year and continues through the end of December 31 of that year.`
    }, {
        title: "LAST N DAYS",
        description: `Starts at 12:00:00 AM n days before the current day and continues up to 11:59 PM on the day before the report runs.`
    },{
        title: "LAST N WEEKS",
        description: `Starts at 12:00:00 AM on the first day of the week that started n weeks before the current week, and continues up to 11:59 PM on the last day of the week before the current week.`
    },{
        title: "LAST N MONTHS",
        description: `Starts at 12:00:00 AM on the first day of the month that started n months before the current month and continues up to 11:59 PM on the last day of the month before the current month.`
    },{
        title: "LAST N YEARS",
        description: `Starts at 12:00:00 am on January 1, n+1 years ago. The range ends on December 31 of the year before the current year.`
    },{
        title: "NEXT N DAYS",
        description: `Starts at 12:00:00 AM on the day after the report is run and continues for n days.`
    },{
        title: "NEXT N WEEKS",
        description: `Starts at 12:00:00 AM on the first day of the week after the current week and continues for n times seven days.`
    },{
        title: "NEXT N MONTHS",
        description: `Starts at 12:00:00 AM on the first day of the month after the current month and continues until the end of the nth month.`
    },{
        title: "NEXT N YEARS",
        description: `Starts at 12:00:00 AM on January 1 of the year after the current year and continues through the end of December 31 of the nth year.`
    }];

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };
};
