export type MultiselectCallbackParameter<TType> = { id: TType }[];

export interface MultiselectModel {
  id: unknown;
  label: string;
}

class MultiselectCtrl implements ng.IComponentController {
  // ---- bindings ----- //
  options!: [];
  onSelect?: () => (item: MultiselectModel[]) => void;
  selectedModel?: MultiselectModel[];
  settings?: Record<string, unknown> = {};
  // ------------------- //

  events = {};

  extraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
  };

  $onInit = () => {
    if (this.selectedModel === undefined) {
      this.selectedModel = [];
    }

    this.extraSettings = {
      ...this.extraSettings,
      ...this.settings,
    };
    
    this.events = {
      onSelectionChanged: () => {
        if (this.onSelect !== undefined) {
          this.onSelect()(this.selectedModel!);
        }
      },
    };
  };
}

export const multiselectComponent = {
  $name: "multiselect",
  templateUrl: "admin/modules/shared/components/multiselect/multiselect.component.html",
  controller: MultiselectCtrl,
  controllerAs: "ctrl",
  bindings: {
    options: "<",
    onSelect: "&?",
    selectedModel: "=?",
    settings: "<?",
  },
};
