import { LocalDate, nativeJs } from "js-joda";

//! @ngInject
export function caregiverPayRateModalCtrl(DatabaseApi, $rootScope, toaster, $scope, $uibModalInstance, SweetAlert, dateUtils) {
    $scope.patientsMap = DatabaseApi.patients();
    $scope.patientId = null;
    $scope.isEditMode = $scope.$resolve.caregiverPayRateInitValues !== undefined;
    $scope.handlePatientSelection = (patient) => {
        $scope.patientId = patient.id;
    };
    $scope.handlePatientDeselect = () => {
        $scope.patientId = null;
    };
    $scope.payrollCodes = DatabaseApi.payrollCodes()
        .filter(code =>
            !code.nonPayable
            && code.isActive
            && $scope.$resolve.certifications
            && $scope.$resolve.certifications.indexOf(code.certification) !== -1
        )
        .map(code => ({ id: code.id, label: code.displayId }));
    $scope.rate = { type: "FIXED_AMOUNT", payCode: {} };

    $scope.endDatePickerOptions = {
        minDate: $scope.rate.startDate,
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    const addNewPayRate = () => {
        const body = {
            type: $scope.rate.type,
            hourly: $scope.rate.type === 'PERCENTAGE' ? $scope.rate.hourly / 100 : Number(($scope.rate.hourly * 100).toFixed()),
            daily: $scope.rate.type === 'PERCENTAGE' ? $scope.rate.daily / 100 : Number(($scope.rate.daily * 100).toFixed()),
            startDate: LocalDate.from(nativeJs($scope.rate.startDate)),
            endDate: LocalDate.from(nativeJs($scope.rate.endDate)),
            payCode: $scope.rate.payCode.id
        }
        body.patientId = $scope.patientId ? $scope.patientId : null;
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/caregivers/' + $scope.$resolve.caregiverId + '/pay_rates', body).then(function (res) {
            toaster.pop('success', "Caregiver pay rate updated successfully");
            $uibModalInstance.close('success');
        }, function () {
            toaster.pop('error', "Something went wrong", "Could not save caregiver pay rate");
        })
    } 

    $scope.save = function () {
        if ($scope.rate.startDate && $scope.rate.endDate && ($scope.rate.hourly > 0 || $scope.rate.daily > 0)) {
            if ($scope.rate.daily === 0) {
                SweetAlert.swal(
                    {
                        title: `Daily rate is zero`,
                        type: "info",
                        text: `Are you sure you want to save daily rate as zero?`,
                        showCancelButton: true,
                        confirmButtonText: `Save as ${($scope.rate.hourly * 13).toFixed(2)} (hourly X 13)`,
                        cancelButtonText: "Save as 0"
                    },
                    hasConfirmed => {
                        if (hasConfirmed) {
                            $scope.rate.daily = ($scope.rate.hourly * 13).toFixed(2);
                        }
    
                        addNewPayRate();
                    }
                );
            } else {
                addNewPayRate();
            }
        } else {
            toaster.pop('error', "Something went wrong", "Dates and rates must be filled");
        }
    }

    

    $scope.payCodesMultiselectSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true,
        selectionLimit: 1,
        showUncheckAll: false,
        showCheckAll: false
    };

    $scope.cancelModal = function () {
        $uibModalInstance.dismiss('dismiss');
    }

    $scope.updateFromOptions = function () {
        $scope.endDatePickerOptions = { minDate: $scope.rate.startDate };
    }

    const initValues = () => {
        if ($scope.isEditMode) {
            $scope.caregiverPayRateInitValues = $scope.$resolve.caregiverPayRateInitValues;
            $scope.rate.type = $scope.caregiverPayRateInitValues.type;
            $scope.rate.payCode = { 
                id: $scope.caregiverPayRateInitValues.payCodeId,
                name: $scope.caregiverPayRateInitValues.payCodeName
            };
            $scope.rate.daily = $scope.caregiverPayRateInitValues.type === 'PERCENTAGE' ? $scope.caregiverPayRateInitValues.daily * 100 : Number(($scope.caregiverPayRateInitValues.daily / 100).toFixed(2));
            $scope.rate.hourly = $scope.caregiverPayRateInitValues.type === 'PERCENTAGE' ? $scope.caregiverPayRateInitValues.hourly * 100 : Number(($scope.caregiverPayRateInitValues.hourly / 100).toFixed(2));
            $scope.rate.startDate = dateUtils.ymdStringToDate($scope.caregiverPayRateInitValues.startDate);
            $scope.rate.endDate = dateUtils.ymdStringToDate($scope.caregiverPayRateInitValues.endDate);
            $scope.preSelectedPatient = $scope.patientsMap[$scope.caregiverPayRateInitValues.patientId];
            $scope.patientId = $scope.caregiverPayRateInitValues.patientId;
        }
    }

    initValues();
};