import $ from "jquery";

//! @ngInject
export function trainingCenterClassroomEditCtrl(
  $scope,
  $rootScope,
  $stateParams,
  $timeout,
  NgTableParams,
  TrainingCenterClassroomService,
  officesService,
  toaster,
  DatabaseApi,
  SweetAlert
) {
  $scope.classroomId = $stateParams.classroomId;
  $scope.isLoading = true;
  $scope.isRemoving = undefined;
  $scope.isCompleting = undefined;
  $scope.isEditing = false;

  $rootScope.$on("got_data", function () {
    if (!DatabaseApi.caregivers()) return;
    if ($scope.caregiverOptions && $scope.caregiverOptions.length !== 0) return;

    initCaregivers();
    finishLoading();
  });

  this.$onInit = async function () {
    await initForm();

    setOffices();
    setCertificationOptions();
    setPaycodeOptions();
    initPaycodeCertificationMap();
    initCaregivers();

    finishLoading();
    $scope.$digest();
  };

  $scope.shouldFix = function (field) {
    return $scope.fieldsWithErrors.includes(field);
  };

  $scope.fixedField = function (fixedField) {
    $scope.fieldsWithErrors = $scope.fieldsWithErrors.filter(
      (field) => field !== fixedField
    );
  };

  $scope.addCaregiver = function (caregiver) {
    $scope.isLoading = true;

    const payload = {
      caregiverId: caregiver.id,
      classroomId: $scope.classroomId,
    };
    TrainingCenterClassroomService.addCaregiver(payload)
      .then((caregiver) => {
        $scope.form.caregivers.push({
          ...DatabaseApi.caregivers()[caregiver.caregiverId],
          completed: false,
        });
        setTableData($scope.form.caregivers);

        $scope.caregiverOptions = $scope.caregiverOptions.filter(
          ({ id }) => id !== $scope.form.addedCaregiver.id
        );
        $scope.form.addedCaregiver = undefined;

        toaster.pop("success", "Caregiver was added");
      })
      .catch(() =>
        toaster.pop("error", "Something went wrong", "Can't add caregiver")
      )
      .finally(() => ($scope.isLoading = false));
  };

  $scope.removeCaregiver = function (caregiverId) {
    $scope.isRemoving = caregiverId;

    const payload = {
      caregiverId,
      classroomId: $scope.classroomId,
    };
    TrainingCenterClassroomService.removeCaregiver(payload)
      .catch(() =>
        toaster.pop(
          "error",
          "Can't remove caregiver",
          "Already completed classroom"
        )
      )
      .finally(() => {
        $scope.isRemoving = undefined;
        toaster.pop("success", "Caregiver was removed");
        $scope.caregiverOptions.push(DatabaseApi.caregivers()[caregiverId]);

        $scope.form.caregivers = $scope.form.caregivers.filter(
          ({ id }) => id !== caregiverId
        );
        setTableData($scope.form.caregivers);
      });
  };

  $scope.showOfficeName = function (officeId) {
    const office = $scope.offices.find(({ id }) => id === officeId);
    return office !== undefined ? office.label : "Not found";
  };

  $scope.canShowCompleteAllCaregivers = function () {
    return (
      $scope.form.caregivers &&
      $scope.form.caregivers.filter(({ completed }) => !completed).length > 0
    );
  };

  $scope.completeAllCaregivers = function () {
    SweetAlert.swal(
      {
        title: "Mark all caregivers completed",
        text:
          "Are you sure you want to mark all uncompleted caregivers as completed?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3077EB",
        confirmButtonText: "Mark completed",
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      function (isConfirm) {
        if (!isConfirm) return;

        $scope.isLoading = true;

        TrainingCenterClassroomService.completeAllCaregivers($scope.classroomId)
          .then(() => {
            toaster.pop("success", "All caregivers were marked completed");
            $scope.form.caregivers.forEach(
              (caregiver) => (caregiver.completed = true)
            );
          })
          .catch(() => toaster.pop("error", "Something went wrong"))
          .finally(() => ($scope.isLoading = false));
      }
    );
  };

  $scope.caregiverCompletion = function (caregiver) {
    $scope.isCompleting = caregiver.id;

    const { id: caregiverId, completed } = caregiver;
    const payload = {
      caregiverId,
      classroomId: $scope.classroomId,
      completed: !completed,
    };

    TrainingCenterClassroomService.caregiverCompletion(payload)
      .then(() => {
        caregiver.completed = !caregiver.completed;
      })
      .catch(() => toaster.pop("error", "Something went wrong"))
      .finally(() => ($scope.isCompleting = undefined));
  };

  $scope.removeCertification = function (removeCert) {
    delete $scope.form.paycodePerCertificationMap[removeCert];
    $scope.certPaycodePairs = $scope.certPaycodePairs.filter(
      ({ certification }) => certification !== removeCert
    );

    setCertificationOptions();
  };

  $scope.saveClassroom = function () {
    if (!$scope.form) return;

    console.log("current", $scope.form.office);
    const { office } = $scope.form;
    if (!office.id) {
      $scope.fieldsWithErrors.push("office");
    }

    const { date, from, to } = $scope.form;
    if (!date) {
      $scope.fieldsWithErrors.push("date");
    }

    const newFrom = getDateTime(date, from);
    const newTo = getDateTime(date, to);
    if (date && !newFrom) {
      $scope.fieldsWithErrors.push("from");
    }
    if (date && !newTo) {
      $scope.fieldsWithErrors.push("to");
    }
    if (fromAfterTo(newFrom, newTo)) {
      $scope.fieldsWithErrors.push("to");
    }

    const { paycodePerCertificationMap } = $scope.form;
    if (Object.keys(paycodePerCertificationMap).length === 0) {
      $scope.fieldsWithErrors.push("certification");
    }

    const { bundles } = $scope.form;
    if (bundles.length === 0) {
      $scope.fieldsWithErrors.push("bundles");
    }

    if ($scope.fieldsWithErrors.length !== 0) return;

    const {
      language: { id: language },
      isOvertime,
    } = $scope.form;

    const newPaycodePerCertificationMap = {};
    Object.keys(paycodePerCertificationMap).map(
      (key) =>
        (newPaycodePerCertificationMap[key] =
          paycodePerCertificationMap[key].id || paycodePerCertificationMap[key])
    );

    const form = {
      from: newFrom,
      to: newTo,
      officeId: office.id,
      language,
      isOvertime,
      notes: null,
      paycodePerCertificationMap: newPaycodePerCertificationMap,
      bundles: bundles.map(({ id }) => id),
    };

    TrainingCenterClassroomService.editClassroom($scope.classroomId, form)
      .then(() => {
        toaster.pop("success", "Classroom was saved");
        console.log("current", $scope.form.office);
      })
      .catch(() => {
        toaster.pop("error", "Something went wrong", "Can't save classroom");

        $scope.form = $scope.formCopy;
      })
      .finally(() => {
        $scope.isLoading = false;
        $scope.isEditing = false;
      });
  };

  $scope.certificationSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("certification");

      setPaycodeOptions();
      setCaregiverOptions();

      $timeout(() => {
        $("#paycodeSelect").find(".dropdown-toggle").trigger("click");
      }, 50);
    },
  };

  $scope.paycodeSelectionEvents = {
    onItemSelect: function () {
      const paycode = $scope.paycodeOptions.find(
        ({ id }) => $scope.selectedPaycode.id === id
      );
      setCertificationPaycodePairs(paycode);

      $scope.selectedCertification = {};
      $scope.selectedPaycode = {};

      setCertificationOptions();
      setPaycodeOptions();
      setCaregiverOptions();
    },
  };

  $scope.officesSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("office");

      setCaregiverOptions();
    },
  };

  $scope.bundlesSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("bundles");
    },
  };

  $scope.closeModal = function (reason) {
    $uibModalInstance.close(reason);
  };

  $scope.editMode = function (enable) {
    $scope.isEditing = enable;
    if (enable) {
      $scope.formCopy = JSON.parse(JSON.stringify($scope.form));
      $scope.formCopy.date = new Date($scope.form.date);
      $scope.formCopy.from = new Date($scope.form.from);
      $scope.formCopy.to = new Date($scope.form.to);
      $scope.certPaycodePairsCopy = JSON.parse(
        JSON.stringify($scope.certPaycodePairs)
      );
    } else {
      $scope.form = $scope.formCopy;
      $scope.certPaycodePairs = $scope.certPaycodePairsCopy;
    }
  };

  function fromAfterTo(from, to) {
    if (!from || !from) return;

    return from.getTime() > to.getTime();
  }

  function getDateTime(date, time) {
    if (!date || !time) return;

    const dateStr = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    const timeStr = `${time.getHours()}:${time.getMinutes()}:00`;
    return new Date(`${dateStr} ${timeStr}`);
  }

  function setOffices() {
    const mapOffices = (offices) =>
      offices.map((office) => ({
        id: office.id,
        label: office.name,
      }));

    if (!officesService.offices) {
      officesService.getOffices().then((offices) => {
        $scope.offices = mapOffices(offices.filter((office) => office.active));

        const officeForLabel = $scope.offices.find(
          ({ id }) => id === $scope.form.office.id
        );
        $scope.form.office.label =
          officeForLabel !== undefined ? officeForLabel.label : "";
      });
    } else {
      $scope.offices = mapOffices(
        officesService.offices.filter((office) => office.active)
      );

      const officeForLabel = $scope.offices.find(
        ({ id }) => id === $scope.form.office.id
      );
      $scope.form.office.label =
        officeForLabel !== undefined ? officeForLabel.label : "";
    }
  }

  function setCertificationPaycodePairs({ id, label, certification }) {
    $scope.form.paycodePerCertificationMap[certification] = {
      id,
      label,
    };
    $scope.certPaycodePairs.push({ id, label, certification });
  }

  function setCertificationOptions() {
    if (!$scope.form || !$scope.form.paycodePerCertificationMap) return;

    $scope.certificationOptions = $scope.certifications.filter(
      ({ id: certificationId }) =>
        !Object.keys($scope.form.paycodePerCertificationMap).includes(
          certificationId
        ) &&
        $scope.paycodes
          .filter(({ certification }) => certificationId === certification)
          .filter(
            ({ id }) =>
              $scope.certPaycodePairs.find(
                (selectedPaycode) => selectedPaycode.id === id
              ) === undefined
          ).length > 0
    );
  }

  function setPaycodeOptions() {
    if (!$scope.selectedCertification) return;

    $scope.paycodeOptions = $scope.paycodes.filter(
      ({ id, certification }) =>
        $scope.selectedCertification.id === certification &&
        !$scope.certPaycodePairs.find(
          (selectedPaycode) => selectedPaycode.id === id
        )
    );
  }

  async function initForm() {
    $scope.fieldsWithErrors = []; // Names of fields, when validating fill this array with errorred fields

    $scope.offices = [];

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
      .map(certificationItem => ({
        id: certificationItem.certification,
        label: certificationItem.certification
      }));


    $scope.paycodes = [];

    $scope.languageOptions = $rootScope.allLanguages.map((lang) => ({
      id: lang,
      label: lang,
    }));
    $scope.certificationOptions = [];
    $scope.paycodeOptions = [];

    $scope.selectedCertification = {};
    $scope.selectedPaycode = {};

    $scope.certPaycodePairs = [];

    $scope.dateOptions = {
      minDate: new Date(),
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    const defaultFrom = new Date();
    defaultFrom.setHours(13, 0);
    const defaultTo = new Date();
    defaultTo.setHours(15, 0);

    $scope.form = {
      date: new Date(),
      from: defaultFrom,
      to: defaultTo,
      office: {},
      isOvertime: false,
      language: { id: "English" },
      paycodePerCertificationMap: {},
      bundles: [],
    };

    const mapPaycodes = (paycodes) =>
      paycodes.map(({ id, certification, displayId }) => ({
        id,
        certification,
        label: displayId,
      }));
    const shouldLoadPaycodes =
      !DatabaseApi.payrollCodes() || DatabaseApi.payrollCodes().length === 0;
    if (shouldLoadPaycodes) {
      $scope.paycodes = mapPaycodes((await DatabaseApi.getPayrollCodesAsync()).filter(code => code.isActive));
    } else {
      $scope.paycodes = mapPaycodes(DatabaseApi.activePayrollCodes());
    }

    $scope.bundleOptions = (
      await TrainingCenterClassroomService.getBundles()
    ).map(({ id, label }) => ({
      id,
      label,
    }));

    const {
      bundles,
      classroomCaregivers,
      date,
      from,
      to,
      language,
      isOvertime,
      officeId,
      paycodePerCertificationMap,
    } = await TrainingCenterClassroomService.getClassroom($scope.classroomId);

    $scope.form.bundles = bundles.map((id) => ({ id }));
    $scope.form.caregivers = classroomCaregivers.map(
      ({ caregiverId, completed }) => ({ caregiverId, completed })
    );
    $scope.form.date = date;
    $scope.form.from = from;
    $scope.form.to = to;
    $scope.form.language = {
      id: language,
    };
    $scope.form.isOvertime = isOvertime;
    $scope.form.office = {
      id: officeId,
    };
    $scope.form.paycodePerCertificationMap = paycodePerCertificationMap;
  }

  function initPaycodeCertificationMap() {
    const rawMap = $scope.form.paycodePerCertificationMap;
    for (const certification in rawMap) {
      const paycodeId = rawMap[certification];
      const paycode = $scope.paycodes.find(({ id }) => paycodeId === id);
      const { id, label } = paycode;

      $scope.certPaycodePairs.push({ certification, id, label });
    }
  }

  function initCaregivers() {
    if ($scope.form.caregivers == undefined) return;

    setCaregiverOptions();

    $scope.form.caregivers = $scope.form.caregivers.map(
      ({ caregiverId, completed }) => {
        return {
          ...DatabaseApi.caregivers()[caregiverId],
          caregiverId,
          id: caregiverId,
          completed,
        };
      }
    );

    setTableData($scope.form.caregivers);
  }

  function setCaregiverOptions() {
    $scope.caregiverOptions = [];

    const certs = $scope.certPaycodePairs.map(
      ({ certification }) => certification
    );

    for (const key in DatabaseApi.caregivers()) {
      if (!DatabaseApi.caregivers().hasOwnProperty(key)) continue;
      const caregiver = DatabaseApi.caregivers()[key];

      const matchesCertification = certs.includes(caregiver.certification);
      const notExists =
        $scope.form.caregivers.find(
          ({ id, caregiverId }) =>
            id === caregiver.id || caregiverId === caregiver.id
        ) === undefined;
      const matchesOffice = caregiver.officeIds.includes($scope.form.office.id);

      const shouldPush = matchesCertification && notExists && matchesOffice;
      if (shouldPush) {
        $scope.caregiverOptions.push({ ...caregiver });
      }
    }
  }

  function finishLoading() {
    if (!$scope.form) return;
    if (!$scope.form.caregivers) return;
    const caregiverDictNotLoaded =
      $scope.form.caregivers.filter(
        ({ displayName }) => displayName === undefined
      ).length > 0;
    if (caregiverDictNotLoaded) return;

    $scope.isLoading = false;
  }

  function setTableData(caregivers) {
    $scope.form.caregivers = caregivers;

    const options = {
      count: 25,
      sorting: { completed: "desc" },
    };

    $scope.tableParams = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: $scope.form.caregivers,
    });
  }
};
