/*! @ngInject */
export function entityNotesModalService ($uibModal) {
    const notesData = {};

    const setNotesData = ({
      profileId,
      profileName,
      notesType,
      calendarDay
    }) => {
      if (!notesType) {
        notesType = "Caregiver";
      }

      // Set notes data (exported later)
      notesData.profileId = profileId;
      notesData.profileName = profileName;
      notesData.notesType = notesType;
      notesData.calendarDay = calendarDay;
    };

    const handleNotesModalOpen = ({
      profileId,
      profileName,
      notesType
    }) => {
      setNotesData({
        profileId,
        profileName,
        notesType,
      });

      window["notesModalInstance"] = $uibModal.open({
        templateUrl: "admin/views/entity-general-notes-modal.html",
        size: "md",
        controller: "entityGeneralNotesModalCtrl",
        windowClass: "modal modal-slide-in-right entity-notes-container",
      });
      return window["notesModalInstance"];
    };

    const closeModal = () => {
      // Using window[] to check for undefined or defined
      if (window["notesModalInstance"] !== undefined) {
        notesModalInstance.close();
      }
    }

    return {
      notesData,
      closeModal,
      setNotesData,
      handleNotesModalOpen
    };
  };
