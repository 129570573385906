//! @ngInject
export function dutySheetModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, sheet, DatabaseApi, toaster){

    window.scrollTo(0,0);

    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };


    var answers = {};
    sheet.answers.forEach(function (question) {
        var answer = typeof question.answer === 'string' ? question.answer : question.answer[0];
        answers[question.questionId.toString()] = answer;
    });

    function getAnswer(id){
        return answers[id.toString()] || false;
    };

    var result = [];
    sheet.content.forEach(function (docItem) {
        var currentTitle = '';
        if(docItem.itemType === "bigHeader") currentTitle = docItem.label;
        else {
            docItem.section = currentTitle;
            if(docItem.itemType === "check") {
                docItem.type = "Task";
                docItem.completed = getAnswer(docItem.id) === 'Done';
                docItem.notes = getAnswer(docItem.columns[0][0].id);
            }
            else if(docItem.itemType === "smallHeader") docItem.type = "Attribute";
            else if(docItem.itemType === "yesNo") {
                docItem.type = "Quality Of Care";
                docItem.risk = getAnswer(docItem.id) === 'Yes';
                docItem.notes = getAnswer(docItem.columns[0][0].id);
            }
            result.push(docItem);

        }
    });

    $scope.plan = {items: result};

};
