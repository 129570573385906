//! @ngInject
export function editAddressPhonenumberModalCtrl($scope, $rootScope, $filter, DatabaseApi) {
      const phonenumbers = $scope.$resolve.patient.phoneNumbers.map((x) => ({
        id: x.id,
        label: $filter("phonenumber")(x.phonenumber),
      }));

      const form = {
        fields: {
          phoneNumberIds: $scope.$resolve.address.phoneNumberIds.map((x) => ({
            id: x,
          })),
        },
        loading: false,
      };

      function submit() {
        form.loading = true;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/addresses/:patientAddressId`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patient.id)
          .replace(":patientAddressId", $scope.$resolve.address.id);

        const data = {
          phoneNumberIds: form.fields.phoneNumberIds.map(x => x.id),
        };

        return DatabaseApi.put(url, data)
          .then(({ data }) => $scope.$resolve.onSuccess(data.address))
          .catch((e) => $scope.$resolve.onFail(e))
          .finally(() => (form.loading = false));
      }

      $scope.submit = submit;
      $scope.phonenumbers = phonenumbers;
      $scope.form = form;
    };
