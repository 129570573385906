import { PatientId, PlanOfCareId } from "../messages/ids";

interface Scope extends ng.IScope {
  handleClickSendEmail: () => void;
  closeModal: () => void;
  $resolve: {
    patientId: PatientId;
    patientFullName: string;
    selectedPlanOfCareId: PlanOfCareId;
  };
}

//! @ngInject
export function patientPocModalCtrl(
  $scope: Scope,
  $uibModal: ng.ui.bootstrap.IModalService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
) {
  $scope.handleClickSendEmail = () => {
    $uibModal.open({
      templateUrl: "admin/views/email-modal.html",
      size: "lg",
      resolve: {
        documents: () => [{ id: $scope.$resolve.selectedPlanOfCareId }],
        patientId: () => $scope.$resolve.patientId,
        patientFullName: () => $scope.$resolve.patientFullName,
        docsType: () => "planOfCare",
      },
      controller: "emailModalCtrl",
    });
  };

  $scope.closeModal = () => {
    $uibModalInstance.close();
  };
}
