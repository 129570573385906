export const agencyTeamsTable = {
  templateUrl: "../../admin/views/agency-teams-table.html",
  controller: agencyTeamsTableCtrl
};

//! @ngInject
function agencyTeamsTableCtrl(
  $scope,
  $rootScope,
  DatabaseApi,
  toaster,
  NgTableParams,
  wildcard,
  $uibModal
) {
  const init = () => {
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();

    const agencyTeams = DatabaseApi.agencyTeams();
    if (agencyTeams !== undefined) {
      $scope.agencyTeams = agencyTeams;
      initTable();
    } else {
      getTeams();
    }
  };

  $scope.$ctrl.$onInit = () => {
    init();
  };

  $rootScope.$on("got_data", function (event) {
    init();
  });

  const getTeams = () => {
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/teams",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );
    DatabaseApi.get(url).then(
      ({ data }) => {
        $scope.agencyTeams = data.agencyTeams;
        DatabaseApi.storeAgencyTeams($scope.agencyTeams);
        initTable();
      },
      function (error) {
        toaster.pop("error", "Failed to fetch agency teams");
      }
    );
  };

  function initTable() {
    const teamsData = $scope.agencyTeams;

    $scope.table = new NgTableParams(
      {
        count: 25,
      },
      {
        dataset: teamsData,
      }
    );
  }

  $scope.openModal = function (team) {
    var newScope = $scope.$new();
    newScope.team = angular.copy(team);

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-team-modal.html",
      size: "lg",
      controller: "agencyTeamModalCtrl",
      scope: newScope,
    });
    modalInstance.result.then(function () {
      getTeams();
    });
  };
}
