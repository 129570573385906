import moment from "moment";

//! @ngInject
export function sharedSavedReportsCtrl($scope, $state, $filter, $uibModal, NgTableParams, reportService, DatabaseApi, toaster) {
    $scope.agencyMembersMap = DatabaseApi.getAgencyMembers();
    
    function initialize() {
        loadItems();
    }

    const loadItems = function () {
        reportService.getSharedSavedReports().then(res => {
            $scope.savedReports = res.map(report => ({
                ...report, 
                loadingReport: false,
                lastUpdatedAt: report.updatedAt ?? report.createdAt
            }));
            initTable($scope.savedReports);
        });
    }

    const initTable = function (items) {
        const options = {
            count: 25,
        };

        $scope.tableParams = new NgTableParams(options, {
            dataset: items,
        });
    };

    $scope.clickTableRow = (savedReportId, accessType) => {
        $state.go('app.reports.reportTool', { 
            savedReportId,
            accessType 
        });
    }

    $scope.handleNewReport = () => {
        $state.go('app.reports.reportTool');
    }

    var universalBOM = "\uFEFF";

    $scope.handleRunReport = (savedReport) => {
        const reportIndex = $scope.savedReports.findIndex(report => report.id === savedReport.id);
        $scope.savedReports[reportIndex].loadingReport = true;

        reportService.executeSavedReport(savedReport.id, moment.tz.guess(true)).then(({ lines }) => {
            let csvContent = "";
            lines.forEach(function (row) {
                csvContent += row + "\r\n";
            });
            const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", getFileName(savedReport.name));
            document.body.appendChild(link);

            link.click();
                toaster.pop("success", "Success", "Successfully created report");
            }).catch(() => {
                toaster.pop("error", "Something went wrong", "Failed to create report");
            }).finally(() => {
                $scope.savedReports[reportIndex].loadingReport = false;
            });
    }

    const getFileName = (filename) => {
        return `medflyt-report-${(filename ? filename + "-" : '')}${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }

    $scope.handleOpenScheduledReport = function (id, name) {
        const newScope = $scope.$new();
        newScope.savedReportId = id;
        newScope.savedReportName = name;

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/scheduled-reports-modal.html',
            size: 'md',
            controller: 'scheduledReportsModalCtrl',
            scope: newScope,
            windowClass: 'modal modal-slide-in-right scheduled-reports-container',
            backdrop: 'static',
            keyboard : false
        });

        return modalInstance;
    };

    initialize();

};