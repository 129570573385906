import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { LocalDate, Month } from "@js-joda/core";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CustomHeaderParams } from "./DatePickerJsJodaAdapter";

function toYear(localDate: LocalDate) {
  return localDate.year();
}

function toMonthName(month: Month) {
  return {
    [Month.JANUARY.ordinal()]: "January",
    [Month.FEBRUARY.ordinal()]: "February",
    [Month.MARCH.ordinal()]: "March",
    [Month.APRIL.ordinal()]: "April",
    [Month.MAY.ordinal()]: "May",
    [Month.JUNE.ordinal()]: "June",
    [Month.JULY.ordinal()]: "July",
    [Month.AUGUST.ordinal()]: "August",
    [Month.SEPTEMBER.ordinal()]: "September",
    [Month.OCTOBER.ordinal()]: "October",
    [Month.NOVEMBER.ordinal()]: "November",
    [Month.DECEMBER.ordinal()]: "December",
  }[month.ordinal()];
}

function shouldDisplayNextMonth(customHeaderCount: number, totalMonthsShown: number | undefined) {
  return totalMonthsShown === undefined || totalMonthsShown - 1 === customHeaderCount;
}

interface Props extends CustomHeaderParams {
  totalMonthsShown?: number;
}

const DatePickerHeader = (props: Props) => {
  const canMoveToNextMonth = shouldDisplayNextMonth(
    props.customHeaderCount,
    props.totalMonthsShown
  );

  return (
    <Flex>
      {props.customHeaderCount === 0 && (
        <IconButton
          aria-label="Previous month"
          variant="text"
          icon={<FaChevronLeft />}
          onClick={props.decreaseMonth.bind(this)}
        />
      )}
      <Flex gap={2} alignItems="center" justifyContent="center" flex={1}>
        <Menu>
          <MenuButton as={Button} variant="ghost">
            {toMonthName(props.monthDate.month())}
          </MenuButton>
          <MenuList minWidth="fit-content">
            {Month.values().map((month) => (
              <MenuItem
                fontSize="md"
                key={month.ordinal()}
                onClick={() => props.changeMonth(month)}
              >
                {toMonthName(month)}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Button} variant="ghost">
            {toYear(props.monthDate)}
          </MenuButton>
          <MenuList minWidth="fit-content">
            {[
              props.monthDate.minusYears(1).year(),
              props.monthDate.year(),
              props.monthDate.plusYears(1).year(),
              props.monthDate.plusYears(2).year(),
            ].map((year) => (
              <MenuItem fontSize="md" key={year} onClick={() => props.changeYear(year)}>
                {year}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      {canMoveToNextMonth && (
        <IconButton
          aria-label="Next month"
          variant="text"
          icon={<FaChevronRight />}
          onClick={props.increaseMonth.bind(this)}
        />
      )}
    </Flex>
  );
};

export default DatePickerHeader;
