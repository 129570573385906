import moment from "moment";

//! @ngInject
export function newCaregiverAbsenceModalCtrl(
	$scope,
	$rootScope,
	$uibModalInstance,
	$uibModal,
	noteConsts,
	entityNoteService,
	DatabaseApi,
	toaster,
	mfModal,
	dateUtils,
	$q,
	$filter,
){
	const initialize = () => {
		$scope.isLoading = false;
		$scope.datesRange = {
			from: dateUtils.localDateToDate($scope.$resolve.selectedDate),
			to: dateUtils.localDateToDate($scope.$resolve.selectedDate)
		};
		$scope.caregiverId = $scope.$resolve.caregiverId;
		$scope.absenceNote = {};
		$scope.isAbsenceNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.CAREGIVER_ABSENCE);
	};

	const validateCreateForVisitsOverlaps = ({ from, to }) => {
		let url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/visits/check_overlapping"
			.replace(":agencyId", $rootScope.agencyId)
			.replace(":agencyMemberId", $rootScope.agencyMemberId)
			.replace(":caregiverId", $scope.caregiverId);
		url += `?from=${from}&to=${to}`;
		return new Promise((resolve, reject) => {
			DatabaseApi.get(url)
				.then(({ data }) => {
					const overlappingVisits = data.overlappingVisits;
					if (!Array.isArray(overlappingVisits)) {
            mfModal.createSimple({
              variant: "danger",
              subject: "Oops...",
              message: "Failed to validate date range"
            });
						return reject();
					}
					overlappingVisits.forEach(visitInstance => {
						visitInstance.overlappingStatus = getVisitInstanceOverlappingStatus(visitInstance);
					});
					resolve(overlappingVisits);
				});
		});
	};

	const getVisitInstanceOverlappingStatus = (visitInstance) => {
		if (visitInstance.visit.removedAt !== null) {
			return "DELETED";
		}
		if (
			visitInstance.billedSeconds !== 0 ||
			visitInstance.paidSeconds !== 0 ||
			visitInstance.draftPaidSeconds !== 0
		) {
			return "BLOCK_BILLED_PAID_DRAFT";
		}
		if (
			visitInstance.visit.clockinTime !== null ||
			visitInstance.visit.clockoutTime !== null
		) {
			return "BLOCK_CLOCKED";
		}
		return "PTO_ABLE";
	};

	const createPtosAndAbsences = ({ from, to, overlappingVisits }) => {
		const ptoAbleVisits = overlappingVisits.filter(visit => visit.overlappingStatus === "PTO_ABLE");
		const createAbsenceRequest = {
			url: "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/absence"
				.replace(":agencyId", $rootScope.agencyId)
				.replace(":agencyMemberId", $rootScope.agencyMemberId)
				.replace(":caregiverId", $scope.caregiverId),
			body: {
				from: from,
				to: to,
				caregiverAbsenceNote: entityNoteService.buildEntityNoteRequest(
					$scope.absenceNote,
					noteConsts.NoteTypes.CAREGIVER_ABSENCE,
				)
			},
			noteSetting: entityNoteService.getEntityNoteSettings(
				noteConsts.NoteTypes.CAREGIVER_ABSENCE
			)
		};
		if (ptoAbleVisits.length > 0) {
			openCaregiverPtoModal({ptoAbleVisits, createAbsenceRequest})
		} else {
			if (createAbsenceRequest.noteSetting.agencyId === null) {
				entityNoteService.createAgencyEntityNoteSetting(createAbsenceRequest.noteSetting).then((res) => {
					sendCreateAbsenceRequest(createAbsenceRequest);
					DatabaseApi.getAgencyNoteSettings();
				}, (err) => {
					console.error(err);
					toaster.pop("error", "Failed to create absence");
				});
			} else {
				sendCreateAbsenceRequest(createAbsenceRequest);
			}
		}
	};

	const openCaregiverPtoModal = async ({ptoAbleVisits, createAbsenceRequest}) => {
		const deferred = $q.defer();

		const newScope = $scope.$new();
		newScope.caregiverId = $scope.caregiverId;
		newScope.visitInstances = ptoAbleVisits.map((x) => {
			const visitInstance = x.visit;

			return {
				...visitInstance,
				originalStart: moment(visitInstance.startTime),
				originalEnd: moment(visitInstance.endTime),
			};
		});
		newScope.createAbsenceRequest = createAbsenceRequest;

		const modalInstance = $uibModal.open({
			templateUrl: "admin/views/visit-instance-pto-modal.html",
			size: "md",
			controller: "visitInstancePTOModalCtrl",
			windowTopClass: "PTO-modal",
			scope: newScope,
		});

		modalInstance.result.then((res) => {
			if (res === "CREATE_WITH_ABSENCE_SUCCESS") {
				toaster.pop("success", "Absence and PTOS created successfully");
				$scope.closeModal("ABSENCE_CREATED");
				deferred.resolve();
			} else if (res === "ABSENCE_CREATED") {
				toaster.pop("success", "Absence created successfully");
				$scope.closeModal("ABSENCE_CREATED");
				deferred.resolve();
      } else {
				deferred.reject(null);
			}
		});

		return deferred.promise;
	};

	const sendCreateAbsenceRequest = ({ url, body }) => {
		DatabaseApi.post(url, body)
			.then((res) => {
				toaster.pop("success", "Absence created successfully");
				$scope.closeModal("ABSENCE_CREATED");
			})
			.catch((err) => {
				console.error(err);
        mfModal.createSimple({
          variant: "danger",
          subject: "Oops...",
          message: "Failed to set absence"
        });
			});
	};

	const validateForm = () => {
		const errorMessages = [];

		if (!$scope.datesRange.from || !$scope.datesRange.to) {
			errorMessages.push("Please choose dates range and try again");
		}

		const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
			$scope.absenceNote,
			noteConsts.NoteTypes.CAREGIVER_ABSENCE
		);
		if (!isNoteValid) {
			$scope.noteValidations = { isPredefinedValid, isMessageValid };
			errorMessages.push("Note missing required fields");
		}

		return errorMessages;
	};

	const getVisitsFormattedDates = (visits) => {
		return visits.map((visit, index) => {
			const date = $filter("mfShortTime")(new Date(visit.visit.startTime), ['withDate']);
			return `${index + 1}. ${date}`;
		}).join("\n");
	};

	$scope.handleClickSetInAbsence = async () => {
		$scope.errorMessages = validateForm();
		if (Array.isArray($scope.errorMessages) && $scope.errorMessages.length > 0) {
			return;
		}

		$scope.isLoading = true;

		const { from, to } = {
			from: dateUtils.dateToLocalDate($scope.datesRange.from),
			to: dateUtils.dateToLocalDate($scope.datesRange.to)
		};

		try {
			const overlappingVisits = await validateCreateForVisitsOverlaps({ from, to });
			const billedPaidDraftOverlappingVisits = overlappingVisits
				.filter(visit => visit.overlappingStatus === "BLOCK_BILLED_PAID_DRAFT");
			const clockedOverlappingVisits = overlappingVisits
				.filter(visit => visit.overlappingStatus === "BLOCK_CLOCKED");
			if (billedPaidDraftOverlappingVisits.length > 0 || clockedOverlappingVisits.length > 0) {
				let billedPaidDraftMessage = "";
				if (billedPaidDraftOverlappingVisits.length > 0) {
					const billedPaidDraftVisitsDetails = getVisitsFormattedDates(billedPaidDraftOverlappingVisits);
					billedPaidDraftMessage = `It is not allowed to set “in absence” on a billed/paid/on payroll draft visits:\n\n${billedPaidDraftVisitsDetails}`;
				}
				let clockedMessage = "";
				if (clockedOverlappingVisits.length > 0) {
					const clockedVisitsDetails = getVisitsFormattedDates(clockedOverlappingVisits);
					clockedMessage = `Absences for already clocked in or out shifts cannot be set. There are clock in/out times for visits on:\n\n${clockedVisitsDetails}`;
				}
				const errorMessage = [billedPaidDraftMessage, clockedMessage].filter(Boolean).join(`\n\n`);
        mfModal.createSimple({
          variant: "danger",
          subject: "Oops...",
          message: errorMessage
        });
			} else {
				createPtosAndAbsences({ from, to, overlappingVisits });
			}
		} finally {
			$scope.isLoading = false;
		}
	};

	$scope.handleNoteChanges = (updatedNote) => {
		$scope.absenceNote = updatedNote;
	};

	$scope.closeModal = (data = "ok") => {
		$uibModalInstance.close(data);
	};

	initialize();
};
