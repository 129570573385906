type NotesState = "app.notes.report" | "app.notes.mentionsInbox" | "app.notes.mentionsDone";

interface NoteTab {
    id: number;
    name: string;
    state: NotesState;
  }
  
/*! @ngInject */
export class NotesCtrl {
  activeTabIndex = 0;
  tabs: NoteTab[] = [
    {
      id: 0,
      name: "Reports",
      state: "app.notes.report",
    },
    {
      id: 1,
      name: "Inbox",
      state: "app.notes.mentionsInbox",
    },
    {
      id: 2,
      name: "Done",
      state: "app.notes.mentionsDone",
    },
  ];

  constructor(private $state: angular.ui.IStateService) {
    this.setInitialRoute();
  }

  setInitialRoute = () => {
    let route = this.tabs.find((r) => r.state === this.$state.current.name);
    if (route === undefined) {
      route = this.tabs[0];
    }
    this.activeTabIndex = route.id;
    this.goToMentionState(route.state);
  };

  goToMentionState = (notesState: NotesState) => {
    this.$state.go(notesState);
  };
}
