export function fmap<T, R>(v: T | null | undefined, predicate: (v: T) => R): R | null {
  if (v === null || v === undefined) {
    return null;
  }

  return predicate(v);
}

export function validateOneOf<T>(value: any, possibilies: readonly T[]): T {
  if (!possibilies.includes(value)) {
    throw new Error(
      `validation of validateOneOf failed. Expected "${value}" to be one of "${possibilies.join(
        ", "
      )}"`
    );
  }

  return value as T;
}
