import $ from "jquery";

//! @ngInject
export function caregiverTrainingCenterInfoBundleDueDateHeaderCtrl(
  $rootScope,
  $scope,
  $uibModal,
  $window,
  CaregiverTrainingCenterService,
  toaster
) {
  const service = CaregiverTrainingCenterService;

  this.$onChanges = function (changedData) {
    if (
      changedData.caregiver !== undefined &&
      changedData.caregiver.currentValue !== undefined
    ) {
      $scope.caregiver = changedData.caregiver.currentValue;
      $scope.caregiverId = $scope.caregiver.id;
    }
    if (
      changedData.bundle !== undefined &&
      changedData.bundle.currentValue !== undefined
    ) {
      $scope.bundle = changedData.bundle.currentValue;
    }
    if (
      changedData.dueDate !== undefined &&
      changedData.dueDate.currentValue !== undefined
    ) {
      $scope.dueDate = changedData.dueDate.currentValue;
    }
  };

  $scope.openEditDatesModal = function () {
    const modalInstance = $uibModal.open({
      size: "lg",
      templateUrl: "admin/views/caregiverTrainingCenterInfoEditDatesModal.html",
      controller: "caregiverTrainingCenterInfoEditDatesModalCtrl",
      resolve: {
        caregiver: function () {
          return $scope.caregiver;
        },
        bundle: function () {
          return $scope.bundle;
        },
        dueDate: function () {
          return $scope.dueDate;
        },
      },
    });

    modalInstance.result.then(
      function ({ result, msg, updatedBundle, updatedDueDate }) {
        if (result === "SUCCESS") {
          $scope.bundle = updatedBundle;
          $scope.dueDate = updatedDueDate;

          toaster.pop("success", "Due dates updated");
        } else if (result === "ERROR") {
          toaster.pop("error", msg);
        }
      },
      function () {
        // Modal dismissed, do nothing
      }
    );
  };

  $scope.onViewCertificate = function (bundleDueDateId) {
    service
      .getCertificateUrl({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: bundleDueDateId,
      })
      .then((url) => $window.open(url))
      .catch((err) => {
        toaster.pop("error", "Couldn't load certificate");
      });
  };

  $scope.onClickMarkBundleExempt = async function (dueDate) {
    const params = {
      exempt: !dueDate.bundleExempted,
      notes: "", // TODO: We should open a modal to allow the user to enter a reason for the exemption
    };

    await service
      .editBundleExempt({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: dueDate.bundleDueDateId,
        params,
      })
      .then(() => (dueDate.bundleExempted = !dueDate.bundleExempted))
      .catch((err) => {
        switch (err.status) {
          case 409:
            toaster.pop("error", "Couldn't set exempt", err.data.message)
            break;
          default:
            toaster.pop("error", "Couldn't set exempt");
        }
      });
  };

  // async
  $scope.toggleWatchBundleDuringShift = async function (dueDate) {
    if (dueDate.caregiverOnShiftOverride === null) {
      await service
        .allowWatchDuringShift({
          agencyId: $rootScope.agencyId,
          agencyMemberId: $rootScope.agencyMemberId,
          caregiverId: $scope.caregiverId,
          bundleDueDateId: dueDate.bundleDueDateId,
        })
        .then(({ data }) => { dueDate.caregiverOnShiftOverride = data })
        .catch((err) => toaster.pop("error", "Couldn't set watch during visit"));
    } else {
      await service
        .preventWatchDuringShift({
          agencyId: $rootScope.agencyId,
          caregiverId: $scope.caregiverId,
          bundleDueDateId: dueDate.bundleDueDateId,
        })
        .then(( res ) => { dueDate.caregiverOnShiftOverride = null })
        .catch((err) => toaster.pop("error", "Couldn't set watch during visit"));
    }
  }
  $scope.sendReminder = async function (caregiverId, bundleId) {
    await service
      .sendReminderToCaregiver({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId,
        bundleId,
      })
      .then(() =>
        toaster.pop("success", "Reminder notification sent to caregiver")
      )
      .catch(() => toaster.pop("error", "Couldn't set exempt"));
  };

  $scope.ignoreClick = function ($event) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
      $("html").click();
    }
  };

  $scope.showRegenerateCount = 0;
  $scope.increaseRegenerateCount = function () {
    $scope.showRegenerateCount += 1;
  };
  $scope.regenerateCountShow = function () {
    return $scope.showRegenerateCount > 6;
  };

  $scope.dateOverriden = function (date, overrideDate) {
    if (overrideDate && date !== overrideDate) {
      return true;
    }

    return false;
  };

  $scope.openRegenerateModal = (dueDate, bundle) => {
    const instance = $uibModal.open({
      templateUrl: "admin/views/caregiver-regenerate-certificate-modal.html",
      size: "lg",
      resolve: {
        dueDate: () => dueDate,
        bundle: () => bundle,
        caregiverId: () => $scope.caregiverId,
        shouldForce: () => $scope.regenerateCountShow(),
        onSuccess: () => handleSuccess,
        onFail: () => handleFail,
      },
      controller: "caregiverRegenerateCertificateModalCtrl",
    });

    function handleSuccess(bundleTitle, bundleYear, fileUrl) {
      const settings = {
        type: "success",
        title: `${bundleTitle} (${bundleYear}) regenerated successfully`,
        timeout: 5000,
      };
      toaster.pop(settings);

      instance.close();
      $window.open(fileUrl);
    }

    function handleFail(bundleTitle, bundleYear, error) {
      let title;
      let body;
      switch (error) {
        case "NO_REASONS":
          title = `${bundleTitle} (${bundleYear}) wasn't regenerated`;
          body = "No reason provided, please select a reason";
          break;
        case "NOT_COMPLETED":
          title = `${bundleTitle} (${bundleYear}) wasn't regenerated`;
          body = [
            "The due date is incomplete",
            "This happens when a bundle was changed after the caregiver got the certificate",
            // "You can try a forced regeneration", // Commented out until we decide we can open this to the users
          ].join("<br />");
          break;
        case "NO_CAREGIVER_SIGNATURE":
          title = `${bundleTitle} (${bundleYear}) wasn't regenerated`;
          body =
            "Caregiver has no signature, make sure caregiver signs in the settings of the mobile app";
          break;
        default:
          title = `${bundleTitle} (${bundleYear}) wasn't regenerated`;
          body = "Couldn't regenerate, please try again later";
          break;
      }
      const settings = {
        type: "error",
        title,
        body,
        bodyOutputType: "trustedHtml",
        timeout: 4000,
      };

      toaster.pop(settings);
    }
  };
};
