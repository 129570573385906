//! @ngInject
export function displayIdSettingsModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  data,
  toaster,
  DatabaseApi
) {
  $scope.displayIds = data;
  if ($scope.displayIds.patient) {
    $scope.displayIds.patient.viewOnly = true
  }
  if ($scope.displayIds.caregiver) {
    $scope.displayIds.caregiver.viewOnly = true
  }

  $scope.submit = (entityName, settings) => {
    const payload = {};
    payload[entityName] = settings;
    DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/display_ids_settings',
      payload
    ).then(function () {
      $scope.displayIds[entityName].viewOnly = true
      toaster.pop('success', 'IDs pattern saved successfully', 'The new IDs will be updated in the next few minutes');
      $rootScope.$broadcast("savedDisplayIdSettings");
    }).catch(function () {
      toaster.pop('error', 'Cannot save ID pattern');
    });
  }

  $scope.getIdPattern = function (firstDigit, length) {
    let Xstring = '';
    for (let i = 1; i < length; i++) {
      Xstring = Xstring.concat('X');
    }
    return firstDigit + Xstring;
  }

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };
};
