//! @ngInject
export function integrationFileRecordModalCtrl($scope, $uibModalInstance, $filter, toaster) {
    $scope.record = $scope.$resolve.record;
    $scope.hideEmptyFields = $scope.$resolve.hideEmptyFields;
    $scope.updateBillingFileRecord = $scope.$resolve.updateBillingFileRecord;

    $scope.downloadRecordContent = () => {
        const content = $scope.record.content;
        const contentKeys = Object.keys(content);

        if (contentKeys.length === 0) {
            toaster.pop('warning', 'Nothing to export', 'No Content at the record');
            return;
        }
        let contentValues = Object.values(content);
        contentValues = contentValues.map(val => {
            return val = '"' + val + '"';
        });

        let rows = [];
        let colNames = contentKeys;

        rows.push(colNames);
        rows.push(contentValues);
        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getFileName());
        document.body.appendChild(link);
        link.click();
    }

    const getFileName = () => {
        return `integration-file-record-${$scope.record.id}-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };
};
