export function customProHeader() {
  var directive = {
    restrict: "E",
    replace: true,
    templateUrl: "pro-header.html",
    scope: true
    //controller: 'ngTableGroupRowController',
    //controllerAs: 'dctrl'
  };
  return directive;
}
