import { CaregiverId, PatientId, TimesheetId, VisitInstanceId } from "../messages/ids";
import { TimeSheetsForApproval } from "../messages/timesheet";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

interface TimesheetChangeStatusData {
  timesheets: {
    timesheetId: TimesheetId,
    caregiverId: CaregiverId,
    patientId: PatientId,
    visitInstanceId: VisitInstanceId
  }[],
  onSuccess?: (res: any) => void,
  onCatch?: (err: any) => void,
  onFinally?: () => void
}

//! @ngInject
export class TimesheetsService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService,
    private $uibModal: any,
    private entityNoteService: any,
    private noteConsts: any
  ) {}

  editTimesheetsStatus(params: TimesheetChangeStatusData, newStatus: "APPROVED" | "DECLINED") {
    const modalInstance = this.$uibModal.open({
      templateUrl: "admin/views/approve-duty-sheets-clock-times-modal.html",
      size: "md",
      controller: "approveDutySheetsClockTimesModalCtrl",
      resolve: {
        requestNoteText: () => false,
        changeToTimesheetStatus: () => newStatus
      }
    });

    modalInstance.result.then((res: any) => {
      const {approveDutySheetNote} = res;

      if (approveDutySheetNote && approveDutySheetNote.note) {
        const body: TimeSheetsForApproval = {
          timesheets: []
        }
        params.timesheets.forEach((item) => {
          approveDutySheetNote.note.visitInstanceId = item.visitInstanceId;

          const note = this.entityNoteService.buildEntityNoteRequest(
            approveDutySheetNote.note,
            this.noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME,
            {
              patientId: item.patientId,
              caregiverId: item.caregiverId
            }
          );
          const result = {
            timesheetId: item.timesheetId,
            note: note
          }

          body.timesheets.push(result);
        });

        const path = newStatus === "APPROVED" ?
          "agencies/:agencyId/agency_members/:agencyMemberId/time_sheet_approval" :
          "agencies/:agencyId/agency_members/:agencyMemberId/time_sheet_decline"

        const url = this.endpoint({
          path: path,
          params: {
            agencyId: this.$rootScope.agencyId,
            agencyMemberId: this.$rootScope.agencyMemberId,
          },
        });

        return this.api.post(url, body)
          .then(params.onSuccess)
          .catch(params.onCatch)
          .finally(() => {
            this.$rootScope.$broadcast("refresh_timesheets");
            if (params.onFinally) {
              params.onFinally();
            }
          });
      }

      if (params.onFinally) {
        params.onFinally();
      }
    });
  }
}
