/* @ngInject */
export default function displayEnvUrl() {
  return (env) => {
    if (env.apiBaseUrl === null || env.socketIOServerUrl === null) {
      return window.location.origin;
    }

    return `${window.location.origin}/?overrideApiUrl=${env.apiBaseUrl}&overrideSocketUrl=${env.socketIOServerUrl}`;
  };
}
