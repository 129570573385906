//! @ngInject
export function uploadSubsectionModalCtrl($scope, $rootScope, $uibModalInstance, subsectionId, subsectionName, toaster, Consts, $stateParams, $http, proId) {

    window.scrollTo(0, 0);
  
    $scope.docName = subsectionName;
  
    $scope.uploading = false;
    $scope.file = null;
  
    $scope.closeModal = function () {
      $uibModalInstance.dismiss();
    };

    $scope.popup1 = { opened: false };
    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
  
    $scope.$watch('file', function () {
      if ($scope.file && $scope.file.name) {
        var fileType = $scope.file.name.split('.');
        fileType = fileType[fileType.length - 1];
        fileType = fileType.toLowerCase();
        if (fileType === 'pdf' || fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
  
        } else {
          toaster.pop('warning', "Unsupported file type", '');
          $scope.file = null;
        }
      }
    });
  
  
    $scope.sendFile = function () {
  
      $scope.uploading = true;
  
      var formData = new FormData();
      formData.append('subSectionId', subsectionId);
      formData.append('file', $scope.file, $scope.file.name);
  
      var url = Consts.api + 'hr/agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/caregiver/' + proId + '/document_upload';
  
      $http({
        url: url,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': undefined }
      }).then(function (response) {
        $scope.file = null;
        $scope.fileSent = 'Thanks for uploading, the file was uploaded successfully.';
        toaster.pop('success', "Success", "File Uploaded");
        $scope.uploading = false;
        $uibModalInstance.close(true);
      }, function (response) {
        $scope.file = null;
        $scope.fileSent = 'An Error Occurred, please refresh the page and try again';
        toaster.pop('error', "Something Went Wrong", 'Please try again');
        $scope.uploading = false;
        //getHistory();
  
      });
  
    };
  };
  