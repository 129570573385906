export const caregiverVisits = {
  templateUrl: "admin/views/professional-page-visits.html",
  bindings: {
    caregiverId: "<",
    visits: "<",
    goToVisitCallback: "&"
  },
  controller: caregiverVisitsCtrl,
};

//! @ngInject
function caregiverVisitsCtrl(
  $scope,
  $rootScope,
  $state,
  NgTableParams,
  toaster,
  CaregiverModalService
) {
  $scope.openPatientModal = $rootScope.openPatientModal;

  this.$onInit = () => {
    $scope.visits = this.visits;
    $scope.goToVisitCallback = this.goToVisitCallback;
    initTable($scope.visits);
  };

  this.$onChanges = (changedData) => {
    if (!changedData || !changedData.visits || !changedData.visits.currentValue)
      return;
    $scope.visits = this.visits;
    initTable($scope.visits);
  };

  function initTable(visits) {
    if (!visits) return;

    const options = {
      count: 10,
      sorting: { createdAt: "desc" },
    };

    $scope.visitsTable = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: visits,
    });
  }

  $scope.cancelVisit = function ({ visitSourceType, id, index }) {
    if (visitSourceType !== "IMPORTED") return;

    console.log(index);

    $scope.cancellingVisit = index;
    CaregiverModalService.cancelImportedVisit(id)
      .then(() => {
        $scope.visits.splice(
          $scope.visits.findIndex((visit) => visit.index === index),
          1
        );
        $scope.visitsTable.reload();
        toaster.pop("success", "Imported visit was canceled");
      })
      .catch(() =>
        toaster.pop("error", "Couldn't cancel visit", "Something went wrong")
      )
      .finally(() => ($scope.cancellingVisit = undefined));
  };

  $scope.goToVisit = function ({ id, visitId, price }) {
    if (visitId) {
      // Having visitId means its a child visit with parent visitId
      $scope.goToVisitCallback({ id: visitId });
    }

    if (id && price !== undefined) {
      // A defined price means it's a parent id
      $scope.goToVisitCallback({ id });
    }

    // Do nothing if it's an imported visit
  };
}
