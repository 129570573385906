//! @ngInject
export function entityNoteCommentsModalCtrl(
  $rootScope,
  $scope,
  DatabaseApi,
  $uibModalInstance,
  toaster,
  noteService,
  entityNoteService,
  noteConsts,
  wildcard
) {

  $scope.subjects = DatabaseApi.agencyNoteSubjectsWithNoteTypes(true);
  $scope.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();
  $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
  $scope.caregiversMap = DatabaseApi.caregivers();
  $scope.patientsMap = DatabaseApi.patients();

  const mapNote = (note) => {
      note.isBulk = note.noteType === "bulk";
      note.isProfileNote = note.isBulk || note.noteType === "profile_note";
      note.subjectText = note.isProfileNote ? note.subject.name : noteConsts.NoteTypesTranslations[note.noteType];
      note.noteRichText = note.predefinedAnswerText && note.noteRichText ?
      entityNoteService.setRichNoteText(note.noteRichText) : note.noteRichText;
      return {
        ...note,
        createdBy:
          $scope.agencyMembers[note.createdBy] !== undefined
            ? $scope.agencyMembers[note.createdBy].name
            : ($scope.agencyMembersNames[note.createdBy] !== undefined
              ? ($scope.agencyMembersNames[note.createdBy] + " (Inactive)")
              : "Deleted Coordinator"),
        createdByPhotoURL:
          $scope.agencyMembers[note.createdBy] !== undefined
            ? $scope.agencyMembers[note.createdBy].photoUrl
            : undefined
      }
  }

  $scope.note = mapNote($scope.note);

  $scope.closeModal = function () {
    $uibModalInstance.dismiss();
  };

  const fetchNoteComments = (note) => {
    noteService
      .getNoteComments(note.id)
      .then((res) => {
        note.comments = mapComments(res);
      })
      .catch((err) => {
        toaster.pop("warning", "Something went wrong");
      });
  };

  $scope.handleCommentsChange = (note) => {
    fetchNoteComments(note);
  }

  const mapComments = (comments) => {
    return comments.map((comment) => ({
      ...comment,
      isUserAuthor: comment.createdBy == $rootScope.agencyMemberId,
      createdByName:
        $scope.agencyMembers[comment.createdBy] !== undefined
          ? $scope.agencyMembers[comment.createdBy].name
          : $scope.agencyMembersNames[comment.createdBy] !== undefined
          ? $scope.agencyMembersNames[comment.createdBy] + " (Inactive)"
          : "Deleted Coordinator",
      createdByPhotoURL:
        $scope.agencyMembers[comment.createdBy] !== undefined
          ? $scope.agencyMembers[comment.createdBy].photoUrl
          : undefined,
    }));
  };

  $scope.fetchNoteTitle = (note) => {
    if(note.title === undefined){
      note.title = "";
      noteService
      .getNoteTitle(note.id, note.noteType)
      .then((res) => {
        note.title = res.title;
      })
      .catch((err) => {
        delete note.title;
        toaster.pop("warning", "Something went wrong");
      });
    }
  }

  $scope.getNoteTitle = (note) => {
    return note.title? note.title : 'loading...';
  }

  //todo: do we need this? if so - what should we do? (base on cargiver/patient id - if so - what to do when bulk?)
  $scope.exportNotes = (noteIds) => {
    if (!noteIds || !noteIds.length) {
      return;
    }
    let baseUrl = ""
    if ($scope.notesType === "Caregiver") {
      baseUrl = "agencies/:agencyId/agency_members/:agencyMemberId/caregiver/:caregiverId/export_notes";
    } else if ($scope.notesType === "Patient") {
      baseUrl = "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/export_notes";
    } else {
      return;
    }
    const url = wildcard(
      baseUrl,
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.modalData.profileId
    );
    const body = {
      noteIds: noteIds
    };
    DatabaseApi.post(url, body).then((res) => {
      $scope.notesDisplay.forEach(note => {
        note.checked = false;
      });
      window.open(res.data.fileUrl).focus();
    }, (err) => {
      toaster.pop("error", "Something went wrong", "Failed exporting notes");
    });
  };

};
