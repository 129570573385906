//! @ngInject
export function visitClockTypeMapModalCtrl($scope, $timeout, $uibModalInstance) {

    var map;
    var clockTypeIcon = L.icon({
        iconUrl: 'admin/images/red-location-pin.png',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
    });

    var clockMarkerOptions = {
        icon: clockTypeIcon,
        interactive: false
    };

    var patientIcon = L.icon({
        iconUrl: 'admin/images/patient2.png',
        iconSize: [32, 38],
        iconAnchor: [16, 16]
    });

    var patientMarkerOptions = {
        icon: patientIcon,
        interactive: false
    };

    var zoomLevel = $scope.checkInDistanceDiff === 10 ? 18 : 17;
    var circleOptions = {
        radius: $scope.checkInDistanceDiff * 0.3048,
        weight: 0.5,
        fillOpacity: 0.1
    };
    $scope.map = { zoom: zoomLevel };

    $scope.map.center = {
        latitude: $scope.patientLocation.lat,
        longitude: $scope.patientLocation.lng
    };

    function initVisitInstanceClockMap() {
        try {
            if (map) {
                map.setView(
                    [$scope.map.center.latitude, $scope.map.center.longitude],
                    $scope.map.zoom
                );
            }
            map = L.map('visitInstanceMap', {
                center: [$scope.map.center.latitude, $scope.map.center.longitude],
                zoom: $scope.map.zoom
            });

            map.setView(
                [$scope.map.center.latitude, $scope.map.center.longitude],
                $scope.map.zoom
            );

            L.tileLayer('https://d5nz7zext4ylq.cloudfront.net/{z}/{x}/{y}.png', {
                tileSize: 512,
                zoomOffset: -1,
                minZoom: 5,
                attribution: '',
                crossOrigin: true
            }).addTo(map);

            L.circle(
                [$scope.map.center.latitude, $scope.map.center.longitude],
                circleOptions
            ).addTo(map);
            L.marker(
                [$scope.map.center.latitude, $scope.map.center.longitude],
                patientMarkerOptions
            ).addTo(map);

            L.marker(
                [$scope.clockLocation.lat, $scope.clockLocation.lng],
                clockMarkerOptions
            ).addTo(map);
        }

        catch (error) {
            $timeout(initVisitInstanceClockMap, 100);
        }
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    initVisitInstanceClockMap();
};