import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useRouter } from "@uirouter/react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import EntityCard, { Entity } from "../../../shared/components/EntityCard";
import ClockIcon from "../../../shared/icons/ClockIcon";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { sortingFns } from "../../../shared/utils/tanstack-table";
import { getMessagePreview, getTopicEntityFromTicket } from "../utils/communication-utils";
import { TableCellBadge } from "./TableCellBadge";
import { Duration, Instant } from "@js-joda/core";
import { durationFormatter } from "../../../shared/utils/duration-formatter";

interface TicketDataRow {
  id: CommCenterTicketId;
  name: string;
  entity: Entity;
  label: string;
  lastMessage: Messages["CommCenterMessage"] | null;
  priority: "Low" | "Medium" | "High";
  estimatedResolveTime: number;
  waitingTime: { duration: Duration; formatted: string } | null;
  assignee: string | null;
  ticketStatus: "New" | "In Progress" | "Resolved";
  onboardingStage?: string; // enum
  unreadCount: number;
  team: string;
}

function toDataRow(ticket: Messages["CommCenterTicket"]): TicketDataRow {
  const entity = getTopicEntityFromTicket(ticket);

  const unreadCount = ticket.messages.filter(
    (x) => x.readAt === null && x.createdBy.type === "Caregiver"
  ).length;

  const lastMessage = ticket.messages.at(-1);

  return {
    id: ticket.id,
    name: entity.fullName,
    assignee: ticket.assignedTo?.name ?? null,
    entity: entity,
    label: ticket.label?.name ?? "",
    lastMessage: ticket.messages.at(-1) ?? null,
    priority: formatPriortiy(ticket.priority),
    ticketStatus: formatStatus(ticket.status),
    estimatedResolveTime: ticket.label?.timeToResolve ?? 0,
    waitingTime:
      lastMessage?.createdBy.type === "Caregiver"
        ? durationFormatter.relative(Duration.between(Instant.now(), lastMessage.createdAt))
        : null,
    onboardingStage: ticket.relatedCaregiver?.onboardingStageDetails?.name ?? "",
    unreadCount: unreadCount,
    team: ticket.relatedTeam.name,
  };
}

function formatPriortiy(
  priority: Messages["CommCenterTicket"]["priority"]
): TicketDataRow["priority"] {
  return match(priority)
    .with("HIGH", () => "High" as const)
    .with("MEDIUM", () => "Medium" as const)
    .with("LOW", () => "Low" as const)
    .exhaustive();
}

function formatStatus(
  status: Messages["CommCenterTicket"]["status"]
): TicketDataRow["ticketStatus"] {
  return match(status)
    .with("NEW", () => "New" as const)
    .with("IN PROGRESS", () => "In Progress" as const)
    .with("RESOLVED", () => "Resolved" as const)
    .exhaustive();
}

const ticketStatusTextToColor = {
  New: "blue",
  "In Progress": "yellow",
  Resolved: "green",
};

const priorityStatusTextToColor = {
  Low: "blue",
  Medium: "yellow",
  High: "red",
};

const paginationPageSizes = [10, 20, 30, 40, 50];

export function TicketsTable(props: { tickets: Messages["CommCenterTicket"][] }) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "lastMessage.createdAt", desc: true },
  ]);

  const data = React.useMemo(() => props.tickets.map(toDataRow), [props.tickets]);
  const { stateService } = useRouter();

  const columnHelper = createColumnHelper<TicketDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => <EntityCard entity={info.getValue()} />,
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("team", {
      cell: (info) => info.getValue(),
      header: () => <span>Team</span>,
    }),
    columnHelper.accessor("assignee", {
      cell: (info) => info.getValue(),
      header: () => <span>Assignee</span>,
    }),
    columnHelper.accessor("ticketStatus", {
      cell: (info) => (
        <TableCellBadge color={ticketStatusTextToColor[info.getValue()]} text={info.getValue()} />
      ),
      header: () => <span>Ticket Status</span>,
    }),
    columnHelper.accessor("lastMessage.createdAt", {
      id: "lastMessage.createdAt",
      sortingFn: sortingFns.instant,
      cell: (info) => <Text>{dateFormatter.toDateOrDateTime(info.getValue())}</Text>,
      header: () => <span>Updated at</span>,
    }),
    columnHelper.accessor("label", {
      cell: (info) => info.getValue(),
      header: () => <span>Label</span>,
    }),
    columnHelper.accessor("lastMessage", {
      cell: (info) => (
        <Flex gap={1}>
          <Tag
            rounded="full"
            bg="blue.500"
            color="white"
            mr={2}
            hidden={info.row.original.unreadCount === 0}
          >
            {info.row.original.unreadCount}
          </Tag>
          <span>{fmap(info.getValue(), getMessagePreview)}</span>
        </Flex>
      ),
      header: () => <span>Preview</span>,
    }),
    columnHelper.accessor("onboardingStage", {
      cell: (info) => info.getValue(),
      header: () => <span>Onboarding Stage</span>,
    }),
    columnHelper.accessor("priority", {
      cell: (info) => (
        <TableCellBadge color={priorityStatusTextToColor[info.getValue()]} text={info.getValue()} />
      ),
      header: () => <span>Priority</span>,
    }),
    columnHelper.accessor("estimatedResolveTime", {
      cell: (info) => (
        <Flex gap={1} align="center">
          <ClockIcon color="gray.400" />
          <Text>{info.getValue()} hours</Text>
        </Flex>
      ),
      header: () => <span>EST. resolve time</span>,
    }),
    columnHelper.accessor("waitingTime", {
      cell: (info) => (
        <Flex gap={1} align="center">
          <ClockIcon color="gray.400" />
          <Text>{info.getValue()?.formatted}</Text>
        </Flex>
      ),
      header: () => <span>Waiting time</span>,
      sortingFn: (a, b, col) => {
        const aVal = a.getValue(col) as { duration: Duration } | null;
        const bVal = b.getValue(col) as { duration: Duration } | null;

        if (aVal === null && bVal === null) {
          return 0;
        }

        if (aVal === null) {
          return 1;
        }

        if (bVal === null) {
          return -1;
        }

        return aVal.duration.compareTo(bVal.duration);
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<TicketDataRow, any>) => {
    return (
      <Th
        key={header.id}
        colSpan={header.colSpan}
        onClick={header.column.getToggleSortingHandler()}
        cursor="pointer"
        transition="100ms ease-in-out"
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex justifyContent="space-between" alignItems="center">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<TicketDataRow>) => {
    return (
      <Tr
        key={row.id}
        cursor="pointer"
        bg={row.original.unreadCount > 0 ? "blue.50" : undefined}
        borderBottomColor={row.original.unreadCount > 0 ? "blue.100" : undefined}
        fontWeight={row.original.unreadCount > 0 ? 600 : undefined}
        _hover={{ bg: "gray.50" }}
        onClick={() => {
          stateService.go("app.commcenter_ticket", { ticketId: row.original.id });
        }}
      >
        {row.getVisibleCells().map((cell) => {
          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
      <Flex direction="row" dir="ltr" alignItems="center" gap={8}>
        <Stack direction="row" dir="ltr" alignItems="center" spacing={1}>
          <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
            {"«"}
          </Button>
          <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {"‹"}
          </Button>
          <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {"›"}
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {"»"}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md">Page</Text>
          <Stack direction="row" alignItems="center" w="150px">
            <Text as="b" fontSize="md">
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Text>
            <Text>({table.getRowModel().rows.length} Rows)</Text>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md" w="100px">
            Go to page:
          </Text>
          <Input
            w="60px"
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Stack>
        <Menu>
          <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
          <MenuList>
            {paginationPageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize} onClick={() => table.setPageSize(pageSize)}>
                Show {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
}

export default TicketsTable;
