import moment from "moment";

//! @ngInject
export function certificationPeriodsCtrl(
    $scope,
    $rootScope,
    DatabaseApi,
    toaster,
    NgTableParams,
    $filter,
    mfModal,
    createDropdownFilter,
    agencyPatientStatusService,
) {
    let dropdownFilterManager;

    $scope.dropdownFilterEvents = {
        onSelectionChanged: () => $scope.applyFilters(),
    };

    const initialize = () => {
        $scope.patients = DatabaseApi.patients();
        $scope.certificationPeriodsGlobalFilter = { val: "" };
        $scope.filterBy = {
            daysLeft: undefined,
            startOfCareDate: { startDate: null, endDate: null },
            certificationStartDate: { startDate: null, endDate: null },
            certificationEndDate: { startDate: null, endDate: null },
            dropdowns: [
                {
                    title: "Status",
                    entityPath: ["status", "label"],
                    options: [],
                    values: [
                        { id: "Not Completed", label: "Not Completed" }
                    ]
                },
                {
                    title: "Patient Status",
                    entityPath: ["patient", "status", "label"],
                    options: [],
                    values: [],
                },
                {
                    title: "Zip Code",
                    entityPath: ["patient", "zipCode"],
                    options: [],
                    values: [],
                    settings: {
                        enableSearch: true,
                        scrollable: true,
                        scrollableHeight: 400
                    }
                },
            ],
        };

        dropdownFilterManager = createDropdownFilter({
            dropdowns: $scope.filterBy.dropdowns
        });

        $scope.dateRangeOptions = {
            ranges: {
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 14 Days": [moment().subtract(13, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "Last Year": [
                    moment().subtract(1, "year").startOf("year"),
                    moment().subtract(1, "year").endOf("year"),
                ],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                ],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
            },
            alwaysShowCalendars: true,
            applyClass: "btn-primary",
            locale: {
                direction: "ltr date-range-picker-v2",
                format: "D MMM YY"
            },
            opens: "left",
            autoApply: true,
            minDate: new Date("2001-01-01"),
            eventHandlers: {
                "apply.daterangepicker": $scope.applyFilters
            },
        };

        if ($scope.patients && $scope.patients.$$state === undefined) {
            fetchData();
        }
    };

    const fetchData = () => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/certification_periods"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        DatabaseApi.get(url).then((res) => {
            $scope.certificationPeriods = res.data.certificationPeriods;
            $scope.certificationPeriods.forEach(row => {
                const patient = $scope.patients[row.patientId];
                row.patient = {
                    displayName: patient.displayName,
                    status: {
                        label: $filter("capitalize")(patient.status),
                        class: agencyPatientStatusService.getStatusClass(patient.status)
                    },
                    zipCode: patient?.address?.components?.formatedAddressDetails?.zip5,
                    addressText: patient?.address?.text,
                };
                const endDateMoment = moment(row.endDate);
                const nowMoment = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
                setRowStatus(row);
                if (!nowMoment.isAfter(endDateMoment)) {
                    row.daysLeft = endDateMoment.diff(nowMoment, "days");
                } else {
                    row.daysLeft = 0 - nowMoment.diff(endDateMoment, "days");
                }

                if (row.isCompleted) {
                    row.daysLeft = null;
                }
            });
            dropdownFilterManager.setOptionsFromLocalData($scope.certificationPeriods);
            $scope.applyFilters();
        })
        .catch((err) => {
            toaster.pop("error", "Oops... Something went wrong", "Failed to fetch certification periods");
        });
    };

    const setRowStatus = (row) => {
        row.status = {
            label: row.isCompleted ? "Completed" : "Not Completed",
            color: row.isCompleted ? "blue" : "grey",
        };
    };

    const initCertificationPeriodsTable = (certificationPeriods) => {
        const options = {
            count: 25
        };
        $scope.certificationPeriodsTable = new NgTableParams(options, {
            dataset: certificationPeriods,
        });
    };

    $scope.exportTable = () => {
        const tableData = $scope.certificationPeriodsTable.data;
        if (tableData.length <= 0) {
            toaster.pop('warning', 'Nothing to export', 'The table is empty');
            return;
        }

        $scope.loadingCSV = true;

        const rows = [];
        const titles = [
            'Patient Name',
            'Zip Code',
            'Address',
            'Patient Status',
            'Start of Care Date',
            'Certification Start Date',
            'Certification End Date',
            'Days Left',
        ];
    
        rows.push(titles);

        tableData.forEach(dataRow => {
          const csvRow = [];
    
          titles.forEach(function (title) {
            if (title === 'Patient Name')  + csvRow.push('"' + (dataRow.patient.displayName || '').replace(/(\r\n|\n|\r)/gm, "") + '"');
            else if (title === 'Zip Code') csvRow.push(dataRow.patient.zipCode);
            else if (title === 'Address') csvRow.push('"' + dataRow.patient.addressText + '"');
            else if (title === 'Patient Status') csvRow.push(dataRow.patient.status.label);
            else if (title === 'Start of Care Date') csvRow.push($filter("mfShortDate")(dataRow.patientStartOfCare));
            else if (title === 'Certification Start Date') csvRow.push($filter("mfShortDate")(dataRow.startDate));
            else if (title === 'Certification End Date') csvRow.push($filter("mfShortDate")(dataRow.endDate));
            else if (title === 'Days Left') csvRow.push(dataRow.daysLeft || '');
          });
    
          rows.push(csvRow);
        });
    
        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
    
        const universalBOM = "\uFEFF";
        const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `medflyt-export-certification-periods-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`);
        document.body.appendChild(link);
    
        link.click();
        $scope.loadingCSV = false;
    };

    $scope.applyCertificationPeriodsGlobalSearch = (term) => {
        const filter = { $: term };
        if ($scope.certificationPeriodsTable) {
            angular.extend($scope.certificationPeriodsTable.filter(), filter);
        }
    };

    const filterByMethods = {
        startOfCareDate: (row, {startDate, endDate}) => moment(row.startOfCare).isBetween(startDate, endDate, undefined, "[]"),
        certificationStartDate: (row, {startDate, endDate}) => moment(row.startDate).isBetween(startDate, endDate, undefined, "[]"),
        certificationEndDate: (row, {startDate, endDate}) => moment(row.endDate).isBetween(startDate, endDate, undefined, "[]"),
        daysLeft: (row, daysLeft) => row.daysLeft && row.daysLeft <= daysLeft,
    };

    $scope.applyFilters = () => {
        let filters = [];

        if ($scope.filterBy.startOfCareDate && $scope.filterBy.startOfCareDate.startDate && $scope.filterBy.startOfCareDate.endDate) {
            filters.push((row) => filterByMethods.startOfCareDate(row, $scope.filterBy.startOfCareDate));
        }

        if ($scope.filterBy.certificationStartDate && $scope.filterBy.certificationStartDate.startDate && $scope.filterBy.certificationStartDate.endDate) {
            filters.push((row) => filterByMethods.certificationStartDate(row, $scope.filterBy.certificationStartDate));
        }

        if ($scope.filterBy.certificationEndDate && $scope.filterBy.certificationEndDate.startDate && $scope.filterBy.certificationEndDate.endDate) {
            filters.push((row) => filterByMethods.certificationEndDate(row, $scope.filterBy.certificationEndDate));
        }

        if (typeof $scope.filterBy.daysLeft === "number") {
            filters.push((row) => filterByMethods.daysLeft(row, $scope.filterBy.daysLeft));
        }

        filters.push((row) => dropdownFilterManager.filter(row));

        let filteredData = [];
        Object.assign(filteredData, $scope.certificationPeriods);
        if (filters.length > 0) {
            filteredData = filteredData.filter((row) => {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](row);
                }
                return isFiltered;
            });
        }

        initCertificationPeriodsTable(filteredData);
    };

    $scope.handleClearDaysLeftFilter = () => {
        $scope.filterBy.daysLeft = undefined;
        $scope.applyFilters();
    };

    $scope.resetFilterDateRange = (field) => {
        $scope.filterBy[field] = { startDate: null, endDate: null};
        $scope.applyFilters();
    };

    $scope.markAsCompleted = (certificationPeriod) => {
        const modal = mfModal.create({
            subject: "Mark as completed?",
            message: "This certification period will be marked as completed. Are you sure?",
            cancelLabel: "Cancel",
            confirmLabel: "Confirm",
            preventBackdropClose: true,
            onConfirm: () => {
                modal.setLoading(true);

                updateCertificationPeriod(certificationPeriod, { isCompleted: true }).then(() => {
                    certificationPeriod.isCompleted = true;
                    setRowStatus(certificationPeriod);
                    toaster.pop('success', 'Successfully marked as completed');
                    modal.close();
                }, () => {
                    toaster.pop('error', 'Failed to mark as completed');
                });
            },
        });
    };

    function updateCertificationPeriod(certificationPeriod, params) {
        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods/:certificationPeriodId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", certificationPeriod.patientId)
            .replace(":certificationPeriodId", certificationPeriod.id);

        return DatabaseApi.put(url, {
            ...certificationPeriod,
            ...params
        });
    }

    $rootScope.$on("got_patients_data", (event) => {
        $scope.patients = DatabaseApi.patients();
        fetchData();
    });

    initialize();
};
