import angular from "angular";
import { ExtendedCustomPOCItem } from "../custom-plan-of-care-type/custom-plan-of-care-type.component";
import "./custom-plan-of-care-type-item.component.scss";
interface Bindings {
  item: ExtendedCustomPOCItem;
  itemIndex: number;
  isFromTemplate: boolean;
  categories: { id: number; label: string }[];
  onItemDelete: () => (idx: number) => void;
}

interface ComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof Bindings, string>;
  $name: string;
}

//! @ngInject
class CustomPlanOfCareTypeItemCtrl implements ng.IComponentController, Bindings {
  // ---- bindings ----- //
  item!: ExtendedCustomPOCItem;
  itemIndex!: number;
  isFromTemplate!: boolean;
  categories!: { id: number; label: string }[];
  onItemUpdate!: () => (item: ExtendedCustomPOCItem, idx: number) => void;
  onItemDelete!: () => (idx: number) => void;
  // ------------------- //

  private selectedCategory: { id: number | null } = { id: null }
  public form!: ng.IFormController;
  constructor(private toaster: toaster.IToasterService) { }

  categoryExtraSettings = {
    styleActive: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1,
    closeOnSelect: true,
    showCheckAll: false,
    showUncheckAll: false,
    enableSearch: true,
  };

  categoryEvents = {
    onItemSelect: (selectedCategory: any) => {
      const category = this.categories.find((c) => c.id === selectedCategory.id);
      if (category === undefined) {
        return this.toaster.pop("warning", "Cannot set relevant category");
      }
      this.item.section = category.label;
    },
    onItemDeselect: () => {
      return this.toaster.pop("warning", "Task must have a category");
    },
  };

  $onInit = () => {
    if (this.isFromTemplate) {
      const categoryId = this.categories.find(c => c.label === this.item.section)?.id;
      if (categoryId === undefined) {
        return this.toaster.pop("warning", "Cannot init task with relevant category");
      }
      this.selectedCategory.id = categoryId;
    }
  };

  handleClickDeleteItem = () => {
    if (this.onItemDelete()) {
      this.onItemDelete()(this.itemIndex);
    }
  };

  handleSaveItemState = () => {
    this.validateItem();
  };

  validateItem = () => {
    if (this.form.$invalid || this.selectedCategory.id === null) {
      this.item.state = "ERROR";
      return;
    }
    this.item.state = "VALID";
  };
}

export const customPlanOfCareTypeItemComponent: ComponentOptions = {
  $name: "customPlanOfCareTypeItem",
  templateUrl:
    "admin/modules/plan-of-care/components/custom-plan-of-care-type-item/custom-plan-of-care-type-item.component.html",
  controller: CustomPlanOfCareTypeItemCtrl,
  controllerAs: "ctrl",
  bindings: {
    item: "<",
    itemIndex: "<",
    isFromTemplate: "<",
    categories: "<",
    onItemDelete: "&",
  },
};
