//! @ngInject
export function cityTaxCodeService($rootScope, $http, Consts) {
    /* Decalare factory return object */
    const service = {};

    service.getCityTaxCodes = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/city_tax_codes`).then(res => {
            return res.data.codes;
      })
    }

    service.createCityTaxCode = (cityTaxCodeCreateParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/city_tax_code`, cityTaxCodeCreateParams);
    }

    service.editCityTaxCode = (cityTaxCodeId, cityTaxCodeEditParams) => {
      return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/city_tax_code/${cityTaxCodeId}`, cityTaxCodeEditParams);
    }

    return service;
};