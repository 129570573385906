import { Caregiver } from "../../../../scripts/messages/caregiver";
import { CaregiverId, VisitBroadcastId, VisitInstanceId } from "../../../../scripts/messages/ids";
import { VisitInstance } from "../../../../scripts/messages/visit";
import "./caregivers-visits-assignment-requests-table.component.scss";
import {
    CaregiverInShift,
    CaregiverVisitsRequestsTableBindings,
} from "./caregivers-visits-assignment-requests-table.types";
import {
  getPatternVisitShifts,
  getSinglesVisitShifts, mapCaregiversIntoCaregiversInShifts,
  mapVisitInstancesTimes
} from "../visit-table-common.utils";
import { Shift, VisitAssignmentRequest } from "../visit-table-common.types";
import { VisitBroadcastService } from "../../visitBroadcast.service";

//! @ngInject
class caregiversVisitsAssignmentRequestsTableCtrl
  implements ng.IComponentController, CaregiverVisitsRequestsTableBindings
{
  visitAssignmentRequests!: VisitAssignmentRequest[];
  visitBroadcastType!: "PATTERN" | "SINGLES";
  visitInstances!: VisitInstance[];
  visitBroadcastId!: VisitBroadcastId;
  startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
  stopFlyToCaregiver!: () => void;
  updateEngagements!: () => void;

  shifts: Shift[];
  caregivers: CaregiverInShift[];

  constructor(
      private $filter: ng.IFilterService,
      private $rootScope: ng.IRootScopeService,
      private toaster: toaster.IToasterService,
      private mfModal: any,
      private visitBroadcastService: VisitBroadcastService
  ) {
    this.shifts = [];
    this.caregivers = [];
  }

  $onInit() {
    this.visitInstances = mapVisitInstancesTimes(this.visitInstances);
  }

  $onChanges(changesObj) {
    if ("visitInstances" in changesObj) {
      this.visitInstances = mapVisitInstancesTimes(this.visitInstances);
    }

    if ("visitAssignmentRequests" in changesObj) {
      const caregiverVisitRequests = changesObj.visitAssignmentRequests.currentValue;

      if (caregiverVisitRequests.length > 0) {
        this.setShiftsOnEngagementDataChange();
        this.caregivers = mapCaregiversIntoCaregiversInShifts(
          this.shifts,
          [],
          this.visitAssignmentRequests
        );
      } else {
        // Without engagements there's no point in having shifts.
        this.shifts = [];
      }
    }
  }

  mapCaregiversToInstancesIds(assignmentRequests: VisitAssignmentRequest[]) {
    const caregiversByInstanceIdMap = new Map<VisitInstanceId, Caregiver[]>();

    assignmentRequests.forEach((engagement) => {
      engagement.assignedInstances.forEach(({ visitInstanceId }) => {
        const caregiversByShifts = caregiversByInstanceIdMap.get(visitInstanceId);

        if (caregiversByShifts === undefined) {
          caregiversByInstanceIdMap.set(visitInstanceId, [engagement.caregiver]);
        } else {
          caregiversByShifts.push(engagement.caregiver);
          caregiversByInstanceIdMap.set(visitInstanceId, caregiversByShifts);
        }
      });
    });

    return caregiversByInstanceIdMap;
  }

  setShiftsOnEngagementDataChange() {
    const caregiversByInstanceIdMap = this.mapCaregiversToInstancesIds(this.visitAssignmentRequests);

    if (this.visitBroadcastType === "PATTERN") {
      this.shifts = getPatternVisitShifts(this.visitInstances, caregiversByInstanceIdMap);
    } else {
      this.shifts = getSinglesVisitShifts(this.visitInstances, caregiversByInstanceIdMap, this.$filter);
    }
  }

  getCaregiverAssignedInstance(shift: Shift, caregiverId: CaregiverId) {
    for (const { caregiver, assignedInstances } of this.visitAssignmentRequests) {
      if (caregiverId === caregiver.id) {
        return assignedInstances.find(
          ({ visitInstanceId }) => shift.type === "PATTERN"
            ? shift.visitInstancesIds.indexOf(visitInstanceId) >= 0
            : shift.visitInstanceId = visitInstanceId
        )
      }
    }
  }

  getCaregiverVisitInstancesIds(caregiverId) {
    for (const { caregiver, assignedInstances } of this.visitAssignmentRequests) {
      if (caregiverId === caregiver.id) {
        return assignedInstances.map(({ visitInstanceId }) => visitInstanceId);
      }
    }
    return [];
  }

  assignToCaregiver(caregiverId) {
    this.visitBroadcastService
      .confirmCaregiverAssignmentRequest(this.visitBroadcastId, caregiverId)
      .then(
        (res) => {
          if (res.data.assignWithIncreasedCaregiverOvertime) {
            this.toaster.pop({
              type: "warning",
              title: "Warning",
              body: `Successfully assigned caregiver with increased caregiver overtime`
            });
          } else {
            this.toaster.pop("success", "Successfully assigned caregiver");
          }
          // this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
          //   this.$rootScope.$broadcast("refresh_visits");
          //   this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });

        },
        (err) => {
          let errorMessage = "Failed to assign caregiver";
          if (err.status === 403) {
            errorMessage = "Not permitted to increase caregiver overtime.";
          }
          this.toaster.pop("error", "Oops...", errorMessage);
        }
      );
  }

  openShouldAssignModal(caregiverId: CaregiverId) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message: "Are you sure you want to confirm manually?",
      cancelLabel: "I changed my mind",
      confirmLabel: "Confirm assignment",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: async () => {
        await this.assignToCaregiver(caregiverId);
        modal.close();
      },
    });
  }

  rejectAssignmentToCaregiver(caregiverId) {
    this.visitBroadcastService
      .rejectCaregiverAssignmentRequest(this.visitBroadcastId, caregiverId)
      .then(
        () => {
          this.toaster.pop("success", "Successfully rejected caregiver's request.");
          // this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
          //   this.$rootScope.$broadcast("refresh_visits");
          //   this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
        },
        (err) => {
          this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.", err);
        }
      );
  }

  openShouldRejectModal(caregiverId: CaregiverId) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message: "Are you sure you want to reject assignment?",
      cancelLabel: "I changed my mind",
      confirmLabel: "Confirm",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: async () => {
        await this.rejectAssignmentToCaregiver(caregiverId);
        modal.close();
      },
    });
  }

}

export const caregiversVisitsAssignmentRequestsTable = {
  templateUrl:
    "admin/modules/visit-broadcast/components/caregivers-visits-assignment-requests-table/caregivers-visits-assignment-requests-table.component.html",
  controller: caregiversVisitsAssignmentRequestsTableCtrl,
  controllerAs: "ctrl",
  bindings: {
    visitAssignmentRequests: "<",
    visitBroadcastType: "<",
    visitInstances: "<",
    visitBroadcastId: "<",
    updateEngagements: "<",
    startFlyToCaregiver: "<",
    stopFlyToCaregiver: "<"
  },
};
