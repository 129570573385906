import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import Page from "./Page";

const LoadingPage = () => {
  return (
    <Page>
      <Center flexDirection="column" gap={8} flex={1} color="gray.500">
        <Spinner size="xl" />
      </Center>
    </Page>
  );
};

export default LoadingPage;
