import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import Page from "./Page";

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorPage = (props: Props) => {
  const [showErrorCount, setShowErrorCount] = React.useState(0);
  return (
    <Page>
      <Flex direction="column" m="auto" align="center" gap={8}>
        <Heading size="2xl" onClick={() => setShowErrorCount((prev) => prev + 1)}>
          😬
        </Heading>
        <Heading size="md">Something went wrong</Heading>

        {showErrorCount > 3 && (
          <Box maxW="5xl">
            <pre>
              {props.error.message}
              {props.error.stack}
            </pre>
          </Box>
        )}

        <Box>
          <Button onClick={() => props.resetErrorBoundary()}>Try again</Button>
        </Box>
      </Flex>
    </Page>
  );
};

export default ErrorPage;
