//! @ngInject
export function diagnosisCodeSetupModalCtrl($scope, data, $rootScope, $uibModalInstance, DatabaseApi, toaster, dateUtils) {

    $scope.form = {};
    $scope.calendarPopups = {};
    
    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    }

    $scope.icdCodeAutoCompleteOptions = {
        minimumChars: 1,
        data: function (searchText) {
            return DatabaseApi.get('agencies/' + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + '/icd_10_codes/auto_complete?search_string=' + searchText)
                .then(function (response) {
                    return response.data.codes
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not get icd codes");
                });
        },
        renderItem: function (item) {
            var icdText = item.formattedDxCode + ' - ' + item.shortDesc;
            return {
                value: item.shortDesc,
                label: "<p class='auto-complete'>" + icdText + "</p>"
            };
        },
        itemSelected: function (e) {
            $scope.selectedIcdCodeID = e.item.id;
        }

    }

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }

        var diagnosisCodeRequest = prepareDefaultDiagnosisCodeRequest();
        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + '/default_diagnosis_codes', diagnosisCodeRequest)
                .then(function (res) {
                    console.log(res);
                    toaster.pop('success', "Default diagnosis code saved");
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change default diagnosis code settings")
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + '/default_diagnosis_codes', diagnosisCodeRequest).then(function (res) {
                console.log(res);
                toaster.pop('success', "Default diagnosis code saved");
                $scope.getDefaultDiagnosisCodes();
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add default diagnosis code");
            });
        }
    };

    function prepareDefaultDiagnosisCodeRequest() {
        var diagnosisCodeRequest = angular.copy($scope.data);
        diagnosisCodeRequest.startDate = dateUtils.dateToLocalDate(diagnosisCodeRequest.startDate);
        diagnosisCodeRequest.endDate = dateUtils.dateToLocalDate(diagnosisCodeRequest.endDate);
        diagnosisCodeRequest.icdCode = $scope.selectedIcdCodeID;
        diagnosisCodeRequest.contractTypeId = $scope.contractId;
        return diagnosisCodeRequest;
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };
};
