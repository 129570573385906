import { webappChannel } from "@medflyt/webapp-react/src/core";
import { AngularJSPayload, EventFromReact } from "@medflyt/webapp-react/src/core/webapp-channel";
import angular from "angular";
import { angularize, getService } from "react-in-angularjs";
import CommCenterChatWrapper from "@medflyt/webapp-react/src/modules/communication/components/CommCenterChatWrapper";
import { withApp } from "@medflyt/webapp-react/src/utils";

export function registerReactAdapterModule() {
  registerReactAdapterService();

  angularize(
    withApp(CommCenterChatWrapper),
    "reactCommCenterChatWrapper",
    angular.module("dashboard"),
    {
      patientId: "<",
      caregiverId: "<",
      onClose: "&",
    }
  );

  setTimeout(initWebappChannelPayload);
}

function registerReactAdapterService() {
  //! @ngInject
  class ReactAdapterService {
    constructor(
      public $rootScope: angular.IRootScopeService,
      public Storage: any,
      public $state: any
    ) {
      this.listenForReactEvents();
    }

    listenForReactEvents() {
      window.addEventListener("from-webapp-react", ($event) => {
        const event = ($event as CustomEvent).detail as EventFromReact
        if (event.type === "navigate") {
          switch (event.payload.entity) {
            case "Caregiver":
              return this.$rootScope.openCaregiverModal(event.payload.id as any);
            case "Patient":
              return this.$rootScope.openPatientModal(event.payload.id as any);
          }
        }
      });
    }

    getAuthInfo(): AngularJSPayload["AUTH_SET"] {
      const authFromStorage = this.Storage.getObject("user");

      if (Object.keys(authFromStorage).length === 0) {
        return null;
      }

      return {
        userId: authFromStorage.userId,
        agency: authFromStorage.agency,
        agencyMember: authFromStorage.agencyMember,
        authToken: authFromStorage.authToken,
        refreshToken: authFromStorage.refreshToken,
        permissions: authFromStorage.permissions,
      };
    }

    init() {
      webappChannel.setPayload("AUTH_SET", this.getAuthInfo());
    }
  }

  angular.module("dashboard").service("reactAdapterService", ReactAdapterService);
}

export function initWebappChannelPayload() {
  const service = getService("reactAdapterService");

  service.init();
}

export function dispatchSocketMessageToWebappChannel(event: string, payload: string) {
  webappChannel.dispatch("SOCKET_EVENT_RECEIVED", {
    type: event as any,
    payload: safeJsonParse(payload),
  });
}

function safeJsonParse(json: string) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
}
