import { Instant } from "@js-joda/core";
import { Row } from "@tanstack/react-table";

export const sortingFns = {
  instant: <TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string): number => {
    const rowADate: Instant | null | undefined = rowA.getValue(columnId);
    const rowBDate: Instant | null | undefined = rowB.getValue(columnId);

    if (rowADate === null || rowADate === undefined) {
      return -1;
    }

    if (rowBDate === null || rowBDate === undefined) {
      return 1;
    }

    return rowADate.compareTo(rowBDate);
  },
};
