/* @ngInject */
export function noteService($rootScope, $http, Consts) {
    /* Decalare factory return object */
    const service = {};

    // ============ Note Subjects ================

    /**
     * Edit agency note subjects
     */
    service.editAgencyNoteSubject = (agencyId, agencyMemberId, noteSubjectId, noteSubjectEditParams) => {
      return $http.patch(`${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/note_subject/${noteSubjectId}`, noteSubjectEditParams);
    };

    /**
     * Create agency note subjects
     */
    service.createAgencyNoteSubject = (agencyId, agencyMemberId, noteSubjectCreateParams) => {
      return $http.post(`${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/note_subject`, noteSubjectCreateParams);
    };


    // ================= Notes Report ================
    service.editStatus = (noteId, status, agencyId, agencyMemberId) => {
      return $http.put(`${Consts.api}agencies/${agencyId}/agency_member/${agencyMemberId}/notes/${noteId}/status`, status);
    }
    
    service.editFollowUp = (noteId, followUp, agencyId, agencyMemberId) => {
      return $http.put(`${Consts.api}agencies/${agencyId}/agency_member/${agencyMemberId}/notes/${noteId}/follow_up`, followUp);
    }

    service.deleteNote = (noteId, agencyId, agencyMemberId) => {
      return $http.delete(`${Consts.api}agencies/${agencyId}/agency_member/${agencyMemberId}/notes/${noteId}`);
    }

    service.getNoteDetails = (noteId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/${noteId}`).then(res => {
        return res.data;
      })
    }

    service.getPatientNotes = (patientId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient/${patientId}/notes`).then(res => {
        return res.data.list;
      })
    }

    service.getCaregiverNotes = (caregiverId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregiver/${caregiverId}/notes`).then(res => {
        return res.data.list;
      })
    }

    service.getNoteComments = (noteId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/${noteId}/comments`).then(res => {
        return res.data.list;
      })
    }
    
    service.deleteComment = (noteId, commentId, agencyId, agencyMemberId) => {
      return $http.delete(`${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/notes/${noteId}/comment/${commentId}`);
    }

    service.createComment = (noteId, commentCreateParams, agencyId, agencyMemberId) => {
      return $http.post(`${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/notes/${noteId}/comment`, commentCreateParams);
    }

    service.getVisitInstanceNotes = (visitInstanceId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_instance/${visitInstanceId}/notes`).then(res => {
        return res.data.notes;
      })
    }

    service.createNote = (noteCreateParams) => {
        return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes`, noteCreateParams);
    }

    service.createBulkVisitInstanceNotes = (noteCreateParams) => {
        return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/bulk_visit_instance_notes`, noteCreateParams);
    }

    service.getNoteTitle = (noteId, noteType) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/${noteId}/title?noteType=${noteType}`).then(res => {
        return res.data;
      });
    }

    service.createNoteFile = (noteId, noteFileParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/${noteId}/files`, noteFileParams)
        .then(res => {
        return res.data;
      });
    }

    return service;
};