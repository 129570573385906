//! @ngInject
export function visitPatientDocumentsSetupModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, DatabaseApi, toaster, FormService){

   $scope.visitPatientDocumentAdded = '';
   $scope.existedPatientDocument = undefined;
   $scope.allPatientDocumentsForAgency = [];
   $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/${$rootScope.agencyMemberId}/patient_document_types`).then(function (res) {
    $scope.allPatientDocumentsForAgency = res.data.documents;
  });
   
   $scope.visitLabelChange  = ()=>{
       console.log($scope.visitPatientDocumentAdded);
   }
   
   $scope.existedPatientDocumentChange = ()=>{
    const filtredDocuemnt = $scope.allPatientDocumentsForAgency.filter(x => x.id === parseInt($scope.existedPatientDocument,10));
    console.log(filtredDocuemnt[0].title);
   
   }
   
   

    $scope.submit = function() {
        let patientDocumentToAdd = {};
        if($scope.existedPatientDocument !== undefined){
            patientDocumentToAdd = $scope.allPatientDocumentsForAgency.filter(x => x.id === parseInt($scope.existedPatientDocument,10))[0];
        }else if ($scope.visitPatientDocumentAdded.length > 0){
            patientDocumentToAdd.id = -1;
            patientDocumentToAdd.name = $scope.visitPatientDocumentAdded;  
        }else if($scope.allPatientDocumentsForAgency.length === 1){
            patientDocumentToAdd = $scope.allPatientDocumentsForAgency[0];
        }
        $uibModalInstance.close(patientDocumentToAdd);
    };
};
