import { LocalDate, nativeJs } from "js-joda";

//! @ngInject
export function newPatientVacationModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {
    $scope.isLoading = false;
    $scope.conflictingVisits = [];
    $scope.datesRange = {
        from: new Date($scope.$resolve.selectedDate),
        to: new Date($scope.$resolve.selectedDate)
    };

    function initialize() {
        // updateVisitConflictError();
        $scope.$watch("datesRange.from", updateConflictingVisits);
        $scope.$watch("datesRange.to", updateConflictingVisits);
    }

    function updateConflictingVisits() {
        if ($scope.datesRange.from && $scope.datesRange.to) {
            getConflictingVisitsOnDateRange($scope.datesRange.from, $scope.datesRange.to);
        }
    }

    /**
     * @param {Date} from 
     * @param {Date} to 
     */
    function getConflictingVisitsOnDateRange(from, to) {
        const fromJoda = LocalDate.from(nativeJs(from));
        const toJoda = LocalDate.from(nativeJs(to));
        let url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visit_instances"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patientId);

        url += `?from=${fromJoda}&to=${toJoda}`;

        DatabaseApi.get(url).then(({data}) => {
            $scope.conflictingVisits = data.visitInstances.filter(x => x.removedAt === null);
        });
    }

    $scope.handleClickSetVacation = () => {
        if (!$scope.datesRange.from || !$scope.datesRange.to) {
            toaster.pop("error", "Please choose dates range and try again");
            return;
        }
        $scope.isLoading = true;
        $scope.conflictingVisits = [];
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_vacations"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.$resolve.patientId);
        const body = {
            startTime: LocalDate.from(nativeJs($scope.datesRange.from)),
            endTime: LocalDate.from(nativeJs($scope.datesRange.to))
        };
        DatabaseApi.post(url, body)
            .then((res) => {
                toaster.pop("success", "Vacation set");
                $scope.closeModal("VACATION_CREATED")
            })
            .catch((err) => {
                console.error(err);
                toaster.pop("error", "Something went wrong");
            })
            .finally(() => $scope.isLoading = false);
    };

    $scope.closeModal = (data = 'ok') => {
        $uibModalInstance.close(data);
    };

    initialize();
};
