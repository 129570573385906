import moment from "moment";

//! @ngInject
export function exportProfileService ($rootScope, DatabaseApi, toaster) { return {
        getCalendarDatesRange(range) {
            let fromDate, toDate;
            switch (range) {
                case "last7Days": {
                    fromDate = moment().add(-1, "week").toDate();
                    toDate = moment().toDate();
                    break;
                }
                case "currentWeek": {
                    fromDate = moment().startOf("week").toDate();
                    toDate = moment().endOf("week").toDate();
                    break;
                }
                case "previousWeek": {
                    fromDate = moment().add(-1, "week").startOf("week").toDate();
                    toDate = moment().add(-1, "week").endOf("week").toDate();
                    break;
                }
                case "last31Days": {
                    fromDate = moment().add(-31, "days").toDate();
                    toDate = moment().toDate();
                    break;
                }
                case "currentMonth": {
                    fromDate = moment().startOf("month").toDate();
                    toDate = moment().endOf("month").toDate();
                    break;
                }
                case "previousMonth": {
                    fromDate = moment().add(-1, "month").startOf("month").toDate();
                    toDate = moment().add(-1, "month").endOf("month").toDate();
                    break;
                }
                default: {
                    console.warn(`exportProfileService.getCalendarDatesRange() Implement range: ${range}`);
                }
            }

            return { fromDate, toDate };
        },
        exportPatientProfilePDF(agencyId, agencyMemberId, patientId, payload) {
            const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/export-profile-pdf`
                .replace(":agencyId", agencyId)
                .replace(":agencyMemberId", agencyMemberId)
                .replace(":patientId", patientId);

            return this.exportPDF(url, payload);
        },
        exportCaregiverProfilePDF(agencyId, agencyMemberId, caregiverId, payload) {
            const url = `agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/export-profile-pdf`
                .replace(":agencyId", agencyId)
                .replace(":agencyMemberId", agencyMemberId)
                .replace(":caregiverId", caregiverId);

            return this.exportPDF(url, payload);
        },
        exportPDF(url, payload = { sectionsIncluded: null, calendarPeriod: null }) {
            return DatabaseApi.post(url, payload)
                .then(({ data }) => window.open(data.signedUrl, "_blank"))
                .catch(() => toaster.pop("error", "Something went wrong", "Failed to export profile"));
        }
    } };
