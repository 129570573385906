import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";

export const entityCustomFieldComponent = {
  templateUrl: "admin/views/entity-custom-field-component.html",
  bindings: {
    data: "<",
    disabled: "<",
    showEdit: "<",
    entityType: "<",
    customField: "<",
    onFieldChange: "&",
    onFieldRemoved: "&",
  },
  //! @ngInject
  controller: function ($rootScope, DatabaseApi) {
    this.initOptions = () => {
      this.caregiversMap = DatabaseApi.caregivers();
      this.patientsMap = DatabaseApi.patients();

      if (this.data) {
        switch (this.customField.fieldType) {
          case "TEXT":
          case "NUMBER":
          case "ADDRESS":
          case "PHONE_NUMBER":
          case "ENUM":
          case "BOOLEAN":
            this.customField.value = this.data.value;
            break;
          case "DATE":
            this.customField.date = new Date(
              moment(this.data.value).format("MM/DD/YYYY")
            );
            break;
          case "DATE_RANGE":
            if (!this.customField.range) {
              this.customField.range = {};
            }
            this.customField.from = new Date(
              moment(this.data.from).format("MM/DD/YYYY")
            );
            this.customField.to = new Date(
              moment(this.data.to).format("MM/DD/YYYY")
            );
            this.customField.range.startDate = this.customField.from;
            this.customField.range.endDate = this.customField.to;
            break;
          case "CAREGIVER":
            this.customField.value = this.caregiversMap[this.data.value];
            break;
          case "PATIENT":
            this.customField.value = this.patientsMap[this.data.value];
            break;

          default:
            break;
        }
      }
    };

    this.$onInit = () => {
      this.initOptions();
    };

    this.handleChange = (value) => {
      if (value) {
        this.onFieldChange({
          customField: { ...this.customField, value: value },
        });
      } else {
        if (
          !this.customField.value &&
          (!this.customField.from && !this.customField.to)
        ) {
          // Field Removed
          this.onFieldRemoved({ customField: this.customField });
        } else {
          this.onFieldChange({ customField: this.customField });
        }
      }
    };

    this.handleCaregiverPatientSelection = (c) => {
      this.customField.value = c;
      this.handleChange(c.id);
    };

    this.handleCaregiverPatientDeselect = () => {
      this.customField.value = undefined;
      this.handleChange();
    };

    this.handleRangeChange = () => {
      this.customField.from = LocalDate.from(
        nativeJs(moment(this.customField.range.startDate))
      ).toString();
      this.customField.to = LocalDate.from(
        nativeJs(moment(this.customField.range.endDate))
      ).toString();
      this.handleChange();
    };

    this.handleDatePickerChange = () => {
      this.customField.value = LocalDate.from(
        nativeJs(moment(this.customField.date))
      ).toString();
      this.handleChange();
    };

    this.handleAddressChange = () => {
      this.customField.value = this.addressComponent.formatted_address;
      this.handleChange();
    };

    // Date range picker extras
    this.dateRangePickerOptions = {
      eventHandlers: {
        "apply.daterangepicker": this.handleRangeChange,
      },
    };

    // Date picker extras
    this.isDatePickerOpened = false;
    this.altInputFormats = ["MM/dd/yyyy"];
    this.datepickerOptions = {
      minDate: new Date(),
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $rootScope.$on("got_data", () => {
      this.initOptions();
    });
  },
};
