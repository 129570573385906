//! @ngInject
export function PatientModalService($rootScope, DatabaseApi, wildcard, mfModal, $q) { return {
    /**
     * Get all visits of a patient
     */
    getPatientVisits(patientId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits",
        agencyId,
        agencyMemberId,
        patientId
      );

      return DatabaseApi.get(endpoint).then((res) => res.data.visits);
    },
    getPatientContractBasic(patientId, includeGeneral = false) {
      const { agencyId, agencyMemberId } = $rootScope;
      const query = includeGeneral === true ? '?include_general=true' : '';

      const url = wildcard(
        `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/contracts_basic${query}`,
        agencyId,
        agencyMemberId,
        patientId
      );
      
      return DatabaseApi.get(url).then(res => res.data.contracts);
    },
    getPatientContractExtended(patientId) {
      const { agencyId } = $rootScope;

      const url = wildcard(
        "agencies/:agencyId/patients/:patientId/contracts_extended",
        agencyId,
        patientId
      );
      
      return DatabaseApi.get(url).then(res => res.data.contractsAndTreatments);
    },
    getPatientPhonenumbers(patientId) {
        const { agencyId, agencyMemberId } = $rootScope;

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/phonenumbers"
            .replace(":agencyId", agencyId)
            .replace(":agencyMemberId", agencyMemberId)
            .replace(":patientId", patientId);

        return DatabaseApi.get(url).then(({ data }) => data.phoneNumbers);
    },
    async checkForDuplicateMedicaidNumber(patientId, medicaidNumber) {
        const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/check_medicaid_number`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", patientId)
          + `?medicaidNumber=${medicaidNumber}`;
        
        const res = await DatabaseApi.get(url);

        if (res.data && res.data.isPatientMedicaidNumberUnique === false) {
            const modalDuplicateMedicaidNumber = (medicaidNumber) => {
                const deferred = $q.defer();
                const modal = mfModal.create({
                    subject: "Medicaid ID already exists",
                    message: `The Medicaid ID: ${medicaidNumber} is already in use.\nDo you wish to continue?`,
                    variant: "warning",
                    confirmLabel: "Save",
                    cancelLabel: "No, Keep editing",
                    onConfirm: () => deferred.resolve(true),
                    onComplete: () => modal.close(),
                    onCancel: () => deferred.resolve(false)
                });
    
                return deferred.promise;
            }            

            return await modalDuplicateMedicaidNumber(medicaidNumber);
        }
        else {
          return true;
        }
    }
  } };
