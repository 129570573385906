import { Button, Flex } from "@chakra-ui/react";
import React from "react";

type Props<$Value> = {
  options: { value: $Value; label: string, key?: string }[];
  value?: $Value;
  onClick: (value: $Value) => void;
};

const OptionButtonsGroup = function <$Value>(props: Props<$Value>) {
  return (
    <Flex flexWrap="wrap" gap={2} shrink={0} justifyContent="center">
      {props.options.map((option) => {
        return (
          <Button
            key={`${JSON.stringify(option.value)}`}
            colorScheme={props.value === option.value ? "blue" : "gray"}
            variant={props.value === option.value ? "solid" : "outline"}
            onClick={() => props.onClick(option.value)}
          >
            {option.label}
          </Button>
        );
      })}
    </Flex>
  );
};

export default OptionButtonsGroup;
