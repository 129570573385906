//! @ngInject
export function billingNewManualCheckModalCtrl(
  $scope,
  DatabaseApi,
  $rootScope,
  generalUtils,
  $uibModalInstance,
  wildcard,
  toaster,
  Currency
) {
  const initialize = () => {
    $scope.isProcessing = false;
    $scope.contractsMap = DatabaseApi.contractTypes() || {};
    $scope.newCheckForm = {
      checkNumber: null,
      amount: 0,
      checkDate: new Date(),
      depositDate: new Date(),
      interest: 0,
      forwardingBalance: 0,
      overpaymentRecovery: 0,
      nonreimbursable: 0,
      levyAmount: 0,
      lumpSum: 0,
      adjustment: 0,
      providerRefund: 0,
      contract: {},
      notes: null
    };
    $scope.contractsExtraSettings = {
      styleActive: true,
      singleSelection: true,
      selectionLimit: 1,
      smartButtonMaxItems: 1,
      closeOnSelect: true,
      showCheckAll: false,
      showUncheckAll: false,
      enableSearch: true,
      scrollable: true,
      scrollableHeight: "400px",
      displayProp: "name"
    };
  };

  $scope.toggleCalendarObject = (prop) => {
    $scope.calendarObject[prop] = !$scope.calendarObject[prop];
  };

  const getBodyCreateCheck = () => {
    // ?? 0 just in case, I guess.
    const amount = new Currency().fromMajor($scope.newCheckForm.amount ?? 0),
      interest = new Currency().fromMajor($scope.newCheckForm.interest ?? 0),
      forwardingBalance = new Currency().fromMajor($scope.newCheckForm.forwardingBalance ?? 0),
      overpaymentRecovery = new Currency().fromMajor($scope.newCheckForm.overpaymentRecovery ?? 0),
      nonreimbursable = new Currency().fromMajor($scope.newCheckForm.nonreimbursable ?? 0),
      levyAmount = new Currency().fromMajor($scope.newCheckForm.levyAmount ?? 0),
      lumpSum = new Currency().fromMajor($scope.newCheckForm.lumpSum ?? 0),
      adjustment = new Currency().fromMajor($scope.newCheckForm.adjustment ?? 0),
      providerRefund = new Currency().fromMajor($scope.newCheckForm.providerRefund ?? 0);

    const errors = [];

    if (!$scope.newCheckForm.checkNumber) {
      errors.push("Missing check number");
    }
    if (amount.isNaN()) {
      errors.push("Invalid check amount");
    }
    if (!$scope.newCheckForm.checkDate) {
      errors.push("Missing check date");
    }
    if (interest.isNaN()) {
      errors.push("Invalid interest");
    }
    if (!$scope.newCheckForm.contract.id) {
      errors.push("Missing contract");
    }
    if (forwardingBalance.isNaN()) {
      errors.push("Invalid forwarding balance");
    }
    if (overpaymentRecovery.isNaN()) {
      errors.push("Invalid overpayment recovery");
    }
    if (nonreimbursable.isNaN()) {
      errors.push("Invalid nonreimbursable");
    }
    if (levyAmount.isNaN()) {
      errors.push("Invalid levy amount");
    }
    if (lumpSum.isNaN()) {
      errors.push("Invalid lump sum");
    }
    if (adjustment.isNaN()) {
      errors.push("Invalid adjustment");
    }
    if (providerRefund.isNaN()) {
      errors.push("Invalid provider refund");
    }

    if (errors.length > 0) {
      return {
        // This isn't meant to be beautiful, it's meant to be helpful. There are
        // a lot of problems with this. Hopefully someone more well versed in
        // web-app can fix them.
        errorMessage: errors.join(".")
      };
    }

    if ($scope.newCheckForm.notes === undefined) {
      $scope.newCheckForm.notes = null;
    }

    const checkDate = generalUtils.formatDateByFilter($scope.newCheckForm.checkDate)
    const depositDate = $scope.newCheckForm.depositDate === null
      ? checkDate
      : generalUtils.formatDateByFilter($scope.newCheckForm.depositDate)

    return {
      checkNumber: $scope.newCheckForm.checkNumber,
      agencyId: $rootScope.agencyId,
      contractTypeId: $scope.newCheckForm.contract.id,
      amount: amount.toMinor(),
      interest: interest.toMinor(),
      forwardingBalance: forwardingBalance.toMinor(),
      overpaymentRecovery: overpaymentRecovery.toMinor(),
      nonreimbursable: nonreimbursable.toMinor(),
      levyAmount: levyAmount.toMinor(),
      lumpSum: lumpSum.toMinor(),
      adjustment: adjustment.toMinor(),
      providerRefund: providerRefund.toMinor(),
      checkDate,
      depositDate,
      notes: $scope.newCheckForm.notes,
    };
  }

  $scope.addNewCheck = () => {
    $scope.isProcessing = true;

    const body = getBodyCreateCheck();
    if (body.errorMessage) {
      toaster.pop("error", body.errorMessage);
      $scope.isProcessing = false;
      return;
    }

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/check_batches",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );

    DatabaseApi.post(url, body).then((res) => {
      $scope.isProcessing = false;
      $scope.closeModal(res);
    }, (err) => {
      $scope.isProcessing = false;
      $scope.closeModal({
        isError: true,
        message: err.data.error,
        response: err,
      });
    });
  };

  /**
   * In case the $event target has an empty value, change it to zero
   */
  $scope.setToZeroIfEmpty = ($event) => {
    if ($event.currentTarget.value === "") {
      $event.currentTarget.value = 0;
    }
  };

  $scope.closeModal = (result) => {
    if (!$scope.isProcessing) {
      $uibModalInstance.close(result);
    }
  };

  initialize();
};
