//! @ngInject
export function newPatientTaskTemplateModalCtrl($scope, $rootScope, DatabaseApi, toaster, $uibModalInstance) {
    $scope.newPatientTaskTemplateModel = {
        documents: [],
        allowedCertifications: [],
        planOfCareTypeId: null,
        taskContext: {},
    };

    $scope.newPatientTaskTemplateDocumentsEvents = {
        onItemSelect: (item) => {
            // check whether it is plan of care, and set planOfCaretypeId
            const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
            if (selectedDocument && selectedDocument.isPlanOfCare) {
                $scope.newPatientTaskTemplateModel.planOfCareTypeId = $scope.planOfCare.planOfCareTypeId;
            }
        },

        onItemDeselect: (item) => {
            // check whether it is plan of care, and reset planOfCaretypeId
            const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
            if (selectedDocument && selectedDocument.isPlanOfCare) {
                $scope.newPatientTaskTemplateModel.planOfCareTypeId = null
            }
        }
    }

    $scope.submitForm = function () {
        if (
            $scope.form.$invalid
            || $scope.newPatientTaskTemplateModel.documents.length === 0
            || $scope.newPatientTaskTemplateModel.allowedCertifications.length === 0
            || $scope.newPatientTaskTemplateModel.taskContext.id === undefined
        ) {
            return;
        }

        const durationMinutes = $scope.newPatientTaskTemplateModel.durationMinutes 
            && $scope.newPatientTaskTemplateModel.durationMinutes > 0
            ? $scope.newPatientTaskTemplateModel.durationMinutes : null;

        const allowedCertifications = $scope.newPatientTaskTemplateModel.allowedCertifications
            .map(cert => $scope.certifications.find(c => c.id === cert.id).label);

        const context = $scope.taskContextOptions.find(option =>
            option.id === $scope.newPatientTaskTemplateModel.taskContext.id
        ).value;

        const body = {
            title: $scope.newPatientTaskTemplateModel.title,
            durationMinutes: durationMinutes,
            allowedCertifications: allowedCertifications,
            documents: $scope.newPatientTaskTemplateModel.documents.map(doc => doc.id).filter(docId => docId !== 0),
            hasPlanOfCare: $scope.newPatientTaskTemplateModel.documents.find(d => d.id === 0) !== undefined,
            context: context,
        };

        $scope.formSending = true;
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_task_templates"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        DatabaseApi.post(url, body).then((res) => {
                toaster.pop('success', "Patient Task Template created successfully");
                $scope.formSending = false;
                $scope.closeModal(res.data);
            }, (err) => {
                $scope.formSending = false;
                toaster.pop('error', "Something went wrong", "Failed to create patient task template");
            });
    }

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };
};