import { assertNever } from "../consts/assertNever.const";
import { CaregiverStatus } from "../messages/caregiver";

export type LabelColor = "red" | "green" | "orange" | "gray" | "lightblue";

export function getCaregiverStatusColor(status: CaregiverStatus): LabelColor {
  switch (status) {
    case "PENDING":
      return "lightblue";

    case "ACTIVE":
      return "green";

    case "ON_HOLD":
    case "ON_LEAVE":
      return "orange";

    case "TERMINATED":
    case "REJECTED":
      return "red";

    case "JOIN_REQUEST":
    case "SUSPENDED":
    case "QUIT":
      return "gray";

    default:
      return assertNever(status);
  }
}

export function getCaregiverStatusLabel(status: CaregiverStatus): string {
  switch (status) {
    case "PENDING":
      return "Pending Application";

    case "ACTIVE":
      return "Active";

    case "ON_HOLD":
      return "On Hold";
    
    case "ON_LEAVE":
      return "On Leave";

    case "TERMINATED":
      return "Terminated";

    case "SUSPENDED":
      return "Inactive";

    case "QUIT":
      return "Quit";

    case "JOIN_REQUEST":
      return "Join Request";

    case "REJECTED":
      return "Rejected";

    default:
      return assertNever(status);
  }
}
