import moment from "moment";
import $ from "jquery";

//! @ngInject
export function updateInvoiceRatesModalCtrl($scope, $uibModalInstance, $rootScope) {
    $scope.result = { updateByInvoiceDate: true };

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.openCalendar = function (id) {
        $("#" + id)
            .data("daterangepicker")
            .show();

    };

    $scope.updateFromOptions = function () {
        $scope.endDatePickerOptions = { minDate: $scope.result.startOfDate };
    }

    $scope.confirmDates = function () {
        if ($scope.result.startOfDate && $scope.result.endOfDate) {
            $scope.result.startOfDate = moment($scope.result.startOfDate).format("YYYY-MM-DD");
            $scope.result.endOfDate = moment($scope.result.endOfDate).format("YYYY-MM-DD");
            $scope.result.updateByInvoiceDate = ($scope.result.updateByInvoiceDate == true);
            $uibModalInstance.close($scope.result);
        }
    }

    $scope.discard = function () {
        $uibModalInstance.close('discard');
    }

    $scope.cancelModal = function () {
        $uibModalInstance.dismiss('dismiss');
    }
};