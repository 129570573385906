export const personList = {
    templateUrl: "admin/views/person-list.html",
    bindings: {
        items: "<",
        type: "@"
    },
    //! @ngInject
    controller: function ($scope, $rootScope) {
        const setEntityData = (item) => {
            item.isSearchItem = false;
            switch (this.type) {
                case 'patient': {
                    item.initials = item.firstName.substr(0, 1) + item.lastName.substr(0, 1);
                    break;
                }

                case 'agencyMember': {
                    item.initials = item.initials;
                    item.displayName = item.fullName;
                    break;
                }

                default: return "";
            }
        };

        const initialize = () => {
            this.items.forEach(item => {
                setEntityData(item);
            });
        };

        this.openEntityModal = (itemId) => {
            switch (this.type) {
                case 'patient': {
                    $rootScope.openPatientModal(itemId);
                    break;
                }

                case 'caregiver': {
                    $rootScope.openCaregiverModal(itemId);
                    break;
                }

                default: return "";
            }
        };

        this.$onInit = () => {
            initialize();
        };
    },
};