import { CaregiverId, CommCenterTicketId, PatientId } from "../shared/schema/schema";
import { AuthInfo } from "./auth";

function createWebappChannel() {
  const channel = new BroadcastChannel("webapp_channel");

  // I HAVE NO IDEA WHY IT DOESN'T WORK WITH A SINGLE DECLARATION
  const channelx = new BroadcastChannel("webapp_channel");

  function getPayload<T extends keyof AngularJSPayload>(key: T): AngularJSPayload[T] | undefined {
    return (window as any).__ANGULARJS_PAYLOAD__?.[key];
  }

  function setPayload<T extends keyof AngularJSPayload>(key: T, payload: AngularJSPayload[T]) {
    if ((window as any).__ANGULARJS_PAYLOAD__ === undefined) {
      (window as any).__ANGULARJS_PAYLOAD__ = {};
    }

    (window as any).__ANGULARJS_PAYLOAD__[key] = payload;

    channelx.postMessage({ type: "PAYLOAD_SET", key });
  }

  function dispatch<T extends keyof AngularJSDispatch>(key: T, payload: AngularJSDispatch[T]) {
    channelx.postMessage({ type: "DISPATCH", key, payload });
  }

  return {
    channel,
    getPayload,
    setPayload,
    dispatch,
  };
}

export type EventFromReact = {
  type: "navigate";
  payload: { id: CaregiverId; entity: "Caregiver" } | { id: PatientId; entity: "Patient" };
};

export interface AngularJSPayload {
  AUTH_SET: AuthInfo | null;
}

export interface AngularJSDispatch {
  SOCKET_EVENT_RECEIVED:
    | {
        type: "CommCenterTicketUpdated";
        payload: {
          ticketId: CommCenterTicketId;
        };
      }
    | {
        type: "CommCenterTyping";
        payload: {
          ticketId: CommCenterTicketId;
          millis: number;
        };
      };
}

export default createWebappChannel();
