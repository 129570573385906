//! @ngInject
export function autocompleteMultiple($filter) {
    return {
        restrict: 'E',
        scope : {
            suggestionsArr: '=',
            modelArr : '=ngModel',
            beforeSelectItem : '&',
            afterSelectItem : '&',
            beforeRemoveItem : '&',
            afterRemoveItem : '&'
        },
        templateUrl: 'admin/views/autocomplete-multiple.html',
        link: function(scope, element, attr) {
            scope.objectProperty = attr.objectProperty;
            scope.selectedItemIndex = 0;
            scope.isHover = false;
            scope.isFocused = false;

            if(scope.modelArr == null || scope.modelArr == ""){
                scope.modelArr = [];
            }

            scope.onFocus = function () {
                scope.isFocused = true;
            }

            scope.onMouseEnter = function () {
                scope.isHover = true;
            };

            scope.onMouseLeave = function () {
                scope.isHover = false;
            };

            scope.onBlur = function () {
                scope.isFocused = false;
            };

            scope.onChange = function () {
                scope.selectedItemIndex = 0;
            };

            scope.keyParser = function ($event) {
                const keys = {
                    38: 'up',
                    40: 'down',
                    8 : 'backspace',
                    13: 'enter',
                    9 : 'tab',
                    27: 'esc'
                };

                const key = keys[$event.keyCode];
                if(key === 'backspace' && scope.inputValue == "") {
                    if (scope.modelArr.length != 0){
                        scope.removeAddedValues(scope.modelArr[scope.modelArr.length-1]);
                    }
                }
                else if(key === 'down') {
                    let filteredSuggestionArr = $filter('filter')(scope.suggestionsArr, scope.inputValue);
                    filteredSuggestionArr = $filter('filter')(filteredSuggestionArr, scope.alreadyAddedValues);
                    
                    if(scope.selectedItemIndex < filteredSuggestionArr.length - 1) {
                        scope.selectedItemIndex++;
                    } 
                }
                else if(key === 'up' && scope.selectedItemIndex > 0) {
                    scope.selectedItemIndex--;
                }
                else if(key === 'esc') {
                    scope.isHover = false;
                    scope.isFocused = false;
                }
                else if(key === 'enter') {
                    let filteredSuggestionArr = $filter('filter')(scope.suggestionsArr, scope.inputValue);
                    filteredSuggestionArr = $filter('filter')(filteredSuggestionArr, scope.alreadyAddedValues);
                    
                    if(scope.selectedItemIndex < filteredSuggestionArr.length) {
                        scope.onSuggestedItemsClick(filteredSuggestionArr[scope.selectedItemIndex]);
                    }
                }
            };

            scope.onSuggestedItemsClick = function (selectedValue) {
                if(scope.beforeSelectItem && typeof(scope.beforeSelectItem) === 'function') {
                    scope.beforeSelectItem({item: selectedValue});
                }

                scope.modelArr.push(selectedValue);

                if(scope.afterSelectItem && typeof(scope.afterSelectItem) === 'function') {
                    scope.afterSelectItem({item: selectedValue});
                }

                scope.inputValue = "";

                if(scope.suggestionsArr.length === scope.modelArr.length) {
                    scope.isHover = false;
                }
            };

            const isDuplicate = function (arr, item) {
                var duplicate = false;
                
                if(arr === null || arr === "")
                    return duplicate;

                for(var index = 0; index < arr.length; index++) {
                    duplicate = angular.equals(arr[index], item);
                    if (duplicate) {
                        break;
                    }
                }

                return duplicate;
            };

            scope.alreadyAddedValues = function (item) {
                var isAdded = true;
                isAdded = !isDuplicate(scope.modelArr, item);
                return isAdded;
            };

            scope.removeAddedValues = function (item) {
                if(scope.modelArr !== null && scope.modelArr !== "") {
                    var itemIndex = scope.modelArr.indexOf(item);
                    if (itemIndex !== -1) {
                        if(scope.beforeRemoveItem && typeof(scope.beforeRemoveItem) === 'function') {
                            scope.beforeRemoveItem({item: item});
                        }

                        scope.modelArr.splice(itemIndex, 1);

                        if(scope.afterRemoveItem && typeof(scope.afterRemoveItem) === 'function') {
                            scope.afterRemoveItem({item: item});
                        }
                    }
                }
            };

            scope.mouseEnterOnItem = function (index) {
                scope.selectedItemIndex = index;
            };
        }
    }
}