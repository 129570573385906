//! @ngInject
export function agencyMemberSecuritySettingsModalCtrl($scope, $rootScope, DatabaseApi, toaster, SweetAlert) {

    $scope.updateSecuritySettings = function () {
        var securitySettingsObj = {
            agencyMemberIpSecuritySettings: {}
        };
        var ipAddressesWhitelist = $scope.member.agencyMemberIpSecuritySettings.ipAddressesWhitelist;
        switch ($scope.member.agencyMemberIpSecuritySettings.type) {
            case 'Open': {
                $scope.member.ipAddressesWhitelist = [];
                break;
            }
            case 'WhitelistOnly': {
                if (!ipAddressesWhitelist) {
                    ipAddressesWhitelist = [];
                    return;
                }
                else if (ipAddressesWhitelist && ipAddressesWhitelist.length > 0) {
                    securitySettingsObj.agencyMemberIpSecuritySettings.ipAddressesWhitelist = ipAddressesWhitelist;
                    break;
                }
            }
        }
        securitySettingsObj.agencyMemberIpSecuritySettings.type = $scope.member.agencyMemberIpSecuritySettings.type;
        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_member/' + $scope.member.id + '/ip_security_settings', securitySettingsObj).then(function (res) {
            toaster.pop('success', "Security settings updated successfully");
            $scope.$close();
            $scope.cachedMember.agencyMemberIpSecuritySettings = securitySettingsObj.agencyMemberIpSecuritySettings;
        }, function (err) {
            toaster.pop('error', "Could not update security settings");
        });
    };


    $scope.validateIPAddress = function (ipaddress) {
        if (/^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$|\/\d{1,2})){4}$/.test(ipaddress) || /^::1$/.test(ipaddress)) {
            return;
        } else {
            if ($scope.member.agencyMemberIpSecuritySettings.ipAddressesWhitelist) {
                $scope.member.agencyMemberIpSecuritySettings.ipAddressesWhitelist.pop();
                toaster.pop('error', "You have entered an invalid IP address");
            }
        }
    }

    $scope.onAgencyMberWhiteListChange = function () {
        if (!$scope.member.agencyMemberIpSecuritySettings.ipAddressesWhitelist) {
            $scope.member.agencyMemberIpSecuritySettings.ipAddressesWhitelist = [];
        }
    }

    $scope.resetToDefaultSettings = function () {
        SweetAlert.swal({
            title: 'Reset to default settings',
            text: 'Are you sure you want to reset agency member security settings?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Reset',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var securitySettingsObj = {
                    agencyMemberIpSecuritySettings: {}
                };

                securitySettingsObj.agencyMemberIpSecuritySettings.type = "UserNoRestriction";
                DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_member/' + $scope.member.id + '/ip_security_settings', securitySettingsObj).then(function (res) {
                    toaster.pop('success', "Agency member security settings was reset");
                    $scope.$close();
                    $scope.cachedMember.agencyMemberIpSecuritySettings = securitySettingsObj.agencyMemberIpSecuritySettings;
                }, function (err) {

                });
            }
        });
    }

};
