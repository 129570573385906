/* @ngInject */
export function Notifications(toaster, $rootScope) {
  function desktop(title, body) {
    //return;

    //console.log('notifications service');
    webNotification.showNotification(
      title,
      {
        body: body,
        icon: "favicon-96x96.png",
        onClick: function onNotificationClicked() {
          //console.log('Notification clicked.');
          $rootScope.openNotifications();
        },
        autoClose: 0, //auto close the notification after 4 seconds (you can manually close it via hide function)
      },
      function onShow(error, hide) {
        if (error) {
          //window.alert('Unable to show notification: ' + error.message);
          toaster.pop(
            "Warning",
            "Desktop Notifications Unavailable",
            "Please allow desktop notifications"
          );
        } else {
          //console.log('Notification Shown.');

          //setTimeout(function hideNotification() {
          //console.log('Hiding notification....');
          hide(); //manually close the notification (you can skip this if you use the autoClose option)
          //}, 5000);
        }
      }
    );
  }

  return {
    desktop: desktop,
  };
}
