export const patientTaskScheduleDateTime = {
    templateUrl: "admin/views/patient-task-schedule-date-time.html",
    bindings: {
        taskTemplateId: '<',
        form: '=',
        isEditMode: '<',
        onClickToggleEditMode: '&',
        onScheduledTimeChange: '&',
        onUnschedule: '&',
        isParentEditMode: '<',
        editModeToggle: '<',
    },
    //! @ngInject
    controller: function ($scope) {
        this.onDateTimeChange = (data) => {
            if (this.onScheduledTimeChange()) {
                this.onScheduledTimeChange()(data);
            }
        };

        this.$onInit = () => {
            this.isScheduled = $scope.$ctrl.form.scheduledDateTime.settings.startTime;
        };
    }
};