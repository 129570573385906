import moment from "moment";
import { ChronoUnit } from "js-joda";

export const flexibleHoursChooseDates = {
    templateUrl: 'admin/views/flexible-visit/flexible-hours-choose-dates.html',
    bindings: {
		form: '<',
        advancedDays: '<',
		toggleAllDays: '<',
        getDefaultShift: '<',
        canAddAnotherShift: '<',
        collapseDays: '<',
        collapseDaysState: '=',
        labelTitle: '<',
        isEditMode: '<',
        updateTimesData: '&',
        updatePermanentDatePicker: '&',
        handleSeeAllSuggestions: '&',
        relevantVisitCaregivers: '<',
        handleCaregiverSelect: '&',
        handleCaregiverDeselect: '&',
        updateEnableCaregivers: '&',
        disabledDates: '<',
        applyShiftTimesToAll: '&',
        applyShiftCaregiverToAll: '&',
        clearShiftTimes: '&',
        updateTotalTimePerWeek: '&',
    },
    //! @ngInject
    controller: function ($scope, toaster, dateUtils, generalUtils) {
        $scope.toggleShowMore = () => $scope.$ctrl.collapseDaysState = !$scope.$ctrl.collapseDaysState;
        $scope.totalhours = 0;

        $scope.toggleShiftsAmount = function () {
            if ($scope.$ctrl.form.shifts === 1) {
                $scope.$ctrl.form.shifts = 2;
    
                $scope.$ctrl.form.advancedTimesData2.forEach(function (shift2, index) {
                    var shift1 = $scope.$ctrl.form.advancedTimesData[index];
    
                    $scope.times.forEach(function (time, index) {
                        if (time.label === shift2.startTime) {
                            indexOfStartOfShift2 = index;
                        } else if (time.label === shift1.endTime) {
                            indexOfEndOfShift1 = index;
                        }
                    });
    
                    if (indexOfEndOfShift1 > indexOfStartOfShift2) {
                        var startId = indexOfEndOfShift1 + 4;
                        var endId = indexOfEndOfShift1 + 8;
    
                        if (startId >= $scope.times.length) startId -= $scope.times.length;
                        if (endId >= $scope.times.length) endId -= $scope.times.length;
    
                        shift2.startTime = $scope.times[startId].label;
                        shift2.endTime = $scope.times[endId].label;
                    }
                })
    
            } else {
                $scope.$ctrl.form.shifts = 1;
            }
        };
        

        $scope.toggleDay = (day, forceToggleAllDays) => {
            if ($scope.$ctrl.form.disableDays || $scope.$ctrl.isEditMode) {
                return;
            }
    
            if (forceToggleAllDays) {
                $scope.$ctrl.form.daysOfTheWeek[day] = $scope.$ctrl.toggleAllDays;
            } else {
                if ($scope.$ctrl.form.weeksDiff === 0) {
                    const zeroWeeksDaysRange = getDaysBetweenDates(
                        $scope.$ctrl.form.datePicker.date.startDate,
                        $scope.$ctrl.form.datePicker.date.endDate
                    );
                    if (!zeroWeeksDaysRange.includes(day)) {
                        return;
                    }
                }
                $scope.$ctrl.form.daysOfTheWeek[day] = !$scope.$ctrl.form.daysOfTheWeek[day];
                const selectedDaysAmount = Object.values($scope.$ctrl.form.daysOfTheWeek).filter(
                    isDaySelected => isDaySelected === true
                ).length;
                const maxDays = Math.min(7, $scope.$ctrl.form.daysDiff + 1);
                $scope.$ctrl.toggleAllDays = (selectedDaysAmount === maxDays);
                $scope.updateTimesData();
            }
    
            if ($scope.$ctrl.form.daysOfTheWeek[day]) {
                [0, 1].forEach(shiftId => {
                    $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][day] = $scope.$ctrl.getDefaultShift(shiftId);
                });
            } else {
                [0, 1].forEach(shiftId => delete $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][day]);
            }
    
            if (!forceToggleAllDays) {
                $scope.updateTotalTimePerWeek();
            }

            selectCurrentDays();
        };

        $scope.onTabClick = (tabName) => {
            $scope.$ctrl.form.visitType = tabName;
        };


        $scope.selectAllDays = () => {
            if ($scope.$ctrl.isEditMode) {
                return;
            }

            $scope.$ctrl.toggleAllDays = !$scope.$ctrl.toggleAllDays;
    
            if ($scope.$ctrl.form.weeksDiff >= 1) {
                $scope.$ctrl.advancedDays.forEach(day => {
                    $scope.toggleDay(day.fullName, true);
                });
            } else {
                if ($scope.$ctrl.toggleAllDays) {
                    const zeroWeeksDaysRange = getDaysBetweenDates(
                        $scope.$ctrl.form.datePicker.date.startDate,
                        $scope.$ctrl.form.datePicker.date.endDate
                    );
                    $scope.$ctrl.advancedDays.forEach(day => {
                        const isDayInWeekDaysRange = zeroWeeksDaysRange.includes(day.fullName);
                        if (!$scope.$ctrl.form.daysOfTheWeek[day.fullName] && isDayInWeekDaysRange) {
                            [0, 1].forEach(shiftId => {
                                $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][day.fullName] = $scope.$ctrl.getDefaultShift(shiftId);
                            });
                        } else if ($scope.$ctrl.form.daysOfTheWeek[day.fullName] && !isDayInWeekDaysRange) {
                            [0, 1].forEach(shiftId => delete $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][day]);
                        }
                        $scope.$ctrl.form.daysOfTheWeek[day.fullName] = isDayInWeekDaysRange;
                    });
                } else {
                    $scope.$ctrl.form.daysOfTheWeek = {};
                }
            }
    
            $scope.updateTotalTimePerWeek();
            selectCurrentDays();
        };
    
        $scope.toggleDifferentDayTimes = () => {
            $scope.$ctrl.form.shiftsDuration = null;
            $scope.$ctrl.form.isDifferentDayTimes = !$scope.$ctrl.form.isDifferentDayTimes;
            $scope.totalhours = null;
            selectCurrentDays();
    
            if (!$scope.$ctrl.isEditMode) {
                if ($scope.$ctrl.form.isDifferentDayTimes) {
                    $scope.$ctrl.form.advancedTimeShiftsPermanent = [
                        {},
                        {}
                    ];
                    Object.keys($scope.$ctrl.form.daysOfTheWeek).forEach(day => {
                        if ($scope.$ctrl.form.daysOfTheWeek[day]) {
                            [0, 1].forEach(shiftId => {
                                $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][day] = $scope.$ctrl.getDefaultShift(shiftId);
                            });
                        }
                    });
                } else {
                    $scope.$ctrl.form.advancedTimeShiftsPermanent = [
                        { "Everyday": $scope.$ctrl.getDefaultShift(0) },
                        { "Everyday": $scope.$ctrl.getDefaultShift(1) }
                    ];
                }
        
                if ($scope.$ctrl.form.shiftsPerDays !== undefined) {
                    $scope.$ctrl.form.shiftsPerDays["Everyday"] = 1;
                    $scope.$ctrl.advancedDays.forEach(day => $scope.$ctrl.form.shiftsPerDays[day.fullName] = 1);
                }
            } else {
                const availableDaysOfWeek = Object.entries($scope.$ctrl.form.daysOfTheWeek)
                                                    .filter(d => d[1] === true)
                                                    .map(d => d[0]);
                if (!$scope.$ctrl.form.isDifferentDayTimes) {
                    [0, 1].forEach(shiftId => {
                        const shiftDaysOfWeek = Object.keys($scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId]);
                        const daysOfWeekWithTimes = availableDaysOfWeek.filter(d => shiftDaysOfWeek.includes(d));
                        if (daysOfWeekWithTimes.length > 0 && $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][daysOfWeekWithTimes[0]]) {
                            $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId]['Everyday'] = angular.copy(
                                $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][daysOfWeekWithTimes[0]]
                            );
                        } else {
                            $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId]['Everyday'] = $scope.$ctrl.getDefaultShift(shiftId);
                        }
                    });
                } else {
                    [0, 1].forEach(shiftId => {
                        for (const dow of availableDaysOfWeek) {
                            $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][dow] = angular.copy(
                                $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId]['Everyday']
                            );
                        }
                    });
                }
            }
    
            $scope.updateTotalTimePerWeek();
            $scope.updateEnableCaregivers();
        };

        $scope.updateTotalTimePerWeek = () => {
            if ($scope.$ctrl.updateTotalTimePerWeek()) {
                $scope.$ctrl.updateTotalTimePerWeek()();
            }
        };

        $scope.updateEnableCaregivers = () => {
            if ($scope.$ctrl.updateEnableCaregivers()) {
                $scope.$ctrl.updateEnableCaregivers()();
            }
        };

        $scope.updateTimesData = () => {
          if ($scope.$ctrl.form.shiftsDuration) {
            $scope.totalhours = Object.values($scope.$ctrl.form.shiftsDuration).reduce(function (
              acc,
              obj
            ) {
              return acc + obj[0];
            },
            0);
            const selectedDays = Object.keys($scope.$ctrl.form.daysOfTheWeek).filter(
              (day) => $scope.$ctrl.form.daysOfTheWeek[day] === true
            );

            if (!$scope.$ctrl.form.isDifferentDayTimes) {
              $scope.totalhours *= selectedDays.length;
            }
          }
        };

        $scope.toggleShiftsAmount = (dayFullName) => {
            if ($scope.$ctrl.form.shiftsPerDays === undefined) {
                $scope.$ctrl.form.shifts = 3 - $scope.$ctrl.form.shifts;
            } else {
                $scope.$ctrl.form.shiftsPerDays[dayFullName] = 3 - $scope.$ctrl.form.shiftsPerDays[dayFullName];
                multiShiftsDay = $scope.$ctrl.advancedDays.find(day =>
                    $scope.$ctrl.form.shiftsPerDays[day.fullName] === 2
                );
                $scope.$ctrl.form.shifts = (multiShiftsDay !== undefined) ? 2 : 1;
            }
    
            if ($scope.$ctrl.form.visitType === "Permanent") {
                $scope.updateTotalTimePerWeek();
            }
        };

        $scope.handleChangePermanentDatePicker = () => {
            const datesDiff = getDatesPickerDiff();
            $scope.$ctrl.form.weeksDiff = datesDiff.weeks;
            $scope.$ctrl.form.daysDiff = datesDiff.days;

            if ($scope.$ctrl.isEditMode) {
                return;
            }
    
            if ($scope.$ctrl.form.daysDiff < 2) {
                $scope.$ctrl.form.disableDays = true;
                $scope.$ctrl.form.shifts = 1;
                if ($scope.$ctrl.form.shiftsPerDays !== undefined) {
                    $scope.$ctrl.form.shiftsPerDays["Everyday"] = 1;
                    $scope.$ctrl.advancedDays.forEach(day => $scope.$ctrl.form.shiftsPerDays[day.fullName] = 1);
                }
                $scope.$ctrl.toggleAllDays = true;
                $scope.$ctrl.form.isDifferentDayTimes = false;
                selectCurrentDays();
                $scope.$ctrl.form.advancedTimeShiftsPermanent = [
                    { "Everyday": $scope.$ctrl.getDefaultShift(0) },
                    { "Everyday": $scope.$ctrl.getDefaultShift(1) }
                ];
                $scope.updateTotalTimePerWeek();
            } else {
                $scope.$ctrl.form.disableDays = false;
                if ($scope.$ctrl.form.weeksDiff == 0) {
                    $scope.$ctrl.form.daysOfTheWeek = {};
                    $scope.$ctrl.form.shifts = 1;
                    if ($scope.$ctrl.form.shiftsPerDays !== undefined) {
                        $scope.$ctrl.form.shiftsPerDays["Everyday"] = 1;
                        $scope.$ctrl.advancedDays.forEach(day => $scope.$ctrl.form.shiftsPerDays[day.fullName] = 1);
                    }
                    $scope.$ctrl.toggleAllDays = false;
                    $scope.$ctrl.form.isDifferentDayTimes = false;
                    selectCurrentDays();
                    $scope.$ctrl.form.advancedTimeShiftsPermanent = [
                        { "Everyday": $scope.$ctrl.getDefaultShift(0) },
                        { "Everyday": $scope.$ctrl.getDefaultShift(1) }
                    ];
                    $scope.updateTotalTimePerWeek();
                }
            }
            if ($scope.$ctrl.updatePermanentDatePicker()) {
                $scope.$ctrl.updatePermanentDatePicker()();
            }
        };

        $scope.clearShiftTimes = (shiftId, day) => {
            $scope.$ctrl.clearShiftTimes()(shiftId, day);
        };

        $scope.applyShiftTimesToAll = (shiftId, day) => {
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            $scope.$ctrl.applyShiftTimesToAll()(shift);
        };

        $scope.applyShiftCaregiverToAll = (shiftId, day) => {
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            $scope.$ctrl.applyShiftCaregiverToAll()(shift);
        };

        $scope.canClearShiftTimes = (shiftId, day) => {
            if (day === "Everyday") {
                return false;
            }
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            if (shift.mixedContent === undefined) return false;
            return (
                (shift.mixedContent.startTimes && shift.startTime !== null) ||
                (shift.mixedContent.endTimes && shift.endTime !== null)
            );
        };

        $scope.canApplyShiftTimesToAll = (shiftId, day) => {
            if (day === "Everyday") {
                return false;
            }
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            if (shift.mixedContent === undefined) return true;
            const isEditingStartTime = (!shift.mixedContent.startTimes || shift.editModeToggle.startTime);
            const isEditingEndTime = (!shift.mixedContent.endTimes || shift.editModeToggle.endTime);
            return (
                shift.startTime !== null &&
                isEditingStartTime &&
                shift.endTime !== null &&
                isEditingEndTime
            );
        };

        $scope.canApplyShiftCaregiverToAll = (shiftId, day) => {
            if (day === "Everyday") {
                return false;
            }
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            if (shift.mixedContent === undefined) return false;
            const isEditingCaregiver = (!shift.mixedContent.caregivers || shift.editModeToggle.caregiver);
            const hasRelevantCaregivers = ($scope.$ctrl.relevantVisitCaregivers && $scope.$ctrl.relevantVisitCaregivers.length > 0);
            return (
                isEditingCaregiver && 
                $scope.$ctrl.form.enableCaregivers.shiftsCanAssign === true &&
                hasRelevantCaregivers
            )
        };

        $scope.areOptionsVisible = (shiftId, day) => {
            return (
                $scope.canClearShiftTimes(shiftId, day) ||
                $scope.canApplyShiftTimesToAll(shiftId, day) ||
                $scope.canApplyShiftCaregiverToAll(shiftId, day)
            );
        };

        $scope.toggleEditModeShift = (shiftId, day, editModeField) => {
            const shift = $scope.getShiftByShiftIdAndDayFullName(shiftId, day);
            shift.editModeToggle[editModeField] = !shift.editModeToggle[editModeField];
            if (!shift.editModeToggle[editModeField]) {
                if (["startTime", "endTime"].includes(editModeField)) {
                    $scope.updateTimesData(shiftId, editModeField, day, null);
                    $scope.updateTotalTimePerWeek();
                } else if (editModeField === "caregiver") {
                    $scope.$ctrl.handleCaregiverDeselect()(shift);
                }
            }
        };

        const selectCurrentDays = () => {
            if ($scope.$ctrl.form.isDifferentDayTimes) {
                $scope.currentDays = $scope.$ctrl.advancedDays.filter(
                    day => day.fullName in $scope.$ctrl.form.daysOfTheWeek && $scope.$ctrl.form.daysOfTheWeek[day.fullName]
                );
            } else {
                $scope.currentDays = [
                    angular.copy($scope.$ctrl.advancedDays)
                ];
                $scope.currentDays[0].name = 'Everyday';
                $scope.currentDays[0].shortName = 'Everyday';
                $scope.currentDays[0].fullName = 'Everyday';
            }
        }

        const initPermanentVisitData = () => {
            $scope.permanentVisitDatePickerOptions = {
                applyClass: 'btn-primary',
                locale: {
                    format: "MM/DD/YYYY", // will print 01/06/2017
                    fromLabel: "From",
                    toLabel: "To",
                    applyLabel: "Apply",
                    cancelLabel: 'Cancel'
                },
                autoApply: true,
                // minDate: new Date(),
                opens: "left",
                eventHandlers: {
                    "apply.daterangepicker": $scope.handleChangePermanentDatePicker
                }
            };
            $scope.handleChangePermanentDatePicker();
            // selectCurrentDays();
            $scope.$ctrl.toggleAllDays = false;
        };

        const getDaysBetweenDates = (start, end) => {
            const startM = moment(start);
            const endM = moment(end);
            const days = [];
            for (let dayMoment = startM; !dayMoment.isAfter(endM); dayMoment.add(1, "days")) {
                days.push(moment(dayMoment).format('dddd'));
            }
            return days;
        };
    
        const checkShiftsOverlapse = (dayFullName) => {
            const shift1 = $scope.getShiftByShiftIdAndDayFullName(0, dayFullName);
            const shift2 = $scope.getShiftByShiftIdAndDayFullName(1, dayFullName);
            const timesArray = [shift1.startTime, shift1.endTime, shift2.startTime, shift2.endTime];
    
            for (let i = 1; i < timesArray.length; i++) {
                if (timesArray[i].isBefore(timesArray[i - 1])) {
                    let errorMessage = "Shifts overlapse..";
                    if (dayFullName !== undefined && dayFullName !== "Everyday") {
                        errorMessage = dayFullName + " shifts overlapse...";
                    }
                    return toaster.pop("warning", "Oops...", errorMessage);
                }
            }
    
            if (Math.floor(moment.duration(shift2.endTime.diff(shift1.startTime)).asMinutes()) > 1440) {
                toaster.pop("warning", "Shifts 24 hours overflow");
            }
    
        };
    
        const updateOverNight = (shift) => {
            shift.isOverNight = shift.startTime.date() !== shift.endTime.date();
        };
    
        const updateShiftsDates = (dayFullName) => {
            const startMoment = moment($scope.$ctrl.form.datePicker.date.startDate);
            const startDate = startMoment.date();
            const shift1 = $scope.getShiftByShiftIdAndDayFullName(0, dayFullName);
            shift1.startTime = startMoment.clone().set("hours", shift1.startTime.hours()).set("minutes", shift1.startTime.minutes());
            shift1.endTime = startMoment.clone().set("hours", shift1.endTime.hours()).set("minutes", shift1.endTime.minutes());
    
            const isShift1OverNight = shift1.endTime.isSameOrBefore(shift1.startTime);
            if (isShift1OverNight) {
                shift1.endTime.set("date", startDate + 1);
            }
    
            const shiftsAmount = $scope.$ctrl.form.shiftsPerDays === undefined ?
                $scope.$ctrl.form.shifts :
                $scope.$ctrl.form.shiftsPerDays[dayFullName];
            if (shiftsAmount > 1) {
                const shift2 = $scope.getShiftByShiftIdAndDayFullName(1, dayFullName);
                shift2.startTime = startMoment.clone().set("hours", shift2.startTime.hours()).set("minutes", shift2.startTime.minutes());
                shift2.endTime = startMoment.clone().set("hours", shift2.endTime.hours()).set("minutes", shift2.endTime.minutes());
    
                if (
                    isShift1OverNight ||
                    shift2.startTime.isSameOrBefore(shift1.startTime)
                ) {
                    shift2.startTime.set("date", startDate + 1);
                    shift2.endTime.set("date", startDate + 1);
                }
    
                if (shift2.endTime.isSameOrBefore(shift2.startTime)) {
                    shift2.endTime.add(1, "days");
                }
            }
        };

        $scope.getShiftByShiftIdAndDayFullName = (shiftId, day) => {
            const dayFullName = $scope.$ctrl.form.isDifferentDayTimes ? day : 'Everyday';
            if ($scope.$ctrl.form.visitType === "Single" || dayFullName === undefined) {
                return $scope.$ctrl.form.advancedTimeShiftsSingle[shiftId];
            } else if ($scope.$ctrl.form.visitType === "Permanent") {
                return $scope.$ctrl.form.advancedTimeShiftsPermanent[shiftId][dayFullName];
            }
            return {};
        };

        const getDatesPickerDiff = () => {
            const start = dateUtils.dateToLocalDate($scope.$ctrl.form.datePicker.date.startDate);
            const end = dateUtils.dateToLocalDate($scope.$ctrl.form.datePicker.date.endDate);

            return {
                days: start.until(end.plusDays(1), ChronoUnit.DAYS),
                weeks: start.until(end.plusDays(1), ChronoUnit.WEEKS)
            };
        };

        let lastStartDate;
        let lastEndDate;

        const fireOnDatepickerDateChange = () => {
            if ($scope.$ctrl.form.datePicker.date.startDate !== lastStartDate || $scope.$ctrl.form.datePicker.date.endDate !== lastEndDate) {
                $scope.handleChangePermanentDatePicker();

                lastStartDate = $scope.$ctrl.form.datePicker.date.startDate;
                lastEndDate = $scope.$ctrl.form.datePicker.date.endDate;
            }
        }

        const getDurationStringByHoursAndMinutes = (hours, minutes) => {
            let durationString = "";
            if (hours > 0) durationString += hours + "H";
            if (minutes > 0) {
                if (durationString !== "") durationString += ":";
                durationString += minutes + "M";
            }

            return durationString;
        };

        this.$onInit = function () {
            initPermanentVisitData();
            $scope.$ctrl.form.visitType = 'Flexible fixed hours';
            $scope.$ctrl.toggleAllDays = false;
            $scope.$ctrl.form.patientPreferences.forEach(pref => {
                $scope.$ctrl.form.daysOfTheWeek[generalUtils.capitalize(pref.day)] = true;
            })
        }

        this.$onChanges = (changes) => {
            if ("form" in changes || "advancedDays" in changes) {
                selectCurrentDays();
                $scope.updateTotalTimePerWeek();
            }
        }

        this.$doCheck = () => {
            fireOnDatepickerDateChange();
        };
    }
};