import { CaregiverId, CaregiverDocumentUploadId } from "@medflyt/messages/ids";
import { LocalDateTime } from "js-joda";
import { apiErrors } from "../../../../scripts/consts/apiErrors.const";
import { dateUtils } from "../../../../scripts/consts/dateUtils";
import { loadable, Loadable } from "../../../../scripts/consts/loadable.const";
import { ComplianceItemAutomationResult } from "../../../../scripts/messages/compliance";
import { MfModalFactory } from "../../../../scripts/services/mfModalFactory";
import { CompService } from "../../comp.service";
import "./compliance-automations-table.component.scss";

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "ComplianceAutomationsTable";

  items: Loadable<ComplianceItemAutomationResult[]> = loadable.loading();
  caregiverId!: CaregiverId;
  singleDocumentAutomationResult?: ComplianceItemAutomationResult | null;
  activeRowDocumentUploadId: CaregiverDocumentUploadId | null = null;

  constructor(private compService: CompService, private mfModal: MfModalFactory) {}

  $onInit(): void {
    if (this.singleDocumentAutomationResult !== undefined) {
      this.items = loadable.resolve(
        this.singleDocumentAutomationResult === null ? [] : [this.singleDocumentAutomationResult]
      );
    } else {
      this.fetchData();
    }
  }

  promptErrorModal(error: Error) {
    const modal = this.mfModal.create({
      variant: "danger",
      subject: "Error",
      message: apiErrors.format(error, "Something went wrong"),
      confirmLabel: "Close",
      hideCancelButton: true,
      onConfirm: () => modal.close(),
    });
  }

  formatLocalDateTime = (localDateTime: LocalDateTime) =>
    dateUtils.localDateTimeToMDYHMString(localDateTime);

  onClickRow = (item: ComplianceItemAutomationResult) => {
    if ((item.reviewersAnswers?.length ?? 0) === 0) {
      return;
    }

    this.activeRowDocumentUploadId =
      item.item.uploadId === this.activeRowDocumentUploadId ? null : item.item.uploadId;
  };

  filterData = (data: ComplianceItemAutomationResult[]): ComplianceItemAutomationResult[] => {
    if (this.singleDocumentAutomationResult !== undefined) {
      return this.singleDocumentAutomationResult === null
        ? []
        : [this.singleDocumentAutomationResult];
    }

    return data;
  };

  private async fetchData() {
    return this.compService
      .fetchCaregiverComplianceAutomations(this.caregiverId)
      .then((data) => {
        this.items = loadable.resolve(data);
      })
      .catch((error) => {
        console.error(error);
        this.promptErrorModal(error);
        this.items = apiErrors.format(error, "Something went wrong");
      });
  }
}

export const ComplianceAutomationsTableComponent: ComponentOptions = {
  $name: "complianceAutomationsTable",
  templateUrl:
    "admin/modules/compliance/components/compliance-automations-table/compliance-automations-table.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {
    caregiverId: "<",
    singleDocumentAutomationResult: "<?",
  },
};
