import $ from "jquery";

//! @ngInject
export function caregiverTrainingCenterDueDateCtrl(
  $scope,
  $rootScope,
  $uibModal,
  CaregiverTrainingCenterService,
  SweetAlert,
  toaster
) {
  const service = CaregiverTrainingCenterService;

  this.$onChanges = function (changedData) {
    if (
      changedData.caregiver !== undefined &&
      changedData.caregiver.currentValue !== undefined
    ) {
      $scope.caregiver = changedData.caregiver.currentValue;
      $scope.caregiverId = $scope.caregiver.id;
    }
    if (
      changedData.bundle !== undefined &&
      changedData.bundle.currentValue !== undefined
    ) {
      $scope.bundle = changedData.bundle.currentValue;
    }
    if (
      changedData.dueDate !== undefined &&
      changedData.dueDate.currentValue !== undefined
    ) {
      $scope.dueDate = changedData.dueDate.currentValue;
    }
  };

  $scope.viewTestModal = async function (bundleItem) {
    console.log(bundleItem);
    console.log($scope.dueDate);

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/caregiverTrainingCenterTestModal.html",
      size: "lg-percent",
      controller: "caregiverTrainingCenterTestModalCtrl",
      resolve: {
        caregiverId: function () {
          return $scope.caregiverId;
        },
        dueDateId: function () {
          return $scope.dueDate.bundleDueDateId;
        },
        bundleItem: function () {
          return bundleItem;
        },
      },
    });

    modalInstance.result.then(function (res) {
      if (res === "NO_CONNECTION") {
        toaster.pop("error", "Couldn't load modal data");
      }
    });
  };

  $scope.onClickAdd1TestAttempt = async function (bundleDueDateId, bundleItem) {
    let params = {
      numAdditionalAttempts: 1, // TODO: This should be an input field in a modal
      notes: "", // TODO: We should open a modal to allow the user to enter a reason for the additional attempts
    };

    await service
      .addTestAttempt({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: bundleDueDateId,
        trainingCenterBundleItemId: bundleItem.bundleItemId,
        params,
      })
      .then(() => (bundleItem.testMaxAttemptsAllowed += 1))
      .catch(() => toaster.pop("error", "Couldn't add test attempt"));
  };

  $scope.ignoreClick = function ($event) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
      $("html").click();
    }
  };

  $scope.onClickSegmentProgress = function (bundleItem) {
    $uibModal.open({
      templateUrl: "admin/views/caregiverTrainingCenterTotalWatchedModal.html",
      size: "lg-percent",
      controller: "caregiverTrainingCenterTotalWatchedModalCtrl",
      resolve: {
        caregiverId: () => $scope.caregiverId,
        dueDateId: () => $scope.dueDate.bundleDueDateId,
        bundle: () => $scope.bundle,
        bundleItem: () => bundleItem,
      },
    });
  };

  $scope.onClickMarkItemExempt = async function (
    bundleDueDateId,
    bundleItemId,
    exempt
  ) {
    switch (exemptStrategy()) {
      case "ALL_EXEMPTED":
        toaster.pop(
          "error",
          "Can't exempt all items",
          "All other items are exempted in this due date"
        );
        console.log("Exempt starategy:", "ALL_EXEMPTED");
        break;
      case "LAST_COMPLETED":
        SweetAlert.swal(
          {
            title:
              "Exempting last item in the bundle will generate a certificate of completion",
            text: "Are you sure you want to exempt last bundle item?",
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          async function (isConfirm) {
            if (isConfirm) {
              await exemptItem(bundleDueDateId, bundleItemId, exempt);
            }
          }
        );
        console.log("Exempt starategy:", "LAST_COMPLETED");
        break;
      default:
        await exemptItem(bundleDueDateId, bundleItemId, exempt);
        console.log("Exempt starategy:", "DEFAULT");
    }
  };

  async function exemptItem(bundleDueDateId, bundleItem, exempt) {
    const params = {
      exempt,
      notes: "", // TODO: We should open a modal to allow the user to enter a reason for the exemption
    };

    await service
      .editItemExempt({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: bundleDueDateId,
        trainingCenterBundleItemId: bundleItem.bundleItemId,
        params,
      })
      .then((bundles) => {
        bundleItem.itemExempt = !bundleItem.itemExempt;
        $scope.$parent.updateBundle(
          findBundleByDueDateId(bundles, bundleDueDateId)
        );
      })
      .catch((err) => {
        const errTitle = "Couldn't set exempt";
        if (err.data.error !== "CAREGIVER_SIGNATURE") {
          toaster.pop("error", errTitle);
        } else {
          toaster.pop(
            "error",
            errTitle,
            "No caregiver signature found in the system"
          );
        }
      });
  }

  function exemptStrategy() {
    console.log($scope.dueDate.bundleItems);

    const itemsCount = $scope.dueDate.bundleItems.length;
    if (itemsCount === 1) return "ALL_EXEMPTED";

    let unfinishedItemsCount = 0;
    let completedItemCount = 0;
    for (const item of $scope.dueDate.bundleItems) {
      if (item.itemCompleted) {
        completedItemCount++;
        continue;
      }
      if (!item.itemExempt) unfinishedItemsCount++;
    }

    if (unfinishedItemsCount === 1 && completedItemCount > 0)
      return "LAST_COMPLETED";

    if (unfinishedItemsCount === 1 && completedItemCount === 0)
      return "ALL_EXEMPTED";

    return "DEFAULT";
  }

  function findBundleByDueDateId(bundles, bundleDueDateId) {
    const updatedBundle = bundles.find(function (bundle) {
      const hasBundleDueDate = bundle.bundleDueDates.find(function (
        bundleDueDate
      ) {
        return bundleDueDate.bundleDueDateId === bundleDueDateId;
      });
      return hasBundleDueDate !== undefined;
    });
    return updatedBundle;
  }
};
