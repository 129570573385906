//! @ngInject
export function agencyPermissionsRolesService ($rootScope, $http, Consts) {
      /* Decalare factory return object */
      const service = {};

      service.getAllPermissions = () => {
        return $http.get(`${Consts.api}allPermissions`).then((res) => {
          return res.data.groups;
        });
      };

      service.getAgencyRoles = () => {
        return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/roles`).then((res) => {
            return res.data.roles;
          });
      };

      service.getAgencyRolesPermissions = () => {
        return $http
          .get(
            `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/roles_permissions`
          )
          .then((res) => {
            return res.data.agencyRoles;
          });
      };

      // TODO: remove this, needs to be implemented in user response on login
      service.getAgencyMemberPermissions = () => {
        return $http
          .get(
            `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/permissions/agency_member_id/${$rootScope.agencyMemberId}`
          )
          .then((res) => {
            return res.data;
          });
      };

      service.deleteRole = (roleName) => {
        return $http.delete(
          `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/role/${roleName}`
        );
      };

      service.createNewRole = (newRoleName) => {
        return $http.post(
          `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/role`,
          { name: newRoleName }
        );
      };

      service.editRolePermissions = (roleName, rolePermissions) => {
        return $http.put(
          `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/role/${roleName}/permissions`,
          rolePermissions
        );
      };

      service.editAgencyMemberRole = (selectedAgencyMember, roles, type) => {
        return $http.put(
          `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/agency_member_id/${selectedAgencyMember}/roles`,
          {
            type: type,
            roles: roles
          }
        );
      };

      return service;
    }
