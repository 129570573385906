import { LocalDateTime, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function patientMeasurementModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster, SweetAlert) {

    $scope.vitalMethods = [
        { id: 1, label: "Right Arm", value: "Right Arm" },
        { id: 2, label: "Left Arm", value: "Left Arm" },
        { id: 3, label: "Sitting", value: "Sitting" },
        { id: 4, label: "Laying", value: "Laying" },
        { id: 5, label: "Standing", value: "Standing" },
        { id: 6, label: "Regular", value: "Regular" },
        { id: 7, label: "Irregular", value: "Irregular" },
        { id: 8, label: "Oral", value: "Oral" },
        { id: 9, label: "Axillary", value: "Axillary" },
        { id: 10, label: "Rectal", value: "Rectal" },
        { id: 11, label: "Remote / Forehead", value: "Remote/Forehead" }
    ];

    $scope.vitalUnits = [
        { id: 1, label: "Fahrenheit", value: "FAHRENHEIT" },
        { id: 2, label: "mmHg", value: "mmHg" },
        { id: 3, label: "Breaths Per Minute", value: "Breaths Per Minute" },
        { id: 4, label: "Beats Per Minute", value: "Beats Per Minute" }
    ];

    $scope.vitalOptions = $scope.vitalQuestions.map(question => {
        return { id: question.id, label: question.questionText }
    });
    $scope.selectedVitalType = 'number';
    $scope.selectedVitalMethod = {};
    $scope.selectedVitalUnit = {};

    $scope.measurementModel = {
        vitalAnswer: [],
        vitalType: {}
    };

    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true
    };

    $scope.vitalTypeSelectEvents = {
        onItemSelect: function (selectedVital) {
            let vitalQuestion = $scope.vitalQuestions.find(vital => vital.id === selectedVital.id);
            if (vitalQuestion) {
                $scope.measurementModel.vitalAnswer = [];
                $scope.selectedVitalType = vitalQuestion.answerType;
            }
        }
    }

    $scope.vitalMethodSelectEvents = {
        onItemSelect: function (selectedMethod) {
            let vitalMethod = $scope.vitalMethods.find(vital => vital.id === selectedMethod.id);
            if (vitalMethod) {
                $scope.measurementModel.vitalMethod = vitalMethod.value;
            }
        }
    }

    $scope.vitalUnitSelectEvents = {
        onItemSelect: function (selectedUnit) {
            let vitalUnit = $scope.vitalUnits.find(vital => vital.id === selectedUnit.id);
            if (vitalUnit) {
                $scope.measurementModel.vitalUnit = vitalUnit.value;
            }
        }
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }

        // fields that are not plain inputs
        if (!$scope.measurementModel.vitalType.id || !$scope.selectedVitalMethod.id || $scope.measurementModel.vitalAnswer.length === 0) {
            return;
        }

        let vitalAnswers = $scope.measurementModel.vitalAnswer.map( answer => {
            return answer.toString();
        });
        const vitalReqAnswer = vitalAnswers.length > 1 ?
            vitalAnswers : vitalAnswers[0];
        let vitalRequest = {
            questionId: $scope.measurementModel.vitalType.id,
            vitalAnswer: vitalReqAnswer,
            vitalMethod: $scope.measurementModel.vitalMethod,
            vitalComments: $scope.measurementModel.vitalComments ? $scope.measurementModel.vitalComments : null,
            vitalMeasuredAt: null, // will set below, if dates exists
            vitalUnit:  $scope.measurementModel.vitalUnit ? $scope.measurementModel.vitalUnit : null,
        }
        
        if ($scope.measurementModel.dateTaken && $scope.measurementModel.timeTaken) {
            const timeTakenObj = LocalDateTime.from(nativeJs(moment($scope.measurementModel.timeTaken)));
            let dateTakenObj = LocalDateTime.from(nativeJs(moment($scope.measurementModel.dateTaken)));
            dateTakenObj = dateTakenObj.plusHours(timeTakenObj.hour());
            dateTakenObj = dateTakenObj.plusMinutes(timeTakenObj.minute());
            vitalRequest.vitalMeasuredAt = dateTakenObj.toString();
        }
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
            + '/patient/' + $scope.patientId + '/vital_questions',
            vitalRequest).then(function (res) {
                toaster.pop('success', "Patient measurement saved successfully");
                $scope.getPatientVitals();
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not create a new measurement for patient");
            });
    }

    $scope.closeModal = function (isSuccess) {
        $uibModalInstance.close(isSuccess);
    };
};