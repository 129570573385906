import { Box, Button, Center, Flex, Heading, Radio, RadioGroup, Textarea } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { CaregiverId, CommCenterLabelId, PatientId } from "../../../shared/schema/schema";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../patient/components/PatientSelect";
import { NewTicketRequestBody } from "../utils/communication-utils";
import ChooseLabelSection from "./ChooseLabelSection";
import { z } from "zod";

interface NewTicketDetails {
  labelId: CommCenterLabelId | null;
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  message: string;
  topic: "Patient" | "Caregiver";
}

interface Props {
  initialLabelId: CommCenterLabelId | null;
  labels: Messages["CommCenterLabel"][];
  defaultValues: {
    caregiverId: CaregiverId | null;
    patientId: PatientId | null;
    topic: "Caregiver" | "Patient";
  };
  onCreateTicket: (details: NewTicketRequestBody) => void;
}

const newDetailsSchema = z
  .object({
    labelId: z.number({ required_error: "Label is missing " }).transform(CommCenterLabelId.wrap),
    message: z.string().min(1, { message: "Message is missing" }),
  })
  .and(
    z.union([
      z.object({
        topic: z.literal("Caregiver"),
        caregiverId: z
          .number({ required_error: "Caregiver is missing" })
          .transform(CaregiverId.wrap),
        patientId: z.number().transform(PatientId.wrap).nullable(),
      }),
      z.object({
        topic: z.literal("Patient"),
        patientId: z.number({ required_error: "Patient is missing" }).transform(PatientId.wrap),
        caregiverId: z.number().transform(CaregiverId.wrap).nullable(),
      }),
    ])
  );

const NewTicket = (props: Props) => {
  const [chosenLabels, setChosenLabels] = React.useState<Messages["CommCenterLabel"][]>([]);
  const [labelsToDisplay, setLabelsToDisplay] = React.useState<Messages["CommCenterLabel"][]>(() =>
    props.labels.filter((label) => label.parent === props.initialLabelId)
  );

  const [newTicket, setNewTicket] = React.useState<NewTicketDetails>({
    caregiverId: props.defaultValues.caregiverId,
    patientId: props.defaultValues.patientId,
    topic: props.defaultValues.topic,
    labelId: null,
    message: "",
  });

  const [errors, setErrors] = React.useState<{
    labelId?: string[];
    message?: string[];
    topic?: string[];
    caregiverId?: string[];
    patientId?: string[];
  } | null>({ labelId: ["Label id missing"], message: ["Message is missing"] });

  const handleChangeTicketDetails = (field: Partial<NewTicketDetails>) => {
    const newDetails = {
      ...newTicket,
      ...field,
    };

    setNewTicket(newDetails);

    const result = newDetailsSchema.safeParse(newDetails);
    setErrors(!result.success ? result.error.flatten().fieldErrors : null);
  };

  const onClickCreateTicket = () => {
    const result = newDetailsSchema.safeParse(newTicket);

    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      return;
    }

    props.onCreateTicket(result.data);
  };

  const handleClickLabel = (labelClicked: Messages["CommCenterLabel"]) => {
    const labelsToDisplay = props.labels.filter((label) => label.parent === labelClicked.id);

    if (labelsToDisplay.length !== 0) {
      setChosenLabels([...chosenLabels, labelClicked]);
      setLabelsToDisplay(labelsToDisplay);
    } else {
      handleChangeTicketDetails({ labelId: labelsToDisplay.length === 0 ? labelClicked.id : null });
    }
  };

  const handleClickChosenLabel = (chosenLabel: Messages["CommCenterLabel"]) => {
    const labelIndex = chosenLabels.indexOf(chosenLabel);
    setChosenLabels(chosenLabels.slice(0, labelIndex));

    const labelsToDisplay = props.labels.filter((label) => label.parent === chosenLabel.parent);
    setLabelsToDisplay(labelsToDisplay);

    handleChangeTicketDetails({ labelId: labelsToDisplay.length === 0 ? chosenLabel.id : null });
  };

  return (
    <Flex direction="column" gap={10} w="full" bg="white" align="center">
      <Heading color="blue.500">Let's start a new conversation</Heading>
      <Flex>
        <RadioGroup
          value={newTicket.topic}
          onChange={(value: "Caregiver" | "Patient") => handleChangeTicketDetails({ topic: value })}
        >
          <Flex direction="column" gap={2} alignItems="flex-start">
            <Flex gap={4}>
              <Radio value="Caregiver" checked={newTicket.topic === "Caregiver"}>
                Primary
              </Radio>
              <CaregiverSelect
                value={newTicket.caregiverId}
                onChange={(value) => handleChangeTicketDetails({ caregiverId: value?.id ?? null })}
              />
            </Flex>
            <Flex gap={4}>
              <Radio value="Patient" checked={newTicket.topic === "Patient"}>
                Primary
              </Radio>
              <PatientSelect
                value={newTicket.patientId}
                onChange={(value) => handleChangeTicketDetails({ patientId: value?.id ?? null })}
              />
            </Flex>
          </Flex>
        </RadioGroup>
      </Flex>
      <Center>
        <ChooseLabelSection
          labels={labelsToDisplay}
          chosenLabels={chosenLabels}
          value={props.labels.find((label) => label.id === newTicket.labelId)}
          onClickChosenLabel={handleClickChosenLabel}
          onChange={handleClickLabel}
        />
      </Center>
      <Box w="full" maxW="xl">
        <Textarea
          value={newTicket.message}
          lineHeight="1.6"
          minHeight="42px"
          placeholder="Type your message..."
          borderRadius="2xl"
          size="lg"
          rows={1}
          resize="none"
          onChange={(e) => handleChangeTicketDetails({ message: e.target.value })}
        />
      </Box>
      <Button colorScheme="blue" disabled={errors !== null} onClick={onClickCreateTicket}>
        Send
      </Button>
    </Flex>
  );
};

export default NewTicket;
