//! @ngInject
export function billingIssuesTemplateSetupModalCtrl($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService, $filter) {

    const parseData = (data) => {
        if (Array.isArray(data.roles) && Array.isArray(data.roleIds) && data.roles.length === data.roleIds.length) {
            data.roleIds = data.roleIds.map((roleId, idx) => ({ id: roleId.toString(), label: data.roles[idx] }));
        } else {
            data.roleIds = [];
        }
        delete data.roles;
        if (Array.isArray(data.issues) && data.issues.length > 0) {
            data.issues = data.issues.map(issue => ({ id: issue, label: $filter("titlecasefromsnake")(issue) }));
        } else {
            data.issues = [];
        }
        return data;
    };
    
    $scope.data = parseData(angular.copy(data));
    $scope.fields = FormService.getFields(fields, $scope.data);
    $scope.form = {};

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid) {
            return;
        }

        const body = FormService.prepareFormData($scope.fields);
        body.issues = body.issues.map(issue => issue.id);
        body.roleIds = body.roleIds.map(role => parseInt(role.id));

        if (body.issues.length === 0) {
            toaster.pop("error", "Missing issues");
            return;
        }
        if (body.roleIds === 0) {
            toaster.pop("error", "Missing roles");
            return;
        }

        if ($scope.data.id) {
            const editUrl = "agencies/:agencyId/agency_members/:agencyMemberId/billing_issues_templates_settings/:billingIssuesTemplateId"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":billingIssuesTemplateId", $scope.data.id);
            DatabaseApi.put(editUrl , body)
                .then((res) => {
                    toaster.pop('success', "Billing issues template saved");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop('error', "Something went wrong", "Could not change billing issues template settings")
                });
        } else {
            const createUrl = "agencies/:agencyId/agency_members/:agencyMemberId/billing_issues_templates_settings"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);
            DatabaseApi.post(createUrl, body).then((res) => {
                toaster.pop('success', "Billing issues template saved");
                $scope.closeModal();
            }, (err) => {
                toaster.pop('error', "Something went wrong", "Could not create billing issues template");
            });
        }
    };

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };
};
