//! @ngInject
export function caregiverTrainingCenterInfoEditDatesModalCtrl(
  $rootScope,
  $scope,
  DatabaseApi
) {
  let originalVisibleDate;
  let originalCompletionDate;
  let originalComplianceDate;

  this.$onInit = function () {
    $scope.caregiver = $scope.$resolve.caregiver;
    $scope.bundle = $scope.$resolve.bundle;
    $scope.bundleDueDate = $scope.$resolve.dueDate;

    originalVisibleDate =
      $scope.bundleDueDate.visibleDateOverride ||
      $scope.bundleDueDate.visibleDate;
    originalCompletionDate =
      $scope.bundleDueDate.completionDateOverride ||
      $scope.bundleDueDate.completionDate;
    originalComplianceDate =
      $scope.bundleDueDate.complianceDateOverride ||
      $scope.bundleDueDate.complianceDate;

    $scope.visibleDateInput = originalVisibleDate;
    $scope.completionDateInput = originalCompletionDate;
    $scope.complianceDateInput = originalComplianceDate;
    $scope.notesInput = $scope.bundleDueDate.dateOverridesNotes;

    $scope.visibleDateError = null;
    $scope.completionDateError = null;
    $scope.complianceDateError = null;
  };

  $scope.submit = function () {
    const params = {
      notes: $scope.notesInput || null,
      visibleDate:
        $scope.visibleDateInput !== originalVisibleDate
          ? $scope.visibleDateInput
          : undefined,
      completionDate:
        $scope.completionDateInput !== originalCompletionDate
          ? $scope.completionDateInput
          : undefined,
      complianceDate:
        $scope.complianceDateInput !== originalComplianceDate
          ? $scope.complianceDateInput
          : undefined,
    };

    $scope.visibleDateError = null;
    $scope.completionDateError = null;
    $scope.complianceDateError = null;

    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/caregivers/" +
        $scope.caregiver.id +
        "/training_center/bundle_due_dates/" +
        $scope.bundleDueDate.bundleDueDateId +
        "/edit_dates",
      params
    ).then(
      function (res) {
        const updatedBundle = res.data.bundles.find(
          (bundle) => bundle.bundleId === $scope.bundle.bundleId
        );

        const updatedDueDate = updatedBundle.bundleDueDates.find(
          (dueDate) =>
            dueDate.bundleDueDateId === $scope.bundleDueDate.bundleDueDateId
        );

        $scope.$close({
          result: "SUCCESS",
          updatedBundle,
          updatedDueDate,
        });
      },
      function (err) {
        if (err.status === 409) {
          if (err.data.error === "VISIBLE_DATE_AFTER_COMPLETION_DATE") {
            $scope.visibleDateError = "Visible Date after Completion Date";
          } else if (
            err.data.error === "COMPLETION_DATE_AFTER_COMPLIANCE_DATE"
          ) {
            $scope.completionDateError =
              "Completion Date after Compliance Date";
          }
        } else {
          $scope.$close({
            result: "ERROR",
            msg: "Something went wrong",
          });
        }
      }
    );
  };
};
