import { DateUtils } from "../consts/dateUtils";
import { DateRangeDefaultOptions } from "../consts/dateRangeDefaultOptions";
import { CaregiverId } from "../messages/ids";
import { CreateDropdownFilter, DropdownEntity, DropdownFilter } from "../services/dropdownFilter";
import {
  TasksService,
  CaregiverTaskFilters,
  ExtendedCaregiverTaskTypesForWebapp,
} from "../services/tasksService";
import { Moment } from "moment";

const taskTableSettings: NgTable.IParamValues<ExtendedCaregiverTaskTypesForWebapp> = {
  count: 25,
  sorting: {
    "task.createdAt": "desc",
  },
};

interface Filters {
  dateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  };
  dropdowns: DropdownEntity<ExtendedCaregiverTaskTypesForWebapp>[];
}

interface CaregiverAssignedTasksBindings {
  caregiverId: CaregiverId;
}

interface CaregiverAssignedTasksCtrlComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof CaregiverAssignedTasksBindings, string>;
}

//! @ngInject
class CaregiverAssignedTasksCtrl
  implements ng.IComponentController, CaregiverAssignedTasksBindings
{
  private tasks: ExtendedCaregiverTaskTypesForWebapp[] = [];
  private isLoading: boolean;
  private filters: Filters;
  private table: NgTableParams<ExtendedCaregiverTaskTypesForWebapp>;
  private dropdownFilterManager: DropdownFilter<ExtendedCaregiverTaskTypesForWebapp>;
  private dateRangeOptions: DateRangeDefaultOptions;
  dropdownFilterEvents = {};

  constructor(
    private tasksService: TasksService,
    private toaster: toaster.IToasterService,
    private $rootScope: ng.IRootScopeService,
    private NgTableParams: NgTable.ITableParamsConstructor<ExtendedCaregiverTaskTypesForWebapp>,
    private dateUtils: DateUtils,
    private dateRangeDefaultOptions: DateRangeDefaultOptions,
    private createDropdownFilter: CreateDropdownFilter,
  ) {
    this.table = new this.NgTableParams(taskTableSettings, { dataset: [] });
    this.isLoading = false;
    this.filters = {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dropdowns: [
        {
          title: "Task Status",
          entityPath: ["status", "status"],
          options: [],
          values: [],
        },
        {
          title: "Task Name",
          entityPath: ["title"],
          options: [],
          values: [],
        },
      ],
    };
    this.dropdownFilterManager = this.createDropdownFilter<ExtendedCaregiverTaskTypesForWebapp>({
      dropdowns: this.filters.dropdowns,
    });

    this.dropdownFilterEvents = {
      onSelectionChanged: () => this.onFilterChange(),
    };

    this.dateRangeOptions = {
      ...dateRangeDefaultOptions,
      opens: "right",
      eventHandlers: {
        "apply.daterangepicker": () => this.loadTable(),
      }
    };
  }

  // HACK FOR COMPONENT BINDING
  caregiverId: CaregiverId = 0 as unknown as CaregiverId;

  dropdownFilterExtraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
  };

  $onInit = () => {
    this.loadTable();
  };

  loadTable = () => {
    const caregiverTaskFilters: CaregiverTaskFilters = {
      from:
        this.filters.dateRange.startDate !== null
          ? this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.startDate.format("YYYY-MM-DD"))
          : null,
      to:
        this.filters.dateRange.endDate !== null
          ? this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.endDate.format("YYYY-MM-DD"))
          : null,
    };

    this.isLoading = true;
    this.tasksService
      .getCaregiverTasks(this.caregiverId, caregiverTaskFilters)
      .then((tasks) => {
        this.tasks = tasks;
        this.setTable(tasks);
      })
      .catch(() => {
        this.toaster.pop("error", "Something went wrong", "Could not get caregiver tasks");
      })
      .finally(() => (this.isLoading = false));
  };

  resetDueDate = () => {
    this.filters.dateRange = {
      startDate: null,
      endDate: null,
    };

    this.loadTable();
  };

  onFilterChange = () => {
    this.setTable(this.tasks);
  };

  setTable = (tasks: ExtendedCaregiverTaskTypesForWebapp[]) => {
    const filteredTasks = this.filterTasks(tasks);
    this.table = new this.NgTableParams(taskTableSettings, { dataset: filteredTasks });
    this.dropdownFilterManager.setOptionsFromLocalData(tasks);
  };

  filterTasks(tasks: ExtendedCaregiverTaskTypesForWebapp[]) {
    return tasks.filter((task) => {
      return this.dropdownFilterManager.filter(task);
    });
  }
}

export const CaregiverAssignedTasks: CaregiverAssignedTasksCtrlComponentOptions = {
  controller: CaregiverAssignedTasksCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/caregiver-assigned-tasks.html",
  bindings: {
    caregiverId: "<",
  },
};

