import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import useAuth from "../../../shared/hooks/useAuth";
import { queryKeys } from "../../../shared/query-keys";

export function useSearchPatientsQuery(params: { term: string }) {
  const { authInfo } = useAuth();
  const api = useApi();

  return useQuery({
    queryKey: queryKeys.patient.search(params.term),
    enabled: params.term !== "",
    keepPreviousData: params.term !== "",
    select: (data) => data.patients.slice(0, 10),
    queryFn: () =>
      api.get({
        url: "/agencies/:agencyId/search",
        params: {
          path: {
            agencyId: authInfo.agency.id,
          },
          query: {
            patient_id: true,
            patient_first_name: true,
            patient_last_name: true,
            text: params.term,
          },
        },
      }),
  });
}
