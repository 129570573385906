//! @ngInject
export function visitLabelSetupModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, DatabaseApi, toaster, FormService) {


    $scope.visitLabelName = '';
    $scope.visitLabelColor = 'rgba(130,130,130, 0.8)';
    $scope.visitLabelChange = () => {
        console.log($scope.visitLabelName);
    }

    $scope.submit = function () {
        let visitLabelToAdd = {};
        if ($scope.visitLabelName.length > 0 && $scope.visitLabelColor.length > 0) {

            if ($scope.visitLabelColor.includes('rgb(')) {
                // change to rgba 
                $scope.visitLabelColor = $scope.visitLabelColor.replace('rgb', 'rgba');
                $scope.visitLabelColor = $scope.visitLabelColor.substring(0, $scope.visitLabelColor.length - 1);
                $scope.visitLabelColor += ',0.8)';
            }

            visitLabelToAdd.id = -1;
            visitLabelToAdd.name = $scope.visitLabelName;
            visitLabelToAdd.color = $scope.visitLabelColor;
            $scope.visitLabelName = '';
            $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_label`, visitLabelToAdd).then(res => {
                const visitLabelAdded = res.data;
                $uibModalInstance.close(visitLabelAdded);
            })

        }

    };
};
