//! @ngInject
export function remoteApplicationLinkModalCtrl($scope, $rootScope, Consts, $http, $uibModalInstance, toaster, DatabaseApi) {
    $scope.caregvierData = {};
    var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/hr_auth_link'
    $http.get(url).then(function (res) {
        $scope.link = res.data.url;
    }, function (err) {
        toaster.pop("error", "Failed to get remote application link");
    });

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    $scope.copyLink = function () {
        var copyText = document.createElement("TEXTAREA");
        var t = document.createTextNode($scope.link);
        copyText.appendChild(t);
        copyText.style.height = '0px';
        document.body.appendChild(copyText);
        copyText.select();
        document.execCommand("copy");
        toaster.pop("success", "link is copied to the clipboard");
    };

    function isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function isValidPhonenumber(phonenumber) {
        const re = /^\d{10}$/;
        return re.test(phonenumber);
    }

    $scope.sendLink = () => {
        const url = `agencies/${$rootScope.agencyId}/agency_member/${$rootScope.agencyMemberId}/send_application_link`;
        let body = {};
        if (isValidEmail($scope.caregvierData.phonenumberOrEmail)) {
            body = {
                type: "Email",
                caregiverName: $scope.caregvierData.caregiverName,
                email: $scope.caregvierData.phonenumberOrEmail
            };
        } else if (isValidPhonenumber($scope.caregvierData.phonenumberOrEmail)) {
            body = {
                type: "Phonenumber",
                caregiverName: $scope.caregvierData.caregiverName,
                phonenumber: $scope.caregvierData.phonenumberOrEmail
            }
        } else {
            return toaster.pop("error", "Email or phone number is not valid");
        }
        DatabaseApi.put(url, body).then(
            function (res) {
                toaster.pop("info", "SMS sent");
                $scope.caregvierData = {};
            },
            function (err) {
                toaster.pop("error", "Couldn't send the SMS. Please contact support");
            }
        );
    }
};