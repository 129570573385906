export const complianceConsts = {
    docBNotNeeded: [
        'U.S. Passport or U.S. Passport Card',
        'Permanent Resident Card or Alien Registration Receipt Card',
        'Employment Authorization Document Card',
        'Foreign passport with Form I-94 or Form I-94A with Arrival-Departure Record, and containing an endorsement to work',
        'Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A',
        'Foreign passport containing a Form I-551 stamp or Form I-551 printed notation'
    ],
    customComplianceItems: [
        {
            showSelect: false,
            name: 'I-9',
            sectionLabel: 'General',
            docA: true,
            docB: true,
            expires: true,
            comments: true,
            options: {
                docA: [
                    {
                        name: 'U.S. Passport or U.S. Passport Card',
                        needsB: false
                    }, {
                        name: 'Permanent Resident Card or Alien Registration Receipt Card',
                        needsB: false
                    }, {
                        name: 'Employment Authorization Document Card',
                        needsB: false
                    }, {
                        name: 'Foreign passport with Form I-94 or Form I-94A with Arrival-Departure Record, and containing an endorsement to work',
                        needsB: false
                    }, {
                        name: 'Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A',
                        needsB: false
                    }, {
                        name: 'Foreign passport containing a Form I-551 stamp or Form I-551 printed notation',
                        needsB: false
                    }, {
                        name: 'Driver\'s License',
                        needsB: true
                    }, {
                        name: 'ID Card',
                        needsB: true
                    }
                ], docB: [
                    {
                        name: 'U.S. Social Security account number card that is unrestricted'
                    }, {
                        name: 'Consular Report of Birth Abroad'
                    }, {
                        name: 'Certification of Birth Abroad issued by the U.S. Department of State'
                    }, {
                        name: 'Certification of Report of Birth issued by the U.S. Department of State'
                    }, {
                        name: 'Original or certified copy of a birth certificate issued by a state, county, municipal authority or outlying territory of the United States bearing an official seal'
                    }, {
                        name: 'Native American tribal document'
                    }, {
                        name: 'U.S. Citizen ID Card'
                    }, {
                        name: 'Identification Card for Use of Resident Citizen in the United States'
                    }, {
                        name: 'Employment authorization document issued by the Department of Homeland Security'
                    }
                ]
            }
        },
        {
            showSelect: false,
            name: 'References',
            sectionLabel: 'General',
            reference1: true,
            reference2: true,
            degree: true, ////
            comments: true
        },
        {
            showSelect: false,
            name: 'Criminal background',
            sectionLabel: 'General',
            sentOutDate: true,
            receivedDate: true,
            results: true,
            comments: true,
            options: {
                results: ['Submitted', 'ok', 'Temporary Denial', 'Final Denial', 'Resubmitted']
            }
        },
        {
            showSelect: false,
            name: 'Last employment agency',
            sectionLabel: 'General',
            lastEmployment: true, ////
            startDate: true, /////
            endDate: true, /////
            results: true,
            comments: true
        },
        {
            showSelect: false,
            name: 'Training School',
            sectionLabel: 'General',
            trainingSchool: true, ////
            certificationDate: true, /////
            instructor: true, /////
            verificationDate: true, ////
            onFile: true, /////
            comments: true
        },
        {
            name: 'Face mask Provided (2017)',
            sectionLabel: 'Medical',
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Flu Vaccine (2017)',
            sectionLabel: 'Medical',
            results: true,
            comments: true,
            options: {
                results: ['Completed (In-Office)',
                    'Completed (Outside of off',
                    'Exempt',
                    'Declined']
            }
        }, {
            name: 'Annual health Assessment',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Chest X- Ray',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }

        }, {
            name: 'Drug Screen',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Flu Shot',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Flu Vaccination',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Accepted',
                    'Declined']
            }
        }, {
            name: 'N1H1 Vaccination',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Accepted',
                    'Declined']
            }
        }, {
            name: 'PPD',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Pre employment physical',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Quantiferon',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Rubella Immune',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Immune',
                    'Not Immune']
            }
        }, {
            name: 'Rubella (MMR)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Rubeola Immune',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Immune',
                    'Not Immune']
            }
        }, {
            name: 'Rubeola (MMR1)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Rubeola (MMR2)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'TB - Screen',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: '3 month evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: '6 month evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Annual Evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Orientation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Supervisory Visit',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }

    ],
    fieldTypesMap: {
        requiresComments: `Comments`,
        requiresSentOutDate: 'Date',
        requiresReceivedDate: `Received Date`,
        requiresCompletionDate: `Completion Date`,
        requiresResults: `Results`,
        requiresExpiryDate: `Expires`,
        requiresScore: `Score`,
        requiresDocA: `Doc A`,
        requiresDocB: `Doc B`,
        requiresReference1: `Reference 1`,
        requiresReference2: `Reference 2`,
        requiresTrainingSchool: `Training School`,
        requiresInstructor: `Instructor`,
        requiresDegree: `Degree`,
        requiresLastEmployment: `Last Employment`,
        requiresStartDate: `Start Date`,
        requiresEndDate: `End Date`,
        requiresCertificationDate: `Certification Date`,
        requiresVerificationDate: `Verification Date`,
        requiresOnFile: `On File`
    }
};