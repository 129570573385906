type AssignedTaskEditMode = "CurrentTask" | "GoingForward";

interface PatientTaskModalAssignedTypeBindings {
  selection: AssignedTaskEditMode;
}

interface PatientTaskModalAssignedTypeCtrlComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof PatientTaskModalAssignedTypeBindings, string>;
}

//! @ngInject
class PatientTaskModalAssignedTypeCtrl
  implements ng.IComponentController, PatientTaskModalAssignedTypeBindings
{
  selection!: AssignedTaskEditMode;
}

export const PatientTaskModalAssignedType: PatientTaskModalAssignedTypeCtrlComponentOptions = {
  controller: PatientTaskModalAssignedTypeCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/patient-task-modal-assigned-type.html",
  bindings: {
    selection: "=",
  },
};
