export const CalendarItemType = {
    UNSTAFFED_VISIT: "UNSTAFFED_VISIT",
    DELETED_VISIT: "DELETED_VISIT",
    MISSED_VISIT: "MISSED_VISIT",
    BROADCASTED_VISIT: "BROADCASTED_VISIT",
    ASSIGNED_VISIT: "ASSIGNED_VISIT",
    PAID_TIME_OFF: "PAID_TIME_OFF",
    ASSIGNED_TASK: "ASSIGNED_TASK",
    PATIENT_VACATION: "PATIENT_VACATION",
    BROADCASTED_IN_THE_PAST: "BROADCASTED_IN_THE_PAST",
    CAREGIVER_ABSENCE: "CAREGIVER_ABSENCE",
};
