"use strict";

/*! @ngInject */
export function agencyMemberMentionsDoneCtrl(
  $scope,
  $rootScope,
  $timeout,
  NgTableParams,
  toaster,
  noteService,
  entityNotesModalService,
  DatabaseApi,
  agencyMemberMentionsService,
  Storage,
  selectionLogic,
) {
  $scope.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();

  function initialize() {
    initTableColumns();
    $scope.loadItems();
  }

  function initTableColumns() {
    var columns = Storage.getObject("agencyMemberMentionsTableSettings");

    if (columns && Object.keys(columns).length) {
      Object.keys(columns).forEach(function (t) {
        if (columns[t]) $scope.tableColumnsLength++;
      });

      $scope.tableColumns = columns;
    } else {
      $scope.tableColumns = {
        "Date posted": true,
        "Posted by": true,
        Note: true,
        Category: true,
      };
    }

    $scope.$watch(
      "tableColumns",
      function () {
        if ($scope.tableColumns) {
          Storage.setObject(
            "agencyMemberMentionsTableSettings",
            $scope.tableColumns
          );
          $scope.tableColumnsLength = 0;
          Object.keys($scope.tableColumns).forEach(function (t) {
            if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
          });
          //   filterTable();
        }
      },
      true
    );
  }

  $scope.globalFilter = { val: "" };
  $scope.applyGlobalSearch = function (term) {
    var filter = { $: term };
    if ($scope.tableParams) {
      angular.extend($scope.tableParams.filter(), filter);
      $timeout(() => $scope.selectionLogic.initItemsCollection($scope.tableParams.data));
    } else {
      console.log("no table");
    }
  };

  function initTable(mentions) {
    $scope.tableData = mentions;
    var options = {
      count: 25,
      sorting: { createdAt: "desc" },
    };
    $scope.tableParams = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: $scope.tableData,
    });

    if ($scope.globalFilter.val) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
  }

  const getProfile = (note) => {
    let profileType = "Caregiver";
    let profileId = note.caregiverId;
    if (note.caregiverId === null && note.patientId !== null) {
      profileType = "Patient";
      profileId = note.patientId;
    }
    return {
      type: profileType,
      id: profileId,
    };
  };

  const initSelection = function () {
    $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
      $scope.selectionLogic.addItemToCollection(item);
    }, "mentionId");

    $scope.mentions.forEach((note) => {
      $scope.selectionLogic.initItem(note);
    });
  };

  $scope.openMention = function (mention) {
    agencyMemberMentionsService
    .markMentionsAsRead([mention.mentionId])
    .then(async () => {
      const noteDetails = await noteService.getNoteDetails(mention.content.noteId);
      const profile = getProfile(noteDetails);
      entityNotesModalService.handleNotesModalOpen({
        profileId: profile.id,
        notesType: profile.type
      });
      $scope.loadItems();
      if (mention.content.seenAt === null && $rootScope.mentionesCount > 0) {
        $rootScope.mentionesCount -= 1;
      }
    })
    .catch(() => {
      toaster.pop("error", "Failed to mark note mention as read");
    });
  };

  $scope.loadItems = function () {
    $scope.isLoading = true;
    agencyMemberMentionsService
      .getMentions({ done: true })
      .then((res) => {
        $scope.isLoading = false;
        $scope.mentions = res.data.mentions;
        initSelection();
        initTable($scope.mentions);
      })
      .catch(() => {
        $scope.isLoading = false;
        toaster.pop("error", "Failed to load mentions");
      });
  };

  $scope.markAsUndone = function (mention) {
    agencyMemberMentionsService
      .markMentionsAsUndone([mention.mentionId])
      .then(() => {
        toaster.pop("success", "Note mention marked as undone");
        $scope.loadItems();
      })
      .catch((err) => {
        toaster.pop("error", "Failed to mark note mention as undone");
      });
  };

  $scope.markSelectedAsUndone = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as undone",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsUndone(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as undone");
        $scope.loadItems();
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as undone");
      });
  };

  $scope.markSelectedAsRead = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as read",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsRead(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as read");
        $scope.loadItems();

        $rootScope.mentionesCount += getUnSeenCountFromIds(mentionIds);
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as read");
      });
  };

  $scope.markSelectedAsUnread = function () {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    const mentionIds = selectedRows.map(mention => mention.mentionId);
    if (mentionIds.length === 0) {
      return toaster.pop(
        "warning",
        "Failed to mark as unread",
        "No mention was selected"
      );
    }

    agencyMemberMentionsService
      .markMentionsAsUnread(mentionIds)
      .then(() => {
        toaster.pop("success", "Note mention marked as unread");
        $scope.loadItems();

        $rootScope.mentionesCount += getSeenCountFromIds(mentionIds);
      })
      .catch(() => {
        toaster.pop("error", "Failed to mark note mention as unread");
      });
  };

  function getSeenCountFromIds(mentionIds) {
    return $scope.tableData.filter(
      (mention) =>
        mentionIds.includes(mention.mentionId) &&
        mention.content.seenAt !== null
    ).length;
  }

  function getUnSeenCountFromIds(mentionIds) {
    return $scope.tableData.filter(
      (mention) =>
        mentionIds.includes(mention.mentionId) &&
        mention.content.seenAt === null
    ).length;
  }

  initialize();
};
