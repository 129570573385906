export function sideNavigationItem() {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/side-navigation-item.html',
        scope: {
            title: '<',
            labelText: '<',
            stateName: '<',
            sectionName: '<',
            iconPath: '<',
            permissionKey: '<',
            materialIcon: '<',
            subItems: '='
        },
        /* @ngInject */
        controller: function ($scope, $rootScope, $state) {
            $scope.onMouseEnter = function () {
                $rootScope.sidenavHover($scope.sectionName);
            };

            $scope.onChevClick = function () {
                event.stopPropagation();
                $rootScope.sidenavFocus($scope.sectionName);
            };

            $scope.onMainItemClick = function () {
                event.stopPropagation();
                $rootScope.sidenavFocus($scope.sectionName);
                if (!$scope.subItems.length) {
                    $state.go($scope.stateName);
                } else {
                    $state.go($scope.subItems[0].stateName);
                }
            };

            $scope.onSubItemClick = function (stateName) {
                if (stateName) {
                    $state.go(stateName, {}, { reload: true });
                }
            };

            if ($scope.labelText === "unread_count") {
                $scope.$watch(
                    function () {
                        return $rootScope.mentionesCount;
                    },
                    function () {
                        $scope.labelText = $rootScope.mentionesCount;
                    },
                    true
                );
            }
        }
    };
}
;
