import { QueryKey } from "@tanstack/react-query";

const UNSET = "__UNSET__" as const;

export function createQueryKeys<$Base, $Keys>(base: $Base, keys: $Keys) {
  const newKeys = {} as {
    [key in keyof $Keys]: $Keys[key] extends (arg: infer U) => void
      ? ((arg: U) => QueryKey) & { K: QueryKey }
      : () => QueryKey;
  };

  for (const [key, value] of Object.entries(keys)) {
    if (value === null) {
      (newKeys as any)[key] = () => [base, key];
    }

    if (typeof value === "function") {
      (newKeys as any)[key] = (v: unknown = UNSET) => [
        base,
        key,
        ...(v === UNSET ? [] : [value(v)]),
      ];
      (newKeys as any)[key].K = [base, key];
    }
  }

  return newKeys;
}
