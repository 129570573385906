//! @ngInject
export function visitBroadcastOptionsModalCtrl($scope, $rootScope, $uibModalInstance) {
    const ceil = $rootScope.agencyId === 49 ? 25 : 100;
    const step = $rootScope.agencyId === 49 ? 0.1 : 1;
    const showTicks = $rootScope.agencyId === 49 ? 1 : 20;
    const precision = $rootScope.agencyId === 49 ? 1 : undefined;
    
    $scope.state = {
      allowMultipleCaregiversToSelectShifts: false,
      miles: 10,
    };

    $scope.handleClickStartBroadcast = () => {
      if ($scope.$resolve.onSubmit) {
        $scope.$resolve.onSubmit($scope.state);
        $uibModalInstance.close();
      }
    };

    $scope.patientName = $scope.$resolve.patientName;
    $scope.slider = {
      options: {
        floor: 0,
        ceil: ceil,
        step: step,
        precision: precision,
        showSelectionBar: true,
        translate: (value) => `${value} Mi.`,
        showTicks: showTicks,
        customValueToPosition: (val, minVal, maxVal) => {
          val = Math.sqrt(val);
          minVal = Math.sqrt(minVal);
          maxVal = Math.sqrt(maxVal);
          const range = maxVal - minVal;
          return (val - minVal) / range;
        },
        customPositionToValue: (percent, minVal, maxVal) => {
          maxVal = Math.sqrt(maxVal);
          const value = percent * maxVal;
          return Math.pow(value, 2);
        },
        getPointerColor: () => "#3077db",
        getSelectionBarColor: () => "#3077db",
      },
    };
  };
