/*! @ngInject */
export function agencyPatientStatusService($rootScope, DatabaseApi, toaster) {
      /* Decalare factory return object */
      const service = {
        patientStatuseCache: null
      };

      // ============ Agency Patient Status ================

      /**
       * Get agency patient statuses
       */
      service.getAgencyPatientStatuses = async () => {
        if (service.patientStatuseCache) {
          return service.patientStatuseCache;
        } else {
          const tempStatuses = DatabaseApi.agencyPatientStatuses();
          if (tempStatuses.length > 0) {
            return tempStatuses;
          }
        }
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_statuses"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId);

        return DatabaseApi.get(url).then((res) => {
          service.patientStatuseCache = angular.copy(res.data.list);
          return res.data.list;
        });
      };

      service.editAgencyPatientStatusActive = (statusText, body) => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_statuses/:statusText"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":statusText", statusText);

        return DatabaseApi.put(url, body).then(
          function (res) {
            service.patientStatuseCache = null;
            toaster.pop("success", "Patient Status updated successfully", "");
          },
          function (err) {
            toaster.pop(
              "error",
              "Something went wrong",
              "Could not change Patient Status status"
            );
          }
        );
      };

      service.getStatusClass = (statusText) => {
        switch (statusText) {
          case 'ACTIVE':
          case 'ELIGIBLE': {
            return 'green';
          }
          case 'ACCEPTED': {
            return 'lightblue';
          }
          case 'HOSPITALIZED':
          case 'ON_HOLD':
          case 'VACATION':
          case 'PENDING_FILES': {
            return 'orange';
          }
          case 'DISCHARGED':
          case 'DECEASED':
          case 'REFERRAL': {
            return 'red';
          }
          default: {
            return 'default';
          }
        }
      }

      return service;
    };
