export function billingVisitActionDataGenerator() {
  var generateNewVisitActionData = function ({
    displayText,
    className = "",
    tooltip,
    hideWhen = () => false
  }, methodToExecute) {
    return {
      displayText: displayText,
      execute: methodToExecute,
      className: className,
      tooltip: tooltip,
      hideWhen: hideWhen
    };
  };

  this.createNewVisitActionData = generateNewVisitActionData;
};

export function visitsTableScopeService() {

  var scopeService = {

    linkSelection: function (scope) {

      scope.isSelectionVirtualized = function () {
        return scope.selectionLogic && scope.selectionLogic.type === "virtualized";
      };

      if (scope.isSelectionVirtualized()) {
        scope.selectionHeaderTemplateName = "selection-table-page-header.html";
        scope.onItemSelectionChanged = function (item) {
          scope.selectionLogic.onRenderedItemSelectionChanged(item);
        };
      }
      else {
        scope.selectionHeaderTemplateName = "selection-standard-header.html";
        scope.onItemSelectionChanged = function (item) {
          scope.selectionLogic.onItemSelectionChanged(item);
        };
      }

    }

  };

  return scopeService;

};

//! @ngInject
export function billingVisitsTable(visitsTableScopeService) {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/billing-visits-table.html',
    scope: {
      tableParams: '=',
      tableItemsTitle: '@',
      clickRow: '&',
      selectionLogic: '=',
      isRowDisabled: '=',
      isSortingEnabled: '=',
      actionsDataArray: '=',
      showInvoiceCols: "=",
      showBillableCols: "=",
      showExportCols: "=",
      showVisitsToExportCols: "=",
    },

    link: function (scope) {
      scope.clickTableRow = function (item) {
        return scope.clickRow({ row: item });
      };

      visitsTableScopeService.linkSelection(scope);

    }

  };
};

//! @ngInject
export function importedVisitsTable(visitsTableScopeService) {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/imported-visits-table.html',
    scope: {
      tableParams: '=',
      tableItemsTitle: '@',
      type: '@',
      clickRow: '&',
      selectionLogic: '=',
      isSortingEnabled: '=',
    },

    link: function (scope) {
      scope.clickTableRow = function (item) {
        return scope.clickRow({ row: item });
      };

      scope.showCaregiverColumn = false;
      if (scope.type === 'update') {
        scope.showCaregiverColumn = true;
      }

      visitsTableScopeService.linkSelection(scope);

    }

  };
};

//! @ngInject
export function billingVisitsToExportTable(visitsTableScopeService) {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/billing-visits-to-export-table.html',
    scope: {
      tableParams: '=',
      tableItemsTitle: '@',
      clickRow: '&',
      selectionLogic: '=',
      isRowDisabled: '=',
      isSortingEnabled: '=',
      showAdjustments: '='
    },

    link: function (scope) {
      scope.clickTableRow = function (item) {
        return scope.clickRow({ row: item });
      };
      visitsTableScopeService.linkSelection(scope);
    }
  };
};

