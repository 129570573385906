import { PatientId } from "../messages/ids";
import { PatientAvailability, PatientAvailabilityTimeFrames, PatientExtendedData } from "../messages/patient";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export class PatientService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  /**
   * Get patient by id
   */
  async getById(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/patients/:patientId",
      params: {
        agencyId: this.$rootScope.agencyId,
        patientId: patientId,
      },
    });

    return this.api.get<PatientExtendedData>(url).then(({ data }) => data);
  }



  /**
   * Get PatientAvailabilityTimeframes list
   */
   async getPatientAvailabilityTimeframes() {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/availability_time_frames",
      params: {
        agencyId: this.$rootScope.agencyId,        
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<PatientAvailabilityTimeFrames>(url).then(({ data }) => data);
  }


  /**
   * Get PatientAvailabilityTimeframe by patient
   */
   async getPatientAvailabilitySettings(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/availability_settings",
      params: {
        patientId: patientId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        agencyId: this.$rootScope.agencyId,        
      },
    });

    return this.api.get<PatientAvailabilityTimeFrames>(url).then(({ data }) => data);
  }
    /**
   * Update PatientAvailabilityTimeframe by patient
   */
     async updatePatientAvailabilitySettings(patientId: PatientId,data: PatientAvailability) {
      const url = this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/availability_settings",
        params: {
          patientId: patientId,
          agencyMemberId: this.$rootScope.agencyMemberId,          
          agencyId: this.$rootScope.agencyId,        
        },
      });
  
      return this.api.put(url,data).then(({ data }) => data);
    }
}
