export const entityNoteSettingPredefinedAnswers = {
    templateUrl: "admin/views/entity-notes/entity-note-settings-predefined-answers.html",
    bindings: {
        item: '<'
    },
    //! @ngInject
    controller: function ($scope, DatabaseApi, entityNoteService, NgTableParams, $rootScope, toaster, SweetAlert) {
        this.predefinedAnswersGlobalFilter = { val: "" };
        $scope.predefinedAnswersFilters = {
            showOnlyActiveAnswers: true
        };

        const predefinedAnswersFilterByMethods = {
            showActiveAgencySubjects: (answer, showActiveAnswer) => {
                return showActiveAnswer ? answer.active === true : true
            }
        };

        const filterAgencyNoteSubjectsTable = () => {
            if (!$scope.$ctrl.item.predefinedAnswers) return;
            let filters = [];
    
            const showOnlyActiveAnswers = $scope.predefinedAnswersFilters.showOnlyActiveAnswers;
            filters.push(function (item) { return predefinedAnswersFilterByMethods.showActiveAgencySubjects(item, showOnlyActiveAnswers); });
    
            let filteredAnswers = $scope.$ctrl.item.predefinedAnswers;
            if (filters.length > 0) {
                filteredAnswers = filteredAnswers.filter(function (noteSubject) {
                    let isFiltered = true;
                    for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                        isFiltered = isFiltered && filters[idx](noteSubject);
                    }
                    return isFiltered;
                });
            }
    
            initPredefinedAnswersTable(filteredAnswers);
        }

        const initPredefinedAnswersTable = (items) => {
            let options = {
                count: 10,
                sorting: { text: "asc" }
            };

            $scope.predefinedAnswersTable = new NgTableParams(options, {
                dataset: items
            });
        };

        this.applyPredefinedAnswersGlobalSearch = () => {
            const filter = { $:this.predefinedAnswersGlobalFilter.val };
            if ($scope.predefinedAnswersTable) {
                angular.extend($scope.predefinedAnswersTable.filter(), filter);
            }

            let flattedPredefinedAnswerSubject = this.predefinedAnswersGlobalFilter.val.replace( /  +/g, ' ' ).trim().toLowerCase();
            if (this.allPredefinedAnswersTitles.includes(flattedPredefinedAnswerSubject)) {
                this.similarPredefinedTitle = true;
            } else {
                this.similarPredefinedTitle = false;
            }
        };

        const addPredefinedAnswer = () => {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/entity_notes/predefined_answer"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);

            const body = {
                entityNoteSettingId: $scope.$ctrl.item.id,
                text: this.predefinedAnswersGlobalFilter.val.replace(/  +/g, ' ').trim()
            };

            this.isSubmmittingPredefinedAnswer = true;
            DatabaseApi.post(url, body).then((res) => {
                $scope.$ctrl.item.predefinedAnswers.push(res.data);
                filterAgencyNoteSubjectsTable();
                toaster.pop('success', "Predefined answer added successfully");
            }).catch(function (_) {
                toaster.pop("error", "Failed to add predefined answer");
            }).finally(() => {
                this.predefinedAnswersGlobalFilter.val = "";
                this.isSubmmittingPredefinedAnswer = true;
            });
        }

        this.handleAddPredefinedAnswer = () => {
            if ($scope.$ctrl.item.agencyId === null) {
                entityNoteService.createAgencyEntityNoteSetting($scope.$ctrl.item).then((res) => {
                    $scope.$ctrl.item.id = res.data;
                    $scope.$ctrl.item.agencyId = $rootScope.agencyId;
                    addPredefinedAnswer();
                }).catch(function (_) {
                    toaster.pop("error", "Failed to add predefined answer, please refresh the page and try again");
                });

            } else {
                addPredefinedAnswer();
            }
        };

        const editPredefinedAnswer = (predefinedAnswer) => {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/entity_notes/predefined_answer/:answerId"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":answerId", predefinedAnswer.id);

            const body = {
                entityNoteSettingId: $scope.$ctrl.item.id,
                text: predefinedAnswer.text,
                active: predefinedAnswer.active
            };

            DatabaseApi.put(url, body).then((res) => {
                toaster.pop('success', "Predefined answer updated successfully");
            }).catch(function (_) {
                toaster.pop("error", "Failed to update predefined answer");
            });
        };

        this.handleEditPredefinedAnswer = (predefinedAnswer) => {
            if ($scope.$ctrl.item.agencyId === null) {
                entityNoteService.createAgencyEntityNoteSetting($scope.$ctrl.item).then((res) => {
                    $scope.$ctrl.item.id = res.data;
                    $scope.$ctrl.item.agencyId = $rootScope.agencyId;
                    predefinedAnswer.entityNoteSettingId = res.data;
                    editPredefinedAnswer(predefinedAnswer);
                }).catch(function (_) {
                    toaster.pop("error", "Failed to update predefined answer");
                });
            } else {
                editPredefinedAnswer(predefinedAnswer);
            }
        };

        this.handleKeydownPredefinedAnswer = ($event) => {
            if ($event && $event.key === 'Enter') {
                $event.preventDefault();
                $event.target.blur();
            }
        };

        $scope.$watch('predefinedAnswersFilters', function () {
            filterAgencyNoteSubjectsTable();
        }, true);

        this.$onInit = function () {
            this.allPredefinedAnswersTitles = $scope.$ctrl.item.predefinedAnswers.map(answer => answer.text.trim().toLowerCase());
            filterAgencyNoteSubjectsTable();
        };
    }
};