//! @ngInject
export function videoTestModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, test, dueDateId, caregiverId, DatabaseApi, toaster, $window){

    window.scrollTo(0,0);

    $scope.test = test;

    $scope.langs = [
        "English",
        "Spanish",
        "Russian",
        "Chinese",
        "French",
        "Arabic"
    ];

    $scope.lang = {val: 'English'};

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };


    $scope.getTestPdf = function(){
        var body = {
            dueDateId: dueDateId,
            language: $scope.lang.val
        };

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/caregivers/' + caregiverId + '/orientation-test-pdf', body).then(function(res){
            $window.open(res.data.url);
        }, function(err){
            toaster.pop('error', "Something Went Wrong", 'Please try again');
        });
    };


};
