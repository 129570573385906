import moment from "moment";

//! @ngInject
export function reportToolMain($rootScope, $scope, $state, $stateParams, $filter, $uibModal, toaster, reportService,uiGridExporterConstants,uiGridExporterService) {
  $scope.report = {
    allowEmpty: true,
    name: '',
    primaryTable: '',
    columns: [],
    filters: []
  };
  $scope.loadingReport = false;
  const sortOptions = ["NONE", "ASC", "DESC"];
  const logicalRelationships = ["AND", "OR"];
  $scope.savedReportId = $stateParams.savedReportId;
  $scope.accessType = $stateParams.accessType;
  $scope.mySavedReportsNames = $stateParams.mySavedReportsNames;

  function initialize() {
    if ($scope.savedReportId === null) {
      reportService.getReportsSchema().then(res => {
        $scope.reportsSchema = res;
      });
    } else {
      reportService.getSavedReportSchema().then(res => {
        $scope.reportsSchema = res;
        handleLoadSavedReport($scope.savedReportId);
      });
    }
  }

  function getAggregatesByType (columnType, isArray) {
    return $scope.reportsSchema.aggregateColumnTypes.filter(aggType => aggType.parameterType === columnType && aggType.parameterArray === isArray);
  }

  function getLogicalOperatorsByType (columnType, isArray) {
    return $scope.reportsSchema.allowedLogicalOperators.filter(operator => operator.array === isArray && operator.columnType.includes(columnType));
  }

  $scope.handleTableMainClicked = (tableName) => {
    $scope.report.primaryTable = tableName;
  }
  
  $scope.handleRefreshTables = () => {
    $scope.report.tables= getTablesArrayByColumns($scope.report.columns);
  }
  $scope.handleClearReport = () => {
    $scope.report = {
      allowEmpty: true,
      name: '',
      primaryTable: '',
      columns: [],
      filters: []
    };
    for (const table of $scope.reportsSchema.tables) {
      for (const column of table.columns) {
        delete column.selected;
        delete column.filterSelected;
      }
    }
  }

  $scope.handleColumnClicked = (tableName, column) => {
    column.selected = !column.selected;
    const existsIndex = $scope.report.columns.findIndex(
      (existsColumn) =>
        existsColumn.name === column.name &&
        existsColumn.tableName === tableName
    );
    // Add if not exists remove if exists
    if (existsIndex === -1) {
      column.selected = true;
      $scope.report.columns.forEach(existsColumn => {
        if (existsColumn.name === column.name) {
          if (existsColumn.label === $filter("camelCase")(existsColumn.name)) {
            existsColumn.label = `${$filter("camelCase")(existsColumn.name)} (${existsColumn.tableName})`;
          }
          column.label = `${$filter("camelCase")(column.name)} (${tableName})`;
        }
      });
      // First column sets as primary table
      if (!$scope.report.primaryTable) {
        $scope.report.primaryTable = tableName;
      }
      $scope.report.columns.push({
        ...column,
        tableName: tableName,
        aggregateColumnTypes: getAggregatesByType(column.type, column.array),
        sortOptions: sortOptions,
        order: sortOptions[0]
      });
      
    } else {
      $scope.report.columns.splice(existsIndex, 1);
    }
    $scope.handleRefreshTables();
    
  };

  $scope.handleFilterClicked = ( tableName, filter ) => {
    filter.filterSelected = !filter.filterSelected;

    const existsIndex = $scope.report.filters.findIndex(existsFilter => existsFilter.name === filter.name && existsFilter.tableName === tableName);
    // Add if not exists remove if exists
    if (existsIndex === -1) {
      $scope.report.filters.forEach(existsFilter => {
        if (existsFilter.name === filter.name) {
          if (existsFilter.label === $filter("camelCase")(existsFilter.name)) {
            existsFilter.label = `${$filter("camelCase")(existsFilter.name)} (${existsFilter.tableName})`;
          }
          filter.label = `${$filter("camelCase")(filter.name)} (${tableName})`;
        }
      });
      $scope.report.filters.push({
        ...filter,
        tableName: tableName,
        logicalOperators: getLogicalOperatorsByType(filter.type, filter.array),
        logicalRelationships: logicalRelationships,
        logicalRelationshipWithNextFilter: logicalRelationships[0], // Default to And
        isLocked: false
      });
    } else {
      $scope.report.filters.splice(existsIndex, 1);
    }
  };

  /**
   * Add filter duplication with un-filled fields
   * Add isCloned flag
   */
  $scope.handleFilterClone = (filter) => {
    $scope.report.filters.push({
      ...filter,
      isCloned: true,
      operator: '',
      value: ''
    });
  }

  /**
   * Remove filter by index - for now only used for cloned filters.
   */
  $scope.handleRemoveFilter = (index, tableName, columnName) => {
    $scope.reportsSchema.tables.find(table => table.tableName === tableName).columns.find(column => column.name === columnName).filterSelected = false;
    $scope.report.filters.splice(index, 1);
  }
  $scope.handleColumnNotInAggFilters = (tableName,column) => {
    if ($scope.report.filters){
    for (var i = 0; i < $scope.report.filters.length; i++) {
      let filter = $scope.report.filters[i];
      if(filter.aggregate && filter.name === column.name && tableName === filter.tableName){
        return false;
      }
    }
  }
    return true;

}
$scope.handleColumnInAggFilters = (tableName,column) => {
  if ($scope.report.filters){
  for (var i = 0; i < $scope.report.filters.length; i++) {
    let filter = $scope.report.filters[i];
    if(column.aggregate && filter.name === column.name && tableName === filter.tableName){
      return false;
    }
  }

}
return true;
}
  $scope.handleSaveReport = () => {
    if (!$scope.report.name) {
      toaster.pop("warning", "Report name is required");
      return;
    } else if ($scope.savedReportId === null && $scope.mySavedReportsNames.includes($scope.report.name)) {
      toaster.pop("warning", "Report name already exists");
      return;
    } else if ($scope.report.filters.filter(filter => isValueEmpty(filter.value, filter.operator)).length > 0) {
        toaster.pop("warning", "There are empty filter values");
        return;
    }

    const currentReport = angular.copy($scope.report);
    const reportToSave = {
      name: currentReport.name,
      reportTemplate: {
        ...currentReport,
        primaryTable: { tableName: currentReport.primaryTable, columns: [] },
        tables: getTablesArrayByColumns(currentReport.columns),
        columns: parseColumns(currentReport.columns),
        filters: parseFilters(currentReport.filters)
      },
    };

    if ($scope.savedReportId === null) {
      reportService.createSavedReport(reportToSave).then(() => {
        toaster.pop("success", "Success", "Successfully created report");
      }).catch(() => {
        toaster.pop("error", "Something went wrong", "Failed to create report");
      });
    } else {
      reportService.editSavedReport($scope.savedReportId, reportToSave).then(() => {
        toaster.pop("success", "Success", "Successfully saved report");
      }).catch(() => {
        toaster.pop("error", "Something went wrong", "Failed to save report");
      });
    }
  }
  $scope.gridApi= null;
  var universalBOM = "\uFEFF";
  $scope.gridOptions = {
    exporterMenuCsv: true,
    exporterMenuPdf: false,
    enableGridMenu: true,
    enableGroupHeaderSelection: true,
    treeRowHeaderAlwaysVisible: false,
    paginationPageSizes: [25, 50, 100],
    paginationPageSize: 50,
    showColumnFooter:false,
    onRegisterApi: function(gridApi){
      $rootScope.gridApi = gridApi;
    },
    enableFiltering: true

  };

  $scope.handleRunReport = (format= "json") => {
    if ($scope.report.filters.filter(filter => isValueEmpty(filter.value, filter.operator)).length > 0) {
      toaster.pop("warning", "There are empty filter values");
      return;
    }

    $scope.loadingReport = true;
    const currentReport = angular.copy($scope.report);
    const reportToSave = {
      ...currentReport,
      primaryTable: { tableName: currentReport.primaryTable, columns: [] },
      tables: getTablesArrayByColumns(currentReport.columns),
      columns: parseColumns(currentReport.columns),
      filters: parseFilters(currentReport.filters),
      format: format,
      timezone: moment.tz.guess(true)
    };
    if(reportToSave.columns.length > 0) {
      let columnDefs = [];
      for (let i = 0; i < reportToSave.columns; i++) {
        let col = reportToSave.columns[i];
        columnDefs.push({ name: col.label });
      }
      $scope.gridOptions.columnDefs = columnDefs;
    }

    reportService.executeReport(reportToSave).then(({ lines }) => {

      $scope.gridOptions.exporterExcelFilename = getFileName(reportToSave.name,"xslx");
      $scope.gridOptions.exporterCsvFilename = getFileName(reportToSave.name);
      $scope.gridOptions.data=lines;

      toaster.pop("success", "Success", "Successfully created report");
    }).catch(() => {
      toaster.pop("error", "Something went wrong", "Failed to create report");
    }).finally(() => {
      $scope.loadingReport = false;
    });
  }

  const getFileName = (filename,format = "csv") => {
    return `medflyt-report-${(filename ? filename + "-" : '')}${$filter("date")(new Date(), "yyyy-MM-dd")}.${format}`;
  }

  const parseColumns = (columns) => {
    return columns.map((column) => ({
      ...column,
      columnName: column.name,
      columnLabel: column.label,
      aggregate: !column.aggregate ? null : column.aggregate
    }));
  };

  const parseFilters = (filters) => {
    filters.forEach((item, i) => {
      if (item['aggregate']==undefined){
        filters[i].aggregate = null
      }

    });

    return filters.map((filter) => ({
      ...filter,
      tableColumn: filter.name,
      // aggregate: null,
      isLocked: filter.isLocked,
      value: parseFilterValueToObject(filter)
    }));
  };

  const parseFilterValueToObject = (filter) => {
    let valueString = parseFilterValueString(filter);
    let valueObj = {};

    switch (filter.operator) {
      case ("LAST X"):
      case ("THIS X"):
      case ("NEXT X"): {
        valueObj = {
          type: "timePeriod",
          timePeriod: valueString
        };
        break;
      }
      case ("LAST N X"):
      case ("NEXT N X"): {
        valueObj = {
          type: "timePeriodInterval",
          value: {
            interval: filter.timePeriodValue.interval,
            timePeriod: filter.timePeriodValue.timePeriod
          }
        };
        break;
      }
      case ("IS NULL"):
      case ("NOT IS NULL"):
        valueObj = {
          type: "string",
          value: ""
        }
        break;
      default:
        valueObj = {
          type: "string",
          value: valueString
        };
        break;
    }

    return valueObj;
  }

  const parseFilterValueString = (filter) => {
    switch (filter.type) {
      case "TIMESTAMPTZ":
      case "TIMESTAMP":
      case "DATE":
        return $filter("mfShortTime")(filter.value, ['withDate', 'withSeconds'])
      case "TIME":
        return $filter("mfShortTime")(filter.value, ['withSeconds'])

      default:
        return "" + filter.value;
    }
  }

  const getTablesArrayByColumns = (columns) => {
    return Array.from(
      new Set(columns.map((column) => column.tableName))
    ).map((tableName) => ({
      tableName: tableName,
      columns: [],
    }))
  }

  const handleLoadSavedReport = (reportId) => {
    if (!reportId) {
      return;
    }
    reportService.getSavedReport(reportId).then((loadedSavedReport) => {
      $scope.report = {
        ...loadedSavedReport.reportTemplate,
        id: loadedSavedReport.id,
        name: loadedSavedReport.name,
        primaryTable: loadedSavedReport.reportTemplate.primaryTable.tableName,
      };
      for (const filter of loadedSavedReport.reportTemplate.filters) {
        filter.value = parseSavedFilterValueToValue(filter);
      }
      for (const table of $scope.reportsSchema.tables) {
        for (const column of table.columns) {
          column.selected =
            loadedSavedReport.reportTemplate.columns.findIndex(
              (templateColumn) =>
                table.tableName === templateColumn.tableName &&
                column.name === templateColumn.columnName
            ) !== -1;
          column.filterSelected =
            loadedSavedReport.reportTemplate.filters.findIndex(
              (templateFilter) =>
                table.tableName === templateFilter.tableName &&
                column.name === templateFilter.tableColumn
            ) !== -1;
        }
      }
    }).catch(err => toaster.pop("error", "Something went wrong", "Failed to load saved report"));
  }

  const parseSavedFilterValueToValue = (filter) => {
    if (filter.type === "NUMBER") {
        return (Number.parseInt(filter.value.value, 10));
    }

    if (filter.type === "BOOLEAN") {
        return (filter.value.value === "true");
    }

    let newValue;

    switch (filter.operator) {
      case ("LAST X"):
      case ("THIS X"):
      case ("NEXT X"): {
        newValue = filter.value.timePeriod;
        break;
      }
      case ("LAST N X"):
      case ("NEXT N X"): {
        newValue = {
            interval: filter.timePeriodValue.interval,
            timePeriod: filter.timePeriodValue.timePeriod
          }
        break;
      }
      default:
        newValue = filter.value.value;
        break;
    }

    return newValue;
  }

  const isValueEmpty = (filterValue, filterOperator) => {
      return ((filterValue === undefined || filterValue === "") && !["IS NULL", "NOT IS NULL"].includes(filterOperator));
  }

  $scope.handleFilterDescriptionClick = () => {
    const modalInstance = $uibModal.open({
        templateUrl: 'admin/views/time-period-description-modal.html',
        size: 'md',
        controller: 'timePeriodDescriptionModalCtrl',
        windowClass: 'modal modal-slide-in-right time-period-description-container',
        backdrop: 'static',
        keyboard : false
    });
  };

  $scope.handleBackToReports = () => {
    $state.go('app.reports.savedReports');
  }

  $scope.isAdmin = $rootScope.user.admin;

  initialize();
};
