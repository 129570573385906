import Plyr from "plyr/src/js/plyr";
import Hls from "hls.js";

export const plyrVideo = {
  templateUrl: "admin/views/plyr-video.html",
  bindings: {
    videoUrl: "@",
    thumbnailUrl: "@",
  },
  //! @ngInject
  controller: function ($scope, $sce) {

    const checkValidity = () => {
      if (!this.videoUrl) {
        throw new Error("videoUrl is required on plyrVideo");
      }

      if (!this.thumbnailUrl) {
        throw new Error("thumbnailUrl is required on plyrVideo");
      }
    }

    const getVideoStrategy = () => {
      if (this.videoUrl.match("youtube.com/watch") || this.videoUrl.match("youtu.be/")) {
        return {
          type: "YOUTUBE",
          url: $sce.trustAsResourceUrl(
            `https://www.youtube.com/embed/${this.videoUrl.split(/[=]+/).pop()}`
          ),
        };
      }

      if (this.videoUrl.match("youtube.com/embed")) {
        return {
          type: "YOUTUBE",
          url: $sce.trustAsResourceUrl(this.videoUrl),
        };
      }

      if (this.videoUrl.split(/[.]+/).pop() === "m3u8") {
        return {
          type: "HLS",
          url: this.videoUrl,
        };
      }

      return {
        type: "VIDEO",
        url: $sce.trustAsResourceUrl(this.videoUrl),
      };
    }

    this.$onInit = function () {
        checkValidity();

        $scope.videoPoster = this.thumbnailUrl;
        $scope.video = getVideoStrategy();

        // The reason I put the following code inside setTimeout was because
        // I couldn't get the DOM in the current event-loop.
        // If you find a better way of doing so, go for it.
        setTimeout(() => {
          let player;

          if ($scope.video.type === "YOUTUBE") {
            player = new Plyr('[data-video-type="youtube-player"]');
          }

          if ($scope.video.type === "VIDEO") {
            player = new Plyr('[data-video-type="video-player"]', {
              captions: { active: true, update: true, language: "en" },
            });
          }

          if ($scope.video.type === "HLS") {
            const video = document.querySelector('[data-video-type="hls-player"]');
            player = new Plyr(video);

            if (!Hls.isSupported()) {
              video.src = $scope.video.url;
            } else {
              // For more Hls.js options, see https://github.com/dailymotion/hls.js
              const hls = new Hls();
              hls.loadSource($scope.video.url);
              hls.attachMedia(video);
              window.hls = hls;

              // Handle changing captions
              player.on("languagechange", () => {
                // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
                setTimeout(() => (hls.subtitleTrack = player.currentTrack), 50);
              });
            }
          }
        }, 0);
      };
  },
};