import { LocalDate, Duration } from "js-joda";
import moment from "moment";

export function minutesToClock() {
  return function (valueInMins, includeSeconds) {
    if (typeof valueInMins === "number" || typeof valueInMins === "bigint") {
      const hours = Math.trunc(valueInMins / 60);
      const minutes = valueInMins % 60;

      if (includeSeconds) {
        return paddedHours`${hours}` + padded`:${minutes}:00`;
      }

      return paddedHours`${hours}` + padded`:${minutes}`;
    }
    return "";
  };
};

export function secondsToClock() {
  return function (valueInSecs, includeSeconds) {
    if (typeof valueInSecs === "number" || typeof valueInSecs === "bigint") {
      const hours = Math.floor(valueInSecs / 3600);
      const minutes = Math.floor((valueInSecs % 3600) / 60);

      if (includeSeconds) {
        const seconds = valueInSecs % 60;
        return paddedHours`${hours}` + padded`:${minutes}:${seconds}`;
      }

      return paddedHours`${hours}:${minutes}`;
    }
    return "";
  };
};

export function hour() {
  return function (date) {
    if (date) {
      try {
        let hours;
        let minutes;
        if (date.getHours && date.getMinutes) {
          hours = date.getHours();
          minutes = date.getMinutes();
        } else {
          hours = date.hours();
          minutes = date.minutes();
        }
        return `${padding(hours, 2)}:${padding(minutes, 2)}`;
      } catch (err) {
        if (typeof date === "string") {
          return date.split("T")[1];
        }
        return "";
      }
    }
    return "";
  };
};

export function nameWeekDays() {
  return function (weekDayNumber) {
    switch (weekDayNumber) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 7:
        return "Sunday";
      default:
        return "";
    }
  };
};

function padding(number, maxNumberLength) {
  return (1e4 + "" + number).slice(-maxNumberLength);
}

function padded(...tempParams) {
  const [templateStrs, ...params] = tempParams;
  let i = 0;
  let result = params.reduce((acc, curr) => {
    const result = acc + templateStrs[i] + padding(curr, 2);
    i++;
    return result;
  }, "");
  result += templateStrs[templateStrs.length - 1];
  return result;
}

function paddedHours(...tempParams) {
  const [templateStrs, ...params] = tempParams;
  let i = 0;
  let result = params.reduce((acc, curr) => {
    const maxNumberLength =
      curr && curr.toString().length > 2 ? curr.toString().length : 2;
    const result = acc + templateStrs[i] + padding(curr, maxNumberLength);
    i++;
    return result;
  }, "");
  result += templateStrs[templateStrs.length - 1];
  return result;
}

export function emptyString() {
  return function (value) {
    if (value !== undefined && value !== null) {
      return value;
    }
    return "";
  };
};

export function fullName() {
  return function (value) {
    if (value !== undefined && value !== null) {
      const { firstName, lastName } = value;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
      if (firstName) {
        return `${firstName}`;
      }
      if (lastName) {
        return `${lastName}`;
      }
    }
    return "Person";
  };
};

export function camelCase() {
  return function (input) {
    let words = input.replace(/[-_]/g, " ").split(" ");
    for (let i = 0, len = words.length; i < len; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
    return words.join(" ");
  };
};

export function numberRange() {
  return function (input, total) {
    total = parseInt(total);

    for (let i = 0; i < total; i++) {
      input.push(i);
    }

    return input;
  };
};

export function reverse() {
  return function (items) {
    return items.slice().reverse();
  };
};

var getDurationLabel = function (duration) {
  if (duration <= 0) duration.add(1, "days");
  var hours = duration.get("hours");
  if (duration.get("days")) {
    hours += 24;
  }
  var minutes = duration.get("minutes");
  if (duration.get("seconds") > 30) {
    minutes++;
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
  }
  if (minutes) return hours + ":" + minutes + "h";
  if (hours) return hours + "h";
  return "0h";
};

export function duration() {
  return ([from, to]) => {
    if (from === null || to === null) {
      return "";
    }

    if (from.length === 5 && to.length === 5) {
      from = "2000-01-01T" + from;
      to = "2000-01-01T" + to;
    }
    const duration = moment.duration(new Date(to) - new Date(from));

    if (duration <= 0) {
      duration.add(1, "days");
    }

    let hours = duration.get("hours");

    if (duration.get("days")) {
      hours += 24;
    }

    let minutes = duration.get("minutes");

    if (duration.get("seconds") > 30) {
      minutes++;
      if (minutes === 60) {
        hours++;
        minutes = 0;
      }
    }

    if (minutes) {
      if (minutes < 10) {
        return hours + ":0" + minutes + "h";
      }
      return hours + ":" + minutes + "h";
    }

    if (hours) {
      return hours + "h";
    }

    return "0h";
  };
};

export function jsJodaMonth() {
  return (month) => {
    return {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    }[month];
  };
};

export function diffInDays() {
    return ([from, to]) => {
        return Duration.between(
          LocalDate.parse(from).atStartOfDay(),
          LocalDate.parse(to).atStartOfDay()
        ).toDays();
      };
};
