//! @ngInject
export function OrientationCenterService($rootScope, DatabaseApi, wildcard) { return {
    addMissingOrientations(caregiverId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_member/:agencyMemberId/orientation_center/add_orientations/:caregiverId",
        agencyId,
        agencyMemberId,
        caregiverId
      );

      return DatabaseApi.post(endpoint);
    },
  } }