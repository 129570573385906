//! @ngInject
export function claimsConversionCtrl($scope, DatabaseApi, $rootScope, toaster) {
  $scope.convertEdi = function () {
    $scope.isLoading = true;
    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/edi_invoice_swaps",
      { text: $scope.ediText }
    ).then(
      function (response) {
        $scope.isLoading = false;
        if (response.data.errors && response.data.errors.length > 0) {
          $scope.errors = response.data.errors;
        } else {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
              encodeURIComponent(response.data.text)
          );
          element.setAttribute(
            "download",
            "converted-edi__" + new Date(Date.now()).toISOString()
          );
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      },
      function (error) {
        $scope.isLoading = false;
        toaster.pop("error", "Failed to load EDI");
      }
    );
  };
};
