//! @ngInject
export function $localstorage($window) {
        return {
            set: function (key, value) {
                $window.localStorage[key] = value;
            },
            get: function (key, defaultValue) {
                return $window.localStorage[key] || defaultValue;
            },
            remove: function (key) {
                return $window.localStorage.removeItem(key);
            }

        };
};