//! @ngInject
export function BackOfficeCtrl($scope, $rootScope, NgTableParams, $state, DatabaseApi, toaster){
  
  window.scrollTo(0,0);
  
  DatabaseApi.get('back-office/agencies').then(function(res){
    $scope.agencies = res.data;
  }, function(err){
    console.log('err');
    toaster.pop('error', "Something went wrong", 'Please refresh');
  });
  
  
  $scope.getAgencyData = function(name,i){
    $scope.active = i;
    DatabaseApi.get('back-office/agencies/' + name + '/sms-report').then(function(res){
        $scope.smsReport = res.data;
        initTable($scope.smsReport.list);
      }, function(err){
        toaster.pop('error', "Something went wrong", 'Please try again');
      });
  };
  
  function initTable(data){
    $scope.agencyTable = new NgTableParams({
      count: 50
    }, {
      counts: [5,10,25,50,100,200],
      dataset: data
    });
  }

  
  
};