export const DropdownSettingsConsts = {
  searchable: {
    enableSearch: true,
    scrollable: true,
  },
  singleSelect: {
    styleActive: true,
    showCheckAll: false,
    showUncheckAll: false,
    selectionLimit: 1,
    closeOnSelect: true,
    smartButtonMaxItems: 1,
    smartButtonTextConverter: (item: unknown) => item,
  },
};
