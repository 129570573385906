import _ from "lodash";

//! @ngInject
export function boostModalCtrl($scope, SweetAlert, DatabaseApi, $rootScope, toaster) {
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();

    $scope.boostHistory = [];

    DatabaseApi.get('agency/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/boost?visit=' + $scope.$resolve.visitId + '&type=' + $scope.$resolve.type).then(function (result) {
        $scope.boostHistory = result.data.batches
    });

    if ($scope.$resolve.type === "PENDING_BOOST") {
        $scope.helpImage = 'admin/images/boost-pending.png';
    } else if ($scope.$resolve.type === "COMPLIANCE_BOOST") {
        $scope.helpImage = 'admin/images/boost-compliance.png';
    }
    $scope.getBoostMessage = function () {
        switch ($scope.$resolve.type) {
            case "VISIT_BOOST":
                return "Increase the chances of staffing the case by sending text messages about the open case to all the caregivers that have not downloaded the app yet."
            case "DOWNLOAD_BOOST":
                return "Increase the amount of caregivers using the Medflyt app by sending them a text message reminder.";
            case "TRAINING_BOOST":
                return "Increase the training completion rate of caregivers using the Medflyt app by sending them a text message reminder.";
            case "PENDING_BOOST":
                return "By clicking on the “send reminders” button, the applicant will get a push notification of a text messages reminding about the missing files required in order to complete the application.";
            case "COMPLIANCE_BOOST":
                return "By clicking on the “send reminders” button, the caregiver will get a push notification of a text messages reminding her about the files that are about to run out of compliance.";
            case "CORONAVIRUS_BOOST":
                return "Click below to notify your caregivers about the online COVID-19 training waiting for them inside Medflyt.";
            case "MESSAGE_BOOST":
                    return "Click below to send notification caregivers";
            default:
                throw new Error('INVALID_VISIT_TYPE');
        }
    };

    $scope.getBoostTitle = function () {
        switch ($scope.$resolve.type) {
            case "VISIT_BOOST":
                return "Boost visit"
            case "DOWNLOAD_BOOST":
                return "Download boost";
            case "TRAINING_BOOST":
                return "Training boost";
            case "CORONAVIRUS_BOOST":
                return "Coronavirus boost";
            case "MESSAGE_BOOST":
                    return "Message boost";
            default:
                throw new Error('INVALID_VISIT_TYPE');
        }
    };

    $scope.confirm = function () {
        if(!_.has($rootScope.user.agency, "allowBoost")) {
            toaster.pop('error', 'The option does not exist, please contact Medflyt support');
            return;
        }

        if (!$rootScope.user.agency.allowBoost) {
            toaster.pop('error', 'The option is disabled, please contact Medflyt support');
            return;
        }

        return SweetAlert.swal({
            title: "Boost?",
            text: "Are you sure you want to boost? boosting will charge the additional fees mentioned.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, boost",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                $scope.$close('BOOST');
            }
        });
    }
    
    $scope.confirmPush = function () {

        return SweetAlert.swal({
            title: "Push Notification",
            text: "Are you sure you want to notify?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, notify",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                $scope.$close('BOOST_ONLY_PUSH');
            }
        });
    }
};