import { Tag } from "@chakra-ui/react";
import React from "react";

interface TableCellBadgeProps {
  color: string;
  text: string;
}

export function TableCellBadge(props: TableCellBadgeProps) {
  return (
    <Tag size="sm" variant="subtle" colorScheme={props.color}>
      {props.text}
    </Tag>
  );
}
