//! @ngInject
export function mergePatientsCtrl($http, $scope, $rootScope, NgTableParams, $state, DatabaseApi, toaster, $filter, Consts, Analytics, $stateParams, $window, Storage, $uibModal) {

    $scope.loading = true;

    function getPatients() {
        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/agencyMember/' + $rootScope.agencyMemberId + '/patients/merge_patients').then(function (res) {

            var data = [];

            res.data.patients.forEach(function (group) {
                group.forEach(function (line) {
                    line.checked = true;
                });
                data.push({
                    master: group[0].id,
                    list: group
                });
            });
            $scope.list = data;
            console.log(data);
            initPatientTable($scope.list);
            $scope.merging = false;
            $scope.loading = false;

        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get patients");
            $scope.list = [];
            initPatientTable($scope.list);
            $scope.merging = false;
            $scope.loading = false;
        });
    }
    getPatients();

    function initPatientTable(data) {

        var options = {
             count: 100
        };

        $scope.table = new NgTableParams(options, {
            counts: [],
            dataset: data
        });
    }

    $scope.mergePatients = function (row) {

        console.log(row);

        $scope.merging = true;
        var body = {
            masterPatient: parseInt(row.master),
            mergePatients: []
        };

        row.list.forEach(function (patient) {
            if(patient.checked && patient.id !== body.masterPatient) {
                body.mergePatients.push(patient.id);
            }
        });

        if (body.mergePatients.length === 0) {
            toaster.pop('warning', "Select at least 2 patients", "");
            $scope.merging = false;
            return;
        }
        console.log(body);
        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/agencyMember/' + $rootScope.agencyMemberId + '/patients/merge_patients', body).then(function (res) {
            $scope.list = res.data.patients;
            getPatients()

        }, function (err) {
            toaster.pop('error', "Something went wrong", err.status === 409 ? "Can't merge more than 10 patients" : "could not merge patients");
            getPatients();

        });
    };

    $scope.back = function () {
        $state.go('app.patients.dashboard');
    };

};