//! @ngInject
export function patientTaskAssignMultipleModal($scope, DatabaseApi, $uibModalInstance) {
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.allowedCertifications = $scope.allowedCertifications;
    $scope.caregiversArr = Object.values($scope.caregiversMap)
                            .filter(caregiver => $scope.allowedCertifications.some(c => caregiver.certifications.includes(c)));
    $scope.caregiverId = null;

    $scope.handleCaregiverSelection = (caregiver) => {
        $scope.caregiverId = caregiver.id;
    };

    $scope.handleCaregiverDeselect = () => {
        $scope.caregiverId = null;
    }

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };

    $scope.assignCaregiver = () => {
        if (!$scope.caregiverId) {
            return;
        }
        $uibModalInstance.close($scope.caregiverId);
    }
};