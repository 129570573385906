//! @ngInject
export function newPhysicianModalCtrl(
    $scope,
    DatabaseApi,
    data,
    physiciansService,
    $uibModalInstance,
    toaster
) {
    const physicians = DatabaseApi.physicians();

    function initialize() {
        $scope.form = {};
        if (data) {
            $scope.data = data;
        } else {
            $scope.data = {
                address: null,
                licenseNumber: null,
                phone: null,
                fax: null
            }
        }
    }

    $scope.adddressChange = () => {
        $scope.data.address = $scope.data.address.formatted_address;
    };

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }

        const physicianRequest = preparePhysicianRequest();
        if ($scope.data.id) {
            physiciansService.editPhysician($scope.data.id, physicianRequest)
                .then(function (res) {
                    toaster.pop('success', "Physician updated successfully");
                    $uibModalInstance.close($scope.data);
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not updated physician");
                });
        } else {
            const existingPhysicianWithNPI = physicians.find(p => p.npi === $scope.data.npi);
            if (existingPhysicianWithNPI) {
                return toaster.pop('warning', "This physician already exists in the list (NPI)");
            }

            physiciansService.createPhysician(physicianRequest).then(function (res) {
                toaster.pop('success', "Physician created successfully");
                $uibModalInstance.close(res.data.physician);
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not create a new physician");
            });
        }
    };

    function preparePhysicianRequest() {
        const body = {
            ...$scope.data
        };

        return body;
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    initialize();
};
