//! @ngInject
export function AgencyLogoUploadService($rootScope, $http, Consts, wildcard) { return {
      /**
       * Get agency logos
       */
      getLogos() {
        const { agencyId, agencyMemberId } = $rootScope;

        const url = wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/logos`,
          agencyId,
          agencyMemberId
        );

        return $http.get(url).then((res) => res.data);
      },

      /**
       * Update agency logos
       */
      updateLogo({ type, file }) {
        const { agencyId, agencyMemberId } = $rootScope;

        const url = wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/logos/:logoType`,
          agencyId,
          agencyMemberId,
          type
        );

        const formData = new FormData();
        formData.append("file", file, file.name);

        return $http({
          url,
          method: "POST",
          data: formData,
          headers: { "Content-Type": undefined },
        });
      },

      /**
       * Delete specified agency logo
       */
      deleteLogo(type) {
        const { agencyId, agencyMemberId } = $rootScope;

        const url = wildcard(
          `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/logos/:logoType`,
          agencyId,
          agencyMemberId,
          type
        );

        return $http.delete(url);
      },
    }
};
