//! @ngInject
export function professionalsOnboardingCtrl(
    $scope,
    $rootScope,
    $state
) {
    $scope.agencyId = $rootScope.agencyId
    $scope.goToState = (state) => {
        $state.go(state);
    }
};
