import { match, P } from "ts-pattern";
import { dateUtils } from "../../scripts/consts/dateUtils";
import {
  ComplianceQuestion,
  ComplianceStatus,
  ItemChartData,
} from "../../scripts/messages/compliance";
import { fmap, isDefined, upperCaseFirst } from "../../scripts/utils/generalUtils";
import { PieChartColor } from "../shared/components/pie-chart/pie-chart.component";

export const compliantStatuses: Set<ComplianceStatus> = new Set([
  "Compliant",
  "Not due yet",
  "Resolved",
]);

export type CaregiverOverviewComplianceStatus =
  | "Not compliant"
  | "Compliant"
  | "Not compliant in next 30 days";

export type ComplianceItemOverviewStatus =
  | "Not compliant"
  | "Compliant"
  | "Invalid in next 30 days";

export function getCaregiverOverviewComplianceStatusColor(
  status: CaregiverOverviewComplianceStatus
): PieChartColor {
  switch (status) {
    case "Not compliant":
      return PieChartColor.red;
    case "Compliant":
      return PieChartColor.green;
    case "Not compliant in next 30 days":
      return PieChartColor.yellow;
  }
}

export function getComplianceItemOverviewStatusColor(
  status: ComplianceItemOverviewStatus
): PieChartColor {
  switch (status) {
    case "Not compliant":
      return PieChartColor.red;
    case "Compliant":
      return PieChartColor.green;
    case "Invalid in next 30 days":
      return PieChartColor.yellow;
  }
}

export function sumItemsStatusGroups(
  groups: Map<"Compliant" | "Not compliant" | "Invalid in next 30 days", ItemChartData[]>
) {
  const map: Map<"Compliant" | "Not compliant" | "Invalid in next 30 days", number> = new Map();

  for (const [status, group] of groups) {
    for (const item of group) {
      map.set(status, (map.get(status) || 0) + item.totalCount);
    }
  }

  return map;
}

export function parseSectionLabel(section: string): string {
  // All compliance documents' sections are ending with "-doc".
  return upperCaseFirst(section.substring(0, section.length - "-doc".length));
}

export function formatDisplayComplianceQuestionAnswer(question: ComplianceQuestion) {
  const answer = match(question)
    .with({ type: "date" }, (q) => fmap(q.value, dateUtils.localDateToMDYString))
    .with(
      { type: "dropdown" },
      (q) => q.options.find((options) => options.id === q.value)?.title ?? ""
    )
    .with({ type: P.union("change_document", "text", "number") }, (q) => q.value)
    .exhaustive();

  if (!isDefined(answer)) {
    return "";
  }

  return answer;
}
