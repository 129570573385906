import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import useAuth from "../../../shared/hooks/useAuth";
import { queryKeys } from "../../../shared/query-keys";

export function useCaregiversQuery() {
  const api = useApi();
  const { authInfo } = useAuth();

  return useQuery({
    queryKey: queryKeys.caregiver.list(),
    queryFn: () =>
      api.get({
        url: "/agencies/:agencyId/caregivers",
        params: {
          path: {
            agencyId: authInfo.agency.id,
          },
        },
      }),
    staleTime: Infinity,
    select: (data) => data.caregivers,
  });
}
