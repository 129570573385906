/* @ngInject */
export function caregiverPatientDisplayIdAndStatus() {
    return function (item, showStatus) {
        let text = "";
        if (showStatus === true) {
            if (item.displayId) {
                text = `(${item.displayId}, ${item.status.toLowerCase()})`;
            } else {
                text = `(${item.status.toLowerCase()})`;
            }
        } else if (item.displayId) {
            text = `(${item.displayId})`;
        }
        return text;
    };
}
;
