
//! @ngInject
export function scheduledReportsModalCtrl($scope, $rootScope, $filter, reportService, $uibModalInstance, NgTableParams, toaster, generalUtils) {
    function init() {
        reportService.getAllScheduledWithHistoryBySavedReport($scope.savedReportId).then(res => {
            const scheduledData = [];
            $scope.historyTablesData = new Map();

            for (const report of res) {
                scheduledData.push({
                    ...report.scheduledReport,
                    displayName: createDisplayName(report.scheduledReport)
                });

                setHistoryTableData(report.scheduledReport.id, report.history);
            }

            setScheduledTableData(scheduledData);
        });
    }

    function setScheduledTableData(items) {
        var options = {
            sorting: { id: "desc" },
            count: Infinity
        };

        $scope.scheduledTableData = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function setHistoryTableData(key, items) {
        var options = {
            sorting: { id: "asc" },
            count: 5
        };

        $scope.historyTablesData.set(key, new NgTableParams(options, {
            counts: [],
            dataset: items
        }));
    }

    const createDisplayName = (scheduledData) => {
        const {periodType, timeInDay, interval, dayOfWeek, dayOfMonth} = scheduledData;
        let displayName;
        const manyAddition = interval > 1 ? `s` : ``;
        
        switch (periodType) {
            case "DAILY": {
                displayName = `Every day at ${timeInDay}`;
                break;
            }
            case "WEEKLY": {
                const dayOfWeekDisplay = dayOfWeek.charAt(0) + dayOfWeek.slice(1).toLowerCase();
                displayName = `Every ${interval} week${manyAddition} on ${dayOfWeekDisplay} at ${timeInDay}`;
                break;
            }
            default: {
                const dayOfMonthDisplay = dayOfMonth === 31 ? 'last day of month' : dayOfMonth;
                displayName = `Every ${interval} month${manyAddition} on the ${dayOfMonthDisplay} at ${timeInDay}`;
                break;
            }
        }

        return displayName;
    }

    $scope.handleOpenAddScheduled = () => {
        const newScope = $scope.$new();
        newScope.savedReportId = $scope.savedReportId;
        $rootScope.openScheduledReportModal(newScope);
    }

    $scope.handleToggleActivateScheduledReport = (scheduledReportId, active) => {
        const params = { 
            type : active ? "ACTIVATE" : "DEACTIVATE"
        };
        reportService.toggleActivateScheduledSavedReport(scheduledReportId, params).then(() => {
            toaster.pop("success", "Success", `Scheduled report ${params.type.toLowerCase()} succeffuly`);
            init();
        }).catch(err => {
            toaster.pop("error", "Something went wrong", `Failed to ${params.type.toLowerCase()} scheduled report`);
        });
    }

    $scope.handleDownloadReportFile = (scheduledReportId, scheduledReportHistoryId, date) => {
        reportService.downloadReportFile(scheduledReportId, scheduledReportHistoryId).then((res) => {
            generalUtils.downloadURI(res, `medflyt-report-${$scope.savedReportName}-${$filter("date")(new Date(date), "yyyy-MM-dd")}.csv`);
            toaster.pop("success", "Success", "Downloaded scheduled report succeffuly");
        }).catch(err => {
            toaster.pop("error", "Something went wrong", "Failed to download scheduled report");
        });
    }

    $rootScope.$on("new_scheduled", function (event) {
        init();
    });

    $scope.closeModal = () => {
        $uibModalInstance.dismiss();
    };

    init();
};