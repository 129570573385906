//! @ngInject
export function videoModalCtrl($scope, $uibModalInstance, Storage){

    window.scrollTo(0,0);

    $scope.closeModal = function(){
        $uibModalInstance.close();
    };

    $scope.gotIt = function () {
        Storage.set('showVideoNewVisitForm', 'true');
        $uibModalInstance.close();
    }

};
