export const patientTasks = {
    templateUrl: "admin/views/patient-tasks.html",
    bindings: {
        patientId: '<',
        patientFullName: '<'
    },
    //! @ngInject
    controller: function ($scope, $rootScope, DatabaseApi, NgTableParams, toaster, $window, $uibModal, $q, $filter) {
        const MANUAL_SUBMISSION_STRING = "MANUAL_SUBMISSION_STRING";

        this.getPatientTasks = async () => {
            const deferred = $q.defer();
            const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
                + "/patients/" + $scope.$ctrl.patientId + '/patient_task_instances';

            DatabaseApi.get(url).then(function (res) {
                deferred.resolve(res.data.patientTasks);
            }, function (err) {
                toaster.pop('error', 'Something went wrong', 'could not get patient tasks');
                deferred.reject(null);
            });

            return deferred.promise;
        }

        this.togglePatientTaskRow = (task) => {
            task.displyTaskRow = !task.displyTaskRow;
        }

        this.initPatientTasksTable = () => {
            const options = {
                count: 25,
                sorting: { dueDate: "asc" }
            };

            $scope.patientTasks.forEach(task => {
                task.instances.forEach(instance => {
                    this.setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
                });
            });

            $scope.patientTasksTable = new NgTableParams(options, {
                counts: [],
                dataset: $scope.patientTasks
            });
        }


        this.setPatientTaskInstanceDocumentsStatisticsAndOptions = (instance) => {

            instance.filledDocumentsAmount = instance.documents.filter(d =>
                d.submitted === true).length;
            instance.missingDocumentsAmount = instance.documents.filter(d =>
                d.submitted !== true
            ).length;

            instance.documents.forEach(doc => {
                if (doc.submitted) {
                    doc.classNames = 'label-success label-with-options-success';
                } else {
                    doc.classNames = 'label-error label-with-options-error'
                }

                let options = [];
                const documentObj = {
                    id: doc.documentScheduleId,
                    title: doc.title
                }

                if (doc.submitted && doc.fileUrl && doc.fileUrl !== MANUAL_SUBMISSION_STRING) {
                    options.push({ displayText: 'View PDF', callback: this.openPatientTaskInstanceDocument, callbackParam1: doc.documentScheduleId });
                    options.push({ displayText: 'Send PDF to e-mail', callback: this.sendPatientTaskDocumentPDFEmail, callbackParam1: documentObj });
                    options.push({ displayText: 'Send PDF to fax', callback: this.sendPatientTaskDocumentPDFEfax, callbackParam1: documentObj });
                } 
                
                if (doc.submitted) {
                    options.push({
                        displayText: 'Open for submission',
                        callback: this.reopenPatientTaskDocumentForSubmission,
                        callbackParam1: instance,
                        callbackParam2: doc
                    });
                }
                else if (!doc.submitted) {
                    options.push({
                        displayText: 'Submit manually',
                        callback: this.submitPatientTaskDocumentManually,
                        callbackParam1: instance,
                        callbackParam2: doc
                    });
                    options.push({
                        displayText: 'Mark as submitted',
                        callback: this.markPatientTaskDocumentAsSubmitted,
                        callbackParam1: instance,
                        callbackParam2: doc
                    });
                    if (!doc.isScanned) {
                        options.push({
                            displayText: 'Review form',
                            callback: this.reviewForm,
                            callbackParam1: doc,
                            callbackParam2: instance.taskInstanceId,
                            callbackParam3: instance.patientName,
                            callbackParam4: instance.caregiver.fullName,
                            callbackParam5: instance.patientId
                         });
                    }
                }

                doc.options = options;
            })
        }

        this.openPatientTaskInstanceDocument = (docId) => {
            if (docId === null) {
                return;
            }

            const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
                + "/patients/" + $scope.$ctrl.patientId + '/scheduled_documents/' + docId + "/generate_url";

            DatabaseApi.get(url).then(function (res) {
                $window.open(res.data.fileUrl);
            }, function (err) {
                toaster.pop('error', 'Something went wrong', 'Failed to view document pdf');
            });
        }

        this.sendPatientTaskDocumentPDFEmail = (document, type) => {
            let docsType = type === 'planOfCare' ? 'planOfCare' : 'patient';
            $uibModal.open({
                templateUrl: 'admin/views/email-modal.html',
                size: 'lg',
                resolve: {
                    documents: function () { return [document]; },
                    patientId: function () { return $scope.$ctrl.patientId; },
                    patientFullName: function () { return $scope.$ctrl.patientFullName; },
                    docsType: function () { return (docsType); }
                },
                controller: 'emailModalCtrl'
            });
        };

        this.sendPatientTaskDocumentPDFEfax = (document) => {
            $uibModal.open({
                templateUrl: 'admin/views/fax-modal.html',
                size: 'lg',
                resolve: {
                    documents: function () { return [document]; },
                    patientId: function () { return $scope.$ctrl.patientId; }
                },
                controller: 'faxModalCtrl'
            }); d
        };

        this.reopenPatientTaskDocumentForSubmission = (instance, document) => {
            const that = this;
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":scheduledDocId", document.documentScheduleId);

            DatabaseApi.post(url).then(function (res) {
                document.submitted = null;
                document.classNames = 'label-error label-with-options-error';
                that.setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
                toaster.pop('success', "Success", "Document is open for submission");
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not reopen for submission");
            });
        }

        this.submitPatientTaskDocumentManually = (instance, document) => {
            const that = this;
            const url = "agencies/:agencyId/agency_member/:agencyMemberId/patient_task_instance/:patientTaskInstanceId/patient_document"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":patientTaskInstanceId", instance.taskInstanceId);

            $rootScope.openUploadDocumentPdfModal({
                url: url,
                document: document, successCallback: (result) => {
                    if (result !== null) {
                        document.submitted = true;
                        document.documentScheduleId = result.data.id;
                        document.fileUrl = result.data.url;
                        that.setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
                    }
                }
            });
        }

        this.markPatientTaskDocumentAsSubmitted = (instance, document) => {
            const that = this;
            const url = "agencies/:agencyId/agency_member/:agencyMemberId/patient_task_instance/:patientTaskInstanceId/patient_document"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":patientTaskInstanceId", instance.taskInstanceId);

            DatabaseApi.post(url,
                {
                    base64: null,
                    documentId: document.documentTypeId,
                    documentVersion: null,
                    documentTitle: null
                }
            ).then(function (res) {
                toaster.pop('success', "Manual submission successful", '', ' ');
                document.submitted = true;
                document.documentScheduleId = res.data.id;
                that.setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
            }, function (err) {
                toaster.pop('error', 'Error', 'Manual submission failed');
            });
        }

        async function prepareDocumentReplaceConstants(patientName, caregiverName) {
            var data = {};
            data.agencyName = $rootScope.user.agency.name;
            data.caregiverName = caregiverName;
            data.patientName = patientName;
            data.agencyAddress = $rootScope.user.agency.address;
            data.agencyPhone = $filter("americanphone")($rootScope.user.agency.officePhone);
            return data;
        }

        this.reviewForm = (document, instanceId, patientName, caregiverName, patientId) => {
            let url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_instances/${instanceId}/patient_documents/${document.documentTypeId}`;
            DatabaseApi.get(url).then(function (res) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'admin/views/edit-patient-doc-modal.html',
                    size: 'lg',
                    controller: 'editPatientDocModalCtrl',
                    resolve: {
                        patientId: patientId,
                        document: res.data.document,
                        documentReplaceConstants: () => prepareDocumentReplaceConstants.bind({}, patientName, caregiverName)
                    }
                });
    
                modalInstance.result.then(function (data) {
                    if (data && data.fileUrl && data.date) {
                        document.submittedAt = data.date;
                        document.pdfUrl = data.fileUrl;
                    }
                }, function () {});
            }, function () {
                toaster.pop('error', "Something went wrong");
            });
        };

        this.$onInit = async function () {
            const patientTasks = await this.getPatientTasks();
            if (patientTasks !== null) {
                $scope.patientTasks = patientTasks;
                this.initPatientTasksTable();
            }
        }
    }
};