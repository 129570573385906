//! @ngInject
export function careManagementCtrl($http, $scope, $rootScope, NgTableParams, $state, DatabaseApi, itemSearchPageManager, toaster, $filter, Consts, $window, $uibModal, mfModal, patientDocumentService) {
    $scope.caregiversMap = null;
    $scope.scheduledVisits = null;

    $scope.nursesById = [];
    $scope.formsByDocumentId = [];
    $scope.filterBy = {
        nurseId: [],
        formId: [],
        statuses: [],
        label: [],
        offices: []
    };
    $scope.visitLabels = [
        { id: 1, label: "UAS" },
        { id: 2, label: "Initial Rn Assessment" },
        { id: 3, label: "Re-Assessment" },
        { id: 4, label: "Supervisory Visit" },
        { id: 5, label: "Start of Care" },
        { id: 6, label: "Follow-up" },
        { id: 7, label: "Post Hospitalization" }
    ];
    $scope.formsStatuses = [
        { id: 1, label: "Completed", value: "completed" },
        { id: 2, label: "Not completed", value: "notCompleted" },
        { id: 3, label: "Partly completed", value: "partlyCompleted" }
    ]

    function initialize() {
        initPageManager();
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/care_management");

        $scope.pageManager.initFromToDateParams();

        $scope.onDateRangeChanged = function (startDate, endDate) {
            $scope.pageManager.updateSearchParamValue("from", new Date(startDate));
            $scope.pageManager.updateSearchParamValue("to", new Date(endDate));
            $scope.loadItems();
        };
    };

    const getDocName = (doc) => {
        if (doc.type === 'PlanOfCareDocument') {
            return 'Plan of care';
        }
        return doc.name;
    };

    var filterByMethods = {
        hasNurse: function (visit, selectedNurseIds) { return selectedNurseIds.indexOf(visit.nurseId) !== -1; },
        hasLabel: function (visit, selectedLabels) { return visit.labels.find(function (labelObj) { return selectedLabels.indexOf(labelObj.text) !== -1; }) !== undefined; },
        hasForm: function (visit, selectedFormIds) { return visit.forms && visit.forms.find(function (form) { return selectedFormIds.indexOf(form.documentId) !== -1 }); },
        hasStatuses: function (visit, selectedStatuses) { return visit.formStatuses.some(status => selectedStatuses.includes(status)); },
        hasOfficeId: (visit, selectedOfficeIds) => selectedOfficeIds.includes(visit.officeId)
    };

    function filterTable() {
        if (!$scope.scheduledVisits) return;

        var filters = [];
        var selectedNurseIds = $scope.filterBy.nurseId.map(function (obj) { return obj.id; });
        if (selectedNurseIds.length > 0) {
            filters.push(function (visit) { return filterByMethods.hasNurse(visit, selectedNurseIds); });
        }
        var selectedLabels = $scope.filterBy.label.map(function (obj) {
            return $scope.visitLabels.find(function (visitLabel) { return visitLabel.id === obj.id; }).label;
        });
        if (selectedLabels.length > 0) {
            filters.push(function (visit) { return filterByMethods.hasLabel(visit, selectedLabels); });
        }
        var selectedFormIds = $scope.filterBy.formId.map(function (obj) { return obj.id; });
        if (selectedFormIds.length > 0) {
            filters.push(function (visit) { return filterByMethods.hasForm(visit, selectedFormIds); });
        }
        const selectedStatuses = $scope.filterBy.statuses.map((obj) =>
            $scope.formsStatuses.find(formStatus => formStatus.id === obj.id).value);

        if (selectedStatuses.length > 0) {
            filters.push(function (visit) { return filterByMethods.hasStatuses(visit, selectedStatuses); });
        }

        const selectedOfficeIds = $scope.filterBy.offices.map(obj => obj.id)
        if (selectedOfficeIds.length > 0) {
            filters.push((visit) => filterByMethods.hasOfficeId(visit, selectedOfficeIds));
        }

        var filteredVisits = $scope.scheduledVisits;
        if (filters.length > 0) {
            filteredVisits = filteredVisits.filter(function (visit) {
                var isFiltered = true;
                for (var idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](visit);
                }
                return isFiltered;
            });
        }

        initTable(filteredVisits);
    }

    $scope.globalFilter = { val: "" };
    $scope.applyGlobalSearch = function (term) {
        var filter = { patientName: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    function initTable(scheduledVisits) {
        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: scheduledVisits
        });

        if ($scope.globalFilter.val) {
            $scope.applyGlobalSearch($scope.globalFilter.val);
        }
    }

    function checkIfCustomDocumentScanned(doc) {
        return doc.documentId === -1;
    }

    var mapScheduledVisits = function () {
        var nursesByIdObj = {};
        var formsByDocumentIdObj = {}
        var officesByIdObj = {}

        $scope.scheduledVisits.forEach(function (visit) {
            nursesByIdObj[visit.nurseId] = { id: visit.nurseId, label: visit.nurseName };
            officesByIdObj[visit.officeId] = { id: visit.officeId, label: visit.officeName };

            if (!visit.labels) {
                visit.labels = [];
            }

            var isCompleted = true;
            visit.forms.forEach(function (doc) {
                if (!formsByDocumentIdObj[doc.documentId] && !checkIfCustomDocumentScanned(doc)) {
                    formsByDocumentIdObj[doc.documentId] = { id: doc.documentId, label: getDocName(doc) };
                }
                isCompleted = isCompleted && doc.submitted;

                var options = [];
                if (doc.submitted && doc.pdfUrl && doc.type === 'PatientDocument') {
                    options.push({ displayText: 'View PDF', callback: $scope.viewPDF, callbackParam1: doc.pdfUrl });
                    options.push({ displayText: 'Send PDF to e-mail', callback: $scope.sendDocumentPDFEmail, callbackParam1: doc });
                    options.push({ displayText: 'Send PDF to fax', callback: $scope.sendDocumentPDFEfax, callbackParam1: doc });
                }
                if(!checkIfCustomDocumentScanned(doc) && doc.type === 'PatientDocument') {
                    options.push({ displayText: 'Review form', callback: $scope.reviewForm, callbackParam1: doc, callbackParam2: visit });
                    if (doc.submitted) {
                        options.push({ displayText: 'Open for submission', callback: $scope.reopenSubmission, callbackParam1: doc });
                    }
                }
                options.push({ displayText: 'Go to visit', callback: $scope.goToVisit, callbackParam1: visit });

                if (doc.type === 'PlanOfCareDocument' && doc.pdfUrl) {
                    options.push({ displayText: 'Download PDF', callback: $scope.viewPDF, callbackParam1: doc.pdfUrl });
                }

                options.push({ displayText: 'Delete document', callback: $scope.onClickRemovePatientScheduledDocument, callbackParam1: doc, callbackParam2: visit });

                doc.labelText = getDocName(doc);
                doc.options = options;
                doc.isSuccess = doc.submitted;
                if(checkIfCustomDocumentScanned(doc)){
                    doc.classNames = 'label-info label-with-options-info';
                } else if(doc.isSuccess) {
                    doc.classNames = 'label-success label-with-options-success';
                } else {
                    doc.classNames = 'label-error label-with-options-error'
                }
                doc.patientId = visit.patientId;
                doc.patientName = visit.patientName;
            });
            visit.isCompleted = isCompleted;

            visit.filledFormsAmount = visit.forms.filter(function (v) {
                return v.submitted === true;
            }).length;
            visit.missingFormsAmount = visit.forms.filter(function (v) {
                return v.submitted === false;
            }).length;

            visit.formStatuses = [];
            if (visit.filledFormsAmount > 0 && visit.missingFormsAmount === 0 || visit.forms.length === 0) {
                visit.formStatuses.push('completed');
            } else {
                if (visit.missingFormsAmount > 0) {
                    visit.formStatuses.push('notCompleted');
                }
                if (visit.filledFormsAmount > 0 && visit.missingFormsAmount > 0) {
                    visit.formStatuses.push('partlyCompleted');
                }
            }

            visit.displayForms = false;
        });
        
        $scope.nursesById = Object.values(nursesByIdObj);
        $scope.formsByDocumentId = Object.values(formsByDocumentIdObj);
        $scope.officeById = Object.values(officesByIdObj);
    }

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            $scope.scheduledVisits = response.data.scheduledVisits;

            mapScheduledVisits();

            filterTable();
        }, function (error) {
            toaster.pop("error", "Failed to load the scheduled visits");
        });
    };

    $scope.$watch('filterBy', function () {
        filterTable();
    }, true);

    var formatDateTime = function (dateTime) {
        var formattedDateTime = dateTime ? new Date(dateTime).toLocaleString().replace(",", "") : "";
        return formattedDateTime;
    }

    var getExportedFileName = function () {
        var filename = "medflyt-care-management-export-from-"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), 'yyyy-MM-dd')
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), 'yyyy-MM-dd')
            + ".csv";
        return filename;
    }

    $scope.exportTable = function () {
        if (!$scope.tableParams || !$scope.tableParams.data || !$scope.tableParams.data.length) return;

        var tableData = $scope.tableParams.data;
        if (tableData.length <= 0) {
            toaster.pop('warning', 'Nothing to export', 'The table is empty');
            return;
        }

        var rows = [];
        var colNames = [
            "Patient",
            "RN",
            "Start Time",
            "End Time",
            "Form Name",
            "Is Submitted",
            "Submition Time"
        ];

        rows.push(colNames);

        tableData.forEach(function (visit) {
            const leftRowData = [
                visit.patientName || "",
                visit.nurseName || "",
                formatDateTime(visit.scheduledVisitStartTime),
                formatDateTime(visit.scheduledVisitEndTime)
            ];

            visit.forms.forEach(function (doc) {
                const newRow = Array.from(leftRowData);
                const docName = getDocName(doc) ? `"${getDocName(doc)}"` : '';
                newRow.push(docName);
                newRow.push(doc.submitted ? "Yes" : "No");
                newRow.push(formatDateTime(doc.submittedAt) || "");

                rows.push(newRow);
            });
        });

        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    $scope.sendReminderForScheduledVisit = function (scheduledVisitId) {
        // /agencies/:agencyId/care_management/send_documents_reminder_for_scheduled_visit/:scheduledVisitId
        var url = 'agencies/' + $rootScope.agencyId + '/care_management/send_documents_reminder_for_scheduled_visit/' + scheduledVisitId;

        // console.log("sendReminderForScheduledVisit -> url=", url);

        DatabaseApi.post(url).then(function (res) {

            // console.log("sendReminderForScheduledVisit -> res=", res);

            toaster.pop('success', 'A reminder was sent');
        }, function (err) {
            toaster.pop('error', 'Failed to send reminder for scheduled visit id=' + scheduledVisitId);
        });
    }

    $scope.preOpenCaregiverModal = function (row) {
        if (!row.nurseId) return;
        if (!$scope.caregiversMap) $scope.caregiversMap = DatabaseApi.caregivers() || {};
        var nurseCaregiver = $scope.caregiversMap[row.nurseId];
        $rootScope.openCaregiverModal(row.nurseId, nurseCaregiver);
    };

    $scope.viewPDF = function (pdfUrl) {
        if (pdfUrl !== null) {
            $window.open(pdfUrl);
        }
    };

    function buildDocuments(document) {
        var documents;
        if (document !== undefined) {
            documents = [document];
        } else {
            documents = $scope.documentsTable.data.reduce(function (a, c) {
                if (c.selected) {
                    a.push({ id: c.id, title: c.title });
                }
                return a;
            }, []);
        }
        return documents;
    }

    $scope.sendDocumentPDFEmail = function (document, type) {
        var docsType = type === 'planOfCare' ? 'planOfCare' : 'patient';
        $uibModal.open({
            templateUrl: 'admin/views/email-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return buildDocuments(document); },
                patientId: function () { return document.patientId; },
                patientFullName: function () { return document.patientName; },
                docsType: function () { return (docsType); }
            },
            controller: 'emailModalCtrl'
        });
    };

    $scope.sendDocumentPDFEfax = function (document) {
        $uibModal.open({
            templateUrl: 'admin/views/fax-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return buildDocuments(document); },
                patientId: function () { return document.patientId; }
            },
            controller: 'faxModalCtrl'
        });
    };

    async function prepareDocumentReplaceConstants(patientName, caregiverName) {
        var data = {};
        data.agencyName = $rootScope.user.agency.name;
        data.caregiverName = caregiverName;
        data.patientName = patientName;
        data.agencyAddress = $rootScope.user.agency.address;
        data.agencyPhone = $filter("americanphone")($rootScope.user.agency.officePhone);
        return data;
    }

    $scope.reviewForm = function (document, visit) {
        const url = Consts.api + "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_documents/:documentTypeId"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":visitInstanceId", visit.scheduledVisitId)
                        .replace(":documentTypeId", document.documentId)
        $http.get(url).then(function (res) {
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/views/edit-patient-doc-modal.html',
                size: 'lg',
                controller: 'editPatientDocModalCtrl',
                resolve: {
                    patientId: document.patientId,
                    document: res.data,
                    documentReplaceConstants: () => prepareDocumentReplaceConstants.bind({}, visit.patientName, visit.nurseName)
                }
            });

            modalInstance.result.then(function (data) {
                if (data && data.fileUrl && data.date) {
                    document.submittedAt = data.date;
                    document.pdfUrl = data.fileUrl;
                }
            });
        }, function (err) {
            toaster.pop('error', "Failed to show a review of the form");
        });
    };

    $scope.reopenSubmission = function (document) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":scheduledDocId", document.id);
        $http.post(Consts.api + url).then(function (res) {
            document.submittedAt = null;
            document.pdfUrl = null;

            toaster.pop('success', 'The document was reopened for submission');
        }, function (err) {
            toaster.pop('error', "Failed to reopen for submission");
        });
    };

    $scope.goToVisit = function (visit) {
        $rootScope.openVisitModal({ visit: visit });
    };

    $scope.toggleFormsDisplay = function (visit) {
        visit.displayForms = !visit.displayForms;
    }

    const handleConfirmRemoveScheduledDocument = (document, visit) => {
        patientDocumentService
            .deletePatientVisitDocument(document.patientId, document.id)
            .then(() => {
                toaster.pop("success", "Document successfully deleted");
                visit.forms = visit.forms.filter(form => form.id !== document.id);
                visit.missingFormsAmount -= 1;

                if (visit.forms.length === 0) {
                    const visitIndex = $scope.scheduledVisits.findIndex(v => v.scheduledVisitId === visit.scheduledVisitId);
                    $scope.scheduledVisits.splice(visitIndex, 1);
                    filterTable();
                }
            })
            .catch(() => {
                this.toaster.pop("error", "Something went wrong", "Failed to delete document");
            })
    };

    $scope.onClickRemovePatientScheduledDocument = function (document, visit) {
        if (document.id === null) {
            const modal = mfModal.create({
                subject: `Delete ${document.name} Document`,
                message: "This visit document hasn't been filled yet, in order to delete pre-filled documents, delete it by editing the visit via the patient calendar.",
                variant: "info",
                confirmLabel: "OK",
                hideCancelButton: true,
                onConfirm: () => modal.close()
            });
            return;
        }

        const modal = mfModal.create({
            subject: `Delete ${document.name} Document`,
            message: "Are you sure you want to delete this document?",
            variant: "danger",
            confirmLabel: "Confirm",
            cancelLabel: "Cancel",
            onConfirm: () => modal.close() && handleConfirmRemoveScheduledDocument(document, visit)
        });
    };

    initialize();
};