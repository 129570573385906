import { Chart } from "chart.js";

//! @ngInject
export function patientMedicalStatisticsCtrl($scope, toaster, nursingMedicalStatisticsService, $uibModal) {
    
    $scope.filterBy = {
        questions: []
    };

    function initialize () {
        nursingMedicalStatisticsService.get()
            .then(onGetDataSuccess)
            .catch(onGetDataFail)
            .finally(() => ($scope.isInitialized = true));
    }

    function onGetDataSuccess (response) {
        const data = response.data.stats.map(question => {
            question.totalAnswers = 0;
            for (const answerStat of question.answerStats) {
                question.totalAnswers += answerStat.count;
            }
            return question;
        });
        $scope.data = data.filter(question => question.totalAnswers > 0);
        $scope.noAnswersData = data.filter(question => question.totalAnswers === 0);
        
        $scope.primaryQuestions = $scope.data.map(question => ({
            id: question.id,
            label: question.questionText
        }));

        $scope.showMissingAnswers = ($scope.data.length === 0 && $scope.noAnswersData.length > 0);

        setTimeout(setCharts, 0);
    }

    function onGetDataFail (error) {
        console.error(error);
        toaster.pop('error', 'Oops!', 'Failed loading medical statistics');
    }

    function setCharts() {
        for (const question of $scope.data) {
            const canvas = document.getElementById(`nursing-chart-${question.id}`);
            const ctx = canvas.getContext("2d");
            new Chart(ctx, nursingMedicalStatisticsService.getChartConfigByQuestion(question));
        }
    }

    $scope.isQuestionSelected = question => {
        if ($scope.filterBy.questions.length === 0) {
            return true;
        }
  
        return $scope.filterBy.questions.find(({ id }) => question.id === id) !== undefined;
    }

    $scope.toggleShowMissingAnswers = () => {
        $scope.showMissingAnswers = !$scope.showMissingAnswers;
    }

    $scope.openNursingQuestionStatisticsModal = question => {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/views/nursing-question-statistics-modal.html",
          size: "lg",
          controller: "nursingQuestionStatisticsModalCtrl",
          resolve: {
            question: () => question
          }
        });
      };

    initialize();

};
