import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  ThemingProps,
} from "@chakra-ui/react";
import React from "react";

interface Props<$Option> {
  label: string;
  options: { label: string; value: $Option }[] | ReadonlyArray<{ label: string; value: $Option }>;
  buttonProps?: MenuButtonProps;
  value: $Option[] | null;
  size?: ThemingProps["size"];
  onChange: (selected: $Option[] | undefined) => void;
}

const MultiSelectMenu = <$Option,>(props: Props<$Option>): JSX.Element => {
  const { label, options, buttonProps } = props;
  const selectedOptions = props.value ?? [];

  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={<ChevronDownIcon />}
            backgroundColor={selectedOptions.length ? "blue.50" : "transparent"}
            color={selectedOptions.length ? "blue.500" : "gray.600"}
            borderColor={selectedOptions.length ? "blue.200" : "gray.300"}
            size={props.size}
            {...buttonProps}
          >
            {`${label}${selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ""}`}
          </MenuButton>
          <MenuList maxH="40vh" overflowY="auto">
            <MenuGroup title={undefined}>
              <MenuItemOption
                fontSize={props.size}
                onClick={() => {
                  props.onChange(options.map((x) => x.value));
                  onClose();
                }}
              >
                Select all
              </MenuItemOption>

              <MenuItemOption
                fontSize={props.size}
                onClick={() => {
                  props.onChange(undefined);
                  onClose();
                }}
              >
                Clear all
              </MenuItemOption>
            </MenuGroup>
            <MenuDivider />
            <MenuOptionGroup
              title={undefined}
              value={selectedOptions.map((x) => `${x}`)}
              type="checkbox"
              onChange={($values) => {
                const type = typeof options[0]?.value ?? "string";
                const asArray = Array.isArray($values) ? $values : [$values];
                const values = asArray.map((x) =>
                  type === "number" ? Number(x) : x
                ) as unknown as $Option[];

                props.onChange(values);
              }}
            >
              {options.map((option) => {
                return (
                  <MenuItemOption
                    isChecked={selectedOptions.includes(option.value)}
                    key={`multiselect-menu-${option.value}`}
                    value={`${option.value}`}
                    fontSize={props.size}
                  >
                    {option.label}
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

MultiSelectMenu.displayName = "MultiSelectMenu";

export default MultiSelectMenu;
