import { useSrefActive } from "@uirouter/react";
import React from "react";

interface Props {
  to: string;
  params?: object;
  children: React.ReactNode;
  activeClassName?: string;
  className?: string;
}

function Link(props: Props, ref: React.Ref<HTMLAnchorElement>) {
  const { to, children, params = null, activeClassName = "active", ...rest } = props;
  const sref = useSrefActive(to, params ?? {}, activeClassName);
  const debugString = `${to}${params ? " " + JSON.stringify(params) : ""}`;

  return (
    <a
      {...sref}
      {...rest}
      className={classNames(sref.className, rest.className)}
      ref={ref}
      data-sref-debug={debugString}
    >
      {children}
    </a>
  );
}

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(" ");
}

export default React.forwardRef(Link);
