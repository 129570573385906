//! @ngInject
export function applicationFullProfileSubsectionsSelectionModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster, $window) {
    $scope.selectedSubsections = angular.copy($scope.$resolve.subSectionsOptions);
    $scope.fullProfileSubsectionsExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: "400px",
        enableSearch: true
    };

    $scope.createPDF = () => {
        $scope.isLoading = true;

        if ($scope.selectedSubsections.length === 0) {
            toaster.pop('warning', 'Missing files to generate to PDF');
            $scope.isLoading = false;
            return;
        }

        const url = `hr/agencies/${$rootScope.agencyId}/caregivers/${$scope.$resolve.caregiverId}/download_applicant_profile`;
        const body = {
            subSectionsIds: $scope.selectedSubsections.map(obj => obj.id)
        };
        DatabaseApi.post(url, body).then((res) => {
            const applicantProfileWindow = $window.open('', '_blank');
            applicantProfileWindow.document.write('Loading PDF, this may take a while... <br/> Do not close this window');
            applicantProfileWindow.location.href = res.data.url;
            $scope.isLoading = false;
            $uibModalInstance.close();
        }, (err) => {
          toaster.pop('error', 'something went wrong', 'Failed downloading applicant profile');
          $scope.isLoading = false;
        });
    };

    $scope.closeModal = () => {
        $uibModalInstance.close();
    };
};