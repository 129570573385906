import { IPromise } from "angular";
import { VisitInstanceId } from "../messages/ids";
import { VisitInstancesSaveResponseWithAssignIncreaseOvertime } from "../messages/visit";
import { VisitInstanceEditParams } from "../messages/visit_instance";
import { Api } from "../services/Api";

export interface missedVisitRequestBody {
    ids: VisitInstanceId[],
    changes: VisitInstanceEditParams
}

//! @ngInject
export class MissedVisitService {
    constructor(
        private api: Api,
        private $rootScope: ng.IRootScopeService,
    ) { }

    missVisits = (body :missedVisitRequestBody) :IPromise<VisitInstancesSaveResponseWithAssignIncreaseOvertime> => {
        const missVisitsUrl =
            `agencies/${this.$rootScope.agencyId}/agency_members/${this.$rootScope.agencyMemberId}/bulk_edit_visit_instance`;
        return this.api.post<VisitInstancesSaveResponseWithAssignIncreaseOvertime>(missVisitsUrl, body).then((res) => {
            return res.data;
        });
    }
}
