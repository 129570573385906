/* @ngInject */
export function dataManagerGenerator(DatabaseApi, $filter, $rootScope, generalUtils, visitConsts, CaregiverStatus) {

    var createContractTypesDataManager = function () {

        var contractTypesData = {
            mapped: {},
            activeItems: [],
            items: []
        };
        var contractTypesOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        var loadContractTypes = function () {
            const contractTypes = DatabaseApi.contractTypes();
            const sortedContractTypes = contractTypes.sort(generalUtils.sortByName);
            sortedContractTypes.forEach(function (ct) {
                contractTypesData.mapped[ct.id] = ct;
                contractTypesData.items.push(ct);
                if (ct.active) {
                    contractTypesData.activeItems.push(ct);
                }
            });
        };

        var hasData = function () {
            return contractTypesData.items && contractTypesData.items.length > 0;
        };

        var dataManager = {

            getAllContractTypes: function () {
                if (!hasData()) {
                    loadContractTypes();
                }
                return contractTypesData.items;
            },

            getActiveContractTypes: function () {
                if (!hasData()) {
                    loadContractTypes();
                }
                return contractTypesData.activeItems;
            },

            getDropdownOptions: function () {
                return contractTypesOptions;
            },

            getContractTypeById: function (contractTypeId) {
                if (!hasData()) {
                    loadContractTypes();
                }
                return contractTypesData.mapped[contractTypeId];
            },

        };

        return dataManager;

    };

    const createServiceCodesDataManager = function () {

        const serviceCodesData = {
            mapped: {},
            activeItems: [],
            items: []
        };
        const serviceCodesOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        const loadServiceCodes = function () {
            const serviceCodes = DatabaseApi.serviceCodes();
            const sortedServiceCodes = serviceCodes.sort(generalUtils.sortByCode);
            sortedServiceCodes.forEach(function (sc) {
                serviceCodesData.mapped[sc.id] = sc;
                serviceCodesData.items.push(sc);
                if (sc.isActive) {
                    const mappedSc = {...sc, name: sc.code };
                    serviceCodesData.activeItems.push(mappedSc);
                }
            });
        };

        const hasData = function () {
            return serviceCodesData.items && serviceCodesData.items.length > 0;
        };

        const dataManager = {

            getAllServiceCodes: function () {
                if (!hasData()) {
                    loadServiceCodes();
                }
                return serviceCodesData.items;
            },

            getActiveServiceCodes: function () {
                if (!hasData()) {
                    loadServiceCodes();
                }
                return serviceCodesData.activeItems;
            },

            getDropdownOptions: function () {
                return serviceCodesOptions;
            },

            getServiceCodeById: function (serviceCodeId) {
                if (!hasData()) {
                    loadServiceCodes();
                }
                return serviceCodesData.mapped[serviceCodeId];
            },

        };

        return dataManager;

    };

    const createPayCodesDataManager = function () {

        const payCodesData = {
            mapped: {},
            activeItems: [],
            items: []
        };
        const payCodesOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        const loadPayCodes = function () {
            const payCodes = DatabaseApi.payrollCodes();
            const sortedPayCodes = payCodes.sort(generalUtils.sortByDisplayId);
            sortedPayCodes.forEach(function (pc) {
                payCodesData.mapped[pc.id] = pc;
                payCodesData.items.push(pc);
                if (pc.isActive) {
                    const mappedPc = {...pc, name: pc.displayId };
                    payCodesData.activeItems.push(mappedPc);
                }
            });
        };

        const hasData = function () {
            return payCodesData.items && payCodesData.items.length > 0;
        };

        const dataManager = {

            getAllPayCodes: function () {
                if (!hasData()) {
                    loadPayCodes();
                }
                return payCodesData.items;
            },

            getActivePayCodes: function () {
                if (!hasData()) {
                    loadPayCodes();
                }
                return payCodesData.activeItems;
            },

            getDropdownOptions: function () {
                return payCodesOptions;
            },

            getPayCodeById: function (payCodeId) {
                if (!hasData()) {
                    loadPayCodes();
                }
                return payCodesData.mapped[payCodeId];
            },

        };

        return dataManager;

    };

    const createPatientStatusesDataManager = function () {
        const patientStatusesData = {
            mapped: {},
            items: []
        };
        const patientStatusesOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        const loadPatientStatuses = function () {
            const patientStatuses = DatabaseApi.agencyPatientStatuses()
              .filter((x) => x.active)
              .map((row) => ({
                id: row.text,
                name: $filter("capitalize")(row.text),
              }));

            const sortedPatientStatuses = patientStatuses.sort(generalUtils.sortByName);
            sortedPatientStatuses.forEach(function (x) {
                patientStatusesData.mapped[x.id] = x;
                patientStatusesData.items.push(x);
            });
        };

        const hasData = function () {
            return patientStatusesData.items && patientStatusesData.items.length > 0;
        };

        const dataManager = {

            getAllPatientStatuses: function () {
                if (!hasData()) {
                    loadPatientStatuses();
                }
                return patientStatusesData.items;
            },

            getDropdownOptions: function () {
                return patientStatusesOptions;
            },
        };

        return dataManager;
    };

    const createCaregiverStatusesDataManager = function () {
        const caregiverStatusesData = {
            mapped: {},
            items: []
        };
        const caregiverStatusesOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        const loadCaregiverStatuses = function () {
            const caregiverStatuses = CaregiverStatus.all.map((item) => ({
                id: item,
                name: $filter("capitalize")(item),
            }));
            caregiverStatuses.forEach(function (x) {
                caregiverStatusesData.mapped[x.id] = x;
                caregiverStatusesData.items.push(x);
            });
        };

        const hasData = function () {
            return caregiverStatusesData.items && caregiverStatusesData.items.length > 0;
        };

        const dataManager = {

            getAllCaregiverStatuses: function () {
                if (!hasData()) {
                    loadCaregiverStatuses();
                }
                return caregiverStatusesData.items;
            },

            getDropdownOptions: function () {
                return caregiverStatusesOptions;
            },
        };

        return dataManager;
    };

    var createOfficeDataManager = function () {

        var officeData = {
            mapped: {},
            items: []
        };
        var officeOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        var loadOffices = function () {
            var offices = DatabaseApi.activeOffices();
            offices.forEach(function (office) {
                officeData.mapped[office.id] = office;
                officeData.items.push(office);
            });
        };

        var hasData = function () {
            return officeData.items && officeData.items.length > 0;
        };

        var dataManager = {

            getOffices: function () {
                if (!hasData()) {
                    loadOffices();
                }
                return officeData.items;
            },

            getDropdownOptions: function () {
                return officeOptions;
            },

            getOfficeById: function (officeId) {
                if (!hasData()) {
                    loadOffices();
                }
                return officeData.mapped[officeId];
            },

        };

        return dataManager;

    };

    var createTeamDataManager = function () {

        var teamData = {
            mapped: {},
            items: []
        };
        var teamOptions = {
            displayProp: "name",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        var loadTeams = function () {
            var teams = DatabaseApi.agencyTeams().filter(team => team.active);
            teams.forEach(function (team) {
                if (team.hasCasesNotDischarged) {
                    teamData.mapped[team.id] = team;
                    teamData.items.push(team);
                }
            });
        };

        var hasData = function () {
            return teamData.items && teamData.items.length > 0;
        };

        var dataManager = {

            getTeams: function () {
                if (!hasData()) {
                    loadTeams();
                }
                return teamData.items;
            },

            getDropdownOptions: function () {
                return teamOptions;
            },

            getTeamById: function (teamId) {
                if (!hasData()) {
                    loadTeams();
                }
                return teamData.mapped[teamId];
            },

        };

        return dataManager;
    };

    const createCoordinatorDataManager = function ({onlyActive}) {

        const coordinators = [];

        const coordinatorOptions = {
            displayProp: "displayName",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        const loadCoordinators = function ({onlyActive}) {
            const currentAgencyMemberOfficeIds =
                $rootScope.user.agencyMember.officeIds;
            const agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole() || {};
            let coordinatorsTemp = [
                ...(agencyMembersByRoles.coordinatorsSeniors || []),
                ...(agencyMembersByRoles.coordinators || []),
                //   ...(agencyMembersByRoles.admins || []),
            ]
                .sort(generalUtils.sortByDisplayName)
                .filter((coordinator) => {
                    // filter by office
                    return ((
                        coordinator.officeIds.findIndex((coordinatorOfficeId) => {
                            return currentAgencyMemberOfficeIds.includes(
                                coordinatorOfficeId
                            );
                        }) !== -1) && coordinator.hasCasesNotDischarged
                    );
                });

            if(onlyActive){
                coordinatorsTemp = coordinatorsTemp.filter((coordinator) => coordinator.status === "Active");
            }

            coordinatorsTemp.forEach(temp => {
                coordinators.push(temp); // to avoid angularjs console error
            });
        };

        const hasData = function () {
            return coordinators && coordinators.length > 0;
        };

        const dataManager = {
            getCoordinators: function ({onlyActive}) {
                if (!hasData()) {
                    loadCoordinators({onlyActive});
                }
                return coordinators;
            },

            getDropdownOptions: function () {
                return coordinatorOptions;
            },
        };

        return dataManager;
    };

    var createCertificationDataManager = function () {

        var certificationData = {
            mapped: {},
            items: []
        };
        var certificationOptions = {
            displayProp: "id",
            styleActive: true,
            scrollable: true,
            scrollableHeight: '300px',
            enableSearch: true
        };

        var loadCertifications = function () {
            var certifications = DatabaseApi.activeAgencyCertifications();
            certifications.forEach(function (certification) {
                certificationData.mapped[certification.certification] = certification;
                certificationData.items.push({ id: certification.certification, ...certification });
            });
        };

        var hasData = function () {
            return certificationData.items && certificationData.items.length > 0;
        };

        var dataManager = {

            getCertifications: function () {
                if (!hasData()) {
                    loadCertifications();
                }
                return certificationData.items;
            },

            getDropdownOptions: function () {
                return certificationOptions;
            },

            getCertificationById: function (certificationId) {
                if (!hasData()) {
                    loadCertifications();
                }
                return certificationData.mapped[certificationId];
            },

        };

        return dataManager;

    };

    var createValidtorsDataManager = function (validatorsType) {

        let allValidatorIds = [];
        const validatorsDetailsMap = {};
        switch (validatorsType) {
            case "STAFFING_ISSUES":
                const staffingIssues = visitConsts.staffingIssuesValidatorIds;
                staffingIssues.forEach(issue => {
                    allValidatorIds.push(issue.id);
                    validatorsDetailsMap[issue.id] = issue;
                });
                break;
            case "BILLING":
            default:
                allValidatorIds = visitConsts.allValidatorIds;
                break;
        }
        var validatorsData = {
            mapped: {},
            items: []
        };

        var initValidators = function () {
            var validatorName;
            var validatorToolTip;
            allValidatorIds.forEach((id) => {
                switch (id) {
                    case ("invalid_authorization_limits_period"):
                        validatorName = "Over utilized authorization period";
                        validatorToolTip = "Checks if any of the visit's allocated authorizations exceed their authorization time within that visit's period (daily/weekly/monthly/entire period) by summing each authorization's valid (correct service code, contract, and visit date) allocated time within the period up to and including the visit's start time (two visits with the same start time will both be summed together and thus will both be invalid even if the authorization has enough time for just one of the visits).";
                        break;
                    case ("invalid_authorization_limits_total"):
                        validatorName = "Over utilized authorization total";
                        validatorToolTip = "Checks if any of the visit's allocated authorizations exceed their total authorization time by summing each authorization's valid (correct service code, contract, and visit date) allocated time up to and including the visit's start time (two visits with the same start time will both be summed together and thus will both be invalid even if the authorization has enough time for just one of the visits).";
                        break;
                    case ("invalid_authorization_assignment"):
                        validatorName = "Wrong authorization";
                        validatorToolTip = "Checks if any of the visit's authorization allocations are outside the authorization's start and end dates (if a visit was edited to outside that date range, for instance) and if any of the allocations are for authrizations that do not match the visit's service code and contract.";
                        break;
                    case ("authorization_over_allocation"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Check if the visit's total allocated authorization time is greater than the rounded (defined by the contract used) scheduled time of the visit.";
                        break;
                    case ("missing_authorization_hours"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Check if the visit's total allocated authorization time is less than the rounded (defined by the contract used) scheduled time of the visit, is not scheduled over the authorization period hours, and has exceeded total authorization hours.";
                        break;
                    case ("schedule_auth_allocation_conflict"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Check if the visit's total allocated authorization time is less than the rounded (defined by the contract used) scheduled time of the visit and is scheduled over the authorization period hours or does not exceed total authorization hours.";
                        break;
                    case ("no_matching_authorization"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Check if the visit's patient has an authorization that matches date, contract and service code.";
                        break;
                    case ("missing_clock_times"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit is missing clock in or clock out times (neither should be missing for billing). Does not appear if the visit has no caregiver or is missed.";
                        break;
                    case ("no_caregiver"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit does not have a caregiver assigned to it. Does not appear if the visit is missed.";
                        break;
                    case ("clockin_time_mismatch"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's clock in time is an hour or more before or after the scheduled start time of the visit.";
                        break;
                    case ("clockout_time_mismatch"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's clock out time is an hour or more before or after the scheduled end time of the visit.";
                        break;
                    case ("no_billing_rate"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if no existing billing rate matches this visit's current service code, contract, and billing date.";
                        break;
                    case ("no_billing_rate_county"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if there is no matching billing rate setting (state and county) for this visit within the most recent billing rate that exists for this visit (service code, contract, and billing rate).";
                        break;
                    case ("same_day_visits_with_issues"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if any of the other visits for the same day, patient, contract, service code, and office have any prebilling issues (as defined by the contract's prebilling settings).";
                        break;
                    case ("has_patient_overlap_issue"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit has any other non-missed visits for the same patient and service code that has recorded/clock times that overlap. Does not appear if the visit is missed or is missing clock in/out times.";
                        break;
                    case ("has_caregiver_overlap_issue"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's caregiver has any other non-missed visits that has recorded/clock times that overlap (two visits that use a mutual service code will not trigger this however if either visit does not use a mutual service code it will appear). Does not appear if the visit does not have an assigned caregiver, is missed, or is missing clock in or out times.";
                        break;
                    case ("missing_dx_codes"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit does not have a diagnosis code for the patient and contract nor a default diagnosis code for the contract that matches the visit's billing date.";
                        break;
                    case ("daily_minimum_hours_not_met"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit is using a daily service code and the recorded/clock time total does not meet the minimum daily hours required (as defined by the service code). Does not appear if the visit is missed or has no caregiver.";
                        break;
                    case ("overbilled_day"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's billing date for that patient, contract, service code, and office has more than 24 hours worth of billable seconds (unrounded and least of scheduled time and service time) including missed visits with travel time. Does not appear if the visit is missed.";
                        break;
                    case ("missed_visit_no_travel_time"):
                        validatorName = "Missed visit";
                        validatorToolTip = "Checks if the visit is missed and has no travel time.";
                        break;
                    case ("missing_matching_mutual_visit"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit uses a mutual service code and there is no non-missed visit for the same patient with the same service code that has exact matching scheduled time and clock in within 5 minutes of the original visit's clock in and clock out within 5 minutes of the original visit's clock out. Does not appear if the visit has no service time, assigned caregiver, or is missed.";
                        break;
                    case ("zero_billing_units"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's billable seconds (least of service time and scheduled time or travel time if missed) is rounded (as defined by the contract) to 0. Does not appear if the visit has no caregiver, is missing clock times, or is missed with no travel time.";
                        break;
                    case ("unapproved_manual_clock_time_edit"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's clock in time or clock out time was manually edited by an agency member and was the change was not yet approved. Does not appear if the visit has no caregiver or is missed.";
                        break;
                    case ("caregiver_incompliant"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the visit's assigned caregiver is incompliant on the day of the visit's day of billing. Does not appear if the visit has no assigned caregiver."
                        break;
                    case ("insufficient_visit_duties"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if the number of tasks performed during a visit meets the contract's duty requirement. This issue does not apply if the contract setting is missing or if the visit was missed."
                        break;
                    case ("missing_personal_care_duty"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if at least one personal care task was performed during a visit. This issue does not apply if the contract setting is missing or if the visit was missed."
                        break;
                    case ("excessive_clock_distance"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if a visit that was clocked in/out via mobile is within range of the visit/patient address as defined by the agency setting. This issue will be shown if the max distance is set, the visit was clocked in/out via mobile, the clock in/out was out of range and was not approved or is missing location data.";
                        break;
                    case ("manual_hold_invoicing"):
                        validatorName = $filter('titlecasefromsnake')(id);
                        validatorToolTip = "Checks if a visit is on hold from being invoiced";
                        break;
                    case ("zero_dollar_pay_rate"):
                        validatorName = $filter("titlecasefromsnake")(id);
                        validatorToolTip = "Checks if a visit's pay rate is 0. This issue occurs if a pay rate is found but no matching county/state setting matches the visit, no pay rate is found or the visit has no payroll code."
                        break;
                    case ("missing_caregiver_ssn"):
                        validatorName = $filter("titlecasefromsnake")(id);
                        validatorToolTip = "Checks if the visit's caregiver is missing ssn number.";
                        break;
                    case ("missing_patient_member_id"):
                        validatorName = $filter("titlecasefromsnake")(id);
                        validatorToolTip = "Checks if the visit's patient is missing member ID."
                        break;
                    case ("missing_address"):
                        validatorName = $filter("titlecasefromsnake")(id);
                        validatorToolTip = "Checks if the visit or the patient is missing address."
                        break;
                    default:
                        if (validatorsDetailsMap[id]) {
                            validatorName = validatorsDetailsMap[id].name || $filter('titlecasefromsnake')(id);
                            validatorToolTip = validatorsDetailsMap[id].tooltip || null;
                        } else {
                            validatorName = $filter('titlecasefromsnake')(id);
                            validatorToolTip = null;
                        }
                        break;
                }

                var validator = { id: id, name: validatorName, isSelected: true, tooltip: validatorToolTip };
                validatorsData.mapped[id] = validator;
                validatorsData.items.push(validator);
            });
        };

        var hasData = function () {
            return validatorsData.items && validatorsData.items.length > 0;
        };

        var toggleValidatorsSelection = function (validator) {
            validator.isSelected = !validator.isSelected;
        };

        var getSelectedValidators = function () {
            var selectedValidators = [];
            validatorsData.items.forEach(function (validator) {
                if (validator.isSelected) {
                    selectedValidators.push(validator);
                }
            });
            return selectedValidators;
        };

        var dataManager = {

            getAllValidators: function () {
                if (!hasData()) {
                    initValidators();
                }
                return validatorsData.items;
            },

            getSelectedValidators: getSelectedValidators,

            toggleSelection: toggleValidatorsSelection,

            getValidatorById: function (validatorId) {
                if (!hasData()) {
                    initValidators();
                }
                return validatorsData.mapped[validatorId];
            },

        };

        return dataManager;

    };

    this.createContractTypesDataManager = createContractTypesDataManager;
    this.createServiceCodesDataManager = createServiceCodesDataManager;
    this.createPayCodesDataManager = createPayCodesDataManager;
    this.createPatientStatusesDataManager = createPatientStatusesDataManager;
    this.createCaregiverStatusesDataManager = createCaregiverStatusesDataManager;
    this.createOfficeDataManager = createOfficeDataManager;
    this.createCertificationDataManager = createCertificationDataManager;
    this.createTeamDataManager = createTeamDataManager;
    this.createCoordinatorDataManager = createCoordinatorDataManager;
    this.createValidtorsDataManager = createValidtorsDataManager;

};
