import { Button, Flex } from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import React from "react";

interface Props {
  startDate: LocalDate | null | undefined;
  endDate: LocalDate | null | undefined;
  onClick: (
    range: [LocalDate, LocalDate],
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onClear: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const RangeDatePickerRanges = (props: Props) => {
  const isExactRange = ([start, end]: [LocalDate, LocalDate]) => {
    if (!props.startDate || !props.endDate) {
      return false;
    }

    return start.isEqual(props.startDate) && end.equals(props.endDate);
  };

  return (
    <Flex direction={"column"} gap={3}>
      {Object.entries(getRanges(LocalDate.now())).map(([name, range]) => (
        <Button
          key={name}
          variant={isExactRange(range) ? "solid" : "outline"}
          color={isExactRange(range) ? "primary" : "neutral"}
          size="sm"
          onClick={(e) => props.onClick(range, e)}
        >
          {name}
        </Button>
      ))}
      <Button size="sm" variant="text" color="negative" onClick={props.onClear}>
        Clear
      </Button>
    </Flex>
  );
};

function getRanges(now: LocalDate): {
  [key: string]: [LocalDate, LocalDate];
} {
  return {
    "Last 7 days": [now.minusDays(7), now],
    "Last 14 days": [now.minusDays(14), now],
    "Last 30 days": [now.minusDays(30), now],
    "This month": [LocalDate.of(now.year(), now.month(), 1), now],
    "Last month": [
      now.minusMonths(1).withDayOfMonth(1),
      now.minusMonths(1).withDayOfMonth(now.minusMonths(1).lengthOfMonth()),
    ],
    "Last year": [now.minusYears(1), now],
  };
}

export default RangeDatePickerRanges;
