import axios, { AxiosResponse } from "axios";
import React from "react";
import useAuth from "../shared/hooks/useAuth";
import type { components, paths } from "../shared/schema/schema";
import { checkPathHasValidator, getPathValidator } from "../shared/schema/validate-message";

/**
 * TODO:
 *
 * 1. Get types of errors as well (403 as well)
 */

function createEndpoint(params: {
  path: string;
  params: {
    path?: Record<string, unknown>;
    query?: Record<string, unknown>;
  };
}) {
  const {
    path,
    params: { path: pathParams, query },
  } = params;

  let url = path.replace(/:([^/]+)/g, (_match, key) => {
    if (pathParams === undefined) {
      throw new Error(`Missing path param ${key} in ${path}`);
    }

    const value: unknown = pathParams[key];

    if (value === undefined) {
      throw new Error(`Missing path param: ${key}`);
    }

    if (value === null) {
      return "null";
    }

    return `${value}`;
  });

  if (query !== null && query !== undefined && Object.keys(query).length > 0) {
    url = `${url}?${serializeQuery(query)}`;
  }

  return url;
}

function serializeQuery<T>(params: T) {
  const parsed = JSON.parse(JSON.stringify(params));

  const queries: string[] = [];

  for (const [key, value] of Object.entries(parsed)) {
    if (value === undefined) {
      continue;
    }

    if (Array.isArray(value)) {
      for (const item of value) {
        queries.push(`${key}[]=${encodeURIComponent(item)}`);
      }
      continue;
    }

    queries.push(`${key}=${encodeURIComponent(value as string | number)}`);
  }

  return queries.join("&");
}

export type GETPath = {
  [key in keyof paths]: paths[key] extends { get: unknown } ? key : never;
}[keyof paths];

export type POSTPath = {
  [key in keyof paths]: paths[key] extends { post: unknown } ? key : never;
}[keyof paths];

export type PUTPath = {
  [key in keyof paths]: paths[key] extends { put: unknown } ? key : never;
}[keyof paths];

export type PATCHPath = {
  [key in keyof paths]: paths[key] extends { patch: unknown } ? key : never;
}[keyof paths];

export type GETQueryParamsPath<$Url extends GETPath> = paths[$Url]["get"]["parameters"] extends {
  query: unknown;
}
  ? paths[$Url]["get"]["parameters"]["query"]
  : never;

type SuccessResponse = {
  200: {
    content: {
      "application/json": unknown;
    };
  };
};

type RequestBodyOf<T> = T extends {
  requestBody: {
    content: {
      "application/json": infer U;
    };
  };
}
  ? U
  : never;

type RequestBodyOfPOST<$Url extends POSTPath> = RequestBodyOf<paths[$Url]["post"]>;

export type GetSuccessResponse<$Url extends GETPath> =
  paths[$Url]["get"]["responses"] extends SuccessResponse
    ? paths[$Url]["get"]["responses"][200]["content"]["application/json"]
    : never;

export type PostSuccessResponse<$Url extends POSTPath> =
  paths[$Url]["post"]["responses"] extends SuccessResponse
    ? paths[$Url]["post"]["responses"][200]["content"]["application/json"]
    : never;

export type PutSuccessResponse<$Url extends PUTPath> =
  paths[$Url]["put"]["responses"] extends SuccessResponse
    ? paths[$Url]["put"]["responses"][200]["content"]["application/json"]
    : never;

export type PatchSuccessResponse<$Url extends PATCHPath> =
  paths[$Url]["patch"]["responses"] extends SuccessResponse
    ? paths[$Url]["patch"]["responses"][200]["content"]["application/json"]
    : never;

export type API = ReturnType<typeof createApi>;

export type Messages = components["schemas"];

export function createApi(params: {
  baseUrl: string;
  authToken: string;
  refreshToken: string;
  onInvalidRefreshToken: () => void;
  onTokenRefreshed: (data: PostSuccessResponse<"/auth/token">) => void;
}) {
  const { baseUrl, onInvalidRefreshToken } = params;

  let tokens = {
    authToken: params.authToken,
    refreshToken: params.refreshToken,
  };

  let $refetchTokenPromise: Promise<void> | null = null;

  async function refetchToken() {
    if ($refetchTokenPromise !== null) {
      console.log("[api] token refetch is already in progress");
      return $refetchTokenPromise;
    }

    console.log("[api] token refetch is starting");

    $refetchTokenPromise = new Promise<void>((resolve, reject) => {
      axios({
        method: "POST",
        baseURL: params.baseUrl,
        url: "/auth/token",
        headers: {
          "X-MedFlyt-grant-type": tokens.refreshToken,
        },
      })
        .then((response: AxiosResponse<PostSuccessResponse<"/auth/token">>) => {
          tokens = {
            authToken: response.data.accessJWT,
            refreshToken: response.data.refreshJWT,
          };

          $refetchTokenPromise = null;
          params.onTokenRefreshed(response.data);
          console.log("[api] token refetch is done");
          resolve();
        })
        .catch(() => {
          console.log("[api] token refetch failed");
          onInvalidRefreshToken();
          reject();
        });
    });

    return $refetchTokenPromise;
  }

  class InvalidResponseError extends Error {
    readonly detail: unknown;
    readonly responseJSON: unknown;
    readonly errorJSON: unknown;

    constructor(params: {
      message: string;
      detail: string;
      responseJSON: unknown;
      errorJSON: unknown;
    }) {
      super(params.message);
      this.detail = params.detail;
      this.responseJSON = params.responseJSON;
      this.errorJSON = params.errorJSON;
    }

    toJSON() {
      return {
        detail: this.detail,
        responseJSON: this.responseJSON,
        errorJSON: this.errorJSON,
      };
    }
  }

  async function withValidator<T>(params: {
    data: T;
    method: "GET" | "POST" | "PUT" | "PATCH";
    url: string;
  }): Promise<T> {
    const hasValidator = await checkPathHasValidator(params.method, params.url);

    if (hasValidator) {
      const validate = await getPathValidator(params.method, params.url);
      if (validate(params.data) !== true) {
        throw new InvalidResponseError({
          message: `JSON schema validation error ${JSON.stringify(validate.errors)}`,
          detail: "",
          responseJSON: params.data,
          errorJSON: validate.errors,
        });
      }
    }

    return params.data;
  }

  async function get<$Url extends GETPath>(
    params: {
      url: $Url;
      params: paths[$Url]["get"]["parameters"];
    },
    refetchTokenOn401 = true
  ): Promise<GetSuccessResponse<$Url>> {
    const { authToken: currentAuthToken } = tokens;

    try {
      const { data } = await axios({
        method: "GET",
        baseURL: baseUrl,
        url: createEndpoint({ path: params.url, params: params.params }),
        headers: {
          Authorization: `Token ${currentAuthToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "GET",
        url: params.url,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && refetchTokenOn401) {
          if (currentAuthToken === tokens.authToken) {
            await refetchToken();
          }

          console.log(`[api] retrying ${params.url}`);
          return get(params, false);
        }
      }

      throw error;
    }
  }

  async function post<$Url extends POSTPath>(
    params: {
      url: $Url;
      params: paths[$Url]["post"]["parameters"];
      body: RequestBodyOfPOST<$Url> extends never ? Record<string, never> : RequestBodyOfPOST<$Url>;
    },
    refetchTokenOn401 = true
  ): Promise<PostSuccessResponse<$Url>> {
    try {
      const { data } = await axios({
        method: "POST",
        baseURL: baseUrl,
        url: createEndpoint({ path: params.url, params: params.params }),
        data: params.body,
        headers: {
          Authorization: `Token ${tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "GET",
        url: params.url,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && refetchTokenOn401) {
          return refetchToken().then(() => post(params, false));
        }
      }

      throw error;
    }
  }

  type RequestBodyOfPUT<$Url extends PUTPath> = RequestBodyOf<paths[$Url]["put"]>;

  async function put<$Url extends PUTPath>(
    params: {
      url: $Url;
      params: paths[$Url]["put"]["parameters"];
      body: RequestBodyOfPUT<$Url> extends never ? Record<string, never> : RequestBodyOfPUT<$Url>;
    },
    refetchTokenOn401 = true
  ): Promise<PutSuccessResponse<$Url>> {
    try {
      const { data } = await axios({
        method: "PUT",
        baseURL: baseUrl,
        url: createEndpoint({ path: params.url, params: params.params }),
        data: params.body,
        headers: {
          Authorization: `Token ${tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "GET",
        url: params.url,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && refetchTokenOn401) {
          return refetchToken().then(() => put(params, false));
        }
      }

      throw error;
    }
  }

  type RequestBodyOfPATCH<$Url extends PATCHPath> = RequestBodyOf<paths[$Url]["patch"]>;

  async function patch<$Url extends PATCHPath>(
    params: {
      url: $Url;
      params: paths[$Url]["patch"]["parameters"];
      body: RequestBodyOfPATCH<$Url> extends never
        ? Record<string, never>
        : RequestBodyOfPATCH<$Url>;
    },
    refetchTokenOn401 = true
  ): Promise<PatchSuccessResponse<$Url>> {
    try {
      const { data } = await axios({
        method: "PATCH",
        baseURL: baseUrl,
        url: createEndpoint({ path: params.url, params: params.params }),
        data: params.body,
        headers: {
          Authorization: `Token ${tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "GET",
        url: params.url,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && refetchTokenOn401) {
          return refetchToken().then(() => patch(params, false));
        }
      }

      throw error;
    }
  }

  return { get, post, put, patch };
}

const amEndpointBaseUrl = "/agencies/:agencyId/agency_members/:agencyMemberId" as const;

type AMEndpointU<T> = T extends `${typeof amEndpointBaseUrl}${infer U}` ? U : never;

type AMEndpoint<X extends string> = {
  [key in X]: AMEndpointU<key>;
}[X];

export const endpoints = {
  get: <$Endpoint extends AMEndpoint<GETPath>>(endpoint: $Endpoint) => {
    return `${amEndpointBaseUrl}${endpoint}` as const;
  },
  post: <$Endpoint extends AMEndpoint<POSTPath>>(endpoint: $Endpoint) => {
    return `${amEndpointBaseUrl}${endpoint}` as const;
  },
  patch: <$Endpoint extends AMEndpoint<PATCHPath>>(endpoint: $Endpoint) => {
    return `${amEndpointBaseUrl}${endpoint}` as const;
  },
};

export const ApiContext = React.createContext<ReturnType<typeof createApi>>({} as any);

export const ApiProvider = (props: { children: React.ReactNode }) => {
  const { authInfo, setTokens, logout } = useAuth();

  const api = createApi({
    baseUrl: import.meta.env.API_URL,
    authToken: authInfo.authToken,
    refreshToken: authInfo.refreshToken,
    onTokenRefreshed: setTokens,
    onInvalidRefreshToken: () => {
      console.log("[withApi]: invalid refresh token. logging out");
      logout(() => window.location.reload());
    },
  });

  return <ApiContext.Provider value={api}>{props.children}</ApiContext.Provider>;
};
