//! @ngInject
export function approveDutySheetsClockTimesModalCtrl(
    $scope,
    $uibModalInstance,
    entityNoteService,
    noteConsts
) {
    $scope.approveDutySheetsModel = {
        note: null,
        manualClockTimeEditApproved: $scope.$resolve.changeToTimesheetStatus === "APPROVED",
    };
    $scope.showCheckboxManualEdit = $scope.$resolve.changeToTimesheetStatus === undefined;
    $scope.isAdjutmentApprovalNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.ADJUSTMENT_APPROVAL);
    $scope.approvalNote = {};
    // FOR THE APPROVAL NOTE, WE HAVE requestNoteText, indicates 'billing/paid/payroll draft';

    $scope.clockReasonsExtraSettings = {
        styleActive: true,
        singleSelection: false,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false
    }

    $scope.approveForm = () => {
        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
            $scope.approveDutySheetsModel.note,
            noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME
        );

        if (!isNoteValid) {
            $scope.noteValidations = { isPredefinedValid, isMessageValid };
            return;
        }

        if ($scope.$resolve.requestNoteText) {
            // Adjustment Approval Note may be optional but visits will not update
            if (Object.keys($scope.approvalNote).length === 0) {
                $scope.approvalNoteValidations = {isPredefinedValid: false, isMessageValid: false};
                return;
            }

            const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
                $scope.approvalNote, 
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL
            );
            if (!isNoteValid) {
                $scope.approvalNoteValidations = { isPredefinedValid, isMessageValid };
                return;
            }
        }

        $scope.closeModal({
            approveDutySheetNote: $scope.approveDutySheetsModel,
            approvalNote: $scope.approvalNote
        });
    }

    $scope.handleNoteChanges = (noteModel, updatedNote) => {
        if (noteModel === "manualClockTime") {
            $scope.approveDutySheetsModel.note = updatedNote;
        } else if (noteModel === "approvalNote") {
            $scope.approvalNote = updatedNote;
        }
    };

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };
};