type Loading = { type: "Loading" };
type Resolved<T> = { type: "Resolved"; value: T };
type Rejected<E> = { type: "Rejected"; error: E };
export type Loadable<T> = Loading | Resolved<T> | Rejected<any>;

export const loadable = {
  loading(): Loading {
    return { type: "Loading" };
  },

  resolve<T>(value: T): Resolved<T> {
    return { type: "Resolved", value };
  },

  reject<T>(error: T): Rejected<T> {
    return { type: "Rejected", error };
  },

  isLoading<T>(value: Loadable<T>): value is Loading {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Loading"
    );
  },

  isResolved<T>(value: Loadable<T>): value is Resolved<T> {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Resolved"
    );
  },

  isRejected<T>(value: Loadable<T>): value is Rejected<T> {
    return (
      typeof value === "object" && value !== null && "type" in value && value.type === "Rejected"
    );
  },
};

export type LoadableConstructor = typeof loadable;
