import { DayOfWeek, LocalDateTime, LocalTime } from "js-joda";
import { VisitInstanceId } from "../../../scripts/messages/ids";
import { VisitInstance } from "../../../scripts/messages/visit";
import { Caregiver } from "../../../scripts/messages/caregiver";
import { PatternShiftTimes, Shift, ShiftId, VisitAssignmentRequest } from "./visit-table-common.types";
import {
  CaregiverInShift,
  CaregiverVisitRequest
} from "./caregivers-visits-requests-table/caregivers-visits-requests-table.types";
import { isDefined } from "../../../scripts/utils/generalUtils";

export function generatePatternShiftId(shiftDetails: PatternShiftTimes){
    return `${shiftDetails.dayOfWeek.ordinal()}&&${shiftDetails.startTime.toString()}&&${shiftDetails.endTime.toString()}`
}
  
export function decouplePatternShiftIdToShiftDetails(shiftId: string): PatternShiftTimes{
    const splittedKey = shiftId.split("&&");
    return {
        dayOfWeek: DayOfWeek.of(parseInt(splittedKey[0], 10) + 1),
        startTime: LocalTime.parse(splittedKey[1]),
        endTime: LocalTime.parse(splittedKey[2])
    }
}
  
export function getShiftId(shift: Shift): ShiftId {
    if(shift.type === "PATTERN"){
        return generatePatternShiftId({...shift});
    }

    return shift.visitInstanceId;
}

export function arePatternShiftsEqual(firstShiftTimes: PatternShiftTimes, secondShiftTimes: PatternShiftTimes){
    return firstShiftTimes.dayOfWeek.equals(secondShiftTimes.dayOfWeek) &&
        firstShiftTimes.endTime.equals(secondShiftTimes.endTime) &&
        firstShiftTimes.startTime.equals(secondShiftTimes.startTime);
}


export function mapVisitInstancesTimes(visitInstances: VisitInstance[]) {
  // This is a very ugly walkaround I have to do because for some reason the instances start and end time are strings at runtime,
  // even though they are listed in the interface as LocalDateTime.
  return visitInstances.map((instance) => ({
    ...instance,
    startTime: LocalDateTime.parse(instance.startTime.toString()),
    endTime: LocalDateTime.parse(instance.endTime.toString())
  }));
}

export function getRequestedShifts(shifts: Shift[], requestedInstances: VisitInstanceId[]) {
  return shifts.filter((shift) => {
    if (shift.type === "PATTERN") {
      return shift.visitInstancesIds.some((instanceId) =>
        requestedInstances.includes(instanceId)
      );
    }

    return requestedInstances.includes(shift.visitInstanceId);
  });
}

const daysOfWeekDisplay = {
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday"
}

export function getPatternVisitShifts(
  visitInstances: VisitInstance[],
  caregiversByInstanceIdMap: Map<VisitInstanceId, Caregiver[]>
  // caregiverVisitRequests: CaregiverVisitRequest[]
) {
  // const caregiversByInstanceIdMap = mapCaregiversToInstancesIds(caregiverVisitRequests)

  const shifts: Shift[] = [];
  const instancesByShiftKey = new Map<string, VisitInstanceId[]>();

  for (const currInstance of visitInstances) {
    const currShift = {
      startTime: currInstance.startTime.toLocalTime(),
      endTime: currInstance.endTime.toLocalTime(),
      dayOfWeek: currInstance.startTime.dayOfWeek(),
    };

    const shiftKey = generatePatternShiftId(currShift);
    const visitInstancesInShift = instancesByShiftKey.get(shiftKey);

    if (visitInstancesInShift === undefined) {
      instancesByShiftKey.set(shiftKey, [currInstance.id]);
    } else {
      visitInstancesInShift.push(currInstance.id);
      instancesByShiftKey.set(shiftKey, visitInstancesInShift);
    }
  }

  for (const [shiftId, visitInstancesIds] of instancesByShiftKey.entries()) {
    const patternShiftDetails = decouplePatternShiftIdToShiftDetails(shiftId);
    const caregivers = visitInstancesIds.flatMap(
      (instanceId) => caregiversByInstanceIdMap.get(instanceId) ?? []
    );

    shifts.push({
      type: "PATTERN",
      ...patternShiftDetails,
      caregivers: caregivers,
      visitInstancesIds,
      displayTitle: `${daysOfWeekDisplay[patternShiftDetails.dayOfWeek.toString()]}s`,
      secondaryDisplayTitle: `${patternShiftDetails.startTime} - ${patternShiftDetails.endTime}`,
      isOvernight: false
    });
  }

  return shifts;
}

export function getSinglesVisitShifts(
  visitInstances: VisitInstance[],
  // caregiverVisitRequests: CaregiverVisitRequest[],
  caregiversByInstanceIdMap: Map<VisitInstanceId, Caregiver[]>,
  $filter: ng.IFilterService
) {
  // const caregiversByInstanceIdMap = mapCaregiversToInstancesIds(caregiverVisitRequests)
  const shifts: Shift[] = [];

  for (const currInstance of visitInstances) {
    shifts.push({
      type: "SINGLES",
      startTime: currInstance.startTime,
      endTime: currInstance.endTime,
      caregivers: caregiversByInstanceIdMap.get(currInstance.id) ?? [],
      visitInstanceId: currInstance.id,
      displayTitle: `${$filter("mfShortDate")(currInstance.startTime.toLocalDate())}`,
      secondaryDisplayTitle: `${$filter("mfShortTime")(
        currInstance.startTime
      )} - ${$filter("mfShortTime")(currInstance.endTime)}`,
      isOvernight: currInstance.startTime
        .toLocalDate()
        .isBefore(currInstance.endTime.toLocalDate()),
    });
  }

  return shifts;
}

export function mapCaregiversIntoCaregiversInShifts(
  shifts: Shift[],
  caregiverVisitRequests: CaregiverVisitRequest[],
  visitAssignmentRequests: VisitAssignmentRequest[]
): CaregiverInShift[] {
  const caregivers: CaregiverInShift[] = [];

  for (const { caregiver, requestedInstances } of caregiverVisitRequests) {
    const requestedShifts = getRequestedShifts(shifts, requestedInstances);
    if (requestedShifts.length !== 0) {
      caregivers.push({
        ...caregiver,
        shiftsCount: requestedShifts.length,
        requestedShifts,
        assignedShifts: []
      });
    }
  }

  for (const { caregiver, assignedInstances } of visitAssignmentRequests) {
    const foundCaregiver = caregivers.find(({ id }) => caregiver.id === id);
    const assignedInstancesIds = assignedInstances.map(({ visitInstanceId }) => visitInstanceId);
    const assignedShifts = getRequestedShifts(shifts, assignedInstancesIds);

    if (isDefined(foundCaregiver)) {
      foundCaregiver.assignedShifts.push(...assignedShifts);
      foundCaregiver.shiftsCount += assignedShifts.length;
    } else {
      caregivers.push({
        ...caregiver,
        shiftsCount: assignedShifts.length,
        requestedShifts: [],
        assignedShifts
      });
    }
  }

  return caregivers;
}