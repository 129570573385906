import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";
import phoneUtils from "../utils/phoneUtils";

//! @ngInject
export function addCaregiverCtrl(
    $scope,
    $rootScope,
    $state,
    DatabaseApi,
    toaster,
    Analytics,
    offices,
    generalUtils,
    mfModal
) {

    $scope.form = {};

    const initialize = () => {
      initCertifications();
      initOffices();
      initMap();
      initLanguages();
      initBranches();
      initEmptyForm();
    };

    const initEmptyForm = () => {
      $scope.form = {};
      $scope.form.offices = [];
      if ($scope.offices.length === 1) {
        $scope.form.offices.push({ id: $scope.offices[0].id });
      }
      $scope.form.certifications = [];
      $scope.form.languages = [];
      $scope.form.emergencyContacts = [];
      $scope.form.caregiverBranches = [];
      if ($scope.agencyBranches.length === 1) {
        $scope.form.caregiverBranches.push({ id: $scope.agencyBranches[0].id });
      }
    };

    const initCertifications = () => {
      const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
      $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
          id: index,
          label: certificationItem.certification
        }));
    };

    const initOffices = () => {
      $scope.offices = offices.map(office => ({
        id: office.id,
        label: office.name
      }));

      $scope.officesExtraSettings = {
        styleActive: true,
        smartButtonMaxItems: 3
      };
    };

    const initBranches = () => {
      const agencyBranches = DatabaseApi.agencyBranches() || [];
      $scope.agencyBranches = agencyBranches.map(branch => ({
        id: branch.id,
        label: branch.name
      }));
      $scope.agencyBranchesExtraSettings = {
        styleActive: true,
        smartButtonMaxItems: 3,
        enableSearch: true
      };
    };

    const initMap = () => {
      $scope.map = { center: { latitude: 40.00, longitude: -99.00 }, zoom: 18 };

      $scope.marker = {
        id: 0,
        coords: {
          latitude: 40.00,
          longitude: -99.00,
        },
        options: { draggable: false },
      };

      $scope.$watch('map.center', function () {
        if ($scope.map && $scope.map.center) {
          mapChange();
        }
      }, true);
    };

    const initLanguages = () => {
      $scope.languages = [
        "Albanian",
        "Arabic",
        "Armenian",
        "Bengali",
        "Bulgarian",
        "Cantonese",
        "Chinese",
        "Creole",
        "Danish",
        "Dutch",
        "English",
        "Estonian",
        "Farsi",
        "Filipino",
        "French",
        "Fukkianese",
        "Fula/Fulani",
        "Fuzhounese",
        "Georgian",
        "German",
        "Ghana",
        "Greek",
        "Hakka",
        "Hebrew",
        "Hindi",
        "Hungarian",
        "Italian",
        "Japanese",
        "Korean",
        "Krio",
        "Kyrgyz",
        "Mandarin",
        "Pashto",
        "Persian",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Romanian",
        "Russian",
        "Shanghainese",
        "Sign",
        "Soninke",
        "Spanish",
        "Swahili",
        "Tagalog",
        "Taishanese",
        "Taiwanese",
        "Turkish",
        "Twi",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Vietnamese",
        "Yiddish"
      ];

      $scope.onLanguagesChange = () => {
        $scope.langError = false;
      };
    };


    let map = null, marker = null;

    const mapChange = () => {
      if (map) {
        map.setView(
          [$scope.map.center.latitude, $scope.map.center.longitude],
          $scope.map.zoom
        );
        marker.setLatLng([$scope.marker.coords.latitude, $scope.marker.coords.longitude]);

      } else {
        map = L.map('map', {
          center: [$scope.map.center.latitude, $scope.map.center.longitude],
          zoom: $scope.map.zoom
        });
        L.tileLayer('https://d5nz7zext4ylq.cloudfront.net/{z}/{x}/{y}.png', {
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 5,
          attribution: '',
          crossOrigin: true
        }).addTo(map);
        if ($scope.marker && $scope.marker.coords) {
          marker = L.marker(
            [$scope.marker.coords.latitude, $scope.marker.coords.longitude]
          ).addTo(map);
        }
      }
    };

    $scope.handleLocationChange = function () {
      if ($scope.form.address && $scope.form.address.geometry) {
        $scope.map.center.latitude = $scope.form.address.geometry.location.lat();
        $scope.map.center.longitude = $scope.form.address.geometry.location.lng();
        $scope.marker.coords.latitude = $scope.form.address.geometry.location.lat();
        $scope.marker.coords.longitude = $scope.form.address.geometry.location.lng();

        var geo = {
          lat: $scope.form.address.geometry.location.lat(),
          lng: $scope.form.address.geometry.location.lng()
        };
        $scope.form.address = $scope.form.address.formatted_address;
        $scope.form.addressGeoLocation = geo;
      }
    };

    const validateNewCaregiverForm = () => {
      if ($scope.caregiver) {
        Object.values($scope.caregiver).forEach(a => {
          if (a && a.$invalid) {
            a.$invalid = undefined;
          }
        });
      }

      const body = {
        errorMessages: [],
        firstName: $scope.form.firstName,
        middleName: $scope.form.middleName,
        lastName: $scope.form.lastName,
        gender: $scope.form.gender,
        ssn: $scope.form.ssn,
        homePhone: null,
        photoUrl: null,
        birthDateDate: $scope.form.birthDate,
        address: $scope.form.address,
        address2: $scope.form.address2,
        email: $scope.form.email,
        status: $scope.form.status,
        caregiverCode: $scope.form.caregiverCode,        
        stateRegistryNum: $scope.form.stateRegistryNum,
        caregiverBranches: $scope.form.caregiverBranches.map(branch => branch.id)
      };

      if ($scope.caregiver.$invalid) {
        body.errorMessages.push("Missing Information, Please Complete The Form");
      }
      if ($scope.form.certifications.length === 0) {
        body.errorMessages.push("No Certifications Selected, Please Select.");
      } else {
        body.certification = $scope.form.certifications.map(c => c.label);
      }
      if ($scope.form.hireDate) {
        body.hireDate = LocalDate.from(nativeJs(moment($scope.form.hireDate)));
      }
      if ($scope.form.birthDate) {
        body.birthDateDate = LocalDate.from(nativeJs(moment($scope.form.birthDate)));
      }
      if (
        $scope.form.stateRegistryNum !== undefined
        && (
          $scope.form.stateRegistryNum > 2147483647 ||
          $scope.form.stateRegistryNum <= 0 ||
          !$scope.form.stateRegistryNum.match(/^\d{1,10}$/)
        )
      ) {
        body.errorMessages.push("The state registry number is not valid.");
        $scope.caregiver.stateRegistryNum = { $invalid: true };
      } else {
        body.stateRegistryNum = Number.parseInt($scope.form.stateRegistryNum);
      }
      if (!body.ssn || (body.ssn && !generalUtils.isSsnValid(body.ssn))) {
        body.errorMessages.push("Invalid SSN");
      }
      const isPhoneNumberEmpty = (
        [null, undefined, "", "+1"].includes($scope.form.phoneNumber) ||
        $scope.form.phoneNumber.trim() === ""
      );
      if (isPhoneNumberEmpty || !phoneUtils.isValidNumber($scope.form.phoneNumber)) {
        body.errorMessages.push("Phone Number Invalid");
      } else {
        body.phoneNumber = $scope.form.phoneNumber;
      }
      if ($scope.form.languages.length === 0) {
        body.errorMessages.push("No Language Selected. Please Select A Language.")
        $scope.langError = true;
      } else {
        body.languages = $scope.form.languages;
      }
      if ($scope.form.offices.length === 0) {
        body.errorMessages.push("No Office Selected. Please Select An Office.");
      } else {
        body.officeIds = $scope.form.offices.map(office => office.id);
      }
      if (body.status === 'ACTIVE' && $rootScope.showBillingFeature) {
        const isActiveStatusEligible = generalUtils.validateCaregiverEligibleActiveStatus($scope.form.hireDate, $scope.form.ssn);
        if (!isActiveStatusEligible) {
          body.errorMessages.push("");
        };
      }
      if (!$scope.form.caregiverCode) {
        body.caregiverCode = null;
      }

      body.emergencyContacts = $scope.form.emergencyContacts.map((contact, index) => {
        const indexPart = $scope.form.emergencyContacts.length > 1 ? ` #${index + 1}` : ``;

        if (!contact.name || contact.name.trim() === "") {
          $scope.caregiver[`emergencyContact-${index}-name`] = { $invalid: true };
          body.errorMessages.push(`Missing or invalid emergency contact${indexPart} name`);
        }
        if (contact.livesWithPatient === undefined) {
          contact.livesWithPatient = null;
        }
        const isPhone1Empty = (
          [null, undefined, "", "+1"].includes(contact.emergencyPhoneNumber1) ||
          contact.emergencyPhoneNumber1.trim() === ""
        )
        if (
          isPhone1Empty ||
          !phoneUtils.isValidNumber(contact.emergencyPhoneNumber1)
        ) {
          $scope.caregiver[`emergencyContact-${index}-phone1`] = { $invalid: true };
          body.errorMessages.push(`Missing or invalid emergency contact${indexPart} phone #1`);
        }
        const isPhone2Empty = (
          [null, undefined, "", "+1"].includes(contact.emergencyPhoneNumber2Form) ||
          contact.emergencyPhoneNumber2Form.trim() === ""
        );
        if (
          !isPhone2Empty &&
          !phoneUtils.isValidNumber(contact.emergencyPhoneNumber2)
        ) {
          $scope.caregiver[`emergencyContact-${index}-phone2`] = { $invalid: true };
          body.errorMessages.push(`Invalid emergency contact${indexPart} phone #2`);
        }

        return {
          name: contact.name,
          relationship: contact.relationship,
          livesWithPatient: contact.livesWithPatient,
          phone1: contact.emergencyPhoneNumber1,
          phone2: contact.emergencyPhoneNumber2 || "",
          address: contact.address
        };
      });

      if (body.errorMessages.length === 0) {
        delete body.errorMessages;
      }
      return body;
    };

    $scope.createCaregiver = () => {
      const body = validateNewCaregiverForm();
      if (body && body.errorMessages && body.errorMessages.length > 0) {
        const modal = mfModal.create({
          variant: "danger",
          subject: "Error",
          message: body.errorMessages.join("\n"),
          layoutOrder: ["message"],
          confirmLabel: "Ok",
          hideCancelButton: true,
          onComplete: () => {
            modal.close();
            window.scrollTo(0, 0);
          }
        });
        return;
      }
      const url = "agencies/:agencyId/coordinators/:agencyMemberId/caregivers"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId);
      DatabaseApi.post(url, body).then((res) => {
        window.scrollTo(0, 0);
        if (res.data.isNewCaregiver || res.data.isNewAssociation) {
          toaster.pop('success', "Success", 'New caregiver has been added');

          Analytics.event('new-caregiver-added', {
            gender: $scope.form.gender,
            languages: $scope.form.languages,
            name: $scope.form.firstName + ' ' + $scope.form.lastName,
            role: $scope.form.role,
            certification: $scope.form.certification,
            status: $scope.form.status,
            ssn: $scope.form.ssn
          });

          DatabaseApi.caregivers('refresh');
          $scope.backToCaregivers();
        } else {
          toaster.pop('warning', "Phone number already exists", '');
        }
      }, (err) => {
        console.error(err);
        const errorMessages = (err && err.data && err.data.details) || [];
        const modal = mfModal.create({
          variant: "danger",
          subject: "Failed to create caregiver",
          message: errorMessages.join("\n"),
          layoutOrder: ["message"],
          confirmLabel: "Ok",
          hideCancelButton: true,
          onComplete: () => {
            modal.close();
            window.scrollTo(0, 0);
          }
        });
      });

    };

    $scope.newCaregiverAddEmergencyContact = () => {
      $scope.form.emergencyContacts.push({
        name: "",
        relationship: "",
        livesWithPatient: null,
        emergencyPhoneNumber1: "",
        emergencyPhoneNumber2: "",
        address: ""
      });
    };

    $scope.newCaregiverRemoveEmergencyContact = (idx) => {
      $scope.form.emergencyContacts.splice(idx, 1);
    }

    $scope.setPhone = (phoneNumber, field, emergencyContactIndex) => {
      let val = (phoneNumber === undefined || phoneNumber === "") ? undefined : angular.copy(phoneNumber);
      if (val) {
        val = "+1" + val.replace(/\+1/g, "");
      }
  
      if (emergencyContactIndex === undefined) {
        $scope.form[field] = val;
      } else {
        $scope.form.emergencyContacts[emergencyContactIndex][field] = val;
      }
    };

    $scope.backToCaregivers = () => {
      $state.go('app.professionals.caregivers');
    };

    initialize();
  };