'use strict';
export const entityNoteFiles = {
    templateUrl: "admin/views/entity-note/entity-note-files.html",
    bindings: {
        note: '<',
        handleNewNoteFileSelection: '&'
    },
    //! @ngInject
    controller: function (
        $scope,
        noteService,
        toaster,
        DatabaseApi,
    ) {
        this.newFile = null;
        this.newFilebase64Content = null;
        const agencyMembers = DatabaseApi.getAgencyMembersArr();

        this.clearFile = () => {
            this.newFile = null;
            this.newFilebase64Content = null;
        };

        this.handleNewFilesChange = () => {
            if (!this.newFile) {
                return;
            }
            const that = this;
            const reader = new FileReader();
            reader.onloadend = function () {
                that.newFilebase64Content = reader.result;
                if (!$scope.$ctrl.note || !$scope.$ctrl.note.id) {
                    $scope.$ctrl.handleNewNoteFileSelection({ base64File: that.newFilebase64Content });
                }
            };
            reader.readAsDataURL(this.newFile);
        };

        this.handleUploadExistingNoteFile = () => {
            const that = this;
            if (!this.newFilebase64Content) {
                return;
            }

            const body = {
                base64File: this.newFilebase64Content
            };

            that.isUploadingFile = true;
            noteService.createNoteFile(this.note.id, body).then(function (createdFile) {
                toaster.pop("success", "Note file created successfully");
                const agencyMember = agencyMembers.find(a => a.id === createdFile.createdBy);
                if (agencyMember) {
                    createdFile.createdByText = agencyMember.displayName;
                }

                that.note.files.push(createdFile);
                that.clearFile();
            }).catch(function (err) {
                toaster.pop("warning", "something went wrong", "Could not create a note file");
            }).finally(() => (that.isUploadingFile = false));
        };
    }
};