import ng from "angular";
import { Caregiver } from "../../../../scripts/messages/caregiver";
import "./caregiver-details.component.scss";
import { CaregiverId } from "../../../../scripts/messages/ids";
import { FlyToCaregiver } from "../visit-table-common.types";


type CaregiverWithExtraDetails = Caregiver & {
  numOfRequestingShifts?: number;
  requestMatchesVisitPrecentage?: number;
}

interface CaregievrDetailsBindings extends FlyToCaregiver {
    caregiver: CaregiverWithExtraDetails;
    showExtra: boolean;
}

//! @ngInject
class caregiverDetailsCtrl implements ng.IComponentController, CaregievrDetailsBindings
{
    caregiver!: CaregiverWithExtraDetails;
    showExtra = true;
    startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
    stopFlyToCaregiver!: () => void;

    constructor(private $rootScope: angular.IRootScopeService){}

    openCaregiverModal(caregiver: Caregiver){
        this.$rootScope.openCaregiverModal(caregiver.id, caregiver);
    }
}

export const caregiverDetails = {
    templateUrl:
      "admin/modules/visit-broadcast/components/caregiver-details/caregiver-details.component.html",
    controller: caregiverDetailsCtrl,
    controllerAs: "ctrl",
    bindings: {
      caregiver: "<",
      showExtra: "<",
      onCaregiverHover: "<",
      startFlyToCaregiver: "<",
      stopFlyToCaregiver: "<"
    },
  };