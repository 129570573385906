import { BoxProps, Flex, Link, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../core/api";
import { TableCellBadge } from "../../modules/communication/components/TableCellBadge";
import { useEntityLink } from "../hooks/useEntityLink";
import { CaregiverId, PatientId } from "../schema/schema";
import { ImageCircleShimmer } from "./Shimmer";

export type CaregiverEntity = {
  type: "Caregiver";
  id: CaregiverId;
  displayId: number | null;
  photoUrl: string | null;
  fullName: string;
  status: Messages["CaregiverStatus"];
};

export type PatientEntity = {
  type: "Patient";
  id: PatientId;
  displayId: number | null;
  gender: string | null;
  fullName: string;
  status: Messages["PatientStatus"];
};

function getColorByEntityStatus(status: Messages["CaregiverStatus"] | Messages["PatientStatus"]) {
  return match(status)
    .with("ACTIVE", () => "green")
    .with("JOIN_REQUEST", () => "purple")
    .with("ON_HOLD", () => "yellow")
    .with("ON_LEAVE", () => "yellow")
    .with("PENDING", () => "gray")
    .with("QUIT", () => "gray")
    .with("REJECTED", () => "gray")
    .with("SUSPENDED", () => "gray")
    .with("TERMINATED", () => "gray")
    .with("ACCEPTED", () => "gray")
    .with("DECEASED", () => "gray")
    .with("DISCHARGED", () => "gray")
    .with("DRAFT", () => "gray")
    .with("ELIGIBLE", () => "gray")
    .with("HOSPITALIZED", () => "gray")
    .with("PENDING_FILES", () => "gray")
    .with("REFERRAL", () => "gray")
    .with("VACATION", () => "gray")
    .exhaustive();
}

export type Entity = CaregiverEntity | PatientEntity;

export type EntityWithStatus<$Entity extends Entity> = $Entity & {
  status: {
    Caregiver: Messages["CaregiverStatus"];
    Patient: Messages["PatientStatus"];
  }[$Entity["type"]];
};

interface Props {
  entity: Entity;
  boxProps?: BoxProps;
}

const EntityCard = (props: Props) => {
  const { entity, boxProps } = props;

  return (
    <Flex gap={3} {...boxProps}>
      <ImageCircleShimmer size={35} src={getEntityPhotoUrl(entity)} />
      <Flex gap={1} direction="column">
        <Flex>
          <Text>
            {entity.fullName} ({`${entity.displayId ?? entity.id}`})
          </Text>
        </Flex>
        <Flex>
          <TableCellBadge
            color={getColorByEntityStatus(props.entity.status)}
            text={props.entity.status}
          />

          <Tag size="sm" color="gray.700" bg="transparent">
            {entity.type}
          </Tag>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EntityCardLink = (props: Props) => {
  const { open } = useEntityLink();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    open({ event, entity: props.entity });
  };

  return (
    <EntityCard
      {...props}
      boxProps={{
        as: Link,
        onClick: handleClick,
        p: 3,
        borderRadius: "lg",
        _hover: { bg: "gray.100", color: "black", textDecoration: "none" },
      }}
    />
  );
};

export const PatientPhotoUrl = {
  M: "/admin/images/patient-men.png",
  F: "/admin/images/patient-women.png",
};

export function getEntityPhotoUrl(
  entity: Pick<CaregiverEntity, "photoUrl"> | Pick<PatientEntity, "gender">
) {
  const blank = "admin/images/blank-profile.jpg";

  const url = match(entity)
    .with({ photoUrl: P.not(null) }, ({ photoUrl }) => photoUrl)
    .with({ gender: "F" }, () => PatientPhotoUrl.F)
    .with({ gender: "M" }, () => PatientPhotoUrl.M)
    .otherwise(() => blank);

  return url ?? blank;
}

export default EntityCard;
