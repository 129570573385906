//! @ngInject
export function ExclusionListActionsModalCtrl($scope, $rootScope, DatabaseApi, SweetAlert, toaster, noteConsts, entityNoteService) {
    $scope.isLoading = false;

    this.$onInit = async function () {
      $scope.action = $scope.$resolve.action;
      $scope.caregiver = $scope.$resolve.caregiver;

      const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType(noteConsts.NoteTypes.CAREGIVER_EXCLUSION);
      const predefindAnswer = noteSettings.predefinedAnswers.find(a => a.active === true && a.text === $scope.action);
      $scope.note = { 
        notePredefinedAnswerId: predefindAnswer?.id
      };

      $scope.doAction = function () {
        $scope.isLoading = true;

        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote($scope.note, noteConsts.NoteTypes.CAREGIVER_EXCLUSION);
        if (!isNoteValid) {
            $scope.noteValidations = { isPredefinedValid, isMessageValid };
            return;
        }
        
        const note = entityNoteService.buildEntityNoteRequest(
            $scope.note,
            noteConsts.NoteTypes.CAREGIVER_EXCLUSION,
            { caregiverId: Number($scope.caregiver.id) }
        );

        const predefinedAnswer = noteSettings.predefinedAnswers.find(a => a.id === note.notePredefinedAnswerId)?.text;

        if ($scope.action === "Terminate") {
          SweetAlert.swal(
            {
              title: `Terminate ${$scope.caregiver.fullName}?`,
              text:
                "This action will have system wide affect on the caregiver.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes, Terminate",
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            function (isConfirm) {
              if (!isConfirm) {
                $scope.$close();
                return;
              }

              DatabaseApi.put(
                `agencies/${$rootScope.agencyId}/exclusion_list/${$scope.caregiver.exclusionId}/caregivers/${$scope.caregiver.id}/terminate`,
                {
                  note: note,
                }
              )
                .then(() => {
                  toaster.pop(
                    "success",
                    `Successfully terminated ${$scope.caregiver.fullName}`
                  );
                  note.predefinedAnswer = predefinedAnswer;
                  $scope.$close({
                    action: "TERMINATED",
                    result: {
                      note: note,
                    },
                  });
                })
                .catch((err) => {
                  toaster.pop(
                    "error",
                    `Couldn't terminate ${$scope.caregiver.fullName}`,
                    "Please try again later"
                  );
                  console.log(err);
                })
                .finally(() =>
                  $scope.$close({
                    action: "TERMINATED",
                    result: "FAILED",
                  })
                );
            }
          );
        } else {
          const flag = !$scope.caregiver.flagged;
          DatabaseApi.put(
            `agencies/${$rootScope.agencyId}/exclusion_list/${$scope.caregiver.exclusionId}/flag`,
            {
              flag,
              note: note,
            }
          )
            .then(() => {
              toaster.pop(
                "success",
                `Successfully changed status for ${$scope.caregiver.fullName}`
              );
              note.predefinedAnswer = predefinedAnswer;
              $scope.$close({
                action: "FLAGGED",
                result: {
                  flag,
                  note: note,
                },
              });
            })
            .catch((err) => {
              toaster.pop(
                "error",
                `Couldn't ${$scope.action.toLowerCase()} ${$scope.caregiver.fullName}`
              );
              console.log(err);
            })
            .finally(() =>
              $scope.$close({
                action: "FLAGGED",
                result: "FAILED",
              })
            );
        }
      };

      $scope.handleNoteChanges = (updatedNote) => {
        $scope.note = updatedNote;
      };

      $scope.exit = function () {
        $scope.$close();
      };
    };
  };
