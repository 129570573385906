import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import useAuth from "../../../shared/hooks/useAuth";
import { queryKeys } from "../../../shared/query-keys";

export function useSearchCaregiversQuery(params: { term: string }) {
  const api = useApi();
  const { authInfo } = useAuth();

  return useQuery({
    queryKey: queryKeys.caregiver.search(params.term),
    queryFn: () =>
      api.get({
        url: "/agencies/:agencyId/search",
        params: {
          path: {
            agencyId: authInfo.agency.id,
          },
          query: {
            caregiver_id: true,
            caregiver_first_name: true,
            caregiver_last_name: true,
            text: params.term,
          },
        },
      }),
    enabled: params.term !== "",
    select: (data) => data.caregivers.slice(0, 10),
    keepPreviousData: params.term !== "",
  });
}
