import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { GETQueryParamsPath } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import useAuth from "../../../../shared/hooks/useAuth";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import { CommCenterTeamMemberId } from "../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import {
    getCommCenterTeamMemberIdByAgencyMemberId,
    NewTicketRequestBody
} from "../../utils/communication-utils";
import OpenCommunicationsPage from "./OpenCommunicationsPage";

const TICKETS_URL = "/agencies/:agencyId/agency_members/:agencyMemberId/comm_center/tickets";

const OpenCommunicationsRoute = () => {
  const api = useApi();
  const { authInfo } = useAuth();
  const toast = useToast();
  const { stateService } = useRouter();

  const queryParams = useQueryParams<GETQueryParamsPath<typeof TICKETS_URL>>({
    storageKey: ["comm_center", "tickets-table"],
    defaultOptions: {
      ticketStatus: ["IN PROGRESS", "NEW"],
    },
  });

  const tickets = useQuery({
    queryKey: queryKeys.commCenter.search(queryParams.params),
    keepPreviousData: true,
    queryFn: () => {
      return api.get({
        url: TICKETS_URL,
        params: {
          path: {
            agencyId: authInfo.agency.id,
            agencyMemberId: authInfo.agencyMember.id,
          },
          query: queryParams.params.toJSON(),
        },
      });
    },
  });

  const stats = useQuery({
    queryKey: queryKeys.commCenter.stats(),
    queryFn: () => {
      return api.get({
        url: "/agencies/:agencyId/agency_members/:agencyMemberId/comm_center/tickets/stats",
        params: {
          path: {
            agencyId: authInfo.agency.id,
            agencyMemberId: authInfo.agencyMember.id,
          },
        },
      });
    },
  });

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => {
      return api.get({
        url: "/agencies/:agencyId/agency_members/:agencyMemberId/comm_center/teams",
        params: {
          path: {
            agencyId: authInfo.agency.id,
            agencyMemberId: authInfo.agencyMember.id,
          },
        },
      });
    },
  });

  const stages = useQuery({
    queryKey: ["onboarding_stages"],
    queryFn: () => {
      return api.get({
        url: "/agencies/:agencyId/agency_members/:agencyMemberId/onboarding_stages",
        params: {
          path: {
            agencyId: authInfo.agency.id,
            agencyMemberId: authInfo.agencyMember.id,
          },
        },
      });
    },
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => {
      return api.get({
        url: "/agencies/:agencyId/agency_members/:agencyMemberId/comm_center/labels",
        params: {
          path: {
            agencyId: authInfo.agency.id,
            agencyMemberId: authInfo.agencyMember.id,
          },
        },
      });
    },
    keepPreviousData: true,
    select: (response) => response.labels,
  });

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: ({ ticketId }) => {
      if (tickets.data?.tickets.some((ticket) => ticket.id === ticketId)) {
        tickets.refetch();
      }
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post({
        url: "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets", // TODO: Einat use the apiPaths function here. Fix the endpoint.
        params: {
          path: {
            agencyId: authInfo.agency.id,
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(
                teams.data?.teams ?? [],
                authInfo.agencyMember.id
              ) ?? CommCenterTeamMemberId.wrap(0),
          },
        },
        body: newTicketRequest,
      });
    },
    onSuccess: (response) => {
      stateService.go("app.commcenter_ticket", { ticketId: response.ticketId });
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleCreateTicket = (newTicketDetails: NewTicketRequestBody) => {
    createTicket.mutate(newTicketDetails);
  };

  if (
    tickets.isLoading ||
    stats.isLoading ||
    teams.isLoading ||
    stages.isLoading ||
    labels.isLoading
  ) {
    return <LoadingPage />;
  }

  if (tickets.isError || stats.isError || teams.isError || stages.isError || labels.isError) {
    return <div>Error</div>;
  }

  if (!tickets.data || !stats.data || !teams.data || !stages.data || !labels.data) {
    return <div>Something went wrong</div>;
  }

  return (
    <OpenCommunicationsPage
      tickets={tickets.data.tickets}
      teams={teams.data.teams}
      stages={stages.data.stages}
      labels={labels.data.filter((label) => label.active)}
      initialLabelId={
        labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
      }
      stats={stats.data}
      filters={queryParams}
      isRefetching={tickets.isFetching}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onCreateNewTicket={handleCreateTicket}
    />
  );
};

export default OpenCommunicationsRoute;
