//! @ngInject
export function billingAttachCheckVisitsModalCtrl(
  $rootScope,
  $scope,
  $filter,
  $uibModalInstance,
  DatabaseApi,
  check,
  checkBalance,
  NgTableParams,
  itemSearchPageManager,
  selectionLogic,
  generalUtils,
  toaster,
  Currency,
) {
  $scope.tableParams = null;
  $scope.globalFilter = { val: "" };
  $scope.offices = [];
  $scope.filterBy = {
    visitDateFrom: "",
    visitDateTo: "",
    invoiceDateFrom: "",
    invoiceDateTo: "",
    exportBatch: "",
    invoiceNumber: "",
    includeVisitsWithClaimNr: false,
    includeVisitsFromOtherContracts: false,
  };

  const initialize = () => {
    setTimeout(() => {
      if (!check) {
        $scope.closeModal({
          isError: true,
          message: "Check not found",
        });
      }
    });
    initFilters();
    initPageManager();
    initSelection();
    $scope.pageManager.resetSearchParamsByUrl();
    $scope.loadItems();
    $scope.checkBalance = checkBalance;
  };

  const initFilters = () => {
    if ($scope.$resolve.defaultFilters) {
      if ($scope.$resolve.defaultFilters.visitFrom) {
        $scope.filterBy.visitDateFrom = $scope.$resolve.defaultFilters.visitFrom;
      }
      if ($scope.$resolve.defaultFilters.visitTo) {
        $scope.filterBy.visitDateTo = $scope.$resolve.defaultFilters.visitTo;
      }
      if ($scope.$resolve.defaultFilters.patientName) {
        $scope.globalFilter.val = $scope.$resolve.defaultFilters.patientName;
      }
    }
  };

  const convertDateTimeFromString = (dateTimeValue) => new Date(dateTimeValue);

  const initPageManager = () => {
    const contractTypeId = check.contractTypeId;
    $scope.pageManager = itemSearchPageManager.createSearchPageManager(
      `/check/invoice_visits/${contractTypeId}`
    );

    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

    $scope.pageManager.initSearchParamDeprecated(
      "visit-from",
      $scope.filterBy.visitDateFrom,
      "visit-from",
      generalUtils.formatDateByFilter,
      convertDateTimeFromString
    );
    $scope.pageManager.initSearchParamDeprecated(
      "visit-to",
      $scope.filterBy.visitDateTo,
      "visit-to",
      generalUtils.formatDateByFilter,
      convertDateTimeFromString
    );
    $scope.pageManager.initSearchParamDeprecated(
      "invoice-from",
      $scope.filterBy.invoiceDateFrom,
      "invoice-from",
      generalUtils.formatDateByFilter,
      convertDateTimeFromString
    );
    $scope.pageManager.initSearchParamDeprecated(
      "invoice-to",
      $scope.filterBy.invoiceDateTo,
      "invoice-to",
      generalUtils.formatDateByFilter,
      convertDateTimeFromString
    );
    $scope.pageManager.initOfficeParam();
    $scope.pageManager.initSearchParam(
      "export-batch-display-id",
      $scope.filterBy.exportBatch
    );
    $scope.pageManager.initSearchParam(
      "invoice-number",
      $scope.filterBy.invoiceNumber
    );
    $scope.pageManager.initSearchParam(
      "include-visits-with-claim-nr",
      $scope.filterBy.includeVisitsWithClaimNr
    );
    $scope.pageManager.initSearchParam(
      "include-visits-from-other-contracts",
      $scope.filterBy.includeVisitsFromOtherContracts
    );
  };

  const initSelection = function () {
    $scope.selectionLogic = selectionLogic.createNewVirtualizedLogic();
  };

  // Brute force remainingBalance is performant enough and much less error prone than incrementally updating it on all
  // events.
  $scope.remainingBalance = () =>
    $scope.checkBalance -
    $scope.invoiceVisits
      .filter((visit) => visit.isSelected)
      .reduce((totalPayment, { payment }) => totalPayment + payment, 0);

  const refreshPaymentInDollars = (invoiceVisit) => {
    invoiceVisit.paymentInDollars = invoiceVisit.payment / 100;
  }

  $scope.handlePaymentInDollarsBlur = (invoiceVisit) => {
    // Refresh paymentInDollar to set invalid inputs to 0. (Change event sets payment to 0 for invalid inputs).
    refreshPaymentInDollars(invoiceVisit);
  }

  $scope.handlePaymentInDollarsChange = (invoiceVisit) => {
    // Yields 0 for invalid inputs.
    invoiceVisit.payment = new Currency().fromMajor(invoiceVisit.paymentInDollars, 0).toMinor();
  }

  const setInvoiceVisitPayment = (invoiceVisit, payment) => {
    invoiceVisit.payment = payment;
    refreshPaymentInDollars(invoiceVisit);
  }

  // Assign a payment (as much as available) to all selected visits with payment 0.
  $scope.payUnpaidVisits = () => {
    const selectedUnpaidVisits =
      $scope.invoiceVisits.filter((visit) => visit.isSelected && visit.billingAmount > 0 && visit.payment === 0);

    const balanceBeforePayment = $scope.remainingBalance();

    // We don't want to call remainingBalance() for each item (would be O(n^2)), so we incrementally update it here.
    let remainingBalance = balanceBeforePayment;
    let paidVisitCount = 0;

    if (balanceBeforePayment > 0) {
      for (const visit of selectedUnpaidVisits) {
        const payment = Math.max(0, Math.min(visit.billingAmount, remainingBalance));
        setInvoiceVisitPayment(visit, payment);

        remainingBalance -= payment;
        paidVisitCount++;

        if (remainingBalance === 0) {
          break;
        }
      }
    }

    const totalPaymentStr = ((balanceBeforePayment - remainingBalance)/100).toFixed(2);
    toaster.pop('success', `Assigned a total payment of $${totalPaymentStr} to ` +
      `${paidVisitCount} visit${paidVisitCount !== 1 ? 's' : ''}.`);
  }

  const mapItems = (items) => {
    if (!$scope.caregiversMap) {
      $scope.caregiversMap = DatabaseApi.caregivers() || {};
    }

    $scope.selectionLogic.updateItemsToRender(items);
    items.forEach(function (item) {
      if ($scope.selectionLogic.totalData.selectedIds.hasOwnProperty(item.id)) {
        item.isSelected = true;
      }
      item.officeName = (() => {
        const offices = $scope.officeDataManager.getOffices();
        const officeFound = offices.find(
          (office) => office.id === item.officeId
        );
        if (officeFound === undefined) {
          return "";
        }
        return officeFound.name;
      })();
      item.claimNumber = "";
      setInvoiceVisitPayment(item, 0);
      item.caregiver = $scope.caregiversMap[item.caregiverId];
    });
  };

  const initTable = (items) => {
    const oldTotal = $scope.tableParams?.total?.() || 0;
    const hasItems = items && items.length;
    if (hasItems) {
      mapItems(items);
    }

    let count = 25;
    let sorting = {};
    let page = false;
    if ($scope.tableParams) {
      count = $scope.tableParams.count();
      sorting = $scope.tableParams.sorting();
      page = $scope.tableParams.page();
    }
    const options = {
      count: count,
      sorting: sorting
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
    if ($scope.globalFilter.val) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
    if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
  };

  const updateSearchParams = () => {
    $scope.pageManager.updateSearchParamValue(
      "visit-from",
      $scope.filterBy.visitDateFrom
    );
    $scope.pageManager.updateSearchParamValue(
      "visit-to",
      $scope.filterBy.visitDateTo
    );
    $scope.pageManager.updateSearchParamValue(
      "invoice-from",
      $scope.filterBy.invoiceDateFrom
    );
    $scope.pageManager.updateSearchParamValue(
      "invoice-to",
      $scope.filterBy.invoiceDateTo
    );
    $scope.pageManager.updateSearchParamValue(
      "export-batch-display-id",
      $scope.filterBy.exportBatch
    );
    $scope.pageManager.updateSearchParamValue(
      "invoice-number",
      $scope.filterBy.invoiceNumber
    );
    $scope.pageManager.updateSearchParamValue(
      "include-visits-with-claim-nr",
      $scope.filterBy.includeVisitsWithClaimNr
    );
    $scope.pageManager.updateSearchParamValue(
      "include-visits-from-other-contracts",
      $scope.filterBy.includeVisitsFromOtherContracts
    );
  };

  $scope.clearAllSelection = () => {
    $scope.tableParams.data.forEach((item) => (item.isSelected = false));
    $scope.selectionLogic.resetAllSelection();
  };

  $scope.searchCheckInvoiceVisits = () => {
    updateSearchParams();
    $scope.pageManager.executeSearch().then(
      (res) => {
        $scope.invoiceVisits = res.data.invoiceVisits;
        initTable($scope.invoiceVisits);
      },
      (err) => {
        toaster.pop("error", "Failed to load invoiced visits");
      }
    );
  };

  $scope.assignedMembersExtraSettings = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: "400px",
    enableSearch: true,
  };

  $scope.arSettingsExtraSettings = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: "400px",
    enableSearch: true,
    displayProp: "text",
  };

  $scope.loadItems = (search) => {
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.assignedMembersOptions = DatabaseApi.getAgencyMembersArr().filter(
      (member) => member.status === "Active"
    );

    $scope.searchCheckInvoiceVisits();
  };

  $scope.applyGlobalSearch = (term) => {
    let filter = { $: term };
    if ($scope.tableParams) {
      angular.extend($scope.tableParams.filter(), filter);
      const invoiceVisits = $filter('filter')($scope.invoiceVisits, $scope.globalFilter.val);
      $scope.selectionLogic.updateItemsToRender(invoiceVisits);

    }
  };

  $scope.clickTableRow = (row) => {
    $rootScope.openVisitInstanceModal(row.visitInstanceId);
  };

  $scope.attachVisitsToCheck = function () {
    $scope.isProcessing = true;

    const checkLines = $scope.invoiceVisits
      .filter(visit => $scope.selectionLogic.getAllSelectedItemIds().includes(visit.id))
      .map((invoiceVisit) => ({
        checkId: check.id,
        invoiceVisitId: invoiceVisit.id,
        payment: invoiceVisit.payment,
        date: invoiceVisit.visitDate,
        invoiceId: invoiceVisit.invoiceId,
        external_id: invoiceVisit.externalId,
        claimNumber: invoiceVisit.claimNumber,
      }));

    const url = "agencies/" + $rootScope.agencyId + "/check_lines_batch";

    const body = {
      checkLines: checkLines,
    };

    DatabaseApi.post(url, body)
      .then(function (response) {
        $scope.isProcessing = false;
        $scope.closeModal(response);
      })
      .catch(function (errorResponse) {
        $scope.isProcessing = false;
        $scope.closeModal({
          isError: true,
          message: "Failed to attach check visits",
          response: errorResponse,
        });
      });
  };

  $scope.closeModal = function (result) {
    if (!$scope.isProcessing) {
      $uibModalInstance.close(result);
    }
  };

  initialize();
};
