export const entityName = {
    templateUrl: "admin/views/entity-name.html",
    bindings: {
        entity: "<",
        asText: "<",
        allowExternal: "<",
        type: "@", // patient | caregiver
    },
    //! @ngInject
    controller: function ($rootScope) {
        const getFullName = ({ firstName, middleName, lastName, fullName }) => {
            if (fullName) {
                return fullName;
            }

            return [firstName, middleName, lastName].filter(namePart => namePart !== null).join(' ');
        }
        
        const getEntityName = () => {
            if (this.entity.displayId) {
                return `${getFullName(this.entity)} (${this.entity.displayId})`
            }

            return `${getFullName(this.entity)}`
        }

        this.$onInit = () => {
            this.entityName = getEntityName(this.entity);
        }

        this.$onChanges = () => {
            this.entityName = getEntityName(this.entity);
        }

        this.onClick = ($event) => {
            $event.stopPropagation();

            switch (this.type) {
                case "patient":
                    return $rootScope.openPatientModal(this.entity.id);
                case "caregiver":
                    return $rootScope.openCaregiverModal(this.entity.id);
                default:
                    throw new Error(`Unhandled entity name "${this.type.toLowercase}"`)
            }
        }

        this.onClickExternal = ($event) => {
            $event.stopPropagation();

            switch (this.type) {
                case "patient":
                    return $rootScope.openPatientNewTab(this.entity.id);
                case "caregiver":
                    return $rootScope.openCaregiverNewTab(this.entity.id);
                default:
                    throw new Error(`Unhandled entity name "${this.type.toLowercase}"`)
            }
        }
    },
};
