//! @ngInject
export function agencyTeamModalCtrl(
  $scope,
  $rootScope,
  DatabaseApi,
  toaster,
  wildcard
) {
  if ($scope.team === undefined) {
    $scope.team = {
      name: "",
      // teamLeader: "", // undefined by default
      active: true,
      teamMembers: [],
    };
  }

  $scope.agencyMembers = DatabaseApi.getAgencyMembers();
  $scope.teamMemberSelection = "";

  $scope.teamMemberSelected = () => {
    if ($scope.teamMemberSelection !== "") {
      $scope.team.teamMembers.push($scope.teamMemberSelection);
      $scope.teamMemberSelection = "";
    }
  };

  $scope.removeTeamMember = (memberToRemove) => {
    const filteredMembers = $scope.team.teamMembers.filter(
      (memberId) => memberId !== memberToRemove
    );
    $scope.team.teamMembers = filteredMembers;
  };

  if ($scope.team.id) {
    // convert teamMembers to string - to avoid duplications
    $scope.team.teamMembers = $scope.team.teamMembers.map(memberId => memberId.toString());
  }

  $scope.submit = function () {
    if ($scope.team.name === "") {
      toaster.pop("warning", "Team name is required");
    }

    $scope.team.teamMembers = $scope.team.teamMembers.map((memberId) =>
      typeof memberId === "string" ? Number.parseInt(memberId, 10) : memberId
    );

    if (typeof $scope.team.teamLeader === "string") {
      $scope.team.teamLeader = Number.parseInt($scope.team.teamLeader, 10);
    }

    let method = DatabaseApi.post;
    let url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/teams",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );

    if ($scope.team.id !== undefined) {
      method = DatabaseApi.put;
      url = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/teams/:agencyTeamId",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        $scope.team.id
      );
    }

    method(url, $scope.team).then(
      ({ data }) => {
        if (data.agencyTeam === null) {
          toaster.pop(
            "error",
            "A team with the same name already exists."
          );
        } else {
          toaster.pop(
            "success",
            "Team was " + ($scope.team.id ? "edited" : "created") + " successfully"
          );
          $scope.$close();
        }
      },
      function (error) {
        toaster.pop("error", "Failed to create or edit team");
      }
    );
  };
};
