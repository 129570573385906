import { LocalDate, DateTimeFormatter } from "js-joda";

//! @ngInject
export function agencyPhysicianModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, patient, codes, DatabaseApi, toaster){

    window.scrollTo(0,0);

    $scope.physician = {
        contractType: null,
        serviceCodeId: null,
        isPrimary:  null,
        altPatientId:  null,
        startDate: null,
        startOfCareDate: null,
        notes: null,
        placementId: null
    };


    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };


    $scope.cal = {startDate: false};

    $scope.setStartDate = function(date){

        if (date) {

            var localDateObj = LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
            $scope.contract.startDate = localDateObj.format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));

        } else {
            $scope.contract.startDate = null;
        }
        console.log($scope.contract);
    };

    $scope.setContractId = function(){
        $scope.contract.contractType = parseInt($scope.contract.contractObj);
    };
    $scope.setServiceCodeId = function(){
        $scope.contract.serviceCodeId = parseInt($scope.contract.serviceCodeObj);
    };

    $scope.submit = function(){
        $scope.sending = true;
        console.log($scope.contract);

        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + patient.id + '/contracts', $scope.contract).then(function (res) {
            $scope.closeModal();
        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add contract");
        });

    };




};
