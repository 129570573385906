import { QueryClient, QueryKey } from "@tanstack/react-query";
import produce from "immer";

export function optimisticUpdate<$Type>(params: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  update: (draft: $Type) => void;
}) {
  const { queryClient, queryKey, update } = params;

  const previousValue = queryClient.getQueriesData<$Type>(queryKey).at(-1)?.at(1);

  queryClient.cancelQueries(queryKey);

  queryClient.setQueriesData<$Type>(queryKey, (old) => {
    if (old === undefined) {
      return old;
    }

    return produce(old, update);
  });

  return { previousValue };
}
