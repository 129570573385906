const languages = [
  { text: "Albanian", value: "Albanian" },
  { text: "Arabic", value: "Arabic" },
  { text: "Armenian", value: "Armenian" },
  { text: "Bengali", value: "Bengali" },
  { text: "Bulgarian", value: "Bulgarian" },
  { text: "Cantonese", value: "Cantonese" },
  { text: "Chinese", value: "Chinese" },
  { text: "Creole", value: "Creole" },
  { text: "Danish", value: "Danish" },
  { text: "Dutch", value: "Dutch" },
  { text: "English", value: "English" },
  { text: "Estonian", value: "Estonian" },
  { text: "Farsi", value: "Farsi" },
  { text: "Filipino", value: "Filipino" },
  { text: "French", value: "French" },
  { text: "Fukkianese", value: "Fukkianese" },
  { text: "Fula-Fulani", value: "Fula-Fulani" },
  { text: "Fuzhounese", value: "Fuzhounese" },
  { text: "Georgian", value: "Georgian" },
  { text: "German", value: "German" },
  { text: "Ghana", value: "Ghana" },
  { text: "Greek", value: "Greek" },
  { text: "Hakka", value: "Hakka" },
  { text: "Hebrew", value: "Hebrew" },
  { text: "Hindi", value: "Hindi" },
  { text: "Hungarian", value: "Hungarian" },
  { text: "Italian", value: "Italian" },
  { text: "Japanese", value: "Japanese" },
  { text: "Korean", value: "Korean" },
  { text: "Krio", value: "Krio" },
  { text: "Kyrgyz", value: "Kyrgyz" },
  { text: "Mandarin", value: "Mandarin" },
  { text: "Pashto", value: "Pashto" },
  { text: "Persian", value: "Persian" },
  { text: "Polish", value: "Polish" },
  { text: "Portuguese", value: "Portuguese" },
  { text: "Punjabi", value: "Punjabi" },
  { text: "Romanian", value: "Romanian" },
  { text: "Russian", value: "Russian" },
  { text: "Shanghaines", value: "Shanghainese" },
  { text: "Sign", value: "Sign" },
  { text: "Soninke", value: "Soninke" },
  { text: "Spanish", value: "Spanish" },
  { text: "Swahili", value: "Swahili" },
  { text: "Tagalog", value: "Tagalog" },
  { text: "Taishanese", value: "Taishanese" },
  { text: "Taiwanese", value: "Taiwanese" },
  { text: "Turkish", value: "Turkish" },
  { text: "Twi", value: "Twi" },
  { text: "Ukrainian", value: "Ukrainian" },
  { text: "Urdu", value: "Urdu" },
  { text: "Uzbe", value: "Uzbe" },
  { text: "Vietnmese", value: "Vietnmese" },
  { text: "Yiddish", value: "Yiddish" },
];

export default languages;
