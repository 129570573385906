interface Bindings {
  resolve: { imageUrl: string };
  modalInstance: ng.ui.bootstrap.IModalInstanceService;
}

interface ComponentOptions extends angular.IComponentOptions {
  $name: "imageCanvasViewer";
  bindings: Record<keyof Bindings, string>;
}

//! @ngInject
class Controller implements ng.IComponentController, Bindings {
  // #region Bindings
  resolve!: Bindings["resolve"];
  modalInstance!: Bindings["modalInstance"];
  // #endregion

  title = "View photo";

  $onInit(): void {
    console.log(this);
  }

  close(): void {
    this.modalInstance.close();
  }
}

export const imageCanvasViewerComponent: ComponentOptions = {
  $name: "imageCanvasViewer",
  templateUrl:
    "admin/modules/shared/components/image-canvas-viewer/image-canvas-viewer.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
};
