//! @ngInject
export function feedCtrl($scope, $rootScope, $state, DatabaseApi, $q, $stateParams, toaster, $filter, NgTableParams, SweetAlert){
  
  
  window.scrollTo(0,0);
  
  /*
  DatabaseApi.get('users/by-agency').then(function(res){
      $scope.members = res.data;
      initTable($scope.members);
    }, function(err){
      toaster.pop('error', "Something went wrong", 'Please try again');
    });
  */

};