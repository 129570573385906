import angular from "angular";
import { customPlanOfCareTypeItemComponent } from "./components/custom-plan-of-care-type-item/custom-plan-of-care-type-item.component";
import { customPlanOfCareTypeComponent } from "./components/custom-plan-of-care-type/custom-plan-of-care-type.component";

export function registerPlanOfCareModule() {
  return (
    angular
      .module("dashboard")
      // Components
      .component(customPlanOfCareTypeComponent.$name, customPlanOfCareTypeComponent)
      .component(customPlanOfCareTypeItemComponent.$name, customPlanOfCareTypeItemComponent)
  );
}
