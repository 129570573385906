//! @ngInject
export function countyMapController($scope, DatabaseApi, $rootScope, $http, Consts, toaster, SweetAlert) {
    $scope.colors = ['#e57373',
        '#7986CB',
        '#64B5F6',
        '#4DB6AC',
        '#81C784',
        '#AED581',
        '#FFD54F',
        '#FFB74D',
        '#FF8A65'];
    
    $scope.allCounties = [
        'Columbia',
        'Rensselaer',
        'Chautauqua',
        'Cattaraugus',
        'Allegany',
        'Steuben',
        'Chemung',
        'Tioga',
        'Broome',
        'Delaware',
        'Sullivan',
        'Ulster',
        'Dutchess',
        'Putnam',
        'Westchester',
        'Rockland',
        'Suffolk',
        'Nassau',
        'Queens',
        'Kings',
        'Richmond',
        'New York',
        'Bronx',
        'Orange',
        'Erie',
        'Wyoming',
        'Livingston',
        'Ontario',
        'Yates',
        'Seneca',
        'Schuyler',
        'Tompkins',
        'Cortland',
        'Chenango',
        'Otsego',
        'Schoharie',
        'Albany',
        'Greene',
        'Schenectady',
        'Saratoga',
        'Washington',
        'Warren',
        'Niagara',
        'Genesee',
        'Orleans',
        'Monroe',
        'Wayne',
        'Cayuga',
        'Onondaga',
        'Madison',
        'Montgomery',
        'Fulton',
        'Oneida',
        'Oswego',
        'Lewis',
        'Jefferson',
        'Herkimer',
        'Hamilton',
        'Essex',
        'Saint Lawrence',
        'Franklin',
        'Clinton'
    ];

    $scope.toggleRemoving = function () {
        $scope.isRemoving = !$scope.isRemoving;
    };

    $scope.counties = $scope.singleGroup ? [] : DatabaseApi.counties();
    $rootScope.$on('got_data', function (event) {
        $scope.counties = $scope.singleGroup ? $scope.counties : DatabaseApi.counties();
    });

    $scope.finishCountyGroupCreation = function (countyGroup) {
        $scope.counties = $scope.counties.filter(function (curr) {
            return (curr.name !== "");
        });
        
        if (countyGroup.name === "") {
            $scope.selectedCountyGroup = undefined;
        } else {
            countyGroup.isEditing = undefined;
        }
    };

    $scope.setEdit = function (countyGroupName) {
        $scope.unsetAllEdits();

        $scope.getCountyGroupByName(countyGroupName).isEditing = true;
    };

    $scope.unsetAllEdits = function () {
        $scope.counties.forEach(function (curr) {
            curr.isEditing = undefined;
        });
    };

    $scope.selectGroup = function (name) {
        if (name !== "") {
            if ($scope.isRemoving) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this county",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: true
                  },
                  function(isConfirm){
                      if (isConfirm) {
                        var i;
                        for (i = 0; i < $scope.counties.length && $scope.counties[i].name !== name; i++);

                        $scope.counties.splice(i, 1);
                        
                        if ($scope.selectedCountyGroup === name) {
                            $scope.selectedCountyGroup = undefined;
                        }
                      }
                      $scope.isRemoving = false;
                  });
            } else {
                $scope.selectedCountyGroup = name;
            }
        }
    };

    $scope.addCounty = function (event) {
        var countyName = event.target.innerText + " County";

        if ($scope.selectedCountyGroup !== undefined) {
            var foundIndex;
            var found;
            var i;

            for (i = 0; i < $scope.counties.length && found === undefined; i++) {
                found = $scope.counties[i].counties.find(function (curr, index) {
                    foundIndex = index;
                    return (curr.countyName === countyName && curr.state === "NY");
                });
            }

            if (found === undefined) {
                var countyGroup = $scope.getCountyGroupByName($scope.selectedCountyGroup);
                countyGroup.counties.push({ countyName: countyName, state: "NY" });
                if ($scope.singleGroup) {
                    $scope.singleGroup.push(countyName);
                }
            } else {
                var deSelect = $scope.counties[i - 1].counties[foundIndex] === found && $scope.counties[i - 1].name === $scope.selectedCountyGroup;
                
                // Delete county from previous county group
                $scope.counties[i - 1].counties.splice(foundIndex, 1);
                if ($scope.singleGroup) {
                    $scope.singleGroup.splice($scope.singleGroup.indexOf(countyName), 1);
                }

                if (!deSelect) {
                    var countyGroup = $scope.getCountyGroupByName($scope.selectedCountyGroup);
                    countyGroup.counties.push({ countyName: countyName, state: "NY" });
                    if ($scope.singleGroup) {
                        $scope.singleGroup.push(countyName);
                    }
                }
            }
        }
    };

    $scope.getCountyGroupByName = function (name) {
        return ($scope.counties.find(function (curr) {
            return (curr.name === name)
        }));
    }

    $scope.getCountyGroupIndexByName = function (name) {
        var i;

        for (i = 0; i < $scope.counties.length && $scope.counties[i].name !== name; i++);

        return i;
    };

    $scope.addCountyGroup = function () {
        $scope.unsetAllEdits();
        $scope.counties.push({ name: "", counties: [], isEditing: true });
    };

    $scope.getCountyColor = function (county) {
        var found = undefined;
        var i = 0;

        for (i = 0; i < $scope.counties.length && found === undefined; i++) {
            found = $scope.counties[i].counties.find(function (curr) {
                return (curr.countyName === county && curr.state === "NY");
            });
        }

        if (found) {
            return ($scope.colors[(i - 1) % $scope.colors.length]);
        } else {
            return ("gray");
        }
    };

    $scope.sendCounties = function () {
        $http.put(Consts.api + "agencies/" + $rootScope.agencyId + "/agency_member/" + $rootScope.agencyMemberId + "/location_county_groups", {countyGroups: $scope.counties}).then(function (res) {
            DatabaseApi.getLocationGroupsAndCounties();
            toaster.pop('success', "County groups updated sucessfully");
        }, function (err) {
            toaster.pop('error', "Couldn't send county groups");
        });
    };

    // $scope.selectAll = function () {}
    // $scope.deSelectAll = function () {}
    
    if ($scope.singleGroup) {
        $scope.counties = [{name: "Single group", counties: []}];
        $scope.selectGroup("Single group");
        $scope.countyMapOptions.selectAll = function () {
            var allCounties = angular.copy($scope.allCounties);
            $scope.singleGroup = [];
            $scope.counties[0].counties = allCounties.map(function (county) {
                $scope.singleGroup.push(county + ' County');
                return { countyName: county + ' County', state: "NY" };
            });
        }
        $scope.countyMapOptions.deSelectAll = function () {
            $scope.counties[0].counties = [];
            $scope.singleGroup = [];
        }
        $scope.countyMapOptions.deSelectCounty = function (county) {
            $scope.counties[0].counties = $scope.counties[0].counties.filter(function (c) {
                return c.countyName !== county;
            });
            $scope.singleGroup.splice($scope.singleGroup.indexOf(county), 1);
        }
        $scope.countyMapOptions.countiesAmount = $scope.allCounties.length;
    }
}

export function countyMap() {
        return {
            scope: {
                singleGroup: '=',
                countyMapOptions: '='
            },
            restrict: 'E',
            templateUrl: 'admin/views/county-map-directive.html',
            controller: 'countyMapController'
        };
};