//! @ngInject
export function patientDocumentBuilderCtrl($scope, $rootScope, DatabaseApi, toaster, $timeout, $stateParams, $state, $uibModal, $window, SweetAlert, generalUtils) {
    /**
     * @param {[]} content 
     * @param {{questionType: {}}} nursingQuestions 
     */
    function getSimilarQuestionsMap(content, nursingQuestions) {
        let map = new Map(); // <number, [number, number, number]>
        const allRelevantItems = getContentItem(content, item => item.nursingQuestionLinked === undefined);
        allRelevantItems.forEach(item => {
            const relevantNursingQuestions = nursingQuestions[item.itemType];
            if (relevantNursingQuestions !== undefined) {
                relevantNursingQuestions.forEach(question => {
                    if (generalUtils.levenshtein(item.label, question.questionText) < 65 * item.label.length / 100) {
                        const existsArray = map.get(item.id);
                        if (existsArray === undefined) {
                            map.set(item.id, [question.id]);
                        } else {
                            existsArray.push(question.id);
                            map.set(item.id, existsArray); 
                        }
                    }
                });
            }
        });
        return map;
    }

    function getContentItem(array, predicate, recursive = true) {
        let arr = [];
        array.forEach((question) => {
            if (predicate(question)) {
                arr.push(question);
            }
    
            if (question.columns !== undefined && question.columns !== null && question.columns.length > 0 && recursive) {
                const children = getContentItem(question.columns[0], predicate, true);
                arr = arr.concat(children);
            }
        });
    
        return arr;
    }

    /**
     * 
     * @param {[]} content 
     * @param {{questionsType: []}} databaseQuestions 
     * @param {Map} similarQuestions 
     */
    function initiateSuggestionsForQuestionLinkArray(content, databaseQuestions, similarQuestions) {
        $scope.suggestionsForQuestionLinks = [];
        [...similarQuestions.entries()].forEach(([key, values]) => {
            const [item] = getContentItem(content, (x) => x.id === key);
            const nursingQuestionsArr = [];
            values.forEach((questionId) => {
                nursingQuestionsArr.push(databaseQuestions[item.itemType].find(x => x.id === questionId));
            });
            $scope.suggestionsForQuestionLinks.push({
                questionName: item.label,
                questionId: item.id,
                suggestions: nursingQuestionsArr
            });
        })
    }

    $scope.backdoorClicked = 0;
    $scope.showConvertButton = false;
    $scope.showCompleteConvertButton = false;
    $scope.showSuggestionsForQuestionLinks = false;
    $scope.convertingToNursingQuestion = false;
    $scope.completeConvertingToNursingQuestion = false;
    $scope.suggestionsForQuestionLinks = [];
    $scope.countBackDoor = () => {
        $scope.backdoorClicked++;
        if ($scope.backdoorClicked === 8) {
            $scope.showConvertButton = true;
        }
    };

    $scope.convertToNursingQuestions = () => {
        if (!$scope.existingDocType || !$scope.existingDocType.id){
            return toaster.pop('error', "Need to publish the document before converting to nursing questions");
        }
        $scope.convertingToNursingQuestion = true;
        const databaseQuestions = $scope.databaseQuestions;
        const content = $scope.existingDocLatestVersion.content;
        if (isContentInvalid(content)) {
            toaster.pop('error', 'Oops! Form is invalid', 'Please review your nested questions and try again');
            return;
        }
        const similarQuestions = getSimilarQuestionsMap(content, databaseQuestions);
        if (similarQuestions.size === 0) {
            $scope.showCompleteConvertButton = true;
        } else {
            initiateSuggestionsForQuestionLinkArray(content, databaseQuestions, similarQuestions);
            $scope.showSuggestionsForQuestionLinks = true;
        }
    };

    $scope.linkToNursingQuestion = (questionId, nursingQuestion) => {
        const content = $scope.existingDocLatestVersion.content;
        if (isContentInvalid(content)) {
            toaster.pop('error', 'Oops! Form is invalid', 'Please review your nested questions and try again');
            return;
        }
        const [item] = getContentItem(content, (x) => x.id === questionId);
        item.possibleAnswers = nursingQuestion.possibleAnswers === null ? undefined : nursingQuestion.possibleAnswers.map(x => x.text);
        item.nursingQuestionLinked = nursingQuestion.id;
        const suggestionIndex = $scope.suggestionsForQuestionLinks.findIndex(x => x.questionId === questionId);
        if (suggestionIndex !== -1) {
            $scope.suggestionsForQuestionLinks.splice(suggestionIndex, 1);
        }
    }

    $scope.completeConvertToNursingQuestions = () => {
        const content = $scope.existingDocLatestVersion.content;
        if (isContentInvalid(content)) {
            toaster.pop('error', 'Oops! Form is invalid', 'Please review your nested questions and try again');
            return;
        }
        const a = $scope.publish().then(() => {
            const url = "agencies/" + $rootScope.agencyId + "/agency_members/" +
                        $rootScope.agencyMemberId + "/patient_documents/" +
                        $scope.existingDocType.id + "/convert_to_nursing_questions"
            DatabaseApi.post(url).then(function (_res) {
                $scope.completeConvertingToNursingQuestion = true;
                $scope.convertingToNursingQuestion = false;
                $window.location.reload();
            }, function (_err) {
                $scope.convertingToNursingQuestion = false;
                toaster.pop('error', 'Failed converting document');
            });
        });
    }

    $scope.isSaving = false;
    let dropZoneTimeoutPromise;
    const dropZoneDelayInMs = 500;

    var editArr = [];
    $scope.databaseQuestionsTypes = ["radio", "check", "dropDown", "yesNo", "textShort", "textLong", "number", "time", "date", "bloodPressure"];
    $scope.databaseQuestions = mapDatabaseQuestions(DatabaseApi.patientQuestionTypes());

    $scope.alerts = [];
    var url = "agencies/" + $rootScope.agencyId +
        "/agency_members/" + $rootScope.agencyMemberId +
        "/alerts";
    DatabaseApi.get(url).then(function (res) {
        $scope.alerts = res.data.alerts;
    }, function (err) {
        toaster.pop('error', 'Failed loading alerts');
    });

    $scope.onIsTriggeringAlert = function(item) {
        if(!item) {
            return;
        }
        if(!item.isTriggeringAlert) {
            delete item.alertId;
            delete item.triggerAnswer;
        }
    }

    $scope.models = {
        selected: null,
        disabled: false,
        templates: [],
        dropzones: {
            "MAIN": []
        }
    };


    $scope.getDoc = function () {
        if ($scope.existingDocType === undefined) {
            $scope.existingDocType = DatabaseApi.patientDocumentTypes().find(function (doc) {
                return doc.id.toString() == $stateParams.documentId.toString()
            });


            if ($scope.existingDocType !== undefined) {
                $scope.existingDocLatestVersion = $scope.existingDocType.versions.sort(function (ver1, ver2) {
                    return ver2.id - ver1.id;
                })[0];
                // Init form from existing version
                parseData($scope.existingDocLatestVersion.content);
            }
        }
    };

    $scope.processContentToParse = function (content) {
        if (content === null || content === undefined) {
            return (null);
        }

        return content.reduce(function (result, item) {
            var newItem = Object.assign({}, item);

            if (newItem.columns !== null && newItem.columns !== undefined) {
                newItem.columns[0] = $scope.processContentToParse(newItem.columns[0]);
            }

            if (item.vbpItemId !== null && item.vbpItemId !== undefined) {
                newItem.id = item.id;
                newItem.vbpItemId = item.vbpItemId;
                newItem.itemType = "vbpItem";
                newItem.mainClass = "vbpItem";
                newItem.title = "Value Based Payment Item";
                newItem.label = "Value Based Payment Item";
                newItem.isRequired = item.isRequired;
            }

            result.push(newItem);

            return result;
        }, []);
    };



    $scope.editFormDetails = function () {
        DatabaseApi.patch(
            "agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types/" + $scope.existingDocType.id,
            {
                title: $scope.models.title,
                isRemoved: false,
                detailsSettings: $scope.models.detailsSettings
        });
        $scope.existingDocType.title = $scope.models.title;
    }

    function addOrPullEditQueue(newContent) {
        if (newContent) {
            editArr.push(angular.copy(newContent));
        }
        if (editArr.length && $scope.isSaving === false) {
            var item = editArr.shift();
            $scope.editForm(item);
        }
    }



    ///////////////////-------



    $scope.example = function () {
        var lastVersion = $scope.existingDocType.versions.sort(function (ver1, ver2) { return ver2.id - ver1.id; })[0];


        $scope.isLoadingExample = true;

        DatabaseApi.post("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types/example",

            { title: $scope.existingDocType.title, content: lastVersion.content }).then(function (data) {
                $scope.isLoadingExample = false;
                $window.open(data.data.url);
            }, function () {
                toaster.pop('error', "Something went wrong");
            });
    };

    if ($stateParams.documentId) {
        $scope.isEditingExistingDoc = true;
        $scope.getDoc();
    } else {
        $state.go("app.patientDocumentSettings");
    }

    const recursiveCountMedicationProfileQuestions = (columns) => {
        let medicationProfileCounter = 0;
        columns.forEach(item => {
            if (item.mainClass === "medicationProfile") {
                medicationProfileCounter++;
            }
            if (item.columns) {
                medicationProfileCounter += recursiveCountMedicationProfileQuestions(item.columns[0]);
            }
        });

        return medicationProfileCounter;
    }

    const isDocumentValidated = (newModel) => {
        const medicationProfileCounter = recursiveCountMedicationProfileQuestions(newModel);

        if (medicationProfileCounter > 1) {
            // Multiple Error
            toaster.pop('error', 'Oops! Failed updating document', 'Multiple medication profiles are not allowed');
            return false;
        }
        
        // Validated
        return true;
    }

    function parseData(data) {
        data = data || [];
        $scope.models.lastId = getMaxIdFromArray(data);
        $scope.models.dropzones["MAIN"] = data;
        $scope.models.title = $scope.existingDocType.title || "";
        $scope.models.detailsSettings = $scope.existingDocType.detailsSettings || {
            showPatientName: true,
            showPatientGender: true,
            showPatientAddress: true,
            showPatientPhoneNumber: true,
            showPatientDateOfBirth: true,
            showCaregiverName: true,
            showPatientContract: true,
            showPatientInitials: true,
            showGeneralDetailsOnEveryPage: false
        };

        const QUESTIONS_PER_PAGE = 20;
        $scope.currentPage = 1;
        $scope.previousTotalPages = 1;

        const setPageLogic = () => {
            $scope.totalPages = Math.ceil($scope.models.dropzones.MAIN.length / QUESTIONS_PER_PAGE);
            $scope.nextPage = () => $scope.currentPage = Math.min($scope.currentPage + 1, $scope.totalPages);
            $scope.previousPage = () => $scope.currentPage = Math.max($scope.currentPage - 1, 1);
            $scope.goToPage = (index) => $scope.currentPage = index;
            $scope.isInPage = (index) => {
                if ($scope.models.dropzones.MAIN.length < QUESTIONS_PER_PAGE) {
                    return true;
                }

                const fromIndex = QUESTIONS_PER_PAGE * ($scope.currentPage - 1);
                const toIndex = QUESTIONS_PER_PAGE * $scope.currentPage;
                return index >= fromIndex && index < toIndex;
            }

            $scope.canGoNextPage = $scope.currentPage < $scope.totalPages;
            $scope.canGoPreviousPage = $scope.currentPage > 1;

            $scope.previousTotalPages = $scope.totalPages;
        }

        $scope.$watch("currentPage", setPageLogic);

        $scope.range = (min, max, step) => {
            step = step || 1;
            const input = [];
            for (let i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

        $scope.$watch('models.dropzones.MAIN', function (newModel, oldModel) {
            $scope.totalPages = Math.ceil($scope.models.dropzones.MAIN.length / QUESTIONS_PER_PAGE);
            const lastItemId = $scope.models.dropzones.MAIN[$scope.models.dropzones.MAIN.length - 1]?.id;

            if ($scope.currentPage > $scope.totalPages) {
                $scope.currentPage = $scope.totalPages;
            } else if (lastItemId && $scope.previousLastItemId && lastItemId !== $scope.previousLastItemId) {
                $scope.currentPage = $scope.currentPage + 1;
            }

            $timeout.cancel(dropZoneTimeoutPromise);
            dropZoneTimeoutPromise = $timeout(() => {
                if (!angular.equals(newModel, oldModel)) {
                    if (isDocumentValidated(newModel)) {
                        addOrPullEditQueue(newModel);
                    } else {
                        $scope.models.dropzones["MAIN"] = oldModel;
                    }
                }

            }, dropZoneDelayInMs);

            $scope.previousLastItemId = lastItemId;
        }, true);
    }

    $rootScope.$on('got_data', () => {
        $scope.getDoc();
        $scope.databaseQuestions = mapDatabaseQuestions(DatabaseApi.patientQuestionTypes());
    });


    $scope.editForm = function (newContent) {

        if ($scope.isSaving) return;
        $scope.isSaving = true;

        DatabaseApi.put("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types/" + $scope.existingDocType.id + "/version",
            { isPublished: false, content: newContent }).
            then(function () {
                if ($scope.existingDocLatestVersion.isPublished) {
                    var newVersion = {
                        id: $scope.existingDocLatestVersion.id + 1,
                        content: newContent,
                        isPublished: false
                    };

                    $scope.existingDocType.versions.push(newVersion);
                    $scope.existingDocLatestVersion = newVersion;

                } else {
                    $scope.existingDocLatestVersion.content = newContent;
                }

                $scope.isSaving = false;
                // DatabaseApi.setPatientDocumentTypes();
                addOrPullEditQueue();
            }, function (err) {
                toaster.pop('error', 'something went wrong');
                $scope.isSaving = false;
                addOrPullEditQueue();
            });
    };

    $scope.publish = function () {
        if (isContentInvalid($scope.existingDocLatestVersion.content)) {
            toaster.pop('error', 'Oops! Form is invalid', 'Please review your nested questions and try again');
            return;
        }
        return DatabaseApi.put("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types/" + $scope.existingDocType.id + "/version",
            { isPublished: true, content: $scope.existingDocLatestVersion.content }).
            then(function () {
                $scope.existingDocLatestVersion.isPublished = true;
                toaster.pop('success', 'Document updated successfully');
            });
    };

    const isContentInvalid = (questions) => {
        return questions.find(question => {
            let nestedParamsCounter = 0;
            if (question.parentId !== null) nestedParamsCounter++;
            if (question.parentType !== null) nestedParamsCounter++;
            if (question.ifParentEquals !== undefined) nestedParamsCounter++;
            return nestedParamsCounter > 0 && nestedParamsCounter < 3;
        }) !== undefined;
    }

    $scope.allowedNestedQuestionTypes = [
        'radio',
        'check',
        'dropDown',
        'yesNo',
        'textShort',
        'number'
    ];

    var possibleAnswersTemplate = [
        'option 1',
        'option 2',
        'option 3',
        'option 4'
    ];
    var possibleAnswersValuesTemplate = [
        1,
        2,
        3,
        4
    ]
    var possibleYesNoTemplate = [
        'Yes',
        'No'
    ];

    var types = {
        multiple: {
            list: [
                { type: 'radio', title: 'Radio' },
                { type: 'check', title: 'Multiple Select (check)' },
                { type: 'dropDown', title: 'Drop Down' },
                { type: 'yesNo', title: 'Yes/No' }
            ],
            data: {
                mainClass: 'multiple',
                title: null,
                itemType: null,
                columns: null,
                possibleAnswers: null,
                isRequired: false,
                parentId: null,
                showIfParentEquals: null,
                showOtherOption: false,
                answerValues: [],
                valueGroup: null,
                isInValueGroup: false,
                useInOtherDocs: false
            }
        },

        noQuestion: {
            list: [
                { type: 'bigHeader', title: 'Main Header' },
                { type: 'smallHeader', title: 'Sub Header' }
            ],
            data: {
                mainClass: 'noQuestion',
                title: null,
                itemType: null,
                parentId: null,
                showIfParentEquals: null
            }
        },

        single: {
            list: [
                { type: 'textShort', title: 'Short Text' },
                { type: 'textLong', title: 'Long Text' },
                { type: 'number', title: 'Number' },
                { type: 'employeeSignature', title: 'Employee Signature' },
                { type: 'patientSignature', title: 'Patient Signature' },
                { type: 'customSignature', title: 'Signature' },
                { type: 'time', title: 'Time' },
                { type: 'date', title: 'Date' }
            ],
            data: {
                mainClass: 'single',
                title: null,
                itemType: null,
                columns: null,
                isRequired: false,
                parentId: null,
                showIfParentEquals: null,
                prefilledAnswer: null,
                valueGroup: null,
                isInValueGroup: false,
                useInOtherDocs: false,
                copyFromOtherDocs: false,
                allowUnsignedSignature: false,
                hideNameFieldSignature: false
            }
        },



        editor: {
            list: [
                { type: 'editor', title: 'Add Free Text' }
            ],
            data: {
                mainClass: 'editor',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                html: ''
            }
        },

        chart: {
            list: [
                { type: 'chart', title: 'Chart' }
            ],
            data: {
                mainClass: 'chart',
                title: null,
                itemType: null,
                columns: [[]],
                parentId: null,
                showIfParentEquals: null,
                useInOtherDocs: false
            }
        },

        image: {
            list: [
                { type: 'image', title: 'Image' }
            ],
            data: {
                mainClass: 'image',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                url: null
            }
        },

        bodyDrawing: {
            list: [
                { type: 'bodyDrawing', title: 'Body Drawing' }
            ],
            data: {
                mainClass: 'bodyDrawing',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                url: null
            }
        },

        medicationProfile: {
            list: [
                { type: 'medicationProfile', title: 'Medication Profile' }
            ],
            data: {
                mainClass: 'medicationProfile',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                url: null
            }
        },

        bloodPressure: {
            list: [
                { type: 'bloodPressure', title: 'Blood Pressure' }
            ],
            data: {
                mainClass: 'bloodPressure',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                url: null
            }
        },
                
        icdCodes: {
            list: [
                { type: 'icdCodes', title: 'ICD' }
            ],
            data: {
                mainClass: 'icdCodes',
                title: null,
                itemType: null,
                columns: null,
                parentId: null,
                showIfParentEquals: null,
                url: null
            }
        }
    };

    $scope.types = types;

    for (var typeList in types) {

        typeList = types[typeList];

        var mainType = typeList.data.mainClass;

        typeList.list.forEach(function (type) {
            var newItem = angular.copy(typeList.data);
            newItem.id = -1;
            newItem.label = type.title;
            newItem.title = type.title;
            newItem.itemType = type.type;
            newItem.linkId = null;

            if (mainType === 'multiple') {
                if (type.type === 'yesNo') {
                    newItem.possibleAnswers = angular.copy(possibleYesNoTemplate);
                } else {
                    newItem.possibleAnswers = angular.copy(possibleAnswersTemplate);
                    newItem.answerValues = angular.copy(possibleAnswersValuesTemplate);
                }
            }

            $scope.models.templates.push(newItem);

        });
    }

    $scope.moved = function (item) {
        var parent = findItemParentIdByIdFromArray(item.id, $scope.models.dropzones["MAIN"]);
        console.log('moved', item.id, parent);
        item.parentId = parent.parent ? parent.parent.id : null;
        item.parentType = parent.parent ? parent.parent.itemType : null;
        if (item.parentType === 'chart' && item.itemType === 'chart') {
            $scope.models.dropzones["MAIN"] = setItemByIdInArray(item.id, $scope.models.dropzones["MAIN"], undefined);
        } else {
            setItemByIdInArray(item.id, $scope.models.dropzones["MAIN"], item);
        }
        console.log(item);


    };

    
    $scope.addItemClick = function(item, insertAsChild){
        $scope.selectedPlaceToAdd = { id: item.id, insertAsChild: insertAsChild };
    }

    $scope.addManually = function (item) {
        const newItem = angular.copy(item);
        newItem.id = getMaxIdFromArray($scope.models.dropzones["MAIN"]) + 1;
        insertItemAfterItemById(
            $scope.selectedPlaceToAdd.id, 
            $scope.models.dropzones["MAIN"], 
            null,
            newItem, 
            $scope.selectedPlaceToAdd.insertAsChild
        );
        addItem(newItem); 
    };

    $scope.removeManually = function (id, isLinked) {
        if (isLinked) {
            SweetAlert.swal({
                title: "Delete Linked Question",
                text: "Are you sure you want to delete this linked question? (removal of links will not update related questions)",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, remove",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    removeItemById(id, $scope.models.dropzones["MAIN"], null);
                }
            });
        } else {
            removeItemById(id, $scope.models.dropzones["MAIN"], null);
        }
    }
    

    function addItem(item) {
        $timeout(function () {
            var res = findItemParentIdByIdFromArray(item.id, $scope.models.dropzones["MAIN"]);
            console.log('dropped', item.id, res);
            if (res && res.found) {
                var newItem = res.found;
                if (res.parent) {
                    newItem.parentId = res.parent.id;
                    newItem.parentType = res.parent.itemType;
                    newItem.ifParentEquals = '';
                    if (res.parent.blockOnMobile) {
                        newItem.isParentBlockedOnMobile = true;
                        newItem.blockOnMobile = true;
                        newItem.isRequired = false;
                    }
                } else {
                    newItem.parentId = null;
                    newItem.parentType = null;
                    delete newItem.ifParentEquals;
                }
                newItem.showIfParentEquals = false;
                if (newItem.parentType === 'chart' && newItem.itemType === 'chart') {
                    res.parent.columns[0].forEach(function (child, i) {
                        if (child.itemType === 'chart') res.parent.columns[0].splice(i, 1);
                    });
                    $scope.models.dropzones["MAIN"] = setItemByIdInArray(res.parent.id, $scope.models.dropzones["MAIN"], res.parent);
                } else {
                    setItemByIdInArray(newItem.id, $scope.models.dropzones["MAIN"], newItem);
                }
            }

            if (item.itemType === 'customSignature') {
                var newItem = {
                    mainClass: "single",
                    title: "Short Text",
                    itemType: "textShort",
                    columns: null,
                    isRequired: item.isRequired,
                    parentId: item.id,
                    showIfParentEquals: null,
                    id: getMaxIdFromArray($scope.models.dropzones["MAIN"]) + 1,
                    label: "Signee Name",
                    linkId: null,
                    parentType: "customSignature"
                }
                item.columns = [[newItem]];
            }
        }, 10);
    }

    $scope.dropped = function (event, index, type, external, item) {

        if (item.id === -1) {
            item.id = getMaxIdFromArray($scope.models.dropzones["MAIN"]) + 1;
        } else {
            console.log('xxxxx', item.id);
        }
        
        addItem(item);
        return item;
    };

    function getMaxIdFromArray(array, max) {
        max = max || 0;

        array.forEach(function (item) {
            max = item.id > max ? item.id : max;
            if (item.columns) {
                var maxFromArray = getMaxIdFromArray(item.columns[0], max);
                max = maxFromArray > max ? maxFromArray : max;
            }
        });

        return max;
    }

    function findItemByIdFromArray(id, array) {

        var found = null;

        array.forEach(function (item) {
            if (found) return;

            if (item.id === id) {
                found = item;
            } else if (item.columns) {
                found = findItemByIdFromArray(id, item.columns[0]);
            }
        });

        return found;
    }

    function findItemParentIdByIdFromArray(id, array, parent) {

        var found = null;

        array.forEach(function (item) {
            if (found) return;

            if (item.id === id) {
                found = item;
                parent = parent || null;
            }
        });

        if (!found) {
            array.forEach(function (item) {
                if (found) return;

                if (item.id === id) {
                    found = item;
                    parent = parent || null;

                } else if (item.columns) {
                    var res = findItemParentIdByIdFromArray(id, item.columns[0], item);
                    found = res ? res.found : null;
                    parent = res && found ? res.parent : null;
                }
            });
        }

        return { parent: parent, found: found };
    }

    function removeItemById(id, array, parent) {

        var found = null;

        array.forEach(function (item, i) { 
            if (found) return;

            if (item.id === id) {
                found = item;
                parent = parent || null;
                array.splice(i,1);
            }
        });

        if (!found) {
            array.forEach(function (item) {
                if (found) return;

                if (item.id === id) {
                    found = item;
                    parent = parent || null;

                } else if (item.columns) {
                    var res = removeItemById(id, item.columns[0], item);
                    found = res ? res.found : null;
                    parent = res && found ? res.parent : null;
                }
            });
        }

        return { parent: parent, found: found };
    }

    function insertItemAfterItemById(id, array, parent, itemToInsert, haveParent) {

        var found = null;

        array.forEach(function (item,i) {
            if (found) return;

            if (item.id === id) {
                found = item;
                parent = parent || null;
                if(!haveParent) {
                    array.splice(i+1, 0, itemToInsert)
                } else {
                    item.columns[0].push(itemToInsert);
                }
            }
        });

        if (!found) {
            array.forEach(function (item) {
                if (found) return;

                if (item.id === id) {
                    found = item;
                    parent = parent || null;

                } else if (item.columns) {
                    var res = insertItemAfterItemById(id, item.columns[0], item, itemToInsert, haveParent);
                    found = res ? res.found : null;
                    parent = res && found ? res.parent : null;
                }
            });
        }

        return { parent: parent, found: found };
    }

    function setItemByIdInArray(id, array, newItem) {

        array = array.map(function (item) {

            if (item.id === id) {
                item = newItem;
            } else if (item.columns) {
                item.columns[0] = setItemByIdInArray(id, item.columns[0], newItem);
            }
            return item;
        });

        return array;
    }

    $scope.toggleParent = function (item) {
        if (item.columns) {
            item.columns = null;
        } else {
            var found = findItemByIdFromArray(item.id, $scope.models.dropzones["MAIN"]);
            if (found) {
                found.columns = [[]];
                setItemByIdInArray(found.id, $scope.models.dropzones["MAIN"], found);
            }
        }
    }

    $scope.editor = {
        customMenu: [
            ['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript'],
            ['font'],
            ['font-size'],
            ['font-color', 'hilite-color'],
            ['remove-format'],
            ['ordered-list', 'unordered-list', 'outdent', 'indent'],
            ['left-justify', 'center-justify', 'right-justify'],
            ['paragraph']
        ]
    };

    ////////////////////
    /// image
    ////////////////////

    $scope.openUploadModal = function (item) {

        console.log("item");
        console.log(item);

        var modalInstance = $uibModal.open({
            templateUrl: 'patient-doc-image-upload-modal.html',
            size: 'md',
            controller: 'PatientDocImageUploadModalCtrl',
            resolve: {
                docId: function () {
                    return $stateParams.documentId;
                }
            }
        });
        modalInstance.result.then(function (res) {

            if (res) {
                // var item = findItemByIdFromArray(item.id, $scope.models.dropzones["MAIN"]);
                // if (item) {
                item.url = res;
                setItemByIdInArray(item.id, $scope.models.dropzones["MAIN"], item);
                // }

                toaster.pop('success', "Success", "File Uploaded");
            }

        }, function () {
            console.log('modal close');
        });
    };



    /*$scope.clickConnectQuestions = function (item) {

        if (item.linkId) {
            $scope.clickRemoveConnectQuestions(item);
        }

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-doc-link-question.html',
            size: 'md',
            controller: 'patientDocLinkQuestionModalCtrl',
            resolve: {
                docId: function () {
                    return $stateParams.documentId;
                },
                question: function () {
                    return item;
                }
            }
        });
        modalInstance.result.then(function (res) {

            if (res) {
                item.linkId = res.data.id;
                delete item.prefilledAnswer;
                setItemByIdInArray(item.id, $scope.models.dropzones["MAIN"], item);
                toaster.pop('success', "Success", "Questions are linked");
            }

        }, function () {
            console.log('modal close');
        });

    };

    $scope.clickRemoveConnectQuestions = function (item) {
        item.linkId = null;

        var url = 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/patient_document_types/delete_link/' + $stateParams.documentId + '/' + item.id;
        DatabaseApi.delete(url).then(function () {

        }, function () {
            toaster.pop('error', "Something went wrong", "please try again");
        });
        setItemByIdInArray(item.id, $scope.models.dropzones["MAIN"], item);

    }*/

    $scope.clickDatabaseQuestions = function (item) {
        if (item.nursingQuestionLinked) {
            item.nursingQuestionLinked = undefined;
        } else {
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/patient-doc-database-questions.html',
                size: 'md',
                controller: 'patientDocDatabaseQuestionsModalCtrl',
                resolve: {
                    item: function () {
                        return item;
                    },
                    mappedDatabaseQuestions: function () {
                        return $scope.databaseQuestions;
                    }
                }
            });
            modalInstance.result.then(function (databaseQuestionId) {
                if (databaseQuestionId) {
                    const selectedDatabaseQuestion = $scope.databaseQuestions[item.itemType].find(question => question.id === databaseQuestionId);
                    
                    const typeList = Object.values($scope.types);
                    const type = typeList.find(typeList => {
                        return typeList.list.find(questionType => questionType.type === item.itemType) !== undefined;
                    });
                    const newItem = {
                        ...angular.copy(type.data),
                        ...item
                    };
                    newItem.nursingQuestionLinked = selectedDatabaseQuestion.id;
                    newItem.id = item.id;
                    newItem.label = selectedDatabaseQuestion.questionText;
                    if (item.columns) {
                        newItem.columns = [
                            item.columns[0].map(nestedQuestion => {
                                return {
                                    ...nestedQuestion,
                                    parentId: newItem.id
                                };
                            })
                        ];
                    }
                    let foundQuestionType = null;
                    for (const typeListItem of typeList) {
                        for (const typeItem of typeListItem.list) {
                            if (foundQuestionType === null && typeItem.type === selectedDatabaseQuestion.answerType) {
                                foundQuestionType = typeItem;
                                break;
                            }
                        }
                    }
                    newItem.title = foundQuestionType.title;
                    newItem.itemType = item.itemType;
                    newItem.parentId = item.parentId;
                    newItem.parentType = item.parentType;
                    newItem.showIfParentEquals = item.showIfParentEquals;
                    newItem.icon = selectedDatabaseQuestion.icon

                    if (selectedDatabaseQuestion.possibleAnswers) {
                        newItem.possibleAnswers = selectedDatabaseQuestion.possibleAnswers.map(x => x.text);
                    }

                    $scope.models.dropzones["MAIN"] = setItemByIdInArray(newItem.id, $scope.models.dropzones["MAIN"], newItem);

                    toaster.pop('success', "Success", "Connected with database question");
                }
            });
        }
    }

    $scope.itemRequiredChanged = function (item) {
        if (item.itemType === "customSignature") {
            item.columns.forEach(function (col) {
                col.forEach(function (rowItem) {
                    rowItem.isRequired = item.isRequired && !item.hideNameFieldSignature;
                });
            });
        }

        if (item.isRequired && item.blockOnMobile === true) {
            item.blockOnMobile = false;
        }
    }

    $scope.itemHideNameFieldSignatureChanged = (item) => {
        if (item.itemType === "customSignature") {
            item.columns.forEach(function (col) {
                col.forEach(function (rowItem) {
                    rowItem.isRequired = item.isRequired && !item.hideNameFieldSignature;
                });
            });
        }
    }
    
    $scope.onBlockOnMobileChanged = (item) => {
        if (item.isRequired && item.blockOnMobile) {
            item.isRequired = false;
        }

        if (!item.columns) {
            return;
        }

        item.columns[0].forEach(child => {
            child.isParentBlockedOnMobile = item.blockOnMobile;
            child.blockOnMobile = item.blockOnMobile;

            $scope.onBlockOnMobileChanged(child);
        });
    }  

    $scope.itemPrefilledChanged = function (item, isPrefilled) {
        if (isPrefilled) {
            item.prefilledAnswer = "";
        } else {
            delete item.prefilledAnswer;
        }
    }

    $scope.canPrefill = function (item) {
        if ((item.itemType === 'textShort' || item.itemType === 'textLong')
            && (item.linkId === null || item.linkId === undefined)) {
            return true;
        }
        return false;
    }

    $scope.canLinkQuestion = function (item) {
        return !item.linkId && (item.prefilledAnswer === null || item.prefilledAnswer === undefined);
    }

    $scope.multiOptionPop = function (item) {
        item.possibleAnswers.pop();

        if (item.answerValues) {
            item.answerValues.pop();
        } else {
            item.answerValues = item.possibleAnswers.map((_, i)=> i + 1);
        }
    }

    $scope.multiOptionPush = function (item, val) {
        item.answerValues = item.answerValues || item.possibleAnswers.map((_, i)=> i + 1);
        
        var nextVal = item.answerValues.length > 0 ? item.answerValues[item.answerValues.length - 1] + 1 : 1;

        item.possibleAnswers.push(val);
        item.answerValues.push(nextVal);
    }

    function mapDatabaseQuestions (questions) {
        const questionsMap = {};
        $scope.databaseQuestionsTypes.forEach(databaseQuestionType => questionsMap[databaseQuestionType] = []);
        if (questions) {
            questions.forEach(question => questionsMap[question.answerType].push(question));
        } else {
            DatabaseApi.setPatientQuestionTypes();
        }
        return questionsMap;
    }

};

//! @ngInject
export function PatientDocImageUploadModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, DatabaseApi, toaster, docId) {


    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };


    $scope.$watch('image', function () {

        if ($scope.image && $scope.image.upload && $scope.image.upload.name) {
            var fileType = $scope.image.upload.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {

                $scope.uploadText = 'Uploading';
                $scope.updateImage();

            } else {
                toaster.pop('warning', "Unsupported file type", '');
                $scope.image = { upload: null };
            }
        }

    }, true);


    $scope.updateImage = function () {

        console.log('updateImage');


        $scope.uploading = true;


        var formData = new FormData();
        formData.append('file', $scope.image.upload, $scope.image.upload.name);


        var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/patient_document_types/' + docId + '/upload';

        $http({
            url: url,
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': undefined }
        }).then(function (response) {

            $uibModalInstance.close(response.data.url);

        }, function (response) {
            $scope.image = { upload: undefined };
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            $scope.uploading = false;
        });


    };


};
