export const reportToolTablesAndColumns = {
  templateUrl: "admin/views/report-tool-tables-and-columns.html",
  bindings: {
    primaryTable: "<",
    tables: "<",
    searchTables: "<",
    columnNotInAggFilters: "&",
    savedTables: "<",
    onColumnClicked: "&",
    onFilterClicked: "&",
    onTableMainClicked: "&",
    accessType: "<"
  },
  //! @ngInject
  controller: function($timeout, $filter) {
    this.tableFilter = "";
    this.handleColumnClicked = (tableName, column) => {
      this.onColumnClicked({
        tableName,
        column
      });
    }
    this.handleColumnNotInAggFilters = (tableName,column) => {
    return this.columnNotInAggFilters({tableName,column});
    }
    this.handleTableToggleSelectAllClicked = (table, clickedSelectAll) => {
      var that = this;
      table.$selectAll = !table.$selectAll;
      for (var i = 0; i < table.columns.length; i++) {
        let col = table.columns[i];
        if (col.selected ^ clickedSelectAll) {
          that.handleColumnClicked(table.tableName, col)
        }
      }
    }
    this.resetTableColumnsFilter = () => {
      this.tables = angular.copy(this.searchTables);
    }
    this.filterTablesColumns = (term) => {
      if (!term || term.length == 0) {
        this.resetTableColumnsFilter();
        return;
      }

      let data = angular.copy(this.searchTables);
      let finalTableData = [];
      for (var i = 0; i < data.length; i++) {
        let items = data[i].columns;
        var arr = $filter('filter')(items, term).filter(function(x) {
          return x !== undefined;
        })
        if (arr.length > 0) {
          data[i].columns = arr;
          finalTableData.push(data[i]);
        }

      }

      this.tables = finalTableData;
      return;
    }

    this.handleFilterClicked = (tableName, filter) => {
      this.onFilterClicked({
        tableName,
        filter
      });
    }
    this.handleTableMain = (tableName) => {
      this.onTableMainClicked({
        tableName
      });
    }

  },
};
