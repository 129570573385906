//! @ngInject
export function payrollCtrl($scope, $rootScope, $state, NgTableParams, DatabaseApi, toaster){
  
  window.scrollTo(0,0);
  /*
  $scope.upload = false;
  $scope.file = null;
  $scope.typeArray = [
    {id: 'EN', title: 'EN'},
    {id: 'RN', title: 'RN'},
    {id: 'TN', title: 'TN'},
  ];
  
  var caregiversArr = DatabaseApi.caregivers();
 
  var dates = {
    from: 111,
    to: 2111111111111
  };
  
  function caregiverByPhone(phone){
    var flag = {};
    if(!caregiversArr.length) return {};
    caregiversArr.forEach(function(care){
      if(phone == care.phone) flag = care;
    });
    return flag;
  }
  
  DatabaseApi.get('caregivers/payroll/111/2111111111111').then(function(res){
    
    $scope.pros = res.data;
    
    //for (var pro in $scope.pros){
    //  $scope.pros[pro].pro = caregiverByPhone(pro);
    //}
    $scope.pros.forEach(function(pro){
      pro.pro = caregiverByPhone(pro.id);
    });
    //console.log($scope.pros);
    initTable($scope.pros);
  }, function(err){
    console.log('something went wrong');
    toaster.pop('error', "Something Went Wrong", 'Please try again');
  });
 
  
  $scope.goToPro = function(id){
    $state.go('app.singleProfessionals', {id: id});
  };
  
  function initTable(data){
    $scope.table = new NgTableParams({
      count: 25
    }, {
      counts: [],
      dataset: data
    });
  }
  
  */
  //
  //
};