import { IPromise } from "angular";
import { DayOfWeek, Instant, LocalDate } from "js-joda";
import { Rawify } from "../@ts-utils/rawify";
import { AssertNever } from "../consts/assertNever.const";
import { PatientId, PlanOfCareDutyId, PlanOfCareId, PlanOfCareItemId } from "../messages/ids";
import {
    AgencyMemberCreatePlanOfCareRequest,
    GeneratePatientDutySheetsCSVResponse,
    PatientPlanOfCare,
    PatientPlansOfCareDuty,
    PatientPlansOfCareResponse,
    PlanOfCareDutyPeriod
} from "../messages/plan_of_care";
import { AgencyMemberCreateCustomPlanOfCareTypeForOfficesRequest, DefaultPOCTaskItems } from "../messages/plan_of_care_type";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export interface PlanOfCareDutyTableRow {
  name: string;
  code: string;
  section: string;
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  fr: boolean;
  sa: boolean;
  su: boolean;
  frequency: string;
  notes: string;
}

export interface GeneratePatientDutySheetRow {
  id: PlanOfCareDutyId;
  period: PlanOfCareDutyPeriod;
  daysOfWeek: DayOfWeek[] | null;
  visitsPerWeek: number | null;
  frequency: number | null;
  notes: string | null;
  startDate: LocalDate;
  caregiverName: string;
  isCompleted: boolean;
  item: {
    id: PlanOfCareItemId;
    label: string;
    code: string;
  };
}

export class PlanOfCareService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api,
    private assertNever: AssertNever
  ) {}

  /**
   * Create a new plan of care for a patient
   */
  async create(params: AgencyMemberCreatePlanOfCareRequest & { patientId: PatientId }) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plans_of_care",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: params.patientId,
      },
    });

    return this.api.post(url, params).then(() => void null);
  }

  /**
   * Get all plans of care for a patient
   */
  async getByPatientId(patientId: PatientId): Promise<PatientPlanOfCare[]> {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plans_of_care",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });

    return this.api.get<Rawify<PatientPlansOfCareResponse>>(url).then(({ data }) =>
      data.plansOfCare.map((planOfCare) => ({
        ...planOfCare,
        duties: planOfCare.duties.map((duty) => ({
          ...duty,
          daysOfWeek: duty.daysOfWeek?.map((dayOfWeek) => DayOfWeek.valueOf(dayOfWeek)) ?? null,
        })),
        startDate: planOfCare.startDate ? LocalDate.parse(planOfCare.startDate) : null,
        endDate: planOfCare.endDate ? LocalDate.parse(planOfCare.endDate) : null,
        submittedAt: Instant.parse(planOfCare.submittedAt),
      }))
    );
  }

  /**
   * Get a plan of care pdf url
   */
  async getPdfUrl(planOfCareId: PlanOfCareId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/plans_of_care/:planOfCareId/generate_url",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        planOfCareId: planOfCareId,
      },
    });

    return this.api.get<{ fileUrl: string | null }>(url).then(({ data }) => data.fileUrl);
  }

  /**
 * Get default hard-coded plan of care task items
 */
    getDefaultPOCTaskItems(): IPromise<DefaultPOCTaskItems> {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/default_poc_task_items",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId
      },
    });

    return this.api.get<DefaultPOCTaskItems>(url).then((res) => res.data);
  }

  async generateDutySheetsCsv(params: {
    patientId: PatientId;
    planOfCareId: PlanOfCareId;
    from: LocalDate;
    to: LocalDate;
  }): Promise<GeneratePatientDutySheetRow[]> {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/plans_of_care/:planOfCareId/generate_duty_sheets_csv_by_dates",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        planOfCareId: params.planOfCareId,
      },
      queries: {
        from: params.from.toString(),
        to: params.to.toString(),
      },
    });

    return this.api.get<Rawify<GeneratePatientDutySheetsCSVResponse>>(url).then(({ data }) => {
      return data.duties.map(
        (duty): GeneratePatientDutySheetRow => ({
          id: duty.id,
          period: duty.period,
          daysOfWeek: duty.daysOfWeek?.map((day) => DayOfWeek.valueOf(day)) ?? null,
          item: duty.item,
          caregiverName: duty.caregiverName,
          isCompleted: duty.isCompleted,
          startDate: LocalDate.parse(duty.startDate),
          visitsPerWeek: duty.visitsPerWeek,
          frequency: duty.frequency,
          notes: duty.notes,
        })
      );
    });
  }

  /**
   * Create a new custom plan of care type for an office/s
   */
  createCustomPlanOfCareType(
    params: AgencyMemberCreateCustomPlanOfCareTypeForOfficesRequest
  ): IPromise<void> {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/custom_plan_of_care_type",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.post(url, params).then(() => void null);
  }

  async generateDutySheetPdf(params: {
    patientId: PatientId;
    planOfCareId: PlanOfCareId;
    from: LocalDate;
    to: LocalDate;
  }) {
    return this.api
      .get<{ fileUrl: string }>(
        this.endpoint({
          path: "agencies/:agencyId/patients/:patientId/plans_of_care/:planOfCareId/generate_duty_sheets_pdf_by_dates",
          params: {
            agencyId: this.$rootScope.agencyId,
            patientId: params.patientId,
            planOfCareId: params.planOfCareId,
          },
          queries: {
            from: params.from.toString(),
            to: params.to.toString(),
          },
        })
      )
      .then(({ data }) => data.fileUrl);
  }

  // This method should probably not be here, but it's not clear where it should go
  mapToTableRow(duty: PatientPlansOfCareDuty): PlanOfCareDutyTableRow {
    return {
      name: duty.item.label,
      code: duty.item.code,
      section: duty.item.sectionName,
      frequency: (() => {
        switch (duty.period) {
          case "AS_REQUESTED":
            return "As requested";
          case "EVERY_VISIT":
            return "Every visit";
          case "DAYS_A_WEEK":
            return duty.frequency !== null && duty.visitsPerWeek !== null
              ? `${duty.frequency}/${duty.visitsPerWeek} days a week`
              : "";
          default:
            this.assertNever(duty.period);
        }
      })(),
      notes: duty.notes ?? "",
      mo: this.isDutyRequiredInDay({ duty, day: DayOfWeek.MONDAY }),
      tu: this.isDutyRequiredInDay({ duty, day: DayOfWeek.TUESDAY }),
      we: this.isDutyRequiredInDay({ duty, day: DayOfWeek.WEDNESDAY }),
      th: this.isDutyRequiredInDay({ duty, day: DayOfWeek.THURSDAY }),
      fr: this.isDutyRequiredInDay({ duty, day: DayOfWeek.FRIDAY }),
      sa: this.isDutyRequiredInDay({ duty, day: DayOfWeek.SATURDAY }),
      su: this.isDutyRequiredInDay({ duty, day: DayOfWeek.SUNDAY }),
    };
  }

  isDutyRequiredInDay = (params: { duty: PatientPlansOfCareDuty; day: DayOfWeek }) => {
    return params.duty.daysOfWeek?.includes(params.day) === true;
  };
}
