//! @ngInject
export function patientsPreExistingModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, possibleMatches, DatabaseApi, toaster){
    
    $scope.possibleMatches = possibleMatches.patients;
    $scope.gradients = DatabaseApi.getCssGradient;

    $scope.goToPatient = function (id) {
        $rootScope.openPatientModal(id);
        $uibModalInstance.dismiss();
    };

    $scope.continueAdding = function () {
        $uibModalInstance.close();
    }
    $scope.edit = function () {
        $uibModalInstance.dismiss();
    }
}
