//! @ngInject
export function statusChangeReasonSetupModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService){

    // window.scrollTo(0,0);

    const parseData = (data) => {
        return {
          ...data,
          statuses: data.statuses !== undefined ? data.statuses.map((item) => ({ id: item, label: item })) : [],
        };
    };
    
    $scope.data = parseData(angular.copy(data));

    $scope.fields = FormService.getFields(fields, $scope.data);

    $scope.form = {};

    $scope.fieldChanged = function(item){
        if(item.type.type === "Boolean" && $scope.data.id){
            $scope.submitForm();
        }
    }

    $scope.submitForm = function() {
        console.log($scope.form);
        
        if ($scope.form.data.$invalid) {
            return;
        }

        var newData = FormService.prepareFormData($scope.fields);

        // Add agency info
        newData.agency = $rootScope.agencyId;

        if (Array.isArray(newData.statuses)) {
            newData.statuses = newData.statuses.map(status => status.id !== undefined ? status.id : status);
        } else {
            newData.statuses = [newData.statuses];
        }
        
        console.log(newData);

        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/status_change_reason/' + $scope.data.id , newData) //{[field.name]: field.value})
                .then(function(res) {
                    console.log(res);
                    toaster.pop('success', "Status change reason saved");
                    $scope.closeModal();
                }, function(err){
                    toaster.pop('error', "Something went wrong", "Could not change status change reason settings")
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/status_change_reason', newData).then(function (res) {
                console.log(res);
                toaster.pop('success', "Status change reason saved");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not add status change reason");
            });
        }
    };

    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };

};
