import _ from "lodash";

//! @ngInject
export function trainingCenterMediaLibraryCloneBundleModal($scope, $state, $uibModalInstance, $sce) {
      $scope.showBundles = { val: false };
      $scope.agencyBundles = $scope.$resolve.agencyBundles;
      $scope.isProcessing = $scope.$resolve.isProcessing;
      $scope.onChooseBundle = $scope.$resolve.onChooseBundle;
      $scope.onChooseCloneBundle = $scope.$resolve.onChooseCloneBundle;
      $scope.createdBundle = $scope.$resolve.createdBundle;
      $scope.bundle = $scope.$resolve.bundle;
      $scope.selectedBundleId = $scope.$resolve.selectedBundleId;
      $scope.goToBundle = goToBundle;
      $scope.closeModal = closeModal;

      this.$onInit = function () {
        $scope.agencyBundles.forEach(bundle => {
          bundle.__displayData = _.keyBy(bundle.bundleDisplayData, "language")
        });
      }

      $scope.cloneAsNewBundle = function() {
        $scope.showBundles.val = false;
      }

      function goToBundle() {
        $state.go("app.trainingCenterBundleSettings", {
          id: $scope.createdBundle.val
        });
        closeModal();
      }

      function closeModal() {
        $uibModalInstance.dismiss();
      }
    }
