import { CaregiverId, PatientId, VisitBroadcastId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { AssignCaregiversToFlexibleVisitRequest, AssignFlexibleVisitResponse, PartialAssignResponse, PatientWeeklyTemplateShiftsResponse, RejectCaregiverVisitRequestsBody, VisitBroadcastPartialAssingParams, WeeklyHoursForCaregiversByIdsResponse } from "./visitBroadcast.types";

//! @ngInject
export class VisitBroadcastService {
    constructor(
      private api: Api,
      private endpoint: Endpoint,
      private $rootScope: ng.IRootScopeService
    ) {}

    rejectCaregiverVisitRequest = (caregiverId: CaregiverId, visitBroadcastId: VisitBroadcastId, requestBody: RejectCaregiverVisitRequestsBody) => {
        const url = this.endpoint({
            path: "agency/:agencyId/agency_members/:agencyMemberId/visits/:visitBroadcastId/caregivers/:caregiverId/ignore_request",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId,
              caregiverId
            },
          });

        return this.api.put(url, requestBody);
    }

    assignCaregiversToVisit = (
        visitBroadcastId: VisitBroadcastId,
        requestBody: VisitBroadcastPartialAssingParams
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId
            }
          });

        return this.api.post<PartialAssignResponse>(url, requestBody);
    }

    requestAssignmentCaregiversToVisit = (visitBroadcastId: VisitBroadcastId, requestBody: VisitBroadcastPartialAssingParams) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially/request",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId
            }
        });

        return this.api.post<PartialAssignResponse>(url, requestBody);
    }

    confirmCaregiverAssignmentRequest = (
        visitBroadcastId: VisitBroadcastId,
        caregiverId: CaregiverId
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially/confirm/:caregiverId",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId,
                caregiverId
            }
        });

        return this.api.post<PartialAssignResponse>(url);
    }

    rejectCaregiverAssignmentRequest = (
        visitBroadcastId: VisitBroadcastId,
        caregiverId: CaregiverId
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially/reject/:caregiverId",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId,
                caregiverId
            }
        });

        return this.api.post(url);
    }


    assignCaregiversToFlexibleVisit = (
        visitBroadcastId: VisitBroadcastId,
        requestBody: AssignCaregiversToFlexibleVisitRequest
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_flexible_visit",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId
            },
        });

        return this.api.post<AssignFlexibleVisitResponse>(url, requestBody);
    };

    requestAssignmentCaregiversToFlexibleVisit = (
        visitBroadcastId: VisitBroadcastId,
        requestBody: AssignCaregiversToFlexibleVisitRequest
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_flexible_visit/request",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId
            },
        });

        return this.api.post<AssignFlexibleVisitResponse>(url, requestBody);
    };

    confirmAssignmentRequestForFlexibleVisit = (
        visitBroadcastId: VisitBroadcastId,
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_flexible_visit/confirm",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId
            }
        });

        return this.api.post<AssignFlexibleVisitResponse>(url);
    }

    rejectAssignmentRequestForFlexibleVisit = (
        visitBroadcastId: VisitBroadcastId,
    ) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_flexible_visit/reject",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitBroadcastId
            }
        });

        return this.api.post(url);
    }

    getPatientWeeklyTemplate = (patientId: PatientId) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/weekly_template_shifts",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              patientId
            },
          });

    return this.api.get<PatientWeeklyTemplateShiftsResponse>(url);
  }

  // TODO! Should actualy be on a 'Caregiver Service' but there's no module that currently hosts the caregiver profile/data.
  getCaregiversWorkingHoursPerWeek = (caregiverIds: CaregiverId[], visitId: VisitBroadcastId) => {
    const url = this.endpoint({
        path: "agencies/:agencyId/agency_member/:agencyMemberId/visits/:visitId/caregivers_work_time_by_visit_weeks",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          visitId: visitId
        },
        queries: {
          caregiverIds: caregiverIds.toString(),
        }
      });

    return this.api.get<WeeklyHoursForCaregiversByIdsResponse>(url);
  }
}
