//! @ngInject
export function caregiverPayrollTax(toaster, cityTaxCodeService, $rootScope, Consts, $http) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/caregiver-payroll-tax.html',
        scope: {
            caregiverId: '=',
            officesIds: '='
        },
        link: function (scope) {
            scope.boolOptions = [
                { value: true, label: "Yes" },
                { value: false, label: "No" }
            ];
            scope.isLoading = true;

            function init() {
                getCaregiverCityTaxCodes(scope.caregiverId).then(res => {
                    scope.payrollTax = res;
                    scope.selected = {
                        federalMaritalStatus: res.federalMaritalStatus !== null ? { id: res.federalMaritalStatus } : {},
                        stateMaritalStatus: res.stateMaritalStatus !== null ? { id: res.stateMaritalStatus } : {},
                        cityTaxCode: res.cityTaxCode !== null ? { id: res.cityTaxCode } : {}
                    }
                    scope.isLoading = false;
                }).catch(err => {
                    scope.isLoading = false;
                })
            }

            scope.cityTaxCodeDisplayName = () => {
                if (scope.selected.cityTaxCode.id !== undefined) {
                    return scope.cityTaxCodes.find(code => code.id == scope.selected.cityTaxCode.id).label;
                }

                return "";
            }

            scope.documentTypes = [
                { id: "UNTIL_2020", label: "Until 2020" },
                { id: "2020_ONWARDS", label: "2020 Onwards" }
            ]

            scope.federalUntil2020MaritalStatuses = [
                { id: 'Single', label: 'Single' },
                { id: 'Married', label: 'Married' },
                { id: 'Married, but withhold at higher Single rate', label: 'Married, but withhold at higher Single rate' }
            ];

            scope.federal2020OnwardsMaritalStatuses = [
                { id: 'Single or Married filing separately', label: 'Single or Married filing separately' },
                { id: 'Married filing jointly or Qualifying widow(er)', label: 'Married filing jointly or Qualifying widow(er)' },
                { id: 'Head of household', label: 'Head of household' }
            ];

            scope.stateMaritalStatuses = [
                { id: 'Single or Head of household', label: 'Single or Head of household' },
                { id: 'Married', label: 'Married' },
                { id: 'Married, but withhold at higher single rate', label: 'Married, but withhold at higher single rate' }
            ];

            cityTaxCodeService.getCityTaxCodes().then(res => {
                scope.cityTaxCodes = res.filter(code =>
                    code.active && code.officesIds.some(codeOfficeId =>
                        scope.officesIds.includes(codeOfficeId)
                    )
                ).map(code => ({ id: code.id, label: code.text }));
            })

            scope.updatePayrollTax = (property) => {
                createOrEditCaregiverCityTaxCode(scope.caregiverId, { documentType: scope.payrollTax.documentType, [property]: scope.payrollTax[property] }).then(res => {
                    init();
                    toaster.pop('success', 'success', "Caregiver's payroll tax updated successfully");
                }).catch(err => {
                    toaster.pop('error', "Something went wrong", "Could not update caregiver's payroll tax");
                })
            }

            scope.updateDocumentType = (documentTypeValue) => {
                createOrEditCaregiverCityTaxCode(scope.caregiverId, { documentType: documentTypeValue, federalMaritalStatus: null }).then(res => {
                    init();
                    toaster.pop('success', 'success', "Caregiver's payroll tax updated successfully");
                }).catch(err => {
                    toaster.pop('error', "Something went wrong", "Could not update caregiver's payroll tax");
                })
            }

            scope.stateMaritalStatusEvents = {
                onSelectionChanged: () => {
                    scope.payrollTax.stateMaritalStatus = scope.selected.stateMaritalStatus.id;
                    scope.updatePayrollTax('stateMaritalStatus');
                }
            }

            scope.federalMaritalStatusEvents = {
                onSelectionChanged: () => {
                    scope.payrollTax.federalMaritalStatus = scope.selected.federalMaritalStatus.id;
                    scope.updatePayrollTax('federalMaritalStatus');
                }
            }

            scope.cityTaxCodeEvents = {
                onSelectionChanged: () => {
                    scope.payrollTax.cityTaxCode = scope.selected.cityTaxCode.id;
                    scope.updatePayrollTax('cityTaxCode');
                }
            }

            scope.selectExtraSettings = {
                styleActive: true,
                singleSelection: true,
                selectionLimit: 1,
                smartButtonMaxItems: 1,
                closeOnSelect: true,
                closeOnBlur: true,
                displayProp: 'label',
                showUncheckAll: false
            }

            const getCaregiverCityTaxCodes = (caregiverId) => {
                return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${caregiverId}/payroll_tax`).then(res => {
                    return res.data;
                })
            }

            const createOrEditCaregiverCityTaxCode = (caregiverId, caregiverPayrollTaxParams) => {
                return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${caregiverId}/payroll_tax`, caregiverPayrollTaxParams);
            }

            init();
        }
    };
};