import { LocalDate, DateTimeFormatter } from "js-joda";
import _ from "lodash";

//! @ngInject
export function callsMaintenanceCtrl(
  $rootScope,
  $scope,
  $uibModal,
  ClockinClockoutService,
  NgTableParams,
  DatabaseApi,
  generalUtils,
  mfModal,
  selectionLogic,
  ngTableEventsChannel,
  toaster,
  itemSearchPageManager,
  typeOfCalls,
  officesService,
  Storage,
  $filter,
  FilterUtils,
  SweetAlert
) {
  const universalBOM = "\uFEFF";
  $scope.caregiversMap = DatabaseApi.caregivers() || {};
  $scope.patientsMap = DatabaseApi.patients() || {};

  if (!officesService.offices) {
    officesService.getOffices().then((offices) => {
      $scope.offices = offices;
    });
  } else {
    $scope.offices = officesService.offices
  }

  $scope.actions = {};
  $scope.showActions;
  $scope.tableParams = null;

  $scope.coordinators = [];
  $scope.coordinatorsDropdownOptions = {
    enableSearch: true,
    displayProp: "displayName",
    styleActive: true,
    scrollable: true
  };

  function initialize() {
    initTableColumns();
    initPageManager();
    initCoordinators();

    if (Object.keys($scope.caregiversMap).length === 0 ||
      Object.keys($scope.patientsMap).length === 0) {
      return;
    }

    initFilters();

    $scope.loadItems();
  }

  $scope.$on("$destroy", function () {
    const filters = $scope.pageManager.searchParams;

    if (typeOfCalls === "unlinked") {
      FilterUtils.rootFilters.unlinkedCallsMaintenance = angular.copy(filters);
    }

    if (typeOfCalls === "rejected") {
      FilterUtils.rootFilters.rejectedCallsMaintenance = angular.copy(filters);
    }

    FilterUtils.rootFilters.callsMaintenanceTableParams = angular.copy($scope.tableParams);
  });

  const initTableColumns = () => {
    const columns = Storage.getObject("evvCallsTableSettings");
    if (columns && Object.keys(columns).length) {
      $scope.tableColumns = columns;
    } else {
      $scope.tableColumns = {
        "ID": true,
        "Patient": true,
        "Patient Address": false,
        "Patient Display ID": false,
        "Patient Office": false,
        "Caregiver": true,
        "Caregiver Address": false,
        "Caregiver Display ID": false,
        "Caregiver Certifications": false,
        "Caregiver Phone": true,
        "Call Date": true,
        "Call Time": true,
        "Call Type": true,
        "Source Phone": true,
        "Status": true
      };
    }

    $scope.$watch(
      "tableColumns",
      () => {
        if ($scope.tableColumns) {
          Storage.setObject("evvCallsTableSettings", $scope.tableColumns);
          $scope.tableColumnsLength = 0;
          Object.keys($scope.tableColumns).forEach(function (t) {
            if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
          });
        }
      },
      true
    );
}

  const initCoordinators = async () => {
    await DatabaseApi.loadAgencyMembers();
    const agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole();
    $scope.coordinators = [
      ...agencyMembersByRoles.coordinatorsSeniors,
      ...agencyMembersByRoles.coordinators,
      ...agencyMembersByRoles.admins,
    ].filter(coordinator => coordinator.hasCasesNotDischarged && coordinator.status === "Active").sort(generalUtils.sortByDisplayName);
  };

  const initFilters = () => {
    let storageFilters;
    if (typeOfCalls === "unlinked") {
      storageFilters = FilterUtils.rootFilters.unlinkedCallsMaintenance;
    }

    if (typeOfCalls === "rejected") {
      storageFilters = FilterUtils.rootFilters.rejectedCallsMaintenance;
    }

    if (storageFilters !== undefined) {
      $scope.pageManager.setSearchParams(storageFilters);
    }

    $scope.cachedTableParams = FilterUtils.rootFilters.callsMaintenanceTableParams;
  };

  const initSelection = function (items) {
    $scope.selectionLogic = selectionLogic.createNewVirtualizedLogic();
    $scope.selectionLogic.initItemIdsCollection(items.map((item) => item.id));
  };

  ngTableEventsChannel.onPagesChanged(() => {
    $scope.selectionLogic.updateItemsToRender($scope.tableParams.data);
  }, $scope);

  const initPageManager = function () {
    $scope.pageManager = itemSearchPageManager.createSearchPageManager(
      "/clockin_clockout_records"
    );

    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
    $scope.teamDataManager = $scope.pageManager.getTeamDataManager();

    $scope.pageManager.initFromToDateParams();
    $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().minusDays(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));

    $scope.initialClockRecords = [];
    $scope.filteredClockRecords = [];
    
    $scope.filters = {
      offices: [],
      coordinators: [],
      teams: []
    };

    if (typeOfCalls === "unlinked") {
      $scope.actions = { link: true, reject: true };
      $scope.pageManager.initSearchParam("unlinkedOnly", true);
    }

    if (typeOfCalls === "rejected") {
      $scope.actions = { restore: true };
      $scope.pageManager.initSearchParam("rejectedOnly", true);
    }

    $scope.showActions = Object.keys($scope.actions).length > 0;
  };

  function populateTable(items) {
    const oldTotal = $scope.tableParams?.total?.() || 0;
    let sorting = { createdAt: "desc" };
    let page = false;
    let count = 25;
    if ($scope.cachedTableParams) {
      sorting = $scope.cachedTableParams.sorting();
      count = $scope.cachedTableParams.count();
    }
    if ($scope.tableParams) {
      sorting = $scope.tableParams.sorting();
      count = $scope.tableParams.count();
      page = $scope.tableParams.page();
    }
    const options = {
      count: count,
      sorting: sorting,
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
    if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    if ($scope.globalFilter) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
    initSelection(items);
  }

  $scope.loadItems = function () {
    $scope.pageManager
      .executeSearch()
      .then(onSuccessLoadItems)
      .catch(onErrorLoadItems);
  };

  $scope.linkCall = function (selectedRecord) {
    const bulkLinks = $scope.filteredClockRecords.filter(recordInTable => isRecordSameAsSelected(recordInTable, selectedRecord));

    let modal;

    if (bulkLinks.length > 1 && selectedRecord.potentialLinks.length === 1) {
      modal = $uibModal.open({
        templateUrl: "admin/views/clockin-clockout-bulk-link-modal.html",
        size: "lg",
        controller: "clockinClockoutBulkLinkModalCtrl",
        resolve: {
          tableData: () => bulkLinks,
          tableColumns: () => $scope.tableColumns,
          clockinClockoutRecord: () => selectedRecord
        }
      });

    } else {
      modal = $uibModal
        .open({
          templateUrl: "admin/views/clockin-clockout-link-call-modal.html",
          size: "md",
          controller: "clockinClockoutLinkCallModalCtrl",
          windowClass: "clockin-clockout-modal-window",
          resolve: {
            clockinClockoutRecord: () => selectedRecord
          }
        });
    }

    modal.result.then((response) => {
      switch (response.type) {
        case "success":
          $scope.initialClockRecords = $scope.initialClockRecords.filter(item => item !== selectedRecord);

          if (response.singleLinkedCall) {
            $scope.filteredClockRecords = ($scope.filteredClockRecords || $scope.initialClockRecords).filter(item => item !== selectedRecord);
            $scope.filteredClockRecords.forEach(item => item.potentialLinks =
              item.potentialLinks.filter(link => link.visitInstance.id !== response.singleLinkedCall.visitInstance.id));
          } else if (response.linkedCalls) {
            $scope.filteredClockRecords =
              ($scope.filteredClockRecords || $scope.initialClockRecords).filter(item => !response.linkedCalls.includes(item));
          }

          populateTable($scope.filteredClockRecords);
          break;
        case "close":
        case "fail":
        default:
      }
    });
  };

  function isRecordSameAsSelected(recordInTable, selectedRecord) {
    return selectedRecord.patient.id === recordInTable.patient.id && selectedRecord.patient.id !== null &&
      selectedRecord.caregiver.id === recordInTable.caregiver.id && selectedRecord.caregiver.id !== null &&
      selectedRecord.sourcePhone === recordInTable.sourcePhone && selectedRecord.sourcePhone !== null &&
      recordInTable.potentialLinks.length === 1;
  }

  $scope.rejectCall = (record) => {
    const modal = mfModal.create({
      subject: "Reject Call",
      variant: "warning",
      message: "Are you sure you want to reject this call?",
      hideCancelButton: false,
      confirmLabel: "Yes, reject",
      onConfirm: () => sendRejectCallRequest(record),
      onComplete: () => modal.close()
    });
  };

  $scope.promptRestoreCallsModal = (selectedItemsIds) => {
    const modal = mfModal.create({
      subject: "Restore Calls",
      variant: "info",
      message: "Are you sure you want to restore these calls?",
      hideCancelButton: false,
      confirmLabel: "Yes, restore",
      onConfirm: () => sendRestoreCallRequest(selectedItemsIds),
      onComplete: () => modal.close()
    });
  };

  $scope.restoreSingleCall = (row) => {
    const modal = mfModal.create({
      subject: "Restore Call",
      variant: "info",
      message: "Are you sure you want to restore this call?",
      hideCancelButton: false,
      confirmLabel: "Yes, restore",
      onConfirm: () => sendRestoreCallRequest([row.id]),
      onComplete: () => modal.close()
    });
  };

  $scope.handleChangeCallType = (call) => {
    const oppositeCallType = call.callType === "IN" ? "OUT" : "IN";
    const modal = mfModal.create({
      subject: "Change call type",
      variant: "info",
      message: `Are you sure you want to change the call type from ${oppositeCallType} to ${call.callType}?`,
      hideCancelButton: false,
      confirmLabel: "Yes, change",
      onConfirm: () => sendUpdateCallType(call),
      onComplete: () => modal.close(),
      onCancel: () => {
        call.callType = oppositeCallType;
      },
    });
  };

  $scope.exportTable = function () {
    $scope.loadingCSV = true;
    const rows = [];
    const titles = [];

    Object.keys($scope.tableColumns).forEach(column => {
      if ($scope.tableColumns[column] === true) {
        titles.push(column);
      }
    });

    rows.push(titles);

    $scope.tableParams.data.forEach(dataRow => {
      const csvRow = [];
      const sourcePhone = dataRow.sourcePhone === 'Anonymous' ? 'Anonymous' : $filter("americanphone")(dataRow.sourcePhone);
      const patientAddress = dataRow.patient.address ? '"' + dataRow.patient.address + '"' : '';

      titles.forEach(function (title) {
        if (title === 'ID') csvRow.push(dataRow.id || '');
        else if (title === 'Patient') csvRow.push(dataRow.patient.fullName || '');
        else if (title === 'Patient Address') csvRow.push(patientAddress)
        else if (title === 'Patient Display ID') csvRow.push(dataRow.patient.displayId || '');
        else if (title === 'Patient Office') csvRow.push(dataRow.patient.currentOfficeName || '');
        else if (title === 'Caregiver') csvRow.push(dataRow.caregiver.fullName || '');
        else if (title === 'Caregiver Address') csvRow.push('"' + dataRow.caregiver.address + '"' || '');
        else if (title === 'Caregiver Display ID') csvRow.push(dataRow.caregiver.displayId || '');
        else if (title === 'Caregiver Certifications') csvRow.push('"' + dataRow.caregiver.certifications.join(', ') + '"' || '');
        else if (title === 'Caregiver Phone') csvRow.push($filter("americanphone")(dataRow.caregiver.phoneNumber) || '');
        else if (title === 'Call Date') csvRow.push($filter("dateStringToLocaleDate")(dataRow.createdAt) || '');
        else if (title === 'Call Time') csvRow.push($filter("mfShortTime")(dataRow.createdAt) || '');
        else if (title === 'Call Type') csvRow.push(dataRow.callType || '');
        else if (title === 'Source Phone') csvRow.push(sourcePhone);
        else if (title === 'Status') csvRow.push(dataRow.status || '');
      });

      rows.push(csvRow);
    });

    let csvContent = "";
    rows.forEach(function (rowArray) {
        const row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", getExportedFileName());
    document.body.appendChild(link);

    link.click();
    $scope.loadingCSV = false;
};

  const getExportedFileName = () => {
    const fromDate = $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd");
    const toDate = $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd");
    return `medflyt-evv-${typeOfCalls}-calls-${fromDate}-to-${toDate}.csv`;
  };

  function sendUpdateCallType(call) {
    return ClockinClockoutService.update(call.id, {
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId,
      data: {
        callType: call.callType
      }
    })
    .then(onSuccessUpdateCallType)
    .catch(onFailUpdateCallType)
    .finally(() => ($scope.isProcessing = false));
  }

  function sendRejectCallRequest(record) {
    return ClockinClockoutService.rejectCalls({
      records: [record.id],
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    })
      .then(() => onSuccessReject(record))
      .catch(onFailReject)
      .finally(() => ($scope.isProcessing = false));
  }

  function sendRestoreCallRequest(reocordIds) {
    return ClockinClockoutService.restoreCalls({
      records: reocordIds,
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    })
      .then(onSuccessRestore)
      .catch(onFailRestore)
      .finally(() => {
        $scope.isProcessing = false;
      });
  }

  function onSuccessLoadItems(response) {
    $scope.initialClockRecords = response.data.records.map(parseRecord.bind(this));
    $scope.onFiltersChange();
  }

  function onErrorLoadItems(error) {
    toaster.pop("error", "Failed to load calls for maintenance");

    throw error;
  }
  
  function onSuccessUpdateCallType(response) {
    toaster.pop("success", "Success", "Call type has been successfully updated");
    $scope.loadItems();
  }

  function onFailUpdateCallType(error) {
    toaster.pop("error", "Something went wrong", "Could not update call type");
  }

  function onSuccessReject(record) {
    toaster.pop("success", "Success", "Call has been successfully rejected");
    $scope.initialClockRecords = $scope.initialClockRecords.filter(item => item !== record);
    $scope.filteredClockRecords = ($scope.filteredClockRecords || $scope.initialClockRecords).filter(item => item !== record);
    populateTable($scope.filteredClockRecords);
  }

  function onFailReject(error) {
    toaster.pop("error", "Something went wrong", "Could not reject call");
  }

  function onSuccessRestore(response) {
    toaster.pop("success", "Success", "Calls have been successfully restored");
    $scope.loadItems();
  }

  function onFailRestore(error) {
    toaster.pop("error", "Something went wrong", "Could not restore call");
  }

  function parseRecord(record) {
    record.caregiver.fullName = getFullName(record.caregiver);

    const caregiver = DatabaseApi.getCaregiverById(record.caregiver.id);
    if (caregiver) {
      record.caregiver.displayId = caregiver.displayId;
      record.caregiver.address = caregiver.address;
      record.caregiver.certifications = caregiver.certifications;
    }

    if (record.patient) {
      record.patient.fullName = getFullName(record.patient);

      const patient = DatabaseApi.getPatientById(record.patient.id);
      if (patient) {
        record.patient.displayId = patient.displayId;
        record.patient.address = patient.address ? patient.address.text : null;

        const patientCurrentOffice = $scope.offices.find(office => office.id === patient.currentOfficeId);
        if (patientCurrentOffice) {
          record.patient.currentOfficeName = patientCurrentOffice.name;
        }
      }
    }

    record.status = ClockinClockoutService.formatReason({ record, reason: record.unlinkedReason });

    return record;
  }

  function getFullName({ firstName, middleName, lastName }) {
    return [firstName, middleName, lastName].filter(namePart => namePart !== null).join(' ');
  }

  initialize();

  $scope.onFiltersChange = function () {

    $scope.filteredClockRecords = Array.from($scope.initialClockRecords);
    
    // Offices
    if ($scope.filters.offices.length) {
      const selectedOfficeIds = $scope.filters.offices.map(
        (office) => office.id
      );
      $scope.filteredClockRecords = $scope.filteredClockRecords.filter(
        (clockRecord) => {
          let relevantOfficeIds = [];

          const caregiver = DatabaseApi.getCaregiverById(clockRecord.caregiver.id);
          if (caregiver) {
            relevantOfficeIds.push(...caregiver.officeIds);
          }

          const patient = DatabaseApi.getPatientById(clockRecord.patient.id);
          if (patient) {
            relevantOfficeIds.push(patient.currentOfficeId);
          }

          return relevantOfficeIds.some(relevantOfficeId => selectedOfficeIds.includes(relevantOfficeId));
        }
      );
    }

    // Coordinators
    if ($scope.filters.coordinators.length) {
      const selectedCoordinatorIds = $scope.filters.coordinators.map(
        (coordinator) => coordinator.id
      );
      $scope.filteredClockRecords = $scope.filteredClockRecords.filter(
        (clockRecord) => {
          const patientIds = (() => {
            if (clockRecord.patient.id) {
                return [clockRecord.patient.id]
            }

            return clockRecord.potentialLinks.map(x => x.patient.id)
          })();

          const patients = patientIds.map(DatabaseApi.getPatientById);

          return patients.some(patient => patient.assignedCoordinator && selectedCoordinatorIds.includes(patient.assignedCoordinator));
        }
      );
    }

    // Teams
    if ($scope.filters.teams.length) {
      const selectedTeamIds = $scope.filters.teams.map((team) => team.id);
      $scope.filteredClockRecords = $scope.filteredClockRecords.filter(
        (clockRecord) => {
          if (!clockRecord.patient.id) {
            return false;
          }
          const patient = DatabaseApi.getPatientById(clockRecord.patient.id);
          return selectedTeamIds.indexOf(patient.agencyTeamId) !== -1
        }
      );
    }

    populateTable($scope.filteredClockRecords);
  }

  $scope.promptUnlinkPatient = (row) => {
    const modal = mfModal.create({
      subject: "Unlink patient",
      variant: "warning",
      message: `Are you sure you want to unlink ${getFullName(row.patient)} from this call? this action is irreversible.`,
      hideCancelButton: false,
      confirmLabel: "Yes, unlink",
      onConfirm: () => unlinkPatientFromClockRecord(row.id),
      onComplete: () => modal.close()
    });
  };

  const unlinkPatientFromClockRecord = (clockinClockoutRecordId) => {
    const url = `agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/:clockinClockoutRecordId/unlink_patient`
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":clockinClockoutRecordId", clockinClockoutRecordId);

    return DatabaseApi.patch(url)
        .then(() => {
            SweetAlert.swal("The patient has been successfully unlinked");
            $scope.loadItems();
        })
        .catch(() => SweetAlert.swal("Failed to unlink the patient"))
  }

  $scope.multiSelectEvents = {
      onSelectionChanged() {
          $scope.onFiltersChange();
      }
  };

  $scope.applyGlobalSearch = async function () {
    const filter = { $: $scope.globalFilter.val };
    if ($scope.tableParams) {
      await angular.extend($scope.tableParams.filter(), filter);
    }
    $scope.selectionLogic.updateItemsToRender($scope.tableParams.data);
  };

  $rootScope.$on("got_caregivers_data", function () {
    $scope.caregiversMap = DatabaseApi.caregivers();

    if (Object.keys($scope.caregiversMap).length > 0 &&
      Object.keys($scope.patientsMap).length > 0) {
        initialize();
    }
  });

  $rootScope.$on("got_patients_data", function () {
    $scope.patientsMap = DatabaseApi.patients();
  
    if (Object.keys($scope.caregiversMap).length > 0 &&
      Object.keys($scope.patientsMap).length > 0) {
      initialize();
    }
  });
};

export function uniquePotentialLink() {
    return input => _.uniqBy(input, x => x.patient.id)
}

export function humanizedDuration() {
    return input => {
        if (input * 60 < 1) {
            return "Less than 1 min";
        }

        if (input < 1) {
            return `${(input * 60).toFixed(0)} min`;
        }

        return `${input.toFixed(0)} hr`
    }
}