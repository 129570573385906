//! @ngInject
export function timesheetExportChooseCaregiversModalCtrl(
  $scope,
  DatabaseApi,
  $uibModalInstance,
  mfModal,
) {
  const initialize = () => {
    $scope.chooseType = null;
    $scope.caregiverIds = [];
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.caregiversArr = Object.values($scope.caregiversMap);
    $scope.caregiversOptions = [];
    if ($scope.$resolve.caregiverIds) {
      $scope.caregiversOptions = $scope.caregiversArr.filter(caregiver => 
        $scope.$resolve.caregiverIds.includes(caregiver.id)
      );
    }
  };

  $scope.handleCaregiverSelection = (caregivers) => {
    $scope.caregiverIds = caregivers.map(caregiver => caregiver.id);
  };

  $scope.onSubmit = () => {
    if ($scope.chooseType === 'All') {
      $scope.closeModal({
        chooseType: "ALL_CAREGIVERS",
        caregiverIds: []
      });
    } else if ($scope.chooseType === 'Specific') {
      $scope.closeModal({
        chooseType: "SPECIFIC",
        caregiverIds: $scope.caregiverIds
      });
    } else {
      mfModal.createSimple({
        variant: "danger",
        subject: "Oops...",
        message: "Please choose specific caregivers or all and try again"
      });
    }
  };

  $scope.onCancel = () => {
    $scope.closeModal("CANCEL");
  };

  $scope.closeModal = (data = "ok") => {
    $uibModalInstance.close(data);
  };

  initialize();
}
