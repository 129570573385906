import { DownloadIcon } from "@chakra-ui/icons";
import {
  ComponentWithAs,
  Flex,
  IconButton,
  Image,
  ImageProps,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useDownloadImage } from "../hooks/useDownloadImage";

const ImageModal = (props: {
  src: string;
  width: number;
  height: number;
  element?: React.ElementType;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ImageComponent = (props.element ?? Image) as ComponentWithAs<"img", ImageProps>;
  const { download } = useDownloadImage();

  return (
    <Flex alignItems="center" justifyContent="center">
      <ImageComponent
        src={props.src}
        width={props.width}
        height={props.height}
        onClick={onOpen}
        cursor="pointer"
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="5xl">
        <ModalOverlay bgColor="whiteAlpha.800" />
        <ModalContent bg="transparent" shadow="none" w="fit-content">
          <Flex direction="column" align="center" gap={8}>
            <ImageComponent src={props.src} maxH="80vh" h="100%" />
            <IconButton
              icon={<DownloadIcon fontSize="xl" />}
              aria-label="download"
              colorScheme="blackAlpha"
              variant="outline"
              size="lg"
              rounded="full"
              onClick={() => download(props.src)}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ImageModal;
