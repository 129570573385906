import { DayOfWeek, Instant, LocalDate } from "js-joda";
import { Rawify } from "../@ts-utils/rawify";
import { PatientPlansOfCareDutySheetsResponse } from "../messages/duty_sheet";
import { PatientId, PlanOfCareItemId, VisitInstanceId } from "../messages/ids";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export class DutySheetService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  /**
   * Get patient duty sheets for week
   */
  async getByPatient(params: {
    patientId: PatientId;
    startOfWeek: LocalDate;
  }): Promise<PatientPlansOfCareDutySheetsResponse> {
    const url = this.endpoint({
      path: "agencies/:agencyId/patients/:patientId/duty_sheets",
      params: {
        agencyId: this.$rootScope.agencyId,
        patientId: params.patientId,
      },
      queries: {
        startOfWeek: params.startOfWeek.toString(),
      },
    });

    return this.api.get<Rawify<PatientPlansOfCareDutySheetsResponse>>(url).then(
      ({ data }): PatientPlansOfCareDutySheetsResponse => ({
        hasPlanOfCare: data.hasPlanOfCare,
        rows: (data.rows ?? []).map((row) => ({
          planOfCare: {
            id: row.planOfCare.id,
            planOfCareTypeId: row.planOfCare.planOfCareTypeId,
            startDate: row.planOfCare.startDate ? LocalDate.parse(row.planOfCare.startDate) : null,
            endDate: row.planOfCare.endDate ? LocalDate.parse(row.planOfCare.endDate) : null,
            submittedAt: Instant.parse(row.planOfCare.submittedAt),
            duties: row.planOfCare.duties.map((duty) => ({
              id: duty.id,
              period: duty.period,
              days: duty.days?.map(DayOfWeek.valueOf) ?? null,
              frequency: duty.frequency,
              visitsPerWeek: duty.visitsPerWeek,
              item: duty.item,
            })),
          },
          dutySheets: row.dutySheets.map((dutySheet) => ({
            ...dutySheet,
            visitInstanceStartDate: LocalDate.parse(dutySheet.visitInstanceStartDate),
          })),
        })),
      })
    );
  }

  async answer(params: {
    visitInstanceId: VisitInstanceId;
    planOfCareItemId: PlanOfCareItemId;
    notes?: string | null;
    completed: boolean;
  }) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/duty_sheets/answer",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        visitInstanceId: params.visitInstanceId,
      },
    });

    return this.api
      .post(url, {
        dutyId: params.planOfCareItemId,
        notes: params.notes,
        completed: params.completed,
      })
      .then(() => void 0);
  }

  async submitNote(params: {
    visitInstanceId: VisitInstanceId;
    planOfCareItemId: PlanOfCareItemId;
    notes: string | null;
  }) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/duty_sheets/submit_note",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        visitInstanceId: params.visitInstanceId,
      },
    });

    return this.api
      .post(url, {
        dutyId: params.planOfCareItemId,
        notes: params.notes,
      })
      .then(() => void 0);
  }
}
