import moment, { Moment } from "moment";
import { PatientStatusUtils } from "../consts/commonConsts";
import { DateRangeDefaultOptions } from "../consts/dateRangeDefaultOptions";
import { DateUtils } from "../consts/dateUtils";
import { PatientId } from "../messages/ids";
import { ContractTypeService } from "../services/contractTypeService";
import { CsvExportUtil } from "../services/csvExportUtil";
import { CreateDropdownFilter, DropdownEntity, DropdownFilter } from "../services/dropdownFilter";
import { EligibilityChecksService, EnrichedEligibiltyCheck } from "../services/eligibilityChecksService";
import { OfficesService } from "../services/officesService";

const eligibilityTableSettings: NgTable.IParamValues<EnrichedEligibiltyCheck> = {
  count: 25,
  sorting: {
    "createdAt": "desc",
  },
};

  enum IssuesKnownFilters {
    eligibile = 'Eligible',
    not_eligibile = 'Not eligible'
  }

  interface EligibilityIssue {
    name:string,
    counter: number
  }
 interface Filters {
    freeText: "";
    runDate: Moment | null
    dropdowns: DropdownEntity<EnrichedEligibiltyCheck>[];
    issues: {[key: string]: boolean}
  }

//! @ngInject
 class EligibilityChecksCtrl implements ng.IComponentController{
    private eligibilityChecks: EnrichedEligibiltyCheck[] = [];
    private dateRangeOptions: DateRangeDefaultOptions;
    table: NgTableParams<EnrichedEligibiltyCheck>;
    issuesTypes: EligibilityIssue[] = [];
    isLoading = false;
    filters: Filters = {
        freeText: "",
        runDate: null,
        dropdowns: [],
        issues: {}
      };

      dropdownFilterExtraSettings = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
    };

    dropdownFilterEvents = {};
    dropdownFilterManager!: DropdownFilter<EnrichedEligibiltyCheck>;
   constructor(
    public patientStatusUtils: PatientStatusUtils,
    private officesService: OfficesService,
    private contractTypeService: ContractTypeService,
    private eligibilityChecksService: EligibilityChecksService,
    private NgTableParams: NgTable.ITableParamsConstructor<EnrichedEligibiltyCheck>,
    private $rootScope: angular.IRootScopeService,
    private createDropdownFilter: CreateDropdownFilter,
    private dateRangeDefaultOptions: DateRangeDefaultOptions,
    private toaster: toaster.IToasterService,
    private dateUtils: DateUtils,
    private $timeout: ng.ITimeoutService,
    private $filter: ng.FilterFactory,
    private csvExportUtil: CsvExportUtil,
    private $uibModal: any
   ) {
    this.table = new this.NgTableParams(eligibilityTableSettings, { dataset: [] });
    this.dropdownFilterEvents = {
      onSelectionChanged: () => this.onFilterChange(),
    };
    this.dateRangeOptions = {
      ...this.dateRangeDefaultOptions,
      eventHandlers: {
        "apply.daterangepicker": () => this.loadTable(),
      },
    };
   }

   async $onInit() {
      await this.initFilters();
      this.loadTable();
      this.$rootScope.$on("got_patients_data", () => {
        this.loadTable()
       });
   }

  async runEligibiltyCheckForPatient (patientId: PatientId) {
    await this.eligibilityChecksService.runEligibilityCheck(patientId);
    this.loadTable();
  }

  openFileRecordModal(vendorResponse: any){
    const content = {content: vendorResponse}
    this.$uibModal.open({
        templateUrl: 'admin/views/hha-integration/integration-file-record-modal.html',
        size: 'md',
        controller: 'integrationFileRecordModalCtrl',
        resolve: {
            record: () => content,
            hideEmptyFields: () => true
        },
        backdropClass: 'transparent-backdrop',
        windowClass: "modal modal-slide-in-right uib-side-modal uib-side-modal-wide integration-file-record-side-modal"
    });
}


  getEligibilityStatusText(isEligible: boolean | null) {
    return this.eligibilityChecksService.getEligibilityStatusText(isEligible)
  }

  getEligibilityStatusColor(isEligible: boolean | null) {
    return this.eligibilityChecksService.getEligibilityStatusColor(isEligible)
  }

  onFilterChange() {
    this.setTable(this.eligibilityChecks);
  }

  openPatientModal(patientId: PatientId) {
    this.$rootScope.openPatientModal(patientId);
  }

  async loadTable(){
    this.isLoading = true;
    let runDateFilter;
    if (this.filters.runDate !== null){
      runDateFilter = this.dateUtils.ymdStringToLocalDate(
        moment(this.filters.runDate).format("YYYY-MM-DD"))
    }
    try{
      const eligibilityChecks = await this.eligibilityChecksService.getEligibiltyChecks(runDateFilter)
      this.eligibilityChecks = eligibilityChecks;
      this.setTable(eligibilityChecks);
    }
    catch(e){
      console.error(e)
      this.toaster.pop("error", "Something went wrong", "Could not get patients eligibility checks");
    }
    finally{
      this.$timeout(() => this.isLoading = false, 0)
    }
  }

  exportToCsv(){
    interface EligibilityCsvRow {
      'patientDisplayName': string,
      'patientDisplayId': string,
      'patientStatus': string,
      'patientOfficeName': string,
      'eligibilityStatus': string,
      'createdAt': string,
      'createdByName': string,
      'message': string,
      'responsePayerName': string,
      'patientActiveContractsNames': string,
      'vendorResponse': string
    }
    const csvRows : EligibilityCsvRow[] = [];
    const titles:{[key: string] : keyof EligibilityCsvRow} = {
     'Patient': 'patientDisplayName',
     'Patient ID': 'patientDisplayId',
     'Patient Status': 'patientStatus',
     'Office': 'patientOfficeName',
     'Eligibility Status': 'eligibilityStatus',
     'Execution Time': 'createdAt',
     'Executed By': 'createdByName',
     'Error Message': 'message',
     'Response Payer': 'responsePayerName',
     'Patient Active Contract': 'patientActiveContractsNames',
     'Vendor Response': 'vendorResponse'
   }
   this.filterEligibiltyChecks(this.eligibilityChecks).forEach(dataRow => {
        csvRows.push({
          patientDisplayName: dataRow.patientDisplayName,
          patientDisplayId: (dataRow.patientDisplayId ?? '').toString(),
          patientStatus: dataRow.patientStatus,
          patientOfficeName: dataRow.patientOfficeName,
          eligibilityStatus: this.getEligibilityStatusText(dataRow.isEligible),
          createdAt: this.$filter("instantStringToLocaleDateTime")(dataRow.createdAt),
          createdByName: dataRow.createdByName ?? '',
          message: (dataRow.message ?? ''),
          responsePayerName: (dataRow.responsePayerName ?? ''),
          patientActiveContractsNames: (dataRow.patientActiveContractsNames ? dataRow.patientActiveContractsNames.join(', ') : ''),
          vendorResponse: dataRow.responseMessage? dataRow.responseMessage.replace(/(\r\n|\n+|\r|\s+|\t|&nbsp;)/gm,' ') : ''
        });
    });

    this.csvExportUtil.exportCsv<EligibilityCsvRow>(titles, csvRows);
  }

  private async initFilters(){
    this.filters = {
      freeText: "",
      runDate: null,
      dropdowns: [],
      issues: {}
    };

    const offices = await this.officesService.getOffices({onlyActives: true, refetch:false});
    this.filters.dropdowns.push( {
      title: "Offices",
      entityPath: ['patientOfficeId'],
      options: offices.map(office => ({id: office.id, label: office.name})),
      values: [],
    });

    const contracts = await this.contractTypeService.getContractTypes({onlyActives: true, refetch:false});
    this.filters.dropdowns.push(  {
      title: "Contracts",
      entityPath: ['activeContractTypes'],
      options: contracts.map(contract => ({id: contract.id, label: contract.name})),
      values: [],
    });

    this.dropdownFilterManager = this.createDropdownFilter<EnrichedEligibiltyCheck>({
      dropdowns: this.filters.dropdowns,
    });
  }

  private setIssuesTypes(eligibilityChecks: EnrichedEligibiltyCheck[]){
    const issuesCounter = new Map<string, number>();
      eligibilityChecks.forEach(record => {
         if (record.isEligible && !record.isEligibleOnDifferentPayer){
           let count = issuesCounter.get(IssuesKnownFilters.eligibile);
           count = (count === undefined) ? 1 : count + 1;
          issuesCounter.set(IssuesKnownFilters.eligibile, count);
         }

         if (record.isEligible === false){
          let count = issuesCounter.get(IssuesKnownFilters.not_eligibile);
          count = (count === undefined) ? 1 : count + 1;
          issuesCounter.set(IssuesKnownFilters.not_eligibile, count);
        }

        if (record.message !== null){
          let count = issuesCounter.get(record.message);
          count = (count === undefined) ? 1 : count + 1;
          issuesCounter.set(record.message, count);
        }
      });


      const oldIssues = [...this.issuesTypes];
      this.issuesTypes = [];
      const newIssues = [...issuesCounter.entries()].sort();
      for (const [key, value] of newIssues) {
        this.issuesTypes.push({name: key, counter: value})
      }

      oldIssues.forEach(oldIssue => {
        if (this.issuesTypes.find(issue => issue.name === oldIssue.name) === undefined){
          this.issuesTypes.push({name: oldIssue.name, counter: 0})
        }
      })
  }

  private setTable(eligibilityChecks: EnrichedEligibiltyCheck[]) {
    const filteredEligibilityChecksWithoutIssuesFilter = this.filterEligibiltyChecksByFilterInputs(eligibilityChecks);
    this.setIssuesTypes(filteredEligibilityChecksWithoutIssuesFilter);
    const filteredEligibilityChecks = this.filterEligibiltyChecksByIssues(filteredEligibilityChecksWithoutIssuesFilter);
    this.table = new this.NgTableParams(eligibilityTableSettings, { dataset: filteredEligibilityChecks });
  }

  private filterEligibiltyChecks(eligibilityChecks: EnrichedEligibiltyCheck[]) {
    const filteredEligibilityChecksWithoutIssuesFilter = this.filterEligibiltyChecksByFilterInputs(eligibilityChecks);
    return this.filterEligibiltyChecksByIssues(filteredEligibilityChecksWithoutIssuesFilter);
  }

  private filterEligibiltyChecksByIssues(eligibilityChecks: EnrichedEligibiltyCheck[]) {
    return eligibilityChecks.filter((record) => {
      return (
      ((Object.values(this.filters.issues).filter(issue => issue).length === 0) ||
            ((this.filters.issues[IssuesKnownFilters.eligibile] && record.isEligible === true && !record.isEligibleOnDifferentPayer) ||
             (this.filters.issues[IssuesKnownFilters.not_eligibile] && record.isEligible === false) ||
             (record.message !== null && this.filters.issues[record.message])))
      );
    });
  }

  private filterEligibiltyChecksByFilterInputs(eligibilityChecks: EnrichedEligibiltyCheck[]) {
    const query = this.filters.freeText.toLowerCase();
    return eligibilityChecks.filter((record) => {
      return (
        this.dropdownFilterManager.filter(record)
        && this.filterEligibilityChecksByFreeText(record, query)
      );
    });
  }

  private filterEligibilityChecksByFreeText(eligibilityCheck: EnrichedEligibiltyCheck, lowerCaseQuery: string) {
    return (
      lowerCaseQuery === "" ||
      eligibilityCheck.patientDisplayName.toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.patientDisplayId?.toString().toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.responsePayerName?.toString().toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.patientActiveContractsNames?.toString().toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.patientOfficeName?.toString().toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.message?.toString().toLowerCase().includes(lowerCaseQuery) ||
      eligibilityCheck.createdByName?.toString().toLowerCase().includes(lowerCaseQuery)
    );
  }
}

type EligibilityChecksPageComponentOptions = angular.IComponentOptions

export const EligibilityChecksPage: EligibilityChecksPageComponentOptions = {
  controller: EligibilityChecksCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/eligibility-checks.html"
};
