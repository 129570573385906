import * as Ajv from "ajv";
import { createAjvDraft07Compat } from "./ajv-compat";
import { Reviver, revivers } from "./schema-revivers";

const validators = new Map<string, Ajv.ValidateFunction>();

const ajv = createAjvDraft07Compat({
  removeAdditional: true,
});

/**
 * See this for an explanation:
 * <https://github.com/YousefED/typescript-json-schema/issues/98#issuecomment-349733289>
 */
const validateFn = (
  keywordValue: string,
  data: any,
  _parentSchema?: object,
  dataPath?: string,
  parentData?: object | any[],
  parentDataProperty?: string | number,
  _rootData?: object | any[]
): boolean => {
  const setErrorMessage = (msg: string): void => {
    (<any>validateFn).errors = [
      {
        keyword: "xreviver",
        dataPath: "" + dataPath,
        schemaPath: "", // This field appears to be ignored
        params: {
          keyword: "xreviver",
        },
        message: msg,
        data: data,
      },
    ];
  };

  if (typeof data === "string") {
    const reviverFn: Reviver | undefined = <any>revivers[keywordValue];
    if (reviverFn === undefined) {
      setErrorMessage(`Unknown xreviver function: "${keywordValue}"`);
      return false;
    }

    let parsed: any;
    try {
      parsed = reviverFn(data);
    } catch (e: any) {
      // Take only the first line, because the rest may contain junk (a stack trace)
      const parseError = e.message.split("\n")[0];

      setErrorMessage(`${keywordValue}: ${parseError}`);
      return false;
    }
    if (parentData !== undefined && parentDataProperty !== undefined) {
      (<any>parentData)[parentDataProperty] = parsed;
    }
  }
  return true;
};

ajv.addKeyword("xreviver", {
  modifying: true,
  validate: validateFn,
});

let wereMessagesInitialized = false;

async function initMessages() {
  if (wereMessagesInitialized) {
    return;
  }

  const { messages, pathsToMessage } = await import("./messages.json");

  Object.entries(pathsToMessage).forEach(([path, message]) => {
    const validator = ajv.compile((messages as any)[message]);
    validators.set(path, validator);
  });

  wereMessagesInitialized = true;
}

export type Path = string;

export async function checkPathHasValidator(method: string, path: Path): Promise<boolean> {
  await initMessages();
  return validators.has(`${method} ${path}`);
}

export async function getPathValidator(method: string, path: Path): Promise<Ajv.ValidateFunction> {
  await initMessages();
  const lookupResult = validators.get(`${method} ${path}`);

  if (lookupResult === undefined) {
    throw new Error(`path "${path}" does not have a validator`);
  }

  return lookupResult;
}
