//! @ngInject
export class CsvExportUtil{
    exportCsv<T>(headers: {[key: string] : keyof T}, data: T[]){
        const rows: string[][] = [];
        rows.push(Object.keys(headers));
        data.forEach(record => {
            const csvRow: string[] = [];
            Object.values(headers).forEach(title => {
                csvRow.push(`"${record[title]}"`.replaceAll('#', ''));
            });

            rows.push(csvRow);
        });
        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const rowText = rowArray.join(",");
            csvContent += rowText + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", 'eligibility-checks');
        document.body.appendChild(link);
        link.click();
    }
}