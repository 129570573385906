import { Flex, IconButton, Textarea } from "@chakra-ui/react";
import React from "react";
import { useAutosizeTextarea } from "../../../shared/hooks/useAutoresize";
import SendIcon from "../../../shared/icons/SendIcon";

type Props = {
  onSubmit: (message: string) => void;
};

const ChatMessageInput = (props: Props) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  useAutosizeTextarea(ref, { lineHeight: 42 });

  React.useEffect(() => {
    const textarea = ref.current;

    if (textarea === null) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();

        if (textarea.value.trim().length > 0) {
          props.onSubmit(textarea.value.trim());
        }

        textarea.value = "";
        textarea.style.height = "42px";
      }
    };

    textarea.addEventListener("keydown", handleKeyDown);

    return () => textarea.removeEventListener("keydown", handleKeyDown);
  }, [ref]);

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    const textarea = ref.current;

    if (textarea !== null && textarea.value.trim().length > 0) {
      props.onSubmit(textarea.value.trim());
      textarea.value = "";
    }
  };

  return (
    <Flex
      as="form"
      bg="white"
      borderBottomEndRadius="2xl"
      p={4}
      gap={2}
      alignItems="flex-end"
      onSubmit={handleSubmit}
    >
      <Textarea
        lineHeight="1.6"
        minHeight="42px"
        placeholder="Type your message..."
        borderRadius="2xl"
        size="lg"
        rows={1}
        resize="none"
        ref={ref}
      />
      <IconButton
        aria-label="send-icon"
        borderRadius="2xl"
        size="lg"
        colorScheme="blue"
        icon={<SendIcon />}
        type="submit"
      />
    </Flex>
  );
};

export default ChatMessageInput;
