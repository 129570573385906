//! @ngInject
export function profileCtrl($scope, $rootScope, DatabaseApi, Storage, $timeout, $uibModal, $http, Consts, toaster){
  
  window.scrollTo(0,0);
  
  $scope.showSig = false;
  
  //init
  $scope.link = {val: undefined};
  $scope.form = {
    pass: undefined,
    address: $rootScope.user.agency.address,
    address2: $rootScope.user.agency.address2,
    signature: null,
    signatureImage: undefined
  };
  if($rootScope.user.agency.officePhone) $scope.form.phone = $rootScope.user.agency.officePhone.slice(2);
  else $scope.form.phone = '';
  $scope.image = undefined;
  $scope.uploadText = 'Change Photo';
  $scope.uploadSignatureText = 'Upload signature';
  
    $scope.toggleStealthMode = function(){
        $rootScope.stealthMode = !$rootScope.stealthMode;
        if($rootScope.stealthMode) Storage.set('stealthMode', 'true');
        else Storage.delete('stealthMode');
    };

 $scope.getLink = function(){
     var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/hr_auth_link'
     $http.get(url).then(function(res){
         console.log('good');

         $scope.link.val = res.data.url;

     }, function(err){
     });

  };

 $scope.copyLink = function () {
     var copyText = document.createElement("TEXTAREA");
     // copyText.value = ;
     var t = document.createTextNode($scope.link.val);
     copyText.appendChild(t);

     console.log(copyText);
     copyText.style.height = '0px';

     document.body.appendChild(copyText);
     copyText.select();
     document.execCommand("copy");
     alert("link is copied to the clipboard");
 };

  var sigUrl = Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinators/' + $rootScope.agencyMemberId + '/signature'
  $http.get(sigUrl).then(function(res){
    console.log('good');
      
    if(res.data.url){
      $http.get(res.data.url).then(function(result){
        $scope.form.signatureUrl = res.data.url;
        $scope.showSig = true;
      }, function(err){
        $scope.form.signatureUrl = null;
        $scope.showSig = true;
      });
    } else {
      $timeout(function(){
        $scope.form.signatureUrl = null;
        $scope.showSig = true;
      },1);
    }
    
  }, function(err){
    console.log('error');
    $scope.showSig = true;
  });
  
  
  $scope.$watch('form.signature.dataUrl', function() {
    if (!$scope.form.signature || !$scope.form.signature.dataUrl || $scope.form.signature.isEmpty !== false) {
      return;
    }

    $scope.uploadingSignature = true;
    const url = `agencies/${$rootScope.agencyId}/coordinators/${$rootScope.agencyMemberId}/signature`;
    const body = {
      signatureData: $scope.form.signature.dataUrl
    };
    DatabaseApi.put(url, body).then((res) => {
      $scope.form.signature = null;
      $scope.form.signatureUrl = body.signatureData;
      $scope.uploadingSignature = false;
      $scope.uploadSignatureText = 'Upload signature';
    }, (err) => {
      $scope.form.signature = null;
      $scope.uploadingSignature = false;
      $scope.uploadSignatureText = 'Upload signature';
      toaster.pop('error', "Something Went Wrong", 'Please try again');
    });
  });

  // add or edit item
  $scope.updateImage = function(){

      $scope.uploading = true;
      //var formData = new FormData();
      //formData.append('photo', $scope.image, $scope.image.name);
      //console.log($rootScope.user.coordinator.photoUrl);
      
      var url = 'agencies/' + $rootScope.agencyId + '/coordinators/' + $rootScope.user.coordinator.id + '/photo';
      DatabaseApi.put(url, {photoData: $rootScope.user.coordinator.photoUrl}).then(function(res){
          Storage.setObject('user', $rootScope.user);
          $scope.uploading = false;
          $scope.uploadText = 'Change Photo';
        }, function(err){
          $scope.uploading = false;
          $scope.uploadText = 'Error Uploading Photo';
        });
    
  };

  $scope.updateProfile = function(prop, agency){
    console.log($rootScope.user);
    if(!agency){
      var url = 'agencies/' + $rootScope.agencyId + '/coordinators/' + $rootScope.user.coordinator.id;
      var body = {};
      body[prop] = $rootScope.user.coordinator[prop];
      DatabaseApi.patch(url, body).then(function(res){
        console.log(res.data);
        $rootScope.user.email = $rootScope.user.coordinator.email; 
        $rootScope.user.displayName = $rootScope.user.coordinator.firstName + ' ' + $rootScope.user.coordinator.lastName; 
        Storage.setObject('user', $rootScope.user);
      }, function(err){

      });
    } else {
      var url = 'agencies/' + $rootScope.agencyId;
      var body = {};
      if(prop === 'officePhone') body[prop] = '+1' + $scope.form.phone;
      else body[prop] = $rootScope.user.agency[prop];
      DatabaseApi.patch(url, body).then(function(res){
        console.log(body);
        console.log(res.data);
        Storage.setObject('user', $rootScope.user);
      }, function(err){

      });
    }
   
  };
  
  $scope.$watch('form.pass', function (val) {
      $scope.form.pass = val; 
  });
  
  function updatePhone(phone, oldPhone){
    DatabaseApi.changePhone(phone).then(function(res){
      phoneModal(phone, oldPhone);
    }, function(err){
      $timeout(function() {
        $scope.form.mobilePhone = oldPhone;
      }, 1);
    });
  }
  
  function phoneModal(number, oldPhone){
    var modalInstance = $uibModal.open({
        templateUrl: 'admin/views/phone-modal.html',
        size: 'sm',
        controller: 'phoneModalCtrl',
        resolve: {
          number: function() { return number; },          
        }

    }); 
    modalInstance.result.then(function (res) {
            console.log('done');					
            console.log(res);	
        }, function () {
          console.log('dismis');
          $timeout(function() {
            $scope.form.mobilePhone = oldPhone;
          }, 1);
    });  
  }
    
  /**
   * @deprecated
   */
  $scope.updatePass = function(pass) {
    // 
  };

  $scope.$watch('image', function () {

      if($scope.image){

          $scope.uploadText = 'Uploading';
          var file = $scope.image;
          var reader  = new FileReader();
          reader.onloadend = function () {
              $rootScope.user.coordinator.photoUrl = reader.result;
              $scope.$apply();
              $scope.updateImage();
          }
          reader.readAsDataURL(file);
      }

  });

  $scope.$watch('form.signatureImage', function () {
    if ($scope.form.signatureImage) {
      $scope.uploadSignatureText = 'Uploading';
      const file = $scope.form.signatureImage;
      const reader = new FileReader();
      reader.onloadend = function () {
        $scope.form.signature = {
          isEmpty: false,
          dataUrl: reader.result
        };
      };
      reader.readAsDataURL(file);
    }
  });
  
  
  $scope.locationChange = function(){
    console.log($scope.form.address);
    if($scope.form.address && $scope.form.address.geometry){
      
      $scope.form.address.address_components.forEach(function(section){
        if(section.types.indexOf('locality') > -1 || (section.types.indexOf('sublocality')> -1 && section.types.indexOf('political')> -1 ) ) {
          $rootScope.user.city = section.long_name;
        }
        if(section.types.indexOf('postal_code') > -1 ) {
          $rootScope.user.zip = section.long_name;
        }
        if(section.types.indexOf('administrative_area_level_1') > -1 && section.types.indexOf('political') > -1 ) {
          $rootScope.user.state = section.short_name;
        }
      });
      
      if(!$scope.form.city) $scope.form.city = '';
      $rootScope.user.agency.address = $scope.form.address.formatted_address;
      
      //console.log($scope.form);
      $scope.updateProfile('address', 'agency');
    
    }
  }
  
  const getAgencyMemberTimezones = function() {
    return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices_timezones`).then(res => {
      $scope.agencyMemberTimezones = res.data.timezones;
    })
  };

  getAgencyMemberTimezones();
};
