import { LocalDate } from "js-joda";

/* @ngInject */
export function ClientSurvey ($rootScope, Storage, $uibModal) {

    const _store = (time) => {
        Storage.set('ClientSurveyNextDate', time);
    }

    const set = (time) => {

        if(!time) {
            let randomWeekday = Math.floor(Math.random() * 5) + 1;
            const today = LocalDate.now();
            const todaysDay = today.dayOfWeek().value(); // monday = 1

            if (randomWeekday < todaysDay) {
                randomWeekday += 7;
            }
            const next = today.plusDays(randomWeekday - todaysDay);
            _store(next);
            return get();

        } else {
            console.log('next')
            const today = LocalDate.now();
            const nextTime = LocalDate.parse(time).plusDays(7);
            if(nextTime.isAfter(today.plusDays(7))) {
                console.log('minus')
                set(nextTime.minusDays(14).toString());
            } else if(today.isBefore(nextTime) || nextTime.isEqual(today.plusDays(7))) {
                console.log('store')
                _store(nextTime);
            } else {
                console.log('add')
                set(nextTime.toString());
            }
        }
    };

    const get = () => {
        return Storage.get('ClientSurveyNextDate')

    };

    const displaySurvey = function () {
        $uibModal.open({
            templateUrl: "admin/views/client-survey-modal.html",
            size: "lg",
            controller: "clientSurveyModalCtrl"
          })
    };

    const checkDisplaySurvey = function (time) {
        if (!$rootScope.userId) {
            return false;
        }
        const today = LocalDate.now();
        const nextTime = LocalDate.parse(time);
        const SHOW_AFTER = LocalDate.of(2022, 7, 9);
        return (today.isAfter(nextTime) || today.isEqual(nextTime)) && today.isAfter(SHOW_AFTER)
    };


    const survey = {
        init: function () {
            let surveyTime = get();
            if(!surveyTime) {
                surveyTime = set();
            }
            if(checkDisplaySurvey(surveyTime)){
                setTimeout(function(){
                    displaySurvey();
                    set(surveyTime)
                }, 10000);
            }
        }
    };

    return survey;

};