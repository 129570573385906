//! @ngInject
export function ClockinClockoutService($http, Consts, wildcard, DatabaseApi) { return {
    /**
   * link a clock records that's missing visit instance / patient id
   */
  linkCalls: (
    agencyId, agencyMemberId, records
  ) => {
    const url =
      Consts.api +
      "agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/link";
    return $http.patch(wildcard(url, agencyId, agencyMemberId), {
      records: records
    });
  },
  
    /**
     * Reject a clock record that's missing visit instance / patient id
     */
    linkCall: (
      recordId,
      { agencyId, agencyMemberId, patientId, visitInstanceId }
    ) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/:clockInClockoutRecordId/link";
      return $http.patch(wildcard(url, agencyId, agencyMemberId, recordId), {
        patientId: patientId,
        visitInstanceId: visitInstanceId
      });
    },

  /**
   * Reject a clock records that's missing visit instance / patient id
   */
  rejectCalls: ({ records, agencyId, agencyMemberId }) => {
    const url =
      Consts.api +
      "agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/reject";
    return $http.patch(
      wildcard(url, agencyId, agencyMemberId), {
      records: records
    });
  },

  /**
   * Restore a clock records from Rejected table
   */
  restoreCalls: ({ records, agencyId, agencyMemberId }) => {
    const url =
      Consts.api +
      "agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/restore";
    return $http.patch(
      wildcard(url, agencyId, agencyMemberId), {
      records: records
    });
    },

    /**
     * Update a clock record
     */
    update: (recordId, { agencyId, agencyMemberId, data }) => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/clockin_clockout_records/:clockInClockoutRecordId"
            .replace(":agencyId", agencyId)
            .replace(":agencyMemberId", agencyMemberId)
            .replace(":clockInClockoutRecordId", recordId);

        return DatabaseApi.put(url, data);
    },


    formatReason: ({ record, reason }) => {
        switch (reason) {  
            case "MISSING_PATIENT":
                return "Missing patient";
            case "MISSING_VISIT":
                return "Missing visit";
            case "DUPLICATE_CALL":
                return "Duplicate call";
            case "BLOCKED_CALLER_ID":
                return "Blocked caller ID";
            case "CALL_FROM_CAREGIVER_PHONE":
                return "Call from caregiver phone";
            case "CLOCK_OUTSIDE_OF_RANGE":
                return "Clock outside of range";
            case "PHONE_WITHOUT_EVV_LOCATION":
                return "Phone without EVV location";
            case "UNLINKED_MANUALLY":
                return "Unlinked manually";
            default:
                if (!record) {
                    return status;
                }

                if (!record.patient.exists && !record.visitInstance.exists) {
                    return "Missing Patient & Visit";
                }

                if (!record.patient.exists) {
                    return "Missing Patient";
                }

                if (!record.visitInstance.exists) {
                    return "Missing Visit";
                }

                return reason;
        }
    }
  } };
