//! @ngInject
export function clockinClockoutLinkCallModalCtrl(
      $rootScope,
      $scope,
      $uibModalInstance,
      DatabaseApi,
      ClockinClockoutService,
      clockinClockoutRecord,
      SweetAlert,
      toaster
    ) {
      $scope.record = clockinClockoutRecord;
      $scope.possibilities = [];
      $scope.selectedPossiblity = null;
      $scope.isProcessing = false;
      $scope.invalidPhoneClockErrorMessage = "This clock record cannot be linked because the source phone number can't be assigned to the patient's profile";
      $scope.linkSourcePhoneModalState = {
        isOpen: false,
        isFetchingPatient: false,
        isLinking: false,
      }
      
      $scope.closestPossiblity = ($scope.record.potentialLinks || []).sort((a, b) => {
          return a.clockOffsetInHours < b.clockOffsetInHours ? -1 : 1;
      })[0]

      const canSourcePhoneBeAddedToPatient = possibility => {
        if (possibility.wasCallMadeFromThePatient || possibility.wasCallMadeFromTheCaregiver) {
          return false;
        }

        switch (clockinClockoutRecord.unlinkedReason) {
          case "MISSING_PATIENT":
          case "MISSING_VISIT":
          case "CLOCK_OUTSIDE_OF_RANGE":
          case "PHONE_WITHOUT_EVV_LOCATION":
          case "UNLINKED_MANUALLY":
          case "DUPLICATE_CALL":
            return true;
          case "CALL_FROM_CAREGIVER_PHONE":
          case "BLOCKED_CALLER_ID":
            return false;
          default:
            console.error(`Unhandled clockinClockoutRecord.unlinkedReason "${clockinClockoutRecord.status}"`);
            return false;
        }
      }

      /**
       * Select a visit instance possibility the unlinked record 
       */
      $scope.selectPossibility = possibility => {
        $scope.selectedPossiblity = possibility;

        if (!possibility.wasCallMadeFromThePatient) {
            return canSourcePhoneBeAddedToPatient(possibility)
                ? promptAddSourcePhoneToPatient(possibility)
                : promptInvalidAlert();
        }

        promptAlert(possibility);
      };

      $scope.handleClickOpenVisit = (e, possibility) => {
          e.stopPropagation();
          $rootScope.openVisitInstanceModal(possibility.visitInstance.id);
      }

      $scope.linkPhoneAndCall = async ({ possibility, address }) => {
        setSourcePhoneModalState(prev => ({ ...prev, view: "Loading", isLinking: true }));
  
        attachSourcePhoneToPatient({ patientId: possibility.patient.id, addressId: address.id })
          .then(() => {
            return linkCall(possibility)
              .then(() => setSourcePhoneModalState(prev => ({ ...prev, view: "Ready", isLinking: false })));
          })
          .catch(error => setSourcePhoneModalState(prev => ({...prev, view: "Error", error: error.message || "Failed to link phone and call" })))
      }

      function attachSourcePhoneToPatient({ patientId, addressId }) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/addresses/:patientAddressId/create_phonenumber"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", patientId)
          .replace(":patientAddressId", addressId);

        const body = { phoneNumber: clockinClockoutRecord.sourcePhone };

        return DatabaseApi.post(url, body);
      }

      function promptAddSourcePhoneToPatient(possibility) {
        setSourcePhoneModalState({ view: "Loading", isOpen: true, isFetchingPatient: true, possibility });

        getPatient(possibility.patient.id)
          .then(({ data }) => setSourcePhoneModalState(prev => ({...prev, view: "Ready", patient: data })))
          .catch(error => setSourcePhoneModalState(prev => ({...prev, view: "Error", error: error.message || "Failed to fetch patient data" })))
          .then(() => setSourcePhoneModalState(prev => ({...prev, isFetchingPatient: false })));
      }

      function getPatient(patientId) {
        const url = `agencies/:agencyId/patients/:patientId`
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":patientId", patientId);

        return DatabaseApi.get(url);
      }

      function setSourcePhoneModalState(dispatcher) {
        $scope.linkSourcePhoneModalState = typeof dispatcher === "function"
          ? dispatcher($scope.linkSourcePhoneModalState)
          : dispatcher;
      }

      function promptInvalidAlert() {
        SweetAlert.swal(
            {
              title: "Can't link call!",
              text: $scope.invalidPhoneClockErrorMessage,
              type: "error",
              showCancelButton: true,
              cancelButtonText: "Close",
              showConfirmButton: false,
            },
          );
      }

      /**
       * Prompt "Are you sure" alert for linking the record
       * @param {*} possibility 
       */
      function promptAlert(possibility) {
        SweetAlert.swal(
          {
            title: "Link Call",
            text: "Are you sure you want to link this call?",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, link",
            closeOnConfirm: true,
            showConfirmButton: true
          },
          hasConfirmed => hasConfirmed && linkCall(possibility)
        );
      }

      /**
       * Send a PATCH request to link the record
       * @param {*} possibility 
       */
      function linkCall(possibility) {
        $scope.isProcessing = true;

        return ClockinClockoutService.linkCalls($rootScope.agencyId, $rootScope.agencyMemberId,
          [{
            clockinClockoutRecordId: possibility.id,
            visitInstanceId: possibility.visitInstance.id
          }])
          .then(() => onSuccessLink({ type: "success", singleLinkedCall: possibility}))
          .catch((error) => onFailLink({ type: "fail", error }))
          .finally(() => ($scope.isProcessing = false));
      }

      /**
       * Hanlde success response of linking a record
       * @param {Response} response 
       */
      function onSuccessLink(response) {
        toaster.pop("succes", "Success", "Call has been successfully linked");
        $uibModalInstance.close(response);
      }

      /**
       * Handle an error response of linking a record
       * @param {Error} error 
       */
      function onFailLink({ type: _type, error }) {
        console.error(error);
        toaster.pop("error", "Something went wrong", "Could not link call");
      }

      $scope.closeModal = () => {
        $uibModalInstance.close({ type: "close" });
      }

      
      $scope.canSourcePhoneBeAddedToPatient = canSourcePhoneBeAddedToPatient;
    };
