import { LocalDate, DateTimeFormatter } from "js-joda";

//! @ngInject
export function patientAlertsCtrl($scope, $rootScope, NgTableParams, DatabaseApi, toaster, FilterUtils, itemSearchPageManager) {
    $scope.tableParams = null;
    $scope.patientAlerts = [];

    $scope.preOpenPatientModal = function (patientId) {
        if (!patientId) return;
        $rootScope.openPatientModal(patientId);
    }

    $scope.preOpenCaregiverModal = function (caregiverId) {
        if (!caregiverId) return;
        $rootScope.openCaregiverModal(caregiverId);
    }

    var initialize = function () {
        initPageManager();
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.patientAlertsCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }

        $scope.searchPatientAlerts();
    }

    $scope.$on("$destroy", function() {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.patientAlertsCtrl = angular.copy(filters);
    });

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/patient_alerts");
        $scope.validatorsDataManager = $scope.pageManager.getValidatorsDataManager();
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initValidatorsParam();
        $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().minusMonths(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
        $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().plusDays(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    }

    var initTable = function (items) {
        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: items
        });
    };

    $scope.searchPatientAlerts = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            $scope.patientAlerts = response.data.patientAlerts;
            initTable($scope.patientAlerts);
        }, function (error) {
            toaster.pop("error", "Failed to load patient alerts");
        });
    }

    $scope.$on('PatientAlertsUpdated', function () {
        $scope.searchPatientAlerts();
    });

    initialize();
};