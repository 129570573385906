import { AngularJSDispatch } from "../../core/webapp-channel";
import { useWebappChanneDispatchEvent } from "./useWebappChannelEvent";

type SocketEvent = AngularJSDispatch["SOCKET_EVENT_RECEIVED"];

type SocketPayloadMap = {
  [k in SocketEvent["type"]]: Extract<SocketEvent, { type: k }>["payload"];
};

const useSocketEvent = <$SocketEventKey extends SocketEvent["type"]>(params: {
  key: $SocketEventKey;
  onEvent: (event: SocketPayloadMap[$SocketEventKey]) => void;
}) => {
  useWebappChanneDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: (value) => {
      if (value.type === params.key) {
        params.onEvent(value.payload as SocketPayloadMap[$SocketEventKey]);
      }
    },
  });
};

export default useSocketEvent;
