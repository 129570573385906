import { ContractType, ContractTypes } from "../messages/contract_type";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export class ContractTypeService {
   contractTypes: ContractType[] | undefined = undefined;

   constructor(
     private api: Api,
     private endpoint: Endpoint,
     private $rootScope: ng.IRootScopeService
   ) {}

   /**
    * Get a list of agency contractTypes
    */
   async getContractTypes(params: {onlyActives: boolean, refetch: boolean} = {
     onlyActives: false,
     refetch: false
   }) {
     const url = this.endpoint({
       path: "agencies/:agencyId/contract_types",
       params: {
         agencyId: this.$rootScope.agencyId
       },
     });

     if (!params.refetch && this.contractTypes !== undefined){
       return params.onlyActives ? this.contractTypes.filter(row => row.active) : this.contractTypes;
     }

     return this.api.get<ContractTypes>(url).then((res) => {
       this.contractTypes = res.data.contractTypes;
       return params.onlyActives ? this.contractTypes.filter(row => row.active) : this.contractTypes;
     });
   }

 }
