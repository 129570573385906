//! @ngInject
export function permissionsManagement(agencyPermissionsRolesService, toaster) {
    return {
        restrict: 'E',
        scope : {
            title: '<',
            loadRolesAccess: '&',
            onAddAccess: '&',
            onEditAccess: '&',
            onRemoveAccess: '&',
            onCloseModal: '&'
        },
        templateUrl: 'admin/views/permissions-management.html',
        link: function(scope) {
            scope.rolesAddAccess = {
                roles: [],
                accessType: null
            };
            scope.accessTypes = ["VIEW", "EDIT"];

            function initialize() {
                scope.loadRolesAccess().then(res => {
                    scope.rolesAccess = res;
                    loadAllRoles().then(() => {
                        initSuggestedRoles();
                    });
                });
            }

            function loadAllRoles() {
                return agencyPermissionsRolesService.getAgencyRoles().then(res => {
                    scope.allRoles = res;
                    scope.allRolesMap = rolesMap();
                });
            }

            scope.handleAddAccess = () => {
                const rolesIds = scope.rolesAddAccess.roles.map(role => role.id);
                const accessType = scope.rolesAddAccess.accessType;

                scope.onAddAccess({rolesIds, accessType}).then(() => {
                    toaster.pop("success", "Success", "Added roles access succeffuly");
                    clearAddAccessFields();
                    scope.loadRolesAccess().then(res => {
                        scope.rolesAccess = res;
                        initSuggestedRoles();
                    });
                }).catch(err => {
                    toaster.pop("error", "Something went wrong", "Failed to add roles access");
                });
            }

            scope.handleEditAccess = (roleId, accessType) => {  
                scope.onEditAccess({roleId, accessType}).then(res => {
                    toaster.pop("success", "Success", "Edited role access succeffuly");
                    scope.loadRolesAccess().then(res => {
                        scope.rolesAccess = res;
                    });
                }).catch(err => {
                    toaster.pop("error", "Something went wrong", "Failed to edit role access");
                });
            }
        
            scope.handleRemoveAccess = (roleId) => {
                scope.onRemoveAccess({roleId}).then(res => {
                    toaster.pop("success", "Success", "Removed role access succeffuly");
                    scope.loadRolesAccess().then(res => {
                        scope.rolesAccess = res;
                        initSuggestedRoles();
                    });
                }).catch(err => {
                    toaster.pop("error", "Something went wrong", "Failed to remove role access");
                });
            }

            function rolesMap() {
                return scope.allRoles.reduce((acc, cur) => ({...acc, [cur.id]: cur.name}), {});
            }

            function initSuggestedRoles() {
                const existRolesAccessIds = scope.rolesAccess.map(role => role.roleId);
                scope.suggestedRoles =  scope.allRoles.filter(role => !existRolesAccessIds.includes(role.id));
            }

            function clearAddAccessFields() {
                scope.rolesAddAccess = {
                    roles: [],
                    accessType: null
                };
            }

            initialize();
        }
    }
};