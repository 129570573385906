//! @ngInject
export function patientDocLinkQuestionModalCtrl($scope, $rootScope, Consts, $http, $state, $uibModalInstance, DatabaseApi, toaster, docId, question){


    console.log('in');
    var questionType = question.itemType;

    $scope.selectedDoc = {val: null};

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };

    $scope.selectQuestion = function(selectedQuestion){
        var body = {
            masterDoc: parseInt($scope.selectedDoc.val),
            slaveDoc: parseInt(docId),
            masterQuestion: selectedQuestion.id,
            slaveQuestion: question.id
        };
        console.log(body);

        var url  = 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/patient_document_types/link_questions';
        DatabaseApi.put(url, body).then(function (res) {
            $uibModalInstance.close(res);
        }, function(){
            toaster.pop('error', "Something went wrong", "please try again");
        })

    };

    $scope.listOfDocs = DatabaseApi.patientDocumentTypes().map(function(doc, i){
        return {title: doc.title, idx: i, id: doc.id};
    });

    console.log($scope.listOfDocs);

    $scope.getDocQuestions = function () {

        if (!$scope.selectedDoc.val) return;

        var docSelected = DatabaseApi.patientDocumentTypes().find(function(doc){
            return doc.id.toString() == $scope.selectedDoc.val;
        });

        $scope.existingDocLatestVersion = docSelected.versions.sort(function(ver1, ver2) {
            return ver2.id - ver1.id;
        })[0];

        $scope.questions = [];
        iterateContentArray($scope.existingDocLatestVersion.content);

        console.log($scope.questions);
    };


    function iterateContentArray(array) {

        array.forEach(function (item) {

            if(item.itemType === questionType) $scope.questions.push(item);
            if (item.columns) {
                iterateContentArray(item.columns[0]);
            }

        });

    }

};
