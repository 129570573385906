//! @ngInject
export function newAgencyMedicineModalCtrl(
    $rootScope,
    $scope,
    DatabaseApi,
    $uibModalInstance,
    toaster,
    mfModal,
) {

    function initialize() {
        $scope.form = {};
        $scope.data = {};
    }

    function promptAboutChangesBeforeSave() {
        const modal = mfModal.create({
            variant: "warning",
            subject: "You won't be able to edit the medication name and route after saving",
            message: "Please verify the information you entered is correct",
            confirmLabel: "Save medication",
            hideCancelButton: false,
            cancelLabel: "Continue editing",
            onConfirm: () => {
                createNewAgencyMedication();
                modal.close();
            }
        });
    }

    function displaySimilarMedicationsError(createMedicationErrorBody) {
        const { type, similarMedications } = createMedicationErrorBody;
        if (type === "NAME") {
            const modal = mfModal.create({
                variant: "warning",
                subject: `There are similar agency medication${similarMedications.length > 1 ? "s" : ""} with the same name/route`,
                message: similarMedications.map((medication, index) => `${index + 1}. ${medication}\n`).join("") + "\nAre you sure you want to add this one also?",
                confirmLabel: "Yes, add",
                hideCancelButton: false,
                onConfirm: () => {
                    promptAboutChangesBeforeSave();
                    modal.close();
                }
            });
        } else if (type === "NAME_AND_ROUTE") {
            const modal = mfModal.create({
                variant: "warning",
                subject: "Similar medication",
                message: "There is already a medication with the same name and route, try to search for it.",
                confirmLabel: "Ok",
                hideCancelButton: true,
                onConfirm: () => {
                    modal.close();
                }
            });
        }
    }

    function createNewAgencyMedication() {
        const body = {
            displayName: $scope.data.displayName,
            route: $scope.data.route,
            dose: $scope.data.dose
        };

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/agency_medication_profile"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.$resolve.patientId);

        $scope.formSending = true;
        DatabaseApi.post(url, body).then(function (res) {
            toaster.pop('success', "A new medication created successfully");

            const newMedication = {
                ...res.data,
                medicationName: body.displayName,
                route: body.route,
                dose: body.dose
            };
            $uibModalInstance.close(newMedication);

        }).catch((err) => {
            toaster.pop('error', "Something went wrong", "Could not create new medication");
        }).finally(() => $scope.formSending = false);
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }

        const body = {
            displayName: $scope.data.displayName,
            route: $scope.data.route,
            dose: $scope.data.dose
        };

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/medications/similar_medications"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.$resolve.patientId);

        $scope.formSending = true;
        DatabaseApi.post(url, body).then((res) => {
            if (res.data.type !== "NONE") {
                return displaySimilarMedicationsError(res.data);
            }

            promptAboutChangesBeforeSave();
        }).catch((err) => {
            toaster.pop('error', "Something went wrong", "Failed to validate a new agency medication");
        }).finally(() => $scope.formSending = false);
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    initialize();
};
