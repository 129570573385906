//! @ngInject
export function rejectComplianceItemPendingUploadModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {

    $scope.rejectReason = "";
    $scope.selectedReason = {};
    $scope.rejectionReasons = [];
    $scope.isOnboardingAgency = $rootScope.isOnboardingAgency;

    $scope.closeModal = (result = 'cancel') => {
        $uibModalInstance.close(result);
    };

    $scope.getRejectReasons = () => {
        if ($rootScope.isOnboardingAgency){
            const url = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/compliance_items/rejection_reasons`;
            DatabaseApi.get(url).then((res) => {
                $scope.rejectionReasons = res.data.reasons;
            }), (err) => {
                toaster.pop('error', 'Oops! Something went wrong', 'could not get rejection reasons');
            }
        }
    }
    
    $scope.updateRejectReason = (reason) => {
        $scope.rejectReason = reason;
    }

    $scope.submitReject = () => {
        const reason =  $scope.rejectReason !== "" ? $scope.rejectReason : null;
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${$scope.pendingUpload.caregiverId}/uploaded_documents/${$scope.pendingUpload.caregiverDocumentUploadId}/reject`;
        const body = {
            reason: reason
        };
        DatabaseApi.post(url, body).then((res) => {
            toaster.pop('success', 'Successfully rejected upload');
            $scope.pendingUpload.complianceStatus = "Rejected";
            $scope.pendingUpload.complianceRejectReason = reason;
            $scope.closeModal('success');
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong', 'could not reject upload');
        });
    }
    $scope.getRejectReasons();

};