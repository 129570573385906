import { PatientPlanOfCare } from "../messages/plan_of_care";
import { PlanOfCareId } from "../messages/ids";
import { PlanOfCareService } from "../services/planOfCareService";
import { PatientExtendedData } from "../messages/patient";

type PlanOfCareWithRevision = PatientPlanOfCare & { revisionName: string };

type State =
  | { type: "Loading" }
  | {
      type: "Ready";
      plansOfCare: PlanOfCareWithRevision[];
      selectedPlanOfCare: PlanOfCareWithRevision | null;
    }
  | {
      type: "Error";
      errorMessage: string;
    };

//! @ngInject
export class PatientPlanOfCareHistoryCtrl implements ng.IController {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
    private $timeout: ng.ITimeoutService,
    private planOfCareService: PlanOfCareService,
    private patient: PatientExtendedData
  ) {}

  state: State = { type: "Loading" };

  $onInit() {
    this.fetchPlansOfCare();

    this.$rootScope.$on("patient_poc_updated", () => this.fetchPlansOfCare());
  }

  handleClickPlanOfCare(planOfCare: PlanOfCareWithRevision) {
    if (this.state.type !== "Ready") {
      console.warn("Can't select a plan of care when the state is not ready");
      return;
    }

    this.state.selectedPlanOfCare = planOfCare;
  }

  handleClickSendEmail(planOfCareId: PlanOfCareId) {
    this.$uibModal.open({
      templateUrl: "admin/views/email-modal.html",
      size: "lg",
      resolve: {
        documents: { id: planOfCareId },
        patientId: this.patient.id,
        patientFullName: this.getFullName(this.patient),
        docsType: "planOfCare",
      },
      controller: "emailModalCtrl",
    });
  }

  handleRequestClose() {
    this.$uibModalInstance.close();
  }

  private fetchPlansOfCare() {
    this.state = { type: "Loading" };

    this.planOfCareService
      .getByPatientId(this.patient.id)
      .then((plansOfCare) => {
        const plansOfCareWithRevision = plansOfCare.map((planOfCare, i) => ({
          ...planOfCare,
          revisionName: `Revision ${i + 1}`,
        }));

        this.$timeout(() => {
          this.state = {
            type: "Ready",
            plansOfCare: plansOfCareWithRevision,
            selectedPlanOfCare: plansOfCareWithRevision[0],
          };
        }, 0);
      })
      .catch((error) => {
        console.error(error);
        this.$timeout(() => {
          this.state = {
            type: "Error",
            errorMessage: "Failed to fetch the patient plans of care",
          };
        }, 0);
      });
  }

  // We should probably move this method to somewhere else
  private getFullName(params: {
    firstName: string;
    middleName: string | null;
    lastName: string | null;
  }) {
    return [params.firstName, params.middleName, params.lastName].filter(Boolean).join(" ");
  }
}
