import moment from "moment";
import _ from "lodash";

/* @ngInject */
export function VisitStatus() { 
    this.getVisitStatus = (visit) => {

        if (visit.assignedAt || visit.assignedCaregiver || visit.splitShiftFullyAssigned === true) {
            return 'scheduled';
        }

        // const assignmentRequests = _.filter(
        //     visit.assignmentRequests, ({ isConfirmed }) => !isConfirmed
        // );

        const isConfirmation = _.some(visit.assignmentRequests, ({ visitInstanceId }) =>
            _.some(visit.visitInstances, ({ id }) => visitInstanceId === id)
        );

        if (_.size(visit.assignmentRequests) > 0) {
            return "confirmation";
        }
        
        if (visit.acceptedBy && visit.acceptedBy.length) {
            return 'waiting';
        }

        const now = new Date().getTime();
        const start = moment.tz(visit.startTime, visit.timezone).toDate().getTime();
        const end = moment.tz(visit.endTime, visit.timezone).toDate().getTime();

        if (visit.flexibleVisitParams !== null) {
            if (now > end) {
                return "unstaffed";
            }
        } else {
            if (now > start) {
                return "unstaffed";
            }
        }
    
        return 'pending';
    }
}