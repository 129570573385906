//! @ngInject
export function TrainingCenterExemptsService($rootScope, DatabaseApi, wildcard) { return {
    getFilters() {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/bulk_exempts/filters",
        agencyId,
        agencyMemberId
      );

      return DatabaseApi.get(endpoint).then(({ data: { filters } }) => filters);
    },

    getCaregivers({ dueDateIds }) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/bulk_exempts/caregivers",
        agencyId,
        agencyMemberId
      );

      const url = `${endpoint}?dueDateIds=${dueDateIds.join()}`;

      return DatabaseApi.get(url).then(({ data }) => {
        return data.caregiverIds;
      });
    },

    exemptCaregivers({ dueDateIds, caregiverIds, notes }) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/bulk_exempts",
        agencyId,
        agencyMemberId
      );

      return DatabaseApi.post(endpoint, { dueDateIds, caregiverIds, notes });
    },
  }}
