//! @ngInject
export function serviceCodeSetupModalCtrl($scope, $rootScope, $uibModalInstance, data, DatabaseApi, toaster) {
    $scope.rateTypeOptions = ["Hourly", "Daily", "Visit", "Cluster"];
    $scope.unitsPerHourOptions = [1, 2, 4, 60];
    $scope.payCodeExtraOptions = {
        displayProp: "displayId",
        styleActive: true,
        scrollable: true,
        enableSearch: true
    };
    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certificationList = activeAgencyCertifications
        .map((certificationItem) => certificationItem.certification);

    $scope.form = {};
    $scope.selectedPayCodes = [];
    $scope.payrollCodes = DatabaseApi.activePayrollCodes();
    if($scope.viewModeOnly) {
        $scope.data = angular.copy(data);
        setServiceCodeView();
    } else {
        $scope.data = {};
    }

    $scope.isActiveChange = function(data){
        if (data.id === undefined) return;

        var serviceCodeRequest = {
            id: data.id,
            isActive: data.isActive
        }
        $scope.putOrPostServicCodes(data, serviceCodeRequest);
    }

    $scope.allowVisitOverlapChange = (data) => {
        if (data.id === undefined) return;

        const serviceCodeRequest = {
            id: data.id,
            allowVisitOverlap: data.allowVisitOverlap
        }

        $scope.putOrPostServicCodes(data, serviceCodeRequest);
    }

    $scope.submitForm = function () {


        if ($scope.form.$invalid || $scope.formSending) {
            return;
        }

        $scope.formSending = true;


        var serviceCodeRequest = prepareServiceCodeRequest();

        $scope.putOrPostServicCodes(data, serviceCodeRequest);
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    function prepareServiceCodeRequest() {
        var serviceCodeReqObj = {};
        serviceCodeReqObj = $scope.data;
        if(!$scope.data.mutual){
            serviceCodeReqObj.mutual = false;
        }
        //set rateType object
        var rateTypeTempObj = {
            type: serviceCodeReqObj.rateType
        }
        switch (rateTypeTempObj.type) {
            case "Hourly": {
                rateTypeTempObj.unitsPerHour = Number(serviceCodeReqObj.unitsPerHour);
                delete serviceCodeReqObj.unitsPerHour;
                break;
            }
            case "Cluster": {
                rateTypeTempObj.unitsPerHour = Number(serviceCodeReqObj.unitsPerHour);
                delete serviceCodeReqObj.unitsPerHour;
                break;
            }
            case "Daily": {
                rateTypeTempObj.minimumDailyHours = serviceCodeReqObj.minimumDailyHours;
                delete serviceCodeReqObj.minimumDailyHours;
                break;
            }

            default: {
                break;
            }
        }
        serviceCodeReqObj.rateType = rateTypeTempObj;

        //set pay codes
        serviceCodeReqObj.payrollCodes = [];
        $scope.selectedPayCodes.forEach(function (payCode) {
            serviceCodeReqObj.payrollCodes.push(payCode.id);
        });

        return serviceCodeReqObj;
    }

    function setServiceCodeView() {
        switch($scope.data.rateType.type) {
            case 'Hourly': {
                $scope.data.unitsPerHour = angular.copy($scope.data.rateType.unitsPerHour);
                break;
            }
            case 'Cluster': {
                $scope.data.unitsPerHour = angular.copy($scope.data.rateType.unitsPerHour);
                break;
            }
            case 'Daily': {
                $scope.data.minimumDailyHours = angular.copy($scope.data.rateType.minimumDailyHours);
                break;
            }
            default: {
                break;
            }
        }

        $scope.data.rateType = $scope.data.rateType.type;
        // set selectedPayCodes
        $scope.selectedPayCodes = data.payrollCodes.map(id => ({id}));
    }

    $scope.getPayCodeById = function(payCodeId) {
        for (var i in $scope.payrollCodes) {
            if ($scope.payrollCodes[i].id === payCodeId) {
                return $scope.payrollCodes[i].displayId;
            }
        }
    }

    $scope.putOrPostServicCodes = function(data, serviceCodeRequest) {
        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/service_code/' + data.id, serviceCodeRequest)
                .then(function (res) {
                    toaster.pop('success', "Service code saved");
                    $scope.closeModal();
                    $scope.formSending = false;
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change Service code settings");
                    $scope.formSending = false;
                });
        }
        else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId +'/agency_members/' + $rootScope.agencyMemberId+ '/service_code', serviceCodeRequest).then(function (res) {
                toaster.pop('success', "Service code saved");
                $scope.closeModal();
                $scope.formSending = false;
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add service code");
                $scope.formSending = false;
            });
        }
    }
};
