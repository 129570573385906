
//! @ngInject
export function patientContractDiagnosisCodeSetupModalCtrl($scope, data, isAnswer, $rootScope, $uibModalInstance, DatabaseApi, toaster, dateUtils) {

    $scope.form = {};
    $scope.calendarPopups = {};
    $scope.isAnswer = isAnswer;
    
    $scope.check = {updateToPatientProfile: true};

    $scope.diagnosesTypes = [
        {id: "PRINCIPAL", label: "Principal diagnosis"},
        {id: "SURGICAL", label: "Surgical procedure"},
        {id: "OTHER", label: "Other pertinent diagnosis"}
    ];

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.$watchGroup(["data.startDate", "data.endDate"], function(){
        if (!$scope.data || !$scope.data.startDate || !$scope.data.endDate) {
            $scope.isForBilling = false;
        }
    });

    if (data) {
        $scope.data = { ...data };
        $scope.patientContractId = data.patientContractId;
        $scope.patientContractDiagnosisType = data.type ? data.type : "OTHER";
        $scope.selectedIcdCodeID = data.icdCode;
        $scope.selectedIcdFormattedDxCode = data.formattedDxCode;
        $scope.selectedIcdShortDesc = data.shortDesc;
        $scope.isForBilling = data.isForBilling;

        $scope.data.startDate = data.startDate ? dateUtils.ymdStringToDate(data.startDate) : null;
        $scope.data.endDate = data.endDate ? dateUtils.ymdStringToDate(data.endDate) : null;
        $scope.data.icdCode = $scope.data.shortDesc;

        if ($scope.isForBilling) {
          $scope.check.updateToPatientProfile = false;
        }
    }

    $scope.icdCodeAutoCompleteOptions = {
        minimumChars: 1,
        data: function (searchText) {
            return DatabaseApi.get('agencies/' + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + '/icd_10_codes/auto_complete?search_string=' + searchText)
                .then(function (response) {
                    return response.data.codes
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not get icd codes");
                });
        },
        renderItem: function (item) {
            var icdText = item.formattedDxCode + ' - ' + item.shortDesc;
            return {
                value: item.shortDesc,
                label: "<p class='auto-complete'>" + icdText + "</p>"
            };
        },
        itemSelected: function (e) {
            $scope.selectedIcdCodeID = e.item.id;
            $scope.selectedIcdFormattedDxCode = e.item.formattedDxCode;
            $scope.selectedIcdShortDesc = e.item.shortDesc;
        }

    }

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }

        const diagnosisCodeRequest = preparePatientContractDiagnosisCodeRequest();

        const saveAnswerCodeIfNeeded = (changedCode, prevId) => {
          if ($scope.isAnswer && $scope.saveAnswerCode) {
            $scope.saveAnswerCode(changedCode, prevId);
          }
        }

        if ($scope.isAnswer && !$scope.check.updateToPatientProfile) {
          try {
            saveAnswerCodeIfNeeded(diagnosisCodeRequest);
          } catch (e) {
            toaster.pop('error', "Something went wrong", "Could not save diagnosis code answer settings");
            return;
          }
          $scope.closeModal();
          return;
        }

        if (diagnosisCodeRequest.id && diagnosisCodeRequest.id >= 0) {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_contract_diagnosis_codes/:patientContractDiagnosisCodeId"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":patientContractDiagnosisCodeId", diagnosisCodeRequest.id);
            DatabaseApi.put(url, diagnosisCodeRequest)
                .then(function (res) {
                    toaster.pop('success', "Default diagnosis code saved");
                    $scope.getPatientContractDiagnosisCode();
                    saveAnswerCodeIfNeeded(diagnosisCodeRequest);
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change default diagnosis code settings")
                });
        } else {
            const prevId = diagnosisCodeRequest.id;
            delete diagnosisCodeRequest.id;

            const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_contract_diagnosis_codes/"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);
            DatabaseApi.post(url, diagnosisCodeRequest).then(function (res) {
                toaster.pop('success', "Default diagnosis code saved");
                $scope.getPatientContractDiagnosisCode();
                if (res.data.id) {
                  diagnosisCodeRequest.id = res.data.id; 
                  try {
                    saveAnswerCodeIfNeeded(diagnosisCodeRequest, prevId);
                  } catch (e) {
                    toaster.pop('error', "Something went wrong", "Could not save diagnosis code answer settings");
                    return;
                  }
                }
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add default diagnosis code");
            });
        }
    };

    $scope.isForBillingChanged = () => {
        if ($scope.isForBilling) {
            $scope.patientContractDiagnosisType = "OTHER";
            $scope.check.updateToPatientProfile = false;
        }
    }

    function preparePatientContractDiagnosisCodeRequest() {
        var diagnosisCodeRequest = angular.copy($scope.data);

        diagnosisCodeRequest.startDate = diagnosisCodeRequest.startDate ? dateUtils.dateToLocalDate(diagnosisCodeRequest.startDate) : null;
        diagnosisCodeRequest.endDate = diagnosisCodeRequest.endDate ? dateUtils.dateToLocalDate(diagnosisCodeRequest.endDate) : null;
        diagnosisCodeRequest.icdCode = $scope.selectedIcdCodeID;
        diagnosisCodeRequest.formattedDxCode = $scope.selectedIcdFormattedDxCode;
        diagnosisCodeRequest.shortDesc = $scope.selectedIcdShortDesc;
        diagnosisCodeRequest.patientContractId = $scope.patientContractId;
        diagnosisCodeRequest.type = $scope.patientContractDiagnosisType === "OTHER"
            ? null
            : $scope.patientContractDiagnosisType;
        diagnosisCodeRequest.isForBilling = $scope.isForBilling;
        return diagnosisCodeRequest;
    }

    $scope.deletePatientContractDiagnosisCode = function () {
      if ($scope.isAnswer) {
        if ($scope.deleteAnswerCode) {
          $scope.deleteAnswerCode($scope.data.id);
        }

        if (!$scope.check.updateToPatientProfile) {
          $scope.closeModal();
          return;
        } 
      }

      const url = "caregivers/:caregiverId/patient_contract_diagnosis_codes/:patientContractDiagnosisCodeId"
            .replace(":caregiverId", $rootScope.agencyMemberId)
            .replace(":patientContractDiagnosisCodeId", $scope.data.id);
        
        DatabaseApi.delete(url).then(function (res) {
            toaster.pop('success', "Diagnosis code removed");
            $scope.getPatientContractDiagnosisCode();
            $scope.closeModal();
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not remove diagnosis code");
        });
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

};