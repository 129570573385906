import _ from "lodash";
import Masonry from "masonry-layout";

//! @ngInject
export function TrainingCenterMediaLibraryCtrl(
    $scope,
    $rootScope,
    $uibModal,
    DatabaseApi,
    TrainingCenterBundleService,
    toaster,
    wildcard
  ) {
    $scope.mediaBundles = undefined;
    $scope.agencyBundles = undefined;
    $scope.isFetchingMediaLibrary = false;
    $scope.isFetchingAgencyBundles = false;
    $scope.defaultLanguage = "English";
    $scope.selectedLanguage = { val: $scope.defaultLanguage };
    $scope.isProcessing = { val: false };
    $scope.createdBundle = { val: null };
    $scope.createdBundleItem = { val: null };
    $scope.selectedBundleId = { val: null };
    $scope.addBundleProcess = [];

    $scope.onClickGetFullBundle = onClickBundle;
    $scope.onClickBundleItem = onClickBundleItem;

    function onInit() {
      fetchMediaLibrary();
      fetchAgencyBundles();
    }

    function fetchAgencyBundles() {
      $scope.isFetchingAgencyBundles = true;

      return TrainingCenterBundleService.get()
        .then(({ data }) => ($scope.agencyBundles = data.bundles))
        .catch(() => toaster.pop("error", "Failed to fetch agency bundles"))
        .finally(() => ($scope.isFetchingAgencyBundles = false));
    }

    $scope.$watchGroup(
      ["mediaBundles", "agencyBundles"],
      ([media, library]) => {
        if (media && library) {
          new Masonry('.tcml-bundles', { columnWidth: 1 });
        }
      }
    );

    function fetchMediaLibrary() {
      $scope.isFetchingMediaLibrary = true;

      // TODO remove mockup
      const url = `agencies/${$rootScope.agencyId}/training_center/media_library`;

      return DatabaseApi.get(url)
        .then(({ data }) => {
          $scope.mediaBundles = processFetchedBundles(data.libraryBundles);
        })
        .catch(() => toaster.pop("error", "Failed to fetch library"))
        .finally(() => ($scope.isFetchingMediaLibrary = false));
    }

    function processFetchedBundles(bundles) {
      return bundles.filter((bundle) => bundle.active).map((bundle) => ({
        ...bundle,
        bundleItems: bundle.bundleItems.filter((bundleItem) => bundleItem.active).map((bundleItem) => ({
          ...bundleItem,
          __displayData: _.keyBy(bundleItem.displayData, "language"),
        })),
        __displayData: _.keyBy(bundle.bundleDisplayData, "language"),
        __timeForCertificateMinutes: _.sumBy(
          bundle.bundleDisplayData,
          "timeForCertificateMinutes"
        ),
      }));
    }

    function onClickBundle(bundle) {
      const modal = $uibModal.open({
        templateUrl:
          "admin/views/training-center-media-library-clone-bundle-modal.html",
        size: "lg",
        controller: "trainingCenterMediaLibraryCloneBundleModal",
        windowTopClass: "training-center-media-library-clone-bundle-modal",
        resolve: {
          bundle: () => bundle,
          agencyBundles: () => $scope.agencyBundles,
          isProcessing: () => $scope.isProcessing,
          onChooseBundle: () => addItemsToBundle,
          onChooseCloneBundle: () => addBundle,
          createdBundle: () => $scope.createdBundle,
          selectedBundleId: () => $scope.selectedBundleId,
        },
      });

      modal.closed.then(() => {
        $scope.isProcessing = { val: false };
        $scope.createdBundle = { val: null };
        $scope.selectedBundleId = { val: null };
      });

    }

    function onClickBundleItem(bundleItem) {
      const modal = $uibModal.open({
        templateUrl:
          "admin/views/training-center-media-library-bundle-item-modal.html",
        size: "lg",
        controller: "trainingCenterMediaLibraryBundleItemModal",
        windowTopClass: "training-center-media-library-bundle-item-modal",
        resolve: {
          bundleItem: () => bundleItem,
          agencyBundles: () => $scope.agencyBundles,
          isProcessing: () => $scope.isProcessing,
          onChooseBundle: () => addItemToBundle,
          onChooseNewBundle: () => addItemToNewBundle,
          createdBundleItem: () => $scope.createdBundleItem,
          selectedBundleId: () => $scope.selectedBundleId,
        },
      });

      modal.closed.then(() => {
        $scope.isProcessing = { val: false };
        $scope.createdBundleItem = { val: null };
        $scope.selectedBundleId = { val: null };
      });
    }

    function addBundle({ libraryBundleId }) {
      $scope.addBundleProcess[libraryBundleId] = { status: "LOADING" };

      const url = wildcard(
        "agencies/:agencyId/training_center/media_library/clone_bundle",
        $rootScope.agencyId
      );

      DatabaseApi.post(url, { sourceLibraryBundleId: libraryBundleId })
        .then(({ data }) => {
          toaster.pop("success", "Bundle added!");
          $scope.addBundleProcess[libraryBundleId].status = "SUCCESS";
          $scope.addBundleProcess[libraryBundleId].bundle = data;
        })
        .catch(() => {
          toaster.pop("error", "Cloning bundle failed");
          $scope.addBundleProcess[libraryBundleId] = undefined;
        });
    }

    function addItemToNewBundle(bundleItem) {
      $scope.isProcessing.val = true;

      TrainingCenterBundleService.createBundleWithDefaults()
        .then(({ data }) => {
          const createdBundle = data.bundles[0];
          addItemToBundle(createdBundle, bundleItem);
        })
        .catch(() => {
          toaster.pop("error", "Failed to add bundle item to a new bundle");
        })
        .finally(() => ($scope.isProcessing.val = false));
    }

    function addItemToBundle(bundle, bundleItem) {
      $scope.isProcessing.val = true;

      const { agencyId } = $rootScope;
      const url = wildcard(
        `agencies/:agencyId/training_center/media_library/clone_bundle_item`,
        agencyId
      );

      DatabaseApi.post(url, {
        sourceBundleItemId: bundleItem.bundleItemId,
        targetBundleId: bundle.bundleId,
      })
        .then(({ data }) => {
          $scope.createdBundleItem.val = data.bundleItems[data.bundleItems.length - 1];
          $scope.selectedBundleId.val = bundle.bundleId;
        })
        .catch(() => toaster.pop("error", "Failed to add bundle item"))
        .finally(() => ($scope.isProcessing.val = false));
    }

    function addItemsToBundle(createdBundle, bundle) {
      $scope.isProcessing.val = true;
      
      const { agencyId } = $rootScope;
      const url = wildcard(
        `agencies/:agencyId/training_center/media_library/clone_bundle_items`,
        agencyId
      );

      DatabaseApi.post(url, {
        sourceBundleItemsIds: bundle.bundleItems.map(bundleItem => bundleItem.bundleItemId), //bundleItem.bundleItemId,
        targetBundleId: createdBundle.bundleId,
      })
      .then(({ data }) => {
        $scope.createdBundle.val = createdBundle.bundleId;
        $scope.selectedBundleId.val = bundle.libraryBundleId;
      })
      .catch(() => toaster.pop("error", "Failed to add bundle item"))
      .finally(() => ($scope.isProcessing.val = false));
    }

    onInit();
  };
