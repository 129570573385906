export const caregiverStatus = {
  all: [
    "PENDING",
    "ACTIVE",
    "ON_HOLD",
    "SUSPENDED",
    "TERMINATED",
    "ON_LEAVE",
    "JOIN_REQUEST",
    "QUIT",
    "REJECTED",
  ],
  dropdown: [
    { label: "Active", value: "ACTIVE" },
    { label: "On Hold", value: "ON_HOLD" },
    { label: "On Leave", value: "ON_LEAVE" },
    { label: "Pending Application", value: "PENDING" },
    { label: "Terminated", value: "TERMINATED" },
    { label: "Inactive", value: "SUSPENDED" },
    { label: "Quit", value: "QUIT" },
    { label: "Rejected", value: "REJECTED" },
  ],
} as const;
