//! @ngInject
export function uploadDocumentPdfModalCtrl($scope, $uibModalInstance, DatabaseApi, $rootScope, toaster) {
    $scope.data = $scope.$resolve.data.document;
    $scope.url = $scope.$resolve.data.url;
    $scope.successCallback = $scope.$resolve.data.successCallback;
    $scope.file = null;
    $scope.fileLoading = false;
    $scope.submit = async function () {
       if ($scope.file !== null) {
        $scope.fileLoading = true;
        DatabaseApi.post($scope.url,
            {
                base64: $scope.file === null ? null : await getBase64($scope.file),
                documentId: $scope.data.documentType || $scope.data.documentTypeId,
                documentVersion: $scope.data.versionId || null,
                documentTitle: null
            }
        ).then(function (res) {
            toaster.pop('success', "Manual submission successful", '', ' ');
            $uibModalInstance.close("ok");
            $scope.successCallback(res);
        }).catch(function (err) {
            toaster.pop('error', 'Error', 'Manual submission failed');
            console.error(err);
            $uibModalInstance.close(err);
        }).finally(() => {
            $scope.fileLoading = false;
        });
       } else {
            toaster.pop('error', 'Error', 'No file selected');
       }
    }

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
};

function getBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            reject();
        };
    })
}