/* @ngInject */
export function pageTitle($rootScope, $timeout, $transitions) {
  return {
    link: function (scope, element) {
      var listener = function (toState) {
        // Default title - load on Dashboard 1
        var title = "Medflyt | Connecting Home Health Agencies With Caregivers";
        // Create your own title pattern
        if (toState.data && toState.data.pageTitle) title = "Medflyt | " + toState.data.pageTitle;
        $timeout(function () {
          element.text(title);
        });
      };
      $transitions.onStart({}, function (trans) {
        const toState = trans.$to();
        listener(toState);
      });
    },
  };
}
