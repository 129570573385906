import { ComplianceStatus } from "../../../../scripts/messages/compliance";

//! @ngInject
class ComplianceStatusLabelCtrl implements ng.IComponentController {
  status!: ComplianceStatus;
}

interface Component extends angular.IComponentOptions {
  $name: string;
}

export const complianceStatusLabelComponent: Component = {
  $name: "complianceStatusLabel",
  templateUrl:
    "admin/modules/compliance/components/compliance-status-label/compliance-status-label.component.html",
  controller: ComplianceStatusLabelCtrl,
  controllerAs: "ctrl",
  bindings: {
    status: "<"
  },
};
