import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";
import { getBase64 } from "../../../scripts/utils/getBase64";

//! @ngInject
export function caregiverComplianceInstanceModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, $http, toaster, combinePdf, Consts, $q, generalUtils, wildcard, dateUtils) {
    function init() {
        if (!$scope.isEditMode && $scope.caregiverDocumentUploadId) {
            loadComplianceItemRelevantImage();
        }
    }
    
    $scope.document = {};
    $scope.isLoading = false;
    $scope.uploading = false;
    $scope.isEditMode = Boolean($scope.complianceItemInstance);

    const expiryDateOptionsMap = {
        'DAY': 'd',
        'WEEK': 'w',
        'MONTH': 'M'
    };
      
    function generateDateInDays(days) {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + days);
        return date;
    };

    $scope.getCalculatedExpiryDate = () => {
        if (!$scope.defaultExpirySettings) {
            return;
        }

        return moment($scope.complianceInstanceModel.effectiveDate)
                .clone()
                .add(
                    $scope.defaultExpirySettings.expiryAmount,
                    expiryDateOptionsMap[$scope.defaultExpirySettings.expiryUnit]
                );
    };

    $scope.setDefaultExpirationDateAccordingToDefault = () => {
        const updatedExpiryDate = $scope.getCalculatedExpiryDate()?.toDate();
        $scope.complianceInstanceModel.expiryDate = updatedExpiryDate;
    };
    $scope.complianceInstanceModel = {
        isCompliant: $scope.isEditMode
            ? $scope.complianceItemInstance.isCompliant
            : false,
        effectiveDate: $scope.isEditMode && $scope.complianceItemInstance.effectiveDate
            ? dateUtils.ymdStringToDate($scope.complianceItemInstance.effectiveDate)
            : new Date(),
        expiryDate: $scope.isEditMode && $scope.complianceItemInstance.expiryDate
            ? dateUtils.ymdStringToDate($scope.complianceItemInstance.expiryDate)
            : null,
        fields: $scope.fields,
        expires: $scope.item.expires,
        requireReVerification: $scope.item.requireReVerification,
        files: null,
        expiryDateButtons: [
            {
                title: "1 week",
                active: false,
                date: generateDateInDays(7),
            },
            {
                title: "1 month",
                active: false,
                date: generateDateInDays(30),
            },
            {
                title: "1 year",
                active: false,
                date: generateDateInDays(365),
            },
            {
                title: "Custom date",
                active: false,
                date: new Date()
            },
        ],
    };

    if ($scope.defaultExpirySettings && !$scope.isEditMode) {
        $scope.setDefaultExpirationDateAccordingToDefault();
    }

    $scope.defaultDateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    }

    $scope.expirationDatePickerOptions = {
        minDate: $scope.complianceInstanceModel.effectiveDate,
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    }

    $scope.editForm = {
        files: "keep",
    };

    function loadComplianceItemRelevantImage() {
        const url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.caregiverId +
            '/document_uploads/' + $scope.caregiverDocumentUploadId + '/generate_url';

        $scope.isLoading = true;
        generalUtils.getDocumentContentFromURL(url).then(res => {
            $scope.document = res;
            $scope.isLoading = false;
        }).catch(err => {
            toaster.pop('error', "Failed to present document");
            $scope.isLoading = false;
            $scope.closeModal();
        });
    }

    function transformFieldBeforeRequest(field) {
        let value = null;

        switch (field.type) {
            case "Text":
                value = field.model || null;
                break;

            case "Date":
                value = field.model.value ? $scope.dateToLocalDate(field.model.value) : null;
                break;

            case "Dropdown":
                value = field.possibleValues.find(pv => pv.id === parseInt(field.model)) ?? null;
                break;

            default:
                console.error(`Unhandled type: "${field.type}"`);
        }

        return {
            ...field,
            value: value
        };
    }

    function createComplianceInstance(params) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.caregiverId
        );

        DatabaseApi.post(url, params, true)
            .then((res) => {
                toaster.pop('success', "Item created successfully");
                $scope.uploading = false;
                $scope.closeModal(res);
                $rootScope.$emit("caregiver_compliance_item_created", res);
            }).catch((err) => {
                const message = err.status === 403
                    ? "You don't have permissions to edit caregiver compliance"
                    : "Could not create new compliance instance";
                toaster.pop('error', "Something went wrong", message);
                $scope.uploading = false;
            });
    }

    function updateComplianceInstance(params) {
        const url = wildcard(
            "hr/agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items/:complianceItemId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.caregiverId,
            $scope.complianceItemInstance.id
        );

        DatabaseApi.put(url, params, true)
            .then((res) => {
                toaster.pop('success', "Item created successfully");
                $scope.uploading = false;
                $scope.closeModal(res);
            }).catch((err) => {
                const message = err.status === 403
                    ? "You don't have permissions to edit caregiver compliance"
                    : "Could not update compliance instance";
                toaster.pop('error', "Something went wrong", message);
                $scope.uploading = false;
            });
    }

    async function handleFilesUpload() {
        $scope.uploading = true;

        switch ($scope.type) {
            case 'NEW_RECORD':
            case 'UPDATE_RECORD':
                if ($scope.complianceInstanceModel.files && $scope.complianceInstanceModel.files.length > 0) {
                    return $scope.uploadFiles();
                }
                break;

            case 'PENDING_UPLOAD':
                return $scope.caregiverDocumentUploadId;
        }

        return undefined;
    }

    $scope.uploadFiles = async () => {
        const deferred = $q.defer();

        try {
            const fetchPromisesWithTypes = [];

            if ($scope.type === 'UPDATE_RECORD' && $scope.editForm.files === "keep") {
                const url = `agencies/${$rootScope.agencyId}/caregivers/${$scope.caregiverId}/compliance_instances/${$scope.complianceItemInstance.id}/preview`;
                const existsFile = await DatabaseApi.get(url).then((res) => {
                    return $http.get(res.data.fileUrl, {responseType:'arraybuffer'}).then(fileData => {
                        console.log("fileData", fileData.data);
                        return new Blob([fileData.data], {
                            type: "application/pdf"
                        });
                    });
                });
                fetchPromisesWithTypes.push({
                    fetchPromise: await getBase64(existsFile), type: "pdf"
                });
            }
            for (const file of $scope.complianceInstanceModel.files) {
                const fetchCurrentFilePromise = await getBase64(file);
                if (file.type === 'application/pdf') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'pdf' });
                } else if (file.type === 'image/png') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'png' });
                } else if (file.type === 'image/jpg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpg' });
                } else if (file.type === 'image/jpeg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpeg' });
                }
            }
            const bytesPdf = await combinePdf(fetchPromisesWithTypes);
            const blob = new Blob([bytesPdf], { type: "application/pdf" });
            const combinedPdfFile = new File([blob], 'Combined.pdf');
            const url = `${Consts.api}hr/agencies/${$rootScope.agencyId}/caregivers/${$scope.caregiverId}/compliance_items/${$scope.itemId}/upload_file`;

            const formData = new FormData();
            formData.append('file', combinedPdfFile, combinedPdfFile.name);

            $http({
                url: url,
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': undefined }
            }).then((res) => {
                deferred.resolve(res.data.id);
            }).catch((err) => {
                console.error(err);
                toaster.pop('error', "Something went wrong", "Could not create new compliance instance");
                deferred.resolve(null);
            })
        } catch (e) {
            $scope.uploading = false;
            toaster.pop('error', "Something went wrong", "Could not create new compliance instance");
            deferred.resolve(null);
        }

        return deferred.promise;
    };

    $scope.dateToLocalDate = (date) => {
        if (date) {
            return LocalDate.from(nativeJs(moment(date)));
        }
        return undefined;
    }

    $scope.isEmptyField = (field) => {
        switch (field.type) {
            case "Text":
            case "Dropdown":
                return !Boolean(field.model);

            case "Date":
                return !Boolean(field.model.value);

            default:
                console.error(`Unhandled field type: ${field.type}`);
        }
    }

    $scope.isValidInput = () => {
        const mandatoryFields = $scope.fields.filter(field => field.isMandatory);

        for (const field of mandatoryFields) {
            if ($scope.isEmptyField(field)) {
                return false;
            }
        }

        return true;
    };

    $scope.submitForm = async function () {
        if ($scope.form.$invalid) {
            return;
        }

        const params = {
            caregiverDocumentTypeId: $scope.itemId,
            isCompliant: $scope.complianceInstanceModel.isCompliant,
            effectiveDate: LocalDate.from(nativeJs(moment($scope.complianceInstanceModel.effectiveDate))),
            expiryDate: $scope.complianceInstanceModel.expiryDate ?
                LocalDate.from(nativeJs(moment($scope.complianceInstanceModel.expiryDate)))
                : ($scope.isEditMode ? null : undefined),
            fields: $scope.fields.map(transformFieldBeforeRequest),
            caregiverDocumentUploadId: await handleFilesUpload()
        }

        if (params.caregiverDocumentUploadId === null) {
            $scope.uploading = false;
            return;
        }

        if ($scope.isEditMode) {
            updateComplianceInstance(params);
        } else {
            createComplianceInstance(params);
        }
    }

    $scope.closeModal = (res) => {
        if (res) {
            $uibModalInstance.close(res);
        } else {
            $uibModalInstance.dismiss();
        }
    };

    init();
};