import { Image, ImageProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Shimmer } from "react-shimmer";
import { queryKeys } from "../query-keys";

type Props = ImageProps & { src: string; fallback?: React.ReactNode };

const CachedSignedImage = (props: Props) => {
  const { width, height, src, fallback, ...rest } = props;

  const actualUrl = src.split("?")[0];

  const query = useQuery({
    queryKey: queryKeys.shared.image(actualUrl),
    queryFn: async () => {
      if (src.startsWith("blob:")) {
        return src;
      }

      const response = await fetch(src);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    staleTime: Infinity,
  });

  if (query.isLoading) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return (
      <Image src={query.data} w={width} h={height} borderRadius="lg" objectFit="cover" {...rest} />
    );
  }

  return null;
};

export const CachedSignedImageWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedImage
      {...props}
      fallback={<Shimmer width={props.width} height={props.height} />}
    />
  );
};

export default CachedSignedImage;
