import moment from "moment";

//! @ngInject
export function scheduledReportModalCtrl($scope, $rootScope, reportService, $uibModalInstance, toaster) {
    $scope.periodTypes = ["Daily", "Weekly", "Monthly"];
    $scope.daysWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    $scope.hours = [...Array(12).keys()].slice(1).map(number => ({label: `${number}:00`, value: number}));

    function init() {
        $scope.daysMonth = initDayMonths();
        $scope.hourModel = {
            hour: $scope.hours[0],
            type: "AM"
        };
        $scope.scheduledReport = initScheduleReport($scope.periodTypes[0]);
    }

    $scope.handleChangePeriodType = () => {
        $scope.scheduledReport = initScheduleReport($scope.scheduledReport.periodType);
    }

    $scope.toggleAmPm = () => {
        $scope.hourModel.type === "AM" ? $scope.hourModel.type = "PM" : $scope.hourModel.type = "AM";
    }

    $scope.handleChangeHour = (newHour) => {
        $scope.hourModel.hour = newHour;
    }

    $scope.handleChangeDayWeek = (newDayWeek) => {
        $scope.scheduledReport.dayOfWeek = newDayWeek;
    }

    $scope.handleChangeDayMonth = (newDayMonth) => {
        $scope.scheduledReport.dayOfMonth = newDayMonth;
    }

    $scope.handleSave = () => {
        const timeInDay = calculateTimeInDay();

        let scheduledReportParams = {
            periodType: $scope.scheduledReport.periodType.toUpperCase(),
            timeInDayDate: moment().set({hours: timeInDay, minutes: 0, seconds: 0}),
            interval: null,
            dayOfWeek: null,
            dayOfMonth: null,
            timezone: moment.tz.guess(true)
        };

        if ($scope.scheduledReport.periodType === "Weekly") {
            scheduledReportParams.interval = $scope.scheduledReport.interval;
            scheduledReportParams.dayOfWeek = $scope.scheduledReport.dayOfWeek.toUpperCase();
        } else if ($scope.scheduledReport.periodType === "Monthly") {
            scheduledReportParams.interval = $scope.scheduledReport.interval;
            scheduledReportParams.dayOfMonth = $scope.scheduledReport.dayOfMonth;
        }

        reportService.addScheduledSavedReport($scope.savedReportId, scheduledReportParams).then(() => {
            toaster.pop("success", "Success", "Added scheduled report succeffuly");
            $rootScope.$broadcast("new_scheduled");
            $scope.closeModal();
        }).catch(err => {
            toaster.pop("error", "Something went wrong", "Failed to add scheduled report");
        });
    }

    const initScheduleReport = (periodType) => {
        return {
            periodType: periodType,
            interval: 1,
            dayOfWeek: "Monday",
            dayOfMonth: 1
        };
    }

    const calculateTimeInDay = () => {
        let timeInLocalFormat;
        
        if ($scope.hourModel.type == "AM") {
                timeInLocalFormat = $scope.hourModel.hour.value;
        } else {
            timeInLocalFormat = $scope.hourModel.hour.value + 12;
        }

        return timeInLocalFormat;
    }

    const initDayMonths = () => {
        const days = [];

        for (let index = 1; index < 30; index++) {
            days.push({
                label: `${index}`,
                value: index
            });
        }

        days.push({
            label: '29 (Except February)',
            value: 29
        });
        days.push({
            label: '30 (Except February)',
            value: 30
        });
        days.push({
            label: 'Last day of month',
            value: 31
        });

        return days;
    }

    $scope.closeModal = () => {
        $uibModalInstance.dismiss();
    };

    init();
};