//! @ngInject
export function visitEditRequestsCtrl($scope, $rootScope, $state, DatabaseApi, $q, $stateParams, toaster, $filter, NgTableParams, SweetAlert, $uibModal, Analytics){


  window.scrollTo(0,0);

  $scope.visits = DatabaseApi.visitPendingEditRequests();
  $scope.history = DatabaseApi.visitArchivedEditRequests();

  if(!$scope.visits){
    init();
  } else {
    initPendingTable($scope.visits);
  }

  if(!$scope.visits){
    initHistory();
  } else {
    initHistoryTable($scope.history);
  }

  $rootScope.$on('VisitPendingRequestsUpdated', function(event){
    init();
  });

  $rootScope.$on('VisitArchivedRequestsUpdated', function(event){
    initHistory();
  });

  function init(){
    $scope.visits = DatabaseApi.visitPendingEditRequests();
    if($scope.visits) initPendingTable($scope.visits);
  }

  function initHistory(){
    $scope.history = DatabaseApi.visitArchivedEditRequests();
    if($scope.history) initHistoryTable($scope.history);
  }

  function initPendingTable(data) {

      if(!data) return;

    data.forEach(function(row){
      row.timeStamp = new Date(row.editRequestParams.createdAt).getTime();
    });

    $rootScope.pendingVisitEditRequests = data.length;

    var options = {
      count: 25,
      sorting: {id: "asc"}
    };

    $scope.table = new NgTableParams(options, {
      counts: [],
      dataset: data
    });
  }

  function initHistoryTable(data) {

    if(!data) return;

    data.forEach(function(row){
      row.timeStamp = new Date(row.editRequestParams.createdAt).getTime();
    });

    var options = {
      count: 25,
      sorting: {id: "asc"}
    };

    $scope.historyTable = new NgTableParams(options, {
      counts: [],
      dataset: data
    });
  }

  $scope.goToVisit = function(id){
    $state.go('app.single-visit', {id:id});
  };


  $scope.openModal = function(row){
    var modalInstance = $uibModal.open({
        templateUrl: 'visit-request-modal.html',
        size: 'md',
        controller: 'visitRequestsModalCtrl',
        resolve: {
            visit: function() { return row; }
        }
    });
    modalInstance.result.then(function (res) {
        console.log(res);
        if(res){
          var idx = -1;
          $scope.visits.forEach(function(visit,i){
            if(row.scheduledVisitDetails.id === row.scheduledVisitDetails.id){
              idx = i;
            }
          });

          if(idx >= 0) {
            $scope.visits.splice(idx,1);
            initPendingTable($scope.visits);
          }
        }
    }, function () {

    });
  };

};

//! @ngInject
export function visitRequestsModalCtrl($scope, $rootScope, $uibModalInstance, visit, DatabaseApi, toaster, SweetAlert, Analytics){

  window.scrollTo(0,0);

  console.log('modal');
  $scope.visit = visit;

  $scope.form = {
    message: '',
    approved: false
  };

  $scope.closeModal = function(){
    $uibModalInstance.dismiss();
  };

  $scope.action = function(action){

    if($scope.sending) return;
    $scope.sending = true;

    var title = 'Decline Request?';
    var confirm = 'Yes, decline request';
    $scope.form.approved = false;

    if(action === 'cancel'){
      title = 'Cancel Visit?';
      confirm = 'Yes, cancel visit';
      $scope.form.approved = true;

    } else if(action === 'reschedule'){
      title = 'Reschedule Visit?';
      confirm = 'Yes, reschedule visit';
      $scope.form.approved = true;
    }

    SweetAlert.swal({
        title: title,
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: confirm,
        closeOnConfirm: true,
        closeOnCancel: true
    }, function (isConfirm) {
        if (isConfirm) {

          console.log(visit);
          var url = 'agency_member/' + $rootScope.agencyMemberId + '/scheduled_visit/' + visit.editRequestParams.requestId + '/edit_request';
          DatabaseApi.put(url, $scope.form).then(function(res){
            toaster.pop('success', "Success", '');
            $uibModalInstance.close(true);
            $scope.sending = false;

            Analytics.event('visit-edit-request',{
              request: title,
              isApproved: $scope.form.approved
            });

          }, function(err){
            $scope.sending = false;
            toaster.pop('error', `Something went wrong", "Error while ${title}`);
            console.log(err.data.error ? err.data.error : err.data)

          });

        } else {
          $scope.sending = false;
        }
    });

  };




};