import React from "react";
import { Messages } from "../../../core/api";
import { getEntityPhotoUrl } from "../../../shared/components/EntityCard";
import EntitySelect from "../../../shared/components/EntitySelect";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import PatientIcon from "../../../shared/icons/PatientIcon";
import { PatientId } from "../../../shared/schema/schema";
import { usePatients } from "../hooks/usePatients";
import { useSearchPatientsQuery } from "../hooks/useSearchPatientsQuery";

type PatientResult = Pick<
  Messages["GlobalSearchPatientResult"],
  "id" | "firstName" | "middleName" | "lastName" | "displayId" | "gender"
>;

type Props = {
  value: PatientId | null;
  isDisabled?: boolean;
  onChange: (value: PatientResult | null) => void;
};

const PatientSelect = (props: Props) => {
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const searchQuery = useSearchPatientsQuery({ term: debouncedSearchText });
  const patientsQuery = usePatients();

  return (
    <EntitySelect
      entitiesQuery={patientsQuery}
      searchQuery={searchQuery}
      searchText={searchText}
      value={props.value}
      buttonPlaceholder="Select patient"
      inputPlaceholder="Search patient..."
      notFoundPlaceholder="No results"
      isDisabled={props.isDisabled}
      leftIcon={<PatientIcon fontSize={18} />}
      photoUrlPredicate={getEntityPhotoUrl}
      onChangeSearchText={setSearchText}
      onChange={props.onChange}
    />
  );
};

export default PatientSelect;
