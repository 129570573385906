import { LoginResponse } from "../messages/user_auth";
import { LoginDataStorageService } from "../services/loginDataStorageService";

//! @ngInject
class LoginCtrl implements ng.IComponentController {
  public forgotPass = false;
  public email?: string;
  public password?: string;
  public twoFactorCode?: string;
  public twoFactorCodeRequired = false;
  public showPop = false;
  public disable = false;
  public error = "";
  public sending = false;
  public emailSent = false;
  public popCode = "";

  private counter = 0;
  constructor(
    private $rootScope: ng.IRootScopeService,
    private DatabaseApi: any,
    private Storage: Storage,
    private $http: ng.IHttpService,
    private Consts: any,
    private $timeout: ng.ITimeoutService,
    private loginDataStorageService: LoginDataStorageService,
    private $state: ng.ui.IStateService,
    private Analytics: any,
    private Socket: any,
    private ClientSurvey: any
  ) {}

  private handleLoginError(error: any, email: string) {
    let errorMsg: string | undefined;
    if (error.status === 401) {
      errorMsg = "Incorrect Credentials";
      this.twoFactorCodeRequired = false;
    } else if (error.status === 419) {
      this.twoFactorCodeRequired = true;
    } else if (error.status === 403) {
      if (this.twoFactorCodeRequired) {
        errorMsg = "Incorrect Code";
      } else {
        errorMsg = "Login is not permitted";
        alert("User login blocked, either user or associated agency is inactive.");
      }
    } else if (error.status >= 500 && error.status <= 599) {
      errorMsg = "Server Error " + error.status;
    } else if (error.status === -1) {
      errorMsg = "Internet Connection Error";
    } else if (error.status === 423) {
      errorMsg = "User is blocked";
      alert("User login blocked, too many consecutive login attempts.");
    } else {
      errorMsg = "Uknown Error";
      alert("Uknown Error:\n" + JSON.stringify(error));
    }

    this.sending = false;
    this.disable = false;
    this.error = errorMsg ?? "";
    console.error("login error");
    console.error(error);
    if (!this.twoFactorCodeRequired) {
      this.Analytics.event("login-failed", {
        email,
      });
    }
  }

  popInput = () => {
    if (this.counter >= 5) this.showPop = true;
    this.counter++;
  };
  insertCode = (code: string) => {
    if (!this.popCode) return;
    this.Storage.set("code", code);
    this.counter = 0;
    this.showPop = false;
    this.popCode = "";
  };
  onForgotPassClick = () => {
    this.forgotPass = !this.forgotPass;
    this.disable = false;
    this.error = "";
  };
  resetPassword = async (email: string) => {
    try {
      await this.DatabaseApi.post("auth/forgot_password", { email: email });
      this.sending = false;
      this.disable = true;
      this.emailSent = true;
    } catch {
      this.error = "Email unknown, Please try again.";
      this.sending = false;
      this.disable = false;
    }
  };

  login = async () => {
    this.sending = true;
    const email = this.email;
    const pass = this.password;
    const twoFactorCode = this.twoFactorCode;
    if (this.forgotPass && email) return this.resetPassword(email);
    if (email && pass) {
      const body = {
        email: email.toLowerCase(),
        password: pass,
        twoFactorCode: twoFactorCode,
        code: this.Storage.get("code") || undefined,
      };

      // ********* Old Login *********
      // this.$rootScope.loginType = "AUTH_TOKEN";
      // try {
      //   const res: { data: OldLoginResponse } = await this.$http.post(
      //     this.Consts.api + `auth/login`,
      //     body
      //   );

      //   this.Socket.init({ ...res.data, loginType: "AUTH_TOKEN" });
      //   this.loginDataStorageService.setLoginData(
      //     { ...res.data, loginType: "AUTH_TOKEN" },
      //     res.data.userId,
      //     email,
      //     res.data.authToken
      //   );
      // } catch (e) {
      //   this.$timeout(() => this.handleLoginError(e, body.email), 0);
      // }

      this.$rootScope.loginType = "JWT";
      try {
        const res: { data: LoginResponse } = await this.$http.post(
          this.Consts.api + `auth/new_login`,
          body
        );

        this.$timeout(() => {
          const userDetails = this.loginDataStorageService.setLoginDataFromAccessToken(
            res.data.accessJWT,
            res.data.refreshJWT,
            res.data.permissions
          );
          this.Socket.init(userDetails);
          this.DatabaseApi.connect();
          this.DatabaseApi.GetSettings();
          this.Analytics.event("login");
          setTimeout(() => {
            this.$state.go("app.dashboard");
          }, 1200);
        }, 0);
      } catch (e) {
        this.$timeout(() => this.handleLoginError(e, email), 0);
      }
    } else {
      this.$timeout(() => {
        this.sending = false;
        this.disable = false;
        return;
      }, 0);
    }
  };
}
interface Component extends angular.IComponentOptions {
  $name: string;
}

export const loginComponent: Component = {
  $name: "loginComponent",
  templateUrl: "admin/views/login.html",
  controller: LoginCtrl,
  controllerAs: "ctrl",
};
