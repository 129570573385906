import { LocalDate } from "@js-joda/core";
import React from "react";
import { RangeDatePickerProps } from "./RangeDatePicker";

type RangeDatePickerDates = [LocalDate, LocalDate | null] | [null, null];

interface Props {
  initialDates?: RangeDatePickerDates;
}

interface State {
  open: boolean;
  dates: RangeDatePickerDates;
}

const useRangeDatePicker = (props?: Props) => {
  const [state, setState] = React.useState<State>(() => {
    return {
      dates: props?.initialDates ?? [null, null],
      open: false,
    };
  });

  const open = () => setState((prev) => ({ ...prev, open: true }));
  const close = () => setState((prev) => ({ ...prev, open: false }));

  const pickerProps: Partial<RangeDatePickerProps> = {
    open: state.open,
    onInputClick: open,
    onFocus: open,
    onClickOutside: close,
  };

  return {
    pickerProps,
    actions: { open, close },
  };
};

export default useRangeDatePicker;
