import { LocalDate } from "js-joda";

//! @ngInject
export function hoursUsageCtrl(
  $scope,
  $rootScope,
  NgTableParams,
  itemSearchPageManager,
  toaster,
  dateUtils,
  DatabaseApi
) {

  const initialize = () => {
    $scope.hoursUsageTable = null;
    $scope.globalFilter = { val: "" };
    $scope.compareOptions = getResetCompareOptions();
    initAssets();
    if (!$scope.gotVisitSettings) {
      return;
    }
    $scope.firstDayOfWeek = parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek || 0);
    initPageManager();
    $scope.pageManager.resetSearchParamsByUrl();
    initDateRangePresets();
    $scope.localDateToMDYString = dateUtils.localDateToMDYString;
    $scope.selectToday = initDateRangePresets;
    $scope.are2Selected = false;
    $scope.compareOption2Name = "Not Selected";
    $scope.compareOption1Name = "Not Selected";
  };

  $scope.toggleCompareOptionSelection = (index) => {
    if (!$scope.compareOptions[index]) {
      return;
    }
    resetTable()
    $scope.compareOptions[index].selected = !$scope.compareOptions[index].selected;
    const selectedOptions = getSelectCompareOptions();
    $scope.are2Selected = selectedOptions.length === 2;
    $scope.pageManager.updateSearchParamValue("compareOptions", convertToPipelineArray(selectedOptions));
    $scope.compareOption1Name = selectedOptions[0]?.label ?? "Not Selected";
    $scope.compareOption2Name = selectedOptions[1]?.label ?? "Not Selected";
  }

  const getResetCompareOptions = () => {
    return [{
      label: "Weekly Template",
      name: "weekly_template",
      selected: false
    }, {
      label: "Scheduled Hours",
      name: "scheduled_hours",
      selected: false
    }, {
      label: "Actual Hours",
      name: "actual_hours",
      selected: false
    },
    // {
    //   label: "Authorization Hours",
    //   name: "authorization",
    //   selected: false
    // }
    ];
  };

  $scope.singleSelectionExtraSettings = {
    styleActive: true,
    singleSelection: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1,
    closeOnSelect: true,
    showCheckAll: false,
    showUncheckAll: false,
  };

  const initPageManager = () => {
    $scope.pageManager = itemSearchPageManager.createSearchPageManager("/hours_usage");
    $scope.pageManager.initFromToDateParams(false, false, "startDate", "endDate");
    $scope.pageManager.initSearchParam("compareOptions", convertToPipelineArray(getSelectCompareOptions()));
  };

  const getSelectCompareOptions = () => {
    return $scope.compareOptions.filter(x => x.selected);
  }

  const convertToPipelineArray = (selectedCompareOptions) => {
    return selectedCompareOptions.map(x => x.name).join("|");
  }

  const getStartOfWeek = () => {
    const nowLocalDate = LocalDate.now();
    let nowDayOfWeek = nowLocalDate.dayOfWeek().value(); // 1 [MONDAY] - 7 [SUNDAY]
    if (nowDayOfWeek === 7) nowDayOfWeek = 0; // SUNDAY
    let sub = nowDayOfWeek - $scope.firstDayOfWeek; // firstDayOfWeek (0 [SUNDAY] - 6 [SATURDAY])
    if (sub < 0) sub += 7;
    const startOfWeekLocalDate = nowLocalDate.minusDays(sub);
    return startOfWeekLocalDate;
  };

  const initDateRangePresets = () => {
    const startOfThisWeek = getStartOfWeek();
    const endOfThisWeek = startOfThisWeek.plusDays(6);
    $scope.dateRange = {
      start: startOfThisWeek,
      end: endOfThisWeek
    };
    updateDateRangeParams();
  };

  $scope.updateWeek = (toAdd) => {
    resetTable();
    const isNegative = toAdd < 0;
    const daysToAdd = Math.abs(toAdd * 7);
    $scope.dateRange = {
      start: isNegative ? $scope.dateRange.start.minusDays(daysToAdd) : $scope.dateRange.start.plusDays(daysToAdd),
      end: isNegative ? $scope.dateRange.end.minusDays(daysToAdd) : $scope.dateRange.end.plusDays(daysToAdd)
    }
    updateDateRangeParams();
  }

  const resetTable = () => {
    $scope.hoursUsageRows = [];
    initTable();
  }

  const updateDateRangeParams = () => {
    $scope.pageManager.updateSearchParamValue("startDate", dateUtils.localDateToDate($scope.dateRange.start));
    $scope.pageManager.updateSearchParamValue("endDate", dateUtils.localDateToDate($scope.dateRange.end));
  }

  $scope.searchHoursUsage = () => {
    $scope.globalFilter.val = "";
    $scope.pageManager.executeSearch().then((res) => {
      $scope.hoursUsageRows = mapItems(res.data.data);
      initTable();
    }, (error) => {
      console.error(error);
      if (error.errors && Array.isArray(error.errors)) {
        toaster.pop("error", `Failed to load hours usage data: ${error.errors.join()}`);
      } else {
        toaster.pop("error", "Failed to load hours usage data");
      }
      resetTable()
    });
  };

  const mapItems = (items) => items.map(item => ({
    ...item,
    firstNameLastName: `${item.firstName} ${item.lastName}`,
    lastNameFirstName: `${item.lastName} ${item.firstName}`,
  }));

  const initTable = () => {
    const options = {
      count: 25
    };
    $scope.hoursUsageTable = new NgTableParams(options, {
      dataset: $scope.hoursUsageRows
    });
  };

  $scope.applyGlobalSearch = (term) => {
    const filter = { $: term };
    if ($scope.hoursUsageTable) {
      angular.extend($scope.hoursUsageTable.filter(), filter);
    }
  };

  const initAssets = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.gotVisitSettings = $scope.initializeMap['visitSettings'];
  };

  $rootScope.$on("got_visit_settings", () => {
    initialize();
  });

  initialize();
};