import { CaregiverId } from "../../../../scripts/messages/ids";

//! @ngInject
class CaregiverChatButtonCtrl implements ng.IComponentController, Bindings {
  // --- Bindings --- //
  caregiverId!: CaregiverId;
  // --- END Bindings --- //

  constructor(private $rootScope: angular.IRootScopeService) {}

  openAgencyChat = () => {
    console.log("this.caregiverId", this.caregiverId);
    this.$rootScope.openAgencyChat(this.caregiverId);
  };
}

interface Bindings {
  caregiverId: CaregiverId;
}

export const caregiverChatButtonComponent = {
  $name: "caregiverChatButton",
  templateUrl:
    "admin/modules/shared/components/caregiver-chat-button/caregiver-chat-button.component.html",
  controller: CaregiverChatButtonCtrl,
  controllerAs: "ctrl",
  bindings: {
    caregiverId: "<",
  },
};
