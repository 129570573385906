import React from "react";
import { AuthContext } from "../../core/auth";

const useAuth = () => {
  const { authInfo, setTokens } = React.useContext(AuthContext);

  const logout = (onLoggedOut: () => void) => {
    const clearExcept = [
      "stealthMode",
      "code",
      "showVideoNewVisitForm",
      "caregiversTableSettings",
      "trainingTableSettings",
      "pendingApplicationTableSettings",
      "coronaReportsTableSettings",
      "medflytPassportTableSettings",
      "billingAccountsReceivableTableSettings",
      "savedAssignedCoordinators",
      "faxComment",
      "localDistHash",
      "hide-sweep-vegas",
      "hide-reset-attempts-video",
      "hide-corona-banner",
      "trainingCenterStatisticsTableColumn",
      "trainingCenterOverallStatisticsTableColumn",
      "searchFiltersSettings",
      "visitsDashboardTableSettings",
      "stateOfHomeCareSurvey",
      "patientCalendarShowDeletedVisits",
      "caregiverCalendarShowDeletedVisits",
      "ClientSurveyNextDate",
    ];

    const localStorageKeys = Object.keys(localStorage);
    for (const key of localStorageKeys) {
      if (!clearExcept.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    return onLoggedOut();
  };

  return { authInfo, logout, setTokens };
};

export default useAuth;
