//! @ngInject
export function notesSettingsCtrl($scope, $rootScope, NgTableParams, toaster, DatabaseApi, noteConsts, noteService, entityNoteService) {
    $scope.agencyNoteSubjectGlobalFilter = { val: "" };

    $scope.agencyNoteSubjects = DatabaseApi.agencyNoteSubjects();
    $scope.agencyEntityNotes = DatabaseApi.agencyEntityNoteSettings();

    $scope.noteSubjectsFilters = {
        showOnlyActiveAgencySubjects: true,
        showDefaultAgencySubjects: false
    };

    function initialize() {
        initNoteSettingsTables();
    }

    const noteSubjectsFilterByMethods = {
        showDefaultSubject: (noteSubject, showDefaultSubject) => {
            if (showDefaultSubject === false && noteSubject.agencyId === null) {
                return false;
            }
            return true;
        },
        showActiveAgencySubjects: (noteSubject, showActiveSubject) => {
            return showActiveSubject ? noteSubject.active === true : true
        }
    };

    $scope.applyAgencyNoteSubjectGlobalSearch = () => {
        const filter = { $: $scope.agencyNoteSubjectGlobalFilter.val };
        if ($scope.noteSubjectsTable) {
            angular.extend($scope.noteSubjectsTable.filter(), filter);
        }

        let flattedNewAgencySubject = $scope.agencyNoteSubjectGlobalFilter.val.replace( /  +/g, ' ' ).trim().toLowerCase();
        if ($scope.allNotesSettingsTitles.includes(flattedNewAgencySubject)) {
            $scope.similarNoteSettingTitle = true;
        } else {
            $scope.similarNoteSettingTitle = false;
        }
    };

    function filterAgencyNoteSubjectsTable() {
        if (!$scope.agencyNoteSubjects) return;
        let filters = [];

        const showDefaultAgencySubjects = $scope.noteSubjectsFilters.showDefaultAgencySubjects;
        filters.push(function (item) { return noteSubjectsFilterByMethods.showDefaultSubject(item, showDefaultAgencySubjects); });

        const showOnlyActiveAgencySubjects = $scope.noteSubjectsFilters.showOnlyActiveAgencySubjects;
        filters.push(function (item) { return noteSubjectsFilterByMethods.showActiveAgencySubjects(item, showOnlyActiveAgencySubjects); });

        let filteredSubjects = $scope.agencyNoteSubjects;
        if (filters.length > 0) {
            filteredSubjects = filteredSubjects.filter(function (noteSubject) {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](noteSubject);
                }
                return isFiltered;
            });
        }

        initNoteSubjectsTable(filteredSubjects);
    }

    function mapEntityNoteItems() {
        $scope.agencyEntityNotes.forEach(item => {
            item.text = noteConsts.NoteTypesTranslations[item.noteType];
        });
    }

    function initNoteSettingsTables() {
        const flattedAgencyNoteSubjects = $scope.agencyNoteSubjects.map(subject => subject.text.trim().toLowerCase());
        const flattedEntitySettingsTitles = $scope.agencyEntityNotes.map(entityNote => noteConsts.NoteTypesTranslations[entityNote.noteType].trim().toLowerCase());
        $scope.allNotesSettingsTitles = flattedAgencyNoteSubjects.concat(flattedEntitySettingsTitles);

        filterAgencyNoteSubjectsTable();
        mapEntityNoteItems();

        const entityNotesOptions = {
            count: 15,
            sorting: { text: "asc" }
        };

        $scope.entityNotesTable = new NgTableParams(entityNotesOptions, {
            dataset: $scope.agencyEntityNotes
        });
    }

    function initNoteSubjectsTable(items) {
        const agencySubjectsOptions = {
            count: 10,
            sorting: { text: "asc" }
        };

        $scope.noteSubjectsTable = new NgTableParams(agencySubjectsOptions, {
            dataset: items
        });
    }

    function handleCreateEntityNoteSetting(noteSetting) {
        const body = {
            agencyId: $rootScope.agencyId,
            noteType: noteSetting.noteType,
            hasPredefinedField: noteSetting.hasPredefinedField,
            isPredefinedMandatory: noteSetting.isPredefinedMandatory,
            hasMessageField: noteSetting.hasMessageField,
            isMessageMandatory: noteSetting.isMessageMandatory,
            showOnPatientProfile: noteSetting.showOnPatientProfile,
            showOnCaregiverProfile: noteSetting.showOnCaregiverProfile
        };

        return entityNoteService.createAgencyEntityNoteSetting(body);
    };

    $scope.handleUpdateEntityNoteSetting = (noteSetting, field) => {
        if (noteSetting.agencyId === null) {
            return handleCreateEntityNoteSetting(noteSetting).then((res) => {
                noteSetting.id = res.data;
                noteSetting.agencyId = $rootScope.agencyId;
                toaster.pop('success', "Note Setting created successfully");
            }).catch(function (_) {
                toaster.pop("error", "Failed to create note setting");
            });
        }

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/entity_notes/settings/:settingsId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":settingsId", noteSetting.id);

        const body = {};
        body[field] = noteSetting[field];

        DatabaseApi.patch(url, body).then(() => {
            if (field === 'hasPredefinedField' && noteSetting[field] === false) {
                noteSetting.isPredefinedMandatory = false;
            }

            if (field === 'hasMessageField' && noteSetting[field] === false) {
                noteSetting.isMessageMandatory = false;
            }

            toaster.pop('success', "Setting updated successfully");
        }).catch(function (_) {
            toaster.pop("error", "Failed to updates note setting");
        });
    };

    $scope.handleCreateNoteSubjectSetting = () => {
        const body = {
            text: $scope.agencyNoteSubjectGlobalFilter.val.replace( /  +/g, ' ' ).trim(),
            active: true,
            showOnCaregiverProfile: false,
            showOnPatientProfile: false
        };

        $scope.isSubmmittingAgencyNoteSubject = true;
        noteService.createAgencyNoteSubject($rootScope.agencyId, $rootScope.agencyMemberId, body).then(res => {
            DatabaseApi.getAgencyNoteSettings();
            toaster.pop('success', "Note subject created successfully");
        }).catch(function (_) {
            toaster.pop("error", "Failed to create note subject");
        }).finally(() => {
            $scope.agencyNoteSubjectGlobalFilter.val = "";
            $scope.isSubmmittingAgencyNoteSubject = false;
        });
    };

    $scope.handleUpdateNoteSubjectSetting = (noteSetting, field) => {
        const body = {};
        body[field] = noteSetting[field];

        noteService.editAgencyNoteSubject($rootScope.agencyId, $rootScope.agencyMemberId, noteSetting.id, body).then(res => {
            DatabaseApi.getAgencyNoteSettings();
            toaster.pop('success', "Setting updated successfully");
        }).catch(function (_) {
            toaster.pop("error", "Failed to updates note setting");
        });
    };

    $scope.$watch('noteSubjectsFilters', function () {
        filterAgencyNoteSubjectsTable();
    }, true);

    initialize();
    $rootScope.$on("got_notes_settings", function (event) {
        $scope.agencyNoteSubjects = DatabaseApi.agencyNoteSubjects();
        $scope.agencyEntityNotes = DatabaseApi.agencyEntityNoteSettings();
        initialize();
    });
};