import angular from "angular";
import { assertNever } from "../../../../scripts/consts/assertNever.const";
import { mfShortDateFilter } from "../../../../scripts/directives/directives";
import "./editable-label.component.scss";

export interface MultiselectOption {
  id: unknown;
  label?: string;
}

export type InputType =
  | "Address"
  | "Date"
  | "Select"
  | "Multiselect"
  | "Phone"
  | "Text"
  | "Number"
  | "Callback"
  | "SSN";

//! @ngInject
class EditableLabelCtrl implements ng.IComponentController {
  // ---- bindings ----- //
  options?: MultiselectOption[];
  type!: InputType;
  currentValue!: unknown;
  title?: string;
  name!: string;
  isEditMode!: boolean;
  onDone!: () => (fieldName: string, value: unknown) => void;
  onClickEdit?: () => () => void;
  // ------------------- //

  model: unknown = null;

  $onInit() {
    if (this.type === "Multiselect" && Array.isArray(this.currentValue)) {
      const currentValues = this.currentValue as unknown[];
      this.currentValue = this.options?.filter((option) => currentValues.includes(option.id));
    }

    if (this.type === "Select") {
      this.currentValue = this.options?.find((option) => option.id === this.currentValue);
    }

    this.model = angular.copy(this.currentValue);
  }

  updateValue = () => {
    this.onDone()(this.name, formatRequestValue(this.type, this.model));
    this.currentValue = angular.copy(this.model);
  };

  cancel = () => {
    this.model = angular.copy(this.currentValue);
  };

  displayValue = () => {
    return formatDisplayValue(this.type, this.currentValue);
  };

  isDirty = () => {
    return !angular.equals(this.model, this.currentValue);
  };
}

function formatDisplayValue(type: InputType, value: unknown) {
  if (value === null || value === undefined || `${value}`.trim() === "") {
    return "N/A";
  }

  switch (type) {
    case "Address":
      return value;

    case "Date":
      return mfShortDateFilter()(value);

    case "Select": {
      const selectedValue = isOptionObject(value) ? value.label : value;

      return selectedValue ?? "N/A";
    }

    case "Multiselect": {
      const selectedValues = Array.isArray(value) ? value.filter(isOptionObject) : [];

      return selectedValues?.map((option) => option.label ?? option.id).join(", ") ?? "N/A";
    }

    case "Phone":
    case "Text":
    case "Number":
    case "Callback":
    case "SSN":
      return value;

    default:
      assertNever(type);
  }
}

function formatRequestValue(type: InputType, value: unknown) {
  switch (type) {
    case "Select": {
      const selectedValue = isOptionObject(value) ? value.id : value;

      return selectedValue ?? null;
    }

    case "Multiselect": {
      const selectedValues = Array.isArray(value) ? value.filter(isOptionObject) : [];

      return selectedValues?.map((option) => option.id);
    }

    case "Address":
    case "Date":
    case "Phone":
    case "Text":
    case "Number":
    case "Callback":
    case "SSN":
      return value;

    default:
      assertNever(type);
  }
}

function isOptionObject(value: unknown): value is MultiselectOption {
  return typeof value === "object" && value !== null && "id" in value;
}

export const editableLabelComponent = {
  $name: "editableLabel",
  templateUrl: "admin/modules/shared/components/editable-label/editable-label.component.html",
  controller: EditableLabelCtrl,
  controllerAs: "ctrl",
  bindings: {
    options: "<?",
    type: "<",
    currentValue: "<",
    title: "<?",
    name: "<",
    isEditMode: "<",
    onDone: "&",
    onClickEdit: "&?",
  },
};
