import phoneUtils from "../utils/phoneUtils";

//! @ngInject
export function registerCtrl(GoogleAddressService, $scope, $rootScope, $state, Storage, DatabaseApi, $window, $location, toaster, Socket, Analytics) {

  window.scrollTo(0, 0);
  $scope.form = { agency: {} };
  $scope.token = $location.search().token || undefined;
  $scope.reset = $location.search().reset || undefined;
  //if($scope.reset) $scope.token = true;

  console.log($scope.reset, $scope.token);
  var validPass = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9\S])(.{8,30})$/);

  $scope.register = async function () {

    $scope.sending = true;
    $scope.pass = false;

    var flag = false;
    if ($scope.regForm.$invalid) {
      flag = true;
    }
    if (($scope.reset || $scope.token) && !validPass.test($scope.form.password)) {
      flag = true;
      $scope.pass = true;
    }

    // regular register
    if (!$scope.token && !$scope.reset) {
      var validEmail = checkEmails($scope.form.email);

      if (!validEmail) {
        console.log('bad email');
        $scope.emailError = true;
        return $scope.sending = false;
      }
      //var companyType = $scope.form.type;
      //$rootScope.setCompanyType(companyType);
    }
    console.log('start b');
    if (flag) return $scope.sending = false;


    // reset password or complete registration
    if ($scope.reset || $scope.token) return reset($scope.form.password);

    console.log('start');

    $scope.form.termsAgreed = true;
    $scope.form.agency.officePhone = '+1' + $scope.form.officePhone;
    if (!phoneUtils.isValidNumber($scope.form.officePhone)) return $scope.error = 'Invalid Phone Number';
    try {
      $scope.agencyAddressComponents = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode($scope.form.agency.address);
      $scope.form.agency.address = $scope.agencyAddressComponents.formatedAddressDetails.fullAddress;
      $scope.form.agency.addressComponents = $scope.agencyAddressComponents;
    }
    catch (e) {
      toaster.pop('error', 'Invalid address: ' + e.message);
    }
    DatabaseApi.post('agencies', $scope.form)
      .then(function (res) {
        $scope.form.password = undefined;
        $scope.form.rePassword = undefined;
        console.log(res);
        $scope.sending = false;
        $scope.sent = true;
        $scope.emailSent = true;


        Analytics.event('new-agency', {
          name: $scope.form.agency.name,
          email: $scope.form.email
        });

        /*
         if($scope.form.token){

         $scope.form.token = undefined;

         DatabaseApi.connect();

         Storage.setObject('user', res.data);
         $rootScope.token = res.data.token;
         $rootScope.user = res.data.user;

         $rootScope.user.displayName = $rootScope.user.firstName + ' ' + $rootScope.user.lastName;
         $rootScope.setCompanyType($rootScope.user.companyType);

         DatabaseApi.GetSettings();

         $state.go('app.dashboard');

         }
         */

      }, function (err) {

        if (err.status === 409) {
          $scope.error = 'Domain is taken';
        }
        else {
          $scope.error = 'Something Went Wrong';
        }
        $scope.sending = false;
        console.log(err);

      });

  };

  function reset(pass) {
    var form = {
      password: pass,
      token: $scope.reset || $scope.token
    };

    DatabaseApi.post('auth/password_reset_token', form).then(function (res) {


      console.log('user');
      //console.log(res.data);
      //if(email)email = email.toLowerCase();



      if (res.data.loginData) {
        res.data.agency = res.data.loginData.agency;
        res.data.agencyMember = res.data.loginData.agencyMember;
        res.data.coordinator = res.data.loginData.coordinator;
        res.data.type = res.data.loginData.type;
      }

      var email;
      if (res.data.agencyMember && res.data.agencyMember.email) {
        email = res.data.agencyMember.email.toLowerCase();
      }
      Storage.set('userEmail', email);
      Storage.setObject('user', res.data);
      //console.log(Storage);

      $rootScope.token = res.data.authToken;
      $rootScope.userId = res.data.userId;
      $rootScope.agencyMemberId = res.data.agencyMember ? res.data.agencyMember.id : undefined;
      if (res.data.agency) {
        $rootScope.agencyId = res.data.agency.id;
      }
      $rootScope.user = res.data;

      Socket.init(res.data);

      //DatabaseApi.connect('only-score');
      DatabaseApi.GetSettings();

      if ($rootScope.user.coordinator) $rootScope.user.displayName = $rootScope.user.coordinator.firstName + ' ' + $rootScope.user.coordinator.lastName;
      if (res.data.agency) $rootScope.setCompanyType(res.data.agency.organizationType);
      $rootScope.user.email = email;//'asca@sdcsd.com';
      $rootScope.user.verified = true;
      $rootScope.user.admin = $rootScope.user.superuser ? true : res.data.coordinator.jobTitle === 'Admin';
      //DatabaseApi.GetSettings();

      $state.go('app.dashboard');

      $scope.form.token = undefined;

      DatabaseApi.connect();
    }, function (err) {
      toaster.pop('error', "Something went wrong", 'Please try again');
      $scope.error = 'Something Went Wrong';
      $scope.sending = false;
      $scope.disable = false;
    });

  }

  $scope.locationChange = function () {
    $scope.error = '';
    console.log($scope.form.address);
    if ($scope.form.address && $scope.form.address.geometry) {
      $scope.form.address = $scope.form.address.formatted_address;
    }
  };

  $scope.goTo = (targetLocation) => $window.open('https://www.medflyt.com/' + targetLocation);

  $scope.formChange = function () {
    console.log($scope.form);
    console.log($scope.regForm);
    $scope.error = '';
    $scope.emailError = !checkEmails($scope.form.email);
  };

  var blackList = ['gmail.com', 'mail.com', 'yahoo.com', 'msn.com', 'aol.com', 'outlook.com', 'me.com', 'yandex.com', 'inbox.com', 'icloud.com'];

  function checkEmails(email) {
    var isValid = true;
    if (!email) {
      return isValid;
    }

    email = email.toLowerCase();
    blackList.forEach(function (item) {
      if (email.search('@' + item) > -1) {
        isValid = false
      }
    });

    return isValid;
  }

};
