// @ts-check
import * as mfGeoCoder from "mf-google-geocoder";

//! @ngInject
export function GoogleAddressService() {
    const GOOGLE_MAPS_API_KEY = 'AIzaSyAcpv_xCANE3_P2EEqNmZL4LESMR_iAIik';
    this.getAddressComponentsFromText = async function (address) {
        return createAddressComponents(await mfGeoCoder.fromAddressText(address, { apiKey: GOOGLE_MAPS_API_KEY, mfAutoFix: true }, ['country', 'state', 'county']))
    }

    this.getAddressComponentsGoogleGeoCode = async function (addressGeoCodeResponse) {
        return createAddressComponents(await mfGeoCoder.fromGoogleGeoCode(addressGeoCodeResponse, { apiKey: GOOGLE_MAPS_API_KEY, mfAutoFix: true }, ['country', 'state', 'county']));
    }

    this.getAddressComponentsFromMedflytGeoCode = (addressGeoCodeResponse) => {
        return this.getAddressComponentsFromText(addressGeoCodeResponse.predictionText);
    }

    const createAddressComponents = function (address) {
        var addressComponentsCountry = null;
        var administrativeAreaLevel1 = null;
        var administrativeAreaLevel2 = null;
        var sublocalityLevel1 = null;
        var neighborhood = null;
        var locality = null;

        address.googleGeoCodeResponse.address_components.forEach(function (section) {
            if (section.types.indexOf('sublocality_level_1') > -1 || section.types.indexOf('sublocality') > -1) {
                sublocalityLevel1 = section.short_name;
            }
            if (section.types.indexOf('locality') > -1) {
                locality = section.short_name;
            }
            if (section.types.indexOf('neighborhood') > -1) {
                neighborhood = section.short_name;
            }
            if (section.types.indexOf('administrative_area_level_1') > -1) {
                administrativeAreaLevel1 = section.short_name;
            }
            if (section.types.indexOf('administrative_area_level_2') > -1) {
                administrativeAreaLevel2 = section.short_name;
            }
            if (section.types.indexOf('country') > -1) {
                addressComponentsCountry = section.short_name;
            }
        });
        const isFunc = typeof address.location.lat === 'function'
        const formatedAddress = {
            location: { lat: isFunc ? address.location.lat() : address.location.lat, lng: isFunc ? address.location.lng() : address.location.lng },
            address1: (address.streetNumber === null ? '' : address.streetNumber + ' ') + address.street,
            address2: address.address2,
            country: address.country,
            state: address.state,
            county: address.county,
            city: address.city,
            zip5: address.zip,
            zip4: address.zipSuffix,
            street: address.street,
            streetNumber: address.streetNumber,
            fullAddress: address.fullAddress
        };
        return {
            location: { lat: isFunc ? address.location.lat() : address.location.lat, lng: isFunc ? address.location.lng() : address.location.lng },
            country: addressComponentsCountry,
            administrativeAreaLevel1: administrativeAreaLevel1,
            administrativeAreaLevel2: administrativeAreaLevel2 ? administrativeAreaLevel2 : administrativeAreaLevel1,
            sublocalityLevel1: sublocalityLevel1,
            neighborhood: neighborhood,
            locality: locality,
            formatedAddressDetails: formatedAddress
        };
    }
};
