/* @ngInject */
export function caregiverPatientSearchFilter() {
    return function (items, name, isMultiple, showOnlyActive, selectedItems) {
        let result = {};
        let regex;
        try {
            regex = new RegExp(name, 'i');
        } catch (e) {
            name = false;
        }
        if (showOnlyActive === true) {
            items = Object.values(items).filter(item => item.status === "ACTIVE");
        }

        if (!name || name === '')
            return isMultiple ? selectedItems : result;
        else {
            angular.forEach(items, function (value, key) {
                const match = regex.test(value.displayName) || regex.test(value.caregiverCode) || regex.test(value.displayId);
                if (match && Object.keys(result).length < 5) {


                    value.messages = [];
                    value.photoUrl = value.photoUrl || 'admin/images/blank-profile.jpg';
                    value.caregiverLastViewd = null;
                    value.agencyLastViewd = null;
                    value.displayName = value.displayName;
                    value.fromList = true;
                    value.status = value.status.replace('_', ' ');

                    result[key] = value;
                }
            });
            return result;
        }
    };
}
;
