import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function exportDutySheetDataModal($scope, $rootScope, DatabaseApi, toaster, wildcard, apiErrors, planOfCareService) {
    $scope.fromDate = new Date($scope.$resolve.fromDate);
    $scope.toDate = new Date($scope.fromDate);
    $scope.toDate.setDate($scope.fromDate.getDate() + 6);
    $scope.exporting = false;
    $scope.caregivers = DatabaseApi.caregivers();

    $scope.exportAsCsv = () => {
        $scope.exporting = true;

        planOfCareService
          .generateDutySheetsCsv({
            patientId: $scope.$resolve.patientId,
            from: LocalDate.from(nativeJs($scope.fromDate)),
            to: LocalDate.from(nativeJs($scope.toDate)),
            planOfCareId: $scope.$resolve.planOfCareId,
          })
          .then((type) => generateToCsv(type))
          .catch((error) => toaster.error(apiErrors.format(error, "Failed to generate CSV")))
          .finally(() => $scope.exporting = false);
    };

    /**
     * @param { import("../services/planOfCareService").GeneratePatientDutySheetRow[] } duties
     */
    const generateToCsv = (duties) => {
        const days = [];
        for (const day = moment($scope.fromDate); day.isSameOrBefore(moment($scope.toDate)); day.add(1, 'days')) {
            days.push(day.format('YYYY-MM-DD'));
        }

        const titles = ["Task Name", "Task Code"];
        days.forEach(day => {
            titles.push(day);
        })
        const rows = [titles];
        for (const duty of duties) {
            const row = [
                duty.item.label,
                duty.item.code
            ];

            for (const day of days) {
                const dayOfWeek = moment(day).format('dddd').toUpperCase();
                const dayDutySheets = duties.dutySheets.filter(
                    (v) => v.startDate.toString() === day
                );

                if (dayDutySheets === undefined || dayDutySheets.length === 0) {
                    row.push("");
                    continue;
                }

                const frequencyPerWeek = duty.frequencyPerWeek;
                const everyVisit = !duty.days && !(frequencyPerWeek && duty.visitPerWeek) && duty.isRequired;
                const hasDays = duty.days && duty.days.includes(dayOfWeek);
                const asNeeded = duty.asNeeded = !duty.isRequired && !(frequencyPerWeek && duty.visitPerWeek) && !duty.days;

                if (!(everyVisit || asNeeded || hasDays || frequencyPerWeek)) {
                    continue;
                }

                const caregiversDuties = [];
                for (const dutySheet of dayDutySheets) {
                    const caregiver = $scope.caregivers[dutySheet.caregiverId];
                    const answer = dutySheet.answers.find(answer => answer.questionId === duty.id);
                    const notes = dutySheet.answers.find(answer => answer.questionId === (duty.id + 1000));
                    caregiversDuties.push(`${caregiver.displayName} ${answer !== undefined && answer.answer.includes("Done") ? "Fulfilled" : "Not Fulfilled"}.${notes !== undefined ? ` Notes: ${notes.answer}` : ''}`);
                }
                row.push('"' + caregiversDuties.join(", ") + '"');
            }
            rows.push(row);
        }
        let csvContent = "";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        const universalBOM = "\uFEFF";
        const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);

        const from = LocalDate.from(nativeJs($scope.fromDate));
        const to = LocalDate.from(nativeJs($scope.toDate));
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${from}-${to}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    $scope.selectedRangeWeekOrLess = () => {
        return moment($scope.toDate).diff($scope.fromDate, 'd') <= 7;
    }

    $scope.isExportPDFEnabled = () => {
        return !$scope.exporting && $scope.selectedRangeWeekOrLess();
    }

    $scope.exportPDF = () => {
        const from = LocalDate.from(nativeJs($scope.fromDate));
        const to = LocalDate.from(nativeJs($scope.toDate));
        const url = wildcard(
            `agencies/:agencyId/patients/:patientId/patient_document_version/:patientDocumentVersionId/generate_duty_sheets_pdf_by_dates?from=${from}&to=${to}`,
            $rootScope.agencyId,
            $scope.$resolve.patientId,
            $scope.$resolve.patientDocumentVersionId
        );
    
        $scope.exporting = true;
        DatabaseApi.get(url).then((res) => {
            window.open(res.data.fileUrl, "_blank");
            $scope.exporting = false;
        }).catch(error => {
            toaster.pop("error", "Something went wrong", "Failed to export patient duty sheets to pdf");
            console.error(error);
            $scope.exporting = false;
        });
    }
};