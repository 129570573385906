//! @ngInject
export function caregiverTrainingCenterTestModalCtrl(
  $scope,
  $rootScope,
  $window,
  CaregiverTrainingCenterService,
  toaster
) {
  const service = CaregiverTrainingCenterService;

  this.$onInit = async function () {
    $scope.caregiverId = $scope.$resolve.caregiverId;
    $scope.dueDateId = $scope.$resolve.dueDateId;
    $scope.bundleItem = $scope.$resolve.bundleItem;

    $scope.languages = undefined;
    $scope.testSubmissions = undefined;
    $scope.selectedLanguage = undefined;

    await service
      .getTestSubmissions({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: $scope.dueDateId,
        trainingCenterBundleItemId: $scope.bundleItem.bundleItemId,
      })
      .then((test) => {
        console.log(test);
        $scope.languages = test.languages;
        $scope.testSubmissionsByLanguage = test.submissionsByLanguage;
        $scope.selectLanguage(test.lastLanguage);

        console.log($scope.languages);
        console.log($scope.selectedLanguage);
        console.log($scope.testSubmissions);
      })
      .catch((err) => {
        if (err.data && err.data.error) {
          if (err.data.error === "SUBMISSIONS") {
            $scope.testSubmissions = [];
          }
        } else {
          $scope.$close("NO_CONNECTION");
        }
      });
  };

  $scope.viewPdf = async function (submission) {
    await service
      .getTestSubmissionPdfUrl({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: $scope.dueDateId,
        testSubmissionId: submission.id,
      })
      .then((pdfUrl) => $window.open(pdfUrl))
      .catch((err) => {
        if (err.data && err.data.error) {
          if (err.data.error === "ANSWERS") {
            toaster.pop("error", "Couldn't find test answers");
          }
          if (err.data.error === "DUE_DATE") {
            toaster.pop("error", "Couldn't find test due date");
          }
          if (err.data.error === "HIRE_DATE") {
            toaster.pop("error", "Couldn't find caregiver hire date");
          }
        } else {
          $scope.$close("NO_CONNECTION");
        }
      });
  };

  $scope.selectLanguage = function (language) {
    $scope.selectedLanguage = language;
    $scope.testSubmissions = $scope.testSubmissionsByLanguage[language];
  };

  $scope.exit = function () {
    $scope.$close();
  };
};
