import { AxiosError } from "axios";
import { z } from "zod";

const customErrorSchema = z.object({
  data: z.object({
    error: z.string(),
  }),
});

export function formatErrorResponse(error: unknown) {
  if (error instanceof AxiosError) {
    const err = customErrorSchema.safeParse(error.response);
    if (err.success) {
      return err.data.data.error;
    }
  }

  return `${error}`;
}
