export function labelWithOptions() {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/label-with-options.html',
        scope: {
            labelText: '=',
            options: '=',
            isSuccess: '=',
            classNames: '='
        },
        link: function ($scope) {
            if ($scope.options && $scope.options.length > 0) {
                $scope.classNames = $scope.classNames.split(' ');
                $scope.classNames.push("pointer");
                $scope.classNames = $scope.classNames.join(' ');
            }

            $scope.labelPopover = {
                isOpen: false,
                close() {
                    $scope.labelPopover.isOpen = false;
                }
            };

            $scope.onOptionClick = (opt) => {
                const args = [];
                let i = 1;
                while (opt[`callbackParam${i}`] !== undefined) {
                    args.push(opt[`callbackParam${i}`]);
                    i++;
                }

                opt.callback(...args);
                $scope.labelPopover.close();
            }
        }
    };
};