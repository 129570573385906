import { loadable, Loadable } from "../consts/loadable.const";
import { OfficeId } from "../messages/ids";
import { PlanOfCareType, PlanOfCareTypesResponse } from "../messages/plan_of_care_type";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export class PlanOfCareTypeService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  private list: Loadable<PlanOfCareType[]> = loadable.loading();

  /**
   * Get the agency's plan of care types
   */
  get(params?: Partial<{ refetch: boolean }>): ng.IPromise<PlanOfCareType[]> {
    return !loadable.isResolved(this.list) || params?.refetch === true
      ? this.fetch()
      : this.$q.resolve(this.list.value);
  }

  /**
   * Get the plan of care type for a given office
   */
  getByOfficeId(params: {
    officeId: OfficeId;
    refetch?: boolean;
  }): ng.IPromise<PlanOfCareType | null> {
    if (!loadable.isResolved(this.list) || params.refetch === true) {
      return this.fetch().then(
        (rows) => rows.find((row) => row.officeId === params.officeId) ?? null
      );
    }

    return this.$q.resolve(this.list.value.find((row) => row.officeId === params.officeId) ?? null);
  }

  /**
   * Fetches the agency's plan of care types and updates the cached list
   */
  private fetch(): ng.IPromise<PlanOfCareType[]> {
    const url = this.endpoint({
      path: "agencies/:agencyId/coordinator/:agencyMemberId/plan_of_care_settings",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<PlanOfCareTypesResponse>(url).then(({ data }) => {
      this.list = loadable.resolve(data.planOfCares);
      return this.list.value;
    });
  }
}
