//! @ngInject
export function pickVisitModalCtrl($scope, $rootScope, NgTableParams, $state, $uibModalInstance, caregiver, patientId, DatabaseApi, toaster, Socket, SweetAlert, $timeout){
  
  window.scrollTo(0,0);
  
  $scope.caregiver = caregiver;
  $scope.visits = DatabaseApi.visits().broadcasts;
  if ($scope.visits === undefined) {
    DatabaseApi.getVisits().then(db => $scope.visits = db.visits.broadcasts);
  }
  
  $scope.closeModal = function(){
    $uibModalInstance.close('cancel');
  };
 
  $scope.filterVisits = function (visit) {
    let filter = true;
    filter = visit.certification.indexOf($scope.caregiver.certification) != -1;
    // if a patientId is available - filter visit only by matching patient.
    if (patientId && filter) {
      filter = visit.patientId === patientId;
    }
    return filter;
  };
  
};