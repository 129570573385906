//! @ngInject
export function orientationCenterCtrl(
  $scope,
  $rootScope,
  $q,
  $filter,
  NgTableParams,
  toaster,
  DatabaseApi,
  Storage,
  offices,
  wildcard
) {
  function getQueryParams() {
    const certifications = $scope.filters.certifications
      .map((cert) => {
        const foundCert = $scope.certifications.find(
          ({ id }) => id === cert.id
        );
        if (foundCert !== undefined) {
          return foundCert.label;
        }
        return undefined;
      })
      .join();
    const languages = $scope.filters.languages
      .map((language) => {
        const foundLang = $scope.languages.find(({ id }) => id === language.id);
        if (foundLang !== undefined) {
          return foundLang.label;
        }
        return undefined;
      })
      .join();
    // const statuses = $scope.filters.statuses
    //   .map(
    //     (status) => {
    //       const foundStatus = $scope.caregiverStatuses.find(({ id }) => id === status.id);
    //       if (foundStatus !== undefined) {
    //         return foundStatus.value;
    //       }
    //       return undefined;
    //     }
    //   )
    //   .join();
    const offices = $scope.filters.offices.map(({ id }) => id).join();

    const areCerts = certifications !== "";
    const areLanguages = languages !== "";
    // const areStatuses = statuses !== "";
    const areOffices = offices !== "";
    if (!areCerts && !areLanguages /*&& !areStatuses*/ && !areOffices)
      return "";

    const params = [];
    if (areCerts) params.push(`certifications=${certifications}`);
    if (areLanguages) params.push(`languages=${languages}`);
    // if (areStatuses) params.push(`statuses=${statuses}`);
    if (areOffices) params.push(`offices=${offices}`);

    return `?${params.join("&")}`;
  }

  $scope.getCaregivers = async function () {
    const deferred = $q.defer();

    const url = wildcard(
      "agencies/:agencyId/agency_member/:agencyMemberId/orientation_center",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );
    const query = getQueryParams();

    DatabaseApi.get(`${url}${query}`).then(
      function (res) {
        $scope.caregivers = res.data.rows.map(
          ({ caregiverId, percentage }) => ({
            percentage,
            percentageStatus: getStatusLabel(percentage),
            percentageColor: getStatusLabelColor(percentage),
            ...DatabaseApi.getCaregiverById(caregiverId),
          })
        );
        initCaregiversTable($scope.caregivers);
        deferred.resolve();
      },
      function (err) {
        toaster.pop("error", "Could not get orientation center");
        deferred.reject();
      }
    );

    return deferred.promise;
  };

  $scope.applyCaregiversGlobalSearch = function (term) {
    const filter = { $: term };
    if ($scope.caregiversTable) {
      angular.extend($scope.caregiversTable.filter(), filter);
    }
  };

  function initCaregiversTable(data) {
    const options = {
      count: 25,
      sorting: { timeStamp: "desc" },
    };
    $scope.caregiversTable = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: data,
    });

    if ($scope.caregiversGlobalFilter.val) {
      $scope.applyCaregiversGlobalSearch($scope.caregiversGlobalFilter.val);
    }
  }

  $scope.openRemoteLinkModal = function () {
    $uibModal.open({
      templateUrl: "admin/views/remote-application-link-modal.html",
      size: "md",
      controller: "remoteApplicationLinkModalCtrl",
    });
  };

  $scope.getProgressbarShrinkerPercentageWidth = function (percentage) {
    const val = 100 - percentage;
    return val + "%";
  };

  const columnsOrientationCenter = Storage.getObject(
    "orientationCenterTableSettings"
  );

  if (
    columnsOrientationCenter &&
    Object.keys(columnsOrientationCenter).length
  ) {
    $scope.orientationCenterTableColumnsLength = 0;
    let renameCertificationCol = false;

    Object.keys(columnsOrientationCenter).forEach(function (t) {
      if (columnsOrientationCenter[t])
        $scope.orientationCenterTableColumnsLength++;
      if (t === "Certification") renameCertificationCol = true;
    });

    if (renameCertificationCol) {
      columnsOrientationCenter.Certifications = columnsOrientationCenter.Certification;
      delete columnsOrientationCenter.Certification;
    }

    $scope.orientationCenterTableColumns = columnsOrientationCenter;
  } else {
    // no columns
    $scope.orientationCenterTableColumns = {
      Photo: true,
      Name: true,
      Gender: true,
      Birthdate: true,
      Languages: true,
      InstalledAt: true,
      Branch: true,
      Certifications: true,
      Installation: true,
      ApplicationStartDate: true,
      LastSeen: true,
      Address: true,
      Offices: true,
      Phone: true,
      Progress: true,
      Status: true,
    };
    $scope.orientationCenterTableColumnsLength = 0;
    Object.keys($scope.orientationCenterTableColumns).forEach(function (t) {
      if ($scope.orientationCenterTableColumns[t])
        $scope.orientationCenterTableColumnsLength++;
    });
  }
  $scope.$watch(
    "orientationCenterTableColumns",
    function () {
      if ($scope.orientationCenterTableColumns) {
        Storage.setObject(
          "orientationCenterTableSettings",
          $scope.orientationCenterTableColumns
        );
        $scope.orientationCenterTableColumnsLength = 0;
        Object.keys($scope.orientationCenterTableColumns).forEach(function (t) {
          if ($scope.orientationCenterTableColumns[t])
            $scope.orientationCenterTableColumnsLength++;
        });
      }
    },
    true
  );

  $scope.exportTable = function () {
    const list = $scope.caregivers;
    const rows = [];
    const titles = [];
    for (const key in $scope.orientationCenterTableColumns) {
      if (
        $scope.orientationCenterTableColumns[key] &&
        key !== "Photo" &&
        (key !== "Branch" || $scope.visitSettings.allowBranches)
      ) {
        titles.push(key);
      }
    }
    rows.push(titles);

    list.forEach(function (caregiver) {
      const row = [];
      titles.forEach(function (title) {
        switch (title) {
          case "Name": {
            row.push(caregiver.displayName || "");
            break;
          }
          case "Gender": {
            row.push(caregiver.gender || "");
            break;
          }
          case "Birthdate": {
            row.push($filter("mfShortDate")(caregiver.birthDate) || "");
            break;
          }
          case "Languages": {
            row.push(caregiver.languages.join(" | ") || "");
            break;
          }
          case "InstalledAt": {
            let string = " ";
            caregiver.appInstalledAt.forEach(function (d) {
              if (string !== " ") string += " | ";
              string += $filter("mfShortDate")(d);
            });
            row.push(string);
            break;
          }
          case "Branch": {
            row.push(row.caregiverBranch || "");
            break;
          }
          case "Certifications": {
            row.push(caregiver.certifications.join(", ") || "");
            break;
          }
          case "Installation": {
            if (caregiver.confirmed === "installed") row.push("Installed");
            else if (caregiver.confirmed === "uninstalled")
              row.push("Un-Installed");
            else row.push("Not Installed");
            break;
          }
          case "LastSeen": {
            if (!caregiver.lastSeen) row.push("");
            else
              row.push($filter("mfShortDate")(caregiver.lastSeen) || "");
            break;
          }
          case "Address": {
            const address = caregiver.address;
            row.push('"' + address + '"' || '" "');
            break;
          }

          case "Offices": {
            row.push(row.officeIds || "");
            break;
          }

          case "Phone": {
            let string = " ";
            caregiver.phoneNumbers.forEach(function (f) {
              if (string !== " ") string += " | ";
              string +=
                $filter("americanphone")(f.phoneNumber) + " (" + f.type + ")";
            });
            row.push(string);
            break;
          }
          case "Progress": {
            let percentage = caregiver.percentage;
            if (!percentage) percentage = 0;
            row.push(percentage + "%");
            break;
          }
          case "Status": {
            row.push($scope.getStatusLabel(caregiver.percentage) || "");
            break;
          }
          default: {
            row.push("");
            break;
          }
        }
      });

      rows.push(row);
    });

    let csvContent = "";
    rows.forEach(function (rowArray) {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv" }),
      url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      "medflyt-orientation-center-caregivers-export.csv"
    );

    link.click();
    window.URL.revokeObjectURL(url);
  };

  function getStatusLabel(percentage) {
    if (percentage == 0) return "Not started";
    if (percentage > 0 && percentage < 100) return "In progress";

    return "Completed";
  }

  function getStatusLabelColor(percentage) {
    if (percentage == 0) return "red";
    if (percentage > 0 && percentage < 100) return "blue";

    return "green";
  }

  $scope.goToProfile = function (id) {
    $rootScope.openCaregiverModal(id);
  };

  $scope.getCaregiverStatusColor = function ({ status, statusClass }) {
    if (statusClass) return statusClass;

    const foundStatus = $scope.caregiverStatuses.find(
      ({ value }) => value === status
    );

    if (foundStatus !== undefined) {
      return foundStatus.label;
    }
    return undefined;
  };

  $scope.statusSelectionEvents = {
    onSelectionChanged: () => {
      if (
        $scope.filters &&
        $scope.filters.statuses &&
        $scope.filters.statuses.length === 0
      )
        return initCaregiversTable($scope.caregivers);

      const statusSelections = $scope.filters.statuses.map(
        ({ id }) => {
          const foundStatus = $scope.statuses.find((status) => status.id === id)
          if (foundStatus !== undefined) {
            return foundStatus.label;
          }
          return undefined;
        }
      );
      const filteredCaregivers = $scope.caregivers.filter(
        ({ percentageStatus }) => statusSelections.includes(percentageStatus)
      );
      initCaregiversTable(filteredCaregivers);
    },
  };

  // $scope.$watch(
  //   "filters",
  //   function () {
  //   },
  //   true
  // );

  $scope.caregivers = [];

  $scope.filters = {
    certifications: [],
    languages: [],
    statuses: [],
    offices: [],
  };

  $scope.caregiversGlobalFilter = { val: "" };

  $scope.offices = offices
    .filter((office) => office.active)
    .map((office) => ({
      id: office.id,
      label: office.name,
    }));

  $rootScope.languages = [
    { id: 1, label: "English" },
    { id: 2, label: "Spanish" },
    { id: 3, label: "Chinese" },
    { id: 4, label: "Portuguese" },
    { id: 5, label: "French" },
    { id: 6, label: "Russian" },
    { id: 7, label: "Arabic" },
  ];

  $scope.caregiverStatuses = [
    { id: 1, value: "ACTIVE", label: "Active", statusClass: "green" },
    { id: 2, value: "ON_HOLD", label: "On Hold", statusClass: "orange" },
    { id: 3, value: "ON_LEAVE", label: "On Leave", statusClass: "orange" },
    {
      id: 4,
      value: "PENDING",
      label: "Pending Application",
      statusClass: "lightblue",
    },
    { id: 5, value: "TERMINATED", label: "Terminated", statusClass: "red" },
    { id: 6, value: "SUSPENDED", label: "Inactive", statusClass: "gray" },
    { id: 6, value: "QUIT", label: "Quit", statusClass: "gray" },
    { id: 7, value: "REJECTED", label: "Rejected", statusClass: "red"}
  ];

  $scope.statuses = [
    { id: 1, label: "Not started" },
    { id: 2, label: "In progress" },
    { id: 3, label: "Completed" },
  ];


  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
  $scope.certifications = activeAgencyCertifications
      .map((certificationItem, index) => ({
          id: index,
          label: certificationItem.certification
      }));

  $scope.getCaregivers();
}
