import moment from "moment";

//! @ngInject
export function trainingCenterLiveEventsCtrl($scope, $rootScope, $uibModal, NgTableParams, DatabaseApi, toaster) {

    function initialize() {
        loadItems();
    }

    $scope.pageTabs = [
        "Upcoming Events",
        // "Previous Events"
    ];

    function loadItems() {
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/live_events`;

        DatabaseApi.get(url).then((res) => {
            // $scope.liveEvents = [
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(3, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(3, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Sophie", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(7, "hours").toDate(), endTime: moment().add(11, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().subtract(1, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Janet", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(1, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(6, "hours").toDate(), endTime: moment().add(7, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(3, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().subtract(3, "hours").toDate(), endTime: moment().add(1, "hours").toDate() },
            //     { name: "Live classroom with Monica", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().subtract(7, "hours").toDate(), endTime: moment().subtract(5, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(3, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Rachel", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(3, "hours").toDate(), endTime: moment().add(5, "hours").toDate() },
            //     { name: "Live classroom with Sandra", bundle: "Bundle Name", caregiversRegistered: 145, startTime: moment().add(2, "hours").toDate(), endTime: moment().add(4, "hours").toDate() },
            // ];
            $scope.liveEvents = res.data.liveEvents;
            mapItems($scope.liveEvents);
            $scope.selectedTab = $scope.pageTabs[0];
            initTable($scope.liveEvents);
        }, (err) => {
            toaster.pop('error', 'Something went wrong', 'Failed to retrieve live events');
        });

    }

    function mapItems(items) {
        items = items.map(liveEvent => {
            const durationMiliSeconds = moment(liveEvent.endTime).diff(moment(liveEvent.startTime));
            liveEvent.duration = moment.duration(durationMiliSeconds).humanize();
    
            if (moment().isAfter(moment(liveEvent.startTime))) {
                if (moment().isAfter(moment(liveEvent.endTime))) {
                    liveEvent.status = "Completed";
                } else {
                    liveEvent.status = "Happening now";
                }
            } else {
                const startsInDurationMiliSeconds = moment(liveEvent.startTime).diff(moment());
                liveEvent.status = "Starts in " + moment.duration(startsInDurationMiliSeconds).humanize();
            }

            return liveEvent;
        });
    }

    function initTable(items) {
        const oldTotal = $scope.tableParams?.total?.() || 0;
        let page = false;
        let sorting = { startTime: "desc" };
        let filters = undefined;

        const options = {
            count: 25,
            sorting: sorting
        };

        if ($scope.tableParams) {
            page = $scope.tableParams.page()
            sorting = $scope.tableParams.sorting();
            filters = $scope.tableParams.filter();
        }
        if (filters) options.filter = filters;

        $scope.tableParams = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: items
                // .filter(item => item.type === $scope.selectedTab)
        });

        if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    }

    $scope.handleClickTab = (tab) => {
        $scope.selectedTab = tab;
        initTable($scope.liveEvents);
    };

    $scope.goLive = () => {
        $uibModal.open({
            templateUrl: 'admin/views/live-event-modal.html',
            size: 'lg',
            windowClass: "live-event-modal-container",
            controller: 'liveEventModalCtrl'
        });
    };

    $scope.watchRecording = () => {
        $uibModal.open({
            templateUrl: 'admin/views/training-center-live-event-recording-modal.html',
            size: 'lg',
            // windowClass: "live-event-modal-container",
            controller: 'trainingCenterLiveEventRecordingModalCtrl'
        });
    };

    initialize();
};